import { FC, PropsWithChildren, useEffect, useState } from "react"
import { useSolicitorOnboarding } from "./SolicitorOnboarding"
import "twin.macro"
import { Card, CardContent, CardFooter, Form, FormSection, SuccessMessage } from "../../elements"
import { ROUTES } from "../../layout/Navigation"
import { SubscribeToMonthlyBillingButton } from "./select-charge-model/ConfirmSignupModal"
import { useAsync, useFromTaskEither } from "../../lib/useAsync"
import { formSubmit, graphql } from "../../lib/utils"
import { solicitorOnboardToStripeCommand, SolicitorOnboardToStripeCommandMutation } from "@lawhive/generated-api"
import { Button, ButtonLink } from "../../elements/Button"
import { isPending, isReplete, refreshFold } from "@nll/datum/DatumEither"
import * as O from "fp-ts/es6/Option"
import { isSome } from "fp-ts/es6/Option"
import { CheckCircleIcon } from "@heroicons/react/outline"
import { useGetChargeAccount } from "../../hooks/useGetChargeAccount"
import { pipe } from "fp-ts/es6/function"
import { match } from "ts-pattern"
import { SkeletonCard } from "../../elements/Skeleton"

export const SolicitorOnboardingWizard: FC = () => {

  const monthlyBillingTE = useGetChargeAccount()

  const {status, execute} = useFromTaskEither(monthlyBillingTE)

  useEffect(() => {execute()}, [])

  const hasMonthlyBilling = pipe(
    status,
    refreshFold(
      () => O.none,
      () => O.none,
      () => O.none,
      a => pipe(a, O.map(b => b.model.tag === "Monthly" && !b.needsSubscription))
    ))

  type TabNumber = 0 | 1 | 2
  const { hasSubmitDetails, state } = useSolicitorOnboarding()
  const [tabIndex, setTabIndex] = useState<TabNumber>(0)

  const determineStepStatus = (tabId: TabNumber): StepperStatus => {
    if (tabIndex < tabId) return 'upcoming'
    if (tabIndex === tabId) return 'current'
    if (tabIndex > tabId) return 'complete'

    return 'complete'
  }

  const selectStep = (): TabNumber => {
    if (isSome(hasMonthlyBilling) && hasMonthlyBilling.value) return 2
    if (isSome(hasSubmitDetails) && hasSubmitDetails.value) return 1
    return 0
  }

  const solicitorOnboardingSteps: StepperStep[] = [
    { id: '1', name: 'Set up payouts', status: determineStepStatus(0) },
    { id: '2', name: 'Set up Direct Debit', status: determineStepStatus(1)},
    { id: '3', name: 'Ready to accept cases', status: determineStepStatus(2)},
  ]

  useEffect(() => {
    setTabIndex(selectStep())
  }, [hasSubmitDetails, hasMonthlyBilling])

  return (
    <>
      {!isReplete(status) || !isReplete(state) ? <SkeletonCard /> :
        <Card>
          <CardContent>
            <Stepper steps={solicitorOnboardingSteps} />
            {
              match(tabIndex)
                .with(0, () => <PaymentDetailsStep />)
                .with(1, () => <MonthlyBillingStep />)
                .with(2, () => <CompleteStep />)
                .exhaustive()
            }
          </CardContent>
        </Card>
      }
  </>
  )


}

const CompleteStep: FC = () => {
  return (
    <div tw="mt-6">
      <Card>
        <CardContent>
           <SuccessMessage icon={<>🎉</>} title="You're ready to go!">
            Our Solicitor Success team will be in touch shortly with your first case!
          </SuccessMessage>
        </CardContent>
        <CardFooter actions={
          <ButtonLink to={ROUTES.solicitors.root}>Go to your account</ButtonLink>
        }/>
      </Card>
    </div>
  )
}

const PaymentDetailsStep: FC = () => {
  return (
    <div tw="mt-6">
      <Card>
        <CardContent>
          <h1 tw="text-lg leading-6 font-medium text-gray-900">
            Choose where to receive client payments
          </h1>
          <p tw="text-base mt-4 text-gray-600">
            Clients pay you through the Lawhive platform using their debit or credit card. You'll receive a bank transfer for your client payments 3 days after your client pays.
          </p>
          <p tw="text-base mt-4 text-gray-600">
            Nominate a bank account to accept payments from clients - most solicitors use their client account but as long as you safeguard your client's money it's up to you.
          </p>
          <p tw="text-base mt-4 text-gray-600">
            We partner with Stripe to handle payments - click below to be taken to their setup form and nominate your payout account.
          </p>
        </CardContent>

        <StripeOnboardAccount
          returnUrl={ROUTES.solicitors.root}
          refreshUrl={ROUTES.solicitors.root}
        >
          Take me to Stripe
        </StripeOnboardAccount>

      </Card>
    </div>
  )
}

const MonthlyBillingStep: FC = () => {
  return (
    <div tw="mt-6">
      <Card>
        <CardContent>
          <h1 tw="text-lg leading-6 font-medium text-gray-900">
            In order to pay your Lawhive fees, you need to set up a Direct Debit
          </h1>
          <p tw="text-base mt-4 text-gray-600">
            Lawhive charges you a fee on each payment a client makes for your legal fees. We don't charge a fee on any disbursements.
          </p>
          <p tw="text-base mt-4 text-gray-600">
            Each month we'll email you an invoice and collect your Lawhive fees by Direct Debit a few days later.
          </p>
          <p tw="text-base mt-4 text-gray-600">
            Nominate a bank account for your monthly Direct Debit. Most solicitors use their office account for this.
          </p>
          <p tw="text-base mt-4 text-gray-600">
            Stripe handle this one for us too, so when you click the button below you'll be taken to another simple form on their site.
          </p>
        </CardContent>
        <CardFooter
          actions={
            <SubscribeToMonthlyBillingButton cancelUrl={ROUTES.solicitors.root} successUrl={ROUTES.solicitors.root}>
              Set up Direct Debit
            </SubscribeToMonthlyBillingButton>
        }
        />

      </Card>
    </div>
  )
}

const StripeOnboardAccount: FC<PropsWithChildren<{ refreshUrl: string, returnUrl: string}>> = ({ refreshUrl, returnUrl, children}) => {
  const { status: submissionState, execute } = useAsync(
    () =>
      graphql<SolicitorOnboardToStripeCommandMutation>({
        query: solicitorOnboardToStripeCommand,
        variables: {
          input: {
            returnUrl,
            refreshUrl
          }
        }
      })
        .then(a => a.data?.solicitorOnboardToStripeCommand
          ? Promise.resolve(a.data.solicitorOnboardToStripeCommand.url!)
          : Promise.reject('Onboarding response error')
        )
        .then(async url => {
          window.location.href = url
        })
  )

  const submit = async () => {
    await execute()
  }

  return (
    <Form
      tw="border-t border-gray-200"
      onSubmit={formSubmit(submit)}
    >
      <FormSection>
        <Button isLoading={isPending(submissionState)}>
          {children}
        </Button>
      </FormSection>
    </Form>
  )
}


type StepperStep = {
  id: string
  name: string
  status: StepperStatus
}

type StepperStatus = 'upcoming' | 'current' | 'complete'



const Stepper = ({steps}: {steps: StepperStep[]}) => {
  return (
    <nav aria-label="Progress">
      <ol role="list" tw="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
        {steps.map((step, stepIdx) => (
          <li key={step.name} tw="relative md:flex md:flex-1">
            {step.status === 'complete' ? (
              <div className="group" tw="flex w-full items-center">
                <span tw="flex items-center px-6 py-4 text-sm font-medium">
                  <span tw="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                    <CheckCircleIcon tw="text-white"/>
                  </span>
                  <span tw="ml-4 text-sm font-medium text-gray-900">{step.name}</span>
                </span>
              </div>
            ) : step.status === 'current' ? (
              <div tw="flex items-center px-6 py-4 text-sm font-medium" aria-current="step">
                <span tw="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-600">
                  <span tw="text-indigo-600">{step.id}</span>
                </span>
                <span tw="ml-4 text-sm font-medium text-indigo-600">{step.name}</span>
              </div>
            ) : (
              <div className="group" tw="flex items-center">
                <span tw="flex items-center px-6 py-4 text-sm font-medium">
                  <span tw="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                    <span tw="text-gray-500 group-hover:text-gray-900">{step.id}</span>
                  </span>
                  <span tw="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">{step.name}</span>
                </span>
              </div>
            )}

            {stepIdx !== steps.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div tw="absolute top-0 right-0 hidden h-full w-5 md:block" aria-hidden="true">
                  <svg
                    tw="h-full w-full text-gray-300"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  )
}

