import * as D from "io-ts/Decoder"
import { DateFromString, withOptional } from "../../../lib/io"
import { PriceUnits, UUID } from "../../../lib/Primitives"
import { LawhiveDiscount } from "../../../features/quotes/types"
import { useApiFetch } from "../../../lib/useApiClient"
import { castTo, fetchParsedJSON } from "../../../lib/fetch"
import { flow } from "fp-ts/lib/function"

export const SolicitorQuoteClientOwner = D.struct({
  tag: D.literal("client"),
  id: UUID
})

export type SolicitorQuoteClientOwner = D.TypeOf<typeof SolicitorQuoteClientOwner>

export const SolicitorQuoteLeadOwner = D.struct({
  tag: D.literal("lead"),
  id: UUID
})

export type SolicitorQuoteLeadOwner = D.TypeOf<typeof SolicitorQuoteLeadOwner>

export const SolicitorQuoteOwner = D.union(SolicitorQuoteClientOwner, SolicitorQuoteLeadOwner)
export type SolicitorQuoteOwner = D.TypeOf<typeof SolicitorQuoteOwner>

export const SolicitorQuoteType = withOptional(D.struct({
  tag: D.literal("solicitor"),
  solicitorId: UUID
}), {
  notes: D.string
})

export type SolicitorQuoteType = D.TypeOf<typeof SolicitorQuoteType>

export const SolicitorQuoteLineItem = withOptional(D.struct({
  amount: PriceUnits,
  type: D.literal("fee", "disbursement"),
  name: D.string
}), {
  groupKey: D.string,
  label: D.string,
  description: D.string
})

export type SolicitorQuoteLineItem = D.TypeOf<typeof SolicitorQuoteLineItem>

export const SolicitorQuoteScope = withOptional(D.struct({
  type: D.literal("inclusion", "exclusion"),
  title: D.string,
}), {
  groupKey: D.string
})

export type SolicitorQuoteScope = D.TypeOf<typeof SolicitorQuoteScope>

export const SolicitorQuoteGroup = withOptional(D.struct({
  name: D.string,
  key: D.string,
}), {
  isRemovable: D.boolean,
})

export type SolicitorQuoteGroup = D.TypeOf<typeof SolicitorQuoteGroup>

export const SolicitorQuote = withOptional(D.struct({
  scopes: D.array(SolicitorQuoteScope),
  lineItems: D.array(SolicitorQuoteLineItem),
  groups: D.array(SolicitorQuoteGroup),
}), {
  productName: D.string,
  discounts: D.array(LawhiveDiscount),
  expiresAt: DateFromString,
  notesFromSolicitor: D.string,
})

export type SolicitorQuote = D.TypeOf<typeof SolicitorQuote>

export const CreateSolicitorQuoteCommand = withOptional(D.struct({
  owner: SolicitorQuoteOwner,
  solicitorId: UUID,
  quote: SolicitorQuote
}), {
  productName: D.string
})

export type CreateSolicitorQuoteCommand = D.TypeOf<typeof CreateSolicitorQuoteCommand>

export const CreateSolicitorQuoteDto = D.union(SolicitorQuote, D.struct({
  type: SolicitorQuoteType,
  owner: SolicitorQuoteOwner
}))

export type CreateSolicitorQuoteDto = D.TypeOf<typeof CreateSolicitorQuoteDto>

type CreateSolicitorQuoteResult = { id: string }

export const useCreateSolicitorQuoteNew = () => {
  const api = useApiFetch(fetchParsedJSON(castTo<CreateSolicitorQuoteResult>()))

  const convertCommandToDto = (command: CreateSolicitorQuoteCommand): CreateSolicitorQuoteDto => ({
    ...command.quote,
    productName: command.productName,
    type: { tag: "solicitor", solicitorId: command.solicitorId, notes: command.quote.notesFromSolicitor },
    owner: command.owner
  })

  return flow(
    convertCommandToDto,
    dto => api(`quotes/solicitors`, {
      method: "POST",
      body: JSON.stringify(dto)
    })
  )
}
