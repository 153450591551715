export const clientListCasesQuery = (clientId: string) => /* GraphQL */ `
  query ListCases {
    listCasesByClient(clientId: "${clientId}") {
      items {
        id
        caseNumber
        brand
        payFirst

        client {
          displayName
        }

        caseSolicitorId
        solicitor {
          name
          businessName
        }

        quotes {
          items {
            lineItems {
              items {
                clientPays
              }
            }
          }
        }

        status

        payments {
          items {
            amount
          }
        }
        createdAt
      }
    }
  }
`
