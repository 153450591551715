/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateClientCommand = {
  emailAddress: string,
  name: string,
  phoneNumber: string,
  referral?: string | null,
};

export type User = {
  __typename: "User",
  id?: string,
  type?: UserType | null,
  emailAddress?: string | null,
  displayName?: string | null,
  phoneNumber?: string | null,
  kycSubmission?: KYCSubmission,
  stripeCustomerId?: string | null,
  paymentMethods?:  Array<PaymentMethod > | null,
  clientCases?: ModelCaseConnection,
  solicitorCases?: ModelCaseConnection,
  referral?: string | null,
  createdAt?: string,
  updatedAt?: string,
};

export enum UserType {
  client = "client",
  solicitor = "solicitor",
}


export type KYCSubmission = {
  __typename: "KYCSubmission",
  userId?: string,
  user?: User,
  status?: KYCSubmissionStatus | null,
  address?: string | null,
  documentUploads?: Array< string > | null,
  identityUploads?: Array< string > | null,
  verificationReport?: KycVerificationReport,
  rejectionReason?: KycRejectionReason,
  createdAt?: string,
  updatedAt?: string,
};

export enum KYCSubmissionStatus {
  submitted = "submitted",
  approved = "approved",
  rejected = "rejected",
}


export type KycVerificationReport = {
  __typename: "KycVerificationReport",
  amlReportFileKey?: string,
  kycReportFileKey?: string | null,
};

export type KycRejectionReason = {
  __typename: "KycRejectionReason",
  reason?: string | null,
  instructions?: string | null,
};

export type PaymentMethod = {
  __typename: "PaymentMethod",
  id?: string,
  lastFour?: string,
  brand?: string,
  expiryMonth?: number,
  expiryYear?: number,
};

export type ModelCaseConnection = {
  __typename: "ModelCaseConnection",
  items?:  Array<Case | null > | null,
  nextToken?: string | null,
};

export type Case = {
  __typename: "Case",
  id?: string,
  caseNumber?: string,
  brand?: string | null,
  productSKU?: string | null,
  hasChatbot?: boolean | null,
  payFirst?: boolean | null,
  context?: string | null,
  createdAt?: string,
  clientId?: string,
  client?: User,
  clientKyc?: CaseClientKyc,
  files?:  Array<File > | null,
  details?: CaseDetails,
  messages?: ModelMessageConnection,
  quotes?: ModelCaseQuoteConnection,
  payments?: ModelCasePaymentConnection,
  caseSolicitorId?: string | null,
  solicitor?: Solicitor,
  solicitorResponse?: SolicitorResponse,
  completion?: CaseCompletion,
  review?: ModelReviewConnection,
  status?: CaseStatus,
  assignedToSolicitorAt?: string | null,
  askedClientToPayAt?: string | null,
  statusWhenCancelled?: string | null,
  exportedCaseId?: string | null,
  updatedAt?: string,
};

export type CaseClientKyc = {
  __typename: "CaseClientKyc",
  isSubmitted?: boolean | null,
  isVerified?: boolean | null,
  verificationReport?: KycVerificationReport,
  documentUploads?: Array< string > | null,
  identityUploads?: Array< string > | null,
};

export type File = {
  __typename: "File",
  name?: string,
  key?: string,
  lastModified?: string,
  size?: number,
};

export type CaseDetails = {
  __typename: "CaseDetails",
  type?: CaseDetailsType,
  isTimeSensitive?: boolean | null,
  details?: string,
  summary?: string | null,
  ask?: string | null,
};

export enum CaseDetailsType {
  structured = "structured",
  unstructured = "unstructured",
}


export type ModelMessageConnection = {
  __typename: "ModelMessageConnection",
  items?:  Array<Message | null > | null,
  nextToken?: string | null,
};

export type Message = {
  __typename: "Message",
  id?: string,
  author?: User,
  authorId?: string,
  case?: Case,
  caseId?: string,
  content?: string,
  type?: MessageType,
  createdAt?: string,
  updatedAt?: string,
};

export enum MessageType {
  text = "text",
}


export type ModelCaseQuoteConnection = {
  __typename: "ModelCaseQuoteConnection",
  items?:  Array<CaseQuote | null > | null,
  nextToken?: string | null,
};

export type CaseQuote = {
  __typename: "CaseQuote",
  id?: string,
  case?: Case,
  caseId?: string,
  isLeadFromAds?: boolean | null,
  isManualFee?: boolean | null,
  lineItems?: ModelCaseLineItemConnection,
  payments?: ModelCasePaymentConnection,
  createdAt?: string,
  updatedAt?: string,
};

export type ModelCaseLineItemConnection = {
  __typename: "ModelCaseLineItemConnection",
  items?:  Array<CaseLineItem | null > | null,
  nextToken?: string | null,
};

export type CaseLineItem = {
  __typename: "CaseLineItem",
  id?: string,
  quote?: CaseQuote,
  quoteId?: string,
  caseLineItemProductId?: string | null,
  product?: Product,
  description?: string | null,
  clientPays?: number,
  solicitorReceives?: number,
  subsidy?: number,
  platformFee?: number,
  feePercentage?: number,
  scope?: CaseScope,
  createdAt?: string,
  updatedAt?: string,
};

export type Product = {
  __typename: "Product",
  id?: string,
  sku?: string,
  title?: string,
  description?: string,
  lineItems?: ModelCaseLineItemConnection,
  createdAt?: string,
  updatedAt?: string,
};

export type CaseScope = {
  __typename: "CaseScope",
  includes?: Array< string >,
  excludes?: Array< string >,
};

export type ModelCasePaymentConnection = {
  __typename: "ModelCasePaymentConnection",
  items?:  Array<CasePayment | null > | null,
  nextToken?: string | null,
};

export type CasePayment = {
  __typename: "CasePayment",
  id?: string,
  caseId?: string,
  clientId?: string,
  quoteId?: string | null,
  type?: CasePaymentType,
  stripeAccountId?: string,
  stripePaymentIntentId?: string,
  toPlatform?: boolean,
  amount?: number,
  refunded?: boolean | null,
  solicitorId?: string | null,
  chargeType?: ChargeType | null,
  createdAt?: string,
  updatedAt?: string,
};

export enum CasePaymentType {
  deposit = "deposit",
  payment = "payment",
}


export enum ChargeType {
  direct = "direct",
  destination = "destination",
}


export type Solicitor = {
  __typename: "Solicitor",
  id?: string,
  user?: User,
  name?: string,
  businessName?: string,
  sraRegistrationNumber?: string | null,
  stripeAccountId?: string | null,
  onboardingStatus?: SolicitorOnboardingStatus,
  createdAt?: string,
  updatedAt?: string,
};

export type SolicitorOnboardingStatus = {
  __typename: "SolicitorOnboardingStatus",
  hasAccount?: boolean,
  accountType?: SolicitorAccountType,
  hasChargesEnabled?: boolean,
  hasSubmitDetails?: boolean,
  hasPayoutsEnabled?: boolean,
};

export enum SolicitorAccountType {
  none = "none",
  express = "express",
  standard = "standard",
}


export type SolicitorResponse = {
  __typename: "SolicitorResponse",
  type?: SolicitorResponseType,
  solicitorId?: string,
  reason?: string | null,
};

export enum SolicitorResponseType {
  accepted = "accepted",
  rejected = "rejected",
}


export type CaseCompletion = {
  __typename: "CaseCompletion",
  type?: CaseCompletionType | null,
  details?: string | null,
};

export enum CaseCompletionType {
  success = "success",
  refund = "refund",
}


export type ModelReviewConnection = {
  __typename: "ModelReviewConnection",
  items?:  Array<Review | null > | null,
  nextToken?: string | null,
};

export type Review = {
  __typename: "Review",
  id?: string,
  solicitorId?: string,
  caseId?: string,
  clientId?: string,
  rating?: number,
  comment?: string | null,
  createdAt?: string,
  updatedAt?: string,
};

export enum CaseStatus {
  needsDetails = "needsDetails",
  needsPrice = "needsPrice",
  needsSolicitorAssignment = "needsSolicitorAssignment",
  needsSolicitorApproval = "needsSolicitorApproval",
  needsClientPayment = "needsClientPayment",
  active = "active",
  completed = "completed",
  reviewed = "reviewed",
  cancelled = "cancelled",
  exported = "exported",
  pendingExport = "pendingExport",
}


export type CreateCaseCommand = {
  clientId: string,
  brand?: string | null,
  payFirst?: boolean | null,
  alreadyPaid?: boolean | null,
  hasChatbot?: boolean | null,
  productSKU?: string | null,
  context?: string | null,
};

export type UpdateCaseMetadataCommand = {
  caseId: string,
  caseNumber?: string | null,
  brand?: string | null,
  product?: string | null,
  payFirst?: boolean | null,
};

export type SetCaseDescriptionCommand = {
  caseId: string,
  details: CaseDetailsInput,
};

export type CaseDetailsInput = {
  type: CaseDetailsType,
  details: string,
  isTimeSensitive?: boolean | null,
  summary?: string | null,
  ask?: string | null,
};

export type AddQuoteToCaseCommand = {
  caseId: string,
  isLeadFromAds?: boolean | null,
  lineItems: Array< PartialAddLineItemCommand >,
};

export type PartialAddLineItemCommand = {
  description?: string | null,
  productId?: string | null,
  clientPays: number,
  solicitorReceives: number,
  subsidy: number,
  platformFee: number,
  feePercentage: number,
  scope?: CaseScopeInput | null,
};

export type CaseScopeInput = {
  includes: Array< string >,
  excludes: Array< string >,
};

export type AddExistingPaymentToCaseCommand = {
  caseId: string,
  sourceId?: string | null,
  isLeadFromAds?: boolean | null,
  lineItems: Array< PartialAddLineItemCommand >,
};

export type AssignSolicitorToCaseCommand = {
  caseId: string,
  solicitorId: string,
};

export type AddPaymentToCaseCommand = {
  id: string,
  caseId: string,
  clientId: string,
  quoteId: string,
  type: CasePaymentType,
  toPlatform: boolean,
  stripeAccountId: string,
  stripePaymentIntentId: string,
  chargeType?: ChargeType | null,
  amount: number,
};

export type RefundCaseDepositCommand = {
  caseId: string,
  casePaymentId: string,
};

export type CompleteCaseCommand = {
  caseId: string,
  details?: string | null,
  sendClientEmail?: boolean | null,
};

export type RefundCaseCommand = {
  caseId: string,
  refundPayments: Array< string >,
  details?: string | null,
};

export type CancelCaseCommand = {
  caseId: string,
  sendClientEmail?: boolean | null,
};

export type UnassignSolicitorCommand = {
  caseId: string,
  sendNotification?: boolean | null,
};

export type LeaveReviewCommand = {
  caseId: string,
  solicitorId: string,
  clientId: string,
  rating: number,
  comment?: string | null,
};

export type DownloadFileCommand = {
  key?: string | null,
};

export type DownloadCommandResult = {
  __typename: "DownloadCommandResult",
  url?: string,
};

export type RequestKycReportUploadCommand = {
  userId: string,
  filename: string,
};

export type UploadCommandResult = {
  __typename: "UploadCommandResult",
  signature?: string,
};

export type ApproveKycSubmissionCommand = {
  userId: string,
  amlReportFileKey: string,
  kycReportFileKey?: string | null,
};

export type RejectKycSubmissionCommand = {
  userId: string,
  reason?: string | null,
  instructions?: string | null,
};

export type SolicitorAcceptCaseCommand = {
  caseId: string,
  solicitorId: string,
};

export type SolicitorRejectCaseCommand = {
  caseId: string,
  solicitorId: string,
  reason?: string | null,
};

export type SolicitorOnboardToStripeCommand = {
  returnUrl: string,
  refreshUrl: string,
};

export type SolicitorOnboardToStripeCommandOutput = {
  __typename: "SolicitorOnboardToStripeCommandOutput",
  url?: string,
};

export type SolicitorRequestStripeDashboardLinkCommandOutput = {
  __typename: "SolicitorRequestStripeDashboardLinkCommandOutput",
  url?: string,
};

export type SolicitorRequestKycReportDownloadCommand = {
  caseId: string,
  key: string,
};

export type ClientCreateAddCardSessionCommand = {
  returnUrl?: string | null,
  cancelUrl?: string | null,
};

export type ClientCreateAddCardSessionCommandResult = {
  __typename: "ClientCreateAddCardSessionCommandResult",
  sessionId?: string,
};

export type ClientCheckoutQuoteCommand = {
  caseId: string,
  quoteId: string,
  paymentMethodId: string,
};

export type ClientCheckoutQuoteCommandResult = {
  __typename: "ClientCheckoutQuoteCommandResult",
  stripeCheckoutSecret?: string,
  stripeAccountId?: string,
};

export type ClientClaimQuotePaymentCommand = {
  paymentIntentId: string,
  caseId: string,
  quoteId: string,
};

export type ClientClaimQuotePaymentCommandResult = {
  __typename: "ClientClaimQuotePaymentCommandResult",
  caseId?: string,
  quoteId?: string,
  paymentId?: string,
};

export type ClientRequestCaseFileDownloadCommand = {
  caseId: string,
  fileKey: string,
};

export type ClientRequestCaseFileUploadCommand = {
  caseId: string,
  filename: string,
  mimeType?: string | null,
};

export type ClientRequestKycFileUploadCommand = {
  documentType: string,
  filename: string,
};

export type DetachPaymentMethodCommand = {
  paymentMethodId: string,
};

export type RegisterMessageSentCommand = {
  messageId: string,
  caseId: string,
  chatSessionId: string,
  content: string,
};

export type StartChatbotSessionCommand = {
  caseId: string,
  productSKU: string,
  hasMessages: boolean,
  firstName: string,
  context?: string | null,
};

export type StartChatbotSessionCommandResult = {
  __typename: "StartChatbotSessionCommandResult",
  sessionId?: string,
};

export type RequestCaseCompletionCommand = {
  caseId: string,
};

export type RequestCaseCompletionCommandResult = {
  __typename: "RequestCaseCompletionCommandResult",
  id?: string,
};

export type QuoteAddedToCaseEvent = {
  caseId: string,
  quoteId: string,
};

export type EventResult = {
  __typename: "EventResult",
  success?: boolean | null,
};

export type ClientPaidQuoteEvent = {
  clientId: string,
  caseId: string,
  quoteId: string,
  paymentId: string,
  amountPaid: number,
  cardLastFour: string,
  cardScheme: string,
};

export type SolicitorAskedToAcceptCaseEvent = {
  solicitorId: string,
  caseId: string,
};

export type SolicitorAcceptedCaseAssignmentEvent = {
  solicitorId: string,
  caseId: string,
};

export type SolicitorRejectedCaseAssignmentEvent = {
  solicitorId: string,
  caseId: string,
  reason: string,
};

export type ClientSubmittedKycEvent = {
  userId: string,
};

export type RejectedClientKycEvent = {
  userId: string,
  reason: string,
  instructions: string,
};

export type ApprovedClientKycEvent = {
  userId: string,
};

export type CaseCompletedEvent = {
  caseId: string,
  sendClientEmail: boolean,
};

export type CaseCreatedEvent = {
  caseId: string,
  alreadyPaid: boolean,
};

export type CaseCancelledEvent = {
  caseId: string,
  sendClientEmail: boolean,
};

export type SolicitorUnassignedEvent = {
  caseId: string,
  sendNotification: boolean,
};

export type UserRequestedCaseCompletionEvent = {
  caseId: string,
  requesterId: string,
};

export type CreateCaseInput = {
  id?: string | null,
  caseNumber: string,
  brand?: string | null,
  productSKU?: string | null,
  hasChatbot?: boolean | null,
  payFirst?: boolean | null,
  context?: string | null,
  createdAt?: string | null,
  clientId: string,
  clientKyc?: CaseClientKycInput | null,
  files?: Array< FileInput > | null,
  details?: CaseDetailsInput | null,
  caseSolicitorId?: string | null,
  solicitorResponse?: SolicitorResponseInput | null,
  completion?: CaseCompletionInput | null,
  status: CaseStatus,
  assignedToSolicitorAt?: string | null,
  askedClientToPayAt?: string | null,
  statusWhenCancelled?: string | null,
  exportedCaseId?: string | null,
};

export type CaseClientKycInput = {
  isSubmitted?: boolean | null,
  isVerified?: boolean | null,
  verificationReport?: KycVerificationReportInput | null,
  documentUploads?: Array< string > | null,
  identityUploads?: Array< string > | null,
};

export type KycVerificationReportInput = {
  amlReportFileKey: string,
  kycReportFileKey?: string | null,
};

export type FileInput = {
  name: string,
  key: string,
  lastModified: string,
  size: number,
};

export type SolicitorResponseInput = {
  type: SolicitorResponseType,
  solicitorId: string,
  reason?: string | null,
};

export type CaseCompletionInput = {
  type?: CaseCompletionType | null,
  details?: string | null,
};

export type ModelCaseConditionInput = {
  caseNumber?: ModelStringInput | null,
  brand?: ModelStringInput | null,
  productSKU?: ModelStringInput | null,
  hasChatbot?: ModelBooleanInput | null,
  payFirst?: ModelBooleanInput | null,
  context?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  status?: ModelCaseStatusInput | null,
  assignedToSolicitorAt?: ModelStringInput | null,
  askedClientToPayAt?: ModelStringInput | null,
  statusWhenCancelled?: ModelStringInput | null,
  exportedCaseId?: ModelStringInput | null,
  and?: Array< ModelCaseConditionInput | null > | null,
  or?: Array< ModelCaseConditionInput | null > | null,
  not?: ModelCaseConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelCaseStatusInput = {
  eq?: CaseStatus | null,
  ne?: CaseStatus | null,
};

export type UpdateCaseInput = {
  id: string,
  caseNumber?: string | null,
  brand?: string | null,
  productSKU?: string | null,
  hasChatbot?: boolean | null,
  payFirst?: boolean | null,
  context?: string | null,
  createdAt?: string | null,
  clientId?: string | null,
  clientKyc?: CaseClientKycInput | null,
  files?: Array< FileInput > | null,
  details?: CaseDetailsInput | null,
  caseSolicitorId?: string | null,
  solicitorResponse?: SolicitorResponseInput | null,
  completion?: CaseCompletionInput | null,
  status?: CaseStatus | null,
  assignedToSolicitorAt?: string | null,
  askedClientToPayAt?: string | null,
  statusWhenCancelled?: string | null,
  exportedCaseId?: string | null,
};

export type DeleteCaseInput = {
  id?: string | null,
};

export type CreateReviewInput = {
  id?: string | null,
  solicitorId: string,
  caseId: string,
  clientId: string,
  rating: number,
  comment?: string | null,
};

export type ModelReviewConditionInput = {
  solicitorId?: ModelIDInput | null,
  caseId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  rating?: ModelIntInput | null,
  comment?: ModelStringInput | null,
  and?: Array< ModelReviewConditionInput | null > | null,
  or?: Array< ModelReviewConditionInput | null > | null,
  not?: ModelReviewConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateReviewInput = {
  id: string,
  solicitorId?: string | null,
  caseId?: string | null,
  clientId?: string | null,
  rating?: number | null,
  comment?: string | null,
};

export type DeleteReviewInput = {
  id?: string | null,
};

export type CreateCasePaymentInput = {
  id?: string | null,
  caseId: string,
  clientId: string,
  quoteId?: string | null,
  type: CasePaymentType,
  stripeAccountId: string,
  stripePaymentIntentId: string,
  toPlatform: boolean,
  amount: number,
  refunded?: boolean | null,
  solicitorId?: string | null,
  chargeType?: ChargeType | null,
};

export type ModelCasePaymentConditionInput = {
  caseId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  quoteId?: ModelIDInput | null,
  type?: ModelCasePaymentTypeInput | null,
  stripeAccountId?: ModelIDInput | null,
  stripePaymentIntentId?: ModelIDInput | null,
  toPlatform?: ModelBooleanInput | null,
  amount?: ModelIntInput | null,
  refunded?: ModelBooleanInput | null,
  solicitorId?: ModelIDInput | null,
  chargeType?: ModelChargeTypeInput | null,
  and?: Array< ModelCasePaymentConditionInput | null > | null,
  or?: Array< ModelCasePaymentConditionInput | null > | null,
  not?: ModelCasePaymentConditionInput | null,
};

export type ModelCasePaymentTypeInput = {
  eq?: CasePaymentType | null,
  ne?: CasePaymentType | null,
};

export type ModelChargeTypeInput = {
  eq?: ChargeType | null,
  ne?: ChargeType | null,
};

export type UpdateCasePaymentInput = {
  id: string,
  caseId?: string | null,
  clientId?: string | null,
  quoteId?: string | null,
  type?: CasePaymentType | null,
  stripeAccountId?: string | null,
  stripePaymentIntentId?: string | null,
  toPlatform?: boolean | null,
  amount?: number | null,
  refunded?: boolean | null,
  solicitorId?: string | null,
  chargeType?: ChargeType | null,
};

export type DeleteCasePaymentInput = {
  id?: string | null,
};

export type CreateCaseQuoteInput = {
  id?: string | null,
  caseId: string,
  isLeadFromAds?: boolean | null,
  isManualFee?: boolean | null,
};

export type ModelCaseQuoteConditionInput = {
  caseId?: ModelIDInput | null,
  isLeadFromAds?: ModelBooleanInput | null,
  isManualFee?: ModelBooleanInput | null,
  and?: Array< ModelCaseQuoteConditionInput | null > | null,
  or?: Array< ModelCaseQuoteConditionInput | null > | null,
  not?: ModelCaseQuoteConditionInput | null,
};

export type UpdateCaseQuoteInput = {
  id: string,
  caseId?: string | null,
  isLeadFromAds?: boolean | null,
  isManualFee?: boolean | null,
};

export type DeleteCaseQuoteInput = {
  id?: string | null,
};

export type CreateCaseLineItemInput = {
  id?: string | null,
  quoteId: string,
  caseLineItemProductId?: string | null,
  description?: string | null,
  clientPays: number,
  solicitorReceives: number,
  subsidy: number,
  platformFee: number,
  feePercentage: number,
  scope?: CaseScopeInput | null,
};

export type ModelCaseLineItemConditionInput = {
  quoteId?: ModelIDInput | null,
  caseLineItemProductId?: ModelIDInput | null,
  description?: ModelStringInput | null,
  clientPays?: ModelIntInput | null,
  solicitorReceives?: ModelIntInput | null,
  subsidy?: ModelIntInput | null,
  platformFee?: ModelIntInput | null,
  feePercentage?: ModelIntInput | null,
  and?: Array< ModelCaseLineItemConditionInput | null > | null,
  or?: Array< ModelCaseLineItemConditionInput | null > | null,
  not?: ModelCaseLineItemConditionInput | null,
};

export type UpdateCaseLineItemInput = {
  id: string,
  quoteId?: string | null,
  caseLineItemProductId?: string | null,
  description?: string | null,
  clientPays?: number | null,
  solicitorReceives?: number | null,
  subsidy?: number | null,
  platformFee?: number | null,
  feePercentage?: number | null,
  scope?: CaseScopeInput | null,
};

export type DeleteCaseLineItemInput = {
  id?: string | null,
};

export type CreateCaseCompletionRequestInput = {
  id?: string | null,
  caseId: string,
  requesterId: string,
  accepted?: boolean | null,
};

export type ModelCaseCompletionRequestConditionInput = {
  caseId?: ModelIDInput | null,
  accepted?: ModelBooleanInput | null,
  and?: Array< ModelCaseCompletionRequestConditionInput | null > | null,
  or?: Array< ModelCaseCompletionRequestConditionInput | null > | null,
  not?: ModelCaseCompletionRequestConditionInput | null,
};

export type CaseCompletionRequest = {
  __typename: "CaseCompletionRequest",
  id?: string,
  caseId?: string,
  case?: Case,
  requesterId?: string,
  requester?: User,
  accepted?: boolean | null,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateCaseCompletionRequestInput = {
  id: string,
  caseId?: string | null,
  requesterId?: string | null,
  accepted?: boolean | null,
};

export type DeleteCaseCompletionRequestInput = {
  id?: string | null,
};

export type CreateConfigurationInput = {
  key: string,
  value: string,
};

export type ModelConfigurationConditionInput = {
  value?: ModelStringInput | null,
  and?: Array< ModelConfigurationConditionInput | null > | null,
  or?: Array< ModelConfigurationConditionInput | null > | null,
  not?: ModelConfigurationConditionInput | null,
};

export type Configuration = {
  __typename: "Configuration",
  key?: string,
  value?: string,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateConfigurationInput = {
  key: string,
  value?: string | null,
};

export type DeleteConfigurationInput = {
  key: string,
};

export type CreateKYCSubmissionInput = {
  userId: string,
  status?: KYCSubmissionStatus | null,
  address?: string | null,
  documentUploads?: Array< string > | null,
  identityUploads?: Array< string > | null,
  verificationReport?: KycVerificationReportInput | null,
  rejectionReason?: KycRejectionReasonInput | null,
};

export type KycRejectionReasonInput = {
  reason?: string | null,
  instructions?: string | null,
};

export type ModelKYCSubmissionConditionInput = {
  status?: ModelKYCSubmissionStatusInput | null,
  address?: ModelStringInput | null,
  documentUploads?: ModelStringInput | null,
  identityUploads?: ModelStringInput | null,
  and?: Array< ModelKYCSubmissionConditionInput | null > | null,
  or?: Array< ModelKYCSubmissionConditionInput | null > | null,
  not?: ModelKYCSubmissionConditionInput | null,
};

export type ModelKYCSubmissionStatusInput = {
  eq?: KYCSubmissionStatus | null,
  ne?: KYCSubmissionStatus | null,
};

export type UpdateKYCSubmissionInput = {
  userId: string,
  status?: KYCSubmissionStatus | null,
  address?: string | null,
  documentUploads?: Array< string > | null,
  identityUploads?: Array< string > | null,
  verificationReport?: KycVerificationReportInput | null,
  rejectionReason?: KycRejectionReasonInput | null,
};

export type DeleteKYCSubmissionInput = {
  userId: string,
};

export type CreateMessageInput = {
  id?: string | null,
  authorId: string,
  caseId: string,
  content: string,
  type: MessageType,
  createdAt?: string | null,
};

export type ModelMessageConditionInput = {
  caseId?: ModelIDInput | null,
  content?: ModelStringInput | null,
  type?: ModelMessageTypeInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelMessageConditionInput | null > | null,
  or?: Array< ModelMessageConditionInput | null > | null,
  not?: ModelMessageConditionInput | null,
};

export type ModelMessageTypeInput = {
  eq?: MessageType | null,
  ne?: MessageType | null,
};

export type UpdateMessageInput = {
  id: string,
  authorId?: string | null,
  caseId?: string | null,
  content?: string | null,
  type?: MessageType | null,
  createdAt?: string | null,
};

export type DeleteMessageInput = {
  id?: string | null,
};

export type CreateNotifcationEmailInput = {
  id?: string | null,
  userId: string,
  caseId: string,
  type?: NotificationType | null,
  lastSentAt?: string | null,
};

export enum NotificationType {
  solicitorNeedsToAcceptReminder = "solicitorNeedsToAcceptReminder",
  clientNeedsToPayReminder = "clientNeedsToPayReminder",
  solicitorNeedsToRespondToMessageReminder = "solicitorNeedsToRespondToMessageReminder",
  messageReceived = "messageReceived",
  fileUploaded = "fileUploaded",
}


export type ModelNotifcationEmailConditionInput = {
  userId?: ModelIDInput | null,
  caseId?: ModelIDInput | null,
  type?: ModelNotificationTypeInput | null,
  lastSentAt?: ModelStringInput | null,
  and?: Array< ModelNotifcationEmailConditionInput | null > | null,
  or?: Array< ModelNotifcationEmailConditionInput | null > | null,
  not?: ModelNotifcationEmailConditionInput | null,
};

export type ModelNotificationTypeInput = {
  eq?: NotificationType | null,
  ne?: NotificationType | null,
};

export type NotifcationEmail = {
  __typename: "NotifcationEmail",
  id?: string,
  user?: User,
  userId?: string,
  caseId?: string,
  case?: Case,
  type?: NotificationType | null,
  lastSentAt?: string | null,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateNotifcationEmailInput = {
  id: string,
  userId?: string | null,
  caseId?: string | null,
  type?: NotificationType | null,
  lastSentAt?: string | null,
};

export type DeleteNotifcationEmailInput = {
  id?: string | null,
};

export type CreateProductInput = {
  id?: string | null,
  sku: string,
  title: string,
  description: string,
};

export type ModelProductConditionInput = {
  sku?: ModelStringInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelProductConditionInput | null > | null,
  or?: Array< ModelProductConditionInput | null > | null,
  not?: ModelProductConditionInput | null,
};

export type UpdateProductInput = {
  id: string,
  sku?: string | null,
  title?: string | null,
  description?: string | null,
};

export type DeleteProductInput = {
  id?: string | null,
};

export type CreateSolicitorInput = {
  id?: string | null,
  name: string,
  businessName: string,
  sraRegistrationNumber?: string | null,
  stripeAccountId?: string | null,
  onboardingStatus?: SolicitorOnboardingStatusInput | null,
};

export type SolicitorOnboardingStatusInput = {
  hasAccount: boolean,
  accountType: SolicitorAccountType,
  hasChargesEnabled: boolean,
  hasSubmitDetails: boolean,
  hasPayoutsEnabled: boolean,
};

export type ModelSolicitorConditionInput = {
  name?: ModelStringInput | null,
  businessName?: ModelStringInput | null,
  sraRegistrationNumber?: ModelStringInput | null,
  stripeAccountId?: ModelStringInput | null,
  and?: Array< ModelSolicitorConditionInput | null > | null,
  or?: Array< ModelSolicitorConditionInput | null > | null,
  not?: ModelSolicitorConditionInput | null,
};

export type UpdateSolicitorInput = {
  id: string,
  name?: string | null,
  businessName?: string | null,
  sraRegistrationNumber?: string | null,
  stripeAccountId?: string | null,
  onboardingStatus?: SolicitorOnboardingStatusInput | null,
};

export type DeleteSolicitorInput = {
  id?: string | null,
};

export type CreateUserInput = {
  id?: string | null,
  type?: UserType | null,
  emailAddress?: string | null,
  displayName?: string | null,
  phoneNumber?: string | null,
  stripeCustomerId?: string | null,
  paymentMethods?: Array< PaymentMethodInput > | null,
  referral?: string | null,
};

export type PaymentMethodInput = {
  id: string,
  lastFour: string,
  brand: string,
  expiryMonth: number,
  expiryYear: number,
};

export type ModelUserConditionInput = {
  type?: ModelUserTypeInput | null,
  emailAddress?: ModelStringInput | null,
  displayName?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  stripeCustomerId?: ModelStringInput | null,
  referral?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelUserTypeInput = {
  eq?: UserType | null,
  ne?: UserType | null,
};

export type UpdateUserInput = {
  id: string,
  type?: UserType | null,
  emailAddress?: string | null,
  displayName?: string | null,
  phoneNumber?: string | null,
  stripeCustomerId?: string | null,
  paymentMethods?: Array< PaymentMethodInput > | null,
  referral?: string | null,
};

export type DeleteUserInput = {
  id?: string | null,
};

export type GetAddressFromPostcodeQueryResult = {
  __typename: "GetAddressFromPostcodeQueryResult",
  addresses?:  Array<AddressLookupResult >,
};

export type AddressLookupResult = {
  __typename: "AddressLookupResult",
  display?: string,
};

export type GetIntercomTokenResult = {
  __typename: "GetIntercomTokenResult",
  token?: string,
};

export type ModelCaseFilterInput = {
  id?: ModelIDInput | null,
  caseNumber?: ModelStringInput | null,
  brand?: ModelStringInput | null,
  productSKU?: ModelStringInput | null,
  hasChatbot?: ModelBooleanInput | null,
  payFirst?: ModelBooleanInput | null,
  context?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  clientId?: ModelIDInput | null,
  caseSolicitorId?: ModelIDInput | null,
  status?: ModelCaseStatusInput | null,
  assignedToSolicitorAt?: ModelStringInput | null,
  askedClientToPayAt?: ModelStringInput | null,
  statusWhenCancelled?: ModelStringInput | null,
  exportedCaseId?: ModelStringInput | null,
  and?: Array< ModelCaseFilterInput | null > | null,
  or?: Array< ModelCaseFilterInput | null > | null,
  not?: ModelCaseFilterInput | null,
};

export type ModelReviewFilterInput = {
  id?: ModelIDInput | null,
  solicitorId?: ModelIDInput | null,
  caseId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  rating?: ModelIntInput | null,
  comment?: ModelStringInput | null,
  and?: Array< ModelReviewFilterInput | null > | null,
  or?: Array< ModelReviewFilterInput | null > | null,
  not?: ModelReviewFilterInput | null,
};

export type ModelCasePaymentFilterInput = {
  id?: ModelIDInput | null,
  caseId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  quoteId?: ModelIDInput | null,
  type?: ModelCasePaymentTypeInput | null,
  stripeAccountId?: ModelIDInput | null,
  stripePaymentIntentId?: ModelIDInput | null,
  toPlatform?: ModelBooleanInput | null,
  amount?: ModelIntInput | null,
  refunded?: ModelBooleanInput | null,
  solicitorId?: ModelIDInput | null,
  chargeType?: ModelChargeTypeInput | null,
  and?: Array< ModelCasePaymentFilterInput | null > | null,
  or?: Array< ModelCasePaymentFilterInput | null > | null,
  not?: ModelCasePaymentFilterInput | null,
};

export type ModelCaseCompletionRequestFilterInput = {
  id?: ModelIDInput | null,
  caseId?: ModelIDInput | null,
  requesterId?: ModelIDInput | null,
  accepted?: ModelBooleanInput | null,
  and?: Array< ModelCaseCompletionRequestFilterInput | null > | null,
  or?: Array< ModelCaseCompletionRequestFilterInput | null > | null,
  not?: ModelCaseCompletionRequestFilterInput | null,
};

export type ModelCaseCompletionRequestConnection = {
  __typename: "ModelCaseCompletionRequestConnection",
  items?:  Array<CaseCompletionRequest | null > | null,
  nextToken?: string | null,
};

export type ModelConfigurationFilterInput = {
  key?: ModelStringInput | null,
  value?: ModelStringInput | null,
  and?: Array< ModelConfigurationFilterInput | null > | null,
  or?: Array< ModelConfigurationFilterInput | null > | null,
  not?: ModelConfigurationFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelConfigurationConnection = {
  __typename: "ModelConfigurationConnection",
  items?:  Array<Configuration | null > | null,
  nextToken?: string | null,
};

export type ModelKYCSubmissionFilterInput = {
  userId?: ModelIDInput | null,
  status?: ModelKYCSubmissionStatusInput | null,
  address?: ModelStringInput | null,
  documentUploads?: ModelStringInput | null,
  identityUploads?: ModelStringInput | null,
  and?: Array< ModelKYCSubmissionFilterInput | null > | null,
  or?: Array< ModelKYCSubmissionFilterInput | null > | null,
  not?: ModelKYCSubmissionFilterInput | null,
};

export type ModelKYCSubmissionConnection = {
  __typename: "ModelKYCSubmissionConnection",
  items?:  Array<KYCSubmission | null > | null,
  nextToken?: string | null,
};

export type ModelNotifcationEmailFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  caseId?: ModelIDInput | null,
  type?: ModelNotificationTypeInput | null,
  lastSentAt?: ModelStringInput | null,
  and?: Array< ModelNotifcationEmailFilterInput | null > | null,
  or?: Array< ModelNotifcationEmailFilterInput | null > | null,
  not?: ModelNotifcationEmailFilterInput | null,
};

export type ModelNotifcationEmailConnection = {
  __typename: "ModelNotifcationEmailConnection",
  items?:  Array<NotifcationEmail | null > | null,
  nextToken?: string | null,
};

export type ModelProductFilterInput = {
  id?: ModelIDInput | null,
  sku?: ModelStringInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelProductFilterInput | null > | null,
  or?: Array< ModelProductFilterInput | null > | null,
  not?: ModelProductFilterInput | null,
};

export type ModelProductConnection = {
  __typename: "ModelProductConnection",
  items?:  Array<Product | null > | null,
  nextToken?: string | null,
};

export type ModelSolicitorFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  businessName?: ModelStringInput | null,
  sraRegistrationNumber?: ModelStringInput | null,
  stripeAccountId?: ModelStringInput | null,
  and?: Array< ModelSolicitorFilterInput | null > | null,
  or?: Array< ModelSolicitorFilterInput | null > | null,
  not?: ModelSolicitorFilterInput | null,
};

export type ModelSolicitorConnection = {
  __typename: "ModelSolicitorConnection",
  items?:  Array<Solicitor | null > | null,
  nextToken?: string | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelUserTypeInput | null,
  emailAddress?: ModelStringInput | null,
  displayName?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  stripeCustomerId?: ModelStringInput | null,
  referral?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items?:  Array<User | null > | null,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelMessageFilterInput = {
  id?: ModelIDInput | null,
  authorId?: ModelIDInput | null,
  caseId?: ModelIDInput | null,
  content?: ModelStringInput | null,
  type?: ModelMessageTypeInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelMessageFilterInput | null > | null,
  or?: Array< ModelMessageFilterInput | null > | null,
  not?: ModelMessageFilterInput | null,
};

export type ModelNotifcationEmailNotificationEmailByCaseUserTypeCompositeKeyConditionInput = {
  eq?: ModelNotifcationEmailNotificationEmailByCaseUserTypeCompositeKeyInput | null,
  le?: ModelNotifcationEmailNotificationEmailByCaseUserTypeCompositeKeyInput | null,
  lt?: ModelNotifcationEmailNotificationEmailByCaseUserTypeCompositeKeyInput | null,
  ge?: ModelNotifcationEmailNotificationEmailByCaseUserTypeCompositeKeyInput | null,
  gt?: ModelNotifcationEmailNotificationEmailByCaseUserTypeCompositeKeyInput | null,
  between?: Array< ModelNotifcationEmailNotificationEmailByCaseUserTypeCompositeKeyInput | null > | null,
  beginsWith?: ModelNotifcationEmailNotificationEmailByCaseUserTypeCompositeKeyInput | null,
};

export type ModelNotifcationEmailNotificationEmailByCaseUserTypeCompositeKeyInput = {
  userId?: string | null,
  type?: NotificationType | null,
};

export type CreateClientCommandMutationVariables = {
  input?: CreateClientCommand,
};

export type CreateClientCommandMutation = {
  createClientCommand?:  {
    __typename: "User",
    id: string,
    type?: UserType | null,
    emailAddress?: string | null,
    displayName?: string | null,
    phoneNumber?: string | null,
    kycSubmission?:  {
      __typename: "KYCSubmission",
      userId: string,
      user:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      status?: KYCSubmissionStatus | null,
      address?: string | null,
      documentUploads?: Array< string > | null,
      identityUploads?: Array< string > | null,
      verificationReport?:  {
        __typename: "KycVerificationReport",
        amlReportFileKey: string,
        kycReportFileKey?: string | null,
      } | null,
      rejectionReason?:  {
        __typename: "KycRejectionReason",
        reason?: string | null,
        instructions?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    stripeCustomerId?: string | null,
    paymentMethods?:  Array< {
      __typename: "PaymentMethod",
      id: string,
      lastFour: string,
      brand: string,
      expiryMonth: number,
      expiryYear: number,
    } > | null,
    clientCases?:  {
      __typename: "ModelCaseConnection",
      items?:  Array< {
        __typename: "Case",
        id: string,
        caseNumber: string,
        brand?: string | null,
        productSKU?: string | null,
        hasChatbot?: boolean | null,
        payFirst?: boolean | null,
        context?: string | null,
        createdAt: string,
        clientId: string,
        client?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientKyc?:  {
          __typename: "CaseClientKyc",
          isSubmitted?: boolean | null,
          isVerified?: boolean | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
        } | null,
        files?:  Array< {
          __typename: "File",
          name: string,
          key: string,
          lastModified: string,
          size: number,
        } > | null,
        details?:  {
          __typename: "CaseDetails",
          type: CaseDetailsType,
          isTimeSensitive?: boolean | null,
          details: string,
          summary?: string | null,
          ask?: string | null,
        } | null,
        messages?:  {
          __typename: "ModelMessageConnection",
          items?:  Array< {
            __typename: "Message",
            id: string,
            author?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            authorId: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            content: string,
            type: MessageType,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        quotes?:  {
          __typename: "ModelCaseQuoteConnection",
          items?:  Array< {
            __typename: "CaseQuote",
            id: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            isLeadFromAds?: boolean | null,
            isManualFee?: boolean | null,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        caseSolicitorId?: string | null,
        solicitor?:  {
          __typename: "Solicitor",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          name: string,
          businessName: string,
          sraRegistrationNumber?: string | null,
          stripeAccountId?: string | null,
          onboardingStatus?:  {
            __typename: "SolicitorOnboardingStatus",
            hasAccount: boolean,
            accountType: SolicitorAccountType,
            hasChargesEnabled: boolean,
            hasSubmitDetails: boolean,
            hasPayoutsEnabled: boolean,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        solicitorResponse?:  {
          __typename: "SolicitorResponse",
          type: SolicitorResponseType,
          solicitorId: string,
          reason?: string | null,
        } | null,
        completion?:  {
          __typename: "CaseCompletion",
          type?: CaseCompletionType | null,
          details?: string | null,
        } | null,
        review?:  {
          __typename: "ModelReviewConnection",
          items?:  Array< {
            __typename: "Review",
            id: string,
            solicitorId: string,
            caseId: string,
            clientId: string,
            rating: number,
            comment?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        status: CaseStatus,
        assignedToSolicitorAt?: string | null,
        askedClientToPayAt?: string | null,
        statusWhenCancelled?: string | null,
        exportedCaseId?: string | null,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    solicitorCases?:  {
      __typename: "ModelCaseConnection",
      items?:  Array< {
        __typename: "Case",
        id: string,
        caseNumber: string,
        brand?: string | null,
        productSKU?: string | null,
        hasChatbot?: boolean | null,
        payFirst?: boolean | null,
        context?: string | null,
        createdAt: string,
        clientId: string,
        client?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientKyc?:  {
          __typename: "CaseClientKyc",
          isSubmitted?: boolean | null,
          isVerified?: boolean | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
        } | null,
        files?:  Array< {
          __typename: "File",
          name: string,
          key: string,
          lastModified: string,
          size: number,
        } > | null,
        details?:  {
          __typename: "CaseDetails",
          type: CaseDetailsType,
          isTimeSensitive?: boolean | null,
          details: string,
          summary?: string | null,
          ask?: string | null,
        } | null,
        messages?:  {
          __typename: "ModelMessageConnection",
          items?:  Array< {
            __typename: "Message",
            id: string,
            author?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            authorId: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            content: string,
            type: MessageType,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        quotes?:  {
          __typename: "ModelCaseQuoteConnection",
          items?:  Array< {
            __typename: "CaseQuote",
            id: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            isLeadFromAds?: boolean | null,
            isManualFee?: boolean | null,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        caseSolicitorId?: string | null,
        solicitor?:  {
          __typename: "Solicitor",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          name: string,
          businessName: string,
          sraRegistrationNumber?: string | null,
          stripeAccountId?: string | null,
          onboardingStatus?:  {
            __typename: "SolicitorOnboardingStatus",
            hasAccount: boolean,
            accountType: SolicitorAccountType,
            hasChargesEnabled: boolean,
            hasSubmitDetails: boolean,
            hasPayoutsEnabled: boolean,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        solicitorResponse?:  {
          __typename: "SolicitorResponse",
          type: SolicitorResponseType,
          solicitorId: string,
          reason?: string | null,
        } | null,
        completion?:  {
          __typename: "CaseCompletion",
          type?: CaseCompletionType | null,
          details?: string | null,
        } | null,
        review?:  {
          __typename: "ModelReviewConnection",
          items?:  Array< {
            __typename: "Review",
            id: string,
            solicitorId: string,
            caseId: string,
            clientId: string,
            rating: number,
            comment?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        status: CaseStatus,
        assignedToSolicitorAt?: string | null,
        askedClientToPayAt?: string | null,
        statusWhenCancelled?: string | null,
        exportedCaseId?: string | null,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    referral?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCaseCommandMutationVariables = {
  input?: CreateCaseCommand,
};

export type CreateCaseCommandMutation = {
  createCaseCommand?:  {
    __typename: "Case",
    id: string,
    caseNumber: string,
    brand?: string | null,
    productSKU?: string | null,
    hasChatbot?: boolean | null,
    payFirst?: boolean | null,
    context?: string | null,
    createdAt: string,
    clientId: string,
    client?:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    clientKyc?:  {
      __typename: "CaseClientKyc",
      isSubmitted?: boolean | null,
      isVerified?: boolean | null,
      verificationReport?:  {
        __typename: "KycVerificationReport",
        amlReportFileKey: string,
        kycReportFileKey?: string | null,
      } | null,
      documentUploads?: Array< string > | null,
      identityUploads?: Array< string > | null,
    } | null,
    files?:  Array< {
      __typename: "File",
      name: string,
      key: string,
      lastModified: string,
      size: number,
    } > | null,
    details?:  {
      __typename: "CaseDetails",
      type: CaseDetailsType,
      isTimeSensitive?: boolean | null,
      details: string,
      summary?: string | null,
      ask?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items?:  Array< {
        __typename: "Message",
        id: string,
        author?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        authorId: string,
        case:  {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        },
        caseId: string,
        content: string,
        type: MessageType,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    quotes?:  {
      __typename: "ModelCaseQuoteConnection",
      items?:  Array< {
        __typename: "CaseQuote",
        id: string,
        case:  {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        },
        caseId: string,
        isLeadFromAds?: boolean | null,
        isManualFee?: boolean | null,
        lineItems?:  {
          __typename: "ModelCaseLineItemConnection",
          items?:  Array< {
            __typename: "CaseLineItem",
            id: string,
            quote:  {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            },
            quoteId: string,
            caseLineItemProductId?: string | null,
            product?:  {
              __typename: "Product",
              id: string,
              sku: string,
              title: string,
              description: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            description?: string | null,
            clientPays: number,
            solicitorReceives: number,
            subsidy: number,
            platformFee: number,
            feePercentage: number,
            scope?:  {
              __typename: "CaseScope",
              includes: Array< string >,
              excludes: Array< string >,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    payments?:  {
      __typename: "ModelCasePaymentConnection",
      items?:  Array< {
        __typename: "CasePayment",
        id: string,
        caseId: string,
        clientId: string,
        quoteId?: string | null,
        type: CasePaymentType,
        stripeAccountId: string,
        stripePaymentIntentId: string,
        toPlatform: boolean,
        amount: number,
        refunded?: boolean | null,
        solicitorId?: string | null,
        chargeType?: ChargeType | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    caseSolicitorId?: string | null,
    solicitor?:  {
      __typename: "Solicitor",
      id: string,
      user?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      name: string,
      businessName: string,
      sraRegistrationNumber?: string | null,
      stripeAccountId?: string | null,
      onboardingStatus?:  {
        __typename: "SolicitorOnboardingStatus",
        hasAccount: boolean,
        accountType: SolicitorAccountType,
        hasChargesEnabled: boolean,
        hasSubmitDetails: boolean,
        hasPayoutsEnabled: boolean,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    solicitorResponse?:  {
      __typename: "SolicitorResponse",
      type: SolicitorResponseType,
      solicitorId: string,
      reason?: string | null,
    } | null,
    completion?:  {
      __typename: "CaseCompletion",
      type?: CaseCompletionType | null,
      details?: string | null,
    } | null,
    review?:  {
      __typename: "ModelReviewConnection",
      items?:  Array< {
        __typename: "Review",
        id: string,
        solicitorId: string,
        caseId: string,
        clientId: string,
        rating: number,
        comment?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    status: CaseStatus,
    assignedToSolicitorAt?: string | null,
    askedClientToPayAt?: string | null,
    statusWhenCancelled?: string | null,
    exportedCaseId?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateCaseMetadataCommandMutationVariables = {
  input?: UpdateCaseMetadataCommand,
};

export type UpdateCaseMetadataCommandMutation = {
  updateCaseMetadataCommand?:  {
    __typename: "Case",
    id: string,
    caseNumber: string,
    brand?: string | null,
    productSKU?: string | null,
    hasChatbot?: boolean | null,
    payFirst?: boolean | null,
    context?: string | null,
    createdAt: string,
    clientId: string,
    client?:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    clientKyc?:  {
      __typename: "CaseClientKyc",
      isSubmitted?: boolean | null,
      isVerified?: boolean | null,
      verificationReport?:  {
        __typename: "KycVerificationReport",
        amlReportFileKey: string,
        kycReportFileKey?: string | null,
      } | null,
      documentUploads?: Array< string > | null,
      identityUploads?: Array< string > | null,
    } | null,
    files?:  Array< {
      __typename: "File",
      name: string,
      key: string,
      lastModified: string,
      size: number,
    } > | null,
    details?:  {
      __typename: "CaseDetails",
      type: CaseDetailsType,
      isTimeSensitive?: boolean | null,
      details: string,
      summary?: string | null,
      ask?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items?:  Array< {
        __typename: "Message",
        id: string,
        author?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        authorId: string,
        case:  {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        },
        caseId: string,
        content: string,
        type: MessageType,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    quotes?:  {
      __typename: "ModelCaseQuoteConnection",
      items?:  Array< {
        __typename: "CaseQuote",
        id: string,
        case:  {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        },
        caseId: string,
        isLeadFromAds?: boolean | null,
        isManualFee?: boolean | null,
        lineItems?:  {
          __typename: "ModelCaseLineItemConnection",
          items?:  Array< {
            __typename: "CaseLineItem",
            id: string,
            quote:  {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            },
            quoteId: string,
            caseLineItemProductId?: string | null,
            product?:  {
              __typename: "Product",
              id: string,
              sku: string,
              title: string,
              description: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            description?: string | null,
            clientPays: number,
            solicitorReceives: number,
            subsidy: number,
            platformFee: number,
            feePercentage: number,
            scope?:  {
              __typename: "CaseScope",
              includes: Array< string >,
              excludes: Array< string >,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    payments?:  {
      __typename: "ModelCasePaymentConnection",
      items?:  Array< {
        __typename: "CasePayment",
        id: string,
        caseId: string,
        clientId: string,
        quoteId?: string | null,
        type: CasePaymentType,
        stripeAccountId: string,
        stripePaymentIntentId: string,
        toPlatform: boolean,
        amount: number,
        refunded?: boolean | null,
        solicitorId?: string | null,
        chargeType?: ChargeType | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    caseSolicitorId?: string | null,
    solicitor?:  {
      __typename: "Solicitor",
      id: string,
      user?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      name: string,
      businessName: string,
      sraRegistrationNumber?: string | null,
      stripeAccountId?: string | null,
      onboardingStatus?:  {
        __typename: "SolicitorOnboardingStatus",
        hasAccount: boolean,
        accountType: SolicitorAccountType,
        hasChargesEnabled: boolean,
        hasSubmitDetails: boolean,
        hasPayoutsEnabled: boolean,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    solicitorResponse?:  {
      __typename: "SolicitorResponse",
      type: SolicitorResponseType,
      solicitorId: string,
      reason?: string | null,
    } | null,
    completion?:  {
      __typename: "CaseCompletion",
      type?: CaseCompletionType | null,
      details?: string | null,
    } | null,
    review?:  {
      __typename: "ModelReviewConnection",
      items?:  Array< {
        __typename: "Review",
        id: string,
        solicitorId: string,
        caseId: string,
        clientId: string,
        rating: number,
        comment?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    status: CaseStatus,
    assignedToSolicitorAt?: string | null,
    askedClientToPayAt?: string | null,
    statusWhenCancelled?: string | null,
    exportedCaseId?: string | null,
    updatedAt: string,
  } | null,
};

export type SetCaseDescriptionCommandMutationVariables = {
  input?: SetCaseDescriptionCommand,
};

export type SetCaseDescriptionCommandMutation = {
  setCaseDescriptionCommand?:  {
    __typename: "Case",
    id: string,
    caseNumber: string,
    brand?: string | null,
    productSKU?: string | null,
    hasChatbot?: boolean | null,
    payFirst?: boolean | null,
    context?: string | null,
    createdAt: string,
    clientId: string,
    client?:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    clientKyc?:  {
      __typename: "CaseClientKyc",
      isSubmitted?: boolean | null,
      isVerified?: boolean | null,
      verificationReport?:  {
        __typename: "KycVerificationReport",
        amlReportFileKey: string,
        kycReportFileKey?: string | null,
      } | null,
      documentUploads?: Array< string > | null,
      identityUploads?: Array< string > | null,
    } | null,
    files?:  Array< {
      __typename: "File",
      name: string,
      key: string,
      lastModified: string,
      size: number,
    } > | null,
    details?:  {
      __typename: "CaseDetails",
      type: CaseDetailsType,
      isTimeSensitive?: boolean | null,
      details: string,
      summary?: string | null,
      ask?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items?:  Array< {
        __typename: "Message",
        id: string,
        author?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        authorId: string,
        case:  {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        },
        caseId: string,
        content: string,
        type: MessageType,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    quotes?:  {
      __typename: "ModelCaseQuoteConnection",
      items?:  Array< {
        __typename: "CaseQuote",
        id: string,
        case:  {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        },
        caseId: string,
        isLeadFromAds?: boolean | null,
        isManualFee?: boolean | null,
        lineItems?:  {
          __typename: "ModelCaseLineItemConnection",
          items?:  Array< {
            __typename: "CaseLineItem",
            id: string,
            quote:  {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            },
            quoteId: string,
            caseLineItemProductId?: string | null,
            product?:  {
              __typename: "Product",
              id: string,
              sku: string,
              title: string,
              description: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            description?: string | null,
            clientPays: number,
            solicitorReceives: number,
            subsidy: number,
            platformFee: number,
            feePercentage: number,
            scope?:  {
              __typename: "CaseScope",
              includes: Array< string >,
              excludes: Array< string >,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    payments?:  {
      __typename: "ModelCasePaymentConnection",
      items?:  Array< {
        __typename: "CasePayment",
        id: string,
        caseId: string,
        clientId: string,
        quoteId?: string | null,
        type: CasePaymentType,
        stripeAccountId: string,
        stripePaymentIntentId: string,
        toPlatform: boolean,
        amount: number,
        refunded?: boolean | null,
        solicitorId?: string | null,
        chargeType?: ChargeType | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    caseSolicitorId?: string | null,
    solicitor?:  {
      __typename: "Solicitor",
      id: string,
      user?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      name: string,
      businessName: string,
      sraRegistrationNumber?: string | null,
      stripeAccountId?: string | null,
      onboardingStatus?:  {
        __typename: "SolicitorOnboardingStatus",
        hasAccount: boolean,
        accountType: SolicitorAccountType,
        hasChargesEnabled: boolean,
        hasSubmitDetails: boolean,
        hasPayoutsEnabled: boolean,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    solicitorResponse?:  {
      __typename: "SolicitorResponse",
      type: SolicitorResponseType,
      solicitorId: string,
      reason?: string | null,
    } | null,
    completion?:  {
      __typename: "CaseCompletion",
      type?: CaseCompletionType | null,
      details?: string | null,
    } | null,
    review?:  {
      __typename: "ModelReviewConnection",
      items?:  Array< {
        __typename: "Review",
        id: string,
        solicitorId: string,
        caseId: string,
        clientId: string,
        rating: number,
        comment?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    status: CaseStatus,
    assignedToSolicitorAt?: string | null,
    askedClientToPayAt?: string | null,
    statusWhenCancelled?: string | null,
    exportedCaseId?: string | null,
    updatedAt: string,
  } | null,
};

export type AddQuoteToCaseCommandMutationVariables = {
  input?: AddQuoteToCaseCommand,
};

export type AddQuoteToCaseCommandMutation = {
  addQuoteToCaseCommand?:  {
    __typename: "CaseQuote",
    id: string,
    case:  {
      __typename: "Case",
      id: string,
      caseNumber: string,
      brand?: string | null,
      productSKU?: string | null,
      hasChatbot?: boolean | null,
      payFirst?: boolean | null,
      context?: string | null,
      createdAt: string,
      clientId: string,
      client?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientKyc?:  {
        __typename: "CaseClientKyc",
        isSubmitted?: boolean | null,
        isVerified?: boolean | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
      } | null,
      files?:  Array< {
        __typename: "File",
        name: string,
        key: string,
        lastModified: string,
        size: number,
      } > | null,
      details?:  {
        __typename: "CaseDetails",
        type: CaseDetailsType,
        isTimeSensitive?: boolean | null,
        details: string,
        summary?: string | null,
        ask?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items?:  Array< {
          __typename: "Message",
          id: string,
          author?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          authorId: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          content: string,
          type: MessageType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      quotes?:  {
        __typename: "ModelCaseQuoteConnection",
        items?:  Array< {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      payments?:  {
        __typename: "ModelCasePaymentConnection",
        items?:  Array< {
          __typename: "CasePayment",
          id: string,
          caseId: string,
          clientId: string,
          quoteId?: string | null,
          type: CasePaymentType,
          stripeAccountId: string,
          stripePaymentIntentId: string,
          toPlatform: boolean,
          amount: number,
          refunded?: boolean | null,
          solicitorId?: string | null,
          chargeType?: ChargeType | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      caseSolicitorId?: string | null,
      solicitor?:  {
        __typename: "Solicitor",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        name: string,
        businessName: string,
        sraRegistrationNumber?: string | null,
        stripeAccountId?: string | null,
        onboardingStatus?:  {
          __typename: "SolicitorOnboardingStatus",
          hasAccount: boolean,
          accountType: SolicitorAccountType,
          hasChargesEnabled: boolean,
          hasSubmitDetails: boolean,
          hasPayoutsEnabled: boolean,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      solicitorResponse?:  {
        __typename: "SolicitorResponse",
        type: SolicitorResponseType,
        solicitorId: string,
        reason?: string | null,
      } | null,
      completion?:  {
        __typename: "CaseCompletion",
        type?: CaseCompletionType | null,
        details?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items?:  Array< {
          __typename: "Review",
          id: string,
          solicitorId: string,
          caseId: string,
          clientId: string,
          rating: number,
          comment?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      status: CaseStatus,
      assignedToSolicitorAt?: string | null,
      askedClientToPayAt?: string | null,
      statusWhenCancelled?: string | null,
      exportedCaseId?: string | null,
      updatedAt: string,
    },
    caseId: string,
    isLeadFromAds?: boolean | null,
    isManualFee?: boolean | null,
    lineItems?:  {
      __typename: "ModelCaseLineItemConnection",
      items?:  Array< {
        __typename: "CaseLineItem",
        id: string,
        quote:  {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        quoteId: string,
        caseLineItemProductId?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          sku: string,
          title: string,
          description: string,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        description?: string | null,
        clientPays: number,
        solicitorReceives: number,
        subsidy: number,
        platformFee: number,
        feePercentage: number,
        scope?:  {
          __typename: "CaseScope",
          includes: Array< string >,
          excludes: Array< string >,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    payments?:  {
      __typename: "ModelCasePaymentConnection",
      items?:  Array< {
        __typename: "CasePayment",
        id: string,
        caseId: string,
        clientId: string,
        quoteId?: string | null,
        type: CasePaymentType,
        stripeAccountId: string,
        stripePaymentIntentId: string,
        toPlatform: boolean,
        amount: number,
        refunded?: boolean | null,
        solicitorId?: string | null,
        chargeType?: ChargeType | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type AddExistingPaymentToCaseCommandMutationVariables = {
  input?: AddExistingPaymentToCaseCommand,
};

export type AddExistingPaymentToCaseCommandMutation = {
  addExistingPaymentToCaseCommand?:  {
    __typename: "Case",
    id: string,
    caseNumber: string,
    brand?: string | null,
    productSKU?: string | null,
    hasChatbot?: boolean | null,
    payFirst?: boolean | null,
    context?: string | null,
    createdAt: string,
    clientId: string,
    client?:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    clientKyc?:  {
      __typename: "CaseClientKyc",
      isSubmitted?: boolean | null,
      isVerified?: boolean | null,
      verificationReport?:  {
        __typename: "KycVerificationReport",
        amlReportFileKey: string,
        kycReportFileKey?: string | null,
      } | null,
      documentUploads?: Array< string > | null,
      identityUploads?: Array< string > | null,
    } | null,
    files?:  Array< {
      __typename: "File",
      name: string,
      key: string,
      lastModified: string,
      size: number,
    } > | null,
    details?:  {
      __typename: "CaseDetails",
      type: CaseDetailsType,
      isTimeSensitive?: boolean | null,
      details: string,
      summary?: string | null,
      ask?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items?:  Array< {
        __typename: "Message",
        id: string,
        author?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        authorId: string,
        case:  {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        },
        caseId: string,
        content: string,
        type: MessageType,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    quotes?:  {
      __typename: "ModelCaseQuoteConnection",
      items?:  Array< {
        __typename: "CaseQuote",
        id: string,
        case:  {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        },
        caseId: string,
        isLeadFromAds?: boolean | null,
        isManualFee?: boolean | null,
        lineItems?:  {
          __typename: "ModelCaseLineItemConnection",
          items?:  Array< {
            __typename: "CaseLineItem",
            id: string,
            quote:  {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            },
            quoteId: string,
            caseLineItemProductId?: string | null,
            product?:  {
              __typename: "Product",
              id: string,
              sku: string,
              title: string,
              description: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            description?: string | null,
            clientPays: number,
            solicitorReceives: number,
            subsidy: number,
            platformFee: number,
            feePercentage: number,
            scope?:  {
              __typename: "CaseScope",
              includes: Array< string >,
              excludes: Array< string >,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    payments?:  {
      __typename: "ModelCasePaymentConnection",
      items?:  Array< {
        __typename: "CasePayment",
        id: string,
        caseId: string,
        clientId: string,
        quoteId?: string | null,
        type: CasePaymentType,
        stripeAccountId: string,
        stripePaymentIntentId: string,
        toPlatform: boolean,
        amount: number,
        refunded?: boolean | null,
        solicitorId?: string | null,
        chargeType?: ChargeType | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    caseSolicitorId?: string | null,
    solicitor?:  {
      __typename: "Solicitor",
      id: string,
      user?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      name: string,
      businessName: string,
      sraRegistrationNumber?: string | null,
      stripeAccountId?: string | null,
      onboardingStatus?:  {
        __typename: "SolicitorOnboardingStatus",
        hasAccount: boolean,
        accountType: SolicitorAccountType,
        hasChargesEnabled: boolean,
        hasSubmitDetails: boolean,
        hasPayoutsEnabled: boolean,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    solicitorResponse?:  {
      __typename: "SolicitorResponse",
      type: SolicitorResponseType,
      solicitorId: string,
      reason?: string | null,
    } | null,
    completion?:  {
      __typename: "CaseCompletion",
      type?: CaseCompletionType | null,
      details?: string | null,
    } | null,
    review?:  {
      __typename: "ModelReviewConnection",
      items?:  Array< {
        __typename: "Review",
        id: string,
        solicitorId: string,
        caseId: string,
        clientId: string,
        rating: number,
        comment?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    status: CaseStatus,
    assignedToSolicitorAt?: string | null,
    askedClientToPayAt?: string | null,
    statusWhenCancelled?: string | null,
    exportedCaseId?: string | null,
    updatedAt: string,
  } | null,
};

export type AssignSolicitorToCaseCommandMutationVariables = {
  input?: AssignSolicitorToCaseCommand,
};

export type AssignSolicitorToCaseCommandMutation = {
  assignSolicitorToCaseCommand?:  {
    __typename: "Case",
    id: string,
    caseNumber: string,
    brand?: string | null,
    productSKU?: string | null,
    hasChatbot?: boolean | null,
    payFirst?: boolean | null,
    context?: string | null,
    createdAt: string,
    clientId: string,
    client?:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    clientKyc?:  {
      __typename: "CaseClientKyc",
      isSubmitted?: boolean | null,
      isVerified?: boolean | null,
      verificationReport?:  {
        __typename: "KycVerificationReport",
        amlReportFileKey: string,
        kycReportFileKey?: string | null,
      } | null,
      documentUploads?: Array< string > | null,
      identityUploads?: Array< string > | null,
    } | null,
    files?:  Array< {
      __typename: "File",
      name: string,
      key: string,
      lastModified: string,
      size: number,
    } > | null,
    details?:  {
      __typename: "CaseDetails",
      type: CaseDetailsType,
      isTimeSensitive?: boolean | null,
      details: string,
      summary?: string | null,
      ask?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items?:  Array< {
        __typename: "Message",
        id: string,
        author?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        authorId: string,
        case:  {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        },
        caseId: string,
        content: string,
        type: MessageType,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    quotes?:  {
      __typename: "ModelCaseQuoteConnection",
      items?:  Array< {
        __typename: "CaseQuote",
        id: string,
        case:  {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        },
        caseId: string,
        isLeadFromAds?: boolean | null,
        isManualFee?: boolean | null,
        lineItems?:  {
          __typename: "ModelCaseLineItemConnection",
          items?:  Array< {
            __typename: "CaseLineItem",
            id: string,
            quote:  {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            },
            quoteId: string,
            caseLineItemProductId?: string | null,
            product?:  {
              __typename: "Product",
              id: string,
              sku: string,
              title: string,
              description: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            description?: string | null,
            clientPays: number,
            solicitorReceives: number,
            subsidy: number,
            platformFee: number,
            feePercentage: number,
            scope?:  {
              __typename: "CaseScope",
              includes: Array< string >,
              excludes: Array< string >,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    payments?:  {
      __typename: "ModelCasePaymentConnection",
      items?:  Array< {
        __typename: "CasePayment",
        id: string,
        caseId: string,
        clientId: string,
        quoteId?: string | null,
        type: CasePaymentType,
        stripeAccountId: string,
        stripePaymentIntentId: string,
        toPlatform: boolean,
        amount: number,
        refunded?: boolean | null,
        solicitorId?: string | null,
        chargeType?: ChargeType | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    caseSolicitorId?: string | null,
    solicitor?:  {
      __typename: "Solicitor",
      id: string,
      user?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      name: string,
      businessName: string,
      sraRegistrationNumber?: string | null,
      stripeAccountId?: string | null,
      onboardingStatus?:  {
        __typename: "SolicitorOnboardingStatus",
        hasAccount: boolean,
        accountType: SolicitorAccountType,
        hasChargesEnabled: boolean,
        hasSubmitDetails: boolean,
        hasPayoutsEnabled: boolean,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    solicitorResponse?:  {
      __typename: "SolicitorResponse",
      type: SolicitorResponseType,
      solicitorId: string,
      reason?: string | null,
    } | null,
    completion?:  {
      __typename: "CaseCompletion",
      type?: CaseCompletionType | null,
      details?: string | null,
    } | null,
    review?:  {
      __typename: "ModelReviewConnection",
      items?:  Array< {
        __typename: "Review",
        id: string,
        solicitorId: string,
        caseId: string,
        clientId: string,
        rating: number,
        comment?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    status: CaseStatus,
    assignedToSolicitorAt?: string | null,
    askedClientToPayAt?: string | null,
    statusWhenCancelled?: string | null,
    exportedCaseId?: string | null,
    updatedAt: string,
  } | null,
};

export type AddPaymentToCaseCommandMutationVariables = {
  input?: AddPaymentToCaseCommand,
};

export type AddPaymentToCaseCommandMutation = {
  addPaymentToCaseCommand?:  {
    __typename: "CasePayment",
    id: string,
    caseId: string,
    clientId: string,
    quoteId?: string | null,
    type: CasePaymentType,
    stripeAccountId: string,
    stripePaymentIntentId: string,
    toPlatform: boolean,
    amount: number,
    refunded?: boolean | null,
    solicitorId?: string | null,
    chargeType?: ChargeType | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type RefundCaseDepositCommandMutationVariables = {
  input?: RefundCaseDepositCommand,
};

export type RefundCaseDepositCommandMutation = {
  refundCaseDepositCommand?:  {
    __typename: "Case",
    id: string,
    caseNumber: string,
    brand?: string | null,
    productSKU?: string | null,
    hasChatbot?: boolean | null,
    payFirst?: boolean | null,
    context?: string | null,
    createdAt: string,
    clientId: string,
    client?:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    clientKyc?:  {
      __typename: "CaseClientKyc",
      isSubmitted?: boolean | null,
      isVerified?: boolean | null,
      verificationReport?:  {
        __typename: "KycVerificationReport",
        amlReportFileKey: string,
        kycReportFileKey?: string | null,
      } | null,
      documentUploads?: Array< string > | null,
      identityUploads?: Array< string > | null,
    } | null,
    files?:  Array< {
      __typename: "File",
      name: string,
      key: string,
      lastModified: string,
      size: number,
    } > | null,
    details?:  {
      __typename: "CaseDetails",
      type: CaseDetailsType,
      isTimeSensitive?: boolean | null,
      details: string,
      summary?: string | null,
      ask?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items?:  Array< {
        __typename: "Message",
        id: string,
        author?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        authorId: string,
        case:  {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        },
        caseId: string,
        content: string,
        type: MessageType,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    quotes?:  {
      __typename: "ModelCaseQuoteConnection",
      items?:  Array< {
        __typename: "CaseQuote",
        id: string,
        case:  {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        },
        caseId: string,
        isLeadFromAds?: boolean | null,
        isManualFee?: boolean | null,
        lineItems?:  {
          __typename: "ModelCaseLineItemConnection",
          items?:  Array< {
            __typename: "CaseLineItem",
            id: string,
            quote:  {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            },
            quoteId: string,
            caseLineItemProductId?: string | null,
            product?:  {
              __typename: "Product",
              id: string,
              sku: string,
              title: string,
              description: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            description?: string | null,
            clientPays: number,
            solicitorReceives: number,
            subsidy: number,
            platformFee: number,
            feePercentage: number,
            scope?:  {
              __typename: "CaseScope",
              includes: Array< string >,
              excludes: Array< string >,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    payments?:  {
      __typename: "ModelCasePaymentConnection",
      items?:  Array< {
        __typename: "CasePayment",
        id: string,
        caseId: string,
        clientId: string,
        quoteId?: string | null,
        type: CasePaymentType,
        stripeAccountId: string,
        stripePaymentIntentId: string,
        toPlatform: boolean,
        amount: number,
        refunded?: boolean | null,
        solicitorId?: string | null,
        chargeType?: ChargeType | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    caseSolicitorId?: string | null,
    solicitor?:  {
      __typename: "Solicitor",
      id: string,
      user?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      name: string,
      businessName: string,
      sraRegistrationNumber?: string | null,
      stripeAccountId?: string | null,
      onboardingStatus?:  {
        __typename: "SolicitorOnboardingStatus",
        hasAccount: boolean,
        accountType: SolicitorAccountType,
        hasChargesEnabled: boolean,
        hasSubmitDetails: boolean,
        hasPayoutsEnabled: boolean,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    solicitorResponse?:  {
      __typename: "SolicitorResponse",
      type: SolicitorResponseType,
      solicitorId: string,
      reason?: string | null,
    } | null,
    completion?:  {
      __typename: "CaseCompletion",
      type?: CaseCompletionType | null,
      details?: string | null,
    } | null,
    review?:  {
      __typename: "ModelReviewConnection",
      items?:  Array< {
        __typename: "Review",
        id: string,
        solicitorId: string,
        caseId: string,
        clientId: string,
        rating: number,
        comment?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    status: CaseStatus,
    assignedToSolicitorAt?: string | null,
    askedClientToPayAt?: string | null,
    statusWhenCancelled?: string | null,
    exportedCaseId?: string | null,
    updatedAt: string,
  } | null,
};

export type CompleteCaseCommandMutationVariables = {
  input?: CompleteCaseCommand,
};

export type CompleteCaseCommandMutation = {
  completeCaseCommand?:  {
    __typename: "Case",
    id: string,
    caseNumber: string,
    brand?: string | null,
    productSKU?: string | null,
    hasChatbot?: boolean | null,
    payFirst?: boolean | null,
    context?: string | null,
    createdAt: string,
    clientId: string,
    client?:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    clientKyc?:  {
      __typename: "CaseClientKyc",
      isSubmitted?: boolean | null,
      isVerified?: boolean | null,
      verificationReport?:  {
        __typename: "KycVerificationReport",
        amlReportFileKey: string,
        kycReportFileKey?: string | null,
      } | null,
      documentUploads?: Array< string > | null,
      identityUploads?: Array< string > | null,
    } | null,
    files?:  Array< {
      __typename: "File",
      name: string,
      key: string,
      lastModified: string,
      size: number,
    } > | null,
    details?:  {
      __typename: "CaseDetails",
      type: CaseDetailsType,
      isTimeSensitive?: boolean | null,
      details: string,
      summary?: string | null,
      ask?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items?:  Array< {
        __typename: "Message",
        id: string,
        author?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        authorId: string,
        case:  {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        },
        caseId: string,
        content: string,
        type: MessageType,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    quotes?:  {
      __typename: "ModelCaseQuoteConnection",
      items?:  Array< {
        __typename: "CaseQuote",
        id: string,
        case:  {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        },
        caseId: string,
        isLeadFromAds?: boolean | null,
        isManualFee?: boolean | null,
        lineItems?:  {
          __typename: "ModelCaseLineItemConnection",
          items?:  Array< {
            __typename: "CaseLineItem",
            id: string,
            quote:  {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            },
            quoteId: string,
            caseLineItemProductId?: string | null,
            product?:  {
              __typename: "Product",
              id: string,
              sku: string,
              title: string,
              description: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            description?: string | null,
            clientPays: number,
            solicitorReceives: number,
            subsidy: number,
            platformFee: number,
            feePercentage: number,
            scope?:  {
              __typename: "CaseScope",
              includes: Array< string >,
              excludes: Array< string >,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    payments?:  {
      __typename: "ModelCasePaymentConnection",
      items?:  Array< {
        __typename: "CasePayment",
        id: string,
        caseId: string,
        clientId: string,
        quoteId?: string | null,
        type: CasePaymentType,
        stripeAccountId: string,
        stripePaymentIntentId: string,
        toPlatform: boolean,
        amount: number,
        refunded?: boolean | null,
        solicitorId?: string | null,
        chargeType?: ChargeType | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    caseSolicitorId?: string | null,
    solicitor?:  {
      __typename: "Solicitor",
      id: string,
      user?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      name: string,
      businessName: string,
      sraRegistrationNumber?: string | null,
      stripeAccountId?: string | null,
      onboardingStatus?:  {
        __typename: "SolicitorOnboardingStatus",
        hasAccount: boolean,
        accountType: SolicitorAccountType,
        hasChargesEnabled: boolean,
        hasSubmitDetails: boolean,
        hasPayoutsEnabled: boolean,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    solicitorResponse?:  {
      __typename: "SolicitorResponse",
      type: SolicitorResponseType,
      solicitorId: string,
      reason?: string | null,
    } | null,
    completion?:  {
      __typename: "CaseCompletion",
      type?: CaseCompletionType | null,
      details?: string | null,
    } | null,
    review?:  {
      __typename: "ModelReviewConnection",
      items?:  Array< {
        __typename: "Review",
        id: string,
        solicitorId: string,
        caseId: string,
        clientId: string,
        rating: number,
        comment?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    status: CaseStatus,
    assignedToSolicitorAt?: string | null,
    askedClientToPayAt?: string | null,
    statusWhenCancelled?: string | null,
    exportedCaseId?: string | null,
    updatedAt: string,
  } | null,
};

export type RefundCaseCommandMutationVariables = {
  input?: RefundCaseCommand,
};

export type RefundCaseCommandMutation = {
  refundCaseCommand?:  {
    __typename: "Case",
    id: string,
    caseNumber: string,
    brand?: string | null,
    productSKU?: string | null,
    hasChatbot?: boolean | null,
    payFirst?: boolean | null,
    context?: string | null,
    createdAt: string,
    clientId: string,
    client?:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    clientKyc?:  {
      __typename: "CaseClientKyc",
      isSubmitted?: boolean | null,
      isVerified?: boolean | null,
      verificationReport?:  {
        __typename: "KycVerificationReport",
        amlReportFileKey: string,
        kycReportFileKey?: string | null,
      } | null,
      documentUploads?: Array< string > | null,
      identityUploads?: Array< string > | null,
    } | null,
    files?:  Array< {
      __typename: "File",
      name: string,
      key: string,
      lastModified: string,
      size: number,
    } > | null,
    details?:  {
      __typename: "CaseDetails",
      type: CaseDetailsType,
      isTimeSensitive?: boolean | null,
      details: string,
      summary?: string | null,
      ask?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items?:  Array< {
        __typename: "Message",
        id: string,
        author?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        authorId: string,
        case:  {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        },
        caseId: string,
        content: string,
        type: MessageType,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    quotes?:  {
      __typename: "ModelCaseQuoteConnection",
      items?:  Array< {
        __typename: "CaseQuote",
        id: string,
        case:  {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        },
        caseId: string,
        isLeadFromAds?: boolean | null,
        isManualFee?: boolean | null,
        lineItems?:  {
          __typename: "ModelCaseLineItemConnection",
          items?:  Array< {
            __typename: "CaseLineItem",
            id: string,
            quote:  {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            },
            quoteId: string,
            caseLineItemProductId?: string | null,
            product?:  {
              __typename: "Product",
              id: string,
              sku: string,
              title: string,
              description: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            description?: string | null,
            clientPays: number,
            solicitorReceives: number,
            subsidy: number,
            platformFee: number,
            feePercentage: number,
            scope?:  {
              __typename: "CaseScope",
              includes: Array< string >,
              excludes: Array< string >,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    payments?:  {
      __typename: "ModelCasePaymentConnection",
      items?:  Array< {
        __typename: "CasePayment",
        id: string,
        caseId: string,
        clientId: string,
        quoteId?: string | null,
        type: CasePaymentType,
        stripeAccountId: string,
        stripePaymentIntentId: string,
        toPlatform: boolean,
        amount: number,
        refunded?: boolean | null,
        solicitorId?: string | null,
        chargeType?: ChargeType | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    caseSolicitorId?: string | null,
    solicitor?:  {
      __typename: "Solicitor",
      id: string,
      user?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      name: string,
      businessName: string,
      sraRegistrationNumber?: string | null,
      stripeAccountId?: string | null,
      onboardingStatus?:  {
        __typename: "SolicitorOnboardingStatus",
        hasAccount: boolean,
        accountType: SolicitorAccountType,
        hasChargesEnabled: boolean,
        hasSubmitDetails: boolean,
        hasPayoutsEnabled: boolean,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    solicitorResponse?:  {
      __typename: "SolicitorResponse",
      type: SolicitorResponseType,
      solicitorId: string,
      reason?: string | null,
    } | null,
    completion?:  {
      __typename: "CaseCompletion",
      type?: CaseCompletionType | null,
      details?: string | null,
    } | null,
    review?:  {
      __typename: "ModelReviewConnection",
      items?:  Array< {
        __typename: "Review",
        id: string,
        solicitorId: string,
        caseId: string,
        clientId: string,
        rating: number,
        comment?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    status: CaseStatus,
    assignedToSolicitorAt?: string | null,
    askedClientToPayAt?: string | null,
    statusWhenCancelled?: string | null,
    exportedCaseId?: string | null,
    updatedAt: string,
  } | null,
};

export type CancelCaseCommandMutationVariables = {
  input?: CancelCaseCommand,
};

export type CancelCaseCommandMutation = {
  cancelCaseCommand?:  {
    __typename: "Case",
    id: string,
    caseNumber: string,
    brand?: string | null,
    productSKU?: string | null,
    hasChatbot?: boolean | null,
    payFirst?: boolean | null,
    context?: string | null,
    createdAt: string,
    clientId: string,
    client?:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    clientKyc?:  {
      __typename: "CaseClientKyc",
      isSubmitted?: boolean | null,
      isVerified?: boolean | null,
      verificationReport?:  {
        __typename: "KycVerificationReport",
        amlReportFileKey: string,
        kycReportFileKey?: string | null,
      } | null,
      documentUploads?: Array< string > | null,
      identityUploads?: Array< string > | null,
    } | null,
    files?:  Array< {
      __typename: "File",
      name: string,
      key: string,
      lastModified: string,
      size: number,
    } > | null,
    details?:  {
      __typename: "CaseDetails",
      type: CaseDetailsType,
      isTimeSensitive?: boolean | null,
      details: string,
      summary?: string | null,
      ask?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items?:  Array< {
        __typename: "Message",
        id: string,
        author?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        authorId: string,
        case:  {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        },
        caseId: string,
        content: string,
        type: MessageType,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    quotes?:  {
      __typename: "ModelCaseQuoteConnection",
      items?:  Array< {
        __typename: "CaseQuote",
        id: string,
        case:  {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        },
        caseId: string,
        isLeadFromAds?: boolean | null,
        isManualFee?: boolean | null,
        lineItems?:  {
          __typename: "ModelCaseLineItemConnection",
          items?:  Array< {
            __typename: "CaseLineItem",
            id: string,
            quote:  {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            },
            quoteId: string,
            caseLineItemProductId?: string | null,
            product?:  {
              __typename: "Product",
              id: string,
              sku: string,
              title: string,
              description: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            description?: string | null,
            clientPays: number,
            solicitorReceives: number,
            subsidy: number,
            platformFee: number,
            feePercentage: number,
            scope?:  {
              __typename: "CaseScope",
              includes: Array< string >,
              excludes: Array< string >,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    payments?:  {
      __typename: "ModelCasePaymentConnection",
      items?:  Array< {
        __typename: "CasePayment",
        id: string,
        caseId: string,
        clientId: string,
        quoteId?: string | null,
        type: CasePaymentType,
        stripeAccountId: string,
        stripePaymentIntentId: string,
        toPlatform: boolean,
        amount: number,
        refunded?: boolean | null,
        solicitorId?: string | null,
        chargeType?: ChargeType | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    caseSolicitorId?: string | null,
    solicitor?:  {
      __typename: "Solicitor",
      id: string,
      user?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      name: string,
      businessName: string,
      sraRegistrationNumber?: string | null,
      stripeAccountId?: string | null,
      onboardingStatus?:  {
        __typename: "SolicitorOnboardingStatus",
        hasAccount: boolean,
        accountType: SolicitorAccountType,
        hasChargesEnabled: boolean,
        hasSubmitDetails: boolean,
        hasPayoutsEnabled: boolean,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    solicitorResponse?:  {
      __typename: "SolicitorResponse",
      type: SolicitorResponseType,
      solicitorId: string,
      reason?: string | null,
    } | null,
    completion?:  {
      __typename: "CaseCompletion",
      type?: CaseCompletionType | null,
      details?: string | null,
    } | null,
    review?:  {
      __typename: "ModelReviewConnection",
      items?:  Array< {
        __typename: "Review",
        id: string,
        solicitorId: string,
        caseId: string,
        clientId: string,
        rating: number,
        comment?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    status: CaseStatus,
    assignedToSolicitorAt?: string | null,
    askedClientToPayAt?: string | null,
    statusWhenCancelled?: string | null,
    exportedCaseId?: string | null,
    updatedAt: string,
  } | null,
};

export type UnassignSolicitorCommandMutationVariables = {
  input?: UnassignSolicitorCommand,
};

export type UnassignSolicitorCommandMutation = {
  unassignSolicitorCommand?:  {
    __typename: "Case",
    id: string,
    caseNumber: string,
    brand?: string | null,
    productSKU?: string | null,
    hasChatbot?: boolean | null,
    payFirst?: boolean | null,
    context?: string | null,
    createdAt: string,
    clientId: string,
    client?:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    clientKyc?:  {
      __typename: "CaseClientKyc",
      isSubmitted?: boolean | null,
      isVerified?: boolean | null,
      verificationReport?:  {
        __typename: "KycVerificationReport",
        amlReportFileKey: string,
        kycReportFileKey?: string | null,
      } | null,
      documentUploads?: Array< string > | null,
      identityUploads?: Array< string > | null,
    } | null,
    files?:  Array< {
      __typename: "File",
      name: string,
      key: string,
      lastModified: string,
      size: number,
    } > | null,
    details?:  {
      __typename: "CaseDetails",
      type: CaseDetailsType,
      isTimeSensitive?: boolean | null,
      details: string,
      summary?: string | null,
      ask?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items?:  Array< {
        __typename: "Message",
        id: string,
        author?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        authorId: string,
        case:  {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        },
        caseId: string,
        content: string,
        type: MessageType,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    quotes?:  {
      __typename: "ModelCaseQuoteConnection",
      items?:  Array< {
        __typename: "CaseQuote",
        id: string,
        case:  {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        },
        caseId: string,
        isLeadFromAds?: boolean | null,
        isManualFee?: boolean | null,
        lineItems?:  {
          __typename: "ModelCaseLineItemConnection",
          items?:  Array< {
            __typename: "CaseLineItem",
            id: string,
            quote:  {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            },
            quoteId: string,
            caseLineItemProductId?: string | null,
            product?:  {
              __typename: "Product",
              id: string,
              sku: string,
              title: string,
              description: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            description?: string | null,
            clientPays: number,
            solicitorReceives: number,
            subsidy: number,
            platformFee: number,
            feePercentage: number,
            scope?:  {
              __typename: "CaseScope",
              includes: Array< string >,
              excludes: Array< string >,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    payments?:  {
      __typename: "ModelCasePaymentConnection",
      items?:  Array< {
        __typename: "CasePayment",
        id: string,
        caseId: string,
        clientId: string,
        quoteId?: string | null,
        type: CasePaymentType,
        stripeAccountId: string,
        stripePaymentIntentId: string,
        toPlatform: boolean,
        amount: number,
        refunded?: boolean | null,
        solicitorId?: string | null,
        chargeType?: ChargeType | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    caseSolicitorId?: string | null,
    solicitor?:  {
      __typename: "Solicitor",
      id: string,
      user?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      name: string,
      businessName: string,
      sraRegistrationNumber?: string | null,
      stripeAccountId?: string | null,
      onboardingStatus?:  {
        __typename: "SolicitorOnboardingStatus",
        hasAccount: boolean,
        accountType: SolicitorAccountType,
        hasChargesEnabled: boolean,
        hasSubmitDetails: boolean,
        hasPayoutsEnabled: boolean,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    solicitorResponse?:  {
      __typename: "SolicitorResponse",
      type: SolicitorResponseType,
      solicitorId: string,
      reason?: string | null,
    } | null,
    completion?:  {
      __typename: "CaseCompletion",
      type?: CaseCompletionType | null,
      details?: string | null,
    } | null,
    review?:  {
      __typename: "ModelReviewConnection",
      items?:  Array< {
        __typename: "Review",
        id: string,
        solicitorId: string,
        caseId: string,
        clientId: string,
        rating: number,
        comment?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    status: CaseStatus,
    assignedToSolicitorAt?: string | null,
    askedClientToPayAt?: string | null,
    statusWhenCancelled?: string | null,
    exportedCaseId?: string | null,
    updatedAt: string,
  } | null,
};

export type LeaveReviewCommandMutationVariables = {
  input?: LeaveReviewCommand,
};

export type LeaveReviewCommandMutation = {
  leaveReviewCommand?:  {
    __typename: "Review",
    id: string,
    solicitorId: string,
    caseId: string,
    clientId: string,
    rating: number,
    comment?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DownloadFileCommandMutationVariables = {
  input?: DownloadFileCommand,
};

export type DownloadFileCommandMutation = {
  downloadFileCommand?:  {
    __typename: "DownloadCommandResult",
    url: string,
  } | null,
};

export type RequestKycReportUploadCommandMutationVariables = {
  input?: RequestKycReportUploadCommand,
};

export type RequestKycReportUploadCommandMutation = {
  requestKycReportUploadCommand?:  {
    __typename: "UploadCommandResult",
    signature: string,
  } | null,
};

export type ApproveKycSubmissionCommandMutationVariables = {
  input?: ApproveKycSubmissionCommand,
};

export type ApproveKycSubmissionCommandMutation = {
  approveKycSubmissionCommand?:  {
    __typename: "KYCSubmission",
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    status?: KYCSubmissionStatus | null,
    address?: string | null,
    documentUploads?: Array< string > | null,
    identityUploads?: Array< string > | null,
    verificationReport?:  {
      __typename: "KycVerificationReport",
      amlReportFileKey: string,
      kycReportFileKey?: string | null,
    } | null,
    rejectionReason?:  {
      __typename: "KycRejectionReason",
      reason?: string | null,
      instructions?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type RejectKycSubmissionCommandMutationVariables = {
  input?: RejectKycSubmissionCommand,
};

export type RejectKycSubmissionCommandMutation = {
  rejectKycSubmissionCommand?:  {
    __typename: "KYCSubmission",
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    status?: KYCSubmissionStatus | null,
    address?: string | null,
    documentUploads?: Array< string > | null,
    identityUploads?: Array< string > | null,
    verificationReport?:  {
      __typename: "KycVerificationReport",
      amlReportFileKey: string,
      kycReportFileKey?: string | null,
    } | null,
    rejectionReason?:  {
      __typename: "KycRejectionReason",
      reason?: string | null,
      instructions?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type SolicitorAcceptCaseCommandMutationVariables = {
  input?: SolicitorAcceptCaseCommand,
};

export type SolicitorAcceptCaseCommandMutation = {
  solicitorAcceptCaseCommand?:  {
    __typename: "Case",
    id: string,
    caseNumber: string,
    brand?: string | null,
    productSKU?: string | null,
    hasChatbot?: boolean | null,
    payFirst?: boolean | null,
    context?: string | null,
    createdAt: string,
    clientId: string,
    client?:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    clientKyc?:  {
      __typename: "CaseClientKyc",
      isSubmitted?: boolean | null,
      isVerified?: boolean | null,
      verificationReport?:  {
        __typename: "KycVerificationReport",
        amlReportFileKey: string,
        kycReportFileKey?: string | null,
      } | null,
      documentUploads?: Array< string > | null,
      identityUploads?: Array< string > | null,
    } | null,
    files?:  Array< {
      __typename: "File",
      name: string,
      key: string,
      lastModified: string,
      size: number,
    } > | null,
    details?:  {
      __typename: "CaseDetails",
      type: CaseDetailsType,
      isTimeSensitive?: boolean | null,
      details: string,
      summary?: string | null,
      ask?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items?:  Array< {
        __typename: "Message",
        id: string,
        author?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        authorId: string,
        case:  {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        },
        caseId: string,
        content: string,
        type: MessageType,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    quotes?:  {
      __typename: "ModelCaseQuoteConnection",
      items?:  Array< {
        __typename: "CaseQuote",
        id: string,
        case:  {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        },
        caseId: string,
        isLeadFromAds?: boolean | null,
        isManualFee?: boolean | null,
        lineItems?:  {
          __typename: "ModelCaseLineItemConnection",
          items?:  Array< {
            __typename: "CaseLineItem",
            id: string,
            quote:  {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            },
            quoteId: string,
            caseLineItemProductId?: string | null,
            product?:  {
              __typename: "Product",
              id: string,
              sku: string,
              title: string,
              description: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            description?: string | null,
            clientPays: number,
            solicitorReceives: number,
            subsidy: number,
            platformFee: number,
            feePercentage: number,
            scope?:  {
              __typename: "CaseScope",
              includes: Array< string >,
              excludes: Array< string >,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    payments?:  {
      __typename: "ModelCasePaymentConnection",
      items?:  Array< {
        __typename: "CasePayment",
        id: string,
        caseId: string,
        clientId: string,
        quoteId?: string | null,
        type: CasePaymentType,
        stripeAccountId: string,
        stripePaymentIntentId: string,
        toPlatform: boolean,
        amount: number,
        refunded?: boolean | null,
        solicitorId?: string | null,
        chargeType?: ChargeType | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    caseSolicitorId?: string | null,
    solicitor?:  {
      __typename: "Solicitor",
      id: string,
      user?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      name: string,
      businessName: string,
      sraRegistrationNumber?: string | null,
      stripeAccountId?: string | null,
      onboardingStatus?:  {
        __typename: "SolicitorOnboardingStatus",
        hasAccount: boolean,
        accountType: SolicitorAccountType,
        hasChargesEnabled: boolean,
        hasSubmitDetails: boolean,
        hasPayoutsEnabled: boolean,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    solicitorResponse?:  {
      __typename: "SolicitorResponse",
      type: SolicitorResponseType,
      solicitorId: string,
      reason?: string | null,
    } | null,
    completion?:  {
      __typename: "CaseCompletion",
      type?: CaseCompletionType | null,
      details?: string | null,
    } | null,
    review?:  {
      __typename: "ModelReviewConnection",
      items?:  Array< {
        __typename: "Review",
        id: string,
        solicitorId: string,
        caseId: string,
        clientId: string,
        rating: number,
        comment?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    status: CaseStatus,
    assignedToSolicitorAt?: string | null,
    askedClientToPayAt?: string | null,
    statusWhenCancelled?: string | null,
    exportedCaseId?: string | null,
    updatedAt: string,
  } | null,
};

export type SolicitorRejectCaseCommandMutationVariables = {
  input?: SolicitorRejectCaseCommand,
};

export type SolicitorRejectCaseCommandMutation = {
  solicitorRejectCaseCommand?:  {
    __typename: "Case",
    id: string,
    caseNumber: string,
    brand?: string | null,
    productSKU?: string | null,
    hasChatbot?: boolean | null,
    payFirst?: boolean | null,
    context?: string | null,
    createdAt: string,
    clientId: string,
    client?:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    clientKyc?:  {
      __typename: "CaseClientKyc",
      isSubmitted?: boolean | null,
      isVerified?: boolean | null,
      verificationReport?:  {
        __typename: "KycVerificationReport",
        amlReportFileKey: string,
        kycReportFileKey?: string | null,
      } | null,
      documentUploads?: Array< string > | null,
      identityUploads?: Array< string > | null,
    } | null,
    files?:  Array< {
      __typename: "File",
      name: string,
      key: string,
      lastModified: string,
      size: number,
    } > | null,
    details?:  {
      __typename: "CaseDetails",
      type: CaseDetailsType,
      isTimeSensitive?: boolean | null,
      details: string,
      summary?: string | null,
      ask?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items?:  Array< {
        __typename: "Message",
        id: string,
        author?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        authorId: string,
        case:  {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        },
        caseId: string,
        content: string,
        type: MessageType,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    quotes?:  {
      __typename: "ModelCaseQuoteConnection",
      items?:  Array< {
        __typename: "CaseQuote",
        id: string,
        case:  {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        },
        caseId: string,
        isLeadFromAds?: boolean | null,
        isManualFee?: boolean | null,
        lineItems?:  {
          __typename: "ModelCaseLineItemConnection",
          items?:  Array< {
            __typename: "CaseLineItem",
            id: string,
            quote:  {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            },
            quoteId: string,
            caseLineItemProductId?: string | null,
            product?:  {
              __typename: "Product",
              id: string,
              sku: string,
              title: string,
              description: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            description?: string | null,
            clientPays: number,
            solicitorReceives: number,
            subsidy: number,
            platformFee: number,
            feePercentage: number,
            scope?:  {
              __typename: "CaseScope",
              includes: Array< string >,
              excludes: Array< string >,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    payments?:  {
      __typename: "ModelCasePaymentConnection",
      items?:  Array< {
        __typename: "CasePayment",
        id: string,
        caseId: string,
        clientId: string,
        quoteId?: string | null,
        type: CasePaymentType,
        stripeAccountId: string,
        stripePaymentIntentId: string,
        toPlatform: boolean,
        amount: number,
        refunded?: boolean | null,
        solicitorId?: string | null,
        chargeType?: ChargeType | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    caseSolicitorId?: string | null,
    solicitor?:  {
      __typename: "Solicitor",
      id: string,
      user?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      name: string,
      businessName: string,
      sraRegistrationNumber?: string | null,
      stripeAccountId?: string | null,
      onboardingStatus?:  {
        __typename: "SolicitorOnboardingStatus",
        hasAccount: boolean,
        accountType: SolicitorAccountType,
        hasChargesEnabled: boolean,
        hasSubmitDetails: boolean,
        hasPayoutsEnabled: boolean,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    solicitorResponse?:  {
      __typename: "SolicitorResponse",
      type: SolicitorResponseType,
      solicitorId: string,
      reason?: string | null,
    } | null,
    completion?:  {
      __typename: "CaseCompletion",
      type?: CaseCompletionType | null,
      details?: string | null,
    } | null,
    review?:  {
      __typename: "ModelReviewConnection",
      items?:  Array< {
        __typename: "Review",
        id: string,
        solicitorId: string,
        caseId: string,
        clientId: string,
        rating: number,
        comment?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    status: CaseStatus,
    assignedToSolicitorAt?: string | null,
    askedClientToPayAt?: string | null,
    statusWhenCancelled?: string | null,
    exportedCaseId?: string | null,
    updatedAt: string,
  } | null,
};

export type SolicitorOnboardToStripeCommandMutationVariables = {
  input?: SolicitorOnboardToStripeCommand,
};

export type SolicitorOnboardToStripeCommandMutation = {
  solicitorOnboardToStripeCommand:  {
    __typename: "SolicitorOnboardToStripeCommandOutput",
    url: string,
  },
};

export type SolicitorRequestStripeDashboardLinkCommandMutation = {
  solicitorRequestStripeDashboardLinkCommand:  {
    __typename: "SolicitorRequestStripeDashboardLinkCommandOutput",
    url: string,
  },
};

export type SolicitorRequestKycReportDownloadCommandMutationVariables = {
  input?: SolicitorRequestKycReportDownloadCommand | null,
};

export type SolicitorRequestKycReportDownloadCommandMutation = {
  solicitorRequestKycReportDownloadCommand:  {
    __typename: "DownloadCommandResult",
    url: string,
  },
};

export type ClientCreateAddCardSessionCommandMutationVariables = {
  input?: ClientCreateAddCardSessionCommand | null,
};

export type ClientCreateAddCardSessionCommandMutation = {
  clientCreateAddCardSessionCommand?:  {
    __typename: "ClientCreateAddCardSessionCommandResult",
    sessionId: string,
  } | null,
};

export type ClientCheckoutQuoteCommandMutationVariables = {
  input?: ClientCheckoutQuoteCommand | null,
};

export type ClientCheckoutQuoteCommandMutation = {
  clientCheckoutQuoteCommand?:  {
    __typename: "ClientCheckoutQuoteCommandResult",
    stripeCheckoutSecret: string,
    stripeAccountId: string,
  } | null,
};

export type ClientClaimQuotePaymentCommandMutationVariables = {
  input?: ClientClaimQuotePaymentCommand | null,
};

export type ClientClaimQuotePaymentCommandMutation = {
  clientClaimQuotePaymentCommand?:  {
    __typename: "ClientClaimQuotePaymentCommandResult",
    caseId: string,
    quoteId: string,
    paymentId: string,
  } | null,
};

export type ClientRequestCaseFileDownloadCommandMutationVariables = {
  input?: ClientRequestCaseFileDownloadCommand | null,
};

export type ClientRequestCaseFileDownloadCommandMutation = {
  clientRequestCaseFileDownloadCommand?:  {
    __typename: "DownloadCommandResult",
    url: string,
  } | null,
};

export type ClientRequestCaseFileUploadCommandMutationVariables = {
  input?: ClientRequestCaseFileUploadCommand | null,
};

export type ClientRequestCaseFileUploadCommandMutation = {
  clientRequestCaseFileUploadCommand?:  {
    __typename: "UploadCommandResult",
    signature: string,
  } | null,
};

export type ClientRequestKycFileUploadCommandMutationVariables = {
  input?: ClientRequestKycFileUploadCommand | null,
};

export type ClientRequestKycFileUploadCommandMutation = {
  clientRequestKycFileUploadCommand?:  {
    __typename: "UploadCommandResult",
    signature: string,
  } | null,
};

export type ClientSubmitKycForApprovalCommandMutation = {
  clientSubmitKycForApprovalCommand?:  {
    __typename: "KYCSubmission",
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    status?: KYCSubmissionStatus | null,
    address?: string | null,
    documentUploads?: Array< string > | null,
    identityUploads?: Array< string > | null,
    verificationReport?:  {
      __typename: "KycVerificationReport",
      amlReportFileKey: string,
      kycReportFileKey?: string | null,
    } | null,
    rejectionReason?:  {
      __typename: "KycRejectionReason",
      reason?: string | null,
      instructions?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ClientEnsureUserIsConfiguredCommandMutation = {
  clientEnsureUserIsConfiguredCommand?: boolean | null,
};

export type DetachPaymentMethodCommandMutationVariables = {
  input?: DetachPaymentMethodCommand | null,
};

export type DetachPaymentMethodCommandMutation = {
  detachPaymentMethodCommand?: boolean | null,
};

export type RegisterMessageSentCommandMutationVariables = {
  input?: RegisterMessageSentCommand | null,
};

export type RegisterMessageSentCommandMutation = {
  registerMessageSentCommand?: boolean | null,
};

export type StartChatbotSessionCommandMutationVariables = {
  input?: StartChatbotSessionCommand | null,
};

export type StartChatbotSessionCommandMutation = {
  startChatbotSessionCommand?:  {
    __typename: "StartChatbotSessionCommandResult",
    sessionId: string,
  } | null,
};

export type RequestCaseCompletionCommandMutationVariables = {
  input?: RequestCaseCompletionCommand,
};

export type RequestCaseCompletionCommandMutation = {
  requestCaseCompletionCommand?:  {
    __typename: "RequestCaseCompletionCommandResult",
    id: string,
  } | null,
};

export type EmitQuoteAddedToCaseEventMutationVariables = {
  event?: QuoteAddedToCaseEvent | null,
};

export type EmitQuoteAddedToCaseEventMutation = {
  emitQuoteAddedToCaseEvent?:  {
    __typename: "EventResult",
    success?: boolean | null,
  } | null,
};

export type EmitClientPaidQuoteEventMutationVariables = {
  event?: ClientPaidQuoteEvent | null,
};

export type EmitClientPaidQuoteEventMutation = {
  emitClientPaidQuoteEvent?:  {
    __typename: "EventResult",
    success?: boolean | null,
  } | null,
};

export type EmitSolicitorAskedToAcceptCaseEventMutationVariables = {
  event?: SolicitorAskedToAcceptCaseEvent | null,
};

export type EmitSolicitorAskedToAcceptCaseEventMutation = {
  emitSolicitorAskedToAcceptCaseEvent?:  {
    __typename: "EventResult",
    success?: boolean | null,
  } | null,
};

export type EmitSolicitorAcceptedCaseAssignmentEventMutationVariables = {
  event?: SolicitorAcceptedCaseAssignmentEvent | null,
};

export type EmitSolicitorAcceptedCaseAssignmentEventMutation = {
  emitSolicitorAcceptedCaseAssignmentEvent?:  {
    __typename: "EventResult",
    success?: boolean | null,
  } | null,
};

export type EmitSolicitorRejectedCaseAssignmentEventMutationVariables = {
  event?: SolicitorRejectedCaseAssignmentEvent | null,
};

export type EmitSolicitorRejectedCaseAssignmentEventMutation = {
  emitSolicitorRejectedCaseAssignmentEvent?:  {
    __typename: "EventResult",
    success?: boolean | null,
  } | null,
};

export type EmitClientSubmittedKycEventMutationVariables = {
  event?: ClientSubmittedKycEvent | null,
};

export type EmitClientSubmittedKycEventMutation = {
  emitClientSubmittedKycEvent?:  {
    __typename: "EventResult",
    success?: boolean | null,
  } | null,
};

export type EmitRejectedClientKycEventMutationVariables = {
  event?: RejectedClientKycEvent | null,
};

export type EmitRejectedClientKycEventMutation = {
  emitRejectedClientKycEvent?:  {
    __typename: "EventResult",
    success?: boolean | null,
  } | null,
};

export type EmitApprovedClientKycEventMutationVariables = {
  event?: ApprovedClientKycEvent | null,
};

export type EmitApprovedClientKycEventMutation = {
  emitApprovedClientKycEvent?:  {
    __typename: "EventResult",
    success?: boolean | null,
  } | null,
};

export type EmitCaseCompletedEventMutationVariables = {
  event?: CaseCompletedEvent | null,
};

export type EmitCaseCompletedEventMutation = {
  emitCaseCompletedEvent?:  {
    __typename: "EventResult",
    success?: boolean | null,
  } | null,
};

export type EmitCaseCreatedEventMutationVariables = {
  event?: CaseCreatedEvent | null,
};

export type EmitCaseCreatedEventMutation = {
  emitCaseCreatedEvent?:  {
    __typename: "EventResult",
    success?: boolean | null,
  } | null,
};

export type EmitCaseCancelledEventMutationVariables = {
  event?: CaseCancelledEvent | null,
};

export type EmitCaseCancelledEventMutation = {
  emitCaseCancelledEvent?:  {
    __typename: "EventResult",
    success?: boolean | null,
  } | null,
};

export type EmitSolicitorUnassignedEventMutationVariables = {
  event?: SolicitorUnassignedEvent | null,
};

export type EmitSolicitorUnassignedEventMutation = {
  emitSolicitorUnassignedEvent?:  {
    __typename: "EventResult",
    success?: boolean | null,
  } | null,
};

export type EmitUserRequestedCaseCompletionEventMutationVariables = {
  event?: UserRequestedCaseCompletionEvent | null,
};

export type EmitUserRequestedCaseCompletionEventMutation = {
  emitUserRequestedCaseCompletionEvent?:  {
    __typename: "EventResult",
    success?: boolean | null,
  } | null,
};

export type PrepareSignInMutationVariables = {
  emailAddress?: string,
};

export type PrepareSignInMutation = {
  prepareSignIn?:  {
    __typename: "EventResult",
    success?: boolean | null,
  } | null,
};

export type CreateCaseMutationVariables = {
  input?: CreateCaseInput,
  condition?: ModelCaseConditionInput | null,
};

export type CreateCaseMutation = {
  createCase?:  {
    __typename: "Case",
    id: string,
    caseNumber: string,
    brand?: string | null,
    productSKU?: string | null,
    hasChatbot?: boolean | null,
    payFirst?: boolean | null,
    context?: string | null,
    createdAt: string,
    clientId: string,
    client?:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    clientKyc?:  {
      __typename: "CaseClientKyc",
      isSubmitted?: boolean | null,
      isVerified?: boolean | null,
      verificationReport?:  {
        __typename: "KycVerificationReport",
        amlReportFileKey: string,
        kycReportFileKey?: string | null,
      } | null,
      documentUploads?: Array< string > | null,
      identityUploads?: Array< string > | null,
    } | null,
    files?:  Array< {
      __typename: "File",
      name: string,
      key: string,
      lastModified: string,
      size: number,
    } > | null,
    details?:  {
      __typename: "CaseDetails",
      type: CaseDetailsType,
      isTimeSensitive?: boolean | null,
      details: string,
      summary?: string | null,
      ask?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items?:  Array< {
        __typename: "Message",
        id: string,
        author?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        authorId: string,
        case:  {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        },
        caseId: string,
        content: string,
        type: MessageType,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    quotes?:  {
      __typename: "ModelCaseQuoteConnection",
      items?:  Array< {
        __typename: "CaseQuote",
        id: string,
        case:  {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        },
        caseId: string,
        isLeadFromAds?: boolean | null,
        isManualFee?: boolean | null,
        lineItems?:  {
          __typename: "ModelCaseLineItemConnection",
          items?:  Array< {
            __typename: "CaseLineItem",
            id: string,
            quote:  {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            },
            quoteId: string,
            caseLineItemProductId?: string | null,
            product?:  {
              __typename: "Product",
              id: string,
              sku: string,
              title: string,
              description: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            description?: string | null,
            clientPays: number,
            solicitorReceives: number,
            subsidy: number,
            platformFee: number,
            feePercentage: number,
            scope?:  {
              __typename: "CaseScope",
              includes: Array< string >,
              excludes: Array< string >,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    payments?:  {
      __typename: "ModelCasePaymentConnection",
      items?:  Array< {
        __typename: "CasePayment",
        id: string,
        caseId: string,
        clientId: string,
        quoteId?: string | null,
        type: CasePaymentType,
        stripeAccountId: string,
        stripePaymentIntentId: string,
        toPlatform: boolean,
        amount: number,
        refunded?: boolean | null,
        solicitorId?: string | null,
        chargeType?: ChargeType | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    caseSolicitorId?: string | null,
    solicitor?:  {
      __typename: "Solicitor",
      id: string,
      user?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      name: string,
      businessName: string,
      sraRegistrationNumber?: string | null,
      stripeAccountId?: string | null,
      onboardingStatus?:  {
        __typename: "SolicitorOnboardingStatus",
        hasAccount: boolean,
        accountType: SolicitorAccountType,
        hasChargesEnabled: boolean,
        hasSubmitDetails: boolean,
        hasPayoutsEnabled: boolean,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    solicitorResponse?:  {
      __typename: "SolicitorResponse",
      type: SolicitorResponseType,
      solicitorId: string,
      reason?: string | null,
    } | null,
    completion?:  {
      __typename: "CaseCompletion",
      type?: CaseCompletionType | null,
      details?: string | null,
    } | null,
    review?:  {
      __typename: "ModelReviewConnection",
      items?:  Array< {
        __typename: "Review",
        id: string,
        solicitorId: string,
        caseId: string,
        clientId: string,
        rating: number,
        comment?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    status: CaseStatus,
    assignedToSolicitorAt?: string | null,
    askedClientToPayAt?: string | null,
    statusWhenCancelled?: string | null,
    exportedCaseId?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateCaseMutationVariables = {
  input?: UpdateCaseInput,
  condition?: ModelCaseConditionInput | null,
};

export type UpdateCaseMutation = {
  updateCase?:  {
    __typename: "Case",
    id: string,
    caseNumber: string,
    brand?: string | null,
    productSKU?: string | null,
    hasChatbot?: boolean | null,
    payFirst?: boolean | null,
    context?: string | null,
    createdAt: string,
    clientId: string,
    client?:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    clientKyc?:  {
      __typename: "CaseClientKyc",
      isSubmitted?: boolean | null,
      isVerified?: boolean | null,
      verificationReport?:  {
        __typename: "KycVerificationReport",
        amlReportFileKey: string,
        kycReportFileKey?: string | null,
      } | null,
      documentUploads?: Array< string > | null,
      identityUploads?: Array< string > | null,
    } | null,
    files?:  Array< {
      __typename: "File",
      name: string,
      key: string,
      lastModified: string,
      size: number,
    } > | null,
    details?:  {
      __typename: "CaseDetails",
      type: CaseDetailsType,
      isTimeSensitive?: boolean | null,
      details: string,
      summary?: string | null,
      ask?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items?:  Array< {
        __typename: "Message",
        id: string,
        author?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        authorId: string,
        case:  {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        },
        caseId: string,
        content: string,
        type: MessageType,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    quotes?:  {
      __typename: "ModelCaseQuoteConnection",
      items?:  Array< {
        __typename: "CaseQuote",
        id: string,
        case:  {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        },
        caseId: string,
        isLeadFromAds?: boolean | null,
        isManualFee?: boolean | null,
        lineItems?:  {
          __typename: "ModelCaseLineItemConnection",
          items?:  Array< {
            __typename: "CaseLineItem",
            id: string,
            quote:  {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            },
            quoteId: string,
            caseLineItemProductId?: string | null,
            product?:  {
              __typename: "Product",
              id: string,
              sku: string,
              title: string,
              description: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            description?: string | null,
            clientPays: number,
            solicitorReceives: number,
            subsidy: number,
            platformFee: number,
            feePercentage: number,
            scope?:  {
              __typename: "CaseScope",
              includes: Array< string >,
              excludes: Array< string >,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    payments?:  {
      __typename: "ModelCasePaymentConnection",
      items?:  Array< {
        __typename: "CasePayment",
        id: string,
        caseId: string,
        clientId: string,
        quoteId?: string | null,
        type: CasePaymentType,
        stripeAccountId: string,
        stripePaymentIntentId: string,
        toPlatform: boolean,
        amount: number,
        refunded?: boolean | null,
        solicitorId?: string | null,
        chargeType?: ChargeType | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    caseSolicitorId?: string | null,
    solicitor?:  {
      __typename: "Solicitor",
      id: string,
      user?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      name: string,
      businessName: string,
      sraRegistrationNumber?: string | null,
      stripeAccountId?: string | null,
      onboardingStatus?:  {
        __typename: "SolicitorOnboardingStatus",
        hasAccount: boolean,
        accountType: SolicitorAccountType,
        hasChargesEnabled: boolean,
        hasSubmitDetails: boolean,
        hasPayoutsEnabled: boolean,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    solicitorResponse?:  {
      __typename: "SolicitorResponse",
      type: SolicitorResponseType,
      solicitorId: string,
      reason?: string | null,
    } | null,
    completion?:  {
      __typename: "CaseCompletion",
      type?: CaseCompletionType | null,
      details?: string | null,
    } | null,
    review?:  {
      __typename: "ModelReviewConnection",
      items?:  Array< {
        __typename: "Review",
        id: string,
        solicitorId: string,
        caseId: string,
        clientId: string,
        rating: number,
        comment?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    status: CaseStatus,
    assignedToSolicitorAt?: string | null,
    askedClientToPayAt?: string | null,
    statusWhenCancelled?: string | null,
    exportedCaseId?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteCaseMutationVariables = {
  input?: DeleteCaseInput,
  condition?: ModelCaseConditionInput | null,
};

export type DeleteCaseMutation = {
  deleteCase?:  {
    __typename: "Case",
    id: string,
    caseNumber: string,
    brand?: string | null,
    productSKU?: string | null,
    hasChatbot?: boolean | null,
    payFirst?: boolean | null,
    context?: string | null,
    createdAt: string,
    clientId: string,
    client?:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    clientKyc?:  {
      __typename: "CaseClientKyc",
      isSubmitted?: boolean | null,
      isVerified?: boolean | null,
      verificationReport?:  {
        __typename: "KycVerificationReport",
        amlReportFileKey: string,
        kycReportFileKey?: string | null,
      } | null,
      documentUploads?: Array< string > | null,
      identityUploads?: Array< string > | null,
    } | null,
    files?:  Array< {
      __typename: "File",
      name: string,
      key: string,
      lastModified: string,
      size: number,
    } > | null,
    details?:  {
      __typename: "CaseDetails",
      type: CaseDetailsType,
      isTimeSensitive?: boolean | null,
      details: string,
      summary?: string | null,
      ask?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items?:  Array< {
        __typename: "Message",
        id: string,
        author?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        authorId: string,
        case:  {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        },
        caseId: string,
        content: string,
        type: MessageType,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    quotes?:  {
      __typename: "ModelCaseQuoteConnection",
      items?:  Array< {
        __typename: "CaseQuote",
        id: string,
        case:  {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        },
        caseId: string,
        isLeadFromAds?: boolean | null,
        isManualFee?: boolean | null,
        lineItems?:  {
          __typename: "ModelCaseLineItemConnection",
          items?:  Array< {
            __typename: "CaseLineItem",
            id: string,
            quote:  {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            },
            quoteId: string,
            caseLineItemProductId?: string | null,
            product?:  {
              __typename: "Product",
              id: string,
              sku: string,
              title: string,
              description: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            description?: string | null,
            clientPays: number,
            solicitorReceives: number,
            subsidy: number,
            platformFee: number,
            feePercentage: number,
            scope?:  {
              __typename: "CaseScope",
              includes: Array< string >,
              excludes: Array< string >,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    payments?:  {
      __typename: "ModelCasePaymentConnection",
      items?:  Array< {
        __typename: "CasePayment",
        id: string,
        caseId: string,
        clientId: string,
        quoteId?: string | null,
        type: CasePaymentType,
        stripeAccountId: string,
        stripePaymentIntentId: string,
        toPlatform: boolean,
        amount: number,
        refunded?: boolean | null,
        solicitorId?: string | null,
        chargeType?: ChargeType | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    caseSolicitorId?: string | null,
    solicitor?:  {
      __typename: "Solicitor",
      id: string,
      user?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      name: string,
      businessName: string,
      sraRegistrationNumber?: string | null,
      stripeAccountId?: string | null,
      onboardingStatus?:  {
        __typename: "SolicitorOnboardingStatus",
        hasAccount: boolean,
        accountType: SolicitorAccountType,
        hasChargesEnabled: boolean,
        hasSubmitDetails: boolean,
        hasPayoutsEnabled: boolean,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    solicitorResponse?:  {
      __typename: "SolicitorResponse",
      type: SolicitorResponseType,
      solicitorId: string,
      reason?: string | null,
    } | null,
    completion?:  {
      __typename: "CaseCompletion",
      type?: CaseCompletionType | null,
      details?: string | null,
    } | null,
    review?:  {
      __typename: "ModelReviewConnection",
      items?:  Array< {
        __typename: "Review",
        id: string,
        solicitorId: string,
        caseId: string,
        clientId: string,
        rating: number,
        comment?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    status: CaseStatus,
    assignedToSolicitorAt?: string | null,
    askedClientToPayAt?: string | null,
    statusWhenCancelled?: string | null,
    exportedCaseId?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateReviewMutationVariables = {
  input?: CreateReviewInput,
  condition?: ModelReviewConditionInput | null,
};

export type CreateReviewMutation = {
  createReview?:  {
    __typename: "Review",
    id: string,
    solicitorId: string,
    caseId: string,
    clientId: string,
    rating: number,
    comment?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateReviewMutationVariables = {
  input?: UpdateReviewInput,
  condition?: ModelReviewConditionInput | null,
};

export type UpdateReviewMutation = {
  updateReview?:  {
    __typename: "Review",
    id: string,
    solicitorId: string,
    caseId: string,
    clientId: string,
    rating: number,
    comment?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteReviewMutationVariables = {
  input?: DeleteReviewInput,
  condition?: ModelReviewConditionInput | null,
};

export type DeleteReviewMutation = {
  deleteReview?:  {
    __typename: "Review",
    id: string,
    solicitorId: string,
    caseId: string,
    clientId: string,
    rating: number,
    comment?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCasePaymentMutationVariables = {
  input?: CreateCasePaymentInput,
  condition?: ModelCasePaymentConditionInput | null,
};

export type CreateCasePaymentMutation = {
  createCasePayment?:  {
    __typename: "CasePayment",
    id: string,
    caseId: string,
    clientId: string,
    quoteId?: string | null,
    type: CasePaymentType,
    stripeAccountId: string,
    stripePaymentIntentId: string,
    toPlatform: boolean,
    amount: number,
    refunded?: boolean | null,
    solicitorId?: string | null,
    chargeType?: ChargeType | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCasePaymentMutationVariables = {
  input?: UpdateCasePaymentInput,
  condition?: ModelCasePaymentConditionInput | null,
};

export type UpdateCasePaymentMutation = {
  updateCasePayment?:  {
    __typename: "CasePayment",
    id: string,
    caseId: string,
    clientId: string,
    quoteId?: string | null,
    type: CasePaymentType,
    stripeAccountId: string,
    stripePaymentIntentId: string,
    toPlatform: boolean,
    amount: number,
    refunded?: boolean | null,
    solicitorId?: string | null,
    chargeType?: ChargeType | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCasePaymentMutationVariables = {
  input?: DeleteCasePaymentInput,
  condition?: ModelCasePaymentConditionInput | null,
};

export type DeleteCasePaymentMutation = {
  deleteCasePayment?:  {
    __typename: "CasePayment",
    id: string,
    caseId: string,
    clientId: string,
    quoteId?: string | null,
    type: CasePaymentType,
    stripeAccountId: string,
    stripePaymentIntentId: string,
    toPlatform: boolean,
    amount: number,
    refunded?: boolean | null,
    solicitorId?: string | null,
    chargeType?: ChargeType | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCaseQuoteMutationVariables = {
  input?: CreateCaseQuoteInput,
  condition?: ModelCaseQuoteConditionInput | null,
};

export type CreateCaseQuoteMutation = {
  createCaseQuote?:  {
    __typename: "CaseQuote",
    id: string,
    case:  {
      __typename: "Case",
      id: string,
      caseNumber: string,
      brand?: string | null,
      productSKU?: string | null,
      hasChatbot?: boolean | null,
      payFirst?: boolean | null,
      context?: string | null,
      createdAt: string,
      clientId: string,
      client?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientKyc?:  {
        __typename: "CaseClientKyc",
        isSubmitted?: boolean | null,
        isVerified?: boolean | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
      } | null,
      files?:  Array< {
        __typename: "File",
        name: string,
        key: string,
        lastModified: string,
        size: number,
      } > | null,
      details?:  {
        __typename: "CaseDetails",
        type: CaseDetailsType,
        isTimeSensitive?: boolean | null,
        details: string,
        summary?: string | null,
        ask?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items?:  Array< {
          __typename: "Message",
          id: string,
          author?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          authorId: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          content: string,
          type: MessageType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      quotes?:  {
        __typename: "ModelCaseQuoteConnection",
        items?:  Array< {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      payments?:  {
        __typename: "ModelCasePaymentConnection",
        items?:  Array< {
          __typename: "CasePayment",
          id: string,
          caseId: string,
          clientId: string,
          quoteId?: string | null,
          type: CasePaymentType,
          stripeAccountId: string,
          stripePaymentIntentId: string,
          toPlatform: boolean,
          amount: number,
          refunded?: boolean | null,
          solicitorId?: string | null,
          chargeType?: ChargeType | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      caseSolicitorId?: string | null,
      solicitor?:  {
        __typename: "Solicitor",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        name: string,
        businessName: string,
        sraRegistrationNumber?: string | null,
        stripeAccountId?: string | null,
        onboardingStatus?:  {
          __typename: "SolicitorOnboardingStatus",
          hasAccount: boolean,
          accountType: SolicitorAccountType,
          hasChargesEnabled: boolean,
          hasSubmitDetails: boolean,
          hasPayoutsEnabled: boolean,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      solicitorResponse?:  {
        __typename: "SolicitorResponse",
        type: SolicitorResponseType,
        solicitorId: string,
        reason?: string | null,
      } | null,
      completion?:  {
        __typename: "CaseCompletion",
        type?: CaseCompletionType | null,
        details?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items?:  Array< {
          __typename: "Review",
          id: string,
          solicitorId: string,
          caseId: string,
          clientId: string,
          rating: number,
          comment?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      status: CaseStatus,
      assignedToSolicitorAt?: string | null,
      askedClientToPayAt?: string | null,
      statusWhenCancelled?: string | null,
      exportedCaseId?: string | null,
      updatedAt: string,
    },
    caseId: string,
    isLeadFromAds?: boolean | null,
    isManualFee?: boolean | null,
    lineItems?:  {
      __typename: "ModelCaseLineItemConnection",
      items?:  Array< {
        __typename: "CaseLineItem",
        id: string,
        quote:  {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        quoteId: string,
        caseLineItemProductId?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          sku: string,
          title: string,
          description: string,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        description?: string | null,
        clientPays: number,
        solicitorReceives: number,
        subsidy: number,
        platformFee: number,
        feePercentage: number,
        scope?:  {
          __typename: "CaseScope",
          includes: Array< string >,
          excludes: Array< string >,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    payments?:  {
      __typename: "ModelCasePaymentConnection",
      items?:  Array< {
        __typename: "CasePayment",
        id: string,
        caseId: string,
        clientId: string,
        quoteId?: string | null,
        type: CasePaymentType,
        stripeAccountId: string,
        stripePaymentIntentId: string,
        toPlatform: boolean,
        amount: number,
        refunded?: boolean | null,
        solicitorId?: string | null,
        chargeType?: ChargeType | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCaseQuoteMutationVariables = {
  input?: UpdateCaseQuoteInput,
  condition?: ModelCaseQuoteConditionInput | null,
};

export type UpdateCaseQuoteMutation = {
  updateCaseQuote?:  {
    __typename: "CaseQuote",
    id: string,
    case:  {
      __typename: "Case",
      id: string,
      caseNumber: string,
      brand?: string | null,
      productSKU?: string | null,
      hasChatbot?: boolean | null,
      payFirst?: boolean | null,
      context?: string | null,
      createdAt: string,
      clientId: string,
      client?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientKyc?:  {
        __typename: "CaseClientKyc",
        isSubmitted?: boolean | null,
        isVerified?: boolean | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
      } | null,
      files?:  Array< {
        __typename: "File",
        name: string,
        key: string,
        lastModified: string,
        size: number,
      } > | null,
      details?:  {
        __typename: "CaseDetails",
        type: CaseDetailsType,
        isTimeSensitive?: boolean | null,
        details: string,
        summary?: string | null,
        ask?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items?:  Array< {
          __typename: "Message",
          id: string,
          author?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          authorId: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          content: string,
          type: MessageType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      quotes?:  {
        __typename: "ModelCaseQuoteConnection",
        items?:  Array< {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      payments?:  {
        __typename: "ModelCasePaymentConnection",
        items?:  Array< {
          __typename: "CasePayment",
          id: string,
          caseId: string,
          clientId: string,
          quoteId?: string | null,
          type: CasePaymentType,
          stripeAccountId: string,
          stripePaymentIntentId: string,
          toPlatform: boolean,
          amount: number,
          refunded?: boolean | null,
          solicitorId?: string | null,
          chargeType?: ChargeType | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      caseSolicitorId?: string | null,
      solicitor?:  {
        __typename: "Solicitor",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        name: string,
        businessName: string,
        sraRegistrationNumber?: string | null,
        stripeAccountId?: string | null,
        onboardingStatus?:  {
          __typename: "SolicitorOnboardingStatus",
          hasAccount: boolean,
          accountType: SolicitorAccountType,
          hasChargesEnabled: boolean,
          hasSubmitDetails: boolean,
          hasPayoutsEnabled: boolean,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      solicitorResponse?:  {
        __typename: "SolicitorResponse",
        type: SolicitorResponseType,
        solicitorId: string,
        reason?: string | null,
      } | null,
      completion?:  {
        __typename: "CaseCompletion",
        type?: CaseCompletionType | null,
        details?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items?:  Array< {
          __typename: "Review",
          id: string,
          solicitorId: string,
          caseId: string,
          clientId: string,
          rating: number,
          comment?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      status: CaseStatus,
      assignedToSolicitorAt?: string | null,
      askedClientToPayAt?: string | null,
      statusWhenCancelled?: string | null,
      exportedCaseId?: string | null,
      updatedAt: string,
    },
    caseId: string,
    isLeadFromAds?: boolean | null,
    isManualFee?: boolean | null,
    lineItems?:  {
      __typename: "ModelCaseLineItemConnection",
      items?:  Array< {
        __typename: "CaseLineItem",
        id: string,
        quote:  {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        quoteId: string,
        caseLineItemProductId?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          sku: string,
          title: string,
          description: string,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        description?: string | null,
        clientPays: number,
        solicitorReceives: number,
        subsidy: number,
        platformFee: number,
        feePercentage: number,
        scope?:  {
          __typename: "CaseScope",
          includes: Array< string >,
          excludes: Array< string >,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    payments?:  {
      __typename: "ModelCasePaymentConnection",
      items?:  Array< {
        __typename: "CasePayment",
        id: string,
        caseId: string,
        clientId: string,
        quoteId?: string | null,
        type: CasePaymentType,
        stripeAccountId: string,
        stripePaymentIntentId: string,
        toPlatform: boolean,
        amount: number,
        refunded?: boolean | null,
        solicitorId?: string | null,
        chargeType?: ChargeType | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCaseQuoteMutationVariables = {
  input?: DeleteCaseQuoteInput,
  condition?: ModelCaseQuoteConditionInput | null,
};

export type DeleteCaseQuoteMutation = {
  deleteCaseQuote?:  {
    __typename: "CaseQuote",
    id: string,
    case:  {
      __typename: "Case",
      id: string,
      caseNumber: string,
      brand?: string | null,
      productSKU?: string | null,
      hasChatbot?: boolean | null,
      payFirst?: boolean | null,
      context?: string | null,
      createdAt: string,
      clientId: string,
      client?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientKyc?:  {
        __typename: "CaseClientKyc",
        isSubmitted?: boolean | null,
        isVerified?: boolean | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
      } | null,
      files?:  Array< {
        __typename: "File",
        name: string,
        key: string,
        lastModified: string,
        size: number,
      } > | null,
      details?:  {
        __typename: "CaseDetails",
        type: CaseDetailsType,
        isTimeSensitive?: boolean | null,
        details: string,
        summary?: string | null,
        ask?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items?:  Array< {
          __typename: "Message",
          id: string,
          author?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          authorId: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          content: string,
          type: MessageType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      quotes?:  {
        __typename: "ModelCaseQuoteConnection",
        items?:  Array< {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      payments?:  {
        __typename: "ModelCasePaymentConnection",
        items?:  Array< {
          __typename: "CasePayment",
          id: string,
          caseId: string,
          clientId: string,
          quoteId?: string | null,
          type: CasePaymentType,
          stripeAccountId: string,
          stripePaymentIntentId: string,
          toPlatform: boolean,
          amount: number,
          refunded?: boolean | null,
          solicitorId?: string | null,
          chargeType?: ChargeType | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      caseSolicitorId?: string | null,
      solicitor?:  {
        __typename: "Solicitor",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        name: string,
        businessName: string,
        sraRegistrationNumber?: string | null,
        stripeAccountId?: string | null,
        onboardingStatus?:  {
          __typename: "SolicitorOnboardingStatus",
          hasAccount: boolean,
          accountType: SolicitorAccountType,
          hasChargesEnabled: boolean,
          hasSubmitDetails: boolean,
          hasPayoutsEnabled: boolean,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      solicitorResponse?:  {
        __typename: "SolicitorResponse",
        type: SolicitorResponseType,
        solicitorId: string,
        reason?: string | null,
      } | null,
      completion?:  {
        __typename: "CaseCompletion",
        type?: CaseCompletionType | null,
        details?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items?:  Array< {
          __typename: "Review",
          id: string,
          solicitorId: string,
          caseId: string,
          clientId: string,
          rating: number,
          comment?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      status: CaseStatus,
      assignedToSolicitorAt?: string | null,
      askedClientToPayAt?: string | null,
      statusWhenCancelled?: string | null,
      exportedCaseId?: string | null,
      updatedAt: string,
    },
    caseId: string,
    isLeadFromAds?: boolean | null,
    isManualFee?: boolean | null,
    lineItems?:  {
      __typename: "ModelCaseLineItemConnection",
      items?:  Array< {
        __typename: "CaseLineItem",
        id: string,
        quote:  {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        quoteId: string,
        caseLineItemProductId?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          sku: string,
          title: string,
          description: string,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        description?: string | null,
        clientPays: number,
        solicitorReceives: number,
        subsidy: number,
        platformFee: number,
        feePercentage: number,
        scope?:  {
          __typename: "CaseScope",
          includes: Array< string >,
          excludes: Array< string >,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    payments?:  {
      __typename: "ModelCasePaymentConnection",
      items?:  Array< {
        __typename: "CasePayment",
        id: string,
        caseId: string,
        clientId: string,
        quoteId?: string | null,
        type: CasePaymentType,
        stripeAccountId: string,
        stripePaymentIntentId: string,
        toPlatform: boolean,
        amount: number,
        refunded?: boolean | null,
        solicitorId?: string | null,
        chargeType?: ChargeType | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCaseLineItemMutationVariables = {
  input?: CreateCaseLineItemInput,
  condition?: ModelCaseLineItemConditionInput | null,
};

export type CreateCaseLineItemMutation = {
  createCaseLineItem?:  {
    __typename: "CaseLineItem",
    id: string,
    quote:  {
      __typename: "CaseQuote",
      id: string,
      case:  {
        __typename: "Case",
        id: string,
        caseNumber: string,
        brand?: string | null,
        productSKU?: string | null,
        hasChatbot?: boolean | null,
        payFirst?: boolean | null,
        context?: string | null,
        createdAt: string,
        clientId: string,
        client?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientKyc?:  {
          __typename: "CaseClientKyc",
          isSubmitted?: boolean | null,
          isVerified?: boolean | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
        } | null,
        files?:  Array< {
          __typename: "File",
          name: string,
          key: string,
          lastModified: string,
          size: number,
        } > | null,
        details?:  {
          __typename: "CaseDetails",
          type: CaseDetailsType,
          isTimeSensitive?: boolean | null,
          details: string,
          summary?: string | null,
          ask?: string | null,
        } | null,
        messages?:  {
          __typename: "ModelMessageConnection",
          items?:  Array< {
            __typename: "Message",
            id: string,
            author?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            authorId: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            content: string,
            type: MessageType,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        quotes?:  {
          __typename: "ModelCaseQuoteConnection",
          items?:  Array< {
            __typename: "CaseQuote",
            id: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            isLeadFromAds?: boolean | null,
            isManualFee?: boolean | null,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        caseSolicitorId?: string | null,
        solicitor?:  {
          __typename: "Solicitor",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          name: string,
          businessName: string,
          sraRegistrationNumber?: string | null,
          stripeAccountId?: string | null,
          onboardingStatus?:  {
            __typename: "SolicitorOnboardingStatus",
            hasAccount: boolean,
            accountType: SolicitorAccountType,
            hasChargesEnabled: boolean,
            hasSubmitDetails: boolean,
            hasPayoutsEnabled: boolean,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        solicitorResponse?:  {
          __typename: "SolicitorResponse",
          type: SolicitorResponseType,
          solicitorId: string,
          reason?: string | null,
        } | null,
        completion?:  {
          __typename: "CaseCompletion",
          type?: CaseCompletionType | null,
          details?: string | null,
        } | null,
        review?:  {
          __typename: "ModelReviewConnection",
          items?:  Array< {
            __typename: "Review",
            id: string,
            solicitorId: string,
            caseId: string,
            clientId: string,
            rating: number,
            comment?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        status: CaseStatus,
        assignedToSolicitorAt?: string | null,
        askedClientToPayAt?: string | null,
        statusWhenCancelled?: string | null,
        exportedCaseId?: string | null,
        updatedAt: string,
      },
      caseId: string,
      isLeadFromAds?: boolean | null,
      isManualFee?: boolean | null,
      lineItems?:  {
        __typename: "ModelCaseLineItemConnection",
        items?:  Array< {
          __typename: "CaseLineItem",
          id: string,
          quote:  {
            __typename: "CaseQuote",
            id: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            isLeadFromAds?: boolean | null,
            isManualFee?: boolean | null,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          quoteId: string,
          caseLineItemProductId?: string | null,
          product?:  {
            __typename: "Product",
            id: string,
            sku: string,
            title: string,
            description: string,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          description?: string | null,
          clientPays: number,
          solicitorReceives: number,
          subsidy: number,
          platformFee: number,
          feePercentage: number,
          scope?:  {
            __typename: "CaseScope",
            includes: Array< string >,
            excludes: Array< string >,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      payments?:  {
        __typename: "ModelCasePaymentConnection",
        items?:  Array< {
          __typename: "CasePayment",
          id: string,
          caseId: string,
          clientId: string,
          quoteId?: string | null,
          type: CasePaymentType,
          stripeAccountId: string,
          stripePaymentIntentId: string,
          toPlatform: boolean,
          amount: number,
          refunded?: boolean | null,
          solicitorId?: string | null,
          chargeType?: ChargeType | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    quoteId: string,
    caseLineItemProductId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      sku: string,
      title: string,
      description: string,
      lineItems?:  {
        __typename: "ModelCaseLineItemConnection",
        items?:  Array< {
          __typename: "CaseLineItem",
          id: string,
          quote:  {
            __typename: "CaseQuote",
            id: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            isLeadFromAds?: boolean | null,
            isManualFee?: boolean | null,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          quoteId: string,
          caseLineItemProductId?: string | null,
          product?:  {
            __typename: "Product",
            id: string,
            sku: string,
            title: string,
            description: string,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          description?: string | null,
          clientPays: number,
          solicitorReceives: number,
          subsidy: number,
          platformFee: number,
          feePercentage: number,
          scope?:  {
            __typename: "CaseScope",
            includes: Array< string >,
            excludes: Array< string >,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    description?: string | null,
    clientPays: number,
    solicitorReceives: number,
    subsidy: number,
    platformFee: number,
    feePercentage: number,
    scope?:  {
      __typename: "CaseScope",
      includes: Array< string >,
      excludes: Array< string >,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCaseLineItemMutationVariables = {
  input?: UpdateCaseLineItemInput,
  condition?: ModelCaseLineItemConditionInput | null,
};

export type UpdateCaseLineItemMutation = {
  updateCaseLineItem?:  {
    __typename: "CaseLineItem",
    id: string,
    quote:  {
      __typename: "CaseQuote",
      id: string,
      case:  {
        __typename: "Case",
        id: string,
        caseNumber: string,
        brand?: string | null,
        productSKU?: string | null,
        hasChatbot?: boolean | null,
        payFirst?: boolean | null,
        context?: string | null,
        createdAt: string,
        clientId: string,
        client?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientKyc?:  {
          __typename: "CaseClientKyc",
          isSubmitted?: boolean | null,
          isVerified?: boolean | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
        } | null,
        files?:  Array< {
          __typename: "File",
          name: string,
          key: string,
          lastModified: string,
          size: number,
        } > | null,
        details?:  {
          __typename: "CaseDetails",
          type: CaseDetailsType,
          isTimeSensitive?: boolean | null,
          details: string,
          summary?: string | null,
          ask?: string | null,
        } | null,
        messages?:  {
          __typename: "ModelMessageConnection",
          items?:  Array< {
            __typename: "Message",
            id: string,
            author?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            authorId: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            content: string,
            type: MessageType,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        quotes?:  {
          __typename: "ModelCaseQuoteConnection",
          items?:  Array< {
            __typename: "CaseQuote",
            id: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            isLeadFromAds?: boolean | null,
            isManualFee?: boolean | null,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        caseSolicitorId?: string | null,
        solicitor?:  {
          __typename: "Solicitor",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          name: string,
          businessName: string,
          sraRegistrationNumber?: string | null,
          stripeAccountId?: string | null,
          onboardingStatus?:  {
            __typename: "SolicitorOnboardingStatus",
            hasAccount: boolean,
            accountType: SolicitorAccountType,
            hasChargesEnabled: boolean,
            hasSubmitDetails: boolean,
            hasPayoutsEnabled: boolean,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        solicitorResponse?:  {
          __typename: "SolicitorResponse",
          type: SolicitorResponseType,
          solicitorId: string,
          reason?: string | null,
        } | null,
        completion?:  {
          __typename: "CaseCompletion",
          type?: CaseCompletionType | null,
          details?: string | null,
        } | null,
        review?:  {
          __typename: "ModelReviewConnection",
          items?:  Array< {
            __typename: "Review",
            id: string,
            solicitorId: string,
            caseId: string,
            clientId: string,
            rating: number,
            comment?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        status: CaseStatus,
        assignedToSolicitorAt?: string | null,
        askedClientToPayAt?: string | null,
        statusWhenCancelled?: string | null,
        exportedCaseId?: string | null,
        updatedAt: string,
      },
      caseId: string,
      isLeadFromAds?: boolean | null,
      isManualFee?: boolean | null,
      lineItems?:  {
        __typename: "ModelCaseLineItemConnection",
        items?:  Array< {
          __typename: "CaseLineItem",
          id: string,
          quote:  {
            __typename: "CaseQuote",
            id: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            isLeadFromAds?: boolean | null,
            isManualFee?: boolean | null,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          quoteId: string,
          caseLineItemProductId?: string | null,
          product?:  {
            __typename: "Product",
            id: string,
            sku: string,
            title: string,
            description: string,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          description?: string | null,
          clientPays: number,
          solicitorReceives: number,
          subsidy: number,
          platformFee: number,
          feePercentage: number,
          scope?:  {
            __typename: "CaseScope",
            includes: Array< string >,
            excludes: Array< string >,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      payments?:  {
        __typename: "ModelCasePaymentConnection",
        items?:  Array< {
          __typename: "CasePayment",
          id: string,
          caseId: string,
          clientId: string,
          quoteId?: string | null,
          type: CasePaymentType,
          stripeAccountId: string,
          stripePaymentIntentId: string,
          toPlatform: boolean,
          amount: number,
          refunded?: boolean | null,
          solicitorId?: string | null,
          chargeType?: ChargeType | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    quoteId: string,
    caseLineItemProductId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      sku: string,
      title: string,
      description: string,
      lineItems?:  {
        __typename: "ModelCaseLineItemConnection",
        items?:  Array< {
          __typename: "CaseLineItem",
          id: string,
          quote:  {
            __typename: "CaseQuote",
            id: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            isLeadFromAds?: boolean | null,
            isManualFee?: boolean | null,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          quoteId: string,
          caseLineItemProductId?: string | null,
          product?:  {
            __typename: "Product",
            id: string,
            sku: string,
            title: string,
            description: string,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          description?: string | null,
          clientPays: number,
          solicitorReceives: number,
          subsidy: number,
          platformFee: number,
          feePercentage: number,
          scope?:  {
            __typename: "CaseScope",
            includes: Array< string >,
            excludes: Array< string >,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    description?: string | null,
    clientPays: number,
    solicitorReceives: number,
    subsidy: number,
    platformFee: number,
    feePercentage: number,
    scope?:  {
      __typename: "CaseScope",
      includes: Array< string >,
      excludes: Array< string >,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCaseLineItemMutationVariables = {
  input?: DeleteCaseLineItemInput,
  condition?: ModelCaseLineItemConditionInput | null,
};

export type DeleteCaseLineItemMutation = {
  deleteCaseLineItem?:  {
    __typename: "CaseLineItem",
    id: string,
    quote:  {
      __typename: "CaseQuote",
      id: string,
      case:  {
        __typename: "Case",
        id: string,
        caseNumber: string,
        brand?: string | null,
        productSKU?: string | null,
        hasChatbot?: boolean | null,
        payFirst?: boolean | null,
        context?: string | null,
        createdAt: string,
        clientId: string,
        client?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientKyc?:  {
          __typename: "CaseClientKyc",
          isSubmitted?: boolean | null,
          isVerified?: boolean | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
        } | null,
        files?:  Array< {
          __typename: "File",
          name: string,
          key: string,
          lastModified: string,
          size: number,
        } > | null,
        details?:  {
          __typename: "CaseDetails",
          type: CaseDetailsType,
          isTimeSensitive?: boolean | null,
          details: string,
          summary?: string | null,
          ask?: string | null,
        } | null,
        messages?:  {
          __typename: "ModelMessageConnection",
          items?:  Array< {
            __typename: "Message",
            id: string,
            author?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            authorId: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            content: string,
            type: MessageType,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        quotes?:  {
          __typename: "ModelCaseQuoteConnection",
          items?:  Array< {
            __typename: "CaseQuote",
            id: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            isLeadFromAds?: boolean | null,
            isManualFee?: boolean | null,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        caseSolicitorId?: string | null,
        solicitor?:  {
          __typename: "Solicitor",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          name: string,
          businessName: string,
          sraRegistrationNumber?: string | null,
          stripeAccountId?: string | null,
          onboardingStatus?:  {
            __typename: "SolicitorOnboardingStatus",
            hasAccount: boolean,
            accountType: SolicitorAccountType,
            hasChargesEnabled: boolean,
            hasSubmitDetails: boolean,
            hasPayoutsEnabled: boolean,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        solicitorResponse?:  {
          __typename: "SolicitorResponse",
          type: SolicitorResponseType,
          solicitorId: string,
          reason?: string | null,
        } | null,
        completion?:  {
          __typename: "CaseCompletion",
          type?: CaseCompletionType | null,
          details?: string | null,
        } | null,
        review?:  {
          __typename: "ModelReviewConnection",
          items?:  Array< {
            __typename: "Review",
            id: string,
            solicitorId: string,
            caseId: string,
            clientId: string,
            rating: number,
            comment?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        status: CaseStatus,
        assignedToSolicitorAt?: string | null,
        askedClientToPayAt?: string | null,
        statusWhenCancelled?: string | null,
        exportedCaseId?: string | null,
        updatedAt: string,
      },
      caseId: string,
      isLeadFromAds?: boolean | null,
      isManualFee?: boolean | null,
      lineItems?:  {
        __typename: "ModelCaseLineItemConnection",
        items?:  Array< {
          __typename: "CaseLineItem",
          id: string,
          quote:  {
            __typename: "CaseQuote",
            id: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            isLeadFromAds?: boolean | null,
            isManualFee?: boolean | null,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          quoteId: string,
          caseLineItemProductId?: string | null,
          product?:  {
            __typename: "Product",
            id: string,
            sku: string,
            title: string,
            description: string,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          description?: string | null,
          clientPays: number,
          solicitorReceives: number,
          subsidy: number,
          platformFee: number,
          feePercentage: number,
          scope?:  {
            __typename: "CaseScope",
            includes: Array< string >,
            excludes: Array< string >,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      payments?:  {
        __typename: "ModelCasePaymentConnection",
        items?:  Array< {
          __typename: "CasePayment",
          id: string,
          caseId: string,
          clientId: string,
          quoteId?: string | null,
          type: CasePaymentType,
          stripeAccountId: string,
          stripePaymentIntentId: string,
          toPlatform: boolean,
          amount: number,
          refunded?: boolean | null,
          solicitorId?: string | null,
          chargeType?: ChargeType | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    quoteId: string,
    caseLineItemProductId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      sku: string,
      title: string,
      description: string,
      lineItems?:  {
        __typename: "ModelCaseLineItemConnection",
        items?:  Array< {
          __typename: "CaseLineItem",
          id: string,
          quote:  {
            __typename: "CaseQuote",
            id: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            isLeadFromAds?: boolean | null,
            isManualFee?: boolean | null,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          quoteId: string,
          caseLineItemProductId?: string | null,
          product?:  {
            __typename: "Product",
            id: string,
            sku: string,
            title: string,
            description: string,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          description?: string | null,
          clientPays: number,
          solicitorReceives: number,
          subsidy: number,
          platformFee: number,
          feePercentage: number,
          scope?:  {
            __typename: "CaseScope",
            includes: Array< string >,
            excludes: Array< string >,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    description?: string | null,
    clientPays: number,
    solicitorReceives: number,
    subsidy: number,
    platformFee: number,
    feePercentage: number,
    scope?:  {
      __typename: "CaseScope",
      includes: Array< string >,
      excludes: Array< string >,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCaseCompletionRequestMutationVariables = {
  input?: CreateCaseCompletionRequestInput,
  condition?: ModelCaseCompletionRequestConditionInput | null,
};

export type CreateCaseCompletionRequestMutation = {
  createCaseCompletionRequest?:  {
    __typename: "CaseCompletionRequest",
    id: string,
    caseId: string,
    case:  {
      __typename: "Case",
      id: string,
      caseNumber: string,
      brand?: string | null,
      productSKU?: string | null,
      hasChatbot?: boolean | null,
      payFirst?: boolean | null,
      context?: string | null,
      createdAt: string,
      clientId: string,
      client?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientKyc?:  {
        __typename: "CaseClientKyc",
        isSubmitted?: boolean | null,
        isVerified?: boolean | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
      } | null,
      files?:  Array< {
        __typename: "File",
        name: string,
        key: string,
        lastModified: string,
        size: number,
      } > | null,
      details?:  {
        __typename: "CaseDetails",
        type: CaseDetailsType,
        isTimeSensitive?: boolean | null,
        details: string,
        summary?: string | null,
        ask?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items?:  Array< {
          __typename: "Message",
          id: string,
          author?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          authorId: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          content: string,
          type: MessageType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      quotes?:  {
        __typename: "ModelCaseQuoteConnection",
        items?:  Array< {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      payments?:  {
        __typename: "ModelCasePaymentConnection",
        items?:  Array< {
          __typename: "CasePayment",
          id: string,
          caseId: string,
          clientId: string,
          quoteId?: string | null,
          type: CasePaymentType,
          stripeAccountId: string,
          stripePaymentIntentId: string,
          toPlatform: boolean,
          amount: number,
          refunded?: boolean | null,
          solicitorId?: string | null,
          chargeType?: ChargeType | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      caseSolicitorId?: string | null,
      solicitor?:  {
        __typename: "Solicitor",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        name: string,
        businessName: string,
        sraRegistrationNumber?: string | null,
        stripeAccountId?: string | null,
        onboardingStatus?:  {
          __typename: "SolicitorOnboardingStatus",
          hasAccount: boolean,
          accountType: SolicitorAccountType,
          hasChargesEnabled: boolean,
          hasSubmitDetails: boolean,
          hasPayoutsEnabled: boolean,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      solicitorResponse?:  {
        __typename: "SolicitorResponse",
        type: SolicitorResponseType,
        solicitorId: string,
        reason?: string | null,
      } | null,
      completion?:  {
        __typename: "CaseCompletion",
        type?: CaseCompletionType | null,
        details?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items?:  Array< {
          __typename: "Review",
          id: string,
          solicitorId: string,
          caseId: string,
          clientId: string,
          rating: number,
          comment?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      status: CaseStatus,
      assignedToSolicitorAt?: string | null,
      askedClientToPayAt?: string | null,
      statusWhenCancelled?: string | null,
      exportedCaseId?: string | null,
      updatedAt: string,
    },
    requesterId: string,
    requester?:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    accepted?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCaseCompletionRequestMutationVariables = {
  input?: UpdateCaseCompletionRequestInput,
  condition?: ModelCaseCompletionRequestConditionInput | null,
};

export type UpdateCaseCompletionRequestMutation = {
  updateCaseCompletionRequest?:  {
    __typename: "CaseCompletionRequest",
    id: string,
    caseId: string,
    case:  {
      __typename: "Case",
      id: string,
      caseNumber: string,
      brand?: string | null,
      productSKU?: string | null,
      hasChatbot?: boolean | null,
      payFirst?: boolean | null,
      context?: string | null,
      createdAt: string,
      clientId: string,
      client?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientKyc?:  {
        __typename: "CaseClientKyc",
        isSubmitted?: boolean | null,
        isVerified?: boolean | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
      } | null,
      files?:  Array< {
        __typename: "File",
        name: string,
        key: string,
        lastModified: string,
        size: number,
      } > | null,
      details?:  {
        __typename: "CaseDetails",
        type: CaseDetailsType,
        isTimeSensitive?: boolean | null,
        details: string,
        summary?: string | null,
        ask?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items?:  Array< {
          __typename: "Message",
          id: string,
          author?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          authorId: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          content: string,
          type: MessageType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      quotes?:  {
        __typename: "ModelCaseQuoteConnection",
        items?:  Array< {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      payments?:  {
        __typename: "ModelCasePaymentConnection",
        items?:  Array< {
          __typename: "CasePayment",
          id: string,
          caseId: string,
          clientId: string,
          quoteId?: string | null,
          type: CasePaymentType,
          stripeAccountId: string,
          stripePaymentIntentId: string,
          toPlatform: boolean,
          amount: number,
          refunded?: boolean | null,
          solicitorId?: string | null,
          chargeType?: ChargeType | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      caseSolicitorId?: string | null,
      solicitor?:  {
        __typename: "Solicitor",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        name: string,
        businessName: string,
        sraRegistrationNumber?: string | null,
        stripeAccountId?: string | null,
        onboardingStatus?:  {
          __typename: "SolicitorOnboardingStatus",
          hasAccount: boolean,
          accountType: SolicitorAccountType,
          hasChargesEnabled: boolean,
          hasSubmitDetails: boolean,
          hasPayoutsEnabled: boolean,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      solicitorResponse?:  {
        __typename: "SolicitorResponse",
        type: SolicitorResponseType,
        solicitorId: string,
        reason?: string | null,
      } | null,
      completion?:  {
        __typename: "CaseCompletion",
        type?: CaseCompletionType | null,
        details?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items?:  Array< {
          __typename: "Review",
          id: string,
          solicitorId: string,
          caseId: string,
          clientId: string,
          rating: number,
          comment?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      status: CaseStatus,
      assignedToSolicitorAt?: string | null,
      askedClientToPayAt?: string | null,
      statusWhenCancelled?: string | null,
      exportedCaseId?: string | null,
      updatedAt: string,
    },
    requesterId: string,
    requester?:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    accepted?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCaseCompletionRequestMutationVariables = {
  input?: DeleteCaseCompletionRequestInput,
  condition?: ModelCaseCompletionRequestConditionInput | null,
};

export type DeleteCaseCompletionRequestMutation = {
  deleteCaseCompletionRequest?:  {
    __typename: "CaseCompletionRequest",
    id: string,
    caseId: string,
    case:  {
      __typename: "Case",
      id: string,
      caseNumber: string,
      brand?: string | null,
      productSKU?: string | null,
      hasChatbot?: boolean | null,
      payFirst?: boolean | null,
      context?: string | null,
      createdAt: string,
      clientId: string,
      client?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientKyc?:  {
        __typename: "CaseClientKyc",
        isSubmitted?: boolean | null,
        isVerified?: boolean | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
      } | null,
      files?:  Array< {
        __typename: "File",
        name: string,
        key: string,
        lastModified: string,
        size: number,
      } > | null,
      details?:  {
        __typename: "CaseDetails",
        type: CaseDetailsType,
        isTimeSensitive?: boolean | null,
        details: string,
        summary?: string | null,
        ask?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items?:  Array< {
          __typename: "Message",
          id: string,
          author?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          authorId: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          content: string,
          type: MessageType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      quotes?:  {
        __typename: "ModelCaseQuoteConnection",
        items?:  Array< {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      payments?:  {
        __typename: "ModelCasePaymentConnection",
        items?:  Array< {
          __typename: "CasePayment",
          id: string,
          caseId: string,
          clientId: string,
          quoteId?: string | null,
          type: CasePaymentType,
          stripeAccountId: string,
          stripePaymentIntentId: string,
          toPlatform: boolean,
          amount: number,
          refunded?: boolean | null,
          solicitorId?: string | null,
          chargeType?: ChargeType | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      caseSolicitorId?: string | null,
      solicitor?:  {
        __typename: "Solicitor",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        name: string,
        businessName: string,
        sraRegistrationNumber?: string | null,
        stripeAccountId?: string | null,
        onboardingStatus?:  {
          __typename: "SolicitorOnboardingStatus",
          hasAccount: boolean,
          accountType: SolicitorAccountType,
          hasChargesEnabled: boolean,
          hasSubmitDetails: boolean,
          hasPayoutsEnabled: boolean,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      solicitorResponse?:  {
        __typename: "SolicitorResponse",
        type: SolicitorResponseType,
        solicitorId: string,
        reason?: string | null,
      } | null,
      completion?:  {
        __typename: "CaseCompletion",
        type?: CaseCompletionType | null,
        details?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items?:  Array< {
          __typename: "Review",
          id: string,
          solicitorId: string,
          caseId: string,
          clientId: string,
          rating: number,
          comment?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      status: CaseStatus,
      assignedToSolicitorAt?: string | null,
      askedClientToPayAt?: string | null,
      statusWhenCancelled?: string | null,
      exportedCaseId?: string | null,
      updatedAt: string,
    },
    requesterId: string,
    requester?:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    accepted?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateConfigurationMutationVariables = {
  input?: CreateConfigurationInput,
  condition?: ModelConfigurationConditionInput | null,
};

export type CreateConfigurationMutation = {
  createConfiguration?:  {
    __typename: "Configuration",
    key: string,
    value: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateConfigurationMutationVariables = {
  input?: UpdateConfigurationInput,
  condition?: ModelConfigurationConditionInput | null,
};

export type UpdateConfigurationMutation = {
  updateConfiguration?:  {
    __typename: "Configuration",
    key: string,
    value: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteConfigurationMutationVariables = {
  input?: DeleteConfigurationInput,
  condition?: ModelConfigurationConditionInput | null,
};

export type DeleteConfigurationMutation = {
  deleteConfiguration?:  {
    __typename: "Configuration",
    key: string,
    value: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateKycSubmissionMutationVariables = {
  input?: CreateKYCSubmissionInput,
  condition?: ModelKYCSubmissionConditionInput | null,
};

export type CreateKycSubmissionMutation = {
  createKYCSubmission?:  {
    __typename: "KYCSubmission",
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    status?: KYCSubmissionStatus | null,
    address?: string | null,
    documentUploads?: Array< string > | null,
    identityUploads?: Array< string > | null,
    verificationReport?:  {
      __typename: "KycVerificationReport",
      amlReportFileKey: string,
      kycReportFileKey?: string | null,
    } | null,
    rejectionReason?:  {
      __typename: "KycRejectionReason",
      reason?: string | null,
      instructions?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateKycSubmissionMutationVariables = {
  input?: UpdateKYCSubmissionInput,
  condition?: ModelKYCSubmissionConditionInput | null,
};

export type UpdateKycSubmissionMutation = {
  updateKYCSubmission?:  {
    __typename: "KYCSubmission",
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    status?: KYCSubmissionStatus | null,
    address?: string | null,
    documentUploads?: Array< string > | null,
    identityUploads?: Array< string > | null,
    verificationReport?:  {
      __typename: "KycVerificationReport",
      amlReportFileKey: string,
      kycReportFileKey?: string | null,
    } | null,
    rejectionReason?:  {
      __typename: "KycRejectionReason",
      reason?: string | null,
      instructions?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteKycSubmissionMutationVariables = {
  input?: DeleteKYCSubmissionInput,
  condition?: ModelKYCSubmissionConditionInput | null,
};

export type DeleteKycSubmissionMutation = {
  deleteKYCSubmission?:  {
    __typename: "KYCSubmission",
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    status?: KYCSubmissionStatus | null,
    address?: string | null,
    documentUploads?: Array< string > | null,
    identityUploads?: Array< string > | null,
    verificationReport?:  {
      __typename: "KycVerificationReport",
      amlReportFileKey: string,
      kycReportFileKey?: string | null,
    } | null,
    rejectionReason?:  {
      __typename: "KycRejectionReason",
      reason?: string | null,
      instructions?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMessageMutationVariables = {
  input?: CreateMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type CreateMessageMutation = {
  createMessage?:  {
    __typename: "Message",
    id: string,
    author?:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    authorId: string,
    case:  {
      __typename: "Case",
      id: string,
      caseNumber: string,
      brand?: string | null,
      productSKU?: string | null,
      hasChatbot?: boolean | null,
      payFirst?: boolean | null,
      context?: string | null,
      createdAt: string,
      clientId: string,
      client?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientKyc?:  {
        __typename: "CaseClientKyc",
        isSubmitted?: boolean | null,
        isVerified?: boolean | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
      } | null,
      files?:  Array< {
        __typename: "File",
        name: string,
        key: string,
        lastModified: string,
        size: number,
      } > | null,
      details?:  {
        __typename: "CaseDetails",
        type: CaseDetailsType,
        isTimeSensitive?: boolean | null,
        details: string,
        summary?: string | null,
        ask?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items?:  Array< {
          __typename: "Message",
          id: string,
          author?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          authorId: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          content: string,
          type: MessageType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      quotes?:  {
        __typename: "ModelCaseQuoteConnection",
        items?:  Array< {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      payments?:  {
        __typename: "ModelCasePaymentConnection",
        items?:  Array< {
          __typename: "CasePayment",
          id: string,
          caseId: string,
          clientId: string,
          quoteId?: string | null,
          type: CasePaymentType,
          stripeAccountId: string,
          stripePaymentIntentId: string,
          toPlatform: boolean,
          amount: number,
          refunded?: boolean | null,
          solicitorId?: string | null,
          chargeType?: ChargeType | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      caseSolicitorId?: string | null,
      solicitor?:  {
        __typename: "Solicitor",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        name: string,
        businessName: string,
        sraRegistrationNumber?: string | null,
        stripeAccountId?: string | null,
        onboardingStatus?:  {
          __typename: "SolicitorOnboardingStatus",
          hasAccount: boolean,
          accountType: SolicitorAccountType,
          hasChargesEnabled: boolean,
          hasSubmitDetails: boolean,
          hasPayoutsEnabled: boolean,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      solicitorResponse?:  {
        __typename: "SolicitorResponse",
        type: SolicitorResponseType,
        solicitorId: string,
        reason?: string | null,
      } | null,
      completion?:  {
        __typename: "CaseCompletion",
        type?: CaseCompletionType | null,
        details?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items?:  Array< {
          __typename: "Review",
          id: string,
          solicitorId: string,
          caseId: string,
          clientId: string,
          rating: number,
          comment?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      status: CaseStatus,
      assignedToSolicitorAt?: string | null,
      askedClientToPayAt?: string | null,
      statusWhenCancelled?: string | null,
      exportedCaseId?: string | null,
      updatedAt: string,
    },
    caseId: string,
    content: string,
    type: MessageType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMessageMutationVariables = {
  input?: UpdateMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type UpdateMessageMutation = {
  updateMessage?:  {
    __typename: "Message",
    id: string,
    author?:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    authorId: string,
    case:  {
      __typename: "Case",
      id: string,
      caseNumber: string,
      brand?: string | null,
      productSKU?: string | null,
      hasChatbot?: boolean | null,
      payFirst?: boolean | null,
      context?: string | null,
      createdAt: string,
      clientId: string,
      client?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientKyc?:  {
        __typename: "CaseClientKyc",
        isSubmitted?: boolean | null,
        isVerified?: boolean | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
      } | null,
      files?:  Array< {
        __typename: "File",
        name: string,
        key: string,
        lastModified: string,
        size: number,
      } > | null,
      details?:  {
        __typename: "CaseDetails",
        type: CaseDetailsType,
        isTimeSensitive?: boolean | null,
        details: string,
        summary?: string | null,
        ask?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items?:  Array< {
          __typename: "Message",
          id: string,
          author?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          authorId: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          content: string,
          type: MessageType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      quotes?:  {
        __typename: "ModelCaseQuoteConnection",
        items?:  Array< {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      payments?:  {
        __typename: "ModelCasePaymentConnection",
        items?:  Array< {
          __typename: "CasePayment",
          id: string,
          caseId: string,
          clientId: string,
          quoteId?: string | null,
          type: CasePaymentType,
          stripeAccountId: string,
          stripePaymentIntentId: string,
          toPlatform: boolean,
          amount: number,
          refunded?: boolean | null,
          solicitorId?: string | null,
          chargeType?: ChargeType | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      caseSolicitorId?: string | null,
      solicitor?:  {
        __typename: "Solicitor",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        name: string,
        businessName: string,
        sraRegistrationNumber?: string | null,
        stripeAccountId?: string | null,
        onboardingStatus?:  {
          __typename: "SolicitorOnboardingStatus",
          hasAccount: boolean,
          accountType: SolicitorAccountType,
          hasChargesEnabled: boolean,
          hasSubmitDetails: boolean,
          hasPayoutsEnabled: boolean,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      solicitorResponse?:  {
        __typename: "SolicitorResponse",
        type: SolicitorResponseType,
        solicitorId: string,
        reason?: string | null,
      } | null,
      completion?:  {
        __typename: "CaseCompletion",
        type?: CaseCompletionType | null,
        details?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items?:  Array< {
          __typename: "Review",
          id: string,
          solicitorId: string,
          caseId: string,
          clientId: string,
          rating: number,
          comment?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      status: CaseStatus,
      assignedToSolicitorAt?: string | null,
      askedClientToPayAt?: string | null,
      statusWhenCancelled?: string | null,
      exportedCaseId?: string | null,
      updatedAt: string,
    },
    caseId: string,
    content: string,
    type: MessageType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMessageMutationVariables = {
  input?: DeleteMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type DeleteMessageMutation = {
  deleteMessage?:  {
    __typename: "Message",
    id: string,
    author?:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    authorId: string,
    case:  {
      __typename: "Case",
      id: string,
      caseNumber: string,
      brand?: string | null,
      productSKU?: string | null,
      hasChatbot?: boolean | null,
      payFirst?: boolean | null,
      context?: string | null,
      createdAt: string,
      clientId: string,
      client?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientKyc?:  {
        __typename: "CaseClientKyc",
        isSubmitted?: boolean | null,
        isVerified?: boolean | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
      } | null,
      files?:  Array< {
        __typename: "File",
        name: string,
        key: string,
        lastModified: string,
        size: number,
      } > | null,
      details?:  {
        __typename: "CaseDetails",
        type: CaseDetailsType,
        isTimeSensitive?: boolean | null,
        details: string,
        summary?: string | null,
        ask?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items?:  Array< {
          __typename: "Message",
          id: string,
          author?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          authorId: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          content: string,
          type: MessageType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      quotes?:  {
        __typename: "ModelCaseQuoteConnection",
        items?:  Array< {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      payments?:  {
        __typename: "ModelCasePaymentConnection",
        items?:  Array< {
          __typename: "CasePayment",
          id: string,
          caseId: string,
          clientId: string,
          quoteId?: string | null,
          type: CasePaymentType,
          stripeAccountId: string,
          stripePaymentIntentId: string,
          toPlatform: boolean,
          amount: number,
          refunded?: boolean | null,
          solicitorId?: string | null,
          chargeType?: ChargeType | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      caseSolicitorId?: string | null,
      solicitor?:  {
        __typename: "Solicitor",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        name: string,
        businessName: string,
        sraRegistrationNumber?: string | null,
        stripeAccountId?: string | null,
        onboardingStatus?:  {
          __typename: "SolicitorOnboardingStatus",
          hasAccount: boolean,
          accountType: SolicitorAccountType,
          hasChargesEnabled: boolean,
          hasSubmitDetails: boolean,
          hasPayoutsEnabled: boolean,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      solicitorResponse?:  {
        __typename: "SolicitorResponse",
        type: SolicitorResponseType,
        solicitorId: string,
        reason?: string | null,
      } | null,
      completion?:  {
        __typename: "CaseCompletion",
        type?: CaseCompletionType | null,
        details?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items?:  Array< {
          __typename: "Review",
          id: string,
          solicitorId: string,
          caseId: string,
          clientId: string,
          rating: number,
          comment?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      status: CaseStatus,
      assignedToSolicitorAt?: string | null,
      askedClientToPayAt?: string | null,
      statusWhenCancelled?: string | null,
      exportedCaseId?: string | null,
      updatedAt: string,
    },
    caseId: string,
    content: string,
    type: MessageType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateNotifcationEmailMutationVariables = {
  input?: CreateNotifcationEmailInput,
  condition?: ModelNotifcationEmailConditionInput | null,
};

export type CreateNotifcationEmailMutation = {
  createNotifcationEmail?:  {
    __typename: "NotifcationEmail",
    id: string,
    user:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    userId: string,
    caseId: string,
    case:  {
      __typename: "Case",
      id: string,
      caseNumber: string,
      brand?: string | null,
      productSKU?: string | null,
      hasChatbot?: boolean | null,
      payFirst?: boolean | null,
      context?: string | null,
      createdAt: string,
      clientId: string,
      client?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientKyc?:  {
        __typename: "CaseClientKyc",
        isSubmitted?: boolean | null,
        isVerified?: boolean | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
      } | null,
      files?:  Array< {
        __typename: "File",
        name: string,
        key: string,
        lastModified: string,
        size: number,
      } > | null,
      details?:  {
        __typename: "CaseDetails",
        type: CaseDetailsType,
        isTimeSensitive?: boolean | null,
        details: string,
        summary?: string | null,
        ask?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items?:  Array< {
          __typename: "Message",
          id: string,
          author?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          authorId: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          content: string,
          type: MessageType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      quotes?:  {
        __typename: "ModelCaseQuoteConnection",
        items?:  Array< {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      payments?:  {
        __typename: "ModelCasePaymentConnection",
        items?:  Array< {
          __typename: "CasePayment",
          id: string,
          caseId: string,
          clientId: string,
          quoteId?: string | null,
          type: CasePaymentType,
          stripeAccountId: string,
          stripePaymentIntentId: string,
          toPlatform: boolean,
          amount: number,
          refunded?: boolean | null,
          solicitorId?: string | null,
          chargeType?: ChargeType | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      caseSolicitorId?: string | null,
      solicitor?:  {
        __typename: "Solicitor",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        name: string,
        businessName: string,
        sraRegistrationNumber?: string | null,
        stripeAccountId?: string | null,
        onboardingStatus?:  {
          __typename: "SolicitorOnboardingStatus",
          hasAccount: boolean,
          accountType: SolicitorAccountType,
          hasChargesEnabled: boolean,
          hasSubmitDetails: boolean,
          hasPayoutsEnabled: boolean,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      solicitorResponse?:  {
        __typename: "SolicitorResponse",
        type: SolicitorResponseType,
        solicitorId: string,
        reason?: string | null,
      } | null,
      completion?:  {
        __typename: "CaseCompletion",
        type?: CaseCompletionType | null,
        details?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items?:  Array< {
          __typename: "Review",
          id: string,
          solicitorId: string,
          caseId: string,
          clientId: string,
          rating: number,
          comment?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      status: CaseStatus,
      assignedToSolicitorAt?: string | null,
      askedClientToPayAt?: string | null,
      statusWhenCancelled?: string | null,
      exportedCaseId?: string | null,
      updatedAt: string,
    },
    type?: NotificationType | null,
    lastSentAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateNotifcationEmailMutationVariables = {
  input?: UpdateNotifcationEmailInput,
  condition?: ModelNotifcationEmailConditionInput | null,
};

export type UpdateNotifcationEmailMutation = {
  updateNotifcationEmail?:  {
    __typename: "NotifcationEmail",
    id: string,
    user:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    userId: string,
    caseId: string,
    case:  {
      __typename: "Case",
      id: string,
      caseNumber: string,
      brand?: string | null,
      productSKU?: string | null,
      hasChatbot?: boolean | null,
      payFirst?: boolean | null,
      context?: string | null,
      createdAt: string,
      clientId: string,
      client?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientKyc?:  {
        __typename: "CaseClientKyc",
        isSubmitted?: boolean | null,
        isVerified?: boolean | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
      } | null,
      files?:  Array< {
        __typename: "File",
        name: string,
        key: string,
        lastModified: string,
        size: number,
      } > | null,
      details?:  {
        __typename: "CaseDetails",
        type: CaseDetailsType,
        isTimeSensitive?: boolean | null,
        details: string,
        summary?: string | null,
        ask?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items?:  Array< {
          __typename: "Message",
          id: string,
          author?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          authorId: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          content: string,
          type: MessageType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      quotes?:  {
        __typename: "ModelCaseQuoteConnection",
        items?:  Array< {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      payments?:  {
        __typename: "ModelCasePaymentConnection",
        items?:  Array< {
          __typename: "CasePayment",
          id: string,
          caseId: string,
          clientId: string,
          quoteId?: string | null,
          type: CasePaymentType,
          stripeAccountId: string,
          stripePaymentIntentId: string,
          toPlatform: boolean,
          amount: number,
          refunded?: boolean | null,
          solicitorId?: string | null,
          chargeType?: ChargeType | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      caseSolicitorId?: string | null,
      solicitor?:  {
        __typename: "Solicitor",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        name: string,
        businessName: string,
        sraRegistrationNumber?: string | null,
        stripeAccountId?: string | null,
        onboardingStatus?:  {
          __typename: "SolicitorOnboardingStatus",
          hasAccount: boolean,
          accountType: SolicitorAccountType,
          hasChargesEnabled: boolean,
          hasSubmitDetails: boolean,
          hasPayoutsEnabled: boolean,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      solicitorResponse?:  {
        __typename: "SolicitorResponse",
        type: SolicitorResponseType,
        solicitorId: string,
        reason?: string | null,
      } | null,
      completion?:  {
        __typename: "CaseCompletion",
        type?: CaseCompletionType | null,
        details?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items?:  Array< {
          __typename: "Review",
          id: string,
          solicitorId: string,
          caseId: string,
          clientId: string,
          rating: number,
          comment?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      status: CaseStatus,
      assignedToSolicitorAt?: string | null,
      askedClientToPayAt?: string | null,
      statusWhenCancelled?: string | null,
      exportedCaseId?: string | null,
      updatedAt: string,
    },
    type?: NotificationType | null,
    lastSentAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteNotifcationEmailMutationVariables = {
  input?: DeleteNotifcationEmailInput,
  condition?: ModelNotifcationEmailConditionInput | null,
};

export type DeleteNotifcationEmailMutation = {
  deleteNotifcationEmail?:  {
    __typename: "NotifcationEmail",
    id: string,
    user:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    userId: string,
    caseId: string,
    case:  {
      __typename: "Case",
      id: string,
      caseNumber: string,
      brand?: string | null,
      productSKU?: string | null,
      hasChatbot?: boolean | null,
      payFirst?: boolean | null,
      context?: string | null,
      createdAt: string,
      clientId: string,
      client?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientKyc?:  {
        __typename: "CaseClientKyc",
        isSubmitted?: boolean | null,
        isVerified?: boolean | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
      } | null,
      files?:  Array< {
        __typename: "File",
        name: string,
        key: string,
        lastModified: string,
        size: number,
      } > | null,
      details?:  {
        __typename: "CaseDetails",
        type: CaseDetailsType,
        isTimeSensitive?: boolean | null,
        details: string,
        summary?: string | null,
        ask?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items?:  Array< {
          __typename: "Message",
          id: string,
          author?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          authorId: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          content: string,
          type: MessageType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      quotes?:  {
        __typename: "ModelCaseQuoteConnection",
        items?:  Array< {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      payments?:  {
        __typename: "ModelCasePaymentConnection",
        items?:  Array< {
          __typename: "CasePayment",
          id: string,
          caseId: string,
          clientId: string,
          quoteId?: string | null,
          type: CasePaymentType,
          stripeAccountId: string,
          stripePaymentIntentId: string,
          toPlatform: boolean,
          amount: number,
          refunded?: boolean | null,
          solicitorId?: string | null,
          chargeType?: ChargeType | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      caseSolicitorId?: string | null,
      solicitor?:  {
        __typename: "Solicitor",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        name: string,
        businessName: string,
        sraRegistrationNumber?: string | null,
        stripeAccountId?: string | null,
        onboardingStatus?:  {
          __typename: "SolicitorOnboardingStatus",
          hasAccount: boolean,
          accountType: SolicitorAccountType,
          hasChargesEnabled: boolean,
          hasSubmitDetails: boolean,
          hasPayoutsEnabled: boolean,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      solicitorResponse?:  {
        __typename: "SolicitorResponse",
        type: SolicitorResponseType,
        solicitorId: string,
        reason?: string | null,
      } | null,
      completion?:  {
        __typename: "CaseCompletion",
        type?: CaseCompletionType | null,
        details?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items?:  Array< {
          __typename: "Review",
          id: string,
          solicitorId: string,
          caseId: string,
          clientId: string,
          rating: number,
          comment?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      status: CaseStatus,
      assignedToSolicitorAt?: string | null,
      askedClientToPayAt?: string | null,
      statusWhenCancelled?: string | null,
      exportedCaseId?: string | null,
      updatedAt: string,
    },
    type?: NotificationType | null,
    lastSentAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateProductMutationVariables = {
  input?: CreateProductInput,
  condition?: ModelProductConditionInput | null,
};

export type CreateProductMutation = {
  createProduct?:  {
    __typename: "Product",
    id: string,
    sku: string,
    title: string,
    description: string,
    lineItems?:  {
      __typename: "ModelCaseLineItemConnection",
      items?:  Array< {
        __typename: "CaseLineItem",
        id: string,
        quote:  {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        quoteId: string,
        caseLineItemProductId?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          sku: string,
          title: string,
          description: string,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        description?: string | null,
        clientPays: number,
        solicitorReceives: number,
        subsidy: number,
        platformFee: number,
        feePercentage: number,
        scope?:  {
          __typename: "CaseScope",
          includes: Array< string >,
          excludes: Array< string >,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateProductMutationVariables = {
  input?: UpdateProductInput,
  condition?: ModelProductConditionInput | null,
};

export type UpdateProductMutation = {
  updateProduct?:  {
    __typename: "Product",
    id: string,
    sku: string,
    title: string,
    description: string,
    lineItems?:  {
      __typename: "ModelCaseLineItemConnection",
      items?:  Array< {
        __typename: "CaseLineItem",
        id: string,
        quote:  {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        quoteId: string,
        caseLineItemProductId?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          sku: string,
          title: string,
          description: string,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        description?: string | null,
        clientPays: number,
        solicitorReceives: number,
        subsidy: number,
        platformFee: number,
        feePercentage: number,
        scope?:  {
          __typename: "CaseScope",
          includes: Array< string >,
          excludes: Array< string >,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteProductMutationVariables = {
  input?: DeleteProductInput,
  condition?: ModelProductConditionInput | null,
};

export type DeleteProductMutation = {
  deleteProduct?:  {
    __typename: "Product",
    id: string,
    sku: string,
    title: string,
    description: string,
    lineItems?:  {
      __typename: "ModelCaseLineItemConnection",
      items?:  Array< {
        __typename: "CaseLineItem",
        id: string,
        quote:  {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        quoteId: string,
        caseLineItemProductId?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          sku: string,
          title: string,
          description: string,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        description?: string | null,
        clientPays: number,
        solicitorReceives: number,
        subsidy: number,
        platformFee: number,
        feePercentage: number,
        scope?:  {
          __typename: "CaseScope",
          includes: Array< string >,
          excludes: Array< string >,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSolicitorMutationVariables = {
  input?: CreateSolicitorInput,
  condition?: ModelSolicitorConditionInput | null,
};

export type CreateSolicitorMutation = {
  createSolicitor?:  {
    __typename: "Solicitor",
    id: string,
    user?:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    businessName: string,
    sraRegistrationNumber?: string | null,
    stripeAccountId?: string | null,
    onboardingStatus?:  {
      __typename: "SolicitorOnboardingStatus",
      hasAccount: boolean,
      accountType: SolicitorAccountType,
      hasChargesEnabled: boolean,
      hasSubmitDetails: boolean,
      hasPayoutsEnabled: boolean,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSolicitorMutationVariables = {
  input?: UpdateSolicitorInput,
  condition?: ModelSolicitorConditionInput | null,
};

export type UpdateSolicitorMutation = {
  updateSolicitor?:  {
    __typename: "Solicitor",
    id: string,
    user?:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    businessName: string,
    sraRegistrationNumber?: string | null,
    stripeAccountId?: string | null,
    onboardingStatus?:  {
      __typename: "SolicitorOnboardingStatus",
      hasAccount: boolean,
      accountType: SolicitorAccountType,
      hasChargesEnabled: boolean,
      hasSubmitDetails: boolean,
      hasPayoutsEnabled: boolean,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSolicitorMutationVariables = {
  input?: DeleteSolicitorInput,
  condition?: ModelSolicitorConditionInput | null,
};

export type DeleteSolicitorMutation = {
  deleteSolicitor?:  {
    __typename: "Solicitor",
    id: string,
    user?:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    businessName: string,
    sraRegistrationNumber?: string | null,
    stripeAccountId?: string | null,
    onboardingStatus?:  {
      __typename: "SolicitorOnboardingStatus",
      hasAccount: boolean,
      accountType: SolicitorAccountType,
      hasChargesEnabled: boolean,
      hasSubmitDetails: boolean,
      hasPayoutsEnabled: boolean,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserMutationVariables = {
  input?: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    type?: UserType | null,
    emailAddress?: string | null,
    displayName?: string | null,
    phoneNumber?: string | null,
    kycSubmission?:  {
      __typename: "KYCSubmission",
      userId: string,
      user:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      status?: KYCSubmissionStatus | null,
      address?: string | null,
      documentUploads?: Array< string > | null,
      identityUploads?: Array< string > | null,
      verificationReport?:  {
        __typename: "KycVerificationReport",
        amlReportFileKey: string,
        kycReportFileKey?: string | null,
      } | null,
      rejectionReason?:  {
        __typename: "KycRejectionReason",
        reason?: string | null,
        instructions?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    stripeCustomerId?: string | null,
    paymentMethods?:  Array< {
      __typename: "PaymentMethod",
      id: string,
      lastFour: string,
      brand: string,
      expiryMonth: number,
      expiryYear: number,
    } > | null,
    clientCases?:  {
      __typename: "ModelCaseConnection",
      items?:  Array< {
        __typename: "Case",
        id: string,
        caseNumber: string,
        brand?: string | null,
        productSKU?: string | null,
        hasChatbot?: boolean | null,
        payFirst?: boolean | null,
        context?: string | null,
        createdAt: string,
        clientId: string,
        client?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientKyc?:  {
          __typename: "CaseClientKyc",
          isSubmitted?: boolean | null,
          isVerified?: boolean | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
        } | null,
        files?:  Array< {
          __typename: "File",
          name: string,
          key: string,
          lastModified: string,
          size: number,
        } > | null,
        details?:  {
          __typename: "CaseDetails",
          type: CaseDetailsType,
          isTimeSensitive?: boolean | null,
          details: string,
          summary?: string | null,
          ask?: string | null,
        } | null,
        messages?:  {
          __typename: "ModelMessageConnection",
          items?:  Array< {
            __typename: "Message",
            id: string,
            author?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            authorId: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            content: string,
            type: MessageType,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        quotes?:  {
          __typename: "ModelCaseQuoteConnection",
          items?:  Array< {
            __typename: "CaseQuote",
            id: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            isLeadFromAds?: boolean | null,
            isManualFee?: boolean | null,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        caseSolicitorId?: string | null,
        solicitor?:  {
          __typename: "Solicitor",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          name: string,
          businessName: string,
          sraRegistrationNumber?: string | null,
          stripeAccountId?: string | null,
          onboardingStatus?:  {
            __typename: "SolicitorOnboardingStatus",
            hasAccount: boolean,
            accountType: SolicitorAccountType,
            hasChargesEnabled: boolean,
            hasSubmitDetails: boolean,
            hasPayoutsEnabled: boolean,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        solicitorResponse?:  {
          __typename: "SolicitorResponse",
          type: SolicitorResponseType,
          solicitorId: string,
          reason?: string | null,
        } | null,
        completion?:  {
          __typename: "CaseCompletion",
          type?: CaseCompletionType | null,
          details?: string | null,
        } | null,
        review?:  {
          __typename: "ModelReviewConnection",
          items?:  Array< {
            __typename: "Review",
            id: string,
            solicitorId: string,
            caseId: string,
            clientId: string,
            rating: number,
            comment?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        status: CaseStatus,
        assignedToSolicitorAt?: string | null,
        askedClientToPayAt?: string | null,
        statusWhenCancelled?: string | null,
        exportedCaseId?: string | null,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    solicitorCases?:  {
      __typename: "ModelCaseConnection",
      items?:  Array< {
        __typename: "Case",
        id: string,
        caseNumber: string,
        brand?: string | null,
        productSKU?: string | null,
        hasChatbot?: boolean | null,
        payFirst?: boolean | null,
        context?: string | null,
        createdAt: string,
        clientId: string,
        client?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientKyc?:  {
          __typename: "CaseClientKyc",
          isSubmitted?: boolean | null,
          isVerified?: boolean | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
        } | null,
        files?:  Array< {
          __typename: "File",
          name: string,
          key: string,
          lastModified: string,
          size: number,
        } > | null,
        details?:  {
          __typename: "CaseDetails",
          type: CaseDetailsType,
          isTimeSensitive?: boolean | null,
          details: string,
          summary?: string | null,
          ask?: string | null,
        } | null,
        messages?:  {
          __typename: "ModelMessageConnection",
          items?:  Array< {
            __typename: "Message",
            id: string,
            author?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            authorId: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            content: string,
            type: MessageType,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        quotes?:  {
          __typename: "ModelCaseQuoteConnection",
          items?:  Array< {
            __typename: "CaseQuote",
            id: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            isLeadFromAds?: boolean | null,
            isManualFee?: boolean | null,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        caseSolicitorId?: string | null,
        solicitor?:  {
          __typename: "Solicitor",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          name: string,
          businessName: string,
          sraRegistrationNumber?: string | null,
          stripeAccountId?: string | null,
          onboardingStatus?:  {
            __typename: "SolicitorOnboardingStatus",
            hasAccount: boolean,
            accountType: SolicitorAccountType,
            hasChargesEnabled: boolean,
            hasSubmitDetails: boolean,
            hasPayoutsEnabled: boolean,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        solicitorResponse?:  {
          __typename: "SolicitorResponse",
          type: SolicitorResponseType,
          solicitorId: string,
          reason?: string | null,
        } | null,
        completion?:  {
          __typename: "CaseCompletion",
          type?: CaseCompletionType | null,
          details?: string | null,
        } | null,
        review?:  {
          __typename: "ModelReviewConnection",
          items?:  Array< {
            __typename: "Review",
            id: string,
            solicitorId: string,
            caseId: string,
            clientId: string,
            rating: number,
            comment?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        status: CaseStatus,
        assignedToSolicitorAt?: string | null,
        askedClientToPayAt?: string | null,
        statusWhenCancelled?: string | null,
        exportedCaseId?: string | null,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    referral?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserMutationVariables = {
  input?: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    type?: UserType | null,
    emailAddress?: string | null,
    displayName?: string | null,
    phoneNumber?: string | null,
    kycSubmission?:  {
      __typename: "KYCSubmission",
      userId: string,
      user:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      status?: KYCSubmissionStatus | null,
      address?: string | null,
      documentUploads?: Array< string > | null,
      identityUploads?: Array< string > | null,
      verificationReport?:  {
        __typename: "KycVerificationReport",
        amlReportFileKey: string,
        kycReportFileKey?: string | null,
      } | null,
      rejectionReason?:  {
        __typename: "KycRejectionReason",
        reason?: string | null,
        instructions?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    stripeCustomerId?: string | null,
    paymentMethods?:  Array< {
      __typename: "PaymentMethod",
      id: string,
      lastFour: string,
      brand: string,
      expiryMonth: number,
      expiryYear: number,
    } > | null,
    clientCases?:  {
      __typename: "ModelCaseConnection",
      items?:  Array< {
        __typename: "Case",
        id: string,
        caseNumber: string,
        brand?: string | null,
        productSKU?: string | null,
        hasChatbot?: boolean | null,
        payFirst?: boolean | null,
        context?: string | null,
        createdAt: string,
        clientId: string,
        client?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientKyc?:  {
          __typename: "CaseClientKyc",
          isSubmitted?: boolean | null,
          isVerified?: boolean | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
        } | null,
        files?:  Array< {
          __typename: "File",
          name: string,
          key: string,
          lastModified: string,
          size: number,
        } > | null,
        details?:  {
          __typename: "CaseDetails",
          type: CaseDetailsType,
          isTimeSensitive?: boolean | null,
          details: string,
          summary?: string | null,
          ask?: string | null,
        } | null,
        messages?:  {
          __typename: "ModelMessageConnection",
          items?:  Array< {
            __typename: "Message",
            id: string,
            author?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            authorId: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            content: string,
            type: MessageType,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        quotes?:  {
          __typename: "ModelCaseQuoteConnection",
          items?:  Array< {
            __typename: "CaseQuote",
            id: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            isLeadFromAds?: boolean | null,
            isManualFee?: boolean | null,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        caseSolicitorId?: string | null,
        solicitor?:  {
          __typename: "Solicitor",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          name: string,
          businessName: string,
          sraRegistrationNumber?: string | null,
          stripeAccountId?: string | null,
          onboardingStatus?:  {
            __typename: "SolicitorOnboardingStatus",
            hasAccount: boolean,
            accountType: SolicitorAccountType,
            hasChargesEnabled: boolean,
            hasSubmitDetails: boolean,
            hasPayoutsEnabled: boolean,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        solicitorResponse?:  {
          __typename: "SolicitorResponse",
          type: SolicitorResponseType,
          solicitorId: string,
          reason?: string | null,
        } | null,
        completion?:  {
          __typename: "CaseCompletion",
          type?: CaseCompletionType | null,
          details?: string | null,
        } | null,
        review?:  {
          __typename: "ModelReviewConnection",
          items?:  Array< {
            __typename: "Review",
            id: string,
            solicitorId: string,
            caseId: string,
            clientId: string,
            rating: number,
            comment?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        status: CaseStatus,
        assignedToSolicitorAt?: string | null,
        askedClientToPayAt?: string | null,
        statusWhenCancelled?: string | null,
        exportedCaseId?: string | null,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    solicitorCases?:  {
      __typename: "ModelCaseConnection",
      items?:  Array< {
        __typename: "Case",
        id: string,
        caseNumber: string,
        brand?: string | null,
        productSKU?: string | null,
        hasChatbot?: boolean | null,
        payFirst?: boolean | null,
        context?: string | null,
        createdAt: string,
        clientId: string,
        client?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientKyc?:  {
          __typename: "CaseClientKyc",
          isSubmitted?: boolean | null,
          isVerified?: boolean | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
        } | null,
        files?:  Array< {
          __typename: "File",
          name: string,
          key: string,
          lastModified: string,
          size: number,
        } > | null,
        details?:  {
          __typename: "CaseDetails",
          type: CaseDetailsType,
          isTimeSensitive?: boolean | null,
          details: string,
          summary?: string | null,
          ask?: string | null,
        } | null,
        messages?:  {
          __typename: "ModelMessageConnection",
          items?:  Array< {
            __typename: "Message",
            id: string,
            author?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            authorId: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            content: string,
            type: MessageType,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        quotes?:  {
          __typename: "ModelCaseQuoteConnection",
          items?:  Array< {
            __typename: "CaseQuote",
            id: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            isLeadFromAds?: boolean | null,
            isManualFee?: boolean | null,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        caseSolicitorId?: string | null,
        solicitor?:  {
          __typename: "Solicitor",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          name: string,
          businessName: string,
          sraRegistrationNumber?: string | null,
          stripeAccountId?: string | null,
          onboardingStatus?:  {
            __typename: "SolicitorOnboardingStatus",
            hasAccount: boolean,
            accountType: SolicitorAccountType,
            hasChargesEnabled: boolean,
            hasSubmitDetails: boolean,
            hasPayoutsEnabled: boolean,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        solicitorResponse?:  {
          __typename: "SolicitorResponse",
          type: SolicitorResponseType,
          solicitorId: string,
          reason?: string | null,
        } | null,
        completion?:  {
          __typename: "CaseCompletion",
          type?: CaseCompletionType | null,
          details?: string | null,
        } | null,
        review?:  {
          __typename: "ModelReviewConnection",
          items?:  Array< {
            __typename: "Review",
            id: string,
            solicitorId: string,
            caseId: string,
            clientId: string,
            rating: number,
            comment?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        status: CaseStatus,
        assignedToSolicitorAt?: string | null,
        askedClientToPayAt?: string | null,
        statusWhenCancelled?: string | null,
        exportedCaseId?: string | null,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    referral?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserMutationVariables = {
  input?: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    type?: UserType | null,
    emailAddress?: string | null,
    displayName?: string | null,
    phoneNumber?: string | null,
    kycSubmission?:  {
      __typename: "KYCSubmission",
      userId: string,
      user:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      status?: KYCSubmissionStatus | null,
      address?: string | null,
      documentUploads?: Array< string > | null,
      identityUploads?: Array< string > | null,
      verificationReport?:  {
        __typename: "KycVerificationReport",
        amlReportFileKey: string,
        kycReportFileKey?: string | null,
      } | null,
      rejectionReason?:  {
        __typename: "KycRejectionReason",
        reason?: string | null,
        instructions?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    stripeCustomerId?: string | null,
    paymentMethods?:  Array< {
      __typename: "PaymentMethod",
      id: string,
      lastFour: string,
      brand: string,
      expiryMonth: number,
      expiryYear: number,
    } > | null,
    clientCases?:  {
      __typename: "ModelCaseConnection",
      items?:  Array< {
        __typename: "Case",
        id: string,
        caseNumber: string,
        brand?: string | null,
        productSKU?: string | null,
        hasChatbot?: boolean | null,
        payFirst?: boolean | null,
        context?: string | null,
        createdAt: string,
        clientId: string,
        client?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientKyc?:  {
          __typename: "CaseClientKyc",
          isSubmitted?: boolean | null,
          isVerified?: boolean | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
        } | null,
        files?:  Array< {
          __typename: "File",
          name: string,
          key: string,
          lastModified: string,
          size: number,
        } > | null,
        details?:  {
          __typename: "CaseDetails",
          type: CaseDetailsType,
          isTimeSensitive?: boolean | null,
          details: string,
          summary?: string | null,
          ask?: string | null,
        } | null,
        messages?:  {
          __typename: "ModelMessageConnection",
          items?:  Array< {
            __typename: "Message",
            id: string,
            author?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            authorId: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            content: string,
            type: MessageType,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        quotes?:  {
          __typename: "ModelCaseQuoteConnection",
          items?:  Array< {
            __typename: "CaseQuote",
            id: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            isLeadFromAds?: boolean | null,
            isManualFee?: boolean | null,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        caseSolicitorId?: string | null,
        solicitor?:  {
          __typename: "Solicitor",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          name: string,
          businessName: string,
          sraRegistrationNumber?: string | null,
          stripeAccountId?: string | null,
          onboardingStatus?:  {
            __typename: "SolicitorOnboardingStatus",
            hasAccount: boolean,
            accountType: SolicitorAccountType,
            hasChargesEnabled: boolean,
            hasSubmitDetails: boolean,
            hasPayoutsEnabled: boolean,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        solicitorResponse?:  {
          __typename: "SolicitorResponse",
          type: SolicitorResponseType,
          solicitorId: string,
          reason?: string | null,
        } | null,
        completion?:  {
          __typename: "CaseCompletion",
          type?: CaseCompletionType | null,
          details?: string | null,
        } | null,
        review?:  {
          __typename: "ModelReviewConnection",
          items?:  Array< {
            __typename: "Review",
            id: string,
            solicitorId: string,
            caseId: string,
            clientId: string,
            rating: number,
            comment?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        status: CaseStatus,
        assignedToSolicitorAt?: string | null,
        askedClientToPayAt?: string | null,
        statusWhenCancelled?: string | null,
        exportedCaseId?: string | null,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    solicitorCases?:  {
      __typename: "ModelCaseConnection",
      items?:  Array< {
        __typename: "Case",
        id: string,
        caseNumber: string,
        brand?: string | null,
        productSKU?: string | null,
        hasChatbot?: boolean | null,
        payFirst?: boolean | null,
        context?: string | null,
        createdAt: string,
        clientId: string,
        client?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientKyc?:  {
          __typename: "CaseClientKyc",
          isSubmitted?: boolean | null,
          isVerified?: boolean | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
        } | null,
        files?:  Array< {
          __typename: "File",
          name: string,
          key: string,
          lastModified: string,
          size: number,
        } > | null,
        details?:  {
          __typename: "CaseDetails",
          type: CaseDetailsType,
          isTimeSensitive?: boolean | null,
          details: string,
          summary?: string | null,
          ask?: string | null,
        } | null,
        messages?:  {
          __typename: "ModelMessageConnection",
          items?:  Array< {
            __typename: "Message",
            id: string,
            author?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            authorId: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            content: string,
            type: MessageType,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        quotes?:  {
          __typename: "ModelCaseQuoteConnection",
          items?:  Array< {
            __typename: "CaseQuote",
            id: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            isLeadFromAds?: boolean | null,
            isManualFee?: boolean | null,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        caseSolicitorId?: string | null,
        solicitor?:  {
          __typename: "Solicitor",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          name: string,
          businessName: string,
          sraRegistrationNumber?: string | null,
          stripeAccountId?: string | null,
          onboardingStatus?:  {
            __typename: "SolicitorOnboardingStatus",
            hasAccount: boolean,
            accountType: SolicitorAccountType,
            hasChargesEnabled: boolean,
            hasSubmitDetails: boolean,
            hasPayoutsEnabled: boolean,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        solicitorResponse?:  {
          __typename: "SolicitorResponse",
          type: SolicitorResponseType,
          solicitorId: string,
          reason?: string | null,
        } | null,
        completion?:  {
          __typename: "CaseCompletion",
          type?: CaseCompletionType | null,
          details?: string | null,
        } | null,
        review?:  {
          __typename: "ModelReviewConnection",
          items?:  Array< {
            __typename: "Review",
            id: string,
            solicitorId: string,
            caseId: string,
            clientId: string,
            rating: number,
            comment?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        status: CaseStatus,
        assignedToSolicitorAt?: string | null,
        askedClientToPayAt?: string | null,
        statusWhenCancelled?: string | null,
        exportedCaseId?: string | null,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    referral?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type NoopQQuery = {
  noopQ?: boolean | null,
};

export type ClientGetAddressFromPostcodeQueryQueryVariables = {
  postCode?: string | null,
};

export type ClientGetAddressFromPostcodeQueryQuery = {
  clientGetAddressFromPostcodeQuery?:  {
    __typename: "GetAddressFromPostcodeQueryResult",
    addresses:  Array< {
      __typename: "AddressLookupResult",
      display: string,
    } >,
  } | null,
};

export type ClientGetIntercomTokenQuery = {
  clientGetIntercomToken?:  {
    __typename: "GetIntercomTokenResult",
    token: string,
  } | null,
};

export type GetCaseQueryVariables = {
  id?: string,
};

export type GetCaseQuery = {
  getCase?:  {
    __typename: "Case",
    id: string,
    caseNumber: string,
    brand?: string | null,
    productSKU?: string | null,
    hasChatbot?: boolean | null,
    payFirst?: boolean | null,
    context?: string | null,
    createdAt: string,
    clientId: string,
    client?:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    clientKyc?:  {
      __typename: "CaseClientKyc",
      isSubmitted?: boolean | null,
      isVerified?: boolean | null,
      verificationReport?:  {
        __typename: "KycVerificationReport",
        amlReportFileKey: string,
        kycReportFileKey?: string | null,
      } | null,
      documentUploads?: Array< string > | null,
      identityUploads?: Array< string > | null,
    } | null,
    files?:  Array< {
      __typename: "File",
      name: string,
      key: string,
      lastModified: string,
      size: number,
    } > | null,
    details?:  {
      __typename: "CaseDetails",
      type: CaseDetailsType,
      isTimeSensitive?: boolean | null,
      details: string,
      summary?: string | null,
      ask?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items?:  Array< {
        __typename: "Message",
        id: string,
        author?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        authorId: string,
        case:  {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        },
        caseId: string,
        content: string,
        type: MessageType,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    quotes?:  {
      __typename: "ModelCaseQuoteConnection",
      items?:  Array< {
        __typename: "CaseQuote",
        id: string,
        case:  {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        },
        caseId: string,
        isLeadFromAds?: boolean | null,
        isManualFee?: boolean | null,
        lineItems?:  {
          __typename: "ModelCaseLineItemConnection",
          items?:  Array< {
            __typename: "CaseLineItem",
            id: string,
            quote:  {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            },
            quoteId: string,
            caseLineItemProductId?: string | null,
            product?:  {
              __typename: "Product",
              id: string,
              sku: string,
              title: string,
              description: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            description?: string | null,
            clientPays: number,
            solicitorReceives: number,
            subsidy: number,
            platformFee: number,
            feePercentage: number,
            scope?:  {
              __typename: "CaseScope",
              includes: Array< string >,
              excludes: Array< string >,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    payments?:  {
      __typename: "ModelCasePaymentConnection",
      items?:  Array< {
        __typename: "CasePayment",
        id: string,
        caseId: string,
        clientId: string,
        quoteId?: string | null,
        type: CasePaymentType,
        stripeAccountId: string,
        stripePaymentIntentId: string,
        toPlatform: boolean,
        amount: number,
        refunded?: boolean | null,
        solicitorId?: string | null,
        chargeType?: ChargeType | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    caseSolicitorId?: string | null,
    solicitor?:  {
      __typename: "Solicitor",
      id: string,
      user?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      name: string,
      businessName: string,
      sraRegistrationNumber?: string | null,
      stripeAccountId?: string | null,
      onboardingStatus?:  {
        __typename: "SolicitorOnboardingStatus",
        hasAccount: boolean,
        accountType: SolicitorAccountType,
        hasChargesEnabled: boolean,
        hasSubmitDetails: boolean,
        hasPayoutsEnabled: boolean,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    solicitorResponse?:  {
      __typename: "SolicitorResponse",
      type: SolicitorResponseType,
      solicitorId: string,
      reason?: string | null,
    } | null,
    completion?:  {
      __typename: "CaseCompletion",
      type?: CaseCompletionType | null,
      details?: string | null,
    } | null,
    review?:  {
      __typename: "ModelReviewConnection",
      items?:  Array< {
        __typename: "Review",
        id: string,
        solicitorId: string,
        caseId: string,
        clientId: string,
        rating: number,
        comment?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    status: CaseStatus,
    assignedToSolicitorAt?: string | null,
    askedClientToPayAt?: string | null,
    statusWhenCancelled?: string | null,
    exportedCaseId?: string | null,
    updatedAt: string,
  } | null,
};

export type ListCasesQueryVariables = {
  filter?: ModelCaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCasesQuery = {
  listCases?:  {
    __typename: "ModelCaseConnection",
    items?:  Array< {
      __typename: "Case",
      id: string,
      caseNumber: string,
      brand?: string | null,
      productSKU?: string | null,
      hasChatbot?: boolean | null,
      payFirst?: boolean | null,
      context?: string | null,
      createdAt: string,
      clientId: string,
      client?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientKyc?:  {
        __typename: "CaseClientKyc",
        isSubmitted?: boolean | null,
        isVerified?: boolean | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
      } | null,
      files?:  Array< {
        __typename: "File",
        name: string,
        key: string,
        lastModified: string,
        size: number,
      } > | null,
      details?:  {
        __typename: "CaseDetails",
        type: CaseDetailsType,
        isTimeSensitive?: boolean | null,
        details: string,
        summary?: string | null,
        ask?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items?:  Array< {
          __typename: "Message",
          id: string,
          author?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          authorId: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          content: string,
          type: MessageType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      quotes?:  {
        __typename: "ModelCaseQuoteConnection",
        items?:  Array< {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      payments?:  {
        __typename: "ModelCasePaymentConnection",
        items?:  Array< {
          __typename: "CasePayment",
          id: string,
          caseId: string,
          clientId: string,
          quoteId?: string | null,
          type: CasePaymentType,
          stripeAccountId: string,
          stripePaymentIntentId: string,
          toPlatform: boolean,
          amount: number,
          refunded?: boolean | null,
          solicitorId?: string | null,
          chargeType?: ChargeType | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      caseSolicitorId?: string | null,
      solicitor?:  {
        __typename: "Solicitor",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        name: string,
        businessName: string,
        sraRegistrationNumber?: string | null,
        stripeAccountId?: string | null,
        onboardingStatus?:  {
          __typename: "SolicitorOnboardingStatus",
          hasAccount: boolean,
          accountType: SolicitorAccountType,
          hasChargesEnabled: boolean,
          hasSubmitDetails: boolean,
          hasPayoutsEnabled: boolean,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      solicitorResponse?:  {
        __typename: "SolicitorResponse",
        type: SolicitorResponseType,
        solicitorId: string,
        reason?: string | null,
      } | null,
      completion?:  {
        __typename: "CaseCompletion",
        type?: CaseCompletionType | null,
        details?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items?:  Array< {
          __typename: "Review",
          id: string,
          solicitorId: string,
          caseId: string,
          clientId: string,
          rating: number,
          comment?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      status: CaseStatus,
      assignedToSolicitorAt?: string | null,
      askedClientToPayAt?: string | null,
      statusWhenCancelled?: string | null,
      exportedCaseId?: string | null,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetReviewQueryVariables = {
  id?: string,
};

export type GetReviewQuery = {
  getReview?:  {
    __typename: "Review",
    id: string,
    solicitorId: string,
    caseId: string,
    clientId: string,
    rating: number,
    comment?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListReviewsQueryVariables = {
  filter?: ModelReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReviewsQuery = {
  listReviews?:  {
    __typename: "ModelReviewConnection",
    items?:  Array< {
      __typename: "Review",
      id: string,
      solicitorId: string,
      caseId: string,
      clientId: string,
      rating: number,
      comment?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetCasePaymentQueryVariables = {
  id?: string,
};

export type GetCasePaymentQuery = {
  getCasePayment?:  {
    __typename: "CasePayment",
    id: string,
    caseId: string,
    clientId: string,
    quoteId?: string | null,
    type: CasePaymentType,
    stripeAccountId: string,
    stripePaymentIntentId: string,
    toPlatform: boolean,
    amount: number,
    refunded?: boolean | null,
    solicitorId?: string | null,
    chargeType?: ChargeType | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCasePaymentsQueryVariables = {
  filter?: ModelCasePaymentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCasePaymentsQuery = {
  listCasePayments?:  {
    __typename: "ModelCasePaymentConnection",
    items?:  Array< {
      __typename: "CasePayment",
      id: string,
      caseId: string,
      clientId: string,
      quoteId?: string | null,
      type: CasePaymentType,
      stripeAccountId: string,
      stripePaymentIntentId: string,
      toPlatform: boolean,
      amount: number,
      refunded?: boolean | null,
      solicitorId?: string | null,
      chargeType?: ChargeType | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetCaseCompletionRequestQueryVariables = {
  id?: string,
};

export type GetCaseCompletionRequestQuery = {
  getCaseCompletionRequest?:  {
    __typename: "CaseCompletionRequest",
    id: string,
    caseId: string,
    case:  {
      __typename: "Case",
      id: string,
      caseNumber: string,
      brand?: string | null,
      productSKU?: string | null,
      hasChatbot?: boolean | null,
      payFirst?: boolean | null,
      context?: string | null,
      createdAt: string,
      clientId: string,
      client?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientKyc?:  {
        __typename: "CaseClientKyc",
        isSubmitted?: boolean | null,
        isVerified?: boolean | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
      } | null,
      files?:  Array< {
        __typename: "File",
        name: string,
        key: string,
        lastModified: string,
        size: number,
      } > | null,
      details?:  {
        __typename: "CaseDetails",
        type: CaseDetailsType,
        isTimeSensitive?: boolean | null,
        details: string,
        summary?: string | null,
        ask?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items?:  Array< {
          __typename: "Message",
          id: string,
          author?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          authorId: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          content: string,
          type: MessageType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      quotes?:  {
        __typename: "ModelCaseQuoteConnection",
        items?:  Array< {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      payments?:  {
        __typename: "ModelCasePaymentConnection",
        items?:  Array< {
          __typename: "CasePayment",
          id: string,
          caseId: string,
          clientId: string,
          quoteId?: string | null,
          type: CasePaymentType,
          stripeAccountId: string,
          stripePaymentIntentId: string,
          toPlatform: boolean,
          amount: number,
          refunded?: boolean | null,
          solicitorId?: string | null,
          chargeType?: ChargeType | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      caseSolicitorId?: string | null,
      solicitor?:  {
        __typename: "Solicitor",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        name: string,
        businessName: string,
        sraRegistrationNumber?: string | null,
        stripeAccountId?: string | null,
        onboardingStatus?:  {
          __typename: "SolicitorOnboardingStatus",
          hasAccount: boolean,
          accountType: SolicitorAccountType,
          hasChargesEnabled: boolean,
          hasSubmitDetails: boolean,
          hasPayoutsEnabled: boolean,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      solicitorResponse?:  {
        __typename: "SolicitorResponse",
        type: SolicitorResponseType,
        solicitorId: string,
        reason?: string | null,
      } | null,
      completion?:  {
        __typename: "CaseCompletion",
        type?: CaseCompletionType | null,
        details?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items?:  Array< {
          __typename: "Review",
          id: string,
          solicitorId: string,
          caseId: string,
          clientId: string,
          rating: number,
          comment?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      status: CaseStatus,
      assignedToSolicitorAt?: string | null,
      askedClientToPayAt?: string | null,
      statusWhenCancelled?: string | null,
      exportedCaseId?: string | null,
      updatedAt: string,
    },
    requesterId: string,
    requester?:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    accepted?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCaseCompletionRequestsQueryVariables = {
  filter?: ModelCaseCompletionRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCaseCompletionRequestsQuery = {
  listCaseCompletionRequests?:  {
    __typename: "ModelCaseCompletionRequestConnection",
    items?:  Array< {
      __typename: "CaseCompletionRequest",
      id: string,
      caseId: string,
      case:  {
        __typename: "Case",
        id: string,
        caseNumber: string,
        brand?: string | null,
        productSKU?: string | null,
        hasChatbot?: boolean | null,
        payFirst?: boolean | null,
        context?: string | null,
        createdAt: string,
        clientId: string,
        client?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientKyc?:  {
          __typename: "CaseClientKyc",
          isSubmitted?: boolean | null,
          isVerified?: boolean | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
        } | null,
        files?:  Array< {
          __typename: "File",
          name: string,
          key: string,
          lastModified: string,
          size: number,
        } > | null,
        details?:  {
          __typename: "CaseDetails",
          type: CaseDetailsType,
          isTimeSensitive?: boolean | null,
          details: string,
          summary?: string | null,
          ask?: string | null,
        } | null,
        messages?:  {
          __typename: "ModelMessageConnection",
          items?:  Array< {
            __typename: "Message",
            id: string,
            author?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            authorId: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            content: string,
            type: MessageType,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        quotes?:  {
          __typename: "ModelCaseQuoteConnection",
          items?:  Array< {
            __typename: "CaseQuote",
            id: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            isLeadFromAds?: boolean | null,
            isManualFee?: boolean | null,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        caseSolicitorId?: string | null,
        solicitor?:  {
          __typename: "Solicitor",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          name: string,
          businessName: string,
          sraRegistrationNumber?: string | null,
          stripeAccountId?: string | null,
          onboardingStatus?:  {
            __typename: "SolicitorOnboardingStatus",
            hasAccount: boolean,
            accountType: SolicitorAccountType,
            hasChargesEnabled: boolean,
            hasSubmitDetails: boolean,
            hasPayoutsEnabled: boolean,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        solicitorResponse?:  {
          __typename: "SolicitorResponse",
          type: SolicitorResponseType,
          solicitorId: string,
          reason?: string | null,
        } | null,
        completion?:  {
          __typename: "CaseCompletion",
          type?: CaseCompletionType | null,
          details?: string | null,
        } | null,
        review?:  {
          __typename: "ModelReviewConnection",
          items?:  Array< {
            __typename: "Review",
            id: string,
            solicitorId: string,
            caseId: string,
            clientId: string,
            rating: number,
            comment?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        status: CaseStatus,
        assignedToSolicitorAt?: string | null,
        askedClientToPayAt?: string | null,
        statusWhenCancelled?: string | null,
        exportedCaseId?: string | null,
        updatedAt: string,
      },
      requesterId: string,
      requester?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      accepted?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetConfigurationQueryVariables = {
  key?: string,
};

export type GetConfigurationQuery = {
  getConfiguration?:  {
    __typename: "Configuration",
    key: string,
    value: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListConfigurationsQueryVariables = {
  key?: string | null,
  filter?: ModelConfigurationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListConfigurationsQuery = {
  listConfigurations?:  {
    __typename: "ModelConfigurationConnection",
    items?:  Array< {
      __typename: "Configuration",
      key: string,
      value: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetKycSubmissionQueryVariables = {
  userId?: string,
};

export type GetKycSubmissionQuery = {
  getKYCSubmission?:  {
    __typename: "KYCSubmission",
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    status?: KYCSubmissionStatus | null,
    address?: string | null,
    documentUploads?: Array< string > | null,
    identityUploads?: Array< string > | null,
    verificationReport?:  {
      __typename: "KycVerificationReport",
      amlReportFileKey: string,
      kycReportFileKey?: string | null,
    } | null,
    rejectionReason?:  {
      __typename: "KycRejectionReason",
      reason?: string | null,
      instructions?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListKycSubmissionsQueryVariables = {
  userId?: string | null,
  filter?: ModelKYCSubmissionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListKycSubmissionsQuery = {
  listKYCSubmissions?:  {
    __typename: "ModelKYCSubmissionConnection",
    items?:  Array< {
      __typename: "KYCSubmission",
      userId: string,
      user:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      status?: KYCSubmissionStatus | null,
      address?: string | null,
      documentUploads?: Array< string > | null,
      identityUploads?: Array< string > | null,
      verificationReport?:  {
        __typename: "KycVerificationReport",
        amlReportFileKey: string,
        kycReportFileKey?: string | null,
      } | null,
      rejectionReason?:  {
        __typename: "KycRejectionReason",
        reason?: string | null,
        instructions?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetNotifcationEmailQueryVariables = {
  id?: string,
};

export type GetNotifcationEmailQuery = {
  getNotifcationEmail?:  {
    __typename: "NotifcationEmail",
    id: string,
    user:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    userId: string,
    caseId: string,
    case:  {
      __typename: "Case",
      id: string,
      caseNumber: string,
      brand?: string | null,
      productSKU?: string | null,
      hasChatbot?: boolean | null,
      payFirst?: boolean | null,
      context?: string | null,
      createdAt: string,
      clientId: string,
      client?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientKyc?:  {
        __typename: "CaseClientKyc",
        isSubmitted?: boolean | null,
        isVerified?: boolean | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
      } | null,
      files?:  Array< {
        __typename: "File",
        name: string,
        key: string,
        lastModified: string,
        size: number,
      } > | null,
      details?:  {
        __typename: "CaseDetails",
        type: CaseDetailsType,
        isTimeSensitive?: boolean | null,
        details: string,
        summary?: string | null,
        ask?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items?:  Array< {
          __typename: "Message",
          id: string,
          author?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          authorId: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          content: string,
          type: MessageType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      quotes?:  {
        __typename: "ModelCaseQuoteConnection",
        items?:  Array< {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      payments?:  {
        __typename: "ModelCasePaymentConnection",
        items?:  Array< {
          __typename: "CasePayment",
          id: string,
          caseId: string,
          clientId: string,
          quoteId?: string | null,
          type: CasePaymentType,
          stripeAccountId: string,
          stripePaymentIntentId: string,
          toPlatform: boolean,
          amount: number,
          refunded?: boolean | null,
          solicitorId?: string | null,
          chargeType?: ChargeType | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      caseSolicitorId?: string | null,
      solicitor?:  {
        __typename: "Solicitor",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        name: string,
        businessName: string,
        sraRegistrationNumber?: string | null,
        stripeAccountId?: string | null,
        onboardingStatus?:  {
          __typename: "SolicitorOnboardingStatus",
          hasAccount: boolean,
          accountType: SolicitorAccountType,
          hasChargesEnabled: boolean,
          hasSubmitDetails: boolean,
          hasPayoutsEnabled: boolean,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      solicitorResponse?:  {
        __typename: "SolicitorResponse",
        type: SolicitorResponseType,
        solicitorId: string,
        reason?: string | null,
      } | null,
      completion?:  {
        __typename: "CaseCompletion",
        type?: CaseCompletionType | null,
        details?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items?:  Array< {
          __typename: "Review",
          id: string,
          solicitorId: string,
          caseId: string,
          clientId: string,
          rating: number,
          comment?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      status: CaseStatus,
      assignedToSolicitorAt?: string | null,
      askedClientToPayAt?: string | null,
      statusWhenCancelled?: string | null,
      exportedCaseId?: string | null,
      updatedAt: string,
    },
    type?: NotificationType | null,
    lastSentAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListNotifcationEmailsQueryVariables = {
  filter?: ModelNotifcationEmailFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotifcationEmailsQuery = {
  listNotifcationEmails?:  {
    __typename: "ModelNotifcationEmailConnection",
    items?:  Array< {
      __typename: "NotifcationEmail",
      id: string,
      user:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      userId: string,
      caseId: string,
      case:  {
        __typename: "Case",
        id: string,
        caseNumber: string,
        brand?: string | null,
        productSKU?: string | null,
        hasChatbot?: boolean | null,
        payFirst?: boolean | null,
        context?: string | null,
        createdAt: string,
        clientId: string,
        client?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientKyc?:  {
          __typename: "CaseClientKyc",
          isSubmitted?: boolean | null,
          isVerified?: boolean | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
        } | null,
        files?:  Array< {
          __typename: "File",
          name: string,
          key: string,
          lastModified: string,
          size: number,
        } > | null,
        details?:  {
          __typename: "CaseDetails",
          type: CaseDetailsType,
          isTimeSensitive?: boolean | null,
          details: string,
          summary?: string | null,
          ask?: string | null,
        } | null,
        messages?:  {
          __typename: "ModelMessageConnection",
          items?:  Array< {
            __typename: "Message",
            id: string,
            author?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            authorId: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            content: string,
            type: MessageType,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        quotes?:  {
          __typename: "ModelCaseQuoteConnection",
          items?:  Array< {
            __typename: "CaseQuote",
            id: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            isLeadFromAds?: boolean | null,
            isManualFee?: boolean | null,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        caseSolicitorId?: string | null,
        solicitor?:  {
          __typename: "Solicitor",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          name: string,
          businessName: string,
          sraRegistrationNumber?: string | null,
          stripeAccountId?: string | null,
          onboardingStatus?:  {
            __typename: "SolicitorOnboardingStatus",
            hasAccount: boolean,
            accountType: SolicitorAccountType,
            hasChargesEnabled: boolean,
            hasSubmitDetails: boolean,
            hasPayoutsEnabled: boolean,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        solicitorResponse?:  {
          __typename: "SolicitorResponse",
          type: SolicitorResponseType,
          solicitorId: string,
          reason?: string | null,
        } | null,
        completion?:  {
          __typename: "CaseCompletion",
          type?: CaseCompletionType | null,
          details?: string | null,
        } | null,
        review?:  {
          __typename: "ModelReviewConnection",
          items?:  Array< {
            __typename: "Review",
            id: string,
            solicitorId: string,
            caseId: string,
            clientId: string,
            rating: number,
            comment?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        status: CaseStatus,
        assignedToSolicitorAt?: string | null,
        askedClientToPayAt?: string | null,
        statusWhenCancelled?: string | null,
        exportedCaseId?: string | null,
        updatedAt: string,
      },
      type?: NotificationType | null,
      lastSentAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetProductQueryVariables = {
  id?: string,
};

export type GetProductQuery = {
  getProduct?:  {
    __typename: "Product",
    id: string,
    sku: string,
    title: string,
    description: string,
    lineItems?:  {
      __typename: "ModelCaseLineItemConnection",
      items?:  Array< {
        __typename: "CaseLineItem",
        id: string,
        quote:  {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        quoteId: string,
        caseLineItemProductId?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          sku: string,
          title: string,
          description: string,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        description?: string | null,
        clientPays: number,
        solicitorReceives: number,
        subsidy: number,
        platformFee: number,
        feePercentage: number,
        scope?:  {
          __typename: "CaseScope",
          includes: Array< string >,
          excludes: Array< string >,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListProductsQueryVariables = {
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductsQuery = {
  listProducts?:  {
    __typename: "ModelProductConnection",
    items?:  Array< {
      __typename: "Product",
      id: string,
      sku: string,
      title: string,
      description: string,
      lineItems?:  {
        __typename: "ModelCaseLineItemConnection",
        items?:  Array< {
          __typename: "CaseLineItem",
          id: string,
          quote:  {
            __typename: "CaseQuote",
            id: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            isLeadFromAds?: boolean | null,
            isManualFee?: boolean | null,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          quoteId: string,
          caseLineItemProductId?: string | null,
          product?:  {
            __typename: "Product",
            id: string,
            sku: string,
            title: string,
            description: string,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          description?: string | null,
          clientPays: number,
          solicitorReceives: number,
          subsidy: number,
          platformFee: number,
          feePercentage: number,
          scope?:  {
            __typename: "CaseScope",
            includes: Array< string >,
            excludes: Array< string >,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetSolicitorQueryVariables = {
  id?: string,
};

export type GetSolicitorQuery = {
  getSolicitor?:  {
    __typename: "Solicitor",
    id: string,
    user?:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    businessName: string,
    sraRegistrationNumber?: string | null,
    stripeAccountId?: string | null,
    onboardingStatus?:  {
      __typename: "SolicitorOnboardingStatus",
      hasAccount: boolean,
      accountType: SolicitorAccountType,
      hasChargesEnabled: boolean,
      hasSubmitDetails: boolean,
      hasPayoutsEnabled: boolean,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSolicitorsQueryVariables = {
  filter?: ModelSolicitorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSolicitorsQuery = {
  listSolicitors?:  {
    __typename: "ModelSolicitorConnection",
    items?:  Array< {
      __typename: "Solicitor",
      id: string,
      user?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      name: string,
      businessName: string,
      sraRegistrationNumber?: string | null,
      stripeAccountId?: string | null,
      onboardingStatus?:  {
        __typename: "SolicitorOnboardingStatus",
        hasAccount: boolean,
        accountType: SolicitorAccountType,
        hasChargesEnabled: boolean,
        hasSubmitDetails: boolean,
        hasPayoutsEnabled: boolean,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id?: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    type?: UserType | null,
    emailAddress?: string | null,
    displayName?: string | null,
    phoneNumber?: string | null,
    kycSubmission?:  {
      __typename: "KYCSubmission",
      userId: string,
      user:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      status?: KYCSubmissionStatus | null,
      address?: string | null,
      documentUploads?: Array< string > | null,
      identityUploads?: Array< string > | null,
      verificationReport?:  {
        __typename: "KycVerificationReport",
        amlReportFileKey: string,
        kycReportFileKey?: string | null,
      } | null,
      rejectionReason?:  {
        __typename: "KycRejectionReason",
        reason?: string | null,
        instructions?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    stripeCustomerId?: string | null,
    paymentMethods?:  Array< {
      __typename: "PaymentMethod",
      id: string,
      lastFour: string,
      brand: string,
      expiryMonth: number,
      expiryYear: number,
    } > | null,
    clientCases?:  {
      __typename: "ModelCaseConnection",
      items?:  Array< {
        __typename: "Case",
        id: string,
        caseNumber: string,
        brand?: string | null,
        productSKU?: string | null,
        hasChatbot?: boolean | null,
        payFirst?: boolean | null,
        context?: string | null,
        createdAt: string,
        clientId: string,
        client?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientKyc?:  {
          __typename: "CaseClientKyc",
          isSubmitted?: boolean | null,
          isVerified?: boolean | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
        } | null,
        files?:  Array< {
          __typename: "File",
          name: string,
          key: string,
          lastModified: string,
          size: number,
        } > | null,
        details?:  {
          __typename: "CaseDetails",
          type: CaseDetailsType,
          isTimeSensitive?: boolean | null,
          details: string,
          summary?: string | null,
          ask?: string | null,
        } | null,
        messages?:  {
          __typename: "ModelMessageConnection",
          items?:  Array< {
            __typename: "Message",
            id: string,
            author?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            authorId: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            content: string,
            type: MessageType,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        quotes?:  {
          __typename: "ModelCaseQuoteConnection",
          items?:  Array< {
            __typename: "CaseQuote",
            id: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            isLeadFromAds?: boolean | null,
            isManualFee?: boolean | null,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        caseSolicitorId?: string | null,
        solicitor?:  {
          __typename: "Solicitor",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          name: string,
          businessName: string,
          sraRegistrationNumber?: string | null,
          stripeAccountId?: string | null,
          onboardingStatus?:  {
            __typename: "SolicitorOnboardingStatus",
            hasAccount: boolean,
            accountType: SolicitorAccountType,
            hasChargesEnabled: boolean,
            hasSubmitDetails: boolean,
            hasPayoutsEnabled: boolean,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        solicitorResponse?:  {
          __typename: "SolicitorResponse",
          type: SolicitorResponseType,
          solicitorId: string,
          reason?: string | null,
        } | null,
        completion?:  {
          __typename: "CaseCompletion",
          type?: CaseCompletionType | null,
          details?: string | null,
        } | null,
        review?:  {
          __typename: "ModelReviewConnection",
          items?:  Array< {
            __typename: "Review",
            id: string,
            solicitorId: string,
            caseId: string,
            clientId: string,
            rating: number,
            comment?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        status: CaseStatus,
        assignedToSolicitorAt?: string | null,
        askedClientToPayAt?: string | null,
        statusWhenCancelled?: string | null,
        exportedCaseId?: string | null,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    solicitorCases?:  {
      __typename: "ModelCaseConnection",
      items?:  Array< {
        __typename: "Case",
        id: string,
        caseNumber: string,
        brand?: string | null,
        productSKU?: string | null,
        hasChatbot?: boolean | null,
        payFirst?: boolean | null,
        context?: string | null,
        createdAt: string,
        clientId: string,
        client?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientKyc?:  {
          __typename: "CaseClientKyc",
          isSubmitted?: boolean | null,
          isVerified?: boolean | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
        } | null,
        files?:  Array< {
          __typename: "File",
          name: string,
          key: string,
          lastModified: string,
          size: number,
        } > | null,
        details?:  {
          __typename: "CaseDetails",
          type: CaseDetailsType,
          isTimeSensitive?: boolean | null,
          details: string,
          summary?: string | null,
          ask?: string | null,
        } | null,
        messages?:  {
          __typename: "ModelMessageConnection",
          items?:  Array< {
            __typename: "Message",
            id: string,
            author?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            authorId: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            content: string,
            type: MessageType,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        quotes?:  {
          __typename: "ModelCaseQuoteConnection",
          items?:  Array< {
            __typename: "CaseQuote",
            id: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            isLeadFromAds?: boolean | null,
            isManualFee?: boolean | null,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        caseSolicitorId?: string | null,
        solicitor?:  {
          __typename: "Solicitor",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          name: string,
          businessName: string,
          sraRegistrationNumber?: string | null,
          stripeAccountId?: string | null,
          onboardingStatus?:  {
            __typename: "SolicitorOnboardingStatus",
            hasAccount: boolean,
            accountType: SolicitorAccountType,
            hasChargesEnabled: boolean,
            hasSubmitDetails: boolean,
            hasPayoutsEnabled: boolean,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        solicitorResponse?:  {
          __typename: "SolicitorResponse",
          type: SolicitorResponseType,
          solicitorId: string,
          reason?: string | null,
        } | null,
        completion?:  {
          __typename: "CaseCompletion",
          type?: CaseCompletionType | null,
          details?: string | null,
        } | null,
        review?:  {
          __typename: "ModelReviewConnection",
          items?:  Array< {
            __typename: "Review",
            id: string,
            solicitorId: string,
            caseId: string,
            clientId: string,
            rating: number,
            comment?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        status: CaseStatus,
        assignedToSolicitorAt?: string | null,
        askedClientToPayAt?: string | null,
        statusWhenCancelled?: string | null,
        exportedCaseId?: string | null,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    referral?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items?:  Array< {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListCasesByStatusQueryVariables = {
  status?: CaseStatus | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCasesByStatusQuery = {
  listCasesByStatus?:  {
    __typename: "ModelCaseConnection",
    items?:  Array< {
      __typename: "Case",
      id: string,
      caseNumber: string,
      brand?: string | null,
      productSKU?: string | null,
      hasChatbot?: boolean | null,
      payFirst?: boolean | null,
      context?: string | null,
      createdAt: string,
      clientId: string,
      client?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientKyc?:  {
        __typename: "CaseClientKyc",
        isSubmitted?: boolean | null,
        isVerified?: boolean | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
      } | null,
      files?:  Array< {
        __typename: "File",
        name: string,
        key: string,
        lastModified: string,
        size: number,
      } > | null,
      details?:  {
        __typename: "CaseDetails",
        type: CaseDetailsType,
        isTimeSensitive?: boolean | null,
        details: string,
        summary?: string | null,
        ask?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items?:  Array< {
          __typename: "Message",
          id: string,
          author?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          authorId: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          content: string,
          type: MessageType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      quotes?:  {
        __typename: "ModelCaseQuoteConnection",
        items?:  Array< {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      payments?:  {
        __typename: "ModelCasePaymentConnection",
        items?:  Array< {
          __typename: "CasePayment",
          id: string,
          caseId: string,
          clientId: string,
          quoteId?: string | null,
          type: CasePaymentType,
          stripeAccountId: string,
          stripePaymentIntentId: string,
          toPlatform: boolean,
          amount: number,
          refunded?: boolean | null,
          solicitorId?: string | null,
          chargeType?: ChargeType | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      caseSolicitorId?: string | null,
      solicitor?:  {
        __typename: "Solicitor",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        name: string,
        businessName: string,
        sraRegistrationNumber?: string | null,
        stripeAccountId?: string | null,
        onboardingStatus?:  {
          __typename: "SolicitorOnboardingStatus",
          hasAccount: boolean,
          accountType: SolicitorAccountType,
          hasChargesEnabled: boolean,
          hasSubmitDetails: boolean,
          hasPayoutsEnabled: boolean,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      solicitorResponse?:  {
        __typename: "SolicitorResponse",
        type: SolicitorResponseType,
        solicitorId: string,
        reason?: string | null,
      } | null,
      completion?:  {
        __typename: "CaseCompletion",
        type?: CaseCompletionType | null,
        details?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items?:  Array< {
          __typename: "Review",
          id: string,
          solicitorId: string,
          caseId: string,
          clientId: string,
          rating: number,
          comment?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      status: CaseStatus,
      assignedToSolicitorAt?: string | null,
      askedClientToPayAt?: string | null,
      statusWhenCancelled?: string | null,
      exportedCaseId?: string | null,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListCasesByStatusCreatedAtQueryVariables = {
  status?: CaseStatus | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCasesByStatusCreatedAtQuery = {
  listCasesByStatusCreatedAt?:  {
    __typename: "ModelCaseConnection",
    items?:  Array< {
      __typename: "Case",
      id: string,
      caseNumber: string,
      brand?: string | null,
      productSKU?: string | null,
      hasChatbot?: boolean | null,
      payFirst?: boolean | null,
      context?: string | null,
      createdAt: string,
      clientId: string,
      client?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientKyc?:  {
        __typename: "CaseClientKyc",
        isSubmitted?: boolean | null,
        isVerified?: boolean | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
      } | null,
      files?:  Array< {
        __typename: "File",
        name: string,
        key: string,
        lastModified: string,
        size: number,
      } > | null,
      details?:  {
        __typename: "CaseDetails",
        type: CaseDetailsType,
        isTimeSensitive?: boolean | null,
        details: string,
        summary?: string | null,
        ask?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items?:  Array< {
          __typename: "Message",
          id: string,
          author?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          authorId: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          content: string,
          type: MessageType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      quotes?:  {
        __typename: "ModelCaseQuoteConnection",
        items?:  Array< {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      payments?:  {
        __typename: "ModelCasePaymentConnection",
        items?:  Array< {
          __typename: "CasePayment",
          id: string,
          caseId: string,
          clientId: string,
          quoteId?: string | null,
          type: CasePaymentType,
          stripeAccountId: string,
          stripePaymentIntentId: string,
          toPlatform: boolean,
          amount: number,
          refunded?: boolean | null,
          solicitorId?: string | null,
          chargeType?: ChargeType | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      caseSolicitorId?: string | null,
      solicitor?:  {
        __typename: "Solicitor",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        name: string,
        businessName: string,
        sraRegistrationNumber?: string | null,
        stripeAccountId?: string | null,
        onboardingStatus?:  {
          __typename: "SolicitorOnboardingStatus",
          hasAccount: boolean,
          accountType: SolicitorAccountType,
          hasChargesEnabled: boolean,
          hasSubmitDetails: boolean,
          hasPayoutsEnabled: boolean,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      solicitorResponse?:  {
        __typename: "SolicitorResponse",
        type: SolicitorResponseType,
        solicitorId: string,
        reason?: string | null,
      } | null,
      completion?:  {
        __typename: "CaseCompletion",
        type?: CaseCompletionType | null,
        details?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items?:  Array< {
          __typename: "Review",
          id: string,
          solicitorId: string,
          caseId: string,
          clientId: string,
          rating: number,
          comment?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      status: CaseStatus,
      assignedToSolicitorAt?: string | null,
      askedClientToPayAt?: string | null,
      statusWhenCancelled?: string | null,
      exportedCaseId?: string | null,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListCasesByStatusAskedClientToPayAtQueryVariables = {
  status?: CaseStatus | null,
  askedClientToPayAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCasesByStatusAskedClientToPayAtQuery = {
  listCasesByStatusAskedClientToPayAt?:  {
    __typename: "ModelCaseConnection",
    items?:  Array< {
      __typename: "Case",
      id: string,
      caseNumber: string,
      brand?: string | null,
      productSKU?: string | null,
      hasChatbot?: boolean | null,
      payFirst?: boolean | null,
      context?: string | null,
      createdAt: string,
      clientId: string,
      client?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientKyc?:  {
        __typename: "CaseClientKyc",
        isSubmitted?: boolean | null,
        isVerified?: boolean | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
      } | null,
      files?:  Array< {
        __typename: "File",
        name: string,
        key: string,
        lastModified: string,
        size: number,
      } > | null,
      details?:  {
        __typename: "CaseDetails",
        type: CaseDetailsType,
        isTimeSensitive?: boolean | null,
        details: string,
        summary?: string | null,
        ask?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items?:  Array< {
          __typename: "Message",
          id: string,
          author?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          authorId: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          content: string,
          type: MessageType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      quotes?:  {
        __typename: "ModelCaseQuoteConnection",
        items?:  Array< {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      payments?:  {
        __typename: "ModelCasePaymentConnection",
        items?:  Array< {
          __typename: "CasePayment",
          id: string,
          caseId: string,
          clientId: string,
          quoteId?: string | null,
          type: CasePaymentType,
          stripeAccountId: string,
          stripePaymentIntentId: string,
          toPlatform: boolean,
          amount: number,
          refunded?: boolean | null,
          solicitorId?: string | null,
          chargeType?: ChargeType | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      caseSolicitorId?: string | null,
      solicitor?:  {
        __typename: "Solicitor",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        name: string,
        businessName: string,
        sraRegistrationNumber?: string | null,
        stripeAccountId?: string | null,
        onboardingStatus?:  {
          __typename: "SolicitorOnboardingStatus",
          hasAccount: boolean,
          accountType: SolicitorAccountType,
          hasChargesEnabled: boolean,
          hasSubmitDetails: boolean,
          hasPayoutsEnabled: boolean,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      solicitorResponse?:  {
        __typename: "SolicitorResponse",
        type: SolicitorResponseType,
        solicitorId: string,
        reason?: string | null,
      } | null,
      completion?:  {
        __typename: "CaseCompletion",
        type?: CaseCompletionType | null,
        details?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items?:  Array< {
          __typename: "Review",
          id: string,
          solicitorId: string,
          caseId: string,
          clientId: string,
          rating: number,
          comment?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      status: CaseStatus,
      assignedToSolicitorAt?: string | null,
      askedClientToPayAt?: string | null,
      statusWhenCancelled?: string | null,
      exportedCaseId?: string | null,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListCasesByNumberQueryVariables = {
  caseNumber?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCasesByNumberQuery = {
  listCasesByNumber?:  {
    __typename: "ModelCaseConnection",
    items?:  Array< {
      __typename: "Case",
      id: string,
      caseNumber: string,
      brand?: string | null,
      productSKU?: string | null,
      hasChatbot?: boolean | null,
      payFirst?: boolean | null,
      context?: string | null,
      createdAt: string,
      clientId: string,
      client?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientKyc?:  {
        __typename: "CaseClientKyc",
        isSubmitted?: boolean | null,
        isVerified?: boolean | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
      } | null,
      files?:  Array< {
        __typename: "File",
        name: string,
        key: string,
        lastModified: string,
        size: number,
      } > | null,
      details?:  {
        __typename: "CaseDetails",
        type: CaseDetailsType,
        isTimeSensitive?: boolean | null,
        details: string,
        summary?: string | null,
        ask?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items?:  Array< {
          __typename: "Message",
          id: string,
          author?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          authorId: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          content: string,
          type: MessageType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      quotes?:  {
        __typename: "ModelCaseQuoteConnection",
        items?:  Array< {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      payments?:  {
        __typename: "ModelCasePaymentConnection",
        items?:  Array< {
          __typename: "CasePayment",
          id: string,
          caseId: string,
          clientId: string,
          quoteId?: string | null,
          type: CasePaymentType,
          stripeAccountId: string,
          stripePaymentIntentId: string,
          toPlatform: boolean,
          amount: number,
          refunded?: boolean | null,
          solicitorId?: string | null,
          chargeType?: ChargeType | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      caseSolicitorId?: string | null,
      solicitor?:  {
        __typename: "Solicitor",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        name: string,
        businessName: string,
        sraRegistrationNumber?: string | null,
        stripeAccountId?: string | null,
        onboardingStatus?:  {
          __typename: "SolicitorOnboardingStatus",
          hasAccount: boolean,
          accountType: SolicitorAccountType,
          hasChargesEnabled: boolean,
          hasSubmitDetails: boolean,
          hasPayoutsEnabled: boolean,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      solicitorResponse?:  {
        __typename: "SolicitorResponse",
        type: SolicitorResponseType,
        solicitorId: string,
        reason?: string | null,
      } | null,
      completion?:  {
        __typename: "CaseCompletion",
        type?: CaseCompletionType | null,
        details?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items?:  Array< {
          __typename: "Review",
          id: string,
          solicitorId: string,
          caseId: string,
          clientId: string,
          rating: number,
          comment?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      status: CaseStatus,
      assignedToSolicitorAt?: string | null,
      askedClientToPayAt?: string | null,
      statusWhenCancelled?: string | null,
      exportedCaseId?: string | null,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListCasesByClientQueryVariables = {
  clientId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCasesByClientQuery = {
  listCasesByClient?:  {
    __typename: "ModelCaseConnection",
    items?:  Array< {
      __typename: "Case",
      id: string,
      caseNumber: string,
      brand?: string | null,
      productSKU?: string | null,
      hasChatbot?: boolean | null,
      payFirst?: boolean | null,
      context?: string | null,
      createdAt: string,
      clientId: string,
      client?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientKyc?:  {
        __typename: "CaseClientKyc",
        isSubmitted?: boolean | null,
        isVerified?: boolean | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
      } | null,
      files?:  Array< {
        __typename: "File",
        name: string,
        key: string,
        lastModified: string,
        size: number,
      } > | null,
      details?:  {
        __typename: "CaseDetails",
        type: CaseDetailsType,
        isTimeSensitive?: boolean | null,
        details: string,
        summary?: string | null,
        ask?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items?:  Array< {
          __typename: "Message",
          id: string,
          author?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          authorId: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          content: string,
          type: MessageType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      quotes?:  {
        __typename: "ModelCaseQuoteConnection",
        items?:  Array< {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      payments?:  {
        __typename: "ModelCasePaymentConnection",
        items?:  Array< {
          __typename: "CasePayment",
          id: string,
          caseId: string,
          clientId: string,
          quoteId?: string | null,
          type: CasePaymentType,
          stripeAccountId: string,
          stripePaymentIntentId: string,
          toPlatform: boolean,
          amount: number,
          refunded?: boolean | null,
          solicitorId?: string | null,
          chargeType?: ChargeType | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      caseSolicitorId?: string | null,
      solicitor?:  {
        __typename: "Solicitor",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        name: string,
        businessName: string,
        sraRegistrationNumber?: string | null,
        stripeAccountId?: string | null,
        onboardingStatus?:  {
          __typename: "SolicitorOnboardingStatus",
          hasAccount: boolean,
          accountType: SolicitorAccountType,
          hasChargesEnabled: boolean,
          hasSubmitDetails: boolean,
          hasPayoutsEnabled: boolean,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      solicitorResponse?:  {
        __typename: "SolicitorResponse",
        type: SolicitorResponseType,
        solicitorId: string,
        reason?: string | null,
      } | null,
      completion?:  {
        __typename: "CaseCompletion",
        type?: CaseCompletionType | null,
        details?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items?:  Array< {
          __typename: "Review",
          id: string,
          solicitorId: string,
          caseId: string,
          clientId: string,
          rating: number,
          comment?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      status: CaseStatus,
      assignedToSolicitorAt?: string | null,
      askedClientToPayAt?: string | null,
      statusWhenCancelled?: string | null,
      exportedCaseId?: string | null,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListCasesByClientStatusQueryVariables = {
  clientId?: string | null,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCasesByClientStatusQuery = {
  listCasesByClientStatus?:  {
    __typename: "ModelCaseConnection",
    items?:  Array< {
      __typename: "Case",
      id: string,
      caseNumber: string,
      brand?: string | null,
      productSKU?: string | null,
      hasChatbot?: boolean | null,
      payFirst?: boolean | null,
      context?: string | null,
      createdAt: string,
      clientId: string,
      client?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientKyc?:  {
        __typename: "CaseClientKyc",
        isSubmitted?: boolean | null,
        isVerified?: boolean | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
      } | null,
      files?:  Array< {
        __typename: "File",
        name: string,
        key: string,
        lastModified: string,
        size: number,
      } > | null,
      details?:  {
        __typename: "CaseDetails",
        type: CaseDetailsType,
        isTimeSensitive?: boolean | null,
        details: string,
        summary?: string | null,
        ask?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items?:  Array< {
          __typename: "Message",
          id: string,
          author?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          authorId: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          content: string,
          type: MessageType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      quotes?:  {
        __typename: "ModelCaseQuoteConnection",
        items?:  Array< {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      payments?:  {
        __typename: "ModelCasePaymentConnection",
        items?:  Array< {
          __typename: "CasePayment",
          id: string,
          caseId: string,
          clientId: string,
          quoteId?: string | null,
          type: CasePaymentType,
          stripeAccountId: string,
          stripePaymentIntentId: string,
          toPlatform: boolean,
          amount: number,
          refunded?: boolean | null,
          solicitorId?: string | null,
          chargeType?: ChargeType | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      caseSolicitorId?: string | null,
      solicitor?:  {
        __typename: "Solicitor",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        name: string,
        businessName: string,
        sraRegistrationNumber?: string | null,
        stripeAccountId?: string | null,
        onboardingStatus?:  {
          __typename: "SolicitorOnboardingStatus",
          hasAccount: boolean,
          accountType: SolicitorAccountType,
          hasChargesEnabled: boolean,
          hasSubmitDetails: boolean,
          hasPayoutsEnabled: boolean,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      solicitorResponse?:  {
        __typename: "SolicitorResponse",
        type: SolicitorResponseType,
        solicitorId: string,
        reason?: string | null,
      } | null,
      completion?:  {
        __typename: "CaseCompletion",
        type?: CaseCompletionType | null,
        details?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items?:  Array< {
          __typename: "Review",
          id: string,
          solicitorId: string,
          caseId: string,
          clientId: string,
          rating: number,
          comment?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      status: CaseStatus,
      assignedToSolicitorAt?: string | null,
      askedClientToPayAt?: string | null,
      statusWhenCancelled?: string | null,
      exportedCaseId?: string | null,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListCasesByBrandQueryVariables = {
  brand?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCasesByBrandQuery = {
  listCasesByBrand?:  {
    __typename: "ModelCaseConnection",
    items?:  Array< {
      __typename: "Case",
      id: string,
      caseNumber: string,
      brand?: string | null,
      productSKU?: string | null,
      hasChatbot?: boolean | null,
      payFirst?: boolean | null,
      context?: string | null,
      createdAt: string,
      clientId: string,
      client?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientKyc?:  {
        __typename: "CaseClientKyc",
        isSubmitted?: boolean | null,
        isVerified?: boolean | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
      } | null,
      files?:  Array< {
        __typename: "File",
        name: string,
        key: string,
        lastModified: string,
        size: number,
      } > | null,
      details?:  {
        __typename: "CaseDetails",
        type: CaseDetailsType,
        isTimeSensitive?: boolean | null,
        details: string,
        summary?: string | null,
        ask?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items?:  Array< {
          __typename: "Message",
          id: string,
          author?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          authorId: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          content: string,
          type: MessageType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      quotes?:  {
        __typename: "ModelCaseQuoteConnection",
        items?:  Array< {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      payments?:  {
        __typename: "ModelCasePaymentConnection",
        items?:  Array< {
          __typename: "CasePayment",
          id: string,
          caseId: string,
          clientId: string,
          quoteId?: string | null,
          type: CasePaymentType,
          stripeAccountId: string,
          stripePaymentIntentId: string,
          toPlatform: boolean,
          amount: number,
          refunded?: boolean | null,
          solicitorId?: string | null,
          chargeType?: ChargeType | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      caseSolicitorId?: string | null,
      solicitor?:  {
        __typename: "Solicitor",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        name: string,
        businessName: string,
        sraRegistrationNumber?: string | null,
        stripeAccountId?: string | null,
        onboardingStatus?:  {
          __typename: "SolicitorOnboardingStatus",
          hasAccount: boolean,
          accountType: SolicitorAccountType,
          hasChargesEnabled: boolean,
          hasSubmitDetails: boolean,
          hasPayoutsEnabled: boolean,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      solicitorResponse?:  {
        __typename: "SolicitorResponse",
        type: SolicitorResponseType,
        solicitorId: string,
        reason?: string | null,
      } | null,
      completion?:  {
        __typename: "CaseCompletion",
        type?: CaseCompletionType | null,
        details?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items?:  Array< {
          __typename: "Review",
          id: string,
          solicitorId: string,
          caseId: string,
          clientId: string,
          rating: number,
          comment?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      status: CaseStatus,
      assignedToSolicitorAt?: string | null,
      askedClientToPayAt?: string | null,
      statusWhenCancelled?: string | null,
      exportedCaseId?: string | null,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListCasesBySolicitorQueryVariables = {
  caseSolicitorId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCasesBySolicitorQuery = {
  listCasesBySolicitor?:  {
    __typename: "ModelCaseConnection",
    items?:  Array< {
      __typename: "Case",
      id: string,
      caseNumber: string,
      brand?: string | null,
      productSKU?: string | null,
      hasChatbot?: boolean | null,
      payFirst?: boolean | null,
      context?: string | null,
      createdAt: string,
      clientId: string,
      client?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientKyc?:  {
        __typename: "CaseClientKyc",
        isSubmitted?: boolean | null,
        isVerified?: boolean | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
      } | null,
      files?:  Array< {
        __typename: "File",
        name: string,
        key: string,
        lastModified: string,
        size: number,
      } > | null,
      details?:  {
        __typename: "CaseDetails",
        type: CaseDetailsType,
        isTimeSensitive?: boolean | null,
        details: string,
        summary?: string | null,
        ask?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items?:  Array< {
          __typename: "Message",
          id: string,
          author?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          authorId: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          content: string,
          type: MessageType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      quotes?:  {
        __typename: "ModelCaseQuoteConnection",
        items?:  Array< {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      payments?:  {
        __typename: "ModelCasePaymentConnection",
        items?:  Array< {
          __typename: "CasePayment",
          id: string,
          caseId: string,
          clientId: string,
          quoteId?: string | null,
          type: CasePaymentType,
          stripeAccountId: string,
          stripePaymentIntentId: string,
          toPlatform: boolean,
          amount: number,
          refunded?: boolean | null,
          solicitorId?: string | null,
          chargeType?: ChargeType | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      caseSolicitorId?: string | null,
      solicitor?:  {
        __typename: "Solicitor",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        name: string,
        businessName: string,
        sraRegistrationNumber?: string | null,
        stripeAccountId?: string | null,
        onboardingStatus?:  {
          __typename: "SolicitorOnboardingStatus",
          hasAccount: boolean,
          accountType: SolicitorAccountType,
          hasChargesEnabled: boolean,
          hasSubmitDetails: boolean,
          hasPayoutsEnabled: boolean,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      solicitorResponse?:  {
        __typename: "SolicitorResponse",
        type: SolicitorResponseType,
        solicitorId: string,
        reason?: string | null,
      } | null,
      completion?:  {
        __typename: "CaseCompletion",
        type?: CaseCompletionType | null,
        details?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items?:  Array< {
          __typename: "Review",
          id: string,
          solicitorId: string,
          caseId: string,
          clientId: string,
          rating: number,
          comment?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      status: CaseStatus,
      assignedToSolicitorAt?: string | null,
      askedClientToPayAt?: string | null,
      statusWhenCancelled?: string | null,
      exportedCaseId?: string | null,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListCasesBySolicitorStatusQueryVariables = {
  caseSolicitorId?: string | null,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCasesBySolicitorStatusQuery = {
  listCasesBySolicitorStatus?:  {
    __typename: "ModelCaseConnection",
    items?:  Array< {
      __typename: "Case",
      id: string,
      caseNumber: string,
      brand?: string | null,
      productSKU?: string | null,
      hasChatbot?: boolean | null,
      payFirst?: boolean | null,
      context?: string | null,
      createdAt: string,
      clientId: string,
      client?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientKyc?:  {
        __typename: "CaseClientKyc",
        isSubmitted?: boolean | null,
        isVerified?: boolean | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
      } | null,
      files?:  Array< {
        __typename: "File",
        name: string,
        key: string,
        lastModified: string,
        size: number,
      } > | null,
      details?:  {
        __typename: "CaseDetails",
        type: CaseDetailsType,
        isTimeSensitive?: boolean | null,
        details: string,
        summary?: string | null,
        ask?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items?:  Array< {
          __typename: "Message",
          id: string,
          author?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          authorId: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          content: string,
          type: MessageType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      quotes?:  {
        __typename: "ModelCaseQuoteConnection",
        items?:  Array< {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      payments?:  {
        __typename: "ModelCasePaymentConnection",
        items?:  Array< {
          __typename: "CasePayment",
          id: string,
          caseId: string,
          clientId: string,
          quoteId?: string | null,
          type: CasePaymentType,
          stripeAccountId: string,
          stripePaymentIntentId: string,
          toPlatform: boolean,
          amount: number,
          refunded?: boolean | null,
          solicitorId?: string | null,
          chargeType?: ChargeType | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      caseSolicitorId?: string | null,
      solicitor?:  {
        __typename: "Solicitor",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        name: string,
        businessName: string,
        sraRegistrationNumber?: string | null,
        stripeAccountId?: string | null,
        onboardingStatus?:  {
          __typename: "SolicitorOnboardingStatus",
          hasAccount: boolean,
          accountType: SolicitorAccountType,
          hasChargesEnabled: boolean,
          hasSubmitDetails: boolean,
          hasPayoutsEnabled: boolean,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      solicitorResponse?:  {
        __typename: "SolicitorResponse",
        type: SolicitorResponseType,
        solicitorId: string,
        reason?: string | null,
      } | null,
      completion?:  {
        __typename: "CaseCompletion",
        type?: CaseCompletionType | null,
        details?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items?:  Array< {
          __typename: "Review",
          id: string,
          solicitorId: string,
          caseId: string,
          clientId: string,
          rating: number,
          comment?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      status: CaseStatus,
      assignedToSolicitorAt?: string | null,
      askedClientToPayAt?: string | null,
      statusWhenCancelled?: string | null,
      exportedCaseId?: string | null,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListCasePaymentsByCaseQueryVariables = {
  caseId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCasePaymentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCasePaymentsByCaseQuery = {
  listCasePaymentsByCase?:  {
    __typename: "ModelCasePaymentConnection",
    items?:  Array< {
      __typename: "CasePayment",
      id: string,
      caseId: string,
      clientId: string,
      quoteId?: string | null,
      type: CasePaymentType,
      stripeAccountId: string,
      stripePaymentIntentId: string,
      toPlatform: boolean,
      amount: number,
      refunded?: boolean | null,
      solicitorId?: string | null,
      chargeType?: ChargeType | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListCasePaymentsByCaseTypeQueryVariables = {
  caseId?: string | null,
  type?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCasePaymentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCasePaymentsByCaseTypeQuery = {
  listCasePaymentsByCaseType?:  {
    __typename: "ModelCasePaymentConnection",
    items?:  Array< {
      __typename: "CasePayment",
      id: string,
      caseId: string,
      clientId: string,
      quoteId?: string | null,
      type: CasePaymentType,
      stripeAccountId: string,
      stripePaymentIntentId: string,
      toPlatform: boolean,
      amount: number,
      refunded?: boolean | null,
      solicitorId?: string | null,
      chargeType?: ChargeType | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListCasePaymentsByClientQueryVariables = {
  clientId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCasePaymentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCasePaymentsByClientQuery = {
  listCasePaymentsByClient?:  {
    __typename: "ModelCasePaymentConnection",
    items?:  Array< {
      __typename: "CasePayment",
      id: string,
      caseId: string,
      clientId: string,
      quoteId?: string | null,
      type: CasePaymentType,
      stripeAccountId: string,
      stripePaymentIntentId: string,
      toPlatform: boolean,
      amount: number,
      refunded?: boolean | null,
      solicitorId?: string | null,
      chargeType?: ChargeType | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListCasePaymentsByQuoteQueryVariables = {
  quoteId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCasePaymentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCasePaymentsByQuoteQuery = {
  listCasePaymentsByQuote?:  {
    __typename: "ModelCasePaymentConnection",
    items?:  Array< {
      __typename: "CasePayment",
      id: string,
      caseId: string,
      clientId: string,
      quoteId?: string | null,
      type: CasePaymentType,
      stripeAccountId: string,
      stripePaymentIntentId: string,
      toPlatform: boolean,
      amount: number,
      refunded?: boolean | null,
      solicitorId?: string | null,
      chargeType?: ChargeType | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListCasePaymentByStripePaymentIntentQueryVariables = {
  stripePaymentIntentId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCasePaymentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCasePaymentByStripePaymentIntentQuery = {
  listCasePaymentByStripePaymentIntent?:  {
    __typename: "ModelCasePaymentConnection",
    items?:  Array< {
      __typename: "CasePayment",
      id: string,
      caseId: string,
      clientId: string,
      quoteId?: string | null,
      type: CasePaymentType,
      stripeAccountId: string,
      stripePaymentIntentId: string,
      toPlatform: boolean,
      amount: number,
      refunded?: boolean | null,
      solicitorId?: string | null,
      chargeType?: ChargeType | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListCaseCompletionRequestByCaseQueryVariables = {
  caseId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCaseCompletionRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCaseCompletionRequestByCaseQuery = {
  listCaseCompletionRequestByCase?:  {
    __typename: "ModelCaseCompletionRequestConnection",
    items?:  Array< {
      __typename: "CaseCompletionRequest",
      id: string,
      caseId: string,
      case:  {
        __typename: "Case",
        id: string,
        caseNumber: string,
        brand?: string | null,
        productSKU?: string | null,
        hasChatbot?: boolean | null,
        payFirst?: boolean | null,
        context?: string | null,
        createdAt: string,
        clientId: string,
        client?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientKyc?:  {
          __typename: "CaseClientKyc",
          isSubmitted?: boolean | null,
          isVerified?: boolean | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
        } | null,
        files?:  Array< {
          __typename: "File",
          name: string,
          key: string,
          lastModified: string,
          size: number,
        } > | null,
        details?:  {
          __typename: "CaseDetails",
          type: CaseDetailsType,
          isTimeSensitive?: boolean | null,
          details: string,
          summary?: string | null,
          ask?: string | null,
        } | null,
        messages?:  {
          __typename: "ModelMessageConnection",
          items?:  Array< {
            __typename: "Message",
            id: string,
            author?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            authorId: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            content: string,
            type: MessageType,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        quotes?:  {
          __typename: "ModelCaseQuoteConnection",
          items?:  Array< {
            __typename: "CaseQuote",
            id: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            isLeadFromAds?: boolean | null,
            isManualFee?: boolean | null,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        caseSolicitorId?: string | null,
        solicitor?:  {
          __typename: "Solicitor",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          name: string,
          businessName: string,
          sraRegistrationNumber?: string | null,
          stripeAccountId?: string | null,
          onboardingStatus?:  {
            __typename: "SolicitorOnboardingStatus",
            hasAccount: boolean,
            accountType: SolicitorAccountType,
            hasChargesEnabled: boolean,
            hasSubmitDetails: boolean,
            hasPayoutsEnabled: boolean,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        solicitorResponse?:  {
          __typename: "SolicitorResponse",
          type: SolicitorResponseType,
          solicitorId: string,
          reason?: string | null,
        } | null,
        completion?:  {
          __typename: "CaseCompletion",
          type?: CaseCompletionType | null,
          details?: string | null,
        } | null,
        review?:  {
          __typename: "ModelReviewConnection",
          items?:  Array< {
            __typename: "Review",
            id: string,
            solicitorId: string,
            caseId: string,
            clientId: string,
            rating: number,
            comment?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        status: CaseStatus,
        assignedToSolicitorAt?: string | null,
        askedClientToPayAt?: string | null,
        statusWhenCancelled?: string | null,
        exportedCaseId?: string | null,
        updatedAt: string,
      },
      requesterId: string,
      requester?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      accepted?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListCaseCompletionRequestByRequesterQueryVariables = {
  requesterId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCaseCompletionRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCaseCompletionRequestByRequesterQuery = {
  listCaseCompletionRequestByRequester?:  {
    __typename: "ModelCaseCompletionRequestConnection",
    items?:  Array< {
      __typename: "CaseCompletionRequest",
      id: string,
      caseId: string,
      case:  {
        __typename: "Case",
        id: string,
        caseNumber: string,
        brand?: string | null,
        productSKU?: string | null,
        hasChatbot?: boolean | null,
        payFirst?: boolean | null,
        context?: string | null,
        createdAt: string,
        clientId: string,
        client?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientKyc?:  {
          __typename: "CaseClientKyc",
          isSubmitted?: boolean | null,
          isVerified?: boolean | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
        } | null,
        files?:  Array< {
          __typename: "File",
          name: string,
          key: string,
          lastModified: string,
          size: number,
        } > | null,
        details?:  {
          __typename: "CaseDetails",
          type: CaseDetailsType,
          isTimeSensitive?: boolean | null,
          details: string,
          summary?: string | null,
          ask?: string | null,
        } | null,
        messages?:  {
          __typename: "ModelMessageConnection",
          items?:  Array< {
            __typename: "Message",
            id: string,
            author?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            authorId: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            content: string,
            type: MessageType,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        quotes?:  {
          __typename: "ModelCaseQuoteConnection",
          items?:  Array< {
            __typename: "CaseQuote",
            id: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            isLeadFromAds?: boolean | null,
            isManualFee?: boolean | null,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        caseSolicitorId?: string | null,
        solicitor?:  {
          __typename: "Solicitor",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          name: string,
          businessName: string,
          sraRegistrationNumber?: string | null,
          stripeAccountId?: string | null,
          onboardingStatus?:  {
            __typename: "SolicitorOnboardingStatus",
            hasAccount: boolean,
            accountType: SolicitorAccountType,
            hasChargesEnabled: boolean,
            hasSubmitDetails: boolean,
            hasPayoutsEnabled: boolean,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        solicitorResponse?:  {
          __typename: "SolicitorResponse",
          type: SolicitorResponseType,
          solicitorId: string,
          reason?: string | null,
        } | null,
        completion?:  {
          __typename: "CaseCompletion",
          type?: CaseCompletionType | null,
          details?: string | null,
        } | null,
        review?:  {
          __typename: "ModelReviewConnection",
          items?:  Array< {
            __typename: "Review",
            id: string,
            solicitorId: string,
            caseId: string,
            clientId: string,
            rating: number,
            comment?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        status: CaseStatus,
        assignedToSolicitorAt?: string | null,
        askedClientToPayAt?: string | null,
        statusWhenCancelled?: string | null,
        exportedCaseId?: string | null,
        updatedAt: string,
      },
      requesterId: string,
      requester?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      accepted?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListCaseCompletionRequestByCaseRequesterQueryVariables = {
  caseId?: string | null,
  requesterId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCaseCompletionRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCaseCompletionRequestByCaseRequesterQuery = {
  listCaseCompletionRequestByCaseRequester?:  {
    __typename: "ModelCaseCompletionRequestConnection",
    items?:  Array< {
      __typename: "CaseCompletionRequest",
      id: string,
      caseId: string,
      case:  {
        __typename: "Case",
        id: string,
        caseNumber: string,
        brand?: string | null,
        productSKU?: string | null,
        hasChatbot?: boolean | null,
        payFirst?: boolean | null,
        context?: string | null,
        createdAt: string,
        clientId: string,
        client?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientKyc?:  {
          __typename: "CaseClientKyc",
          isSubmitted?: boolean | null,
          isVerified?: boolean | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
        } | null,
        files?:  Array< {
          __typename: "File",
          name: string,
          key: string,
          lastModified: string,
          size: number,
        } > | null,
        details?:  {
          __typename: "CaseDetails",
          type: CaseDetailsType,
          isTimeSensitive?: boolean | null,
          details: string,
          summary?: string | null,
          ask?: string | null,
        } | null,
        messages?:  {
          __typename: "ModelMessageConnection",
          items?:  Array< {
            __typename: "Message",
            id: string,
            author?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            authorId: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            content: string,
            type: MessageType,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        quotes?:  {
          __typename: "ModelCaseQuoteConnection",
          items?:  Array< {
            __typename: "CaseQuote",
            id: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            isLeadFromAds?: boolean | null,
            isManualFee?: boolean | null,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        caseSolicitorId?: string | null,
        solicitor?:  {
          __typename: "Solicitor",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          name: string,
          businessName: string,
          sraRegistrationNumber?: string | null,
          stripeAccountId?: string | null,
          onboardingStatus?:  {
            __typename: "SolicitorOnboardingStatus",
            hasAccount: boolean,
            accountType: SolicitorAccountType,
            hasChargesEnabled: boolean,
            hasSubmitDetails: boolean,
            hasPayoutsEnabled: boolean,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        solicitorResponse?:  {
          __typename: "SolicitorResponse",
          type: SolicitorResponseType,
          solicitorId: string,
          reason?: string | null,
        } | null,
        completion?:  {
          __typename: "CaseCompletion",
          type?: CaseCompletionType | null,
          details?: string | null,
        } | null,
        review?:  {
          __typename: "ModelReviewConnection",
          items?:  Array< {
            __typename: "Review",
            id: string,
            solicitorId: string,
            caseId: string,
            clientId: string,
            rating: number,
            comment?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        status: CaseStatus,
        assignedToSolicitorAt?: string | null,
        askedClientToPayAt?: string | null,
        statusWhenCancelled?: string | null,
        exportedCaseId?: string | null,
        updatedAt: string,
      },
      requesterId: string,
      requester?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      accepted?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListKycSubmissionsByStatusQueryVariables = {
  status?: KYCSubmissionStatus | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelKYCSubmissionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListKycSubmissionsByStatusQuery = {
  listKycSubmissionsByStatus?:  {
    __typename: "ModelKYCSubmissionConnection",
    items?:  Array< {
      __typename: "KYCSubmission",
      userId: string,
      user:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      status?: KYCSubmissionStatus | null,
      address?: string | null,
      documentUploads?: Array< string > | null,
      identityUploads?: Array< string > | null,
      verificationReport?:  {
        __typename: "KycVerificationReport",
        amlReportFileKey: string,
        kycReportFileKey?: string | null,
      } | null,
      rejectionReason?:  {
        __typename: "KycRejectionReason",
        reason?: string | null,
        instructions?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListMessagesByCaseCreatedAtQueryVariables = {
  caseId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMessagesByCaseCreatedAtQuery = {
  listMessagesByCaseCreatedAt?:  {
    __typename: "ModelMessageConnection",
    items?:  Array< {
      __typename: "Message",
      id: string,
      author?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      authorId: string,
      case:  {
        __typename: "Case",
        id: string,
        caseNumber: string,
        brand?: string | null,
        productSKU?: string | null,
        hasChatbot?: boolean | null,
        payFirst?: boolean | null,
        context?: string | null,
        createdAt: string,
        clientId: string,
        client?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientKyc?:  {
          __typename: "CaseClientKyc",
          isSubmitted?: boolean | null,
          isVerified?: boolean | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
        } | null,
        files?:  Array< {
          __typename: "File",
          name: string,
          key: string,
          lastModified: string,
          size: number,
        } > | null,
        details?:  {
          __typename: "CaseDetails",
          type: CaseDetailsType,
          isTimeSensitive?: boolean | null,
          details: string,
          summary?: string | null,
          ask?: string | null,
        } | null,
        messages?:  {
          __typename: "ModelMessageConnection",
          items?:  Array< {
            __typename: "Message",
            id: string,
            author?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            authorId: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            content: string,
            type: MessageType,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        quotes?:  {
          __typename: "ModelCaseQuoteConnection",
          items?:  Array< {
            __typename: "CaseQuote",
            id: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            isLeadFromAds?: boolean | null,
            isManualFee?: boolean | null,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        caseSolicitorId?: string | null,
        solicitor?:  {
          __typename: "Solicitor",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          name: string,
          businessName: string,
          sraRegistrationNumber?: string | null,
          stripeAccountId?: string | null,
          onboardingStatus?:  {
            __typename: "SolicitorOnboardingStatus",
            hasAccount: boolean,
            accountType: SolicitorAccountType,
            hasChargesEnabled: boolean,
            hasSubmitDetails: boolean,
            hasPayoutsEnabled: boolean,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        solicitorResponse?:  {
          __typename: "SolicitorResponse",
          type: SolicitorResponseType,
          solicitorId: string,
          reason?: string | null,
        } | null,
        completion?:  {
          __typename: "CaseCompletion",
          type?: CaseCompletionType | null,
          details?: string | null,
        } | null,
        review?:  {
          __typename: "ModelReviewConnection",
          items?:  Array< {
            __typename: "Review",
            id: string,
            solicitorId: string,
            caseId: string,
            clientId: string,
            rating: number,
            comment?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        status: CaseStatus,
        assignedToSolicitorAt?: string | null,
        askedClientToPayAt?: string | null,
        statusWhenCancelled?: string | null,
        exportedCaseId?: string | null,
        updatedAt: string,
      },
      caseId: string,
      content: string,
      type: MessageType,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListNotificationEmailByCaseUserTypeQueryVariables = {
  caseId?: string | null,
  userIdType?: ModelNotifcationEmailNotificationEmailByCaseUserTypeCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotifcationEmailFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationEmailByCaseUserTypeQuery = {
  listNotificationEmailByCaseUserType?:  {
    __typename: "ModelNotifcationEmailConnection",
    items?:  Array< {
      __typename: "NotifcationEmail",
      id: string,
      user:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      userId: string,
      caseId: string,
      case:  {
        __typename: "Case",
        id: string,
        caseNumber: string,
        brand?: string | null,
        productSKU?: string | null,
        hasChatbot?: boolean | null,
        payFirst?: boolean | null,
        context?: string | null,
        createdAt: string,
        clientId: string,
        client?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientKyc?:  {
          __typename: "CaseClientKyc",
          isSubmitted?: boolean | null,
          isVerified?: boolean | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
        } | null,
        files?:  Array< {
          __typename: "File",
          name: string,
          key: string,
          lastModified: string,
          size: number,
        } > | null,
        details?:  {
          __typename: "CaseDetails",
          type: CaseDetailsType,
          isTimeSensitive?: boolean | null,
          details: string,
          summary?: string | null,
          ask?: string | null,
        } | null,
        messages?:  {
          __typename: "ModelMessageConnection",
          items?:  Array< {
            __typename: "Message",
            id: string,
            author?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            authorId: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            content: string,
            type: MessageType,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        quotes?:  {
          __typename: "ModelCaseQuoteConnection",
          items?:  Array< {
            __typename: "CaseQuote",
            id: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            isLeadFromAds?: boolean | null,
            isManualFee?: boolean | null,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        caseSolicitorId?: string | null,
        solicitor?:  {
          __typename: "Solicitor",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          name: string,
          businessName: string,
          sraRegistrationNumber?: string | null,
          stripeAccountId?: string | null,
          onboardingStatus?:  {
            __typename: "SolicitorOnboardingStatus",
            hasAccount: boolean,
            accountType: SolicitorAccountType,
            hasChargesEnabled: boolean,
            hasSubmitDetails: boolean,
            hasPayoutsEnabled: boolean,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        solicitorResponse?:  {
          __typename: "SolicitorResponse",
          type: SolicitorResponseType,
          solicitorId: string,
          reason?: string | null,
        } | null,
        completion?:  {
          __typename: "CaseCompletion",
          type?: CaseCompletionType | null,
          details?: string | null,
        } | null,
        review?:  {
          __typename: "ModelReviewConnection",
          items?:  Array< {
            __typename: "Review",
            id: string,
            solicitorId: string,
            caseId: string,
            clientId: string,
            rating: number,
            comment?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        status: CaseStatus,
        assignedToSolicitorAt?: string | null,
        askedClientToPayAt?: string | null,
        statusWhenCancelled?: string | null,
        exportedCaseId?: string | null,
        updatedAt: string,
      },
      type?: NotificationType | null,
      lastSentAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListUsersByTypeQueryVariables = {
  type?: UserType | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersByTypeQuery = {
  listUsersByType?:  {
    __typename: "ModelUserConnection",
    items?:  Array< {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListUsersByEmailAddressQueryVariables = {
  emailAddress?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersByEmailAddressQuery = {
  listUsersByEmailAddress?:  {
    __typename: "ModelUserConnection",
    items?:  Array< {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type OnCreateMessageSubscriptionVariables = {
  caseId?: string,
};

export type OnCreateMessageSubscription = {
  onCreateMessage?:  {
    __typename: "Message",
    id: string,
    author?:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    authorId: string,
    case:  {
      __typename: "Case",
      id: string,
      caseNumber: string,
      brand?: string | null,
      productSKU?: string | null,
      hasChatbot?: boolean | null,
      payFirst?: boolean | null,
      context?: string | null,
      createdAt: string,
      clientId: string,
      client?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientKyc?:  {
        __typename: "CaseClientKyc",
        isSubmitted?: boolean | null,
        isVerified?: boolean | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
      } | null,
      files?:  Array< {
        __typename: "File",
        name: string,
        key: string,
        lastModified: string,
        size: number,
      } > | null,
      details?:  {
        __typename: "CaseDetails",
        type: CaseDetailsType,
        isTimeSensitive?: boolean | null,
        details: string,
        summary?: string | null,
        ask?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items?:  Array< {
          __typename: "Message",
          id: string,
          author?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          authorId: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          content: string,
          type: MessageType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      quotes?:  {
        __typename: "ModelCaseQuoteConnection",
        items?:  Array< {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      payments?:  {
        __typename: "ModelCasePaymentConnection",
        items?:  Array< {
          __typename: "CasePayment",
          id: string,
          caseId: string,
          clientId: string,
          quoteId?: string | null,
          type: CasePaymentType,
          stripeAccountId: string,
          stripePaymentIntentId: string,
          toPlatform: boolean,
          amount: number,
          refunded?: boolean | null,
          solicitorId?: string | null,
          chargeType?: ChargeType | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      caseSolicitorId?: string | null,
      solicitor?:  {
        __typename: "Solicitor",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        name: string,
        businessName: string,
        sraRegistrationNumber?: string | null,
        stripeAccountId?: string | null,
        onboardingStatus?:  {
          __typename: "SolicitorOnboardingStatus",
          hasAccount: boolean,
          accountType: SolicitorAccountType,
          hasChargesEnabled: boolean,
          hasSubmitDetails: boolean,
          hasPayoutsEnabled: boolean,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      solicitorResponse?:  {
        __typename: "SolicitorResponse",
        type: SolicitorResponseType,
        solicitorId: string,
        reason?: string | null,
      } | null,
      completion?:  {
        __typename: "CaseCompletion",
        type?: CaseCompletionType | null,
        details?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items?:  Array< {
          __typename: "Review",
          id: string,
          solicitorId: string,
          caseId: string,
          clientId: string,
          rating: number,
          comment?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      status: CaseStatus,
      assignedToSolicitorAt?: string | null,
      askedClientToPayAt?: string | null,
      statusWhenCancelled?: string | null,
      exportedCaseId?: string | null,
      updatedAt: string,
    },
    caseId: string,
    content: string,
    type: MessageType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateReviewSubscription = {
  onCreateReview?:  {
    __typename: "Review",
    id: string,
    solicitorId: string,
    caseId: string,
    clientId: string,
    rating: number,
    comment?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateReviewSubscription = {
  onUpdateReview?:  {
    __typename: "Review",
    id: string,
    solicitorId: string,
    caseId: string,
    clientId: string,
    rating: number,
    comment?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteReviewSubscription = {
  onDeleteReview?:  {
    __typename: "Review",
    id: string,
    solicitorId: string,
    caseId: string,
    clientId: string,
    rating: number,
    comment?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCaseCompletionRequestSubscriptionVariables = {
  requesterId?: string | null,
};

export type OnCreateCaseCompletionRequestSubscription = {
  onCreateCaseCompletionRequest?:  {
    __typename: "CaseCompletionRequest",
    id: string,
    caseId: string,
    case:  {
      __typename: "Case",
      id: string,
      caseNumber: string,
      brand?: string | null,
      productSKU?: string | null,
      hasChatbot?: boolean | null,
      payFirst?: boolean | null,
      context?: string | null,
      createdAt: string,
      clientId: string,
      client?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientKyc?:  {
        __typename: "CaseClientKyc",
        isSubmitted?: boolean | null,
        isVerified?: boolean | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
      } | null,
      files?:  Array< {
        __typename: "File",
        name: string,
        key: string,
        lastModified: string,
        size: number,
      } > | null,
      details?:  {
        __typename: "CaseDetails",
        type: CaseDetailsType,
        isTimeSensitive?: boolean | null,
        details: string,
        summary?: string | null,
        ask?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items?:  Array< {
          __typename: "Message",
          id: string,
          author?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          authorId: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          content: string,
          type: MessageType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      quotes?:  {
        __typename: "ModelCaseQuoteConnection",
        items?:  Array< {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      payments?:  {
        __typename: "ModelCasePaymentConnection",
        items?:  Array< {
          __typename: "CasePayment",
          id: string,
          caseId: string,
          clientId: string,
          quoteId?: string | null,
          type: CasePaymentType,
          stripeAccountId: string,
          stripePaymentIntentId: string,
          toPlatform: boolean,
          amount: number,
          refunded?: boolean | null,
          solicitorId?: string | null,
          chargeType?: ChargeType | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      caseSolicitorId?: string | null,
      solicitor?:  {
        __typename: "Solicitor",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        name: string,
        businessName: string,
        sraRegistrationNumber?: string | null,
        stripeAccountId?: string | null,
        onboardingStatus?:  {
          __typename: "SolicitorOnboardingStatus",
          hasAccount: boolean,
          accountType: SolicitorAccountType,
          hasChargesEnabled: boolean,
          hasSubmitDetails: boolean,
          hasPayoutsEnabled: boolean,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      solicitorResponse?:  {
        __typename: "SolicitorResponse",
        type: SolicitorResponseType,
        solicitorId: string,
        reason?: string | null,
      } | null,
      completion?:  {
        __typename: "CaseCompletion",
        type?: CaseCompletionType | null,
        details?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items?:  Array< {
          __typename: "Review",
          id: string,
          solicitorId: string,
          caseId: string,
          clientId: string,
          rating: number,
          comment?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      status: CaseStatus,
      assignedToSolicitorAt?: string | null,
      askedClientToPayAt?: string | null,
      statusWhenCancelled?: string | null,
      exportedCaseId?: string | null,
      updatedAt: string,
    },
    requesterId: string,
    requester?:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    accepted?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCaseCompletionRequestSubscriptionVariables = {
  requesterId?: string | null,
};

export type OnUpdateCaseCompletionRequestSubscription = {
  onUpdateCaseCompletionRequest?:  {
    __typename: "CaseCompletionRequest",
    id: string,
    caseId: string,
    case:  {
      __typename: "Case",
      id: string,
      caseNumber: string,
      brand?: string | null,
      productSKU?: string | null,
      hasChatbot?: boolean | null,
      payFirst?: boolean | null,
      context?: string | null,
      createdAt: string,
      clientId: string,
      client?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientKyc?:  {
        __typename: "CaseClientKyc",
        isSubmitted?: boolean | null,
        isVerified?: boolean | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
      } | null,
      files?:  Array< {
        __typename: "File",
        name: string,
        key: string,
        lastModified: string,
        size: number,
      } > | null,
      details?:  {
        __typename: "CaseDetails",
        type: CaseDetailsType,
        isTimeSensitive?: boolean | null,
        details: string,
        summary?: string | null,
        ask?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items?:  Array< {
          __typename: "Message",
          id: string,
          author?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          authorId: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          content: string,
          type: MessageType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      quotes?:  {
        __typename: "ModelCaseQuoteConnection",
        items?:  Array< {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      payments?:  {
        __typename: "ModelCasePaymentConnection",
        items?:  Array< {
          __typename: "CasePayment",
          id: string,
          caseId: string,
          clientId: string,
          quoteId?: string | null,
          type: CasePaymentType,
          stripeAccountId: string,
          stripePaymentIntentId: string,
          toPlatform: boolean,
          amount: number,
          refunded?: boolean | null,
          solicitorId?: string | null,
          chargeType?: ChargeType | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      caseSolicitorId?: string | null,
      solicitor?:  {
        __typename: "Solicitor",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        name: string,
        businessName: string,
        sraRegistrationNumber?: string | null,
        stripeAccountId?: string | null,
        onboardingStatus?:  {
          __typename: "SolicitorOnboardingStatus",
          hasAccount: boolean,
          accountType: SolicitorAccountType,
          hasChargesEnabled: boolean,
          hasSubmitDetails: boolean,
          hasPayoutsEnabled: boolean,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      solicitorResponse?:  {
        __typename: "SolicitorResponse",
        type: SolicitorResponseType,
        solicitorId: string,
        reason?: string | null,
      } | null,
      completion?:  {
        __typename: "CaseCompletion",
        type?: CaseCompletionType | null,
        details?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items?:  Array< {
          __typename: "Review",
          id: string,
          solicitorId: string,
          caseId: string,
          clientId: string,
          rating: number,
          comment?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      status: CaseStatus,
      assignedToSolicitorAt?: string | null,
      askedClientToPayAt?: string | null,
      statusWhenCancelled?: string | null,
      exportedCaseId?: string | null,
      updatedAt: string,
    },
    requesterId: string,
    requester?:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    accepted?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCaseCompletionRequestSubscriptionVariables = {
  requesterId?: string | null,
};

export type OnDeleteCaseCompletionRequestSubscription = {
  onDeleteCaseCompletionRequest?:  {
    __typename: "CaseCompletionRequest",
    id: string,
    caseId: string,
    case:  {
      __typename: "Case",
      id: string,
      caseNumber: string,
      brand?: string | null,
      productSKU?: string | null,
      hasChatbot?: boolean | null,
      payFirst?: boolean | null,
      context?: string | null,
      createdAt: string,
      clientId: string,
      client?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientKyc?:  {
        __typename: "CaseClientKyc",
        isSubmitted?: boolean | null,
        isVerified?: boolean | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
      } | null,
      files?:  Array< {
        __typename: "File",
        name: string,
        key: string,
        lastModified: string,
        size: number,
      } > | null,
      details?:  {
        __typename: "CaseDetails",
        type: CaseDetailsType,
        isTimeSensitive?: boolean | null,
        details: string,
        summary?: string | null,
        ask?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items?:  Array< {
          __typename: "Message",
          id: string,
          author?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          authorId: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          content: string,
          type: MessageType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      quotes?:  {
        __typename: "ModelCaseQuoteConnection",
        items?:  Array< {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      payments?:  {
        __typename: "ModelCasePaymentConnection",
        items?:  Array< {
          __typename: "CasePayment",
          id: string,
          caseId: string,
          clientId: string,
          quoteId?: string | null,
          type: CasePaymentType,
          stripeAccountId: string,
          stripePaymentIntentId: string,
          toPlatform: boolean,
          amount: number,
          refunded?: boolean | null,
          solicitorId?: string | null,
          chargeType?: ChargeType | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      caseSolicitorId?: string | null,
      solicitor?:  {
        __typename: "Solicitor",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        name: string,
        businessName: string,
        sraRegistrationNumber?: string | null,
        stripeAccountId?: string | null,
        onboardingStatus?:  {
          __typename: "SolicitorOnboardingStatus",
          hasAccount: boolean,
          accountType: SolicitorAccountType,
          hasChargesEnabled: boolean,
          hasSubmitDetails: boolean,
          hasPayoutsEnabled: boolean,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      solicitorResponse?:  {
        __typename: "SolicitorResponse",
        type: SolicitorResponseType,
        solicitorId: string,
        reason?: string | null,
      } | null,
      completion?:  {
        __typename: "CaseCompletion",
        type?: CaseCompletionType | null,
        details?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items?:  Array< {
          __typename: "Review",
          id: string,
          solicitorId: string,
          caseId: string,
          clientId: string,
          rating: number,
          comment?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      status: CaseStatus,
      assignedToSolicitorAt?: string | null,
      askedClientToPayAt?: string | null,
      statusWhenCancelled?: string | null,
      exportedCaseId?: string | null,
      updatedAt: string,
    },
    requesterId: string,
    requester?:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    accepted?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateConfigurationSubscription = {
  onCreateConfiguration?:  {
    __typename: "Configuration",
    key: string,
    value: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateConfigurationSubscription = {
  onUpdateConfiguration?:  {
    __typename: "Configuration",
    key: string,
    value: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteConfigurationSubscription = {
  onDeleteConfiguration?:  {
    __typename: "Configuration",
    key: string,
    value: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateKycSubmissionSubscriptionVariables = {
  userId?: string | null,
};

export type OnCreateKycSubmissionSubscription = {
  onCreateKYCSubmission?:  {
    __typename: "KYCSubmission",
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    status?: KYCSubmissionStatus | null,
    address?: string | null,
    documentUploads?: Array< string > | null,
    identityUploads?: Array< string > | null,
    verificationReport?:  {
      __typename: "KycVerificationReport",
      amlReportFileKey: string,
      kycReportFileKey?: string | null,
    } | null,
    rejectionReason?:  {
      __typename: "KycRejectionReason",
      reason?: string | null,
      instructions?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateKycSubmissionSubscriptionVariables = {
  userId?: string | null,
};

export type OnUpdateKycSubmissionSubscription = {
  onUpdateKYCSubmission?:  {
    __typename: "KYCSubmission",
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    status?: KYCSubmissionStatus | null,
    address?: string | null,
    documentUploads?: Array< string > | null,
    identityUploads?: Array< string > | null,
    verificationReport?:  {
      __typename: "KycVerificationReport",
      amlReportFileKey: string,
      kycReportFileKey?: string | null,
    } | null,
    rejectionReason?:  {
      __typename: "KycRejectionReason",
      reason?: string | null,
      instructions?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteKycSubmissionSubscriptionVariables = {
  userId?: string | null,
};

export type OnDeleteKycSubmissionSubscription = {
  onDeleteKYCSubmission?:  {
    __typename: "KYCSubmission",
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    status?: KYCSubmissionStatus | null,
    address?: string | null,
    documentUploads?: Array< string > | null,
    identityUploads?: Array< string > | null,
    verificationReport?:  {
      __typename: "KycVerificationReport",
      amlReportFileKey: string,
      kycReportFileKey?: string | null,
    } | null,
    rejectionReason?:  {
      __typename: "KycRejectionReason",
      reason?: string | null,
      instructions?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateNotifcationEmailSubscription = {
  onCreateNotifcationEmail?:  {
    __typename: "NotifcationEmail",
    id: string,
    user:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    userId: string,
    caseId: string,
    case:  {
      __typename: "Case",
      id: string,
      caseNumber: string,
      brand?: string | null,
      productSKU?: string | null,
      hasChatbot?: boolean | null,
      payFirst?: boolean | null,
      context?: string | null,
      createdAt: string,
      clientId: string,
      client?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientKyc?:  {
        __typename: "CaseClientKyc",
        isSubmitted?: boolean | null,
        isVerified?: boolean | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
      } | null,
      files?:  Array< {
        __typename: "File",
        name: string,
        key: string,
        lastModified: string,
        size: number,
      } > | null,
      details?:  {
        __typename: "CaseDetails",
        type: CaseDetailsType,
        isTimeSensitive?: boolean | null,
        details: string,
        summary?: string | null,
        ask?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items?:  Array< {
          __typename: "Message",
          id: string,
          author?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          authorId: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          content: string,
          type: MessageType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      quotes?:  {
        __typename: "ModelCaseQuoteConnection",
        items?:  Array< {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      payments?:  {
        __typename: "ModelCasePaymentConnection",
        items?:  Array< {
          __typename: "CasePayment",
          id: string,
          caseId: string,
          clientId: string,
          quoteId?: string | null,
          type: CasePaymentType,
          stripeAccountId: string,
          stripePaymentIntentId: string,
          toPlatform: boolean,
          amount: number,
          refunded?: boolean | null,
          solicitorId?: string | null,
          chargeType?: ChargeType | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      caseSolicitorId?: string | null,
      solicitor?:  {
        __typename: "Solicitor",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        name: string,
        businessName: string,
        sraRegistrationNumber?: string | null,
        stripeAccountId?: string | null,
        onboardingStatus?:  {
          __typename: "SolicitorOnboardingStatus",
          hasAccount: boolean,
          accountType: SolicitorAccountType,
          hasChargesEnabled: boolean,
          hasSubmitDetails: boolean,
          hasPayoutsEnabled: boolean,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      solicitorResponse?:  {
        __typename: "SolicitorResponse",
        type: SolicitorResponseType,
        solicitorId: string,
        reason?: string | null,
      } | null,
      completion?:  {
        __typename: "CaseCompletion",
        type?: CaseCompletionType | null,
        details?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items?:  Array< {
          __typename: "Review",
          id: string,
          solicitorId: string,
          caseId: string,
          clientId: string,
          rating: number,
          comment?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      status: CaseStatus,
      assignedToSolicitorAt?: string | null,
      askedClientToPayAt?: string | null,
      statusWhenCancelled?: string | null,
      exportedCaseId?: string | null,
      updatedAt: string,
    },
    type?: NotificationType | null,
    lastSentAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateNotifcationEmailSubscription = {
  onUpdateNotifcationEmail?:  {
    __typename: "NotifcationEmail",
    id: string,
    user:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    userId: string,
    caseId: string,
    case:  {
      __typename: "Case",
      id: string,
      caseNumber: string,
      brand?: string | null,
      productSKU?: string | null,
      hasChatbot?: boolean | null,
      payFirst?: boolean | null,
      context?: string | null,
      createdAt: string,
      clientId: string,
      client?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientKyc?:  {
        __typename: "CaseClientKyc",
        isSubmitted?: boolean | null,
        isVerified?: boolean | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
      } | null,
      files?:  Array< {
        __typename: "File",
        name: string,
        key: string,
        lastModified: string,
        size: number,
      } > | null,
      details?:  {
        __typename: "CaseDetails",
        type: CaseDetailsType,
        isTimeSensitive?: boolean | null,
        details: string,
        summary?: string | null,
        ask?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items?:  Array< {
          __typename: "Message",
          id: string,
          author?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          authorId: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          content: string,
          type: MessageType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      quotes?:  {
        __typename: "ModelCaseQuoteConnection",
        items?:  Array< {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      payments?:  {
        __typename: "ModelCasePaymentConnection",
        items?:  Array< {
          __typename: "CasePayment",
          id: string,
          caseId: string,
          clientId: string,
          quoteId?: string | null,
          type: CasePaymentType,
          stripeAccountId: string,
          stripePaymentIntentId: string,
          toPlatform: boolean,
          amount: number,
          refunded?: boolean | null,
          solicitorId?: string | null,
          chargeType?: ChargeType | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      caseSolicitorId?: string | null,
      solicitor?:  {
        __typename: "Solicitor",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        name: string,
        businessName: string,
        sraRegistrationNumber?: string | null,
        stripeAccountId?: string | null,
        onboardingStatus?:  {
          __typename: "SolicitorOnboardingStatus",
          hasAccount: boolean,
          accountType: SolicitorAccountType,
          hasChargesEnabled: boolean,
          hasSubmitDetails: boolean,
          hasPayoutsEnabled: boolean,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      solicitorResponse?:  {
        __typename: "SolicitorResponse",
        type: SolicitorResponseType,
        solicitorId: string,
        reason?: string | null,
      } | null,
      completion?:  {
        __typename: "CaseCompletion",
        type?: CaseCompletionType | null,
        details?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items?:  Array< {
          __typename: "Review",
          id: string,
          solicitorId: string,
          caseId: string,
          clientId: string,
          rating: number,
          comment?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      status: CaseStatus,
      assignedToSolicitorAt?: string | null,
      askedClientToPayAt?: string | null,
      statusWhenCancelled?: string | null,
      exportedCaseId?: string | null,
      updatedAt: string,
    },
    type?: NotificationType | null,
    lastSentAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteNotifcationEmailSubscription = {
  onDeleteNotifcationEmail?:  {
    __typename: "NotifcationEmail",
    id: string,
    user:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    userId: string,
    caseId: string,
    case:  {
      __typename: "Case",
      id: string,
      caseNumber: string,
      brand?: string | null,
      productSKU?: string | null,
      hasChatbot?: boolean | null,
      payFirst?: boolean | null,
      context?: string | null,
      createdAt: string,
      clientId: string,
      client?:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientKyc?:  {
        __typename: "CaseClientKyc",
        isSubmitted?: boolean | null,
        isVerified?: boolean | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
      } | null,
      files?:  Array< {
        __typename: "File",
        name: string,
        key: string,
        lastModified: string,
        size: number,
      } > | null,
      details?:  {
        __typename: "CaseDetails",
        type: CaseDetailsType,
        isTimeSensitive?: boolean | null,
        details: string,
        summary?: string | null,
        ask?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items?:  Array< {
          __typename: "Message",
          id: string,
          author?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          authorId: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          content: string,
          type: MessageType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      quotes?:  {
        __typename: "ModelCaseQuoteConnection",
        items?:  Array< {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      payments?:  {
        __typename: "ModelCasePaymentConnection",
        items?:  Array< {
          __typename: "CasePayment",
          id: string,
          caseId: string,
          clientId: string,
          quoteId?: string | null,
          type: CasePaymentType,
          stripeAccountId: string,
          stripePaymentIntentId: string,
          toPlatform: boolean,
          amount: number,
          refunded?: boolean | null,
          solicitorId?: string | null,
          chargeType?: ChargeType | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      caseSolicitorId?: string | null,
      solicitor?:  {
        __typename: "Solicitor",
        id: string,
        user?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        name: string,
        businessName: string,
        sraRegistrationNumber?: string | null,
        stripeAccountId?: string | null,
        onboardingStatus?:  {
          __typename: "SolicitorOnboardingStatus",
          hasAccount: boolean,
          accountType: SolicitorAccountType,
          hasChargesEnabled: boolean,
          hasSubmitDetails: boolean,
          hasPayoutsEnabled: boolean,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      solicitorResponse?:  {
        __typename: "SolicitorResponse",
        type: SolicitorResponseType,
        solicitorId: string,
        reason?: string | null,
      } | null,
      completion?:  {
        __typename: "CaseCompletion",
        type?: CaseCompletionType | null,
        details?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items?:  Array< {
          __typename: "Review",
          id: string,
          solicitorId: string,
          caseId: string,
          clientId: string,
          rating: number,
          comment?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      status: CaseStatus,
      assignedToSolicitorAt?: string | null,
      askedClientToPayAt?: string | null,
      statusWhenCancelled?: string | null,
      exportedCaseId?: string | null,
      updatedAt: string,
    },
    type?: NotificationType | null,
    lastSentAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateProductSubscription = {
  onCreateProduct?:  {
    __typename: "Product",
    id: string,
    sku: string,
    title: string,
    description: string,
    lineItems?:  {
      __typename: "ModelCaseLineItemConnection",
      items?:  Array< {
        __typename: "CaseLineItem",
        id: string,
        quote:  {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        quoteId: string,
        caseLineItemProductId?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          sku: string,
          title: string,
          description: string,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        description?: string | null,
        clientPays: number,
        solicitorReceives: number,
        subsidy: number,
        platformFee: number,
        feePercentage: number,
        scope?:  {
          __typename: "CaseScope",
          includes: Array< string >,
          excludes: Array< string >,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateProductSubscription = {
  onUpdateProduct?:  {
    __typename: "Product",
    id: string,
    sku: string,
    title: string,
    description: string,
    lineItems?:  {
      __typename: "ModelCaseLineItemConnection",
      items?:  Array< {
        __typename: "CaseLineItem",
        id: string,
        quote:  {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        quoteId: string,
        caseLineItemProductId?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          sku: string,
          title: string,
          description: string,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        description?: string | null,
        clientPays: number,
        solicitorReceives: number,
        subsidy: number,
        platformFee: number,
        feePercentage: number,
        scope?:  {
          __typename: "CaseScope",
          includes: Array< string >,
          excludes: Array< string >,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteProductSubscription = {
  onDeleteProduct?:  {
    __typename: "Product",
    id: string,
    sku: string,
    title: string,
    description: string,
    lineItems?:  {
      __typename: "ModelCaseLineItemConnection",
      items?:  Array< {
        __typename: "CaseLineItem",
        id: string,
        quote:  {
          __typename: "CaseQuote",
          id: string,
          case:  {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          },
          caseId: string,
          isLeadFromAds?: boolean | null,
          isManualFee?: boolean | null,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        quoteId: string,
        caseLineItemProductId?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          sku: string,
          title: string,
          description: string,
          lineItems?:  {
            __typename: "ModelCaseLineItemConnection",
            items?:  Array< {
              __typename: "CaseLineItem",
              id: string,
              quoteId: string,
              caseLineItemProductId?: string | null,
              description?: string | null,
              clientPays: number,
              solicitorReceives: number,
              subsidy: number,
              platformFee: number,
              feePercentage: number,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        description?: string | null,
        clientPays: number,
        solicitorReceives: number,
        subsidy: number,
        platformFee: number,
        feePercentage: number,
        scope?:  {
          __typename: "CaseScope",
          includes: Array< string >,
          excludes: Array< string >,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSolicitorSubscriptionVariables = {
  id?: string | null,
};

export type OnCreateSolicitorSubscription = {
  onCreateSolicitor?:  {
    __typename: "Solicitor",
    id: string,
    user?:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    businessName: string,
    sraRegistrationNumber?: string | null,
    stripeAccountId?: string | null,
    onboardingStatus?:  {
      __typename: "SolicitorOnboardingStatus",
      hasAccount: boolean,
      accountType: SolicitorAccountType,
      hasChargesEnabled: boolean,
      hasSubmitDetails: boolean,
      hasPayoutsEnabled: boolean,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSolicitorSubscriptionVariables = {
  id?: string | null,
};

export type OnUpdateSolicitorSubscription = {
  onUpdateSolicitor?:  {
    __typename: "Solicitor",
    id: string,
    user?:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    businessName: string,
    sraRegistrationNumber?: string | null,
    stripeAccountId?: string | null,
    onboardingStatus?:  {
      __typename: "SolicitorOnboardingStatus",
      hasAccount: boolean,
      accountType: SolicitorAccountType,
      hasChargesEnabled: boolean,
      hasSubmitDetails: boolean,
      hasPayoutsEnabled: boolean,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSolicitorSubscriptionVariables = {
  id?: string | null,
};

export type OnDeleteSolicitorSubscription = {
  onDeleteSolicitor?:  {
    __typename: "Solicitor",
    id: string,
    user?:  {
      __typename: "User",
      id: string,
      type?: UserType | null,
      emailAddress?: string | null,
      displayName?: string | null,
      phoneNumber?: string | null,
      kycSubmission?:  {
        __typename: "KYCSubmission",
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        status?: KYCSubmissionStatus | null,
        address?: string | null,
        documentUploads?: Array< string > | null,
        identityUploads?: Array< string > | null,
        verificationReport?:  {
          __typename: "KycVerificationReport",
          amlReportFileKey: string,
          kycReportFileKey?: string | null,
        } | null,
        rejectionReason?:  {
          __typename: "KycRejectionReason",
          reason?: string | null,
          instructions?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeCustomerId?: string | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        id: string,
        lastFour: string,
        brand: string,
        expiryMonth: number,
        expiryYear: number,
      } > | null,
      clientCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      solicitorCases?:  {
        __typename: "ModelCaseConnection",
        items?:  Array< {
          __typename: "Case",
          id: string,
          caseNumber: string,
          brand?: string | null,
          productSKU?: string | null,
          hasChatbot?: boolean | null,
          payFirst?: boolean | null,
          context?: string | null,
          createdAt: string,
          clientId: string,
          client?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          clientKyc?:  {
            __typename: "CaseClientKyc",
            isSubmitted?: boolean | null,
            isVerified?: boolean | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
          } | null,
          files?:  Array< {
            __typename: "File",
            name: string,
            key: string,
            lastModified: string,
            size: number,
          } > | null,
          details?:  {
            __typename: "CaseDetails",
            type: CaseDetailsType,
            isTimeSensitive?: boolean | null,
            details: string,
            summary?: string | null,
            ask?: string | null,
          } | null,
          messages?:  {
            __typename: "ModelMessageConnection",
            items?:  Array< {
              __typename: "Message",
              id: string,
              authorId: string,
              caseId: string,
              content: string,
              type: MessageType,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          quotes?:  {
            __typename: "ModelCaseQuoteConnection",
            items?:  Array< {
              __typename: "CaseQuote",
              id: string,
              caseId: string,
              isLeadFromAds?: boolean | null,
              isManualFee?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          payments?:  {
            __typename: "ModelCasePaymentConnection",
            items?:  Array< {
              __typename: "CasePayment",
              id: string,
              caseId: string,
              clientId: string,
              quoteId?: string | null,
              type: CasePaymentType,
              stripeAccountId: string,
              stripePaymentIntentId: string,
              toPlatform: boolean,
              amount: number,
              refunded?: boolean | null,
              solicitorId?: string | null,
              chargeType?: ChargeType | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          caseSolicitorId?: string | null,
          solicitor?:  {
            __typename: "Solicitor",
            id: string,
            user?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            name: string,
            businessName: string,
            sraRegistrationNumber?: string | null,
            stripeAccountId?: string | null,
            onboardingStatus?:  {
              __typename: "SolicitorOnboardingStatus",
              hasAccount: boolean,
              accountType: SolicitorAccountType,
              hasChargesEnabled: boolean,
              hasSubmitDetails: boolean,
              hasPayoutsEnabled: boolean,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          solicitorResponse?:  {
            __typename: "SolicitorResponse",
            type: SolicitorResponseType,
            solicitorId: string,
            reason?: string | null,
          } | null,
          completion?:  {
            __typename: "CaseCompletion",
            type?: CaseCompletionType | null,
            details?: string | null,
          } | null,
          review?:  {
            __typename: "ModelReviewConnection",
            items?:  Array< {
              __typename: "Review",
              id: string,
              solicitorId: string,
              caseId: string,
              clientId: string,
              rating: number,
              comment?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          status: CaseStatus,
          assignedToSolicitorAt?: string | null,
          askedClientToPayAt?: string | null,
          statusWhenCancelled?: string | null,
          exportedCaseId?: string | null,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      referral?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    businessName: string,
    sraRegistrationNumber?: string | null,
    stripeAccountId?: string | null,
    onboardingStatus?:  {
      __typename: "SolicitorOnboardingStatus",
      hasAccount: boolean,
      accountType: SolicitorAccountType,
      hasChargesEnabled: boolean,
      hasSubmitDetails: boolean,
      hasPayoutsEnabled: boolean,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  id?: string | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    type?: UserType | null,
    emailAddress?: string | null,
    displayName?: string | null,
    phoneNumber?: string | null,
    kycSubmission?:  {
      __typename: "KYCSubmission",
      userId: string,
      user:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      status?: KYCSubmissionStatus | null,
      address?: string | null,
      documentUploads?: Array< string > | null,
      identityUploads?: Array< string > | null,
      verificationReport?:  {
        __typename: "KycVerificationReport",
        amlReportFileKey: string,
        kycReportFileKey?: string | null,
      } | null,
      rejectionReason?:  {
        __typename: "KycRejectionReason",
        reason?: string | null,
        instructions?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    stripeCustomerId?: string | null,
    paymentMethods?:  Array< {
      __typename: "PaymentMethod",
      id: string,
      lastFour: string,
      brand: string,
      expiryMonth: number,
      expiryYear: number,
    } > | null,
    clientCases?:  {
      __typename: "ModelCaseConnection",
      items?:  Array< {
        __typename: "Case",
        id: string,
        caseNumber: string,
        brand?: string | null,
        productSKU?: string | null,
        hasChatbot?: boolean | null,
        payFirst?: boolean | null,
        context?: string | null,
        createdAt: string,
        clientId: string,
        client?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientKyc?:  {
          __typename: "CaseClientKyc",
          isSubmitted?: boolean | null,
          isVerified?: boolean | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
        } | null,
        files?:  Array< {
          __typename: "File",
          name: string,
          key: string,
          lastModified: string,
          size: number,
        } > | null,
        details?:  {
          __typename: "CaseDetails",
          type: CaseDetailsType,
          isTimeSensitive?: boolean | null,
          details: string,
          summary?: string | null,
          ask?: string | null,
        } | null,
        messages?:  {
          __typename: "ModelMessageConnection",
          items?:  Array< {
            __typename: "Message",
            id: string,
            author?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            authorId: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            content: string,
            type: MessageType,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        quotes?:  {
          __typename: "ModelCaseQuoteConnection",
          items?:  Array< {
            __typename: "CaseQuote",
            id: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            isLeadFromAds?: boolean | null,
            isManualFee?: boolean | null,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        caseSolicitorId?: string | null,
        solicitor?:  {
          __typename: "Solicitor",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          name: string,
          businessName: string,
          sraRegistrationNumber?: string | null,
          stripeAccountId?: string | null,
          onboardingStatus?:  {
            __typename: "SolicitorOnboardingStatus",
            hasAccount: boolean,
            accountType: SolicitorAccountType,
            hasChargesEnabled: boolean,
            hasSubmitDetails: boolean,
            hasPayoutsEnabled: boolean,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        solicitorResponse?:  {
          __typename: "SolicitorResponse",
          type: SolicitorResponseType,
          solicitorId: string,
          reason?: string | null,
        } | null,
        completion?:  {
          __typename: "CaseCompletion",
          type?: CaseCompletionType | null,
          details?: string | null,
        } | null,
        review?:  {
          __typename: "ModelReviewConnection",
          items?:  Array< {
            __typename: "Review",
            id: string,
            solicitorId: string,
            caseId: string,
            clientId: string,
            rating: number,
            comment?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        status: CaseStatus,
        assignedToSolicitorAt?: string | null,
        askedClientToPayAt?: string | null,
        statusWhenCancelled?: string | null,
        exportedCaseId?: string | null,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    solicitorCases?:  {
      __typename: "ModelCaseConnection",
      items?:  Array< {
        __typename: "Case",
        id: string,
        caseNumber: string,
        brand?: string | null,
        productSKU?: string | null,
        hasChatbot?: boolean | null,
        payFirst?: boolean | null,
        context?: string | null,
        createdAt: string,
        clientId: string,
        client?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientKyc?:  {
          __typename: "CaseClientKyc",
          isSubmitted?: boolean | null,
          isVerified?: boolean | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
        } | null,
        files?:  Array< {
          __typename: "File",
          name: string,
          key: string,
          lastModified: string,
          size: number,
        } > | null,
        details?:  {
          __typename: "CaseDetails",
          type: CaseDetailsType,
          isTimeSensitive?: boolean | null,
          details: string,
          summary?: string | null,
          ask?: string | null,
        } | null,
        messages?:  {
          __typename: "ModelMessageConnection",
          items?:  Array< {
            __typename: "Message",
            id: string,
            author?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            authorId: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            content: string,
            type: MessageType,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        quotes?:  {
          __typename: "ModelCaseQuoteConnection",
          items?:  Array< {
            __typename: "CaseQuote",
            id: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            isLeadFromAds?: boolean | null,
            isManualFee?: boolean | null,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        caseSolicitorId?: string | null,
        solicitor?:  {
          __typename: "Solicitor",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          name: string,
          businessName: string,
          sraRegistrationNumber?: string | null,
          stripeAccountId?: string | null,
          onboardingStatus?:  {
            __typename: "SolicitorOnboardingStatus",
            hasAccount: boolean,
            accountType: SolicitorAccountType,
            hasChargesEnabled: boolean,
            hasSubmitDetails: boolean,
            hasPayoutsEnabled: boolean,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        solicitorResponse?:  {
          __typename: "SolicitorResponse",
          type: SolicitorResponseType,
          solicitorId: string,
          reason?: string | null,
        } | null,
        completion?:  {
          __typename: "CaseCompletion",
          type?: CaseCompletionType | null,
          details?: string | null,
        } | null,
        review?:  {
          __typename: "ModelReviewConnection",
          items?:  Array< {
            __typename: "Review",
            id: string,
            solicitorId: string,
            caseId: string,
            clientId: string,
            rating: number,
            comment?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        status: CaseStatus,
        assignedToSolicitorAt?: string | null,
        askedClientToPayAt?: string | null,
        statusWhenCancelled?: string | null,
        exportedCaseId?: string | null,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    referral?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  id?: string | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    type?: UserType | null,
    emailAddress?: string | null,
    displayName?: string | null,
    phoneNumber?: string | null,
    kycSubmission?:  {
      __typename: "KYCSubmission",
      userId: string,
      user:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      status?: KYCSubmissionStatus | null,
      address?: string | null,
      documentUploads?: Array< string > | null,
      identityUploads?: Array< string > | null,
      verificationReport?:  {
        __typename: "KycVerificationReport",
        amlReportFileKey: string,
        kycReportFileKey?: string | null,
      } | null,
      rejectionReason?:  {
        __typename: "KycRejectionReason",
        reason?: string | null,
        instructions?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    stripeCustomerId?: string | null,
    paymentMethods?:  Array< {
      __typename: "PaymentMethod",
      id: string,
      lastFour: string,
      brand: string,
      expiryMonth: number,
      expiryYear: number,
    } > | null,
    clientCases?:  {
      __typename: "ModelCaseConnection",
      items?:  Array< {
        __typename: "Case",
        id: string,
        caseNumber: string,
        brand?: string | null,
        productSKU?: string | null,
        hasChatbot?: boolean | null,
        payFirst?: boolean | null,
        context?: string | null,
        createdAt: string,
        clientId: string,
        client?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientKyc?:  {
          __typename: "CaseClientKyc",
          isSubmitted?: boolean | null,
          isVerified?: boolean | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
        } | null,
        files?:  Array< {
          __typename: "File",
          name: string,
          key: string,
          lastModified: string,
          size: number,
        } > | null,
        details?:  {
          __typename: "CaseDetails",
          type: CaseDetailsType,
          isTimeSensitive?: boolean | null,
          details: string,
          summary?: string | null,
          ask?: string | null,
        } | null,
        messages?:  {
          __typename: "ModelMessageConnection",
          items?:  Array< {
            __typename: "Message",
            id: string,
            author?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            authorId: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            content: string,
            type: MessageType,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        quotes?:  {
          __typename: "ModelCaseQuoteConnection",
          items?:  Array< {
            __typename: "CaseQuote",
            id: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            isLeadFromAds?: boolean | null,
            isManualFee?: boolean | null,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        caseSolicitorId?: string | null,
        solicitor?:  {
          __typename: "Solicitor",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          name: string,
          businessName: string,
          sraRegistrationNumber?: string | null,
          stripeAccountId?: string | null,
          onboardingStatus?:  {
            __typename: "SolicitorOnboardingStatus",
            hasAccount: boolean,
            accountType: SolicitorAccountType,
            hasChargesEnabled: boolean,
            hasSubmitDetails: boolean,
            hasPayoutsEnabled: boolean,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        solicitorResponse?:  {
          __typename: "SolicitorResponse",
          type: SolicitorResponseType,
          solicitorId: string,
          reason?: string | null,
        } | null,
        completion?:  {
          __typename: "CaseCompletion",
          type?: CaseCompletionType | null,
          details?: string | null,
        } | null,
        review?:  {
          __typename: "ModelReviewConnection",
          items?:  Array< {
            __typename: "Review",
            id: string,
            solicitorId: string,
            caseId: string,
            clientId: string,
            rating: number,
            comment?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        status: CaseStatus,
        assignedToSolicitorAt?: string | null,
        askedClientToPayAt?: string | null,
        statusWhenCancelled?: string | null,
        exportedCaseId?: string | null,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    solicitorCases?:  {
      __typename: "ModelCaseConnection",
      items?:  Array< {
        __typename: "Case",
        id: string,
        caseNumber: string,
        brand?: string | null,
        productSKU?: string | null,
        hasChatbot?: boolean | null,
        payFirst?: boolean | null,
        context?: string | null,
        createdAt: string,
        clientId: string,
        client?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientKyc?:  {
          __typename: "CaseClientKyc",
          isSubmitted?: boolean | null,
          isVerified?: boolean | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
        } | null,
        files?:  Array< {
          __typename: "File",
          name: string,
          key: string,
          lastModified: string,
          size: number,
        } > | null,
        details?:  {
          __typename: "CaseDetails",
          type: CaseDetailsType,
          isTimeSensitive?: boolean | null,
          details: string,
          summary?: string | null,
          ask?: string | null,
        } | null,
        messages?:  {
          __typename: "ModelMessageConnection",
          items?:  Array< {
            __typename: "Message",
            id: string,
            author?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            authorId: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            content: string,
            type: MessageType,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        quotes?:  {
          __typename: "ModelCaseQuoteConnection",
          items?:  Array< {
            __typename: "CaseQuote",
            id: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            isLeadFromAds?: boolean | null,
            isManualFee?: boolean | null,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        caseSolicitorId?: string | null,
        solicitor?:  {
          __typename: "Solicitor",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          name: string,
          businessName: string,
          sraRegistrationNumber?: string | null,
          stripeAccountId?: string | null,
          onboardingStatus?:  {
            __typename: "SolicitorOnboardingStatus",
            hasAccount: boolean,
            accountType: SolicitorAccountType,
            hasChargesEnabled: boolean,
            hasSubmitDetails: boolean,
            hasPayoutsEnabled: boolean,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        solicitorResponse?:  {
          __typename: "SolicitorResponse",
          type: SolicitorResponseType,
          solicitorId: string,
          reason?: string | null,
        } | null,
        completion?:  {
          __typename: "CaseCompletion",
          type?: CaseCompletionType | null,
          details?: string | null,
        } | null,
        review?:  {
          __typename: "ModelReviewConnection",
          items?:  Array< {
            __typename: "Review",
            id: string,
            solicitorId: string,
            caseId: string,
            clientId: string,
            rating: number,
            comment?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        status: CaseStatus,
        assignedToSolicitorAt?: string | null,
        askedClientToPayAt?: string | null,
        statusWhenCancelled?: string | null,
        exportedCaseId?: string | null,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    referral?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  id?: string | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    type?: UserType | null,
    emailAddress?: string | null,
    displayName?: string | null,
    phoneNumber?: string | null,
    kycSubmission?:  {
      __typename: "KYCSubmission",
      userId: string,
      user:  {
        __typename: "User",
        id: string,
        type?: UserType | null,
        emailAddress?: string | null,
        displayName?: string | null,
        phoneNumber?: string | null,
        kycSubmission?:  {
          __typename: "KYCSubmission",
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          status?: KYCSubmissionStatus | null,
          address?: string | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          rejectionReason?:  {
            __typename: "KycRejectionReason",
            reason?: string | null,
            instructions?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeCustomerId?: string | null,
        paymentMethods?:  Array< {
          __typename: "PaymentMethod",
          id: string,
          lastFour: string,
          brand: string,
          expiryMonth: number,
          expiryYear: number,
        } > | null,
        clientCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        solicitorCases?:  {
          __typename: "ModelCaseConnection",
          items?:  Array< {
            __typename: "Case",
            id: string,
            caseNumber: string,
            brand?: string | null,
            productSKU?: string | null,
            hasChatbot?: boolean | null,
            payFirst?: boolean | null,
            context?: string | null,
            createdAt: string,
            clientId: string,
            client?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            clientKyc?:  {
              __typename: "CaseClientKyc",
              isSubmitted?: boolean | null,
              isVerified?: boolean | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
            } | null,
            files?:  Array< {
              __typename: "File",
              name: string,
              key: string,
              lastModified: string,
              size: number,
            } > | null,
            details?:  {
              __typename: "CaseDetails",
              type: CaseDetailsType,
              isTimeSensitive?: boolean | null,
              details: string,
              summary?: string | null,
              ask?: string | null,
            } | null,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            quotes?:  {
              __typename: "ModelCaseQuoteConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            caseSolicitorId?: string | null,
            solicitor?:  {
              __typename: "Solicitor",
              id: string,
              name: string,
              businessName: string,
              sraRegistrationNumber?: string | null,
              stripeAccountId?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            solicitorResponse?:  {
              __typename: "SolicitorResponse",
              type: SolicitorResponseType,
              solicitorId: string,
              reason?: string | null,
            } | null,
            completion?:  {
              __typename: "CaseCompletion",
              type?: CaseCompletionType | null,
              details?: string | null,
            } | null,
            review?:  {
              __typename: "ModelReviewConnection",
              nextToken?: string | null,
            } | null,
            status: CaseStatus,
            assignedToSolicitorAt?: string | null,
            askedClientToPayAt?: string | null,
            statusWhenCancelled?: string | null,
            exportedCaseId?: string | null,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        referral?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      status?: KYCSubmissionStatus | null,
      address?: string | null,
      documentUploads?: Array< string > | null,
      identityUploads?: Array< string > | null,
      verificationReport?:  {
        __typename: "KycVerificationReport",
        amlReportFileKey: string,
        kycReportFileKey?: string | null,
      } | null,
      rejectionReason?:  {
        __typename: "KycRejectionReason",
        reason?: string | null,
        instructions?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    stripeCustomerId?: string | null,
    paymentMethods?:  Array< {
      __typename: "PaymentMethod",
      id: string,
      lastFour: string,
      brand: string,
      expiryMonth: number,
      expiryYear: number,
    } > | null,
    clientCases?:  {
      __typename: "ModelCaseConnection",
      items?:  Array< {
        __typename: "Case",
        id: string,
        caseNumber: string,
        brand?: string | null,
        productSKU?: string | null,
        hasChatbot?: boolean | null,
        payFirst?: boolean | null,
        context?: string | null,
        createdAt: string,
        clientId: string,
        client?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientKyc?:  {
          __typename: "CaseClientKyc",
          isSubmitted?: boolean | null,
          isVerified?: boolean | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
        } | null,
        files?:  Array< {
          __typename: "File",
          name: string,
          key: string,
          lastModified: string,
          size: number,
        } > | null,
        details?:  {
          __typename: "CaseDetails",
          type: CaseDetailsType,
          isTimeSensitive?: boolean | null,
          details: string,
          summary?: string | null,
          ask?: string | null,
        } | null,
        messages?:  {
          __typename: "ModelMessageConnection",
          items?:  Array< {
            __typename: "Message",
            id: string,
            author?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            authorId: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            content: string,
            type: MessageType,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        quotes?:  {
          __typename: "ModelCaseQuoteConnection",
          items?:  Array< {
            __typename: "CaseQuote",
            id: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            isLeadFromAds?: boolean | null,
            isManualFee?: boolean | null,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        caseSolicitorId?: string | null,
        solicitor?:  {
          __typename: "Solicitor",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          name: string,
          businessName: string,
          sraRegistrationNumber?: string | null,
          stripeAccountId?: string | null,
          onboardingStatus?:  {
            __typename: "SolicitorOnboardingStatus",
            hasAccount: boolean,
            accountType: SolicitorAccountType,
            hasChargesEnabled: boolean,
            hasSubmitDetails: boolean,
            hasPayoutsEnabled: boolean,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        solicitorResponse?:  {
          __typename: "SolicitorResponse",
          type: SolicitorResponseType,
          solicitorId: string,
          reason?: string | null,
        } | null,
        completion?:  {
          __typename: "CaseCompletion",
          type?: CaseCompletionType | null,
          details?: string | null,
        } | null,
        review?:  {
          __typename: "ModelReviewConnection",
          items?:  Array< {
            __typename: "Review",
            id: string,
            solicitorId: string,
            caseId: string,
            clientId: string,
            rating: number,
            comment?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        status: CaseStatus,
        assignedToSolicitorAt?: string | null,
        askedClientToPayAt?: string | null,
        statusWhenCancelled?: string | null,
        exportedCaseId?: string | null,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    solicitorCases?:  {
      __typename: "ModelCaseConnection",
      items?:  Array< {
        __typename: "Case",
        id: string,
        caseNumber: string,
        brand?: string | null,
        productSKU?: string | null,
        hasChatbot?: boolean | null,
        payFirst?: boolean | null,
        context?: string | null,
        createdAt: string,
        clientId: string,
        client?:  {
          __typename: "User",
          id: string,
          type?: UserType | null,
          emailAddress?: string | null,
          displayName?: string | null,
          phoneNumber?: string | null,
          kycSubmission?:  {
            __typename: "KYCSubmission",
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            status?: KYCSubmissionStatus | null,
            address?: string | null,
            documentUploads?: Array< string > | null,
            identityUploads?: Array< string > | null,
            verificationReport?:  {
              __typename: "KycVerificationReport",
              amlReportFileKey: string,
              kycReportFileKey?: string | null,
            } | null,
            rejectionReason?:  {
              __typename: "KycRejectionReason",
              reason?: string | null,
              instructions?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          stripeCustomerId?: string | null,
          paymentMethods?:  Array< {
            __typename: "PaymentMethod",
            id: string,
            lastFour: string,
            brand: string,
            expiryMonth: number,
            expiryYear: number,
          } > | null,
          clientCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          solicitorCases?:  {
            __typename: "ModelCaseConnection",
            items?:  Array< {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          referral?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientKyc?:  {
          __typename: "CaseClientKyc",
          isSubmitted?: boolean | null,
          isVerified?: boolean | null,
          verificationReport?:  {
            __typename: "KycVerificationReport",
            amlReportFileKey: string,
            kycReportFileKey?: string | null,
          } | null,
          documentUploads?: Array< string > | null,
          identityUploads?: Array< string > | null,
        } | null,
        files?:  Array< {
          __typename: "File",
          name: string,
          key: string,
          lastModified: string,
          size: number,
        } > | null,
        details?:  {
          __typename: "CaseDetails",
          type: CaseDetailsType,
          isTimeSensitive?: boolean | null,
          details: string,
          summary?: string | null,
          ask?: string | null,
        } | null,
        messages?:  {
          __typename: "ModelMessageConnection",
          items?:  Array< {
            __typename: "Message",
            id: string,
            author?:  {
              __typename: "User",
              id: string,
              type?: UserType | null,
              emailAddress?: string | null,
              displayName?: string | null,
              phoneNumber?: string | null,
              stripeCustomerId?: string | null,
              referral?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            authorId: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            content: string,
            type: MessageType,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        quotes?:  {
          __typename: "ModelCaseQuoteConnection",
          items?:  Array< {
            __typename: "CaseQuote",
            id: string,
            case:  {
              __typename: "Case",
              id: string,
              caseNumber: string,
              brand?: string | null,
              productSKU?: string | null,
              hasChatbot?: boolean | null,
              payFirst?: boolean | null,
              context?: string | null,
              createdAt: string,
              clientId: string,
              caseSolicitorId?: string | null,
              status: CaseStatus,
              assignedToSolicitorAt?: string | null,
              askedClientToPayAt?: string | null,
              statusWhenCancelled?: string | null,
              exportedCaseId?: string | null,
              updatedAt: string,
            },
            caseId: string,
            isLeadFromAds?: boolean | null,
            isManualFee?: boolean | null,
            lineItems?:  {
              __typename: "ModelCaseLineItemConnection",
              nextToken?: string | null,
            } | null,
            payments?:  {
              __typename: "ModelCasePaymentConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        payments?:  {
          __typename: "ModelCasePaymentConnection",
          items?:  Array< {
            __typename: "CasePayment",
            id: string,
            caseId: string,
            clientId: string,
            quoteId?: string | null,
            type: CasePaymentType,
            stripeAccountId: string,
            stripePaymentIntentId: string,
            toPlatform: boolean,
            amount: number,
            refunded?: boolean | null,
            solicitorId?: string | null,
            chargeType?: ChargeType | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        caseSolicitorId?: string | null,
        solicitor?:  {
          __typename: "Solicitor",
          id: string,
          user?:  {
            __typename: "User",
            id: string,
            type?: UserType | null,
            emailAddress?: string | null,
            displayName?: string | null,
            phoneNumber?: string | null,
            kycSubmission?:  {
              __typename: "KYCSubmission",
              userId: string,
              status?: KYCSubmissionStatus | null,
              address?: string | null,
              documentUploads?: Array< string > | null,
              identityUploads?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            stripeCustomerId?: string | null,
            paymentMethods?:  Array< {
              __typename: "PaymentMethod",
              id: string,
              lastFour: string,
              brand: string,
              expiryMonth: number,
              expiryYear: number,
            } > | null,
            clientCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            solicitorCases?:  {
              __typename: "ModelCaseConnection",
              nextToken?: string | null,
            } | null,
            referral?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          name: string,
          businessName: string,
          sraRegistrationNumber?: string | null,
          stripeAccountId?: string | null,
          onboardingStatus?:  {
            __typename: "SolicitorOnboardingStatus",
            hasAccount: boolean,
            accountType: SolicitorAccountType,
            hasChargesEnabled: boolean,
            hasSubmitDetails: boolean,
            hasPayoutsEnabled: boolean,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        solicitorResponse?:  {
          __typename: "SolicitorResponse",
          type: SolicitorResponseType,
          solicitorId: string,
          reason?: string | null,
        } | null,
        completion?:  {
          __typename: "CaseCompletion",
          type?: CaseCompletionType | null,
          details?: string | null,
        } | null,
        review?:  {
          __typename: "ModelReviewConnection",
          items?:  Array< {
            __typename: "Review",
            id: string,
            solicitorId: string,
            caseId: string,
            clientId: string,
            rating: number,
            comment?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        status: CaseStatus,
        assignedToSolicitorAt?: string | null,
        askedClientToPayAt?: string | null,
        statusWhenCancelled?: string | null,
        exportedCaseId?: string | null,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    referral?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};
