import { matchExhaustive } from "@practical-fp/union-types"
import { pipe } from "fp-ts/es6/function"
import * as TE from "fp-ts/es6/TaskEither"
import * as O from "fp-ts/es6/Option"
import { useCreateChargeAccount } from "packages/app/src/hooks/useCreateChargeAccount"
import { ChargingModel, GetChargeAccountDTO, useGetChargeAccount } from "packages/app/src/hooks/useGetChargeAccount"
import { useRequestMonthlyChargingModelEnrollment } from "packages/app/src/hooks/useRequestMonthlyChargingModelEnrollment"
import { useSelectChargingModel } from "packages/app/src/hooks/useSelectChargingModel"

type EnrollOptions = {
  successUrl: string
  cancelUrl: string
}
export const useEnrollToMonthlyBilling = (opts: EnrollOptions) => {
  const getChargeAccount = useGetChargeAccount()
  const createChargeAccount = useCreateChargeAccount()
  const selectChargingModel = useSelectChargingModel()
  const enrollToMonthlyChargingModel = useRequestMonthlyChargingModelEnrollment()

  const selectMonthlyModelIfNeeded = (chargeAccount: GetChargeAccountDTO) => matchExhaustive(chargeAccount.model, {
    Monthly: () => TE.right(chargeAccount),
    PayByCase: () => selectChargingModel({
      chargeAccountId: chargeAccount.id,
      model: ChargingModel.Monthly({})
    })
  })

  const createChargeAccountAndRetrieveData = () => pipe(
    createChargeAccount({
      model: ChargingModel.Monthly({})
    }),
    TE.chain(getChargeAccount),
    TE.chainW(TE.fromOption(() => new Error('Failed to get charge after creation')))
  )

  const getOrCreateChargeAccount = () => pipe(
    getChargeAccount(),
    TE.chain(O.fold(
      () => createChargeAccountAndRetrieveData(),
      a => TE.right(a)
    ))
  )

  const subscribeIfNeeded = (chargeAccount: GetChargeAccountDTO) => chargeAccount.needsSubscription
    ? pipe(
      enrollToMonthlyChargingModel({
        chargeAccountId: chargeAccount.id,
        cancelUrl: opts.cancelUrl,
        successUrl: opts.successUrl,
      }),
      TE.map(result => {
        window.location.href = result.url
        return chargeAccount
      })
    )
    : pipe(
      TE.right(chargeAccount),
      TE.map(() => {
        window.location.href = opts.successUrl
        return chargeAccount
      })
    )

  const enrollToMonthlyBilling = () => pipe(
    getOrCreateChargeAccount(),
    TE.chainFirstW(selectMonthlyModelIfNeeded),
    TE.chainW(subscribeIfNeeded)
  )

  return enrollToMonthlyBilling
}
