import { FC, Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import tw from 'twin.macro'
import { Link } from 'react-router-dom'
import { ButtonRaw, ButtonStyleProps } from './Button'

type DropdownMenuItem = {
  href: string
  label: string
}

type DropdownMenuProps = {
  text: string
  items: DropdownMenuItem[]
} & ButtonStyleProps

// tw="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
export const DropdownMenu: FC<DropdownMenuProps> = ({ text, items, ...rest }) => (
  <Menu>
    <div tw="relative inline-block text-left">
      <div>
        <Menu.Button
          as={ButtonRaw}
          {...rest}
        >
          {text}
          <ChevronDownIcon tw="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items tw="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div tw="py-1">
            {items.map((i, x) => (
              <Menu.Item key={`item-${x}`}>
                {({ active }) => (
                  <Link
                    to={i.href}
                    css={[
                      tw`block px-4 py-2 text-sm`,
                      active ? tw`bg-gray-100 text-gray-900` : tw`text-gray-700`
                    ]}
                  >
                    {i.label}
                  </Link>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </div>
  </Menu>
)
