import { isPending, isRepleteLeft, refreshFold } from "@nll/datum/DatumEither"
import { matchWildcard, WILDCARD } from "@practical-fp/union-types"
import { pipe } from "fp-ts/es6/function"
import { chain } from "fp-ts/lib/TaskEither"
import { QuoteBuilderSubshell } from "packages/app/src/components/assessment-call/complete/QuoteBuilderSubshell"
import { QuoteCard } from "packages/app/src/components/assessment-call/complete/QuoteCard"
import { AssessmentCallResult, useCompleteCallWithAnalytics } from 'packages/app/src/components/assessment-call/hooks/useCompleteCall'
import {
  AssessmentResult,
  AssessmentSource,
  useCreateAssessment
} from "packages/app/src/components/assessment-call/hooks/useCreateAssessment"
import { DraftQuote, useDraftQuote } from "packages/app/src/components/assessment-call/hooks/useDraftQuote"
import { useUser } from "packages/app/src/contexts/User"
import { ErrorMessage, FormButtonGroup, WarningMessage } from "packages/app/src/elements"
import { Button, ButtonLink } from "packages/app/src/elements/Button"
import { ROUTES } from "packages/app/src/layout/Navigation"
import { ErrorSubshell } from "packages/app/src/layout/subshells/ErrorSubshell"
import { UUID } from "packages/app/src/lib/Primitives"
import { useFromTaskEither } from "packages/app/src/lib/useAsync"
import { useRouter } from "packages/app/src/lib/useRouter"
import { FC, useEffect } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import "twin.macro"
import { AssessmentCallsDetailsSkeleton } from "../../../components/assessment-call/details/Skeleton"
import {
  AssessmentCallUIDetailsResponse,
  useAssessmentCallDetails
} from "../../../components/assessment-call/hooks/useAssessmentCallDetails"
import { ContentLayoutFlow } from "../../../layout/subshells/DetailsSubshell"
import { RequestError } from "../../../lib/fetch"
import {
  SolicitorQuote,
  SolicitorQuoteLineItem,
  SolicitorQuoteScope,
  useCreateSolicitorQuoteNew
} from "../../../components/assessment-call/hooks/useCreateSolicitorQuoteNew"

export const AssessmentCallSendQuoteScene: FC = () => {
  const { id } = useParams<{ id: string }>()
  const { status, execute } = useAssessmentCallDetails()

  const render = refreshFold(
    () => <AssessmentCallsDetailsSkeleton />,
    () => <AssessmentCallsDetailsSkeleton />,
    (e: RequestError) => matchWildcard(e, {
      ResponseError: e => e.statusCode === 401
        ? <ErrorSubshell title={`You don't have access to this call`} />
        : <ErrorSubshell title={`Error getting call details ${e.details}`} />,
      [WILDCARD]: () => <ErrorSubshell title={`Error getting call details, please check your network or refresh`} />
    }),
    (call: AssessmentCallUIDetailsResponse) => (
      <QuoteBuilderSubshell
        backToText="Back to assessment calls"
        backToUrl={ROUTES.assessmentCalls.details(call.id)}
        clientName={call.leadName}
        summaryHeader="Client's Summary"
        matterSummary={call.requestDetails || ""}
        step={'send'}
        content={(
          <Content call={call} />
        )}
      />
    )
  )

  useEffect(() => { execute(id) }, [id])

  return render(status)
}

const convertDraftToSolicitorQuoteNew = (draft: DraftQuote, requestSummary?: string): SolicitorQuote => ({
  notesFromSolicitor: draft.notes ? `${draft.notes} \n\nOriginal client request:\n${requestSummary}` : `Original client request:\n${requestSummary}`,
  lineItems: draft.lineItems.map((a): SolicitorQuoteLineItem => ({name: a.name, type: a.feeType, amount: a.price})),
  scopes: [
    ...draft.includes.map((a): SolicitorQuoteScope => ({type: 'inclusion', title: a.title})),
    ...draft.excludes.map((a): SolicitorQuoteScope => ({type: 'exclusion', title: a.title}))
  ],
  groups: []
})
const extractProductNameFromDraft = (draft: DraftQuote): string | undefined => {
  return draft.feeGuide?.product?.name || undefined
}
const Content: FC<{ call: AssessmentCallUIDetailsResponse }> = ({ call }) => {
  const { leadId, id: callId, requestDetails } = call;
  const intl = useIntl()

  const { draft } = useDraftQuote(callId)
  const { userId } = useUser()
  const { replace } = useRouter()

  const completeCall = useCompleteCallWithAnalytics()
  const createAssessment = useCreateAssessment()
  const createSolicitorQuote = useCreateSolicitorQuoteNew()

  const { status, execute } = useFromTaskEither(
    (draft: DraftQuote) => pipe(
      createSolicitorQuote({
        productName: extractProductNameFromDraft(draft),
        owner: {tag: 'lead', id: leadId},
        solicitorId: userId as UUID,
        quote: convertDraftToSolicitorQuoteNew(draft, requestDetails)
      }),
      chain(quote => createAssessment({
        result: AssessmentResult.SolicitorQuoted(quote.id as UUID),
        leadId: leadId as UUID,
        source: AssessmentSource.AssessmentCall(callId as UUID)
      })),
      chain(assessment => completeCall(call, {
        result: AssessmentCallResult.Assessed(assessment.id)
      }))
    )
  )

  const submit = async () => {
    await execute(draft)
      .then(() => replace(ROUTES.assessmentCalls.details(callId, { quoted: 'true' })))
  }

  const { push } = useRouter()

  return (
    <>
      <ContentLayoutFlow>
        <QuoteCard
          productName={draft.feeGuide?.product?.name || 'Custom'}
          variantName={draft.feeGuide?.variant?.name}
          lineItems={draft.lineItems}
          includes={draft.includes.map(i => i.title)}
          excludes={draft.excludes.map(i => i.title)}
          notes={draft.notes}
          emptyStateButtonText={`Edit Quote`}
          emptyStateDescription={`Add some by editing this quote.`}
          emptyStateOnClick={() => push(ROUTES.assessmentCalls.complete(callId).quote.customise)}
          actions={
            <ButtonLink
              to={ROUTES.assessmentCalls.complete(callId).quote.customise}
              variant="colour-secondary"
              type="button"
            >
              Edit Quote
            </ButtonLink>
          }
        />

        <WarningMessage
          title={`You're about to agree to this instruction`}
        >
          <div tw="space-y-2">
            <p>
              By submitting this quote, you agree that you wish to engage this client and take on the work to the specifications of the quote above.
            </p>
            <p>
              When submitted, the client will be asked to pay and you will be automatically engaged.
            </p>
          </div>
        </WarningMessage>

        <FormButtonGroup>
          <Button type="button" onClick={submit} isLoading={isPending(status)}>
            Send quote to client
          </Button>
        </FormButtonGroup>

        {isRepleteLeft(status) && (
          <ErrorMessage title="Failed to Send Quote to Client">

          </ErrorMessage>
        )}

      </ContentLayoutFlow>
    </>
  )
}
