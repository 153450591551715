import React, { Children, FC } from 'react'
import tw, { styled } from 'twin.macro'

const TimelineIcon = tw.svg`h-7 w-7`

const TimelineItem: FC<{ icon: JSX.Element, title: string | JSX.Element, first?: boolean, completed?: boolean, active?: boolean, next?: boolean }>
  = ({ icon, title, first, completed, active, next }) => (

    <div>
      <div tw="relative mb-2">
        {!first && (
          <div tw="absolute flex text-center items-center align-middle content-center" style={{ width: "calc(100% - 2.5rem - 1rem)", top: "50%", transform: "translate(-50%, -50%)" }}>
            <div tw="w-full bg-gray-200 rounded items-center align-middle text-center flex-1">
              <div tw="w-0 bg-indigo-500 py-1 rounded" style={{width: completed ? "100%" : next ? "50%" : "0%" }}></div>
            </div>
          </div>
        )}

        <div css={[
          tw`w-10 h-10 mx-auto rounded-full text-lg flex items-center justify-center`,
          (active || completed) ? tw`bg-indigo-500 text-white` : tw`bg-white border-2 border-gray-200 text-gray-600`
        ]}>
          {icon}
        </div>
      </div>

      <div css={[
        tw`text-xs text-center md:text-base`,
        completed && tw`text-indigo-500`,
        active && tw`text-indigo-600 font-semibold`
      ]}>
        {title}
      </div>
    </div>

  )

  // const Timeline = styled.div``
const Timeline = styled.div(({ children }) => [
  tw`flex flex-row w-full`,
  `& > div { width: ${100 / (Children.count(children) > 0 ? Children.count(children) : 1)}%; }`
])

export const KycTimeline: FC<{ step: number }> = ({ step }) => {
  const isActive = (i: number) => step === i
  // The completed property effects the "previous" stages bar due to CSS arrangement
  const isCompleted = (i: number) => step >= i
  const isNext = (i: number) => i === step + 1

  return (
    <Timeline>
      {/* <TimelineItem
        icon={(
          <TimelineIcon stroke="currentColor" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
          </TimelineIcon>
        )}
        title="Your details"
        active={isActive(1)}
        completed={isCompleted(1)}
        next={isNext(1)}
        first
      /> */}
      <TimelineItem
        icon={(
          <TimelineIcon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2" />
          </TimelineIcon>
        )}
        title="Your documents"
        active={isActive(1)}
        completed={isCompleted(1)}
        next={isNext(1)}
        first
      />
      <TimelineItem
        icon={(
          <TimelineIcon stroke="currentColor" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </TimelineIcon>
        )}
        title="Your identity"
        active={isActive(2)}
        completed={isCompleted(2)}
        next={isNext(2)}
      />
      <TimelineItem
        icon={(
          <TimelineIcon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
          </TimelineIcon>
        )}
        title="Your address"
        active={isActive(3)}
        completed={isCompleted(3)}
        next={isNext(3)}
      />
    </Timeline>
  )
}
