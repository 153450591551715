import React, { FC, useMemo } from "react"
import 'twin.macro'

import { SkeletonCard, SkeletonSingleLine } from "../../../elements/Skeleton"
import { SkeletonTable } from "../../../elements/table/SkeletonTable"

export const SkeletonCaseList: FC<{ columns: string[] }> = ({ columns }) => {
  const columnsSkeleton = useMemo(() => columns.map(c => ({
    label: c, skeleton: <SkeletonSingleLine />
  })), [columns])

  return (
    <>
      <div tw="hidden sm:block">
        <SkeletonTable columns={columnsSkeleton} />
      </div>
      <div tw="block sm:hidden">
        <SkeletonCard />
      </div>
    </>
  )
}

