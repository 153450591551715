import { CaseStatus, ListCasesByClientQuery } from "@lawhive/generated-api"
import React, { useEffect, useMemo } from "react"
import { Link } from "react-router-dom"
import "twin.macro"

import { useUser } from "../../../../contexts/User"
import { Card, CardContent } from "../../../../elements"
import { NegativePill, NeutralPill, PositivePill, YellowPill } from "../../../../elements/Badge"
import { ContentContainer, PageContainer } from "../../../../layout/Layout"
import { ROUTES } from "../../../../layout/Navigation"
import { useAsync } from "../../../../lib/useAsync"
import { graphql, isNotNullOrUndefined, renderLoadingOrSuccess } from "../../../../lib/utils"
import { CaseFieldRenderer, CaseListRenderer } from "../CaseListRenderer"
import { SkeletonCaseList } from "../SkeletonCaseList"
import { clientListCasesQuery } from "./clientListCasesQuery"

type ClientCaseListItem = {
  id: string
  number: string
  solicitorName: string
  status: 'pending' | 'quote' | 'active' | 'complete' | 'cancelled'
  createdAt: Date
}

const processListItems =
  (input: ListCasesByClientQuery['listCasesByClient']): ClientCaseListItem[] => (input?.items || [])
    .filter(isNotNullOrUndefined)
    .map((c): ClientCaseListItem => ({
      id: c.id,
      number: c.caseNumber,
      status:
        c.status === CaseStatus.cancelled
          ? 'cancelled'
          : c.status === CaseStatus.completed
            ? 'complete'
            : c.status === CaseStatus.exported
              ? 'active'
              : (c.quotes?.items || []).length > 0
                ? (c.payments?.items || []).length > 0
                  ? 'active'
                  : c.status === CaseStatus.needsClientPayment
                    ? 'quote'
                    : 'pending'
                : 'pending',
      solicitorName: c.status === CaseStatus.needsClientPayment || c.status === CaseStatus.active || c.status === CaseStatus.completed
        ? c.solicitor?.name || 'Lawhive Solicitor'
        : '',
        createdAt: new Date(c.createdAt)
    }))
    // sort in date order desc
    .sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)

export const ClientCasesScene = () => {
  const { userId } = useUser()

  const { status, execute } = useAsync(
    (clientId :string) => graphql<ListCasesByClientQuery>({
      query: clientListCasesQuery(clientId)
    })
    .then(r => r.data?.listCasesByClient)
    .then(processListItems)
  )

  const options = useMemo((): CaseFieldRenderer<ClientCaseListItem>[] => [
    {
      label: 'Case ID',
      accessor: 'number'
    },
    {
      label: 'Solicitor',
      accessor: 'solicitorName',
      render: value => value === ''
        ? (
          <span tw="text-gray-400 flex flex-row items-center">Finding solicitor </span>
        )
        : <span tw="leading-5 font-medium text-gray-900">{value}</span>
    },
    {
      label: 'Status',
      accessor: 'status',
      render: value => value === 'active'
        ? <PositivePill>Case work underway</PositivePill>
        : value === 'quote'
          ? <YellowPill>Quote ready</YellowPill>
          : value === 'complete'
            ? <PositivePill>Completed</PositivePill>
            : value === 'cancelled'
              ? <NegativePill>Inactive</NegativePill>
            :  <NeutralPill>Building case</NeutralPill>
    },
    {
      label: '',
      id: 'details',
      render: () => (
        <p tw="text-indigo-600 hover:text-indigo-900 text-right text-sm leading-5 font-medium">Details</p>
      ),
      renderCard: d => (
        <Link
          to={ROUTES.case.details(d.id)}
          tw="inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-green-500 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-400 focus:outline-none focus:border-green-700 focus:ring-green-200 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
        >
          View Case
        </Link>
      )
    }

  ], [])

  const render = renderLoadingOrSuccess(
    () => <SkeletonCaseList columns={['Case ID', 'Solicitor', 'Status', '']} />,
    (data: ClientCaseListItem[]) => (
      <CaseListRenderer
        data={data}
        selectable={{ key: 'id', urlGenerator: s => ROUTES.case.details(s) }}
        none={<ClientNoCases />}
        options={options}
      />
    )
  )

  useEffect(() => { execute(userId) }, [])

  return (
    <PageContainer>
      <ContentContainer>
        {render(status)}
      </ContentContainer>
    </PageContainer>
  )
}

const ClientNoCases = () => (
  <Card>
    <CardContent>
      <h1 tw="font-medium text-lg">
        You don't have any cases
      </h1>

      <p tw="mt-2">
        Please visit <a tw="text-indigo-600 underline" href="https://lawhive.co.uk/onboarding/start">https://lawhive.co.uk/onboarding/start</a> and
        fill out the form to get started.
      </p>

    </CardContent>
  </Card>
)
