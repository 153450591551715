import { format } from "date-fns/fp"
import { flow, pipe } from "fp-ts/es6/function"
import * as TE from "fp-ts/es6/TaskEither"
import { AccountingCurrencyUnits } from "packages/app/src/elements/Currency"
import { PlainDataTable, SkeletonPlainDataTable, TableColumn } from "packages/app/src/elements/table/PlainDataTable"
import { useGetChargeAccount } from "packages/app/src/hooks/useGetChargeAccount"
import { InvoiceDTO, useGetUpcomingInvoice } from "packages/app/src/hooks/useGetUpcomingInvoice"
import { ROUTES } from "packages/app/src/layout/Navigation"
import { useFromTaskEither } from "packages/app/src/lib/useAsync"
import { renderDatum } from "packages/app/src/lib/utils"
import { useEffect } from "react"
import tw from "twin.macro"

import { impl, matchExhaustive, Variant } from "@practical-fp/union-types"
import { ActionPanel } from "packages/app/src/elements/ActionPanel"
import { ButtonLink } from "packages/app/src/elements/Button"
import { RequestError } from "packages/app/src/lib/fetch"
import { Card, CardHeader, ErrorMessage } from "../../../elements"
import { TableCell } from "packages/app/src/elements/table/Table"

export const ChargesScene = () => {
  const getChargeAccount = useGetChargeAccount()
  const getUpcomingInvoice = useGetUpcomingInvoice()

  const { execute, status } = useFromTaskEither(flow(
    getChargeAccount,
    TE.mapLeft(GetInvoiceError.RequestError),
    TE.chainW(TE.fromOption(GetInvoiceError.AccountNotFound)),
    TE.chainW(a => pipe(
      a,
      TE.fromPredicate(() => a.model.tag === 'Monthly', GetInvoiceError.WrongChargingModel)
    )),
    TE.chainW(c => pipe(
      getUpcomingInvoice(c.id),
      TE.mapLeft(GetInvoiceError.RequestError),
      TE.chainW(TE.fromOption(GetInvoiceError.InvoiceNotFound))
    )),
  ))

  useEffect(() => { execute() }, [])

  return pipe(
    status,
    renderDatum(
      () => <Loading />,
      (error) => matchExhaustive(error, {
        RequestError: () => <Error />,
        AccountNotFound: () => <AccountNotFound />,
        WrongChargingModel: () => <AccountNotFound />,
        InvoiceNotFound: () => <InvoiceNotFound />
      }),
      (invoice) => <UpcomingInvoiceCard invoice={invoice} />
    )
  )
}

type GetInvoiceError =
  | Variant<"RequestError", RequestError>
  | Variant<"AccountNotFound">
  | Variant<"InvoiceNotFound">
  | Variant<"WrongChargingModel">

const GetInvoiceError = impl<GetInvoiceError>()

const Loading = () => (
  <Card>
    <CardHeader
      title="Upcoming Invoice"
      supportingText="These charges will be collected from your specified payment method at the end of the period."
    />
    <SkeletonPlainDataTable columns={payoutColumns} />
  </Card>
)

const Error = () => <ErrorMessage title="Failed to load upcoming invoice" />

const AccountNotFound = () => (
  <ActionPanel
    title={`This is only visible if you use our new monthly charging model`}
    content={
      <p>
        You can sign up below.
      </p>
    }
    actions={(
      <ButtonLink variant='secondary' to={ROUTES.solicitors.plan.root}>
        Learn More
      </ButtonLink>
    )}
  />
)
const InvoiceNotFound = () => (
  <ActionPanel
    title={`You don't have any upcoming invoices`}
    content={
      <p>
        You may not have any Lawhive charges yet, if this is not what you expect please contact Lawhive support..
      </p>
    }
  />
)

const UpcomingInvoiceCard = ({ invoice }: { invoice: InvoiceDTO }) => (
  <Card>
    <CardHeader
      title="Upcoming Invoice"
      supportingText="These charges will be collected from your specified payment method at the end of the period."
    />
    <InvoiceTable invoice={invoice} />
  </Card>
)

const formatTable = format('dd/MM/yy')

const payoutColumns: TableColumn<InvoiceDTO['lineItems'][number]>[] = [
  {
    id: 'description',
    Header: 'Description',
    accessor: 'description'
  },
  {
    id: 'createdAt',
    Header: 'Charged On',
    accessor: 'createdAt',
    Cell: ({ value }) => formatTable(value)
  },
  {
    id: 'tax',
    Header: 'Tax',
    accessor: 'taxRate',
    Cell: ({ value: taxRate }) => taxRate ? `${taxRate.percentage}%${taxRate.inclusive ? ` (Incl.)`: ``}` : null
  },
  {
    id: 'amount',
    Header: 'Amount',
    accessor: 'amount',
    style: tw`text-right`,
    Cell: ({ value }) => <AccountingCurrencyUnits amountUnits={value} />
  }
]

export const InvoiceTable = ({ invoice }: { invoice: InvoiceDTO }) => {

  return (
    <PlainDataTable
      columns={payoutColumns}
      data={invoice.lineItems}
      footer={(
        <tfoot>
          <tr>
            <TableCell
              scope="row"
              colSpan={3}
              tw="hidden text-right text-sm font-semibold text-gray-900 sm:table-cell"
            >
              Subtotal
            </TableCell>
            <TableCell
              scope="row"
              tw="text-left text-sm font-semibold text-gray-900 sm:hidden"
            >
              Subtotal
            </TableCell>
            <TableCell
              tw="text-right text-sm font-semibold text-gray-900"
            >
              <AccountingCurrencyUnits amountUnits={invoice.subtotal} />
            </TableCell>
          </tr>
          {invoice.taxItems.map(ti => (
             <tr>
              <TableCell
                scope="row"
                colSpan={3}
                tw="hidden text-right text-sm font-semibold text-gray-900 sm:table-cell"
              >
                {ti.displayName} {ti.inclusive && `(Incl.)`}
              </TableCell>
              <TableCell
                scope="row"
                tw="text-left text-sm font-semibold text-gray-900 sm:hidden"
              >
                {ti.displayName} {ti.inclusive && `(Incl.)`}
              </TableCell>
              <TableCell
                tw="text-right text-sm font-semibold text-gray-900"
              >
                <AccountingCurrencyUnits amountUnits={ti.amount} />
              </TableCell>
            </tr>
          ))}
          <tr>
            <TableCell
              scope="row"
              colSpan={3}
              tw="hidden text-right text-sm font-semibold text-gray-900 sm:table-cell"
            >
              Total
            </TableCell>
            <TableCell
              scope="row"
              tw="text-left text-sm font-semibold text-gray-900 sm:hidden"
            >
              Total
            </TableCell>
            <TableCell
              tw="text-right text-sm font-semibold text-gray-900"
            >
              <AccountingCurrencyUnits amountUnits={invoice.total} />
            </TableCell>
          </tr>
        </tfoot>
      )}
    />
  )
}
