import React, { ButtonHTMLAttributes, FC } from "react"
import { Link, LinkProps } from "react-router-dom"
import tw, { styled } from 'twin.macro'
import { Spinner } from "./Loading"

type Variant = 'positive' | 'negative' | 'primary' | 'secondary' | 'colour-secondary'
type Display = 'filled' | 'border'

export type ButtonStyleProps = {
  variant?: Variant
  display?: Display
}

type ButtonFunctionalityProps = {
  icon?: JSX.Element
  isLoading?: boolean
}

export const ButtonRaw = styled.button<ButtonStyleProps>(({ type='button', variant='primary', display='filled' }) => [
  tw`flex items-center justify-center px-4 py-2 gap-2 border border-transparent text-sm leading-6 shadow-sm font-medium rounded-md transition duration-150 ease-in-out`,
  tw`text-white border-transparent disabled:(text-gray-50 cursor-not-allowed)`,
  tw`text-xs sm:text-sm`,
  tw`focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 ring-opacity-5`,
  ...(display === 'filled' ? [
    variant === 'positive' && tw`bg-green-600 hover:bg-green-500 focus:(border-green-700 ring-green-200) disabled:bg-green-300`,
    variant === 'negative' && tw`bg-red-600 hover:bg-red-500 focus:(border-red-700 ring-red-200) disabled:bg-red-300`,
    variant === 'primary' && tw`bg-indigo-600 hover:bg-indigo-700 focus:(border-indigo-700 ring-indigo-200) disabled:bg-indigo-300`,
    variant === 'secondary' && tw`border border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:(border-indigo-700 ring-indigo-200) disabled:bg-gray-300`,
    variant === 'colour-secondary' && tw`text-indigo-700 bg-indigo-50 hover:bg-indigo-100 focus:(border-indigo-700 ring-indigo-200) disabled:bg-gray-300`,
  ] : []),
  ...(display === 'border' ? [
    tw`border-2`,
    variant === 'positive' && tw`text-green-500 border-green-500 hover:bg-green-50 focus:(border-green-700 ring-green-200) disabled:bg-gray-100`,
    variant === 'negative' && tw`text-red-600 border-red-600 hover:bg-red-50 focus:(border-red-700 ring-red-200) disabled:bg-red-300`,
    variant === 'primary' && tw`text-indigo-600 border-indigo-600 hover:bg-indigo-50 focus:(border-indigo-700 ring-indigo-200) disabled:bg-gray-100`,
  ] : []),
])

export const Button: FC<ButtonFunctionalityProps & ButtonHTMLAttributes<HTMLButtonElement> & ButtonStyleProps> =
  ({ isLoading=false, children, variant, display, ...rest }) => (
    <ButtonRaw
      variant={variant}
      disabled={isLoading}
      display={display}
      {...rest}
    >
      {isLoading ? <Spinner /> : children}
    </ButtonRaw>
)

const StyledLinkButton = styled.button<ButtonStyleProps>(({ type='button', variant='neutral', display='filled' }) => [
  tw`transition duration-150 ease-in-out`,
  tw`flex items-center justify-center text-sm font-medium gap-x-2`,
  tw`text-indigo-600`,
  tw`hover:underline`
])
const StyledLinkButtonA = StyledLinkButton.withComponent(Link)


export const LinkButton: FC<ButtonFunctionalityProps & ButtonHTMLAttributes<HTMLButtonElement> & ButtonStyleProps> =
  ({ isLoading=false, children, variant, display, icon, ...rest }) => (
    <StyledLinkButton
      variant={variant}
      disabled={isLoading}
      display={display}
      {...rest}
    >
      {icon && <span tw="h-4 w-4">{icon}</span>}
      {isLoading ? <Spinner /> : children}
    </StyledLinkButton>
)

export const LinkButtonA: FC<ButtonFunctionalityProps & LinkProps & ButtonStyleProps> =
  ({ isLoading=false, children, variant, display, icon, ...rest }) => (
    <StyledLinkButtonA
      variant={variant}
      display={display}
      {...rest}
    >
      {icon && <span tw="h-4 w-4">{icon}</span>}
      {children}
    </StyledLinkButtonA>
)


export const ButtonLink = ButtonRaw.withComponent(Link)
export const ButtonLinkA = ButtonRaw.withComponent('a')

export const ButtonGroup = tw.div`grid gap-x-2 grid-cols-2`
