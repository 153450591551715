import { axios } from "packages/app/src/lib/axios"
import { useQuery } from "react-query"
import { QuoteEntityV2, UnparsedQuoteEntityV2 } from "../types"

interface GetCaseQuoteData {
  caseId: string | undefined
  onSuccess?: (data: QuoteEntityV2[]) => void
}

const parseQuoteData = (data: UnparsedQuoteEntityV2[]): QuoteEntityV2[] => {
  return data.map((quote) => {
    try {
      const expiresAsDate = new Date(quote.expiresAt)
      return {
        ...quote,
        expiresAt: expiresAsDate
      }
    } catch (e) {
      console.log(e)
    }

    return {
      ...quote,
      // new date two weeks from now
      expiresAt: new Date(new Date().getTime() + 12096e5)
    }
  })
}

const getCaseQuotes = ({
  caseId
}: GetCaseQuoteData): Promise<UnparsedQuoteEntityV2[]> => {
  return axios.get(`case-quotes/${caseId}`)
}

const getAndParseCaseQuotes = async ({
  caseId
}: GetCaseQuoteData): Promise<QuoteEntityV2[]> => {
  const quotes = await getCaseQuotes({ caseId })
  return parseQuoteData(quotes)
}

export const useGetCaseQuotes = ({ caseId, onSuccess }: GetCaseQuoteData) => {
  return useQuery({
    queryKey: ["case-quotes", caseId],
    enabled: Boolean(caseId),
    queryFn: () => getAndParseCaseQuotes({ caseId }),
    onSuccess: (data) => {
      onSuccess && onSuccess(data)
    }
  })
}

