import { useAnalytics } from "../../hooks/useAnalytics";
import { ViewPageEvent, ViewPageEventProps } from "./types";

export const usePageViewEvent = () => {
    const { trackEvent } = useAnalytics();

    return (props: ViewPageEventProps) => trackEvent(buildViewPlatformEvent(props));
}

export const buildViewPlatformEvent = (props: ViewPageEventProps): ViewPageEvent => {
    return {
        action: "viewed",
        target: "platform-page",
        properties: props,
        description: "Page viewed",
    }
}
    