import { AnyVariant, CasesExhaustive, CasesReturn, CasesWildcard, constructor, Impl, match, matchWildcard, ValidateProperties } from '@practical-fp/union-types'
import * as D from 'io-ts/lib/Decoder'

type Variants<A> = {
  [K1 in keyof A]: { tag: Extract<K1, string>, value: D.TypeOf<A[K1]> }
}[keyof A]

// type Properties<A> = { [K in keyof A]: D.Decoder<unknown, A[K]> }

// const mapValues = <O>(object: O) =>
//   <A>(mapper: (k: Exclude<keyof O, symbol> | string, v: O[keyof O]) => A) =>
//     Object.entries(object).reduce(
//       (prev, [k, v]) => ({ ...prev, [k]: mapper(k, v) }),
//       {} as { [K in keyof O]: A }
//     )

export const ADT = <A>(properties: { [K in keyof A]: D.Decoder<unknown, A[K]> | undefined }) => {
  const p = properties
  type P = typeof properties

  type Variant = Variants<P>
  // type T3 = Impl<Variant>

  // const decoderStruct = mapValues(properties)((k, v) => D.struct({ tag: D.literal(k), value: v }))

  // const decoderStruct = mapValues({
  //   Foo: D.struct({ bar: D.string })
  // })((k, v) => D.struct({ tag: D.literal(k), value: v }))

  // const p = {
  //   Foo: D.struct({ bar: D.string })
  // }
  // type P = typeof p

  const decoderStruct = Object.entries(p).reduce(
    (prev, [k, v]) => ({ ...prev, [k]: D.struct({ tag: D.literal(k), ...v ? { value: v as D.Decoder<unknown, any> } : {} }) }),
    {} as { [K in keyof P]: D.Decoder<unknown, { tag: K, value: P[K] }> }
  )

  // TODO Types
  const decoder = D.sum('tag')(decoderStruct as any)

  return new Proxy({
    decode: decoder.decode
  } as Impl<Variant> & D.Decoder<unknown, Variant>, {
    get: <Tag extends keyof Impl<Variant>>(_: Impl<Variant>, tagName: Tag | 'decode') => {
      if(tagName === 'decode') {
        return decoder.decode
      }
      return constructor<Variant, Tag>(tagName)
    },
  })
}

export const matchExhaustiveK =
  <Var extends AnyVariant, Ret>(cases: CasesExhaustive<Var, Ret> & ValidateProperties<CasesExhaustive<Var, Ret>, keyof CasesExhaustive<Var, Ret>>) =>
  (variant: Var): CasesReturn<Var, CasesExhaustive<Var, Ret>> =>
  match(variant, cases)

// @ts-ignore
// export const matchWildcardK =
//   <Var extends AnyVariant, Ret>(cases: CasesWildcard<Var, Ret> & ValidateProperties<CasesWildcard<Var, Ret>, keyof CasesWildcard<Var, Ret>>) =>
//   (variant: Var): CasesReturn<Var, CasesWildcard<Var, Ret>> =>
//   matchWildcard(variant, cases)
