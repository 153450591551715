import { Button } from "packages/app/src/elements/Button"
import { ROUTES } from "packages/app/src/layout/Navigation"
import { NonZeroPriceUnits } from "packages/app/src/lib/Primitives"
import { useQuoteCheckoutStartedEvent } from "../../analytics/useCheckoutEvent"
import { usePaymentCTAClickedEvent } from "../../analytics/usePaymentCTAClickedEvent"
import { useCheckoutQuote } from "../api/checkoutQuote"
import "twin.macro"

export const useCheckoutCaseQuote = () => {
  const state = useCheckoutQuote(async (result) => {
    window.location.href = result.url
  })

  const checkout = (caseId: string, quoteId: string) => {
    const successUrl = `${window.location.origin}${ROUTES.case.waitForPayment(
      caseId,
      quoteId
    )}`
    const cancelUrl = `${window.location.origin}${ROUTES.case.details(
      caseId
    )}?cancel=true`

    state.mutate({
      cancelUrl,
      quoteId,
      successUrl
    })
  }

  return { state, checkout }
}

type CheckoutQuoteButtonProps = {
  caseId: string
  quoteId: string
  ammount: NonZeroPriceUnits
}

export const CheckoutQuoteButton = ({
  caseId,
  quoteId,
  ammount
}: CheckoutQuoteButtonProps) => {
  const { state, checkout } = useCheckoutCaseQuote()

  const fireEvent = useQuoteCheckoutEvents(caseId, quoteId, ammount)

  return (
    <div tw="flex flex-col items-end justify-end">
    <div tw="w-auto md:w-2/3 lg:w-1/2">
      <Button
        onClick={() => {
          fireEvent(); 
          checkout(caseId, quoteId);
        }}
        variant="positive"
        isLoading={state.isLoading}
        tw="w-full"
      >
        Pay for Case
      </Button>
      <span tw="text-xs text-gray-600">By completing this payment you agree to formally engage the solicitor in accordance with the <a tw="text-indigo-400" href="https://lawhive.co.uk/legal/client-solicitor-engagement-terms/">Client Solicitor Terms of Engagement</a>.</span>
    </div>
    </div>
  )
}


const useQuoteCheckoutEvents = (caseId: string, quoteId: string, ammount: NonZeroPriceUnits) => {
  const triggerCTAEvent = usePaymentCTAClickedEvent()
  const triggerCheckoutEvent = useQuoteCheckoutStartedEvent()
  const fireEvent = () => {
    triggerCTAEvent({
      caseId,
    })
    triggerCheckoutEvent({
      caseId,
      quoteId,
      ammount,
    })
  }
  return fireEvent
}
