import { FC } from "react"
import { NavLink } from "react-router-dom"
import tw, { styled } from "twin.macro"
import { DropdownNavigation } from "../elements/TabNavigation"
import { ExternalLinkIcon } from "@heroicons/react/solid"

// Ready for design system
export const SidebarMenuLayout: FC<{ menu: JSX.Element, content: JSX.Element }> = ({ menu, content, ...rest }) => (
  <div tw="flex flex-col sm:(flex-row) gap-4 sm:gap-8" {...rest}>
    <div tw="sm:(w-1/6)">
      {menu}
    </div>
    <div tw="w-full sm:(w-5/6)">
      {content}
    </div>
  </div>
)

const SidebarMenuLink = styled(NavLink)<{ active?: boolean }>`
  ${tw`flex text-gray-500 flex-shrink-0 text-center items-center text-sm font-medium px-3 py-2 rounded-md tracking-wide hover:bg-gray-200`}

  &.active {
    ${tw`text-pink-600`}
  }

  &:not(.active) {
    ${tw``}
  }
`

const SidebarMenuExternalLink: FC<{to: string, label: string}> = ({to, label}) => (
  <a href={to}>
  <div tw="flex text-gray-500 flex-shrink-0 text-center items-center text-sm font-medium px-3 py-2 rounded-md tracking-wide hover:bg-gray-200">
    <span tw="mr-2 h-5 w-5 flex-shrink-0"><ExternalLinkIcon/></span>
    {label}
  </div>
  </a>
)
export type SidebarMenuItem = {
  label: string
  icon?: JSX.Element
  to: string
  external?: boolean
  hidden?: boolean
}

export const SidebarMenu = ({ items, ...rest }: { items: SidebarMenuItem[] }) => (
  <div {...rest}>
    <div tw="hidden sm:block">
      <div tw="flex flex-col gap-2">
        {items.map((c, i) => (
          c.hidden === true ? null :
          c.external ?
            <SidebarMenuExternalLink key={`item-${i}`} to={c.to} label={c.label}/>
            : <SidebarMenuLink key={`item-${i}`} to={c.to}>
            {c.icon && <span tw="mr-2 h-5 w-5 flex-shrink-0">{c.icon}</span>}
            {c.label}
          </SidebarMenuLink>
        ))}
      </div>
    </div>

    <div tw="block sm:hidden">
      <DropdownNavigation items={items} />
    </div>
  </div>
)
