import { DatumEither, initial, isSuccess } from "@nll/datum/DatumEither"
import { fromNullable, none, Option } from "fp-ts/es6/Option"
import React, { createContext, FC, useContext, useEffect } from "react"
import { GetSolicitorQuery, GetSolicitorQueryVariables } from "@lawhive/generated-api"

// import { GetOnboardingStatusQuery } from "../../API"
import { useUser } from "../../contexts/User"
import { Solicitor } from "../../lib/types"
// import { getOnboardingStatus } from "../../graphql/queries"
import { useAsync } from "../../lib/useAsync"
import { graphql, noop } from "../../lib/utils"

type SolicitorOnboardingContext = {
  state: DatumEither<Error, Omit<Solicitor, 'stripeAccountId'>>,
  hasAccount: Option<boolean>
  hasChargesEnabled: Option<boolean>
  hasSubmitDetails: Option<boolean>
  hasPayoutsEnabled: Option<boolean>
  accountType: Option<string>
  refresh: () => void
}

const SolicitorOnboardingContext = createContext<SolicitorOnboardingContext>({
  state: initial,
  hasAccount: none,
  hasChargesEnabled: none,
  hasSubmitDetails: none,
  hasPayoutsEnabled: none,
  accountType: none,
  refresh: noop,
})

export const useSolicitorOnboarding = () => useContext(SolicitorOnboardingContext)

export const SolicitorOnboardingProvider: FC = ({ children }) => {
  const { isSolicitor, userId } = useUser()

  const { status, execute: load } = useAsync(
    () => graphql<GetSolicitorQuery, GetSolicitorQueryVariables>({
      query: /* GraphQL */`
        query GetSolicitor($id: ID!) {
          getSolicitor(id: $id) {
            id
            name
            businessName
            sraRegistrationNumber
            onboardingStatus {
              hasAccount
              accountType
              hasChargesEnabled
              hasSubmitDetails
              hasPayoutsEnabled
            }
            createdAt
            updatedAt
          }
        }
    `,
      variables: {
        id: userId
      }
    }).then(r => r.data?.getSolicitor
      ? Promise.resolve(r.data.getSolicitor)
      : Promise.reject('Failed to get items')
    )
  )

  useEffect(
    () => {
      if(isSolicitor) {
        load()
      }
    },
    [isSolicitor]
  )

  const context: SolicitorOnboardingContext = {
    state: status,
    hasAccount: isSuccess(status)
      ? fromNullable(status.value.right.onboardingStatus?.hasAccount)
      : none,
    hasChargesEnabled: isSuccess(status)
      ? fromNullable(status.value.right.onboardingStatus?.hasChargesEnabled)
      : none,
    hasSubmitDetails: isSuccess(status)
      ? fromNullable(status.value.right.onboardingStatus?.hasSubmitDetails)
      : none,
    hasPayoutsEnabled: isSuccess(status)
      ? fromNullable(status.value.right.onboardingStatus?.hasPayoutsEnabled)
      : none,
    accountType: isSuccess(status)
      ? fromNullable(status.value.right.onboardingStatus?.accountType)
      : none,
    refresh: () => load()
  }

  return (
    <SolicitorOnboardingContext.Provider value={context}>
      {children}
    </SolicitorOnboardingContext.Provider>
  )
}
