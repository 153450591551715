import { castTo, fetchParsedJSON } from "../lib/fetch"
import { UUID } from "../lib/Primitives"
import { useApiFetch } from '../lib/useApiClient'

export type RequestMonthlyBillingEnrollmentCommand = {
  chargeAccountId: UUID,
  successUrl: string,
  cancelUrl: string,
}

export type RequestMonthlyChargingModelEnrollmentResponse = {
  url: string
}

export const useRequestMonthlyChargingModelEnrollment = () => {
  const api = useApiFetch(fetchParsedJSON(castTo<RequestMonthlyChargingModelEnrollmentResponse>()))

  return (command: RequestMonthlyBillingEnrollmentCommand) =>
    api(`charge-accounts/${command.chargeAccountId}/request-subscribe`, {
      method: "POST",
      body: JSON.stringify(command)
    })
}
