import { impl, Variant } from "@practical-fp/union-types"
import { Either, right, toError } from "fp-ts/lib/Either"
import * as T from "fp-ts/lib/Task"
import { flow, identity, pipe } from "fp-ts/lib/function"
import { bimap, chain, chainW, fromEitherK, fromPredicate, mapLeft, matchW, orLeft, tryCatch, tryCatchK } from "fp-ts/lib/TaskEither"

export type RequestError =
  | Variant<"ResponseError", ResponseError>
  | Variant<"ParseError", Error>
  | Variant<"NetworkError", Error>

export const RequestError = impl<RequestError>()

type ResponseError = {
  statusCode: number
  details: string
  body?: any
}

const fetchAndLog = (input: RequestInfo, init?: RequestInit) => {
  console.log(`Requesting URL: [${input}]`)
  return fetch(input, init)
}

export const toNetworkError = flow(toError, RequestError.NetworkError)
export const toParseError = flow(toError, RequestError.ParseError)

// const spreadOver = <U extends readonly any[], A>(fn: (...p: U) => A) => (params: U) => fn(...params)

export const fetchRaw = tryCatchK(fetchAndLog, toNetworkError)

export const fetchWithOK = flow(
  fetchRaw,
  chainW(r => pipe(
    r,
    fromPredicate(r => r.ok, (): RequestError => RequestError.ResponseError({ statusCode: r.status, details: `Not OK` })),
    orLeft((e1) => pipe(
      tryCatch(() => r.json(), toParseError),
      matchW(() => e1, (b): RequestError => RequestError.ResponseError({ statusCode: r.status, details: `Not OK`, body: b })),
    ))
  ))
)

export type Parser<E, A> = (i: unknown) => Either<E, A>

export const fetchParsedJSON = <A>(parser: Parser<Error, A>) => flow(
  fetchWithOK,
  chainW(r => tryCatch(() => r.json(), toParseError)),
  chainW(flow(fromEitherK(parser), mapLeft(toParseError)))
)

export const fetchAndCast = <A>() => fetchParsedJSON(castTo<A>())

export const castTo = <T>() => (i: unknown) => right(i as T)

export const withLogs = <T, E>() => bimap<T, T, E, E>(
  e => {
    console.log('[LEFT]', e)
    return e
  },
  a => {
    console.log('[RIGHT]', a)
    return a
  }
)

// export const withOptions = <T>()
