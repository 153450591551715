import { either, Either, fromPredicate, map } from "fp-ts/lib/Either"
import { calculateFeePercentage, calculatePlatformFee } from "./Quote"

import { DraftQuote } from "./Quote"
import { PriceUnits } from "./PriceUnits"
import { sequence } from "fp-ts/lib/Array"
import { pipe } from "fp-ts/lib/function"

type DraftLineItemRequest = {
  clientPays: PriceUnits
  solicitorReceives: PriceUnits

  description: string

  scope?: {
    includes: string[]
    excludes: string[]
  }
}

type DraftQuoteRequest = {
  isLeadFromAds: boolean
  items: DraftLineItemRequest[]
}

type EnhancedLineItem = DraftLineItemRequest & {
  platformFee: PriceUnits
  feePercentage: number
  subsidy: number
}

const isValidLineItem = (lineItem: DraftLineItemRequest) =>
  Number.isInteger(lineItem.clientPays) && Number.isInteger(lineItem.solicitorReceives)
  && lineItem.clientPays >= lineItem.solicitorReceives
  && lineItem.clientPays > 0

const validateLineItem = fromPredicate(isValidLineItem, () => new Error(`Invalid line item`))

// TODO Unbake this 0 subsidy when remodeling
const enhanceLineItem = (item: DraftLineItemRequest): EnhancedLineItem => ({
  ...item,
  feePercentage: calculateFeePercentage(item.clientPays, item.solicitorReceives),
  platformFee: calculatePlatformFee(item.clientPays, item.solicitorReceives),
  subsidy: 0
})

/**
 * Invariant: Solicitor Receives cannot be above Client Pays
 */
export const constructDraftQuote = (input: DraftQuoteRequest): Either<Error, DraftQuote> => {
  const lineItems = sequence(either)(input.items.map(validateLineItem))

  return pipe(
    lineItems,
    map(is => is.map(enhanceLineItem)),
    map((items): DraftQuote => ({
      ...input,
      items
    }))
  )
}
