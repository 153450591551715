// Taken from lawhive.co.uk

import { RadioGroup as HeadlessRadioGroup } from "@headlessui/react"
import React, { Fragment, PropsWithChildren, ReactNode } from "react"
import tw from 'twin.macro'

type RadioGroupProps<T> = {
  items: RadioItem<T>[]
  groupName: string
  compact?: boolean
  value?: T
  setValue: (i?: T) => void
}

export type RadioItem<T> = {
  value: T
  label: ReactNode
  icon?: ReactNode
  description?: ReactNode
  disabled?: boolean
}

export const RadioGroup = <T extends string>({ items, groupName, value, compact, setValue, ...rest }: PropsWithChildren<RadioGroupProps<T>>) => {
  return (
    <HeadlessRadioGroup value={value} onChange={setValue} {...rest}>
      <div css={[
        tw`space-y-4`,
        compact && tw`flex flex-row space-y-0`,
      ]}>
        {items.map((i, index) => (
          <HeadlessRadioGroup.Option
            key={i.value}
            value={i.value}
            as={Fragment as any}
            disabled={i.disabled}
          >
            {({ active, checked }) => (
              <div css={[
                tw`border relative shadow-sm px-5 py-4 cursor-pointer flex focus:outline-none`,
                !compact && tw`rounded-lg`,
                compact && index === 0 && tw`rounded-l-md`,
                compact && index === items.length -1 && tw`rounded-r-md`,
                active && tw`ring-2 ring-offset-2 ring-offset-pink-300 ring-white ring-opacity-60`,
                checked
                  ? tw`bg-pink-50 text-pink-800 border-pink-300`
                  : tw`bg-white text-gray-700 border-gray-200`
              ]}>
                <div css={[
                  tw`flex items-center justify-between w-full gap-4`,
                  compact && tw`pr-4`,
                ]}>
                  <div css={[
                    tw`border border-gray-300 bg-white rounded-full w-4 h-4 flex-shrink-0 flex items-center justify-center`,
                    checked && tw`border-pink-600`
                  ]}>
                    {checked && (
                      <div tw="border-pink-600 rounded-full bg-pink-500 w-2 h-2">
                      </div>
                    )}
                  </div>
                  {i.icon && (
                    <div tw="hidden sm:block flex-shrink-0 h-6 w-6">
                      {i.icon}
                    </div>
                  )}
                  <div tw="flex items-center w-full">
                    <div tw="text-sm">
                      <HeadlessRadioGroup.Label
                        as="p"
                        css={[
                          tw`font-medium`
                        ]}
                      >
                        {i.label}
                      </HeadlessRadioGroup.Label>
                      {i.description && (
                        <HeadlessRadioGroup.Description
                          as="span"
                          css={[
                            tw`text-xs sm:text-sm inline`,
                            checked ? tw`text-pink-600` : tw`text-gray-500`
                          ]}
                          >
                          {i.description}
                        </HeadlessRadioGroup.Description>
                      )}
                    </div>
                  </div>
                  {/* {checked && !compact && (
                    <div tw="flex-shrink-0">
                      <CheckCircleIcon tw="w-5 h-5 text-white" />
                    </div>
                  )} */}
                </div>
              </div>
            )}
          </HeadlessRadioGroup.Option>
        ))}
      </div>
    </HeadlessRadioGroup>
  )
}

