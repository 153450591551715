import { castTo, fetchParsedJSON } from "../lib/fetch"
import { UUID } from "../lib/Primitives"
import { useApiFetch } from '../lib/useApiClient'
import { ChargeAccountEntity } from "./useCreateChargeAccount"
import { ChargingModel } from "./useGetChargeAccount"

export type SelectChargingModelCommand = {
  chargeAccountId: UUID,
  model: ChargingModel,
}

export const useSelectChargingModel = () => {
  const api = useApiFetch(fetchParsedJSON(castTo<ChargeAccountEntity>()))

  return (command: SelectChargingModelCommand) =>
    api(`charge-accounts/${command.chargeAccountId}/select-model`, {
      method: "POST",
      body: JSON.stringify(command)
    })
}
