import { isSome } from "fp-ts/es6/Option"
import { FC } from "react"
import "twin.macro"

import { KYCSubmissionStatus } from "@lawhive/generated-api"
import { map } from "@nll/datum/DatumEither"
import { pipe } from "fp-ts/es6/function"
import { ClientPaymentsView } from "packages/app/src/features/quotes/routes/ClientPaymentsView"
import { Link } from "react-router-dom"
import { useCase } from "../../../../../contexts/case/CaseContext"
import { useUser } from "../../../../../contexts/User"
import { Card, CardContent } from "../../../../../elements"
import {
  FilesIcon,
  MessageIcon,
  PaymentsIcon
} from "../../../../../elements/Icons"
import { ROUTES } from "../../../../../layout/Navigation"
import { renderNullOrSuccess } from "../../../../../lib/utils"
import {
  CaseStatusTitle,
  CaseStatusTitleHeader,
  ComponentWithCase
} from "../../CaseDetails"
import { CaseFilesCard } from "../../shared/CaseFilesCard"
import { CaseTabLayout } from "../../shared/CaseTabLayout"
import { MessagesCardWithData } from "../../shared/MessagesCardWithData"

export const ActiveStatusPanel: ComponentWithCase = ({ case: cas }) => {
  return (
    <div tw="flex flex-row items-center">
      <div>
        {isSome(cas.solicitor) && (
          <>
            <CaseStatusTitleHeader>Your Solicitor</CaseStatusTitleHeader>
            <CaseStatusTitle>{cas.solicitor.value.name}</CaseStatusTitle>
          </>
        )}
      </div>
    </div>
  )
}

export const ActiveActionPanel: ComponentWithCase = ({ case: cas }) => {
  const { kycSubmission, isAdmin } = useUser()

  if (isAdmin) {
    return null
  }

  return !(isSome(kycSubmission) && isSome(kycSubmission.value.status)) ? (
    <NeedToSubmitKyc caseId={cas.id} />
  ) : kycSubmission.value.status.value === KYCSubmissionStatus.approved ? (
    <KycApproved />
  ) : kycSubmission.value.status.value === KYCSubmissionStatus.rejected ? (
    <KycRejected caseId={cas.id} />
  ) : (
    <KycPending />
  )
}

const NeedToSubmitKyc: FC<{ caseId: string }> = ({ caseId }) => (
  <Card tw="bg-indigo-600">
    <CardContent>
      <div tw="flex flex-row items-start justify-between flex-wrap">
        <div tw="flex-1 flex items-center">
          <p tw="text-sm mr-4 sm:text-base font-medium text-white ">
            We need to verify your identity before your solicitor can deliver
            case work
          </p>
        </div>
        <div tw="self-end order-3 flex-shrink-0 sm:order-2 sm:mt-4">
          <div tw="rounded-md shadow-sm">
            <Link
              to={ROUTES.submitKyc.root(ROUTES.case.details(caseId))}
              tw="flex items-center justify-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-indigo-600 bg-white hover:text-indigo-500 focus:outline-none focus:ring transition ease-in-out duration-150"
            >
              Begin
            </Link>
          </div>
        </div>
      </div>
    </CardContent>
  </Card>
)

const KycPending = () => (
  <div tw="bg-indigo-100 text-indigo-600 rounded-md">
    <CardContent>
      <div tw="flex flex-col items-start justify-between flex-wrap">
        <div tw="flex-1 flex items-center">
          <p tw="font-medium">We're verifying your identity...</p>
        </div>
      </div>
    </CardContent>
  </div>
)

const KycApproved: FC = () => null
// (
// <div tw="bg-green-100 text-green-600 rounded-md">
//   <CardContent>
//     <div tw="flex flex-col items-start justify-between flex-wrap">
//       <div tw="flex-1 flex items-center">
//         <p tw="font-medium">
//           Your identity has been verified
//         </p>
//       </div>
//     </div>
//   </CardContent>
// </div>
// )

const KycRejected: FC<{ caseId: string }> = ({ caseId }) => (
  <Card tw="bg-indigo-600">
    <CardContent>
      <div tw="flex flex-col items-start justify-between flex-wrap">
        <div tw="flex-1 flex items-center">
          <p tw="font-medium text-white">Your identity could not be verified</p>
        </div>
        <div tw="self-end order-3 flex-shrink-0 sm:order-2 sm:mt-4">
          <div tw="rounded-md shadow-sm">
            <Link
              to={ROUTES.submitKyc.root(ROUTES.case.details(caseId))}
              tw="flex items-center justify-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-indigo-600 bg-white hover:text-indigo-500 focus:outline-none focus:ring transition ease-in-out duration-150"
            >
              Fix now
            </Link>
          </div>
        </div>
      </div>
    </CardContent>
  </Card>
)

const ClientMessages = () => (
  <>
    <InitialMessageSuggestion />
    <MessagesCardWithData canSend={true} />
  </>
)

const InitialMessageSuggestion = () => {
  const { messages } = useCase()

  const messageCount = pipe(
    messages,
    map((m) => m.length)
  )

  return renderNullOrSuccess(messageCount, (c) =>
    c === 0 ? (
      <div tw="mb-4">
        <Card tw="bg-indigo-600">
          <CardContent>
            <div tw="flex flex-col items-start justify-between flex-wrap text-sm sm:text-base">
              <div tw="flex-1 flex flex-col items-start text-white">
                <p tw="pb-1">
                  This case is now active and your solicitor has received
                  details about your case. You will be notified via email when
                  your solicitor responds shortly.
                </p>
                <p tw="py-1">
                  In the meantime, it is recommended you describe any additional
                  information about your issue, including uploading any relevant
                  documents.
                </p>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    ) : null
  )
}

export const ActiveDetailsPanel: ComponentWithCase = ({ case: cas }) => (
  <CaseTabLayout
    defaultRedirect={ROUTES.case.messages(cas.id)}
    config={[
      {
        label: "Messages",
        icon: <MessageIcon />,
        to: ROUTES.case.messages(cas.id),
        path: ROUTES.case.messages(":caseId"),
        render: () => <ClientMessages />
      },
      {
        label: "Files",
        icon: <FilesIcon />,
        to: ROUTES.case.files(cas.id),
        path: ROUTES.case.files(":caseId"),
        render: () => <CaseFilesCard canUpload={true} />
      },
      {
        label: "Payments",
        icon: <PaymentsIcon />,
        to: ROUTES.case.fees(cas.id),
        path: ROUTES.case.fees(":caseId"),
        render: () => <ClientPaymentsView />
      }
    ]}
  />
)
