import { FC } from "react"
import { ErrorMessage } from "../../elements/Message"
import { ScenePanel, ScrollingChildrenContainer } from "../Layout"

export const ErrorSubshell: FC<{ title: string }> = ({ title, children }) => (
  <ScrollingChildrenContainer>
    <ScenePanel>
      <div tw="space-y-4 sm:space-y-6">
        <ErrorMessage title={title} />

        {children}
      </div>
    </ScenePanel>
  </ScrollingChildrenContainer>
)
