import React, { FC, ReactNode } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import 'twin.macro'

import { TabNavigation } from "../../../../elements/TabNavigation"

type TabConfigItem = {
  to: string,
  path: string,
  label: string,
  icon?: ReactNode
  render: () => ReactNode
}

export const CaseTabLayout: FC<{ config: TabConfigItem[], defaultRedirect: string }> = ({ config, defaultRedirect }) => (
  <>
    <TabNavigation tw="mb-4" items={config} />

    <Switch>
      {config.map((c, i) => (
        <Route
          key={`route-${i}`}
          path={c.path}
          render={c.render}
        />
      ))}
      <Redirect to={defaultRedirect} />
    </Switch>
  </>
)
