import 'twin.macro'
import { Card, CardContent } from "./Card"

type ActionPanelProps = {
  title: string
  content: JSX.Element
  actions?: JSX.Element
}

export const ActionPanel = ({ title, content, actions }: ActionPanelProps) => (
  <Card>
    <CardContent tw="space-y-5">
      <div tw="space-y-2">
        <h3 tw="text-lg leading-6 font-medium text-gray-900">{title}</h3>
        <div tw="space-y-2 text-sm text-gray-500">
          {content}
        </div>
      </div>
      <div>
        <div tw="flex flex-col gap-2 sm:flex-row sm:gap-4 items-start">
          {actions}
        </div>
      </div>
    </CardContent>
  </Card>
)
