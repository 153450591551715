import React, { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import 'twin.macro'
import { useAuthentication } from '../../contexts/Authentication'
import { ErrorMessageOld, ErrorMessageText } from "../../elements"
import { Spinner } from "../../elements/Loading"
import { ContentContainer, PageContainer } from '../../layout/Layout'
import { ROUTES } from "../../layout/Navigation"

export const MagicLinkCallbackScene = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<boolean>()
  const { loginWithCallbackCredential } = useAuthentication()

  const returnUrl = useMemo(
    () => new URLSearchParams(window.location.search).get('returnUrl') ?? undefined,
    [window.location.search]
  )

  const signIn = async () => {
    setLoading(true)
    setError(false)

    return loginWithCallbackCredential(returnUrl)
      .then(r => {
        setLoading(false)
        return r
      })
      .catch(e => {
        setLoading(false)
        setError(true)
        console.log(e)
      })
  }

  useEffect(() => {
    signIn()
  }, [])

  return (
    <PageContainer>
      <ContentContainer>
      {/* <div tw="w-screen flex items-center flex-1 sm:h-full justify-center px-4 sm:px-0"> */}
        <div tw="flex flex-col sm:flex-row rounded-lg shadow-lg w-full sm:w-3/4 lg:w-3/4 xl:w-3/4 mx-auto bg-white">
          <div tw="flex w-full md:w-3/4 lg:w-1/2 px-4 py-10 md:py-20 lg:py-32">
            <div tw="flex flex-col flex-1 justify-center">
              {error
                ? (
                  <ErrorMessageOld title="There was a problem signing you in">
                    <ErrorMessageText>
                      Please <Link tw="underline font-medium" to={ROUTES.auth.login(returnUrl)}>click here</Link> to try again.
                    </ErrorMessageText>
                    <ErrorMessageText>
                      If this keeps happening, please
                      email <a href="mailto:support@lawhive.co.uk">support@lawhive.co.uk</a>
                    </ErrorMessageText>
                  </ErrorMessageOld>
                )
                : (
                  <>
                    <h1 tw="mb-4 text-4xl text-center font-extralight">
                      Securely signing you in
                    </h1>
                    <div tw="flex items-center flex-col mt-4">
                      <Spinner tw="h-12 w-12 text-gray-600" />
                    </div>
                  </>
                )
              }
            </div>

          </div>
          <div tw="hidden md:block md:w-1/3 lg:w-1/2 rounded-r-lg bg-center bg-cover" style={{ background: "url('https://images.unsplash.com/photo-1524758631624-e2822e304c36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80')", backgroundSize: 'cover', backgroundPosition: "center center" }}></div>
        </div>
      {/* </div> */}
      </ContentContainer>
    </PageContainer>
  )
}
