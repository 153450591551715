import { refreshFold } from "@nll/datum/DatumEither"
import { pipe } from "fp-ts/lib/function"
import { useEffect } from "react"
import { Redirect } from "react-router-dom"
import "twin.macro"
import { ErrorMessageOld } from "../../../elements"
import { Spinner } from "../../../elements/Loading"
import { ContentContainer, PageContainer } from "../../../layout/Layout"
import { ROUTES } from "../../../layout/Navigation"
import { useRouter } from "../../../lib/useRouter"
import { useCaseCommittedEvent } from "../../analytics/useCaseCommittedEvent"
import { useQuoteCheckoutCompletedEvent } from "../../analytics/useCheckoutEvent"
import { useWaitForQuotePaid } from "../hooks/useWaitForQuotePaid"

export const WaitForPaymentScene = () => {
  const {
    params: { quoteId, caseId }
  } = useRouter<{ caseId: string; quoteId: string }, { quoteId: string }>()

  const { status, poll } = useWaitForQuotePaid({})

  useEffect(() => {
    poll(quoteId)
  }, [quoteId])

  const triggerPaymentSucessEvents = usePaymentSucessEvents()
  
  return (
    <PageContainer tw="max-w-screen-md">
      <ContentContainer>
        <div tw="flex items-center justify-center h-96 my-24 max-w-screen-xl mx-auto">
          {pipe(
            status,
            refreshFold(
              () => <Spinner />,
              () => <Spinner />,
              (e) => (
                <ErrorMessageOld title="We ran into a problem">
                  <p tw="mt-3 font-medium text-red-500">
                    Please try refreshing, and if this persists please email{" "}
                    <a tw="underline" href="mailto:support@lawhive.co.uk">
                      support@lawhive.co.uk
                    </a>{" "}
                    quoting your case ID:
                  </p>
                  <p tw="mt-3 font-medium text-red-500">
                    <strong>{caseId}</strong>
                  </p>
                </ErrorMessageOld>
              ),
              (isPaid) =>{
                isPaid && triggerPaymentSucessEvents(caseId, quoteId)
                return isPaid ? (
                  <Redirect to={ROUTES.case.details(caseId, { paid: true })} />
                ) : (
                  <ErrorMessageOld title="We ran into a problem">
                    <p tw="mt-3 font-medium text-red-500">
                      Please try refreshing, and if this persists please email{" "}
                      <a tw="underline" href="mailto:support@lawhive.co.uk">
                        support@lawhive.co.uk
                      </a>{" "}
                      quoting your case ID:
                    </p>
                    <p tw="mt-3 font-medium text-red-500">
                      <strong>{caseId}</strong>
                    </p>
                  </ErrorMessageOld>
                )}
            )
          )}
        </div>
      </ContentContainer>
    </PageContainer>
  )
}

const usePaymentSucessEvents = () => {
  const triggerCommitEvent = useCaseCommittedEvent()
  const triggerCheckoutCompletedEvent = useQuoteCheckoutCompletedEvent()

  const triggerEvents = (caseId: string, quoteId: string) => {
    triggerCommitEvent({ caseId, caseCommitment: "paid" })
    triggerCheckoutCompletedEvent({ caseId, quoteId })
  }

  return triggerEvents
}

