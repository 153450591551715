import {
  CaseDetailsType,
  SetCaseDescriptionCommandMutation,
  SetCaseDescriptionCommandMutationVariables
} from "@lawhive/generated-api"
import { isPending, isSuccess } from "@nll/datum/DatumEither"
import React, { FC } from "react"
import { useForm } from "react-hook-form"
import "twin.macro"

import { useCase } from "../../../../contexts/case/CaseContext"
import { CaseDetails } from "../../../../contexts/case/useGetCase"
import { CaseTitleBreadcrumb } from "../../../../elements/case/CaseTitleBreadcrumb"
import { Button } from "../../../../elements/Button"
import { CheckItem, EnumDropdown } from "../../../../elements"
import { ScenePanel, ScrollingChildrenContainer } from "../../../../layout/Layout"
import { ROUTES } from "../../../../layout/Navigation"
import { useAsync } from "../../../../lib/useAsync"
import { useRouter } from "../../../../lib/useRouter"
import { graphql } from "../../../../lib/utils"
import TextareaAutosize from "react-autosize-textarea/lib"
import { isSome } from "fp-ts/es6/Option"
import { WarnNote } from "../../../../elements"

export const AdminCaseAddDetailsScene = () => {
  const { caseDetails } = useCase()
  return isSuccess(caseDetails) ? (
    <>
      <CaseTitleBreadcrumb caseNumber={caseDetails.value.right.friendlyId} action={'Add details'} />
      <ScrollingChildrenContainer>
        <ScenePanel>
          <AddDetailsForm cas={caseDetails.value.right} />
        </ScenePanel>
      </ScrollingChildrenContainer>
    </>
  ) : null
}

type DetailsInput = {
  type: CaseDetailsType
  details: string
  summary?: string | null
  ask?: string | null
  isTimeSensitive?: boolean | null
}

const AddDetailsForm: FC<{ cas: CaseDetails }> = ({ cas }) => {
  const { refresh } = useCase()
  const { replace } = useRouter()

  const { register, handleSubmit, watch } = useForm<DetailsInput>({
    defaultValues: isSome(cas.details) ? {
      type: cas.details.value.type || CaseDetailsType.structured,
      details: cas.details.value.details,
      summary: cas.details.value.summary,
      ask: cas.details.value.ask,
      isTimeSensitive: cas.details.value.isTimeSensitive || false
    } : {
      type: CaseDetailsType.structured,
      details: '',
      summary: '',
      ask: '',
      isTimeSensitive: false
    }
  })

  const { type, isTimeSensitive } = watch()

  const { status, execute } = useAsync(
    (input: DetailsInput) => graphql<SetCaseDescriptionCommandMutation, SetCaseDescriptionCommandMutationVariables>({
      query: /* GraphQL */ `
        mutation SetCaseDescriptionCommand($input: SetCaseDescriptionCommand!) {
          setCaseDescriptionCommand(input: $input) {
            id
          }
        }
      `,
      variables: {
        input: {
          caseId: cas.id,
          details: {
            details: input.details,
            type: input.type,
            ask: input.ask,
            summary: input.summary,
            isTimeSensitive: input.isTimeSensitive
          }
        }
      }
    })
    .then(() => refresh())
    .then(() => replace(ROUTES.case.details(cas.id)))
  )


  return (
    <form onSubmit={handleSubmit(execute)} tw="flex flex-col space-y-2">
      Type
      <EnumDropdown
        name={'type'}
        ref={register}
        options={CaseDetailsType}
      />

      {type === CaseDetailsType.structured && (
        <>
          Summary
          <TextareaAutosize
            name={'summary'}
            ref={register}
            rows={3}
          />
          </>
      )}

      Details
      <TextareaAutosize
        name={'details'}
        ref={register}
        rows={5}
      />

      {type === CaseDetailsType.structured && (
        <>
          Ask
          <TextareaAutosize
            name={'ask'}
            ref={register}
            rows={3}
          />
        </>
      )}

      <CheckItem name='isTimeSensitive' ref={register}>Case time-sensitive?</CheckItem>

      {isTimeSensitive && <WarnNote>Please ensure the nature of the time-sensitivity is included in the description</WarnNote>}

      <Button isLoading={isPending(status)}>
        Submit
      </Button>
    </form>
  )
}
