import { FC, ReactNode } from 'react'
import 'twin.macro'

type StatisticProps = {
  label: ReactNode
  value: ReactNode
  helperText?: ReactNode
}

export const Statistic: FC<StatisticProps> = ({ value, label, helperText }) => {
  return (
    <div tw="flex flex-col gap-y-1 sm:gap-y-2">
      <span tw="text-xs sm:text-sm font-medium text-gray-500">
        {label}
      </span>

      <div tw="sm:gap-y-1">
        <p tw="font-semibold text-lg sm:text-2xl text-gray-900">
          {value}
        </p>
        {helperText && (
          <p tw="text-xs sm:text-sm font-medium text-gray-400">
            {helperText}
          </p>
        )}
      </div>
    </div>
  )
}
