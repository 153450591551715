import 'twin.macro'

import { format } from 'date-fns/fp'
import { AccountingCurrencyUnits } from '../../elements/Currency'
import { ControlledDataTable } from '../../elements/table/ControlledDataTable'
import { TableColumn } from '../../elements/table/PlainDataTable'
import { PaymentListEntry, useControlledListPayments } from '../../hooks/useListPayments'
import { ROUTES } from '../../layout/Navigation'
import { PaymentStatusBadge } from './PaymentStatusBadge'

const formatTable = format('dd/MM/yy')

const paymentColumns: TableColumn<PaymentListEntry>[] = [
  {
    id: 'paidAt',
    Header: 'Paid At',
    accessor: 'paidAt',
    Cell: ({ value }) => formatTable(value)
  },
  {
    id: 'case',
    Header: 'Case',
    accessor: 'caseNumber'
  },
  {
    id: 'client',
    Header: 'Client',
    accessor: 'clientName'
  },
  {
    id: 'amount',
    Header: 'Amount',
    accessor: 'amount',
    Cell: ({ value }) => <AccountingCurrencyUnits amountUnits={value} />
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: 'status',
    Cell: ({ row }) => <PaymentStatusBadge payment={row.original} />
  },
]

export const PaymentTable = () => {
  const controller = useControlledListPayments()

  return (
    <ControlledDataTable
      columns={paymentColumns}
      controller={controller}
      selectable={{
        key: 'caseId',
        urlGenerator: (id) => ROUTES.case.details(id)
      }}
      error={() => `Error getting payment information`}
    />
  )
}
