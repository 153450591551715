// /cases/:caseId/accept

import { CaseDetails } from 'packages/app/src/contexts/case/useGetCase'
import { axios } from 'packages/app/src/lib/axios'
import { useMutation } from 'react-query'

interface AcceptCaseData {
  caseId: string | undefined
}

export const acceptCase = ({ caseId }: AcceptCaseData): Promise<CaseDetails> => {
  return axios.post(`cases/${caseId}/accept`)
}

export const useAcceptCase = () => {
  return useMutation({
    mutationFn: acceptCase,
  })
}
