import { CaseStatus, ListCasesByStatusQuery } from "@lawhive/generated-api"
import React, { FC, useEffect, useMemo } from "react"
import { Link } from "react-router-dom"

import { NeutralPill, PositivePill, YellowPill } from "../../../../elements/Badge"
import { SidebarLayout, SidebarMenu, SidebarMenuItem } from "../../../../elements/Sidebar"
import { ContentContainer, PageContainer } from "../../../../layout/Layout"
import { ROUTES } from "../../../../layout/Navigation"
import { useAsync } from "../../../../lib/useAsync"
import { useQueryStringState } from "../../../../lib/useQueryStringState"
import { graphql, isNotNullOrUndefined, renderLoadingRefreshOrSuccess } from "../../../../lib/utils"
import { BellIcon, ClockIcon, LiveIcon, TickIcon } from "../../../../elements/Icons"
import { CaseListRenderer } from "../CaseListRenderer"
import { SkeletonCaseList } from "../SkeletonCaseList"
import { AssignedToYouCaseList } from "./AssignedToYouCaseList"
import { WaitingForClientPaymentCaseList } from "./WaitingForClientPaymentCaseList"

export const SolicitorCasesScene = () => {
  const [status] = useQueryStringState<CaseStatus>('status', CaseStatus.active)

  return (
    <PageContainer>
      <ContentContainer>
        <SidebarLayout
          menu={<StatusSelectMenu status={status || CaseStatus.active} queryKey={'status'} />}
          content={<SolicitorCaseSection status={status || CaseStatus.active} />}
        />
      </ContentContainer>
    </PageContainer>
  )
}


const StatusSelectMenu: FC<{ status: CaseStatus, queryKey: string }> = ({ status, queryKey }) => {

  const items = useMemo((): SidebarMenuItem[] => ([
    {
      label: 'Cases',
      items: [
        {
          label: 'Active',
          to: `?${queryKey}=${CaseStatus.active}`,
          active: status === CaseStatus.active,
          icon: <LiveIcon />
        },
        {
          label: 'Completed',
          to: `?${queryKey}=${CaseStatus.completed}`,
          active: status === CaseStatus.completed,
          icon: <TickIcon />
        }
      ]
    },
    {
      label: 'Client Instructions',
      items: [
        {
          label: 'New work',
          to: `?${queryKey}=${CaseStatus.needsSolicitorApproval}`,
          active: status === CaseStatus.needsSolicitorApproval,
          icon: <BellIcon />
        },
        {
          label: 'Waiting for client payment',
          to: `?${queryKey}=${CaseStatus.needsClientPayment}`,
          active: status === CaseStatus.needsClientPayment,
          icon: <ClockIcon />
        }
      ]
    }
  ]), [status])

  return (
    <SidebarMenu
      tw="mb-4"
      items={items}
    />
  )
}


type SolicitorCaseListItem = {
  id: string
  clientName: string
  number: string
  status: SolicitorCaseListItemStatus
  createdAt: Date
}

type SolicitorCaseListItemStatus = 'pending' | 'quote' | 'active' | 'complete'

const caseStatusMap = new Map<CaseStatus, SolicitorCaseListItemStatus>([
  [CaseStatus.needsDetails, "pending"],
  [CaseStatus.needsPrice, "pending"],
  [CaseStatus.needsSolicitorAssignment, "pending"],
  [CaseStatus.needsSolicitorApproval, "pending"],
  [CaseStatus.needsClientPayment, "quote"],
  [CaseStatus.active, "active"],
  [CaseStatus.completed, "complete"],
  [CaseStatus.reviewed, "complete"],
  [CaseStatus.cancelled, "complete"],
  [CaseStatus.pendingExport, "pending"],
  [CaseStatus.exported, "complete"]
])

const SolicitorCaseSection: FC<{ status: CaseStatus }> = ({ status }) => {
  switch(status) {
    case CaseStatus.needsSolicitorApproval:
      return <AssignedToYouCaseList />
    case CaseStatus.needsClientPayment:
      return <WaitingForClientPaymentCaseList />
    case CaseStatus.active:
    case CaseStatus.completed:
      return <ActiveCaseSection status={status} />
    default:
      return null
  }
}


const ActiveCaseSection: FC<{ status: CaseStatus }> = ({ status }) => {
  const { status: cases, execute } = useAsync(
    (status: CaseStatus) => graphql<ListCasesByStatusQuery>({
      query: /* GraphQL */ `query ListCases {
        listCasesByStatus(status: ${status}, limit: 1000) {
          items {
            id
            caseNumber
            brand
            payFirst

            client {
              displayName
            }

            quotes {
              items {
                lineItems {
                  items {
                    solicitorReceives
                  }
                }
              }
            }

            status

            payments {
              items {
                amount
              }
            }
            createdAt
          }
        }
      }`
    })
    .then(r => r.data?.listCasesByStatus)
  )

  const render = renderLoadingRefreshOrSuccess(
    () => <SkeletonCaseList columns={['Case ID', 'Client', 'Status', '']} />,
    (r: ListCasesByStatusQuery['listCasesByStatus']) => {

      const data: SolicitorCaseListItem[] = (r?.items || [])
        .filter(isNotNullOrUndefined)
        .map((c): SolicitorCaseListItem => ({
          id: c.id,
          number: c.caseNumber,
          status: caseStatusMap.get(c.status) || 'active',
          clientName: c.client?.displayName || 'Lawhive Client',
          createdAt: new Date(c.createdAt)
        })
      )
      // sort in date order desc
      .sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)

      return (
        <CaseListRenderer
          data={data}
          selectable={{ key: 'id', urlGenerator: s => ROUTES.case.details(s) }}
          options={[
            {
              label: 'Case ID',
              accessor: 'number'
            },
            {
              label: 'Client',
              accessor: 'clientName',
              render: value => <span tw="leading-5 font-medium text-gray-900">{value}</span>
            },
            {
              label: 'Status',
              accessor: 'status',
              render: value => value === 'active'
                ? <PositivePill>Case work underway</PositivePill>
                : value === 'quote'
                  ? <YellowPill>Quote ready</YellowPill>
                  : value === 'complete'
                    ? <PositivePill>Completed</PositivePill>
                    : <NeutralPill>Building case</NeutralPill>
            },
            {
              label: '',
              id: 'details',
              render: () => (
                <p tw="text-indigo-600 hover:text-indigo-900 text-right text-sm leading-5 font-medium">Details</p>
              ),
              renderCard: d => (
                <Link
                  to={ROUTES.case.details(d.id)}
                  tw="inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-green-500 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-400 focus:outline-none focus:border-green-700 focus:ring-green-200 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  View Case
                </Link>
              )
            }
          ]}
        />
      )
    }
  )

  useEffect(() => { execute(status) }, [status])

  return render(cases)
}
