import React, { FC } from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { useUser } from '../contexts/User'

type AuthorizedRouteProps = {
  isAuthorized: boolean
  componentWhenNotAuthorized?: React.ElementType
} & RouteProps

export const AuthorizedRoute: FC<AuthorizedRouteProps> = ({
  isAuthorized,
  componentWhenNotAuthorized: ComponentWhenNotAuthorized,
  ...rest
}) => {
  return isAuthorized
    ? <Route {...rest} />
    : ComponentWhenNotAuthorized
      ? <ComponentWhenNotAuthorized {...rest} />
      : null
}

type PickedProps = Omit<AuthorizedRouteProps, 'isAuthorized'>

export const AdminAuthorizedRoute: FC<PickedProps> = props => {
  const { isAdmin } = useUser()

  return <AuthorizedRoute isAuthorized={isAdmin} {...props} />
}

export const SolicitorAuthorizedRoute: FC<PickedProps> = props => {
  const { isSolicitor } = useUser()

  return <AuthorizedRoute isAuthorized={isSolicitor} {...props} />
}
