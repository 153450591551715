/* eslint-disable react-hooks/rules-of-hooks */
import { ReactElement, ReactNode } from "react"
import tw from "twin.macro"
import { useTable, Column, usePagination } from "react-table"
import { CSSProp } from "styled-components"

import {
  Table,
  TableHeader,
  TableBody,
  TableCell,
  TableSelectionLink
} from "./Table"
import { Pagination } from "../../components/Pagination"
import { PaginationController } from "../../hooks/useAppSyncPagination"
import { Spinner } from "../Loading"
import { constVoid } from "fp-ts/lib/function"

export type TableColumn<D extends object> = Column<D> & {
  hideMobile?: boolean
  grow?: boolean
  headerStyle?: CSSProp
  style?: CSSProp
}

export type PlainDataTableProps<D extends object, T> = {
  columns: Array<TableColumn<D>>
  data: D[]
  selectable?: DataTableSelectableOptions<D>
  hideHeader?: boolean
  footer?: ReactNode
  paginationController?: PaginationController<T>
  noDataComponent?: ReactElement
}

export type DataTableSelectableOptions<D extends object> = {
  [K in keyof D]: {
    key: K
    urlGenerator: (input: D[K]) => string
  }
}[keyof D]

export const PlainDataTable = <D extends object, T>({
  hideHeader,
  columns,
  data,
  selectable,
  footer,
  paginationController,
  noDataComponent,
  ...rest
}: PlainDataTableProps<D, T>): ReactElement<PlainDataTableProps<D, T>> => {
  const { pageIndex, pageSize, pageCount, hasPagination } =
    paginationController || {}

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    useTable(
      {
        manualPagination: true,
        autoResetPage: false,
        columns,
        data,
        initialState: { pageIndex: pageIndex, pageSize: pageSize },
        pageCount: pageCount
      },
      usePagination
    )

  const hiddenOnMobile = columns
    .filter((c) => c.hideMobile)
    .map((c) => c.accessor)
  const grow = columns.filter((c) => c.grow).map((c) => c.accessor)

  return (
    <>
      <Table {...rest} {...getTableProps()}>
        {!hideHeader && (
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableHeader
                    css={[
                      tw`truncate`,
                      hiddenOnMobile.includes(column.id as any) &&
                        tw`hidden sm:table-cell`,
                      columns.find((c) => c.accessor === column.id)?.headerStyle
                    ]}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                  </TableHeader>
                ))}
              </tr>
            ))}
          </thead>
        )}
        <TableBody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr
                css={[selectable && tw`hover:bg-gray-50`]}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  return selectable ? (
                    <td
                      css={[
                        grow.includes(cell.column.id as any) &&
                          tw`w-full sm:max-w-none`,
                        hiddenOnMobile.includes(cell.column.id as any) &&
                          tw`hidden sm:table-cell`
                      ]}
                      {...cell.getCellProps()}
                    >
                      <TableSelectionLink
                        to={selectable.urlGenerator(
                          row.original[selectable.key]
                        )}
                      >
                        {cell.render("Cell")}
                      </TableSelectionLink>
                    </td>
                  ) : (
                    <TableCell
                      css={[
                        grow.includes(cell.column.id as any) &&
                          tw`w-full sm:max-w-none`,
                        hiddenOnMobile.includes(cell.column.id as any) &&
                          tw`hidden sm:table-cell`,
                        columns.find((c) => c.accessor === cell.column.id)
                          ?.style
                      ]}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  )
                })}
              </tr>
            )
          })}
        </TableBody>
        {footer}
      </Table>
      {data.length === 0 && noDataComponent}
      {paginationController && hasPagination && (
        <Pagination
          pageSize={paginationController.pageSize}
          pageIndex={paginationController.pageIndex}
          pageCount={paginationController.pageCount}
          rowCount={data.length}
          nextPage={paginationController.nextPage}
          previousPage={paginationController.previousPage}
          gotoPage={paginationController.gotoPage}
        />
      )}
    </>
  )
}

export const SkeletonPlainDataTable = <T extends object>({
  columns
}: {
  columns: TableColumn<T>[]
}) => (
  <>
    <PlainDataTable columns={columns} data={[]} />
    <div tw="flex items-center justify-center h-16">
      <Spinner />
    </div>
    <Pagination
      nextPage={constVoid}
      previousPage={constVoid}
      gotoPage={constVoid}
      pageCount={0}
      pageIndex={0}
      pageSize={1}
      rowCount={1}
    />
  </>
)
