import { isSuccess } from "@nll/datum/DatumEither"
import { useCase } from "packages/app/src/contexts/case/CaseContext"
import { UseQueryResult } from "react-query"
import { match } from "ts-pattern"
import { useGetCaseQuotes } from "../api/getCaseQuote"
import { QuoteEntityV2, QuoteType } from "../types"

export interface QuoteStatusHook {
  caseQuotes: QuoteEntityV2[] | undefined
  firstQuote: QuoteEntityV2 | undefined
  getQuotes: UseQueryResult<QuoteEntityV2[], unknown>
}

export const useQuoteStatus = (): QuoteStatusHook => {
  const { caseDetails } = useCase()

  const cas = isSuccess(caseDetails) ? caseDetails.value.right : undefined

  const getCaseQuotes = useGetCaseQuotes({ caseId: cas?.id })

  const firstQuote =
    getCaseQuotes.data && getCaseQuotes.data.length > 0
      ? getCaseQuotes.data[0]
      : undefined

  return {
    caseQuotes: getCaseQuotes.data,
    firstQuote: firstQuote,
    getQuotes: getCaseQuotes
  }
}

export const extractQuoteInformation = (quote: QuoteEntityV2) => {
  const lastPayment = quote?.quotePayments[quote.quotePayments.length - 1]

  const quoteType: QuoteType = lastPayment
    ? match(lastPayment.type)
        .with(
          { type: "ClientAuthorisation" },
          () => "ClientAuthorisation" as QuoteType
        )
        .with(
          { type: "SolicitorPayment" },
          () => "SolicitorPayment" as QuoteType
        )
        .with(
          { type: "OffPlatformPayment" },
          () => "OffPlatformPayment" as QuoteType
        )
        .exhaustive()
    : ("ClientPaymentToSolicitor" as QuoteType)

  const hasPaymentHoldExpired =
    lastPayment &&
    match(lastPayment.type)
      .with({ type: "ClientAuthorisation" }, (a) => a.isCancelled)
      .otherwise(() => false)

  const paymentStatus = lastPayment?.payment.status

  return { lastPayment, quoteType, hasPaymentHoldExpired, paymentStatus }
}
