import { isSome } from "fp-ts/es6/Option"
import { ClientPaymentsView } from "packages/app/src/features/quotes/routes/ClientPaymentsView"
import "twin.macro"

import {
  FilesIcon,
  MessageIcon,
  PaymentsIcon
} from "../../../../../elements/Icons"
import { ROUTES } from "../../../../../layout/Navigation"
import {
  CaseStatusTitle,
  CaseStatusTitleHeader,
  ComponentWithCase
} from "../../CaseDetails"
import { CaseFilesCard } from "../../shared/CaseFilesCard"
import { CaseTabLayout } from "../../shared/CaseTabLayout"
import { MessagesCardWithData } from "../../shared/MessagesCardWithData"

export const ClientCompletedStatusPanel: ComponentWithCase = ({
  case: cas
}) => {
  return (
    <div tw="flex flex-row items-center">
      <div tw="mr-12">
        {isSome(cas.solicitor) && (
          <>
            <CaseStatusTitleHeader>Your Solicitor</CaseStatusTitleHeader>
            <CaseStatusTitle>{cas.solicitor.value.name}</CaseStatusTitle>
          </>
        )}
      </div>

      <div>
        <CaseStatusTitleHeader>Case Status</CaseStatusTitleHeader>
        <CaseStatusTitle>Completed</CaseStatusTitle>
      </div>
    </div>
  )
}

export const ClientCompletedActionPanel: ComponentWithCase = ({
  case: cas
}) => {
  return null
}

export const ClientCompletedDetailsPanel: ComponentWithCase = ({
  case: cas
}) => (
  <CaseTabLayout
    defaultRedirect={ROUTES.case.messages(cas.id)}
    config={[
      {
        label: "Messages",
        icon: <MessageIcon />,
        to: ROUTES.case.messages(cas.id),
        path: ROUTES.case.messages(":caseId"),
        render: () => <MessagesCardWithData canSend={true} />
      },
      {
        label: "Files",
        icon: <FilesIcon />,
        to: ROUTES.case.files(cas.id),
        path: ROUTES.case.files(":caseId"),
        render: () => <CaseFilesCard canUpload={true} />
      },
      {
        label: "Payments",
        icon: <PaymentsIcon />,
        to: ROUTES.case.fees(cas.id),
        path: ROUTES.case.fees(":caseId"),
        render: () => <ClientPaymentsView />
      }
    ]}
  />
)
