import { WarningMessage } from "packages/app/src/elements"
import React from "react"
import { useState } from "react"

type PaymentCancelledMessageProps = {
  initialVisible: boolean
}
export const CheckoutCancelledMessage = ({
  initialVisible
}: PaymentCancelledMessageProps) => {
  const [notificationVisible, setNotificationVisible] = useState(
    () => initialVisible
  )

  return notificationVisible ? (
    <WarningMessage
      title="Checkout cancelled"
      dismissable={true}
      onDismiss={() => setNotificationVisible(false)}
    >
      Don't worry, you haven't been charged!
    </WarningMessage>
  ) : null
}
