import { matchExhaustive } from "@practical-fp/union-types"
import { pipe } from "fp-ts/es6/function"
import * as O from "fp-ts/es6/Option"
import * as TE from "fp-ts/es6/TaskEither"
import { castTo, fetchParsedJSON } from "../lib/fetch"
import { UUID } from "../lib/Primitives"
import { useApiFetch } from "../lib/useApiClient"

export const useCreateSolicitorAffiliateId = () => {
  const api = useApiFetch(fetchParsedJSON(castTo<UUID>()))

  return () => pipe(
    api(`solicitors/affiliate`, { method: "post" }),
    TE.map(O.some),
    TE.orElseW(error => matchExhaustive(error, {
      NetworkError: () => TE.left(error),
      ParseError: () => TE.left(error),
      ResponseError: (e) => e.statusCode === 404
        ? TE.right(O.none as O.Option<UUID>)
        : TE.left(error)
    })),
  )
}
