import { compareAsc } from 'date-fns'
import { FC, useEffect, useRef } from 'react'
import { Message, ChatMessage } from './'

export const MessageList: FC<{ messages: Message[] }> = ({ messages }) => {
  const messagesEndRef = useRef<HTMLDivElement>(null)

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(scrollToBottom, [messages])

  const sorted = messages.sort((a, b) => compareAsc(a.timestamp, b.timestamp))

  return (
    <>
      {sorted.length > 0 ? (
        <div tw="flex flex-col space-y-4 overflow-y-auto">
          {sorted.map((u, i) => (
            <ChatMessage key={`update-${i}`} message={u} />
          ))}
        </div>
      ) : (
        <div tw="leading-6 text-sm">
          <p>There are no messages on your case.</p>
        </div>
      )}
      <div tw="h-0" ref={messagesEndRef} />
    </>
  )
}
