import { createContext, default as React, DependencyList, FC, memo, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { createPortal } from "react-dom"
import 'twin.macro'

import { noop } from "../lib/utils"

type ModalProviderProps = {
  rootComponent?: React.ComponentType<any>
}

type ModalContext = {
  show: (key: string, component: ModalType) => void
  hide: (key: string) => void
}

const ModalContext = createContext<ModalContext>({
  show: noop,
  hide: noop
})

type ModalType = React.FunctionComponent<any>

export const ModalProvider: FC<ModalProviderProps> = ({ rootComponent, children }) => {
  const [modals, setModals] = useState<Record<string, ModalType>>({})

  const show = useCallback(
    (key: string, modal: ModalType) => setModals(modals => ({ ...modals, [key]: modal })),
    []
  )

  const hide = useCallback(
    (key: string) => setModals(modals => {
      const { [key]: _, ...rest } = modals
      return rest
    }),
    []
  )

  const context = useMemo(() => ({ show, hide }), [])

  return (
    <ModalContext.Provider value={context}>
      <React.Fragment>
        {children}

        <ModalRoot
          modals={modals}
          component={rootComponent}
        />
      </React.Fragment>
    </ModalContext.Provider>
  )
}


type ModalRendererProps = {
  component: ModalType
}

const ModalRenderer = memo<ModalRendererProps>(({ component, ...rest }) => component(rest))

type ModalRootProps = {
  modals: Record<string, ModalType>
  component?: React.ComponentType<any>
  container?: Element
}

export const ModalRoot = memo<ModalRootProps>(({ modals, container, component: RootComponent = React.Fragment }) => {
  const [mountNode, setMountNode] = useState<Element | undefined>(undefined)

  useEffect(() => setMountNode(container || document.body), [])

  return mountNode
    ? createPortal(
      <RootComponent>
        {Object.keys(modals).map(key => (
          <ModalRenderer key={key} component={modals[key]} />
        ))}
      </RootComponent>,
      mountNode
    )
    : null
})

const generateModalKey = (() => {
  let count = 0
  return () => `${++count}`
})()

type UseModalShow = () => void
type UseModalHide = () => void

export const useModal = (component: ModalType, inputs: DependencyList = []): [UseModalShow, UseModalHide] => {
  const key = useMemo(generateModalKey, [])
  const modal = useMemo(() => component, inputs)
  const { show, hide } = useContext(ModalContext)
  const [isVisible, setIsVisible] = useState<boolean>(false)

  const showModal = useCallback(() => setIsVisible(true), [])
  const hideModal = useCallback(() => setIsVisible(false), [])

  useEffect(() => {
    if (isVisible) {
      show(key, modal)
    } else {
      hide(key)
    }

    return () => hide(key)
  }, [modal, isVisible])

  return [showModal, hideModal]
}
