import React, { FC, useEffect } from "react"
import { ROUTES } from "../../layout/Navigation"
import { useAsync } from "../../lib/useAsync"
import { isInitial, isPending, isRefresh, refreshFold } from "@nll/datum/DatumEither"
import { useUser } from "../../contexts/User"
import { formSubmit, graphql } from "../../lib/utils"
import {
  clientCreateAddCardSessionCommand,
  ClientCreateAddCardSessionCommandMutation,
  detachPaymentMethodCommand,
  DetachPaymentMethodCommandMutation,
  GetUserQuery,
  GetUserQueryVariables
} from "@lawhive/generated-api"
import { Spinner } from "../../elements/Loading"
import { PaymentMethod } from "../../lib/types"
import { fromNullable, getOrElse, Option } from "fp-ts/es6/Option"
import { ErrorMessageOld } from "../../elements"
import { StripeContainer } from "../../elements/Stripe"
import { NeutralPill } from "../../elements/Badge"
import "twin.macro"
import { useStripe } from "@stripe/react-stripe-js"
import { Stripe } from "@stripe/stripe-js"
import { Button } from "../../elements/Button"
import { AmexIcon, MasterCardIcon, VisaIcon } from "../../elements/Icons"
import { pipe } from "fp-ts/es6/function"
import { DetailsSubshell } from "../../layout/subshells/DetailsSubshell"
import { PageHeader } from "../../elements/PageHeader"

const AddCardButton: FC<{ returnUrl: string, cancelUrl: string }> = ({ returnUrl, cancelUrl }) => {
  const stripe = useStripe()

  const { status, execute } = useAsync(
    (stripe: Stripe) =>
      graphql<ClientCreateAddCardSessionCommandMutation>({
        query: clientCreateAddCardSessionCommand,
        variables: {
          input: {
            returnUrl,
            cancelUrl,
          }
        }
      })
        .then(a => a.data?.clientCreateAddCardSessionCommand
          ? Promise.resolve({ sessionId: a.data.clientCreateAddCardSessionCommand.sessionId! })
          : Promise.reject('Checkout response error')
        )
        .then(async data => {
          const { error } = await stripe.redirectToCheckout({
            sessionId: data.sessionId
          })

          if(error) {
            console.log('Error', error)
          }
        })
  )

  const submit = async () => {
    if(!stripe) {
      return
    }

    await execute(stripe)
  }

  const isLoading = isPending(status) || isRefresh(status)

  // TODO Style / spinner / disable based on submission state
  return (
    <Button
      onClick={formSubmit(submit)}
      disabled={isLoading}
    >
      {isLoading? <Spinner /> : <>Add card</>}
    </Button>
  )
}

const AddCardAction: FC<{ returnUrl: string, cancelUrl: string }> = ({ returnUrl, cancelUrl }) => (
  <StripeContainer>
    <AddCardButton returnUrl={returnUrl} cancelUrl={cancelUrl} />
  </StripeContainer>
)

const PaymentMethods: FC = () => {
  const { userId } = useUser()

  const { status, execute: load } = useAsync(
    () => graphql<GetUserQuery, GetUserQueryVariables>({
      query: /* GraphQL */ `
        query GetUser {
          getUser(id: "${userId}") {
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
          }
        }
      `
    })
    .then(a => a.data?.getUser
      ? Promise.resolve(fromNullable(a.data.getUser.paymentMethods as PaymentMethod[]))
      : Promise.reject('No payment methods')
    )
  )

  useEffect(() => {
    load()
  }, [])

  const renderMethods = refreshFold(
    () => <Spinner />,
    () => <Spinner />,
    () => <ErrorMessageOld title="Couldn't load payment methods" />,
    (methods: Option<PaymentMethod[]>, isRefreshing) =>
      <PaymentMethodsList methods={pipe(methods, getOrElse((): PaymentMethod[] => []))} isLoading={isRefreshing} reloadFunction={load} />
  )

  return (
    <div tw="space-y-4">
      {renderMethods(status)}
    </div>
  )
}

const PaymentMethodsList: FC<{methods: PaymentMethod[], isLoading?: boolean, reloadFunction: () => void}> = ({methods, isLoading, reloadFunction}) => {
  return (
    <div tw="bg-gray-50 px-4 rounded-md">
      {isLoading
        ? <Spinner/>
        : methods.length > 0
          ? (
              <div tw="divide-y">
                {methods.map((m, i) => (
                  <div key={`pm-${i}`} tw="flex-1 flex flex-row flex-wrap items-center justify-center py-4">
                    <PaymentMethodListRow method={m} reloadFunction={reloadFunction} />
                  </div>
                ))}
              </div>
          )
          : (
          <>
            <div tw='flex items-center justify-center py-4'>
              <p tw='text-sm text-gray-500'>No payment methods set up</p>
            </div>
          </>
        )
      }
    </div>
  )
}

const BrandIcon: FC<{method: PaymentMethod}> = ({method}) => {
  switch (method.brand) {
    case 'mastercard':
      return <MasterCardIcon />
    case 'amex':
      return <AmexIcon />
    case 'visa':
      return <VisaIcon />
  }
  return <NeutralPill>{method.brand}</NeutralPill>
}

const DeleteCardButton: FC<{ paymentMethodId: string, reload: () => void }> = ({ paymentMethodId, reload }) => {
  const { status, execute } = useAsync(
    () =>
      graphql<DetachPaymentMethodCommandMutation>({
        query: detachPaymentMethodCommand,
        variables: {
          input: {
            paymentMethodId
          }
        }
      })
        .then(a => a.data
          ? Promise.resolve("Card deleted")
          : Promise.reject("Error deleting card")
        )
  )

  const submit = async () => {
    await execute()
    reload()
  }

  const isLoading = !isInitial(status)

  return (
    <div tw='sm:ml-auto'>
      <Button
        variant='negative'
        onClick={submit}
        disabled={isLoading}
        isLoading={isLoading}
      >
        Remove card
      </Button>
    </div>
  )
}

const PaymentMethodListRow: FC<{ method: PaymentMethod, reloadFunction: () => void }> = ({ method, reloadFunction }) => (
  <div tw="flex-1 flex flex-row flex-wrap items-center min-h-0">
    <BrandIcon method={method} />
    <NeutralPill tw="ml-1 sm:ml-2 font-bold">**** **** **** {method.lastFour}</NeutralPill>
    <NeutralPill tw="ml-1 sm:ml-2">
      {method.expiryMonth.toString().padStart(2, '0')} / {method.expiryYear.toString().slice(2)}
    </NeutralPill>
    <DeleteCardButton paymentMethodId={method.id} reload={reloadFunction} />
  </div>
)


export const ManagePaymentMethodsScene = () => {

  return (
    <DetailsSubshell
      header={
        <PageHeader
          title="Manage Payment Methods"
          actions={<AddCardAction returnUrl={ROUTES.paymentMethods.root} cancelUrl={ROUTES.paymentMethods.root}/>}
        />
      }
      content={
        <PaymentMethods />
      }
    />
  )
}
