import * as D from 'io-ts/lib/Decoder'
import { pipe } from 'fp-ts/lib/function'
import * as A from 'fp-ts/lib/Array'
import * as O from 'fp-ts/lib/Option'
import { withOptions } from '../layout/Navigation'
import { fetchParsedJSON } from "../lib/fetch"
import { decodeOrError, withOptional } from '../lib/io'
import { DateFromString } from '../lib/Primitives'
import { useApiFetch } from '../lib/useApiClient'
import { NextToken, useNextTokenPaginatedData } from './useNextTokenPagination'

export const PaymentStatus = D.literal('canceled', 'processing', 'requires_action', 'requires_capture', 'requires_confirmation', 'requires_payment_method', 'succeeded')
export type PaymentStatus = D.TypeOf<typeof PaymentStatus>

export const RefundListEntry = D.struct({
  amount: D.number
})

export type RefundListEntry = D.TypeOf<typeof RefundListEntry>

export const PaymentListEntry = D.struct({
  id: D.string,
  caseNumber: D.string,
  caseId: D.string,
  clientName: D.string,
  status: PaymentStatus,
  paidAt: DateFromString,
  amount: D.number,
  refunded: D.boolean,
  refunds: D.array(RefundListEntry)
})

export type PaymentListEntry = D.TypeOf<typeof PaymentListEntry>

export const PaymentList = withOptional(
  D.struct({
    data: D.array(PaymentListEntry),
  }),
  {
    nextPage: D.string
  }
)

export type PaymentList = D.TypeOf<typeof PaymentList>

export const StripeNextTokenPagination = D.partial({
  startingAfter: D.string,
  endingBefore: D.string,
  limit: D.string
})

export type StripeNextTokenPagination = D.TypeOf<typeof StripeNextTokenPagination>

export const StripeSearchPagination = D.partial({
  page: D.string,
  limit: D.string
})

export type StripeSearchPagination = D.TypeOf<typeof StripeSearchPagination>


export const useListPayments = () => {
  const api = useApiFetch(fetchParsedJSON(decodeOrError(PaymentList)))

  return (pagination?: StripeSearchPagination) => pipe(
    withOptions(`solicitor-billing/payments`, pagination),
    api
  )
}

export const useControlledListPayments = () => {
  const requestPayments = useListPayments()

  return useNextTokenPaginatedData({
    query: (nextToken, limit) => requestPayments({
      page: nextToken as string | undefined,
      limit: String(limit)
    }),
    dataExtractor: a => a.data,
    nextTokenExtractor: (r) => r.nextPage,
    opts: {
      limit: 5
    },
  })
}
