import "twin.macro"
import { FC } from "react"
import { Card, CardContent, CardTitle } from "../../../elements"

export const FaqModal: FC = () => {

  return (
    <div tw="max-w-xl">
      <Card>
        <CardContent>
          <CardTitle>
            FAQs
          </CardTitle>
          <FaqList questions={[
            {
              question: "How will you collect payment for platform fees?",
              answer: "We’ll ask you to setup a direct debit to your office account to pay for Lawhive fees accrued over the month. If you don’t receive any payments for the month, this will appear as £0.00 on the invoice."
            },
            {
              question: "When do I receive an invoice?",
              answer: "You’ll receive an invoice at the end of every month after signing up to pay-monthly. The Direct Debit comes out of your account a few days later."
            },
            {
              question: "What happens if I refund a client?",
              answer: "Platform fees on refunded client payments will be deducted from your next monthly invoice"
            },
          ]} />

        </CardContent>
      </Card>
    </div>
  )
}

type FaqListProps = {
  questions: {
    question: string
    answer: string
  }[]
}

const FaqList = ({ questions }: FaqListProps) => (
  <>
    <dl tw="grid gap-y-2 grid-cols-1">
      {questions.map((question) => (
        <div key={question.question}>
          <dt>
            <p tw="mt-5 text-lg leading-6 font-medium text-gray-900">{question.question}</p>
          </dt>
          <dd tw="mt-2 text-base text-gray-500">{question.answer}</dd>
        </div>
      ))}
    </dl>
  </>
)
