import "twin.macro"
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from "react"

import { Card, CardContent, CardTitle, ErrorMessage, TextInput } from "../../../../elements"
import { ModalText } from "../../../../elements/Modal"
import { Button } from "../../../../elements/Button"

import { AssessmentCallUIDetailsResponse } from "../../hooks/useAssessmentCallDetails"
import { GetConfirmedNumberResponse, useGetConfirmedTelephoneNumber } from "../../hooks/useGetVerifiedPhoneNumber"
import { useSetConfirmedTelephoneNumber } from "../../hooks/useSetVerifiedPhoneNumber"
import { pipe } from "fp-ts/lib/function"
import { refreshFold } from "@nll/datum/DatumEither"
import { Spinner } from "../../../../elements/Loading"
import { PhoneIcon } from "@heroicons/react/solid"
import { SubmitHandler, useForm } from "react-hook-form"
import PhoneInput from "react-phone-number-input/react-hook-form-input"
import { E164PhoneNumber } from "aws-sdk/clients/chime"
import { formatPhoneNumber, isValidPhoneNumber } from "react-phone-number-input"
import { EditablePhoneNumber } from "../../../../elements/EditablePhoneNumber"

type InitiateCallModalProps = {
  isLoading: boolean
  call: AssessmentCallUIDetailsResponse
  onCancel: () => void
  onCall: () => void
}

export const InitiateCallModal: FC<InitiateCallModalProps> = ({ onCall, call, isLoading }) => {

  const { status: getPhoneNumberStatus, execute: getPhoneNumber } = useGetConfirmedTelephoneNumber()
  const { status: setPhoneNumberStatus, execute: setPhoneNumber } = useSetConfirmedTelephoneNumber()

  useEffect(() => {getPhoneNumber()}, [call])

  const setPhoneNumberAndRefresh = useCallback(
    async (newNumber: E164PhoneNumber) => {
      await setPhoneNumber({ phoneNumber: newNumber})
      getPhoneNumber()
    },
    [call]
  )

  const SolicitorPhoneNumberKnown: FC<{ solicitorPhoneNumber: E164PhoneNumber }> = ({ solicitorPhoneNumber }) => {

    const [isPhoneNumberBeingEdited, setIsPhoneNumberBeingEdited] = useState<boolean>(false)


    return (
      <>
        <CardContent>
          <CardTitle>
            Are you sure?
          </CardTitle>

          <div tw="text-sm sm:text-base leading-6 sm:leading-7 mt-4">
            We will call you on:
            <EditablePhoneNumber
              isEditing={isPhoneNumberBeingEdited}
              setIsEditing={setIsPhoneNumberBeingEdited}
              phoneNumber={solicitorPhoneNumber}
              onSave={setPhoneNumberAndRefresh}
            />
            <div tw="mt-2">
              And connect you to your client:
              <p tw="font-bold">
                {call.leadName}
              </p>
            </div>
          </div>

        </CardContent>

        <CardContent tw="pt-0 flex">
          <Button variant="positive" tw="inline-flex items-center px-3 py-2" onClick={onCall} isLoading={isLoading}
            disabled={isPhoneNumberBeingEdited}
          >
            <PhoneIcon tw="-ml-0.5 mr-2 h-4 w-4" />
            <p>Call client</p>
          </Button>
        </CardContent>
      </>
    )
  }

  const SolicitorPhoneNotNumberKnown: FC = () => {

    const [newPhoneNumber, setNewPhoneNumber] = useState<E164PhoneNumber | undefined>()

    type FormInputs = {
      phoneNumber: E164PhoneNumber
    }

    const onSubmit: SubmitHandler<FormInputs> = data => {
      setPhoneNumberAndRefresh(data.phoneNumber)
    }

    const {
      handleSubmit,
      control,
      formState: { isValid}
    } = useForm<FormInputs>({
      mode: "onChange",
      defaultValues: { phoneNumber: "" }
    })

    return (
      <form onSubmit={handleSubmit(onSubmit)}>

      <CardContent>
          <CardTitle>
            We need to confirm your phone number
          </CardTitle>

          <ModalText tw="mt-4">
            <p tw="mt-2">
              Please enter your phone number
            </p>
              <PhoneInput
                onChange={setNewPhoneNumber}
                control={control}
                country="GB"
                rules={{ required: true, validate: isValidPhoneNumber }}
                defaultValue={newPhoneNumber}
                name="phoneNumber"
                inputComponent={TextInput}
              />
          </ModalText>

        </CardContent>

        <CardContent tw="pt-0">
          <Button variant="positive" type="submit" disabled={!isValid} isLoading={isLoading}>
            Save
          </Button>
        </CardContent>
      </form>
    )
  }

  const ModalContent: FC<{ response: GetConfirmedNumberResponse }> = ({ response }) => (response.hasConfirmedNumber ?
    <SolicitorPhoneNumberKnown solicitorPhoneNumber={response.number} /> : <SolicitorPhoneNotNumberKnown />)

  const WrappedSpinner: FC = () => <CardContent><Spinner /></CardContent>

  return (
    <div tw="max-w-xl">
      <Card>
        {pipe(
          getPhoneNumberStatus,
          refreshFold(
            () => <WrappedSpinner />,
            () => <WrappedSpinner />,
            () => <CardContent><ErrorMessage title="Error initiating phone call" /></CardContent>,
            (a, isRefreshing) => ((isRefreshing === true) ? <WrappedSpinner /> : <ModalContent response={a} />)
          )
        )}
      </Card>
    </div>
  )

}
