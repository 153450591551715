import React from "react"
import { Route, Switch } from "react-router-dom"

import { ROUTES } from "../../layout/Navigation"
// import { ClaimCaseScene } from "./ClaimCase"
// import { CheckoutCallbackScene } from "./checkout/Callback"
// import { CreateCaseScene } from "./CreateNew"
// import { SaveProgressScene } from "./SaveProgress"
import { AddNameScene } from "./authorized/AddNameScene"

// export const OnboardingSwitch = () => (
//   <Switch>
//     <Route path={ROUTES.onboarding.create(':caseType')} component={CreateCaseScene} />
//     <Route path={ROUTES.onboarding.checkoutCallback(':preRegistrationId')} component={CheckoutCallbackScene} />
//     <Route path={ROUTES.onboarding.saveProgress(':preRegistrationId')} component={SaveProgressScene} />
//     <Route path={ROUTES.onboarding.claimInterest(':preRegistrationId')} component={ClaimCaseScene} />
//   </Switch>
// )

export const OnboardingSwitch = () => (
  <Switch>
    <Route path={ROUTES.onboarding.root} component={AddNameScene} />
  </Switch>
)
