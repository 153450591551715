import React, { Dispatch, useMemo, useContext, createContext, useState, FC }  from 'react'

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>
}

type StoreUpdateFunction<T> = (store: T, payload: DeepPartial<T>) => T
type Action<T> = (payload: DeepPartial<T>) => void
type Actions<T, K> = Record<keyof K, Action<T>>
type ActionArg<T> = (globalStore: T, payload: DeepPartial<T>) => T
type ActionsArg<T> = Record<string, ActionArg<T>>
type Middleware = <T>(arg: T) => T

type StateMachineOptions = {
  middleware: Middleware[]
  storageType?: Storage
}

type StateMachineProps<T> = {
  storeName: string,
  defaultStoreData: T,
  options?: StateMachineOptions | undefined
}

const getBrowserStoreData = (storageType: Storage, storeName: string) => {
  try {
    return JSON.parse(storageType.getItem(storeName) as string)
  } catch {
    return ''
  }
}

const actionTemplate = <T,>(
  store: T,
  updateStore: Dispatch<T>,
  callback: StoreUpdateFunction<T>,
) => {
  return <K extends DeepPartial<T>>(payload: K) => {
    const result = callback(store, payload)
    updateStore(result)
  }
}

const buildActions =
  <T,>(store: T, updateStore: Dispatch<T>) =>
  <TActions extends ActionsArg<T>>(actions: TActions): Actions<T, TActions> =>
    Object.entries(actions).reduce(
      (previous, [key, callback]) => ({
        ...previous,
        [key]: actionTemplate<T>(store, updateStore, callback),
      }),
      {} as Actions<T, TActions>,
    )

type StateMachineContext<T> = {
  store: T,
  updateStore: (payload: T) => void,
}

export const createStateMachine = <T,>({
    storeName,
    defaultStoreData,
    options: {
      middleware,
      storageType
    } = {
      middleware: []
    }
  }: StateMachineProps<T>) => {

  const StateMachineContext = createContext<StateMachineContext<T>>({
    store: {} as T,
    updateStore: () => {},
  })

  const StateMachineProvider: FC = props => {
    const storage = storageType || (
      typeof sessionStorage !== 'undefined'
        ? window.sessionStorage
        : ({} as Storage)
    )

    const [globalState, updateStore] = useState<T>(() => getBrowserStoreData(storage, storeName) || defaultStoreData)

    const action = (state: T) => {
      const result = middleware.length
        ? middleware.reduce(
          (currentValue, currentFunction) =>
            currentFunction(currentValue) || currentValue,
            state,
        )
        : state


      storage.setItem(storeName, JSON.stringify(result))
      updateStore(result)
    }

    return (
      <StateMachineContext.Provider
        value={useMemo(
          () => ({
            store: globalState,
            updateStore: action,
          }),
          [globalState],
        )}
        {...props}
      />
    )
  }

  const useStateMachine = <B extends ActionsArg<T>>(
    actions?: B,
  ): {
    actions: Actions<T, B>
    state: T
  } => {
    const { store, updateStore } = useContext(StateMachineContext)
    const as = actions || {} as B

    return useMemo(
      () => ({
        actions: buildActions(store as T, updateStore)(as),
        state: store as T,
      }),
      [store, as],
    )
  }

  return {
    Provider: StateMachineProvider,
    useStateMachine
  }
}
