import { chain, fromNullable, getOrElse, isSome, map, Option } from 'fp-ts/es6/Option'
import React from 'react'
import 'twin.macro'
import {
  solicitorRequestKycReportDownloadCommand,
  SolicitorRequestKycReportDownloadCommandMutation,
  SolicitorRequestKycReportDownloadCommandMutationVariables
} from '@lawhive/generated-api'
import { Card, CardContent } from '../../../../../elements'
import { downloadFileVoid } from '../../../../../lib/files'
import { graphql, throwIfIsNullOrUndefined } from '../../../../../lib/utils'
import { ComponentWithCase } from '../../CaseDetails'
import { FilesList, NewFileListItem } from '../../../../../elements/FilesList'
import { pipe } from 'fp-ts/es6/function'

export const SolicitorClientDetailsCard: ComponentWithCase = ({ case: cas }) => {

  const createFileListItemFromOptionalKeys =
    (name: string) =>
    (maybeList: Option<string[]>) => pipe(
      maybeList,
      getOrElse((): string[] => []),
      docs => docs.map((d, i) => ({ name: `${name}${i === 0 ? `` : ` ${i}`}`, key: d }))
    )

  const kycFileListItems: NewFileListItem[] = pipe(
    fromNullable(cas.clientKyc),
    map(clientKyc => [
      ...pipe(
        clientKyc.documentUploads,
        createFileListItemFromOptionalKeys(`Photo ID`)
      ),
      ...pipe(
        clientKyc.identityUploads,
        createFileListItemFromOptionalKeys(`Client Selfie`)
      ),
      ...pipe(
        clientKyc.verificationReport,
        map(reports => [reports.amlReportFileKey]),
        createFileListItemFromOptionalKeys('AML Report')
      ),
      ...pipe(
        clientKyc.verificationReport,
        chain(reports => reports.kycReportFileKey),
        map(key => [key]),
        createFileListItemFromOptionalKeys('Proof of Address')
      )
    ]),
    getOrElse((): NewFileListItem[] => [])
  )

  const download2 = (key: string, name: string = key) =>
    graphql<SolicitorRequestKycReportDownloadCommandMutation, SolicitorRequestKycReportDownloadCommandMutationVariables>({
      query: solicitorRequestKycReportDownloadCommand,
      variables: {
        input: {
          caseId: cas.id,
          key: key
        }
      }
    })
      .then(r => {
        console.log(r)
        return r.data?.solicitorRequestKycReportDownloadCommand?.url})
      .then(throwIfIsNullOrUndefined)
      .then(downloadFileVoid(name))

  return (
    <Card>
      <CardContent>
        {isSome(cas.client) && (
          <>
            <p tw='text-xs uppercase text-gray-400 font-medium'>
              Client Identity Documents
            </p>
            <p>
              <FilesList onClick={download2} update={() => null} items={kycFileListItems}/>
            </p>
          </>
        )}
      </CardContent>
    </Card>
  )
}
