import * as React from 'react'
import { useContext } from 'react'

export interface Environment {
  STRIPE_PUBLIC_KEY: string,
  MAGIC_PUBLIC_KEY: string
  INTERCOM_APP_ID: string,
  BACKEND_URL: string,
  COBROWSE_KEY: string,
  CONTENTFUL_KEY: string,
  CONTENTFUL_URL: string
  POSTHOG_KEY: string
}

export const DEFAULT_ENVIRONMENT: Environment = {
  STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY || '',
  MAGIC_PUBLIC_KEY: process.env.REACT_APP_MAGIC_PUBLIC_KEY || '',
  INTERCOM_APP_ID: process.env.REACT_APP_INTERCOM_APP_ID || '',
  BACKEND_URL: process.env.REACT_APP_BACKEND_URL || '',
  COBROWSE_KEY: process.env.REACT_APP_COBROWSE_KEY || '',
  CONTENTFUL_KEY: process.env.REACT_APP_CONTENTFUL_KEY || '',
  CONTENTFUL_URL: process.env.REACT_APP_CONTENTFUL_URL || '',
  POSTHOG_KEY: process.env.REACT_APP_POSTHOG_KEY || '',
}

export const EnvironmentContext = React.createContext<Environment>(DEFAULT_ENVIRONMENT)

export const useEnvironment = (): Environment => {
  return useContext(EnvironmentContext)
}
