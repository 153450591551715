import { pipe } from "fp-ts/lib/function"
import { Branded } from "io-ts"
import * as D from "io-ts/lib/Decoder"
import * as G from "io-ts/lib/Guard"
import { validate } from "uuid"

export type EmailAddress = Branded<string, "EmailAddress">
const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

export const EmailAddress = pipe(
  D.string,
  D.refine((s): s is EmailAddress => emailRegex.test(s), "EmailAddress")
)

// export type PhoneNumber = Branded<string, 'PhoneNumber'>
// export const PhoneNumber = pipe(
//   D.string,
//   D.refine((s): s is PhoneNumber => isValidPhoneNumber(s, 'GB'), 'PhoneNumber')
// )

export type MaxLengthString<N> = Branded<string, { maxLength: N }>

export const MaxLengthString = <N extends number>(len: N) => pipe(
  D.string,
  D.refine((s): s is MaxLengthString<N> => s.length <= len, `MaxLengthString${len}`)
)

export type MinLengthString<N> = Branded<string, { minLength: N }>

export const MinLengthString = <N extends number>(len: N) => pipe(
  D.string,
  D.refine((s): s is MinLengthString<N> => s.length >= len, `MinLengthString${len}`)
)

export type RangeString<Min, Max> = Branded<string, { minLength: Min, maxLength: Max }>
export const RangeString = <Min extends number, Max extends number>(minN: Min, maxN: Max) => pipe(
  MinLengthString(minN),
  D.intersect(MaxLengthString(maxN)),
  D.refine((s): s is RangeString<Min, Max> => true, `RangeString[${minN}-${maxN}]`)
)

export type MinInt<N> = Branded<Int, { min: N }>
export const MinInt = <N extends number>(min: N) => pipe(
  Int,
  D.refine((s): s is MinInt<N> => s >= min, `MinInt${min}`)
)

export type MaxInt<N> = Branded<Int, { max: N }>
export const MaxInt = <N extends number>(max: N) => pipe(
  Int,
  D.refine((s): s is MaxInt<N> => s <= max, `MaxInt${max}`)
)

export type RangeInt<Min, Max> = Branded<Int, { min: Min, max: Max }>
export const RangeInt = <Min extends number, Max extends number>(min: Min, max: Max) => pipe(
  MinInt(min),
  D.intersect(MaxInt(max)),
  D.refine((s): s is RangeInt<Min, Max> => true, `RangeInt[${min}-${max}]`)
)

export type FullName = Branded<string, 'FullName'>

export const FullName = pipe(
  MinLengthString(1),
  D.intersect(MaxLengthString(80)),
  D.refine((s: any): s is FullName => true, 'FullName')
)

export type UUID = Branded<string, 'UUID'>

export const UUID = pipe(
  D.string,
  D.refine((s): s is UUID => validate(s), `UUID`)
)


// https://github.com/tchak/io-ts-types-experimental/blob/dab4b3df815d35de7f1b51c2aa8072ae6327d552/src/Decoder.ts

interface IntBrand {
  readonly Int: unique symbol;
}

export type Int = number & IntBrand;

export const GuardInt: G.Guard<unknown, Int> = pipe(
  G.number,
  G.refine((n): n is Int => Number.isInteger(n))
);

export const Int: D.Decoder<unknown, Int> = D.fromGuard(GuardInt, 'Int');

export const NumberFromString: D.Decoder<unknown, number> = pipe(
  D.string,
  D.parse((s) => {
    const n = parseFloat(s);
    return isNaN(n)
      ? D.failure(s, `cannot parse ${JSON.stringify(s)} to a number`)
      : D.success(n);
  })
);

export const IntFromString: D.Decoder<unknown, Int> = pipe(
  NumberFromString,
  D.refine(GuardInt.is, 'Int')
)

// https://github.com/gcanti/io-ts-types/blob/9ddc26eb40ad038c6ab998f51d3fae15602e8ae9/src/Decoder/DateFromString.ts
export const DateFromString: D.Decoder<unknown, Date> = pipe(
  D.string,
  D.parse(s => {
    const d = new Date(s)
    return isNaN(d.getTime())
      ? D.failure(s, `cannot parse ${JSON.stringify(s)} to a Date`)
      : D.success(d)
  })
)

export type Min<Int, N> = Branded<Int, { min: N }>
export const Min = <N extends number, Type extends number> (decoder: D.Decoder<unknown, Type>, min: N) => pipe(
  decoder,
  D.refine((s): s is Min<Type, N> => s >= min, `Min${min}`)
)

export type Max<Int, N> = Branded<Int, { max: N }>
export const Max = <N extends number, Type extends number> (decoder: D.Decoder<unknown, Type>, max: N) => pipe(
  decoder,
  D.refine((s): s is Max<Type, N> => s <= max, `Max${max}`)
)

export type Range<Int, Min, Max> = Branded<Int, { min: Min, max: Max }>
export const Range = <Min extends number, Max extends number, Type extends number> (decoder: D.Decoder<unknown, Type>, min: Min, max: Max) => pipe(
  Min(decoder, min),
  D.intersect(Max(decoder, max)),
  D.refine((s): s is Range<Type, Min, Max> => true, `Range[${min}-${max}]`)
)

export type PriceUnits = Branded<Range<Int, 0, 10_000_000>, "PriceUnits">
export const PriceUnits = pipe(
  Range(Int, 0, 10_000_000),
  D.refine((s: any): s is PriceUnits => true, "PriceUnits")
)

export type NonZeroPriceUnits = Branded<Range<Int, 1, 10_000_000>, "NonZeroPriceUnits">
export const NonZeroPriceUnits = pipe(
  PriceUnits,
  D.intersect(Min(Int, 1)),
  D.refine((s: any): s is NonZeroPriceUnits => true, "NonZeroPriceUnits")
)

export type Percentage = Branded<Range<number, 0, 100>, "Percentage">
export const Percentage = pipe(
  Range(D.number, 0, 100),
  D.refine((s: any): s is Percentage => true, "Percentage")
)
