import 'twin.macro'

import { matchExhaustive, Tags } from "@practical-fp/union-types"
import { format } from "date-fns/fp"
import * as A from "fp-ts/Array"
import { pipe } from "fp-ts/es6/function"
import * as N from "fp-ts/number"
import * as Ord from "fp-ts/Ord"
import { useEffect, useMemo } from "react"
import { useParams } from "react-router-dom"
import { AccountingCurrencyUnits, FormattedCurrencyUnits } from "../../../elements/Currency"
import { PageHeader, PageHeaderBackLink } from "../../../elements/PageHeader"
import { Statistic } from "../../../elements/Statistic"
import { TableColumn } from "../../../elements/table/PlainDataTable"
import { TableNavigationLink } from "../../../elements/table/Table"
import {
  AutomaticPayoutDetails,
  PayoutDetails,
  PayoutTransaction,
  PayoutTransactionType,
  TransactionFee,
  useGetPayoutDetails
} from "../../../hooks/useGetPayoutDetails"
import { ROUTES } from "../../../layout/Navigation"
import { SidebarLayout } from "../../../layout/SidebarLayout"
import { ContentLayoutFlow, DetailsSubshell, DetailsSubshellSkeleton } from "../../../layout/subshells/DetailsSubshell"
import { ErrorSubshell } from "../../../layout/subshells/ErrorSubshell"
import { useFromTaskEither } from "../../../lib/useAsync"
import { matchStringUnion, renderDatum } from "../../../lib/utils"
import { sign } from "fp-ts/es6/Ordering"
import { PayoutStatusBadge } from "../../../components/solicitor-billing/PayoutStatusBadge"
import * as O from "fp-ts/lib/Option"
import { PayoutDetailsTable } from "../../../components/solicitor-billing/PayoutDetailsTable"
import { isFuture } from "date-fns"

export const PayoutDetailsScene = () => {
  const request = useGetPayoutDetails()

  const { payoutId } = useParams<{ payoutId: string }>()

  const { status, execute } = useFromTaskEither(request)

  useEffect(() => {
    execute(payoutId)
  }, [payoutId])

  return pipe(
    status,
    renderDatum(
      () => <DetailsSubshellSkeleton />,
      () => <ErrorSubshell title={"Error getting payout details"} />,
      (payout) => <LoadedPayoutDetailsScene payout={payout} />
    )
  )
}

const OrderMapping: Record<Tags<PayoutTransactionType>, number> = {
  CasePayment: 0,
  Other: 1
}

const typeOrd: Ord.Ord<PayoutTransactionType> = {
  equals: (f, s) => f.tag === s.tag,
  compare: (f, s) => sign(OrderMapping[f.tag] - OrderMapping[s.tag])
}

const byType = pipe(
  typeOrd,
  Ord.contramap((p: PayoutTransaction) => p.type)
)

const byAmount = pipe(
  N.Ord,
  Ord.contramap((p: PayoutTransaction) => p.amount)
)

const sortByTypeAmount = A.sortBy([byType, Ord.reverse(byAmount)])

const LoadedPayoutDetailsScene = ({ payout }: { payout: PayoutDetails }) => {
  const formatStatistic = format('do MMM yyyy - HH:mmaaa')
  const formatTable = format('dd/MM/yy')

  const sortedTransactions = useMemo(() => pipe(
    payout,
    O.fromPredicate((a: PayoutDetails): a is AutomaticPayoutDetails => payout.automatic),
    O.map(a => a.transactions),
    O.map(sortByTypeAmount)
  ), [payout])

  const columns: TableColumn<PayoutTransaction>[] = useMemo(() => [
    {
      id: 'type',
      Header: 'Details',
      accessor: 'type',
      Cell: ({ value }) => matchExhaustive(value, {
        CasePayment: ({ caseNumber, clientName, caseId }) => (
          <div tw="space-y-2">
            <TableNavigationLink to={ROUTES.case.details(caseId)}>
              Case #{caseNumber}
            </TableNavigationLink>

            <p tw="text-gray-500 text-sm">
              {clientName}
            </p>
          </div>
        ),
        Other: () => (<>Other</>)
      })
    },
    {
      id: 'amount',
      Header: 'Amount',
      accessor: 'amount',
      Cell: ({ value }) => <AccountingCurrencyUnits amountUnits={value} />
    },
    {
      id: 'fee',
      Header: 'Fee',
      accessor: 'fee',
      Cell: ({ value, row }) => <FeeCell amount={value} fees={row.original.fees} />
    },
    {
      id: 'net',
      Header: 'Net',
      accessor: 'net',
      Cell: ({ value }) => <AccountingCurrencyUnits amountUnits={value} />
    },
    {
      id: 'paidAt',
      Header: 'Date',
      accessor: 'paidAt',
      Cell: ({ value }) => formatTable(value)
    },
  ], [])

  return (
    <DetailsSubshell
      header={(
        <PageHeader
          title={payout.reference
              ? `Payout ${payout.reference}`
              : `Payout ${payout.id}`}
          supportingText={`A payout represents a transfer from your Lawhive funds account to your connected bank account.`}
          back={(
            <PageHeaderBackLink to={ROUTES.clientPayments.root}>
              Back to Client Payments
            </PageHeaderBackLink>
          )}
        />
      )}
      content={(
        <SidebarLayout
          sidebarFirstMobile={true}
          left={(
            <PayoutDetailsTable data={sortedTransactions} columns={columns}/>
          )}
          right={(
            <ContentLayoutFlow>
              {payout.reference && (
                <Statistic
                  label="Reference"
                  value={payout.reference}
                />
              )}

              <Statistic
                label="Amount"
                value={<AccountingCurrencyUnits amountUnits={payout.amount} />}
              />

              <Statistic
                label={isFuture(payout.arrivalDate) ? "Estimated arrival" : "Arrived at"}
                value={formatStatistic(payout.arrivalDate)}
              />

              <Statistic
                label="Statement Reference"
                value={payout.statementDescriptor}
              />

              <Statistic
                label="Status"
                value={<PayoutStatusBadge status={payout.status} />}
              />
            </ContentLayoutFlow>
          )}
        />
      )}
    />
  )
}

type FeeCellProps = {
  amount: number
  fees: TransactionFee[]
}

const FeeCell = ({ amount, fees }: FeeCellProps) => (
  <div tw="flex flex-col gap-2">
    <FormattedCurrencyUnits amountUnits={amount} />
    <div tw="text-xs text-gray-500">
      {fees.map(f => matchStringUnion(f.type, {
        lawhive: () => <p>Lawhive:  <AccountingCurrencyUnits amountUnits={f.amount} /></p>,
        stripe: () => <p>Payments:  <AccountingCurrencyUnits amountUnits={f.amount} /></p>,
        tax: () => <p>Tax:  <AccountingCurrencyUnits amountUnits={f.amount} /></p>
      }))}
    </div>

  </div>
)
