import { appendToCognitoUserAgent, Auth, CognitoUser } from '@aws-amplify/auth'
import { AuthState, AUTH_STATE_CHANGE_EVENT, onAuthUIStateChange, UI_AUTH_CHANNEL } from '@aws-amplify/ui-components'
import { DatumEither, pending, success, toRefresh } from '@nll/datum/DatumEither'
import { API, Hub } from 'aws-amplify'
import { Magic } from 'magic-sdk'
import { createContext, default as React, FC, useContext, useEffect, useState } from 'react'
import { ofType, unionize, UnionOf } from 'unionize'
import { useLoginEvent, useLogoutEvent } from '../features/analytics/useAuthEvent'
import { ROUTES } from '../layout/Navigation'
import { useRouter } from '../lib/useRouter'
import { useEnvironment } from './Environment'

export const UserState = unionize({
  LoggedIn: ofType<LoggedInUser>(),
  LoggedOut: ofType()
})
export type UserState = UnionOf<typeof UserState>

export type LoggedInUser = {
  info: UserInfo
  user: CognitoUserExt
}

export interface CognitoUserExt extends CognitoUser {
  username: string
  attributes: UserAttributes
  signInUserSession: {
    accessToken: {
      jwtToken: string
      payload: {
        "cognito:groups": string[]
        sub: string
        scope: string
        username: string
        client_id: string
        auth_time: number
        exp: number
        token_use: string
        iss: string
        jti: string
      }
    }
  }
}

interface UserAttributes {
  sub: string
  email: string
  email_verified: string
  name: string
  updated_at: string
}

export type UserInfo = {
  id: string
  username: string
  attributes: {
    email: string
    email_verified: boolean
    name: string
    sub: string
  }
}

type AuthenticationContext = {
  state: DatumEither<string, UserState>
  amplifyState: AuthState
  loginWithMagicLink: (email: string, returnUrl?: string) => Promise<void>
  loginWithCallbackCredential: (returnUrl?: string) => Promise<void>
  logout: () => Promise<void>
}

const AuthenticationContext = createContext<AuthenticationContext>({
  state: pending,
  amplifyState: AuthState.Loading,
  loginWithMagicLink: Promise.resolve,
  loginWithCallbackCredential: Promise.resolve,
  logout: Promise.resolve
})

export const useAuthentication = () => useContext(AuthenticationContext)

export const AuthenticationProvider: FC = ({ children }) => {
  const [state, setState] = useState<DatumEither<string, UserState>>(pending)
  const [amplifyState, setAmplifyState] = useState<AuthState>(AuthState.Loading)
  const { MAGIC_PUBLIC_KEY } = useEnvironment()
  const { push } = useRouter()

  const magic = new Magic(MAGIC_PUBLIC_KEY)

  const triggerLoginEvent = useLoginEvent();
  const triggerLogoutEvent = useLogoutEvent();

  const loadUser = async () => {
    try {
      setState(state => toRefresh(state))

      const user = await Auth.currentAuthenticatedUser()
      const info = await Auth.currentUserInfo()

      if(user && info) {
        setState(success(UserState.LoggedIn({ info, user })))
      } else {
        setState(success(UserState.LoggedOut()))
      }
    } catch (err) {
      setState(success(UserState.LoggedOut()))
    }
  }

  useEffect(() => {
    appendToCognitoUserAgent('AuthenticationContext')
    loadUser()
  }, [])

  const loginWithCallbackCredential = async (returnUrl?: string) => {
    const didToken = await magic.auth.loginWithCredential() || ''
    const userMetadata = await magic.user.getMetadata()

    const u = await ensureUserExists(userMetadata.email || '')
    await loginWithDidToken(u, didToken, returnUrl)
  }

  const loginWithMagicLink = async (emailAddress: string, returnUrl?: string) => {
    const origin = window.location.origin
    const callbackUrl = `${origin}${ROUTES.auth.magicLinkCallback(returnUrl)}`

    const lowerEmail = emailAddress.toLowerCase()
    const u = await ensureUserExists(lowerEmail)

    const didToken = await magic.auth.loginWithEmailOTP({ email: lowerEmail }) || ''
    await loginWithDidToken(u, didToken, returnUrl)
  }

  const ensureUserExists = async (emailAddress: string): Promise<CognitoUser> => {
    const lowerEmail = emailAddress.toLowerCase()
    return await Auth.signIn(lowerEmail)
  }

  const loginWithDidToken = async (authUser: CognitoUser, didToken: string, returnUrl?: string) => {
    await Auth.sendCustomChallengeAnswer(authUser, didToken)
    await loadUser()
    triggerLoginEvent({});
    if(returnUrl) {
      push(returnUrl)
    }
  }

  const logout = async () => {
    triggerLogoutEvent({});
    await Auth.signOut()
    await loadUser()
  }

  const context: AuthenticationContext = {
    state,
    amplifyState,
    loginWithMagicLink,
    loginWithCallbackCredential,
    logout
  }

  return (
    <AuthenticationContext.Provider value={context}>
      {children}
    </AuthenticationContext.Provider>
  )
}


  // useEffect(() => {
  //   return onAuthUIStateChange((authState) => {
  //     setAmplifyState(authState)

  //     if(authState === AuthState.SignedIn || authState === AuthState.SignedOut) {
  //       loadUser()
  //     }
  //   })
  // }, [])
