import {
  clientGetAddressFromPostcodeQuery,
  ClientGetAddressFromPostcodeQueryQuery,
  ClientGetAddressFromPostcodeQueryQueryVariables
} from "@lawhive/generated-api"
import { DatumEither, isPending, refreshFold } from "@nll/datum/DatumEither"
import { useState } from "react"
import "twin.macro"

import { Controller, useForm } from "react-hook-form"
import {
  Card,
  CardContent,
  FormButton,
  FormField,
  FormFieldNew,
  Message,
  TextInput
} from "../../elements"
import { Spinner } from "../../elements/Loading"
import { useAsync } from "../../lib/useAsync"
import { graphql } from "../../lib/utils"
import { useUpdateOrCreateKycSubmission } from "./KycStatusScene"
import { KycStep } from "./KycStep"

export type AddressEntryStatus =
  | "initial"
  | "validationError"
  | "addressFound"
  | "manualEntry"
  | "noAddressFound"

const validatePostcode = (postCode: string | undefined) => {
  return (
    !!postCode &&
    /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/.test(
      postCode
    )
  )
}

type PostcodeEntry = {
  postcode: string
}

export const AddressForm = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm<PostcodeEntry>()
  const [state, setState] = useState<AddressEntryStatus>("initial")

  const onSubmit = async (data: PostcodeEntry) => {
    if (validatePostcode(data.postcode)) {
      load(data.postcode)
      setState("addressFound")
    } else {
      setState("validationError")
    }
  }

  const { status: address, execute: load } = useAsync((postCode: string) =>
    graphql<
      ClientGetAddressFromPostcodeQueryQuery,
      ClientGetAddressFromPostcodeQueryQueryVariables
    >({
      query: clientGetAddressFromPostcodeQuery,
      variables: {
        postCode
      }
    })
      .then(
        (result) =>
          result?.data?.clientGetAddressFromPostcodeQuery?.addresses || []
      )
      .then((array) => {
        return array
      })
  )

  return (
    <KycStep step={3}>
      <Card>
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <p tw="text-gray-900 text-sm sm:text-base mb-4">
              Please enter your postcode and select your address.
            </p>

            <FormFieldNew
              id={"postcode"}
              label={"Postcode"}
              error={errors.postcode}
              control={
                <Controller
                  name="postcode"
                  control={control}
                  rules={{
                    required: "Please enter a valid postcode."
                  }}
                  render={({ onChange, value }) => (
                    <div tw="flex flex-col sm:(flex-row items-center)">
                      <div tw="relative rounded-md shadow-sm flex-1 mb-4 sm:(mr-4 mb-0) ">
                        <div tw="relative rounded-md shadow-sm">
                          <TextInput
                            id="postcode"
                            type="text"
                            placeholder="Your postcode"
                            onChange={onChange}
                            value={value}
                          />
                        </div>
                      </div>

                      <button
                        tw="px-5 py-2 border border-transparent text-sm leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo-200 transition duration-150 ease-in-out disabled:text-gray-50 disabled:cursor-not-allowed disabled:bg-indigo-300"
                        type="submit"
                      >
                        Find Address
                      </button>
                    </div>
                  )}
                />
              }
            />
            {state === "initial" && (
              <Message colour="grey" tw="my-4">
                <p>
                  Please enter your postcode above in the format 'AB12 3CD' to
                  select your address.
                </p>
                <p
                  tw="text-indigo-600 hover:text-indigo-500 transition font-medium cursor-pointer mt-4"
                  onClick={() => {
                    setState("manualEntry")
                  }}
                >
                  I'm not based in the UK
                </p>
              </Message>
            )}
            {state === "validationError" && (
              <Message colour="red" tw="my-4">
                <p>Please enter a valid postcode in the format 'AB12 3CD'.</p>
                <p
                  tw="text-indigo-600 hover:text-indigo-500 transition font-medium cursor-pointer mt-4"
                  onClick={() => {
                    setState("manualEntry")
                  }}
                >
                  Enter my address manually
                </p>
              </Message>
            )}
          </form>
          <div tw="mt-4">
            {state === "addressFound" && (
              <AddressesFoundForm address={address} setState={setState} />
            )}
            {state === "manualEntry" && (
              <CustomAddressForm postcode={watch("postcode")} />
            )}
            {state === "noAddressFound" && (
              <CustomAddressForm showNotFound postcode={watch("postcode")} />
            )}
          </div>
        </CardContent>
      </Card>
    </KycStep>
  )
}

type AddressFoundFormProps = {
  address: DatumEither<
    Error,
    {
      __typename: "AddressLookupResult"
      display: string
    }[]
  >
  setState: (state: AddressEntryStatus) => void
}
const AddressesFoundForm = ({ address, setState }: AddressFoundFormProps) => {
  const renderPostcodes = refreshFold(
    () => null,
    () => <Spinner tw="m-5" />,
    (e: Error) => {
      setState("noAddressFound")
      return null
    },
    (r: { display: string }[]) => {
      if (r.length < 1) {
        setState("noAddressFound")
        return
      }
      return <SelectAddressForm r={r} setState={setState} />
    }
  )

  return <>{renderPostcodes(address)}</>
}

type AddressSelectItem = {
  display: string
}

type SelectAddress = {
  address: string
}

const SelectAddressForm = ({
  r,
  setState
}: {
  r: AddressSelectItem[]
  setState: (state: AddressEntryStatus) => void
}) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<SelectAddress>()

  const onSubmit = (data: SelectAddress) => {
    submitKYC(data)
  }

  const {
    status,
    execute: submit,
    complete,
    completionStatus
  } = useUpdateOrCreateKycSubmission()

  const submitKYC = (submission: SelectAddress) => {
    submit({ address: submission.address }).then(complete)
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormField
          id={"address"}
          label={"Address"}
          error={errors.address}
          control={
            <Controller
              name="address"
              control={control}
              rules={{
                required: "Please select an address."
              }}
              render={({ onChange, value }) => (
                <select
                  onChange={onChange}
                  value={value}
                  defaultValue=""
                  tw="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-200 focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                >
                  <option value="" disabled hidden>
                    Select an address
                  </option>
                  {r.map((a, i) => (
                    <option key={`address-${i}`}>{a.display}</option>
                  ))}
                </select>
              )}
            />
          }
        />
        {watch("address") && (
          <div tw="bg-indigo-50 px-4 py-2 rounded-md mb-4">
            <p tw="text-indigo-500 font-medium text-sm">
              Selected: {watch("address")}
            </p>
          </div>
        )}
        <p
          tw="text-indigo-600 hover:text-indigo-500 transition text-sm cursor-pointer underline font-semibold"
          onClick={() => {
            setState("manualEntry")
          }}
        >
          My address isn't listed here
        </p>
        <ContinueButton status={status} completionStatus={completionStatus} />
      </form>
    </>
  )
}

type CustomAddress = {
  addressLine1: string
  addressLine2: string
  city: string
  postcode: string
  country: string
}

const CustomAddressForm = ({
  showNotFound,
  postcode
}: {
  showNotFound?: boolean
  postcode?: string
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm<CustomAddress>({
    defaultValues: {
      country: "United Kingdom"
    }
  })

  if (postcode) {
    setValue("postcode", postcode, { shouldValidate: true })
  }

  const formatAddressData = (data: CustomAddress) => {
    if (data.addressLine2) {
      return `${data.addressLine1}, ${data.addressLine2}, ${data.city}, ${data.postcode}, ${data.country}`
    } else {
      return `${data.addressLine1}, ${data.city}, ${data.postcode}, ${data.country}`
    }
  }

  const onSubmit = (data: CustomAddress) => {
    submitKYC(formatAddressData(data))
  }

  const {
    status,
    execute: submit,
    complete,
    completionStatus
  } = useUpdateOrCreateKycSubmission()

  const submitKYC = (address: string) => {
    submit({
      address
    }).then(complete)
  }

  return (
    <>
      {showNotFound && (
        <Message
          colour="grey"
          tw="my-4"
          title="We couldn't find your address. Please enter it manually."
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormField
          id={"addressLine1"}
          label={"Address Line 1 *"}
          error={errors.addressLine1}
          control={
            <Controller
              name="addressLine1"
              control={control}
              rules={{
                required: "This field is required."
              }}
              render={({ onChange, value }) => (
                <TextInput
                  id="addressLine1"
                  type="text"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          }
        />
        <FormField
          id={"addressLine2"}
          label={"Address Line 2"}
          error={errors.addressLine2}
          control={
            <Controller
              name="addressLine2"
              control={control}
              render={({ onChange, value }) => (
                <TextInput
                  id="addressLine2"
                  type="text"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          }
        />
        <FormField
          id={"city"}
          label={"Town/City *"}
          error={errors.city}
          control={
            <Controller
              name="city"
              control={control}
              rules={{
                required: "This field is required."
              }}
              render={({ onChange, value }) => (
                <TextInput
                  id="city"
                  type="text"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          }
        />
        <FormField
          id={"postcode"}
          label={"Postcode/Zipcode *"}
          error={errors.postcode}
          control={
            <Controller
              name="postcode"
              control={control}
              rules={{
                required: "This field is required."
              }}
              render={({ onChange, value }) => (
                <TextInput
                  id="postcode"
                  type="text"
                  onChange={onChange}
                  value={value}
                  defaultValue={postcode}
                />
              )}
            />
          }
        />
        <FormField
          id={"country"}
          label={"Country *"}
          error={errors.country}
          control={
            <Controller
              name="country"
              control={control}
              rules={{
                required: "This field is required."
              }}
              render={({ onChange, value }) => (
                <TextInput
                  id="country"
                  type="text"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          }
        />
        <ContinueButton status={status} completionStatus={completionStatus} />
      </form>
    </>
  )
}

type ContinueButtonProps = {
  status: DatumEither<Error, void>
  completionStatus: DatumEither<Error, void>
}
const ContinueButton = ({ status, completionStatus }: ContinueButtonProps) => {
  return (
    <div tw="flex justify-end">
      <FormButton
        type="submit"
        disabled={isPending(status) || isPending(completionStatus)}
      >
        {isPending(status) || isPending(completionStatus) ? (
          <Spinner />
        ) : (
          <>Continue</>
        )}
      </FormButton>
    </div>
  )
}
