
import { useIntercom } from "react-use-intercom"
import 'twin.macro'

import { Card, CardContent } from "../../../../../elements"
import { ComponentWithCase } from "../../CaseDetails"

export const CancelledDetailsPanel: ComponentWithCase = ({ case: cas }) => {
  const { show } = useIntercom()

  return (
    <Card>
      <CardContent tw="text-sm leading-5 text-gray-900 font-medium">
        <p>
          This case has been cancelled.
        </p>

        <p tw="mt-4">
          If you wish to continue with this case,

          please <button onClick={show} tw="underline font-bold">contact support</button> and
          provide the case number <span tw="font-bold">{cas.friendlyId}</span>.
        </p>

      </CardContent>
    </Card>
  )
}
