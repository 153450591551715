import { useAnalytics } from "../../hooks/useAnalytics";
import { CaseInteractionEvent, CaseInteractionEventProps } from "./types";

export const useCaseInteractionEvent = () => {
    const { trackEvent } = useAnalytics();

    return ( properties: CaseInteractionEventProps ) => {
        trackEvent(buildCaseInteractionEvent(properties));
    }
}

const buildCaseInteractionEvent = (properties: CaseInteractionEventProps): CaseInteractionEvent => ({
    action: "submitted",
    target: "case-interaction",
    description: "Case interaction",
    properties
})  