import { Variant } from "@practical-fp/union-types"
import {
  CompletedPayment,
  ExternalFee,
  LawhiveFee,
  PricedLawhiveFee,
  PricedPayable,
  PricedPayableWithExternalFees,
  StripeChargeType
} from "../../../hooks/useGetQuotePricing"
import { NonZeroPriceUnits, Percentage, PriceUnits, UUID } from "../../../lib/Primitives"
import * as D from "io-ts/Decoder"

export type PaymentStatus =
  | "paid"
  | "not-captured"
  | "refunded"
  | "failed"
  | "cancelled"
  | "capture-requested"

export type QuoteType =
  | "ClientAuthorisation"
  | "SolicitorPayment"
  | "OffPlatformPayment"

export type QuotePaymentType =
  | ClientAuthorisation
  | SolicitorPayment
  | OffPlatformPayment

type ClientPaymentTypeTag =
  | "ClientAuthorisation"
  | "SolicitorPayment"
  | "OffPlatformPayment"

interface AbstractQuotePaymentType {
  type: ClientPaymentTypeTag
}

export interface ClientAuthorisation extends AbstractQuotePaymentType {
  type: "ClientAuthorisation"
  isCancelled: boolean
  captureRequested: boolean
}

export interface SolicitorPayment extends AbstractQuotePaymentType {
  type: "SolicitorPayment"
  solicitor: UUID
  chargeAccount: UUID
  stripeAccountId: string
  completedPayment?: CompletedPayment
  chargeType: StripeChargeType
  price: PricedPayableWithExternalFees
}

export interface OffPlatformPayment extends AbstractQuotePaymentType {
  type: "OffPlatformPayment"
  source: string
}

// TODO These types were all sloppily hacked in, this needs sorting

export type ClientPaymentToSolicitor = {
  clientId: UUID
  // TODO Make non nullable?
  solicitor?: UUID
  // TODO Make non nullable?
  chargeAccount?: UUID
  stripeAccountId: string
  payment?: CompletedPayment
  chargeType: StripeChargeType
  price: PricedPayable
}

export type QuoteFee = {
  amount: Number
  id: string
  key: string
  description: string
  structure: {
    tag: string
    value: {
      percentage: Number
    }
  }
}

export type QuotePayment = {
  id: string
  payment: {
    id: string
    createdAt: Date
    updatedAt: Date
    stripePaymentIntentId: string
    amount: number
    status: PaymentStatus
  }
  createdAt: string
  type: QuotePaymentType
}

type LegacyCaseQuote = {
  caseId: string
  lineItems: LegacyCaseLineItemDTO[]
  scopes: ScopeItemDTO[]
}

export type ScopeItemDTO = {
  type: "inclusion" | "exclusion"
  title: string
}

export type LegacyCaseLineItemDTO = {
  description: string
  amount: PriceUnits
}

// TODO Remove
export type CaseQuoteDTO = Variant<"Legacy", LegacyCaseQuote>

export type QuotePriceDetails = {
  payableAmount: NonZeroPriceUnits
  feeChargeableAmount: PriceUnits
  lawhiveFeeTotal: PriceUnits
  lawhiveFees: PricedLawhiveFee[]
  externalFees: ExternalFee[]
  discounts: PricedLawhiveDiscount[]
}

export const DiscountSide = D.literal("payer")
export type DiscountSide = D.TypeOf<typeof DiscountSide>

export const PercentageDiscount = D.struct({
  type: D.literal("percentage" as const),
  amount: Percentage
})
export type PercentageDiscount = D.TypeOf<typeof PercentageDiscount>

export const AbsoluteDiscount = D.struct({
  type: D.literal("absolute" as const),
  amount: PriceUnits
})
export type AbsoluteDiscount = D.TypeOf<typeof AbsoluteDiscount>

export const DiscountDetails = D.union(PercentageDiscount, AbsoluteDiscount)
export type DiscountDetails = D.TypeOf<typeof DiscountDetails>

export const LawhiveDiscount = D.struct({
  details: DiscountDetails,
  side: DiscountSide
})

export type Priced<T> = T & {
  amount: PriceUnits
}

export type LawhiveDiscount = D.TypeOf<typeof LawhiveDiscount>

export type PricedLawhiveDiscount = Priced<LawhiveDiscount>

export type QuoteLineItem = {
  id: string
  name: string
  amount: number
  type: FeeType
}

export type FeeType = "disbursement" | "fee"
export type QuoteStatus = "unpaid" | "paid"

export type QuoteScopeItem = {
  id: string
  type: "inclusion" | "exclusion"
  title: string
}

// TODO This is an old data model, needs replacing
export type Quote = {
  _id: UUID
  status: QuoteStatus
  lineItems: QuoteLineItem[]
  feeChargeableAmount: PriceUnits
  nonFeeChargeableAmount: PriceUnits
  scopes: QuoteScopeItem[]
  discounts: LawhiveDiscount[]
  fees: LawhiveFee[]
  externalFees: ExternalFee[]
  quotePayments: QuotePayment[]
  // TODO
  price: QuotePriceDetails
}

export type QuoteBase = {
  _id: UUID
  status: QuoteStatus
  lineItems: QuoteLineItem[]
  feeChargeableAmount?: PriceUnits
  scopes: QuoteScopeItem[]
  discounts: LawhiveDiscount[]
  quotePayments: QuotePayment[]
  price: QuotePriceDetails
  groups: any[]
  notesForSolicitor: string
  notesFromSolicitor: string
  owner: {
    tag: string
    value: UUID
  }
  type: {
    creator: string
    type: string
  }
  updatedAt: string
  nonFeeChargeableAmount?: PriceUnits
  fees?: LawhiveFee[]
  externalFees?: ExternalFee[]
}

export type UnparsedQuoteEntityV2 = QuoteBase & {
  expiresAt: string
}

export type QuoteEntityV2 = QuoteBase & {
  expiresAt: Date
}

