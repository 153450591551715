import { DatumEither, initial } from "@nll/datum/DatumEither"
import { Option } from "fp-ts/es6/Option"
import { flow, pipe } from "fp-ts/lib/function"
import * as O from "fp-ts/lib/Option"
import { none } from "fp-ts/lib/Option"
import * as TE from "fp-ts/lib/TaskEither"
import { createContext, FC, useContext, useEffect, useState } from "react"
import { useUser } from "../contexts/User"
import { castTo, fetchParsedJSON, RequestError } from "../lib/fetch"
import { useApiFetch } from "../lib/useApiClient"
import { useFromTaskEither } from "../lib/useAsync"
import { useLocalStorageNew } from "../lib/useLocalStorage"
import { noop } from "../lib/utils"

type FeatureFlags = Record<string, any>

type FeatureFlagContext = {
  state: DatumEither<Error | RequestError, FeatureFlags>,
  flags: Option<FeatureFlags>
  monthlyBilling: Option<MonthlyBillingConfig>
  solicitorAffiliateLinks: Option<SolicitorAffiliateLinksConfig>
  refresh: () => void
}

export type MonthlyBillingConfig = {
  enabled: boolean
  enabledUsers: string[]
}

export type SolicitorAffiliateLinksConfig = {
  enabled: boolean
}

const FeatureFlagContext = createContext<FeatureFlagContext>({
  state: initial,
  flags: none,
  monthlyBilling: none,
  solicitorAffiliateLinks: none,
  refresh: noop
})

export const useFeatureFlags = () => useContext<FeatureFlagContext>(FeatureFlagContext)

export const FeatureFlagsProvider: FC = ({ children }) => {

  type FlagResponse = {
    flags?: FeatureFlags
    token: string
    change: boolean
  }

  const api = flow(fetchParsedJSON, useApiFetch)
  const fetchAsString = pipe(castTo<string | undefined>(), api)
  const fetchAsFlagResponse = pipe(castTo<FlagResponse>(), api)

  const [token, setToken] = useLocalStorageNew<string | undefined>('@lawhive/flags-v1/token', undefined)
  const [flags, setFlags] = useLocalStorageNew<FeatureFlags | undefined>('@lawhive/flags-v1/flags', undefined)

  const startFeatureFlagSession = () => pipe(
    fetchAsString(`feature-flags/start`, { method: "POST" }),
    TE.map(O.fromNullable),
    TE.chainW(TE.fromOption(() => new Error("No token received"))),
    TE.map(token => {
      setToken(token)
      return token
    })
  )

  const updateFeatureFlagSession = (response: FlagResponse) => {
    setToken(response.token)
    if(response.change && response.flags) {
      setFlags(response.flags)
    }

    return response
  }

  const getFeatureFlagsSessionAndUpdate = (token: string) => pipe(
    fetchAsFlagResponse(`feature-flags/flags`, { method: "POST", body: JSON.stringify({ token }) }),
    TE.map(updateFeatureFlagSession),
    TE.map(r => r.change ? r.flags : flags),
    TE.map(O.fromNullable),
    TE.chainW(TE.fromOption(() => new Error('Empty flags retrieved')))
  )

  const startSessionAndGetFlags = flow(
    startFeatureFlagSession,
    TE.chainW(getFeatureFlagsSessionAndUpdate)
  )

  const getFeatureFlags = () => pipe(
    O.fromNullable(token),
    O.matchW(
      startSessionAndGetFlags,
      getFeatureFlagsSessionAndUpdate
    ),
    TE.alt(startSessionAndGetFlags)
  )

  const { status, execute } = useFromTaskEither(getFeatureFlags)

  useEffect(() => {
    execute()
  }, [])

  const flagsOption = O.fromNullable(flags)

  const monthlyBillingFlag = pipe(
    flagsOption,
    O.map(flags => flags.monthly_billing_enabled as MonthlyBillingConfig)
  )

  const solicitorAffiliateLinksFlag = pipe(
    flagsOption,
    O.map(flags => flags.solicitor_affiliate_links_enabled as SolicitorAffiliateLinksConfig)
  )


  const context: FeatureFlagContext = {
    state: status,
    flags: flagsOption,
    monthlyBilling: monthlyBillingFlag,
    solicitorAffiliateLinks: solicitorAffiliateLinksFlag,
    refresh: () => execute()
  }

  return (
    <FeatureFlagContext.Provider value={context}>
      {children}
    </FeatureFlagContext.Provider>
  )
}

export const useIsMonthlyBillingEnabled = () => {
  const { userId } = useUser()
  const { monthlyBilling } = useFeatureFlags()

  return O.isSome(monthlyBilling)
    && monthlyBilling.value.enabled
    && (monthlyBilling.value.enabledUsers.includes(userId)
      || monthlyBilling.value.enabledUsers.includes("*")
    )
}

export const useIsSolicitorAffiliateLinksEnabled = () => {

  const { solicitorAffiliateLinks } = useFeatureFlags()

  return O.isSome(solicitorAffiliateLinks)
    && solicitorAffiliateLinks.value.enabled

}
