import { FC } from "react"
import tw from "twin.macro"

import { FormField } from "../../../elements"
import { ScopeItemDTO } from "../types"

type WorkScopeListProps = {
  scopes: ScopeItemDTO[]
}

export const QuoteScopesTable: FC<WorkScopeListProps> = ({
  scopes,
  ...rest
}) => {
  const includes = scopes
    .filter((s) => s.type === "inclusion")
    .map((s) => s.title)

  const excludes = scopes
    .filter((s) => s.type === "exclusion")
    .map((s) => s.title)

  return (
    <div tw="grid grid-cols-1 gap-4 sm:(grid-cols-2 gap-4)" {...rest}>
      {includes.length > 0 && (
        <FormField
          tw="mb-0"
          id={"includes"}
          label={"Includes:"}
          control={<InclusionsList items={includes} />}
        />
      )}

      {excludes.length > 0 && (
        <FormField
          id={"excludes"}
          label={"Does not include:"}
          control={<ExclusionsList items={excludes} />}
        />
      )}
    </div>
  )
}

type ListProps = {
  items: string[]
}

const List = tw.ul`flex flex-col gap-1`
const ListItem = tw.li`flex flex-row items-start font-semibold text-sm`
const Icon = tw.svg`flex-shrink-0 h-5 w-5`

const InclusionsList: FC<ListProps> = ({ items, ...rest }) => (
  <List {...rest}>
    {items.map((l, i) => (
      <ListItem key={i}>
        <CheckIcon tw="mr-2 text-green-500" />
        {l}
      </ListItem>
    ))}
  </List>
)

const ExclusionsList: FC<ListProps> = ({ items, ...rest }) => (
  <List {...rest}>
    {items.map((l, i) => (
      <ListItem key={i}>
        <CrossIcon tw="mr-2 text-red-500" />
        {l}
      </ListItem>
    ))}
  </List>
)

const CheckIcon: FC = (props) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
      clipRule="evenodd"
    />
  </Icon>
)

const CrossIcon: FC = (props) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z"
      clipRule="evenodd"
    />
  </Icon>
)
