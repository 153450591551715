import { CheckCircleIcon } from '@heroicons/react/outline'
import { addMinutes, compareAsc, compareDesc, format } from 'date-fns'
import * as D from 'io-ts/lib/Decoder'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import 'twin.macro'
import { TickIcon } from '../../elements/Icons'
import { Message } from '../../elements/Message'
import { SidebarLayout, SidebarMenu, SidebarMenuItem } from '../../elements/Sidebar'
import { ContentContainer, PageContainer } from '../../layout/Layout'
import { ROUTES, withOptions } from '../../layout/Navigation'
import { fetchAndCast, fetchParsedJSON } from '../../lib/fetch'
import { DateFromString, decodeOrError, withOptional } from '../../lib/io'
import { useApiFetch } from '../../lib/useApiClient'
import { useFromTaskEither } from '../../lib/useAsync'
import { useQueryStringState } from '../../lib/useQueryStringState'
import { useRouter } from '../../lib/useRouter'
import { renderLoadingRefreshOrSuccess } from '../../lib/utils'
import { CaseListRenderer } from '../case/list/CaseListRenderer'
import { SkeletonCaseList } from '../case/list/SkeletonCaseList'

export const AssessmentCallStatus = D.literal('pending-client-selection', 'pending-solicitor-confirmation', 'booked', 'completed', 'closed')
export type AssessmentCallStatus = D.TypeOf<typeof AssessmentCallStatus>

export const WhenParameter = D.literal('upcoming', 'past')
export type WhenParameter = D.TypeOf<typeof WhenParameter>

export const AssessmentCallUIListResponse = withOptional(
  D.struct({
    id: D.string,
    status: AssessmentCallStatus,
    leadName: D.string,
  }),
  {
    solicitorSelection: DateFromString
  }
)

export type AssessmentCallUIListResponse = D.TypeOf<typeof AssessmentCallUIListResponse>

export const AssessmentCallsList = () => {
  const [status] = useQueryStringState<AssessmentCallStatus>('status', 'pending-solicitor-confirmation')
  const [when] = useQueryStringState<WhenParameter>('when', undefined)
  const router = useRouter<{ rejectedClient: string }>()
  const [rejectedClientName, setShowMessage] = useState<string | undefined>(() => router.params.rejectedClient)

  const sort: SortParameter = status === 'completed'
    ? 'descending'
    : status === 'booked'
      ? when === 'past'
        ? 'descending'
        : 'ascending'
      : 'ascending'

  return (
    <PageContainer>
      <ContentContainer tw="space-y-8">
        {rejectedClientName && (
          <Message
            icon={<TickIcon />}
            colour='primary'
            title='Rejected Call'
            onDismiss={() => setShowMessage(undefined)}
            dismissable
            bold
            >
            Rejected client {rejectedClientName}
          </Message>
        )}

        <SidebarLayout
          menu={<StatusSelectMenu status={status} when={when} />}
          content={
            status === 'pending-solicitor-confirmation'
              ? <NeedsBookingList status={status} />
              : <BookedList status={status || 'pending-solicitor-confirmation'} when={when} sort={sort} />
          }
        />
      </ContentContainer>
    </PageContainer>
  )
}


const StatusSelectMenu: FC<{ status?: AssessmentCallStatus, when?: WhenParameter }> = ({ status, when }) => {

  const items = useMemo((): SidebarMenuItem[] => ([
    {
      label: 'Assessment Calls',
      items: [
        {
          label: 'Needs Booking',
          to: `?status=${'pending-solicitor-confirmation'}`,
          active: status === 'pending-solicitor-confirmation',
          icon: (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <rect x="4" y="5" width="16" height="16" rx="2" />
              <line x1="16" y1="3" x2="16" y2="7" />
              <line x1="8" y1="3" x2="8" y2="7" />
              <line x1="4" y1="11" x2="20" y2="11" />
              <line x1="10" y1="16" x2="14" y2="16" />
              <line x1="12" y1="14" x2="12" y2="18" />
            </svg>
          )
        },
        {
          label: 'Upcoming',
          to: `?status=${'booked'}&when=${'upcoming'}`,
          active: status === 'booked' && when ==='upcoming',
          icon: (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path d="M11.795 21h-6.795a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v4" />
              <circle cx="18" cy="18" r="4" />
              <path d="M15 3v4" />
              <path d="M7 3v4" />
              <path d="M3 11h16" />
              <path d="M18 16.496v1.504l1 1" />
            </svg>
          )
        },
        {
          label: 'Needs Quoting',
          to: `?status=${'booked'}&when=${'past'}`,
          active: status === 'booked' && when === 'past',
          icon: (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <polyline points="12 8 12 12 14 14" />
              <path d="M3.05 11a9 9 0 1 1 .5 4m-.5 5v-5h5" />
            </svg>
          )
        },
        {
          label: 'Completed',
          to: `?status=${'completed'}`,
          active: status === 'completed',
          icon: (
            <CheckCircleIcon />
          )
        }
      ]
    }
  ]), [status, when])

  return (
    <SidebarMenu
      tw="mb-4"
      items={items}
    />
  )
}

type NeedsBookingListItem = {
  id: string
  leadName: string
}

const NeedsBookingList: FC<{ status: AssessmentCallStatus }> = ({ status }) => {
  const api = useApiFetch(fetchAndCast<AssessmentCallUIListResponse[]>())

  const { status: cases, execute } = useFromTaskEither(
    (status: AssessmentCallStatus) => api(`assessment-calls/list?status=${status}`)
  )

  const render = renderLoadingRefreshOrSuccess(
    () => <SkeletonCaseList columns={['Lead', '']} />,
    (r: AssessmentCallUIListResponse[]) => {
      const data: NeedsBookingListItem[] = r.map(
        (c): NeedsBookingListItem => ({
          id: c.id,
          leadName: c.leadName
        })
      )

      return (
        <CaseListRenderer
          data={data}
          selectable={{ key: 'id', urlGenerator: s => ROUTES.assessmentCalls.details(s) }}
          options={[
            {
              label: 'Lead',
              accessor: 'leadName',
              render: value => <span tw="leading-5 font-medium text-gray-900">{value}</span>
            },
            {
              label: '',
              id: 'details',
              render: () => (
                <p tw="text-indigo-600 hover:text-indigo-900 text-right text-sm leading-5 font-medium">
                  Details
                </p>
              ),
              renderCard: d => (
                <Link
                  to={ROUTES.assessmentCalls.details(d.id)}
                  tw="inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-green-500 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-400 focus:outline-none focus:border-green-700 focus:ring-green-200 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  View Call Details
                </Link>
              )
            }
          ]}
        />
      )
    }
  )

  useEffect(() => { execute(status) }, [status])

  return render(cases)
}

type BookedListItem = {
  id: string
  leadName: string
  when: Date
}

type SortParameter = 'ascending' | 'descending'

const BookedList: FC<{ status: AssessmentCallStatus, when?: WhenParameter, sort: SortParameter }> = ({ status, when, sort }) => {
  const api = useApiFetch(fetchParsedJSON(decodeOrError(D.array(AssessmentCallUIListResponse))))

  const { status: cases, execute } = useFromTaskEither(
    (status: AssessmentCallStatus, when?: WhenParameter) => api(
      withOptions(`assessment-calls/list`, { status, when })
    )
  )

  const sortFunction = sort === 'ascending' ? compareAsc : compareDesc

  const render = renderLoadingRefreshOrSuccess(
    () => <SkeletonCaseList columns={['Lead', '']} />,
    (r: AssessmentCallUIListResponse[]) => {
      const data: BookedListItem[] = r.map(
        (c): BookedListItem => ({
          id: c.id,
          leadName: c.leadName,
          when: c.solicitorSelection!
        })
      )
      .sort((a, b) => sortFunction(a.when, b.when))

      return (
        <CaseListRenderer
          data={data}
          selectable={{ key: 'id', urlGenerator: s => ROUTES.assessmentCalls.details(s) }}
          options={[
            {
              label: 'Lead',
              accessor: 'leadName',
              render: value => <span tw="leading-5 font-medium text-gray-900">{value}</span>
            },
            {
              label: 'Time',
              accessor: 'when',
              render: value => (
                <span tw="leading-5 font-medium text-gray-900">
                  {format(value, 'h:mmaaa')} - {format(addMinutes(value, 15), 'h:mmaaa')}<br />
                  {format(value, 'EEE do MMM yyyy')}<br />
                </span>
              )
            },
            {
              label: '',
              id: 'details',
              render: () => (
                <p tw="text-indigo-600 hover:text-indigo-900 text-right text-sm leading-5 font-medium">
                  Details
                </p>
              ),
              renderCard: d => (
                <Link
                  to={ROUTES.assessmentCalls.details(d.id)}
                  tw="inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-green-500 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-400 focus:outline-none focus:border-green-700 focus:ring-green-200 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  View Call Details
                </Link>
              )
            }
          ]}
        />
      )
    }
  )

  useEffect(() => { execute(status, when) }, [status, when])

  return render(cases)
}
