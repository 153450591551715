import 'twin.macro'
import { FC } from 'react'

import { Card, CardContent, CardTitle } from '../../../../elements'
import { ModalText } from '../../../../elements/Modal'
import { Button } from '../../../../elements/Button'

import { AssessmentCallUIDetailsResponse } from '../../hooks/useAssessmentCallDetails'
import { addMinutes, format } from 'date-fns'

type BookCallModalProps = {
  isLoading: boolean,
  call: AssessmentCallUIDetailsResponse
  slot: Date
  onCancel: () => void,
  onBook: () => void,
}

export const BookCallModal: FC<BookCallModalProps> = ({ onBook, call, slot, isLoading }) => {
  return (
    <div tw="max-w-xl">
      <Card>
        <CardContent>
          <CardTitle>
            Are you sure?
          </CardTitle>

          <ModalText tw="mt-4">
            <p>
              This will schedule a call with the client:<br />
              <span tw="font-bold">{call.leadName}</span>
            </p>
            <p tw="mt-2">
              At:
              <p tw="font-bold">
                {format(slot, 'h:mm')}-{format(addMinutes(slot, 15), 'h:mmaaa')}<br />
                {format(slot, 'do MMM yyyy')}
              </p>
            </p>
            <p tw="mt-4">
              Please make a note of this in your diary.
            </p>
          </ModalText>

        </CardContent>

        <CardContent tw="pt-0">
          <Button variant='positive' onClick={onBook} isLoading={isLoading}>
            Book for {format(slot, 'h:mm')}-{format(addMinutes(slot, 15), 'h:mmaaa')}
          </Button>
        </CardContent>
      </Card>
    </div>
  )
}
