import { Popover } from "@headlessui/react"
import {
  ArrowDownIcon,
  ArrowUpIcon,
  PencilIcon,
  PlusCircleIcon,
  TrashIcon
} from "@heroicons/react/outline"
import { refreshFold } from "@nll/datum/DatumEither"
import {
  impl,
  matchExhaustive,
  matchWildcard,
  Variant,
  WILDCARD
} from "@practical-fp/union-types"
import { flow, pipe } from "fp-ts/es6/function"
import produce from "immer"
import { QuoteBuilderSubshell } from "packages/app/src/components/assessment-call/complete/QuoteBuilderSubshell"
import {
  FeeTypeBadge,
  sumQuoteItems
} from "packages/app/src/components/assessment-call/complete/QuoteCard"
import { QuoteLineItemFeeType } from "packages/app/src/components/assessment-call/hooks/useCreateSolicitorQuote"
import { useDraftQuote } from "packages/app/src/components/assessment-call/hooks/useDraftQuote"
import { FeeGuideLineItem } from "packages/app/src/components/assessment-call/hooks/useFeeGuide"
import { WithID } from "packages/app/src/components/list-builder/useListState"
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  FormButtonGroup,
  FormFieldNew,
  TextArea,
  TextInput
} from "packages/app/src/elements"
import { Badge } from "packages/app/src/elements/Badge"
import { Button, ButtonLink } from "packages/app/src/elements/Button"
import { FormattedCurrencyUnits } from "packages/app/src/elements/Currency"
import { CurrencyInput } from "packages/app/src/elements/CurrencyInput"
import { RadioGroup } from "packages/app/src/elements/RadioGroup"
import {
  PlainDataTable,
  TableColumn
} from "packages/app/src/elements/table/PlainDataTable"
import { TableCell } from "packages/app/src/elements/table/Table"
import {
  SlideOutForm,
  SlideOutMenu,
  SlideOutMenuHeader,
  SlideOutMenuRenderProps
} from "packages/app/src/layout/SlideOutMenu"
import { ErrorSubshell } from "packages/app/src/layout/subshells/ErrorSubshell"
import { asListState } from "packages/app/src/lib/asListState"
import { useRouter } from "packages/app/src/lib/useRouter"
import { useSelector } from "packages/app/src/lib/useSelector"
import {
  ButtonHTMLAttributes,
  FC,
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react"
import {
  Controller,
  FieldError,
  useForm,
  UseFormMethods
} from "react-hook-form"
import { useIntl } from "react-intl"
import { usePopper } from "react-popper"
import { useParams } from "react-router-dom"
import tw from "twin.macro"
import { AssessmentCallsDetailsSkeleton } from "../../../components/assessment-call/details/Skeleton"
import {
  AssessmentCallUIDetailsResponse,
  useAssessmentCallDetails
} from "../../../components/assessment-call/hooks/useAssessmentCallDetails"
import { ROUTES } from "../../../layout/Navigation"
import { ContentLayoutFlow } from "../../../layout/subshells/DetailsSubshell"
import { RequestError } from "../../../lib/fetch"

export const AssessmentCallCustomiseQuoteScene: FC = () => {
  const { id } = useParams<{ id: string }>()
  const { status, execute } = useAssessmentCallDetails()

  const render = refreshFold(
    () => <AssessmentCallsDetailsSkeleton />,
    () => <AssessmentCallsDetailsSkeleton />,
    (e: RequestError) =>
      matchWildcard(e, {
        ResponseError: (e) =>
          e.statusCode === 401 ? (
            <ErrorSubshell title={`You don't have access to this call`} />
          ) : (
            <ErrorSubshell title={`Error getting call details ${e.details}`} />
          ),
        [WILDCARD]: () => (
          <ErrorSubshell
            title={`Error getting call details, please check your network or refresh`}
          />
        )
      }),
    (call: AssessmentCallUIDetailsResponse) => (
      <QuoteBuilderSubshell
        backToText="Back to assessment calls"
        backToUrl={ROUTES.assessmentCalls.details(call.id)}
        clientName={call.leadName}
        summaryHeader="Client's Summary"
        matterSummary={call.requestDetails || ""}
        step={"send"}
        content={<Content callId={call.id} />}
      />
    )
  )

  useEffect(() => {
    execute(id)
  }, [id])

  return render(status)
}

type ScopeItem = { title: string }

type State =
  | Variant<"Closed">
  | Variant<"CreatingLineItem">
  | Variant<"EditingLineItem", WithID<FeeGuideLineItem>>
  | Variant<"CreatingIncludes">
  | Variant<"EditingIncludes", WithID<ScopeItem>>
  | Variant<"CreatingExcludes">
  | Variant<"EditingExcludes", WithID<ScopeItem>>

const State = impl<State>()

const includeExamples = [
  "Prepare possession proceedings",
  "Drafting and preparation of documents",
  "Review evidence (up to 3 pages)"
]

const excludeExamples = [
  "Negotiation between parties",
  "Additional work (additional fixed fees apply)"
]
const lineItemExamples = [
  "Providing Advice and Drafting Document",
  "Filing with HMLR"
]

const Content: FC<{ callId: string }> = ({ callId }) => {
  const intl = useIntl()

  const { draft, setDraft } = useDraftQuote(callId)

  const includes = pipe(
    useSelector(
      [draft, setDraft],
      (q) => q.includes || [],
      (state, includes) =>
        produce(state, (draft) => {
          draft.includes = includes
          draft.type = "custom"
        })
    ),
    ([items, setItems]) => asListState(items, setItems)
  )

  const excludes = pipe(
    useSelector(
      [draft, setDraft],
      (q) => q.excludes || [],
      (state, excludes) =>
        produce(state, (draft) => {
          draft.excludes = excludes
          draft.type = "custom"
        })
    ),
    ([items, setItems]) => asListState(items, setItems)
  )

  const [notes, setNotes] = pipe(
    useSelector(
      [draft, setDraft],
      (q) => q.notes || undefined,
      (state, notes) =>
        produce(state, (draft) => {
          draft.notes = notes
          draft.type = "custom"
        })
    ),
    (a) => a
    // ([notes, setNotes]) => asTextState(notes, setNotes)
  )

  const lineItems = pipe(
    useSelector(
      [draft, setDraft],
      (q) => q.lineItems || [],
      (state, lineItems) =>
        produce(state, (draft) => {
          draft.lineItems = lineItems
          draft.type = "custom"
        })
    ),
    ([items, setItems]) => asListState(items, setItems)
  )

  const [state, setState] = useState<State>(State.Closed())

  const createLineItem = () => {
    setState(State.CreatingLineItem())
  }

  const editLineItem = (item: WithID<FeeGuideLineItem>) => {
    setState(State.EditingLineItem(item))
  }

  const createInclude = () => {
    setState(State.CreatingIncludes())
  }

  const editInclude = (item: WithID<ScopeItem>) => {
    setState(State.EditingIncludes(item))
  }

  const createExclude = () => {
    setState(State.CreatingExcludes())
  }

  const editExclude = (item: WithID<ScopeItem>) => {
    setState(State.EditingExcludes(item))
  }

  const withClose =
    (fn: Function) =>
    <T,>(i: T) => {
      setState(State.Closed())
      fn(i)
    }

  const { push } = useRouter()

  const error = draft.notes ? draft.notes.length < 25 : true

  const submit = () => {
    if (error) {
      ref.current?.focus()
    } else {
      push(ROUTES.assessmentCalls.complete(callId).quote.send)
    }
  }

  const ref = useRef<HTMLTextAreaElement>(null)
  return (
    <>
      <SlideOutMenu
        isOpen={!State.Closed.is(state)}
        onClose={() => setState(State.Closed())}
        content={(renderProps) =>
          matchExhaustive(state, {
            Closed: () => null,
            CreatingLineItem: () => (
              <LineItemSlideOutForm
                buttonText="Add"
                renderProps={renderProps}
                onSubmit={withClose(lineItems.addItem)}
                examples={lineItemExamples}
              />
            ),
            EditingLineItem: (item) => (
              <LineItemSlideOutForm
                buttonText="Save"
                item={item}
                renderProps={renderProps}
                onSubmit={withClose(lineItems.updateItem(item.id))}
                examples={lineItemExamples}
              />
            ),
            CreatingIncludes: () => (
              <ScopeSlideOutForm
                buttonText="Add"
                renderProps={renderProps}
                onSubmit={withClose(includes.addItem)}
                examples={includeExamples}
              />
            ),
            EditingIncludes: (item) => (
              <ScopeSlideOutForm
                buttonText="Save"
                item={item}
                renderProps={renderProps}
                onSubmit={withClose(includes.updateItem(item.id))}
                examples={includeExamples}
              />
            ),
            CreatingExcludes: () => (
              <ScopeSlideOutForm
                buttonText="Add"
                renderProps={renderProps}
                onSubmit={withClose(excludes.addItem)}
                examples={excludeExamples}
              />
            ),
            EditingExcludes: (item) => (
              <ScopeSlideOutForm
                buttonText="Save"
                item={item}
                renderProps={renderProps}
                onSubmit={withClose(excludes.updateItem(item.id))}
                examples={excludeExamples}
              />
            )
          })
        }
      />
      <ContentLayoutFlow>
        <Card>
          <CardContent tw="flex flex-col sm:flex-row gap-4 items-start sm:items-center justify-between">
            <div tw="flex flex-row gap-4 items-center">
              <div tw="flex flex-col gap-1">
                <h2 tw="font-medium text-gray-900 text-sm sm:text-base">
                  {draft.feeGuide ? (
                    <div tw="flex flex-col gap-2 items-start">
                      <div tw="overflow-ellipsis flex-shrink-0">
                        Customise this{" "}
                        <span tw="font-semibold">
                          {draft.feeGuide.product?.name}
                        </span>{" "}
                        Quote
                      </div>
                      {draft.feeGuide.variant?.name && (
                        <Badge variant="purple">
                          {draft.feeGuide.variant.shortName ||
                            draft.feeGuide.variant.name}
                        </Badge>
                      )}
                    </div>
                  ) : (
                    <>Custom Quote</>
                  )}
                </h2>
              </div>
            </div>
            <ButtonLink
              tw="flex-shrink-0"
              variant="colour-secondary"
              to={ROUTES.assessmentCalls.complete(callId).quote.build}
            >
              Change Product
            </ButtonLink>
          </CardContent>
        </Card>

        <Card>
          <CardHeader
            title="Line Items"
            supportingText="List each item in this quote as it would appear in an invoice."
            actions={
              <FormButtonGroup>
                <Button
                  variant="colour-secondary"
                  type="button"
                  onClick={createLineItem}
                >
                  <PlusCircleIcon tw="h-5 w-5" />
                  Add
                </Button>
              </FormButtonGroup>
            }
          />
          {lineItems.items.length > 0 ? (
            <LineItemsTable
              items={lineItems.items}
              onDelete={(i) => lineItems.deleteItem(i.id)}
              onEdit={editLineItem}
              onMoveUp={(i) => lineItems.moveUp(i.id)}
              onMoveDown={(i) => lineItems.moveDown(i.id)}
            />
          ) : (
            <CardContent>
              <EmptyState
                name="line items"
                button="Add Line Item"
                onClick={createLineItem}
              />
            </CardContent>
          )}
        </Card>

        <Card>
          <CardHeader
            title="Includes"
            supportingText="List what the client can expect you to provide when your work is completed."
            actions={
              <FormButtonGroup>
                <Button
                  variant="colour-secondary"
                  type="button"
                  onClick={() => createInclude()}
                >
                  <PlusCircleIcon tw="h-5 w-5" />
                  Add
                </Button>
              </FormButtonGroup>
            }
          />
          {includes.items.length > 0 ? (
            <ScopeTable
              items={includes.items}
              onDelete={(i) => includes.deleteItem(i.id)}
              onMoveUp={(i) => includes.moveUp(i.id)}
              onMoveDown={(i) => includes.moveDown(i.id)}
              onEdit={editInclude}
            />
          ) : (
            <CardContent>
              <EmptyState
                name="includes"
                button="Add Inclusion"
                onClick={createInclude}
              />
            </CardContent>
          )}
        </Card>

        <Card>
          <CardHeader
            title="Excludes"
            supportingText="List what the client shouldn't expect you to provide as part of this work."
            actions={
              <FormButtonGroup>
                <Button
                  variant="colour-secondary"
                  type="button"
                  onClick={() => createExclude()}
                >
                  <PlusCircleIcon tw="h-5 w-5" />
                  Add
                </Button>
              </FormButtonGroup>
            }
          />
          {excludes.items.length > 0 ? (
            <ScopeTable
              items={excludes.items}
              onDelete={(i) => excludes.deleteItem(i.id)}
              onEdit={editExclude}
              onMoveUp={(i) => excludes.moveUp(i.id)}
              onMoveDown={(i) => excludes.moveDown(i.id)}
            />
          ) : (
            <CardContent>
              <EmptyState
                name="excludes"
                button="Add Exclusion"
                onClick={createExclude}
              />
            </CardContent>
          )}
        </Card>

        <Card>
          <CardHeader
            title="Notes for Lawhive"
            supportingText="Enter notes for the Lawhive team here - these notes won't be visible to the client. Please be sure to provide a detailed description of the work you're requesting a quote for."
          />
          <CardContent>
            <TextArea
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              ref={ref}
            />
            <p tw="text-gray-600 text-sm">Must be at least 25 characters</p>
          </CardContent>
        </Card>

        <FormButtonGroup>
          <Button type="button" onClick={submit}>
            Finish Customising
          </Button>
        </FormButtonGroup>
      </ContentLayoutFlow>
    </>
  )
}

type FormProps<T> = Pick<UseFormMethods<T>, "register" | "control" | "errors">

type LineItemSlideOutFormProps = {
  item?: FeeGuideLineItem
  renderProps: SlideOutMenuRenderProps
  buttonText: string
  examples: string[]
  onSubmit: (item: FeeGuideLineItem) => void
}

type FeeGuideLineItemUI = Omit<FeeGuideLineItem, "name"> & {
  title: FeeGuideLineItem["name"]
}

const translateFeeGuideUIToDomain = (
  input: FeeGuideLineItemUI
): FeeGuideLineItem => ({
  feeType: input.feeType,
  name: input.title,
  price: input.price
})

const translateDomainToUI = (input: FeeGuideLineItem): FeeGuideLineItemUI => ({
  feeType: input.feeType,
  title: input.name,
  price: input.price
})

const LineItemSlideOutForm: FC<LineItemSlideOutFormProps> = ({
  buttonText,
  examples,
  renderProps,
  item,
  onSubmit
}) => {
  const { errors, register, control, handleSubmit, watch } =
    useForm<FeeGuideLineItemUI>({
      defaultValues: item && translateDomainToUI(item)
    })

  const name = watch("title") || ""

  const submit = flow(translateFeeGuideUIToDomain, onSubmit)

  return (
    <SlideOutForm
      renderProps={renderProps}
      onSubmit={handleSubmit(submit)}
      actions={({ close }) => (
        <>
          <Button type="button" variant="secondary" onClick={close}>
            Cancel
          </Button>
          <Button type="submit" variant="primary">
            Save
          </Button>
        </>
      )}
      content={({ close }) => (
        <div>
          <SlideOutMenuHeader
            title={item ? "Editing Line Item" : `Adding Line Item`}
            supportingText="This appears as a row in your invoice and describes a chargable item in this fee quote."
            onClose={close}
          />
          <div tw="flex flex-1 flex-col justify-between px-4 sm:px-6 py-6 gap-5">
            <div tw="space-y-6">
              <div tw="space-y-3">
                <FormFieldNew
                  id="title"
                  label="Title"
                  error={errors.title as FieldError}
                  control={
                    <TextInput
                      type="text"
                      id="title"
                      name="title"
                      autoComplete="off"
                      ref={register({ required: true, maxLength: 200 })}
                    />
                  }
                  hint={
                    <div tw="flex flex-row">
                      <p tw="flex-1">Use short but descriptive language</p>
                      <p>{name.length}/200</p>
                    </div>
                  }
                />
                <ExamplePanel examples={examples} />
              </div>

              <FormFieldNew
                id="feeType"
                label="Fee Type"
                error={errors.feeType as FieldError}
                control={
                  <Controller
                    name="feeType"
                    control={control}
                    rules={{ required: "Please select an option." }}
                    render={({ onChange, value }) => (
                      <RadioGroup<QuoteLineItemFeeType>
                        groupName="feeType"
                        setValue={onChange}
                        value={value}
                        items={[
                          {
                            label: `Fee`,
                            value: "fee",
                            description: `Funds are intended for solicitor.`
                          },
                          {
                            label: `Disbursement`,
                            value: "disbursement",
                            description: `Funds are intended for another party.`
                          }
                        ]}
                      />
                    )}
                  />
                }
              />

              <FormFieldNew
                id="price"
                label={"Price"}
                error={errors.price as FieldError}
                control={
                  <Controller
                    name="price"
                    control={control}
                    rules={{
                      required: "Please enter a price",
                      valueAsNumber: true
                    }}
                    defaultValue={null}
                    render={({ onChange, value }) => (
                      <CurrencyInput
                        id="price"
                        name="price"
                        autoComplete="off"
                        asUnits={true}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                }
              />
            </div>

            <div tw="space-y-2">
              <SlideOutHintPanel>
                You can add as many as you like by using the "Add" button
                multiple times.
              </SlideOutHintPanel>
              <SlideOutHintPanel>
                Ensure line items are error free, descriptive and understandable
                to a client.
              </SlideOutHintPanel>
            </div>
          </div>
        </div>
      )}
    />
  )
}

const EmptyState: FC<{ name: string; button: string; onClick: () => void }> = ({
  name,
  button,
  onClick
}) => (
  <div tw="relative block flex flex-col gap-4 items-center w-full border-2 border-gray-300 border-dashed rounded-lg p-4 sm:p-6 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
    <div tw="space-y-1">
      <h3 tw="text-sm font-medium text-gray-900">No {name}</h3>
      <p tw="text-sm text-gray-500">Get started by adding one or more.</p>
    </div>
    {/* <Button variant='secondary' type="button" onClick={onClick}>{button}</Button> */}
  </div>
)

type ScopeSlideOutFormProps = {
  item?: ScopeItem
  renderProps: SlideOutMenuRenderProps
  buttonText: string
  examples: string[]
  onSubmit: (item: ScopeItem) => void
}

const ScopeSlideOutForm: FC<ScopeSlideOutFormProps> = ({
  buttonText,
  examples,
  renderProps,
  item,
  onSubmit
}) => {
  const { errors, register, control, handleSubmit, watch } = useForm<ScopeItem>(
    {
      defaultValues: item
    }
  )

  const title = watch("title") || ""

  return (
    <SlideOutForm
      renderProps={renderProps}
      onSubmit={handleSubmit(onSubmit)}
      actions={({ close }) => (
        <>
          <Button type="button" variant="secondary" onClick={close}>
            Cancel
          </Button>
          <Button type="submit" variant="primary">
            {buttonText}
          </Button>
        </>
      )}
      content={({ close }) => (
        <div tw="flex flex-col flex-1">
          <SlideOutMenuHeader
            title={item ? `Editing Scope` : `Adding Scope`}
            supportingText="A scope item is a specific deliverable or action you are committing to include or exclude in this quote."
            onClose={close}
          />
          <div tw="flex flex-1 flex-col justify-between px-4 sm:px-6 py-6 gap-5">
            <div tw="space-y-2">
              <FormFieldNew
                id="title"
                label="Title"
                error={errors.title}
                control={
                  <TextInput
                    type="text"
                    id="title"
                    name="title"
                    autoComplete="off"
                    ref={register({ required: true, maxLength: 200 })}
                  />
                }
                hint={
                  <div tw="flex flex-row">
                    <p tw="flex-1">Use short but descriptive language</p>
                    <p>{title.length}/200</p>
                  </div>
                }
              />

              <ExamplePanel examples={examples} />
            </div>

            <div tw="space-y-2">
              <SlideOutHintPanel>
                You can add as many as you like by using the "Add" button
                multiple times.
              </SlideOutHintPanel>
              <SlideOutHintPanel>
                Ensure your scopes are error free, descriptive and
                understandable to a client.
              </SlideOutHintPanel>
            </div>
          </div>
        </div>
      )}
    />
  )
}

const ExamplePanel: FC<{ examples: string[] }> = ({ examples }) => (
  <div tw="rounded-md bg-gray-100 p-3 text-gray-900 space-y-1">
    <h3 tw="font-semibold text-xs text-gray-500">Examples</h3>

    <ul tw="text-xs sm:text-sm space-y-1">
      {examples.map((e, i) => (
        <li key={`example-${i}`}>"{e}"</li>
      ))}
    </ul>
  </div>
)

const SlideOutHintPanel = tw.div`font-medium text-xs sm:text-sm bg-yellow-100 p-3 rounded-md text-yellow-800`

type LineItemsTableProps = {
  items: WithID<FeeGuideLineItem>[]
  onEdit: (item: WithID<FeeGuideLineItem>) => void
  onDelete: (item: WithID<FeeGuideLineItem>) => void
  onMoveUp: (item: WithID<FeeGuideLineItem>) => void
  onMoveDown: (item: WithID<FeeGuideLineItem>) => void
}

const LineItemsTable: FC<LineItemsTableProps> = ({
  items,
  onEdit,
  onDelete,
  onMoveUp,
  onMoveDown,
  ...rest
}) => {
  const columns = useMemo(
    (): TableColumn<WithID<FeeGuideLineItem>>[] => [
      {
        Header: "Name",
        accessor: "name",
        grow: true,
        Cell: ({ value, row }) => (
          <div tw="truncate overflow-wrap[break-word] max-w-xs" title={value}>
            {value}

            <dl tw="font-normal sm:hidden space-y-2">
              <dt tw="sr-only">Price</dt>
              <dd tw="mt-1 truncate text-gray-700">
                <FormattedCurrencyUnits amountUnits={row.original.price} />
              </dd>
              <dt tw="sr-only sm:hidden">Fee Type</dt>
              <dd tw="mt-1 truncate text-gray-500 sm:hidden">
                <FeeTypeBadge feeType={row.original.feeType} />
              </dd>

              <dd tw="flex flex-row gap-4 text-gray-500" key={row.original.id}>
                <MoveUpButton onClick={() => onMoveUp(row.original)} />
                <MoveDownButton onClick={() => onMoveDown(row.original)} />
                <DeleteButton onDelete={() => onDelete(row.original)} />
                <EditButton onClick={() => onEdit(row.original)} />
              </dd>
            </dl>
          </div>
        )
      },
      {
        Header: "Fee Type",
        accessor: "feeType",
        hideMobile: true,
        style: tw`truncate`,
        Cell: ({ value }) => <FeeTypeBadge feeType={value} />
      },
      {
        Header: "Price",
        accessor: "price",
        style: tw`text-right`,
        hideMobile: true,
        Cell: ({ value }) => <FormattedCurrencyUnits amountUnits={value} />
      },
      {
        Header: "",
        id: "details",
        hideMobile: true,
        Cell: (i: any) => (
          <div
            tw="flex-row gap-4 text-gray-500 hidden sm:flex"
            key={i.row.original.id}
          >
            <MoveUpButton onClick={() => onMoveUp(i.row.original)} />
            <MoveDownButton onClick={() => onMoveDown(i.row.original)} />
            <DeleteButton onDelete={() => onDelete(i.row.original)} />
            <EditButton onClick={() => onEdit(i.row.original)} />
          </div>
        )
      }
    ],
    []
  )

  const total = sumQuoteItems(items)

  return (
    <PlainDataTable
      columns={columns}
      data={items}
      footer={
        <tfoot>
          <tr>
            <TableCell
              scope="row"
              colSpan={2}
              tw="hidden text-right text-sm font-semibold text-gray-900 sm:table-cell"
            >
              Total
            </TableCell>
            <TableCell
              scope="row"
              tw="text-left text-sm font-semibold text-gray-900 sm:hidden"
            >
              Total
            </TableCell>
            <TableCell tw="text-right text-sm font-semibold text-gray-900">
              <FormattedCurrencyUnits amountUnits={total} />
            </TableCell>
          </tr>
        </tfoot>
      }
      {...rest}
    />
  )
}

type ScopeTableProps = {
  items: WithID<ScopeItem>[]
  onEdit: (item: WithID<ScopeItem>) => void
  onDelete: (item: WithID<ScopeItem>) => void
  onMoveUp: (item: WithID<ScopeItem>) => void
  onMoveDown: (item: WithID<ScopeItem>) => void
}

const ScopeTable: FC<ScopeTableProps> = ({
  items,
  onEdit,
  onDelete,
  onMoveUp,
  onMoveDown,
  ...rest
}) => {
  const columns = useMemo(
    (): TableColumn<WithID<ScopeItem>>[] => [
      {
        Header: "Title",
        accessor: "title",
        grow: true,
        Cell: ({ value, row }) => (
          <div
            tw="overflow-wrap[break-word] max-w-xs flex flex-col gap-2"
            title={value}
          >
            {value}

            <dl tw="font-normal sm:hidden">
              <dd tw="flex flex-row gap-4 text-gray-500" key={row.original.id}>
                <MoveUpButton onClick={() => onMoveUp(row.original)} />
                <MoveDownButton onClick={() => onMoveDown(row.original)} />
                <DeleteButton onDelete={() => onDelete(row.original)} />
                <EditButton onClick={() => onEdit(row.original)} />
              </dd>
            </dl>
          </div>
        )
      },
      {
        Header: "",
        id: "details",
        hideMobile: true,
        Cell: (i: any) => (
          <div
            tw="flex-row gap-4 text-gray-500 hidden sm:flex"
            key={i.row.original.id}
          >
            <MoveUpButton onClick={() => onMoveUp(i.row.original)} />
            <MoveDownButton onClick={() => onMoveDown(i.row.original)} />
            <DeleteButton onDelete={() => onDelete(i.row.original)} />
            <EditButton onClick={() => onEdit(i.row.original)} />
          </div>
        )
      }
    ],
    []
  )

  return <PlainDataTable columns={columns} data={items} hideHeader {...rest} />
}

const FeaturedIcon: FC = tw.div`flex flex-shrink-0 items-center justify-center p-2.5 bg-indigo-100 rounded-full h-10 w-10 text-indigo-600`

const EditButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = (props) => (
  <button
    type="button"
    tw="inline-flex items-center rounded-full text-gray-500 hover:(text-indigo-500 bg-indigo-50) transition ease-in-out duration-200 ring-offset-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:text-indigo-500 focus:ring-opacity-75"
    title="Edit"
    {...props}
  >
    <PencilIcon tw="h-5 w-5" />
  </button>
)

const MoveUpButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = (props) => (
  <button
    type="button"
    tw="inline-flex items-center rounded-full text-gray-500 hover:(text-indigo-500 bg-indigo-50) transition ease-in-out duration-200 ring-offset-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:text-indigo-500 focus:ring-opacity-75"
    title="Edit"
    {...props}
  >
    <ArrowUpIcon tw="h-5 w-5" />
  </button>
)

const MoveDownButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = (props) => (
  <button
    type="button"
    tw="inline-flex items-center rounded-full text-gray-500 hover:(text-indigo-500 bg-indigo-50) transition ease-in-out duration-200 ring-offset-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:text-indigo-500 focus:ring-opacity-75"
    title="Edit"
    {...props}
  >
    <ArrowDownIcon tw="h-5 w-5" />
  </button>
)

const DeleteButton: FC<{ onDelete?: () => void }> = ({ onDelete }) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>()
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>()

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "top"
  })

  const handleClick = (isOpen: boolean) => {
    if (isOpen) {
      onDelete && onDelete()
    }
  }

  return (
    <Popover as={Fragment}>
      {({ open }) => (
        <>
          <Popover.Button
            ref={setReferenceElement}
            className="group"
            title="Delete"
            css={[
              tw`inline-flex items-center rounded-full text-gray-500 focus:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-opacity-75`,
              tw`hover:(text-red-500 bg-red-50)`,
              tw`transition ease-in-out duration-200`,
              open &&
                tw`text-red-500 hover:text-red-600 bg-red-50 ring-2 ring-offset-2 ring-red-500`
            ]}
            onClick={() => handleClick(open)}
          >
            <TrashIcon tw="h-5 w-5" />
          </Popover.Button>

          <Popover.Panel
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            <div tw="px-2.5 py-1 rounded-lg text-xs bg-red-500 text-white font-medium ring-1 ring-black ring-opacity-5">
              Click again to confirm
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  )
}
