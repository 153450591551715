import { matchExhaustive } from '@practical-fp/union-types'
import { addMinutes, format, formatDistanceToNow, formatDistanceToNowStrict, isBefore } from 'date-fns'
import { Card, CardContent } from 'packages/app/src/elements'
import { PageHeader, PageHeaderBackLink } from 'packages/app/src/elements/PageHeader'
import { Statistic } from 'packages/app/src/elements/Statistic'
import { ROUTES } from 'packages/app/src/layout/Navigation'
import { SidebarLayout } from 'packages/app/src/layout/SidebarLayout'
import { ContentLayoutFlow, DetailsSubshell } from 'packages/app/src/layout/subshells/DetailsSubshell'
import { FC, ReactNode } from 'react'
import 'twin.macro'
import { Badge } from '../../../elements/Badge'
import { matchStringUnion } from '../../../lib/utils'
import { AssessmentCallStatus } from '../../../scenes/assessment-calls/AssessmentCallsList'
import { AssessmentCallUIDetailsResponse } from '../hooks/useAssessmentCallDetails'

export const buildStatusPill = (s: AssessmentCallStatus) => matchStringUnion(s, {
  "pending-client-selection": () => <Badge variant='neutral'>Waiting for Client to Reschedule</Badge>,
  "pending-solicitor-confirmation": () => <Badge variant='yellow'>Needs booking</Badge>,
  booked: () => <Badge variant='positive'>Booked</Badge>,
  completed: () => <Badge variant='positive'>Completed</Badge>,
  "closed": () => <Badge variant='negative'>Closed</Badge>,
})

type AssessmentCallDetailsSubshellProps = {
  call: AssessmentCallUIDetailsResponse
  actions?: ReactNode
  content: ReactNode
}

export const AssessmentCallDetailsSubshell: FC<AssessmentCallDetailsSubshellProps> = ({ call, actions, content }) => (
  <DetailsSubshell
    header={(
      <div tw="space-y-4 sm:space-y-2">
        <PageHeader
          title={`Call with ${call.leadName}`}
          back={(
            <PageHeaderBackLink to={ROUTES.assessmentCalls.root()}>
              Back to assessment calls
            </PageHeaderBackLink>
          )}
          actions={actions}
        />

        {buildStatusPill(call.status)}
      </div>
    )}
    content={(
      <SidebarLayout
        sidebarFirstMobile
        left={content}
        right={<AssessmentCallDetailsSidebar call={call} />}
      />
    )}
  />
)

const AssessmentCallDetailsSidebar: FC<Pick<AssessmentCallDetailsSubshellProps, 'call'>> = ({ call }) => (
  <ContentLayoutFlow>

    {call.result && matchExhaustive(call.result, {
      SolicitorQuoted: q => (
        <>
          {q.clientNotifiedAt && (
            <Statistic
              label={'Sent to Client At'}
              value={format(q.clientNotifiedAt, 'EEE do MMM h:mmaaa')}
            />
          )}
          {q.quotedAt && (
            <Statistic
              label={'Quoted At'}
              value={format(q.quotedAt, 'EEE do MMM h:mmaaa')}
            />
          )}
        </>
      ),
      NotLegalMatter: n => call.completedAt && (
        <Statistic
          label={'Completed At'}
          value={format(call.completedAt, 'EEE do MMM h:mmaaa')}
        />
      ),
      NotAssessed: n => call.completedAt && (
        <Statistic
          label={'Completed At'}
          value={format(call.completedAt, 'EEE do MMM h:mmaaa')}
        />
      ),
      NoAnswer: n => call.completedAt && (
        <Statistic
          label={'Completed At'}
          value={format(call.completedAt, 'EEE do MMM h:mmaaa')}
        />
      ),
    })}

    <Statistic
      label={'Client Name'}
      value={call.leadName}
    />

    <Statistic
      label={'Client number'}
      value={call.leadPhoneNumber}
    />

    {call.solicitorSelection && (
      <>
        <Statistic
          label={'Date'}
          value={format(call.solicitorSelection, 'EEE do MMM')}
          helperText={formatDistanceToNowStrict(call.solicitorSelection, { addSuffix: true })}
        />
        <Statistic
          label={'Time'}
          value={`${format(call.solicitorSelection, 'h:mmaaa')} - ${format(addMinutes(call.solicitorSelection, 15), 'h:mmaaa')}`}
        />
      </>
    )}
  </ContentLayoutFlow>
)

export const AssessmentCallDescriptionSection: FC<{ call: AssessmentCallUIDetailsResponse }> = ({ call }) => (
  <section tw="flex flex-col gap-y-4">
    <div tw="text-xs sm:text-sm">
      <h3 tw="font-medium text-gray-700">
        Client's Description
      </h3>
      <p tw="text-gray-500">
        The client's description of their legal matter, with sensitive and private information removed.
      </p>
    </div>

    <Card>
      <CardContent tw="text-gray-900 text-base">
        {call.requestDetails}
      </CardContent>
    </Card>
  </section>
)
