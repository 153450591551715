
import { Branded } from 'io-ts'
import * as D from 'io-ts/lib/Decoder'
import { castTo, fetchParsedJSON } from "../../../lib/fetch"
import { optional } from '../../../lib/io'
import { RangeInt, RangeString, UUID } from '../../../lib/Primitives'
import { useApiFetch } from "../../../lib/useApiClient"

// 10_000_000 = 10m in units (units = GBP*100)
// = £100k
export const PriceUnits = RangeInt(0, 10_000_000)
export type PriceUnits = Branded<RangeInt<0, 10_000_000>, 'PriceUnits'>

export type QuoteLineItemName = Branded<RangeString<1, 200>, 'QuoteLineItemName'>
export const QuoteLineItemName = RangeString(1, 200)

export type ScopeTitle = Branded<RangeString<1, 200>, 'ScopeTitle'>
export const ScopeTitle = RangeString(1, 200)

export const QuoteLineItemFeeType = D.literal("fee", "disbursement")
export type QuoteLineItemFeeType = D.TypeOf<typeof QuoteLineItemFeeType>

export const SolicitorQuoteType = D.literal("template", "custom")
export type SolicitorQuoteType = D.TypeOf<typeof SolicitorQuoteType>

export const SolicitorQuoteMetadata = D.struct({
  legalArea: optional(D.struct({
    name: D.string,
    id: D.string
  })),
  product: optional(D.struct({
    name: D.string,
    id: D.string
  })),
  variant: optional(D.struct({
    name: D.string,
    id: D.string
  })),
})
export type SolicitorQuoteMetadata = D.TypeOf<typeof SolicitorQuoteMetadata>

// export const QuoteLineItemVATType = D.literal("included", "excluded", "none")
// export type QuoteLineItemVATType = D.TypeOf<typeof QuoteLineItemVATType>


export const SolicitorQuoteLineItem = D.struct({
  id: UUID,
  price: PriceUnits,
  name: QuoteLineItemName,
  feeType: QuoteLineItemFeeType,
})
export type SolicitorQuoteLineItem = D.TypeOf<typeof SolicitorQuoteLineItem>

export const SolicitorQuoteScopeItem = D.struct({
  id: UUID,
  title: ScopeTitle,
})
export type SolicitorQuoteScopeItem = D.TypeOf<typeof SolicitorQuoteScopeItem>

export const SolicitorQuote = D.struct({
  baseCurrency: D.literal("GBP"),
  lineItems: D.array(SolicitorQuoteLineItem),
  includes: D.array(SolicitorQuoteScopeItem),
  excludes: D.array(SolicitorQuoteScopeItem),
  notes: optional(D.string),
  type: SolicitorQuoteType,
  metadata: optional(SolicitorQuoteMetadata)
})
export type SolicitorQuote = D.TypeOf<typeof SolicitorQuote>


export const CreateSolicitorQuoteCommand = D.struct({
  leadId: UUID,
  solicitorId: UUID,
  quote: SolicitorQuote
})

export type CreateSolicitorQuoteCommand = D.TypeOf<typeof CreateSolicitorQuoteCommand>
type CreateSolicitorQuoteResult = { id: string }

export const useCreateSolicitorQuote = () => {
  const api = useApiFetch(fetchParsedJSON(castTo<CreateSolicitorQuoteResult>()))

  return (command: CreateSolicitorQuoteCommand) => api(`solicitor-quotes`, {
    method: 'POST',
    body: JSON.stringify(command)
  })
}
