import { ListUsersByTypeQuery, ListUsersByTypeQueryVariables, UserType } from "@lawhive/generated-api"
import { DatumEither } from "@nll/datum/DatumEither"
import React, { FC, useEffect } from "react"
import 'twin.macro'
import { useAsync } from "../../lib/useAsync"
import { graphql, isNotNullOrUndefined, renderLoadingOrSuccess } from "../../lib/utils"
import { Select, SelectDiv } from "../Form"
import { Spinner } from "../Loading"

export type ClientSelectorItem = {
  id: string
  displayName: string
  emailAddress: string
}

export const useClientList = () => {
  return useAsync(
    () => graphql<ListUsersByTypeQuery, ListUsersByTypeQueryVariables>({
      query: /* GraphQL */ `
        query ListUsersByType(
          $type: UserType
          $sortDirection: ModelSortDirection
          $filter: ModelUserFilterInput
          $limit: Int
          $nextToken: String
        ) {
          listUsersByType(
            type: $type
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
          ) {
            items {
              id
              type
              emailAddress
              displayName
              phoneNumber
              stripeCustomerId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      `,
      variables: {
        limit: 1000,
        type: UserType.client
      }
    })
    .then(r => r.data?.listUsersByType
      ? Promise.resolve(r.data.listUsersByType)
      : Promise.reject()
    )
    .then(r => ((r.items || []).filter(isNotNullOrUndefined).map((i): ClientSelectorItem => ({
      id: i.id,
      displayName: i.displayName || 'No name set',
      emailAddress: i.emailAddress || 'No email set'
    }))))
    .then(r => r.sort((a, b) => a.displayName.localeCompare(b.displayName)))
  )
}

type ClientSelectorProps = {
  items: DatumEither<Error, ClientSelectorItem[]>,
  selectedId?: string,
  onSelected: (s?: ClientSelectorItem) => void
}

export const ClientSelector: FC<ClientSelectorProps> = ({ items, selectedId, onSelected }) => {
  return renderLoadingOrSuccess(
    () => (
      <SelectDiv tw="bg-gray-50 animate-pulse">
        <Spinner tw="h-5 text-gray-300" />
      </SelectDiv>
    ),
    (clients: ClientSelectorItem[]) => {
      const selectSolicitor = (id: string) => clients.filter(s => s.id === id).length > 0
        ? onSelected(clients.filter(s => s.id === id)[0])
        : onSelected(undefined)

      return (
        <Select value={selectedId} onChange={t => selectSolicitor(t.target.value)}>
          <option>Select a client...</option>

          {clients.map((s, i) => (
            <option key={`clients-${i}`} value={s.id}>
              {s.displayName} [{s.emailAddress}]
            </option>
          ))}
        </Select>
      )
    }
  )(items)
}
