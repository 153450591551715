import { Dispatch, FC, SetStateAction, useState } from "react"
import { E164PhoneNumber } from "aws-sdk/clients/chime"
import { SubmitHandler, useForm } from "react-hook-form"
import PhoneInput from "react-phone-number-input/react-hook-form-input"
import { formatPhoneNumber, isValidPhoneNumber } from "react-phone-number-input"
import { TextInput } from "./Form"
import { Button } from "./Button"
import "twin.macro"

export const EditablePhoneNumber: FC<{
  isEditing: boolean,
  setIsEditing: Dispatch<SetStateAction<boolean>>,
  phoneNumber: E164PhoneNumber,
  onSave: (number: string) => void
}> = ({isEditing, setIsEditing, phoneNumber, onSave}) => {

  type FormInputs = {
    phoneNumber: E164PhoneNumber
  }

  const onSubmit: SubmitHandler<FormInputs> = data => {
    setIsEditing(false)
    onSave(data.phoneNumber)
  }

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty }
  } = useForm<FormInputs>({
    mode: "onChange",
    defaultValues: { phoneNumber: phoneNumber }
  })

  const [newPhoneNumber, setNewPhoneNumber] = useState<E164PhoneNumber | undefined>(phoneNumber)

  return (
    <div tw="space-y-2">

      {isEditing ?
        <form onSubmit={handleSubmit(onSubmit)}
              tw="flex space-x-2 space-y-2 sm:space-y-0 items-center justify-center flex-col sm:flex-row"
        >
          <PhoneInput
            onChange={setNewPhoneNumber}
            control={control}
            country="GB"
            rules={{ required: true, validate: isValidPhoneNumber }}
            defaultValue={newPhoneNumber}
            name="phoneNumber"
            inputComponent={TextInput}
          />
          <div tw="flex gap-x-2">
            <Button variant="positive" tw="flex-1 px-2.5 py-1.5"
                    type="submit" disabled={!isDirty || !isValid}
            >
              Save
            </Button>
            <Button variant="negative" tw="flex-1 px-2.5 py-1.5" onClick={() => setIsEditing(false)}>Cancel</Button>
          </div>
        </form> :
        <div tw="flex space-x-2 items-center">
          <span tw="font-bold">{formatPhoneNumber(phoneNumber)}</span>
          <Button variant="secondary" tw="px-2.5 py-1.5" onClick={() => setIsEditing(true)}>Edit</Button>
        </div>
      }
    </div>
  )

}
