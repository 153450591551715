import { Button } from "packages/app/src/elements/Button"
import "twin.macro"

import { Card, CardContent } from "packages/app/src/elements"
import { ContentLayoutFlow, DetailsSubshell } from "packages/app/src/layout/subshells/DetailsSubshell"
import { FC, PropsWithChildren } from "react"
import { Badge } from "packages/app/src/elements/Badge"
import { ChartBarIcon, EmojiHappyIcon, LightningBoltIcon, MailIcon } from "@heroicons/react/outline"
import { PageHeader } from "packages/app/src/elements/PageHeader"
import { IntercomContextValues, useIntercom } from "react-use-intercom"
import { Modal } from "../../../elements/Modal"
import { useModal } from "../../../contexts/Modal"
import { SavingsModal } from "./SavingsModal"
import { FaqModal } from "./FaqModal"
import { ConfirmSignupModal } from "./ConfirmSignupModal"

export const ChangeModelScene = () => (
  <DetailsSubshell
    header={(
      <PageHeader title="Change Plan" />
    )}
    content={(
      <ContentLayoutFlow>
        <FeeCard
          title="Pay by Case"
          badge={<Badge variant="neutral">Your current plan</Badge>}
          stats={[
            { title: "Monthly Fee", stat: "£0", subtext: "per month" },
            { title: "Lawhive Fee", stat: "20%" },
            { title: "Payment Processor Fee", stat: "1.4-2.9% + 20p" },
          ]}
        >
          Pay Lawhive fees and payment processor fees each time a client pays for work.
          <br/>
           ⚠️ From October 1st we will charge an additional 5% administration fee for pay-by-case.
        </FeeCard>

        <FeeCard
          title="Pay Monthly"
          badge={<Badge variant="positive">Recommended</Badge>}
          stats={[
            { title: "Monthly Fee", stat: "£0", subtext: "per month" },
            { title: "Lawhive Fee", stat: "22% + VAT" },
          ]}
          features={[
            {
              name: 'Client payments received in full',
              description: 'Receive client payments for fees and disbursements in full to your client account. Simplify accounting and reconciliation.',
              icon: <ChartBarIcon />
            },
            {
              name: 'Pay Lawhive fees from your office account',
              description: 'Simplify your client money accounting.',
              icon: <MailIcon />
            },
            {
              name: 'More control over fees',
              description: 'Pay a single monthly platform fee. No payment processing charges.',
              icon: <LightningBoltIcon />
            },
            {
              name: 'The same Lawhive Guarantee',
              description: 'Your earnings are still protected through the Lawhive guarantee. Always get paid for work you successfully complete.',
              icon: <EmojiHappyIcon />
            }
          ]}
          actions={(
            <>
              <OpenFaqModalButton/>
              <OpenSeeHowMuchICouldSaveModalButton/>
              <OpenConfirmSignupModalButton/>
            </>
          )}
        >
          Lawhive issues a single monthly invoice with all charges accrued over the month. Your monthly charge is collected by direct debit.
        </FeeCard>

        <GetInTouchCard/>

      </ContentLayoutFlow>
    )}
  />
)



export const OpenSeeHowMuchICouldSaveModalButton = () => {

  const [show, hide] = useModal(() => (
    <Modal onDismiss={hide}>
      <SavingsModal/>
    </Modal>
  ))

  return (
    <Button variant="secondary" onClick={show}>
      See how much you could save
    </Button>
  )
}

export const OpenFaqModalButton = () => {

  const [show, hide] = useModal(() => (
    <Modal onDismiss={hide}>
      <FaqModal/>
    </Modal>
  ))

  return (
    <Button variant="secondary" onClick={show}>
      FAQs
    </Button>
  )
}

export const OpenConfirmSignupModalButton = () => {

  const [show, hide] = useModal(() => (
    <Modal onDismiss={hide}>
      <ConfirmSignupModal/>
    </Modal>
  ))

  return (
    <Button variant="primary" onClick={show}>
      Sign up to pay-monthly
    </Button>
  )
}

type FeeCardProps = {
  title: string
  badge?: JSX.Element
  stats: {
    title: string
    stat: string
    subtext?: string
  }[]
  actions?: JSX.Element
  features?: {
    name: string,
    description: string,
    // icon: React.ComponentType
    icon: JSX.Element
  }[]
}

const FeeCard = ({ title, badge, stats, actions, features, children }: PropsWithChildren<FeeCardProps>) => (
  <Card>
    <CardContent tw="space-y-5">
      <div tw="flex flex-col gap-8 sm:flex-row sm:justify-between">
        <div tw="space-y-2">
          <div tw="flex flex-row gap-2 items-center">
            <h3 tw="text-lg leading-6 font-medium text-gray-900">{title}</h3>
            {badge}
          </div>
          <div tw="space-y-2 text-sm text-gray-500">
            {children}
          </div>
        </div>
        <div tw="grid grid-cols-2 gap-2">
          {stats.map((s, i) => (
            <>
              <div key={`title-${i}`} tw="text-gray-500 font-medium sm:text-right">{s.title}</div>
              <div key={`stat-${i}`} tw="flex flex-row items-start gap-1">
                <span tw="text-gray-900 font-semibold text-xl">{s.stat}</span>
                {s.subtext && (
                  <span tw="font-medium text-gray-500 text-sm">{s.subtext}</span>
                )}
              </div>
            </>
          ))}
        </div>
      </div>
        {features &&
          <FeatureList features={features}/>
        }
    </CardContent>
    {actions && (
      <CardContent tw="border-t border-gray-100 py-4">
        <div tw="flex space-x-2 justify-end">
          {actions}
        </div>
      </CardContent>
    )}
  </Card>
)

type FeatureListProps = {
  features: {
    name: string,
    description: string,
    // icon: React.ComponentType
    icon: JSX.Element
  }[]
}

const FeatureList = ({ features }: FeatureListProps) => (
  <dl tw="mt-10 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-x-8 sm:gap-y-10 lg:mt-0 lg:col-span-2">
    {features.map((feature) => (
      <div key={feature.name}>
        <dt>
          <div tw="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
            <div tw="h-6 w-6" aria-hidden="true">
              {feature.icon}
            </div>
          </div>
          <p tw="mt-5 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
        </dt>
        <dd tw="mt-2 text-base text-gray-500">{feature.description}</dd>
      </div>
    ))}
  </dl>
)

const GetInTouchCard: FC = () => {

  const intercom: IntercomContextValues = useIntercom()

  return (
    <Card>
      <CardContent tw="space-y-5">
        <div tw="flex flex-col gap-8 sm:flex-row sm:justify-between">
          <div tw="space-y-2">
            <div tw="flex flex-row gap-2 items-center">
              <h3 tw="text-lg leading-6 font-medium text-gray-900">Still have questions?</h3>
            </div>
            <div tw="space-y-2 text-sm text-gray-500">
              Can’t find the answer you’re looking for? Please chat to our friendly team.
            </div>
          </div>

          <div tw="flex space-x-2 justify-end">
            <div>
              <Button variant="secondary" onClick={() => intercom.showNewMessages("I'd like to know more about monthly charges")}>Get in touch</Button>
            </div>
          </div>
        </div>
      </CardContent>


    </Card>
  )
}
