import { matchExhaustive } from "@practical-fp/union-types"
import * as D from "io-ts/lib/Decoder"
import { pipe } from "fp-ts/es6/function"
import * as O from "fp-ts/es6/Option"
import * as TE from "fp-ts/es6/TaskEither"
import { fetchParsedJSON } from "../lib/fetch"
import { DateFromString, UUID } from "../lib/Primitives"
import { useApiFetch } from '../lib/useApiClient'
import { PriceUnits } from "../components/assessment-call/hooks/useCreateSolicitorQuote"
import { decodeOrError, withOptional } from "../lib/io"

const TaxRate = D.struct({
  inclusive: D.boolean,
  percentage: D.number
})
export type TaxRate = D.TypeOf<typeof TaxRate>

const InvoiceLineItem = withOptional(
  D.struct({
    id: D.string,
    description: D.string,
    amountExcludingTax: PriceUnits,
    amount: PriceUnits,
    createdAt: DateFromString
  }),
  {
    taxRate: TaxRate
  }
)

export type InvoiceLineItem = D.TypeOf<typeof InvoiceLineItem>

const TaxItem = D.struct({
  id: D.string,
  inclusive: D.boolean,
  amount: PriceUnits,
  displayName: D.string
})

export type TaxItem = D.TypeOf<typeof TaxItem>

const Period = D.struct({
  start: DateFromString,
  end: DateFromString
})

export type Period = D.TypeOf<typeof Period>

export const InvoiceDTO = withOptional(
  D.struct({
    paid: D.boolean,
    period: Period,
    amountDue: PriceUnits,
    amountPaid: PriceUnits,
    amountRemaining: PriceUnits,
    subtotal: PriceUnits,
    subtotalExcludingTax: PriceUnits,
    total: PriceUnits,
    totalExcludingTax: PriceUnits,
    lineItems: D.array(InvoiceLineItem),
    taxItems: D.array(TaxItem)
  }),
  {
    hostedUrl: D.string,
    pdfDownloadUrl: D.string
  }
)

export type InvoiceDTO = D.TypeOf<typeof InvoiceDTO>


export const useGetUpcomingInvoice = () => {
  const api = useApiFetch(fetchParsedJSON(decodeOrError(InvoiceDTO)))

  return (chargeAccountId: UUID) => pipe(
    api(`charge-accounts/${chargeAccountId}/upcoming-invoice`),
    TE.map(O.some),
    TE.orElseW(error => matchExhaustive(error, {
      NetworkError: () => TE.left(error),
      ParseError: () => TE.left(error),
      ResponseError: (e) => e.statusCode === 404
        ? TE.right(O.none as O.Option<InvoiceDTO>)
        : TE.left(error)
    }))
  )
}
