import { UserType } from "@lawhive/domain"
import { impl, Variant } from "@practical-fp/union-types"
import { pipe } from "fp-ts/es6/function"
import { map } from "fp-ts/lib/TaskEither"
import { castTo, fetchParsedJSON } from "../../lib/fetch"
import { useApiFetch } from "../../lib/useApiClient"
import { useFromTaskEither } from "../../lib/useAsync"

type CaseFileEntity = {
  id: string
  hidden: boolean
  fileSize: number
  displayName: string
  case: string
  uploader: string
}

type RegisterFileUploadCommand = {
  s3FileKey: string
  displayName: string
}

export const useRegisterFileUpload = () => {
  const api = useApiFetch(fetchParsedJSON(castTo<{ data: CaseFileEntity }>()))

  return  (command: RegisterFileUploadCommand) => api(`case-files/register-upload`, {
    method: 'POST',
    body: JSON.stringify(command)
  })
}


export type ListFilesForCaseDTOItem =
  | Variant<"LegacyFile", LegacyFile>
  | Variant<"CaseFile", CaseFileDTO>

export const ListFilesForCaseDTOItem = impl<ListFilesForCaseDTOItem>()

type LegacyFile = {
  name: string,
  key: string,
  // TODO Parse as dates
  lastModified: string,
  size: number
}

type CaseFileDTO = {
  uploaderId: string
  uploaderType: UserType
  fileSize: number
  s3FileKey: string
  displayName: string
  // TODO Parse as dates
  lastModified: string
  uploadedAt: string
  hidden?: boolean
}

export const useGetCaseFiles = (caseId: string) => {
  const api = useApiFetch(fetchParsedJSON(castTo<{ data: ListFilesForCaseDTOItem[] }>()))

  return useFromTaskEither(() => pipe(
    api(`case-files/${caseId}`),
    map(a => a.data)
  ))
}
