import {
  approveKycSubmissionCommand,
  ApproveKycSubmissionCommandMutation,
  ApproveKycSubmissionCommandMutationVariables,
  downloadFileCommand,
  DownloadFileCommandMutation,
  DownloadFileCommandMutationVariables,
  GetKycSubmissionQuery,
  KYCSubmissionStatus,
  rejectKycSubmissionCommand,
  RejectKycSubmissionCommandMutation,
  RejectKycSubmissionCommandMutationVariables,
  requestKycReportUploadCommand,
  RequestKycReportUploadCommandMutation,
  RequestKycReportUploadCommandMutationVariables
} from "@lawhive/generated-api"
import { isPending } from "@nll/datum/DatumEither"
import React, { FC, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import "twin.macro"

import { Card, CardContent, FormField, imageAndDocFileTypes, SingleFileUploadInput, TextInput } from "../../../elements"
import { Button } from "../../../elements/Button"
import { NegativePill, PositivePill, YellowPill } from "../../../elements/Badge"
import { SkeletonCard } from "../../../elements/Skeleton"
import { ContentContainer, PageContainer } from "../../../layout/Layout"
import { downloadFileVoid, performUpload } from "../../../lib/files"
import { useAsync } from "../../../lib/useAsync"
import { useRouter } from "../../../lib/useRouter"
import { graphql, noop, renderLoadingOrSuccess, throwIfIsNullOrUndefined } from "../../../lib/utils"
import { FilesList, NewFileListItem } from "../../../elements/FilesList"
import { chain, getOrElse, map, map as mapOption, Option } from "fp-ts/es6/Option"
import { fromNullable } from "fp-ts/Option"
import { pipe } from "fp-ts/es6/function"

export const AdminKycDetailsScene = () => {
  const { params: { userId } } = useRouter<{ userId: string }>()

  const { status: kycSubmission, execute: load } = useAsync(
    (userId: string) => graphql<GetKycSubmissionQuery>({
      query: /* GraphQL */`
        query KYC {
          getKYCSubmission(userId: "${userId}") {
            userId
            user {
              displayName
            }
            status
            documentUploads
            identityUploads
            address
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
          }
        }
      `
    })
    .then(r => r.data?.getKYCSubmission)
    .then(throwIfIsNullOrUndefined)
    .then((r): KYCSubmission => ({
      address: r.address!,
      documentUploads: r.documentUploads || [],
      identityUploads: r.identityUploads || [],
      status: r.status!,
      userId: r.userId,
      // verificationReport: r.verificationReport!,
      verificationReport: pipe(
        fromNullable(r.verificationReport),
        mapOption(report => ({
          amlReportFileKey: report.amlReportFileKey,
          kycReportFileKey: fromNullable(report.kycReportFileKey)
        }))
      ),
      name: r.user?.displayName!
    }))
  )

  useEffect(() => {
    load(userId)
  }, [userId])

  const render = renderLoadingOrSuccess(
    () => <SkeletonCard />,
    (submission: KYCSubmission) => <KYCDetailsDisplay submission={submission} refresh={() => load(userId)} />
  )

  return (
    <PageContainer>
      <ContentContainer>
        {render(kycSubmission)}
      </ContentContainer>
    </PageContainer>
  )
}

type KYCSubmission = {
  userId: string
  status: KYCSubmissionStatus
  documentUploads: string[]
  identityUploads: string[]
  address: string
  verificationReport: Option<{ amlReportFileKey: string, kycReportFileKey: Option<string> }>
  name?: string,
  createdAt?: string
}

type Props = { submission: KYCSubmission, refresh: () => void }
const KYCDetailsDisplay: FC<Props> = ({ submission, refresh }) => {

  const download = (key: string, name: string = key) =>
    graphql<DownloadFileCommandMutation, DownloadFileCommandMutationVariables>({
      query: downloadFileCommand,
      variables: {
        input: {
          key
        }
      }
    })
    .then(r => r.data?.downloadFileCommand?.url)
    .then(throwIfIsNullOrUndefined)
    .then(downloadFileVoid(name))

  const extension = (filename: string) => filename.split('.').pop()

  const { status: kycSubmission, execute: load } = useAsync(
    (userId: string) => graphql<GetKycSubmissionQuery>({
      query: /* GraphQL */`
        query KYC {
          getKYCSubmission(userId: "${userId}") {
            userId
            user {
              displayName
            }
            status
            documentUploads
            identityUploads
            address
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
          }
        }
      `
    })
      .then(r => r.data?.getKYCSubmission)
      .then(throwIfIsNullOrUndefined)
      .then((r): KYCSubmission => ({
        address: r.address!,
        documentUploads: r.documentUploads || [],
        identityUploads: r.identityUploads || [],
        status: r.status!,
        userId: r.userId,
        // verificationReport: r.verificationReport!,
        verificationReport: pipe(
          fromNullable(r.verificationReport),
          mapOption(report => ({
            amlReportFileKey: report.amlReportFileKey,
            kycReportFileKey: fromNullable(report.kycReportFileKey)
          }))
        ),
        name: r.user?.displayName!,
        createdAt: r.createdAt
      }))
  )

  const createFileListItemFromOptionalKeys =
    (name: string) =>
      (maybeList: Option<string[]>) => pipe(
        maybeList,
        getOrElse((): string[] => []),
        docs => docs.map((d, i) => ({ name: `${name}${i === 0 ? `` : ` ${i}`}`, key: d }))
      )

  const kycFileListItems: NewFileListItem[] = pipe(
    fromNullable(submission),
    map(clientKyc => [
      ...pipe(
        fromNullable(submission.documentUploads),
        createFileListItemFromOptionalKeys(`Photo ID`)
      ),
      ...pipe(
        fromNullable(submission.identityUploads),
        createFileListItemFromOptionalKeys(`Client Selfie`)
      ),
      ...pipe(
        submission.verificationReport,
        map(reports => [reports.amlReportFileKey]),
        createFileListItemFromOptionalKeys('AML Report')
      ),
      ...pipe(
        submission.verificationReport,
        chain(reports => reports.kycReportFileKey),
        map(key => [key]),
        createFileListItemFromOptionalKeys('Proof of Address')
      )
    ]),
    getOrElse((): NewFileListItem[] => [])
  )

  return (
    <Card>
      <CardContent>
        <p >
          Name: {submission.name}
        </p>
        <div tw='flex items-center'>
          Status:
          <span tw="ml-2 flex items-center">
            {submission.status === 'approved' && <PositivePill>Approved</PositivePill>}
            {submission.status === 'submitted' && <YellowPill>Awaiting Review</YellowPill>}
            {submission.status === 'rejected' && <NegativePill>Rejected</NegativePill>}
          </span>
        </div>
        <p tw="mt-2">
          Address: {submission.address}
        </p>

        <dl>
          <FilesList items={kycFileListItems} update={() => null} onClick={download}/>
        </dl>

        <div tw="mt-2">
          {submission.status === 'submitted' &&
             (
              <>
                <KycForm submission={submission} refresh={refresh} />
              </>
            )
          }
        </div>
      </CardContent>
    </Card>
  )
}

type RejectForm = {
  reason?: string
  instructions?: string
}

const RejectKyc: FC<Props> = ({ submission, refresh, ...rest}) => {

  const { register, handleSubmit, watch } = useForm<RejectForm>()

  const { status: rejectStatus, execute: rejectKycSubmission } = useAsync(
    (form: RejectForm) => graphql<RejectKycSubmissionCommandMutation, RejectKycSubmissionCommandMutationVariables>({
      query: rejectKycSubmissionCommand,
      variables: {
        input: {
          userId: submission.userId,
          instructions: form.instructions,
          reason: form.reason
        }
      }
    }).then(refresh)
  )

  const {reason, instructions} = watch(
    ['reason', 'instructions'],
    {reason: '', instructions: ''}
  )

  const canReject = reason !== '' && instructions !== '';

  return (
    <div {...rest}>
      <form onSubmit={handleSubmit(rejectKycSubmission)}>
        <FormField
          id='reason'
          label='Reason'
          control={<TextInput name='reason' ref={register} />}
        />

        <FormField
          id='reason'
          label='Instructions to fix'
          control={<TextInput name='instructions' ref={register} />}
        />

        <Button
          isLoading={isPending(rejectStatus)}
          disabled={!canReject}
          variant='negative'
        >
          Reject KYC
        </Button>
      </form>
    </div>
  )
}

const KycForm: FC<Props> = ({ submission, refresh }) => {
  const [lastAMLUpload, setLastAMLUpload] = useState<string | undefined>()
  const [lastKYCUpload, setLastKYCUpload] = useState<string | undefined>()

  const { status: amlReportUploadStatus, execute: uploadAmlReport } = useAsync(
    (file: File, callback: (k: string) => void) => graphql<RequestKycReportUploadCommandMutation, RequestKycReportUploadCommandMutationVariables>({
      query: requestKycReportUploadCommand,
      variables: {
        input: {
          userId: submission.userId,
          filename: file.name
        }
      }
    })
    .then(a => a.data?.requestKycReportUploadCommand?.signature
      ? Promise.resolve(JSON.parse(a.data.requestKycReportUploadCommand.signature))
      : Promise.reject('Invalid upload signature')
    )
    .then(r => performUpload(file, r.url, r.fields).then(() => r))
    .then(r => callback(r.fields.key))
    .then(() => file.name)
  )

  const { status: poaUploadStatus, execute: uploadPoa } = useAsync(
    (file: File, callback: (k: string) => void) => graphql<RequestKycReportUploadCommandMutation, RequestKycReportUploadCommandMutationVariables>({
      query: requestKycReportUploadCommand,
      variables: {
        input: {
          userId: submission.userId,
          filename: file.name
        }
      }
    })
      .then(a => a.data?.requestKycReportUploadCommand?.signature
        ? Promise.resolve(JSON.parse(a.data.requestKycReportUploadCommand.signature))
        : Promise.reject('Invalid upload signature')
      )
      .then(r => performUpload(file, r.url, r.fields).then(() => r))
      .then(r => callback(r.fields.key))
      .then(() => file.name)
  )

  const { status: approveStatus, execute: approveKycSubmission } = useAsync(
    (amlKey: string, kycKey?: string) => graphql<ApproveKycSubmissionCommandMutation, ApproveKycSubmissionCommandMutationVariables>(kycKey !== undefined ? {
      query: approveKycSubmissionCommand,
      variables: {
        input: {
          userId: submission.userId,
          amlReportFileKey: amlKey,
          kycReportFileKey: kycKey
        }
      }
    }
    :
    {
      query: approveKycSubmissionCommand,
      variables: {
        input: {
          userId: submission.userId,
          amlReportFileKey: amlKey,
        }
      }
    }
    ).then(refresh)
  )


  const approve = lastAMLUpload !== undefined
    ? () => {
      console.log(lastAMLUpload, lastKYCUpload)
      approveKycSubmission(lastAMLUpload, lastKYCUpload)
    }
    : noop

  const ApproveKycButton: FC = () => (
    <Button
      type={'button'}
      onClick={approve}
      isLoading={isPending(approveStatus)}
      variant='positive'
      tw='mt-6'
      disabled={lastAMLUpload === undefined}
    >
      Approve KYC
    </Button>
  )

  return (
    <>
      <div tw='mt-6'>
        <p tw='text-indigo-600 text-lg font-bold'>AML Report</p>
        <SingleFileUploadInput state={amlReportUploadStatus} onUpload={k => uploadAmlReport(k, setLastAMLUpload)} fileTypes={imageAndDocFileTypes} />
      </div>
        <div tw='mt-6'>
          <p tw='text-indigo-600 text-lg font-bold'>Proof of address (optional)</p>
          <SingleFileUploadInput state={poaUploadStatus} onUpload={k => uploadPoa(k, setLastKYCUpload)} fileTypes={imageAndDocFileTypes} />
        </div>
      <ApproveKycButton/>
      <RejectKyc submission={submission} refresh={refresh}/>
    </>
  )
}


