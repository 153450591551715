import { RadioGroup } from 'packages/app/src/elements/RadioGroup'
import { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Card, CardContent, CardTitle, FormFieldNew, FormSection, TextArea } from '../../../../../elements'
import { Button, ButtonGroup } from '../../../../../elements/Button'
import tw from 'twin.macro'

export type RejectFormResult = {
  reasonKey: ReasonKey
  other?: string
}

type ReasonKey = 'outside-area-of-practice' | 'too-busy' | 'fee-error' | 'other'

export const RejectCaseModal: FC<{ onCancel: () => void; onReject: (reason: string) => void; isLoading: boolean }> = ({
  onCancel,
  onReject,
  isLoading,
}) => {
  const { watch, control, register, handleSubmit, errors } = useForm<RejectFormResult>()

  const submit = (values: RejectFormResult) => onReject(values.reasonKey + (values.other ? `, ${values.other}` : ''))
  const state = watch()

  return (
    <div tw="max-w-xl">
      <Card>
        <CardContent>
          <CardTitle>Why are you rejecting this instruction?</CardTitle>
        </CardContent>

        <CardContent>
          <form onSubmit={handleSubmit(submit)}>
            <FormFieldNew
              id="reason"
              error={errors.reasonKey}
              control={
                <Controller
                  name="reasonKey"
                  control={control}
                  rules={{ required: 'Please select an answer.' }}
                  render={({ onChange, value }) => (
                    <RadioGroup<ReasonKey>
                      items={[
                        {
                          label: `Outside your practice area`,
                          description: `You weren't suitable to perform this type of work.`,
                          value: 'outside-area-of-practice',
                        },
                        {
                          label: `Too busy for this matter`,
                          description: `You are unavailable or can't meet the timeline needed for this work.`,
                          value: 'too-busy',
                        },
                        {
                          label: `There is an error in the fee or calculations`,
                          description: `You believe the fee is incorrect.`,
                          value: 'fee-error',
                        },
                        {
                          label: `Something else`,
                          description: `Please provide details below.`,
                          value: 'other',
                        },
                      ]}
                      groupName="option"
                      setValue={onChange}
                      value={value}
                    />
                  )}
                />
              }
            />

            {(state.reasonKey === 'other' || state.reasonKey === 'fee-error') && (
              <FormFieldNew
                tw="mt-5"
                id="other"
                label={'Please provide more details on why you are rejecting this instruction'}
                error={errors.reasonKey}
                control={
                  <TextArea
                    id="other"
                    name="other"
                    ref={register({ required: 'Please enter a reason' })}
                    rows={5}
                    placeholder={`Enter reason details...`}
                  />
                }
              />
            )}

            <FormSection tw="mt-5">
              <ButtonGroup>
                <Button type="submit" variant="positive" isLoading={isLoading}>
                  Reject Instruction
                </Button>

                <Button type="button" variant="negative" display="border" onClick={onCancel}>
                  Cancel
                </Button>
              </ButtonGroup>
            </FormSection>
          </form>
        </CardContent>
      </Card>
    </div>
  )
}
