import { Dispatch, Reducer, SetStateAction } from "react"

export const useSelector = <State, Selected>(
  state: [State, Dispatch<SetStateAction<State>>],
  select: (t: State) => Selected,
  reduce: Reducer<State, Selected>
) => {
  const [originalState, setOriginalState] = state
  const selectedState = select(originalState)

  const setSelectedState: Dispatch<SetStateAction<Selected>> =
    selected => setOriginalState(state => reduce(
      state,
      selected instanceof Function
        ? selected(select(state))
        : selected
    ))

  return [selectedState, setSelectedState] as const
}
