import { Auth } from 'aws-amplify'
import Axios, { AxiosRequestConfig } from 'axios'
import { toError } from 'fp-ts/es6/Either'
import { flow } from 'fp-ts/es6/function'
import { toNetworkError } from './fetch'

const API_URL = process.env.REACT_APP_BACKEND_URL

async function authRequestInterceptor(config: AxiosRequestConfig) {
  try {
    const session = await Auth.currentSession()
    const token = session.getAccessToken().getJwtToken()
    config.headers.authorization = `Bearer ${token}`
  } catch (err) {
    flow(toError, toNetworkError)
  }

  config.headers.Accept = 'application/json'
  return config
}

export const axios = Axios.create({
  baseURL: API_URL,
})

axios.interceptors.request.use(authRequestInterceptor)
axios.interceptors.response.use(
  (response) => {
    return response.data
  },
  (error) => {
    // TODO: Add o11y for API errors
    // const message = error.response?.data?.message || error.message;
    // useNotificationStore.getState().addNotification({
    //   type: 'error',
    //   title: 'Error',
    //   message,
    // });

    return Promise.reject(error)
  }
)
