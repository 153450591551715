import { useAnalytics } from "../../hooks/useAnalytics";
import { LoginEvent, AuthEventProps, LogoutEvent } from "./types";

export const useLoginEvent = () => {
    const { trackEvent } = useAnalytics();

    return (properties: AuthEventProps) => {
        trackEvent(buildLoginEvent(properties));
    }
}

export const buildLoginEvent = (properties: AuthEventProps): LoginEvent => ({
    action: "authenticated",
    target: "login",
    description: "User logged in",
    properties,
})

export const useLogoutEvent = () => {
    const { trackEvent } = useAnalytics();

    return (properties: AuthEventProps) => {
        trackEvent(buildLogoutEvent(properties));
    }
}

export const buildLogoutEvent = (properties: AuthEventProps): LogoutEvent => ({
    action: "authenticated",
    target: "logout",
    description: "User logged out",
    properties,
})