import { getOrElse, isSome } from "fp-ts/es6/Option"
import { FC } from "react"
import "twin.macro"

import { SolicitorPaymentsView } from "packages/app/src/features/quotes/routes/SolicitorPaymentsView"
import {
  NeutralPill,
  PositivePill,
  YellowPill
} from "../../../../../elements/Badge"
import {
  FilesIcon,
  IdentificationIcon,
  MessageIcon,
  PaymentsIcon,
  SummaryIcon
} from "../../../../../elements/Icons"
import { ROUTES } from "../../../../../layout/Navigation"
import {
  CaseStatusTitle,
  CaseStatusTitleHeader,
  ComponentWithCase
} from "../../CaseDetails"
import { CaseFilesCard } from "../../shared/CaseFilesCard"
import { CaseSummaryCard } from "../../shared/CaseSummaryCard"
import { CaseTabLayout } from "../../shared/CaseTabLayout"
import { MessagesCardWithData } from "../../shared/MessagesCardWithData"
import { SolicitorClientDetailsCard } from "../shared/SolicitorClientDetailsCard"
import { RequestFollowOnQuoteButton } from "./Active"

export const CompletedStatusPanel: ComponentWithCase = ({ case: cas }) => {
  return (
    <div tw="flex flex-row items-center">
      {isSome(cas.client) && (
        <div tw="mr-12">
          <CaseStatusTitleHeader>Your client</CaseStatusTitleHeader>
          <CaseStatusTitle>
            {getOrElse(() => "")(cas.client.value.displayName)}
          </CaseStatusTitle>
        </div>
      )}

      <div tw="mr-12">
        <CaseStatusTitleHeader>Case Status</CaseStatusTitleHeader>
        <CaseStatusTitle>Completed</CaseStatusTitle>
      </div>

      <div>
        <CaseStatusTitleHeader>KYC Status</CaseStatusTitleHeader>
        <p>
          <KycStatus
            submitted={cas.clientKyc?.isSubmitted || false}
            verified={cas.clientKyc?.isVerified || false}
          />
        </p>
      </div>
    </div>
  )
}

const KycStatus: FC<{ submitted: boolean; verified: boolean }> = ({
  submitted,
  verified
}) =>
  submitted ? (
    verified ? (
      <PositivePill>Identity Verified</PositivePill>
    ) : (
      <NeutralPill>Verification in progress</NeutralPill>
    )
  ) : (
    <YellowPill>Waiting for documents</YellowPill>
  )

export const CompletedActionPanel: ComponentWithCase = ({ case: cas }) => (
  <RequestFollowOnQuoteButton cas={cas} />
)

export const CompletedDetailsPanel: ComponentWithCase = ({ case: cas }) => (
  <CaseTabLayout
    defaultRedirect={ROUTES.case.messages(cas.id)}
    config={[
      {
        label: "Messages",
        icon: <MessageIcon />,
        to: ROUTES.case.messages(cas.id),
        path: ROUTES.case.messages(":caseId"),
        render: () => <MessagesCardWithData canSend={true} />
      },
      {
        label: "Files",
        icon: <FilesIcon />,
        to: ROUTES.case.files(cas.id),
        path: ROUTES.case.files(":caseId"),
        render: () => <CaseFilesCard canUpload={true} />
      },
      {
        label: "Case Summary",
        icon: <SummaryIcon />,
        to: ROUTES.case.summary(cas.id),
        path: ROUTES.case.summary(":caseId"),
        render: () => <CaseSummaryCard case={cas} />
      },
      {
        label: "Client Identity",
        icon: <IdentificationIcon />,
        to: ROUTES.case.client(cas.id),
        path: ROUTES.case.client(":caseId"),
        render: () => <SolicitorClientDetailsCard case={cas} />
      },
      {
        label: "Payments",
        icon: <PaymentsIcon />,
        to: ROUTES.case.fees(cas.id),
        path: ROUTES.case.fees(":caseId"),
        render: () => <SolicitorPaymentsView />
      }
    ]}
  />
)
