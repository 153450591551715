import { Storage } from "@aws-amplify/storage"
import {
  clientRequestKycFileUploadCommand,
  ClientRequestKycFileUploadCommandMutation,
  ClientRequestKycFileUploadCommandMutationVariables
} from "@lawhive/generated-api"
import { default as React, FC } from "react"
import "twin.macro"

import {
  CardContent,
  imageAndDocFileTypes,
  SingleFileUploadInput
} from "../../elements"
import { performUpload } from "../../lib/files"
import { FileListItem, UPLOAD_SEPARATOR } from "../../lib/uploads"
import { useAsync } from "../../lib/useAsync"
import { graphql, noop } from "../../lib/utils"

const FilesList: FC<{ items: FileListItem[]; update: () => void }> = ({
  items,
  update
}) => {
  const formatKey = (key: string) =>
    key.split("/")[2].split(UPLOAD_SEPARATOR)[2] || key.split("/")[2] || key

  const { execute: deleteFile } = useAsync((key: string) =>
    Storage.vault.remove(key).then(update)
  )

  const download = (key: string) =>
    Storage.vault.get(key, { download: true }).then((res: any) => {
      const url = URL.createObjectURL(res.Body)
      const a = document.createElement("a")
      a.href = url
      a.download = formatKey(key) || "download"
      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(url)
          a.removeEventListener("click", clickHandler)
        }, 150)
      }
      a.addEventListener("click", clickHandler, false)
      a.click()
      return a
    })

  return (
    <div tw="mt-6">
      <ul tw="border border-gray-200 rounded-md">
        {items.length > 0 ? (
          items.map((f, i) => (
            <li
              key={`file-${i}`}
              tw="pl-3 pr-4 py-3 flex items-center justify-between border-b border-gray-200 last:border-none text-sm leading-5"
            >
              <div tw="w-0 flex-1 flex items-center">
                <svg
                  tw="flex-shrink-0 h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                    clipRule="evenodd"
                  />
                </svg>
                <button
                  tw="font-medium ml-2 truncate text-indigo-600 hover:text-indigo-500 transition duration-150 ease-in-out"
                  onClick={() => download(f.key)}
                  type="button"
                >
                  {formatKey(f.key)}
                </button>
              </div>
              <div tw="ml-4 flex-shrink-0">
                <button
                  tw="font-medium text-indigo-600 hover:text-indigo-500 transition duration-150 ease-in-out"
                  onClick={() => deleteFile(f.key)}
                  type="button"
                >
                  Delete
                </button>
              </div>
            </li>
          ))
        ) : (
          <CardContent>
            <p tw="text-sm text-gray-500">No uploads yet</p>
          </CardContent>
        )}
      </ul>
    </div>
  )
}

type KycFilesProps = {
  documentType: string
  onChange?: (files: string[]) => void
}

export const KycFiles: FC<KycFilesProps> = ({ documentType, onChange }) => {
  // const { federatedId } = useUser()

  // const { status, execute: loadFiles } = useAsync(
  //   () => Storage.vault.list(`kyc/${directory}`).then(a => a as FileListItem[])
  //     .then(a => {
  //       const fullPaths = a.map(a => `$/${a.key}`)
  //       onChange && onChange(fullPaths)
  //       return a
  //     })
  // )

  // const { status: uploadStatus, execute: upload } = useAsync(
  //   (file: File) => Storage.vault.put(`kyc/${directory}/${getFileLocation(file.name)}`, file)
  //     .then(() => { loadFiles() })
  //     .then(() => file.name)
  // )

  // const renderFiles = refreshFold(
  //   () => <Spinner />,
  //   () => <Spinner />,
  //   (e) => <ErrorMessage title="Couldn't load documents" />,
  //   (r: FileListItem[]) => <FilesList items={r} update={loadFiles} />
  // )

  // useEffect(() => { loadFiles() }, [])

  const { status: uploadStatus, execute: upload } = useAsync((file: File) =>
    graphql<
      ClientRequestKycFileUploadCommandMutation,
      ClientRequestKycFileUploadCommandMutationVariables
    >({
      query: clientRequestKycFileUploadCommand,
      variables: {
        input: {
          documentType,
          filename: file.name
        }
      }
    })
      .then((a) =>
        a.data?.clientRequestKycFileUploadCommand?.signature
          ? Promise.resolve(
              JSON.parse(a.data.clientRequestKycFileUploadCommand.signature)
            )
          : Promise.reject("Invalid upload signature")
      )
      .then((r) => performUpload(file, r.url, r.fields).then(() => r))
      .then((r) => (onChange ? onChange([r.fields.key]) : noop()))
      .then(() => file.name)
  )

  return (
    <>
      <SingleFileUploadInput
        state={uploadStatus}
        onUpload={upload}
        fileTypes={imageAndDocFileTypes}
      />
      {/* {renderFiles(status)} */}
    </>
  )
}
