import { useEffect, useReducer, useState } from 'react'
import { DatumEither, map } from '@nll/datum/DatumEither'
import { useAsync, useFromTaskEither } from '../lib/useAsync'
import { impl, matchExhaustive, Variant } from '@practical-fp/union-types'
import * as TE from 'fp-ts/lib/TaskEither'
import { pipe } from 'fp-ts/lib/function'

export type NextToken = string | null | undefined

export type DataController<E, A> = {
  data: DatumEither<E, A>
  pageIndex: number
  pageCount: number
  pageSize: number
  hasPagination: boolean
  nextPage: () => void
  previousPage: () => void
  gotoPage: (index: number) => void
}

type PaginationControllerState = {
  pageIndex: number
  pageCount: number
  nextTokens: NextToken[]
  hasPagination: boolean
}

const paginationInitialState: PaginationControllerState = {
  pageIndex: 0,
  pageCount: 1,
  nextTokens: [],
  hasPagination: false,
}

type Action =
  | Variant<'SetPageIndex', number>
  | Variant<'SetTotalCount', number>
  | Variant<'SetNextToken', NextToken>
  | Variant<'ResetPaginationData'>

const Actions = impl<Action>()

export const paginationReducer = (state: PaginationControllerState, action: Action): PaginationControllerState => {
  return matchExhaustive(action, {
    SetPageIndex: (i: number) => {
      if (i < 0 || i >= state.pageCount) {
        return { ...state }
      }
      return {
        ...state,
        pageIndex: i,
      }
    },
    SetTotalCount: (count: number) => {
      return {
        ...state,
        pageCount: count,
      }
    },
    SetNextToken: (token: NextToken) => {
      return {
        ...state,
        nextTokens: [...state.nextTokens, token],
        hasPagination: [...state.nextTokens, token].some((token) => token !== null),
      }
    },
    ResetPaginationData: () => {
      return {
        ...paginationInitialState,
      }
    },
  })
}

export const useNextTokenPaginatedData = <E, A, D>(params: {
  query: (nextToken: NextToken | null, limit: number) => TE.TaskEither<E, A>
  dataExtractor: (result: A) => D
  nextTokenExtractor: (result: A) => NextToken
  opts?: { nextToken?: NextToken; limit?: number }
}): DataController<E, D> => {
  const pageSize = params.opts?.limit || 1000

  const [state, dispatch] = useReducer(paginationReducer, paginationInitialState)

  const { status, execute } = useFromTaskEither((newNextToken?: NextToken) => pipe(
    params.query(newNextToken || params.opts?.nextToken, pageSize),
    TE.map(r => {
      const nextToken = params.nextTokenExtractor(r)

      if (!state.nextTokens.includes(nextToken)) {
        dispatch(Actions.SetNextToken(nextToken))
        if (nextToken) dispatch(Actions.SetTotalCount(state.pageCount + 1))
      }

      return r
    })
  ))

  useEffect(() => {
    execute(state.pageIndex === 0 ? null : state.nextTokens[state.pageIndex - 1])
  }, [state.pageIndex])


  const nextPage = () => dispatch(Actions.SetPageIndex(state.pageIndex + 1))
  const previousPage = () => dispatch(Actions.SetPageIndex(state.pageIndex - 1))
  const gotoPage = (index: number) => dispatch(Actions.SetPageIndex(index))
  const data = pipe(
    status,
    map(params.dataExtractor)
  )

  return {
    data,
    nextPage,
    previousPage,
    gotoPage,
    pageIndex: state.pageIndex,
    pageCount: state.pageCount,
    pageSize: pageSize,
    hasPagination: state.hasPagination,
  }
}
