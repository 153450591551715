import { Transition } from "@headlessui/react"
import { IntercomProvider, IntercomProviderProps, useIntercom } from "react-use-intercom"
import {
  ButtonHTMLAttributes,
  ComponentType,
  default as React,
  FC,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react"
import { Link } from "react-router-dom"
import "twin.macro"

import { useAuthentication } from "../contexts/Authentication"
import { useUser } from "../contexts/User"
import { ScrollingChildrenContainer } from "./Layout"
import { DesktopNavButton, DesktopNavLink, MobileNavLink, Navigation, ROUTES } from "./Navigation"
import { AdminOnly, ClientOnly, SolicitorOnly } from "./Selectors"
import { getOrElse } from "fp-ts/es6/Option"
import { useAsync } from "../lib/useAsync"
import { graphql } from "../lib/utils"
import { clientGetIntercomToken, ClientGetIntercomTokenQuery } from "@lawhive/generated-api"
import { isPending, isRefresh, isSuccess } from "@nll/datum/DatumEither"
import { useEnvironment } from "../contexts/Environment"
import { useOpenPaymentDashboard } from "../elements/solicitor/useOpenPaymentDashboard"
import { Spinner } from "../elements/Loading"
import { SolicitorOnboardingProvider, useSolicitorOnboarding } from "../scenes/solicitor/SolicitorOnboarding"
import { isSome } from "fp-ts/lib/Option"
import { useIsMonthlyBillingEnabled } from "../hooks/useFeatureFlags"

export const LoggedInShell: FC = ({ children }) => (
  <AppIntercomProvider>
    <div tw="bg-gray-100 flex-1 flex flex-col h-full">
      <IntercomBooter />
      <Navigation
        primary={<PrimaryNavigation />}
        secondary={<SecondaryNavigation />}
        mobile={dismiss => <MobileNavigation dismiss={dismiss} />}
      />
      <AdminOnly>
        <AdminBar />
      </AdminOnly>

      <ScrollingChildrenContainer>
        {children}
      </ScrollingChildrenContainer>
    </div>
  </AppIntercomProvider>
)

type AppIntercomProviderProps = Omit<IntercomProviderProps, 'appId'>

type UnreadHelpMessagesContext = {
  unreadMessagesCount: number
}

const UnreadHelpMessagesContext = React.createContext<UnreadHelpMessagesContext>({
  unreadMessagesCount: 0
})

export const useUnreadHelpMessages = () => useContext(UnreadHelpMessagesContext)

const AppIntercomProvider: FC<AppIntercomProviderProps> = (props) => {
  const { INTERCOM_APP_ID } = useEnvironment()

  const [unreadMessagesCount, setUnreadMessagesCount] = React.useState(0);

  const onUnreadCountChange = (amount: number) => {
    setUnreadMessagesCount(amount)
  }

  const context = useMemo<UnreadHelpMessagesContext>(() => ({
    unreadMessagesCount
  }), [unreadMessagesCount])

  return (
    <UnreadHelpMessagesContext.Provider value={context}>
      <IntercomProvider appId={INTERCOM_APP_ID} onUnreadCountChange={onUnreadCountChange} {...props} />
    </UnreadHelpMessagesContext.Provider>
  )
}

const IntercomBooter: FC = () => {
  const { emailAddress, userId, name, isSolicitor } = useUser()
  const { boot } = useIntercom()

  const guaranteedName = getOrElse(() => 'No name set')(name)

  const { status, execute } = useAsync(() => graphql<ClientGetIntercomTokenQuery>({
    query: clientGetIntercomToken,
  }))

  useEffect(() => { execute() }, [])

  useEffect(() => {
    if(isSuccess(status)) {
      boot({
        userId: userId,
        userHash: status.value.right.data?.clientGetIntercomToken?.token,
        name: guaranteedName,
        email: emailAddress,
        hideDefaultLauncher: true,
        customAttributes: {
          'User Type': isSolicitor ? 'solicitor' : 'client'
        }
      })
    }
  }, [status, isSolicitor])

  return null
}


const AdminBar: FC = ({ children }) => (
  <header tw="bg-indigo-600 shadow">
    <div tw="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 text-white font-semibold">
      Logged in as Admin User - Don't perform *any* actions, use Retool instead
    </div>
  </header>
)


const PrimaryNavigation = () => {

  return (
    <>
      <SolicitorOnly>
        <SolicitorOnboardingProvider>
          <DesktopNavLink to={ROUTES.solicitors.root}>Dashboard</DesktopNavLink>
          <DesktopNavLink to={ROUTES.case.root}>Cases</DesktopNavLink>
          <DesktopNavLink to={ROUTES.assessmentCalls.root()}>Assessment Calls</DesktopNavLink>
          <DesktopNavLink to={ROUTES.clientPayments.root}>Client Payments</DesktopNavLink>
          <DesktopNavLink to={ROUTES.solicitors.feeGuide.root}>Fee Guide</DesktopNavLink>
        </SolicitorOnboardingProvider>
      </SolicitorOnly>
      <ClientOnly>
        <DesktopNavLink to={ROUTES.case.root}>My Cases</DesktopNavLink>
      </ClientOnly>
      <AdminOnly>
        {/* <DesktopNavLink to={ROUTES.admin.root}>Admin Dashboard</DesktopNavLink> */}
        <DesktopNavLink to={ROUTES.case.root}>All Cases</DesktopNavLink>
        <DesktopNavLink to={ROUTES.admin.kyc.root}>KYC Submissions</DesktopNavLink>
      </AdminOnly>
    </>
  )
}

const SecondaryNavigation = () => {
  const { logout } = useAuthentication()
  const { userId } = useUser()

  const [visible, setVisible] = useState(false)
  const toggle = () => setVisible(!visible)
  const dismiss = () => setVisible(false)
  const menu = useRef<HTMLDivElement>(null)

  const isMonthlyBillingEnabled = useIsMonthlyBillingEnabled()

  const logoutAndDismiss = () => {
    dismiss()
    logout()
  }

  const handleClickOutside = (e: MouseEvent) => {
    const el = e.target
    if (el && el instanceof Node && menu.current?.contains(el)) {
      return
    }
    dismiss()
  }

  useEffect(() => {
    if (visible) {
      document.addEventListener("mousedown", handleClickOutside)
    } else {
      document.removeEventListener("mousedown", handleClickOutside)
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [visible])

  return (
    <div tw="relative" ref={menu}>
      <div>
        <DesktopNavButton onClick={toggle} id="user-menu" aria-label="User menu" aria-haspopup="true">
          <svg xmlns="http://www.w3.org/2000/svg" tw="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </DesktopNavButton>
      </div>

      <Transition
        show={visible}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div tw="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white z-30" >
          <div tw="py-1 rounded-md ring-1 ring-black ring-opacity-5" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
            <ClientOnly>
              <Link
                to={ROUTES.submitKyc.root()}
                onClick={dismiss}
                tw="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                role="menuitem"
              >
                Identity Verification
              </Link>
              <Link
                to={ROUTES.paymentMethods.root}
                onClick={dismiss}
                tw="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                role="menuitem"
              >
                Payment Methods
              </Link>
              <div tw="border-b border-gray-200"/>
            </ClientOnly>
            {isMonthlyBillingEnabled &&
              <SolicitorOnly>
                <Link
                  to={ROUTES.solicitors.plan.root}
                  onClick={dismiss}
                  tw="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                  role="menuitem"
                >
                  My Plan
                </Link>
                <div tw="border-b border-gray-200"/>
              </SolicitorOnly>
            }
            <button
              onClick={logoutAndDismiss}
              tw="block w-full px-4 py-2 text-left cursor-pointer text-sm text-gray-700 hover:bg-gray-100"
              role="menuitem"
            >
              Sign out
            </button>
          </div>
        </div>
      </Transition>
    </div>
  )
}

type DismissMenuProp = {
  dismiss: () => void
}

const MobileNavigation: FC<DismissMenuProp> = ({ dismiss }) => {
  const { logout } = useAuthentication()
  const isMonthlyBillingEnabled = useIsMonthlyBillingEnabled()

  const logoutAndDismiss = () => {
    dismiss()
    logout()
  }

  return (
    <>
      <SolicitorOnly>
        <SolicitorOnboardingProvider>
          <div tw="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <MobileNavLink to={ROUTES.solicitors.root} onClick={dismiss}>Dashboard</MobileNavLink>
            <MobileNavLink to={ROUTES.case.root} onClick={dismiss}>Cases</MobileNavLink>
            <MobileNavLink to={ROUTES.assessmentCalls.root()} onClick={dismiss}>Assessment Calls</MobileNavLink>
            <MobileNavLink to={ROUTES.clientPayments.root} onClick={dismiss}>Client Payments</MobileNavLink>
            <MobileNavLink to={ROUTES.solicitors.feeGuide.root} onClick={dismiss}>Fee Guide</MobileNavLink>
            {isMonthlyBillingEnabled && <MobileNavLink to={ROUTES.solicitors.plan.root} onClick={dismiss}>My Plan</MobileNavLink>}
          </div>
        </SolicitorOnboardingProvider>
      </SolicitorOnly>
      <ClientOnly>
        <div tw="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          <MobileNavLink to={ROUTES.case.root} onClick={dismiss}>My Cases</MobileNavLink>
          <MobileNavLink to={ROUTES.submitKyc.root()} onClick={dismiss}>Identity Verification</MobileNavLink>
          <MobileNavLink to={ROUTES.paymentMethods.root} onClick={dismiss}>Payment Methods</MobileNavLink>
        </div>
      </ClientOnly>
      <AdminOnly>
        {/* <MobileNavLink to={ROUTES.admin.root}>Admin Dashboard</MobileNavLink> */}
        <MobileNavLink to={ROUTES.case.root}>All Cases</MobileNavLink>
        <MobileNavLink to={ROUTES.admin.kyc.root}>KYC Submissions</MobileNavLink>
      </AdminOnly>
      <div tw="pt-4 pb-3 border-t border-gray-700">
        <div tw="px-2 space-y-1">
          <button
            onClick={logoutAndDismiss}
            tw="block px-3 py-2 w-full rounded-md text-left text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700">
              Sign out
          </button>
        </div>
      </div>
    </>
  )
}


export const OpenPaymentDashboardButton: FC<{ component: ComponentType<ButtonHTMLAttributes<HTMLButtonElement>> }> = ({ component: Component, children }) => {

  const { accountType } = useSolicitorOnboarding()
  const { status: dashboardStatus, execute } = useOpenPaymentDashboard()

  return isSome(accountType) && accountType.value === 'express'
    ? (
      <Component
        onClick={execute}
        disabled={isPending(dashboardStatus)}
      >
        <div tw="w-full flex justify-start md:(w-32 justify-center)">
          {(isPending(dashboardStatus) || isRefresh(dashboardStatus))
            ? <Spinner tw="h-5 text-white" />
            : <>{children}</>
          }
        </div>
      </Component>
    )
    : null
}
