/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const noopQ = /* GraphQL */ `
  query NoopQ {
    noopQ
  }
`;
export const clientGetAddressFromPostcodeQuery = /* GraphQL */ `
  query ClientGetAddressFromPostcodeQuery($postCode: String) {
    clientGetAddressFromPostcodeQuery(postCode: $postCode) {
      addresses {
        display
      }
    }
  }
`;
export const clientGetIntercomToken = /* GraphQL */ `
  query ClientGetIntercomToken {
    clientGetIntercomToken {
      token
    }
  }
`;
export const getCase = /* GraphQL */ `
  query GetCase($id: ID!) {
    getCase(id: $id) {
      id
      caseNumber
      brand
      productSKU
      hasChatbot
      payFirst
      context
      createdAt
      clientId
      client {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      clientKyc {
        isSubmitted
        isVerified
        verificationReport {
          amlReportFileKey
          kycReportFileKey
        }
        documentUploads
        identityUploads
      }
      files {
        name
        key
        lastModified
        size
      }
      details {
        type
        isTimeSensitive
        details
        summary
        ask
      }
      messages {
        items {
          id
          author {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          authorId
          case {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          caseId
          content
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      quotes {
        items {
          id
          case {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          caseId
          isLeadFromAds
          isManualFee
          lineItems {
            items {
              id
              quote {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              quoteId
              caseLineItemProductId
              product {
                id
                sku
                title
                description
                createdAt
                updatedAt
              }
              description
              clientPays
              solicitorReceives
              subsidy
              platformFee
              feePercentage
              scope {
                includes
                excludes
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          payments {
            items {
              id
              caseId
              clientId
              quoteId
              type
              stripeAccountId
              stripePaymentIntentId
              toPlatform
              amount
              refunded
              solicitorId
              chargeType
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      payments {
        items {
          id
          caseId
          clientId
          quoteId
          type
          stripeAccountId
          stripePaymentIntentId
          toPlatform
          amount
          refunded
          solicitorId
          chargeType
          createdAt
          updatedAt
        }
        nextToken
      }
      caseSolicitorId
      solicitor {
        id
        user {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        name
        businessName
        sraRegistrationNumber
        stripeAccountId
        onboardingStatus {
          hasAccount
          accountType
          hasChargesEnabled
          hasSubmitDetails
          hasPayoutsEnabled
        }
        createdAt
        updatedAt
      }
      solicitorResponse {
        type
        solicitorId
        reason
      }
      completion {
        type
        details
      }
      review {
        items {
          id
          solicitorId
          caseId
          clientId
          rating
          comment
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      assignedToSolicitorAt
      askedClientToPayAt
      statusWhenCancelled
      exportedCaseId
      updatedAt
    }
  }
`;
export const listCases = /* GraphQL */ `
  query ListCases(
    $filter: ModelCaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        caseNumber
        brand
        productSKU
        hasChatbot
        payFirst
        context
        createdAt
        clientId
        client {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        clientKyc {
          isSubmitted
          isVerified
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          documentUploads
          identityUploads
        }
        files {
          name
          key
          lastModified
          size
        }
        details {
          type
          isTimeSensitive
          details
          summary
          ask
        }
        messages {
          items {
            id
            author {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            authorId
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            content
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            isLeadFromAds
            isManualFee
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        payments {
          items {
            id
            caseId
            clientId
            quoteId
            type
            stripeAccountId
            stripePaymentIntentId
            toPlatform
            amount
            refunded
            solicitorId
            chargeType
            createdAt
            updatedAt
          }
          nextToken
        }
        caseSolicitorId
        solicitor {
          id
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          name
          businessName
          sraRegistrationNumber
          stripeAccountId
          onboardingStatus {
            hasAccount
            accountType
            hasChargesEnabled
            hasSubmitDetails
            hasPayoutsEnabled
          }
          createdAt
          updatedAt
        }
        solicitorResponse {
          type
          solicitorId
          reason
        }
        completion {
          type
          details
        }
        review {
          items {
            id
            solicitorId
            caseId
            clientId
            rating
            comment
            createdAt
            updatedAt
          }
          nextToken
        }
        status
        assignedToSolicitorAt
        askedClientToPayAt
        statusWhenCancelled
        exportedCaseId
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReview = /* GraphQL */ `
  query GetReview($id: ID!) {
    getReview(id: $id) {
      id
      solicitorId
      caseId
      clientId
      rating
      comment
      createdAt
      updatedAt
    }
  }
`;
export const listReviews = /* GraphQL */ `
  query ListReviews(
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        solicitorId
        caseId
        clientId
        rating
        comment
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCasePayment = /* GraphQL */ `
  query GetCasePayment($id: ID!) {
    getCasePayment(id: $id) {
      id
      caseId
      clientId
      quoteId
      type
      stripeAccountId
      stripePaymentIntentId
      toPlatform
      amount
      refunded
      solicitorId
      chargeType
      createdAt
      updatedAt
    }
  }
`;
export const listCasePayments = /* GraphQL */ `
  query ListCasePayments(
    $filter: ModelCasePaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCasePayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        caseId
        clientId
        quoteId
        type
        stripeAccountId
        stripePaymentIntentId
        toPlatform
        amount
        refunded
        solicitorId
        chargeType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCaseCompletionRequest = /* GraphQL */ `
  query GetCaseCompletionRequest($id: ID!) {
    getCaseCompletionRequest(id: $id) {
      id
      caseId
      case {
        id
        caseNumber
        brand
        productSKU
        hasChatbot
        payFirst
        context
        createdAt
        clientId
        client {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        clientKyc {
          isSubmitted
          isVerified
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          documentUploads
          identityUploads
        }
        files {
          name
          key
          lastModified
          size
        }
        details {
          type
          isTimeSensitive
          details
          summary
          ask
        }
        messages {
          items {
            id
            author {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            authorId
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            content
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            isLeadFromAds
            isManualFee
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        payments {
          items {
            id
            caseId
            clientId
            quoteId
            type
            stripeAccountId
            stripePaymentIntentId
            toPlatform
            amount
            refunded
            solicitorId
            chargeType
            createdAt
            updatedAt
          }
          nextToken
        }
        caseSolicitorId
        solicitor {
          id
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          name
          businessName
          sraRegistrationNumber
          stripeAccountId
          onboardingStatus {
            hasAccount
            accountType
            hasChargesEnabled
            hasSubmitDetails
            hasPayoutsEnabled
          }
          createdAt
          updatedAt
        }
        solicitorResponse {
          type
          solicitorId
          reason
        }
        completion {
          type
          details
        }
        review {
          items {
            id
            solicitorId
            caseId
            clientId
            rating
            comment
            createdAt
            updatedAt
          }
          nextToken
        }
        status
        assignedToSolicitorAt
        askedClientToPayAt
        statusWhenCancelled
        exportedCaseId
        updatedAt
      }
      requesterId
      requester {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      accepted
      createdAt
      updatedAt
    }
  }
`;
export const listCaseCompletionRequests = /* GraphQL */ `
  query ListCaseCompletionRequests(
    $filter: ModelCaseCompletionRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCaseCompletionRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        caseId
        case {
          id
          caseNumber
          brand
          productSKU
          hasChatbot
          payFirst
          context
          createdAt
          clientId
          client {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          clientKyc {
            isSubmitted
            isVerified
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            documentUploads
            identityUploads
          }
          files {
            name
            key
            lastModified
            size
          }
          details {
            type
            isTimeSensitive
            details
            summary
            ask
          }
          messages {
            items {
              id
              author {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              authorId
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              content
              type
              createdAt
              updatedAt
            }
            nextToken
          }
          quotes {
            items {
              id
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              isLeadFromAds
              isManualFee
              lineItems {
                nextToken
              }
              payments {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          payments {
            items {
              id
              caseId
              clientId
              quoteId
              type
              stripeAccountId
              stripePaymentIntentId
              toPlatform
              amount
              refunded
              solicitorId
              chargeType
              createdAt
              updatedAt
            }
            nextToken
          }
          caseSolicitorId
          solicitor {
            id
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            name
            businessName
            sraRegistrationNumber
            stripeAccountId
            onboardingStatus {
              hasAccount
              accountType
              hasChargesEnabled
              hasSubmitDetails
              hasPayoutsEnabled
            }
            createdAt
            updatedAt
          }
          solicitorResponse {
            type
            solicitorId
            reason
          }
          completion {
            type
            details
          }
          review {
            items {
              id
              solicitorId
              caseId
              clientId
              rating
              comment
              createdAt
              updatedAt
            }
            nextToken
          }
          status
          assignedToSolicitorAt
          askedClientToPayAt
          statusWhenCancelled
          exportedCaseId
          updatedAt
        }
        requesterId
        requester {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        accepted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getConfiguration = /* GraphQL */ `
  query GetConfiguration($key: String!) {
    getConfiguration(key: $key) {
      key
      value
      createdAt
      updatedAt
    }
  }
`;
export const listConfigurations = /* GraphQL */ `
  query ListConfigurations(
    $key: String
    $filter: ModelConfigurationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listConfigurations(
      key: $key
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        key
        value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getKycSubmission = /* GraphQL */ `
  query GetKycSubmission($userId: ID!) {
    getKYCSubmission(userId: $userId) {
      userId
      user {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      status
      address
      documentUploads
      identityUploads
      verificationReport {
        amlReportFileKey
        kycReportFileKey
      }
      rejectionReason {
        reason
        instructions
      }
      createdAt
      updatedAt
    }
  }
`;
export const listKycSubmissions = /* GraphQL */ `
  query ListKycSubmissions(
    $userId: ID
    $filter: ModelKYCSubmissionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listKYCSubmissions(
      userId: $userId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userId
        user {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        status
        address
        documentUploads
        identityUploads
        verificationReport {
          amlReportFileKey
          kycReportFileKey
        }
        rejectionReason {
          reason
          instructions
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotifcationEmail = /* GraphQL */ `
  query GetNotifcationEmail($id: ID!) {
    getNotifcationEmail(id: $id) {
      id
      user {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      userId
      caseId
      case {
        id
        caseNumber
        brand
        productSKU
        hasChatbot
        payFirst
        context
        createdAt
        clientId
        client {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        clientKyc {
          isSubmitted
          isVerified
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          documentUploads
          identityUploads
        }
        files {
          name
          key
          lastModified
          size
        }
        details {
          type
          isTimeSensitive
          details
          summary
          ask
        }
        messages {
          items {
            id
            author {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            authorId
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            content
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            isLeadFromAds
            isManualFee
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        payments {
          items {
            id
            caseId
            clientId
            quoteId
            type
            stripeAccountId
            stripePaymentIntentId
            toPlatform
            amount
            refunded
            solicitorId
            chargeType
            createdAt
            updatedAt
          }
          nextToken
        }
        caseSolicitorId
        solicitor {
          id
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          name
          businessName
          sraRegistrationNumber
          stripeAccountId
          onboardingStatus {
            hasAccount
            accountType
            hasChargesEnabled
            hasSubmitDetails
            hasPayoutsEnabled
          }
          createdAt
          updatedAt
        }
        solicitorResponse {
          type
          solicitorId
          reason
        }
        completion {
          type
          details
        }
        review {
          items {
            id
            solicitorId
            caseId
            clientId
            rating
            comment
            createdAt
            updatedAt
          }
          nextToken
        }
        status
        assignedToSolicitorAt
        askedClientToPayAt
        statusWhenCancelled
        exportedCaseId
        updatedAt
      }
      type
      lastSentAt
      createdAt
      updatedAt
    }
  }
`;
export const listNotifcationEmails = /* GraphQL */ `
  query ListNotifcationEmails(
    $filter: ModelNotifcationEmailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifcationEmails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        userId
        caseId
        case {
          id
          caseNumber
          brand
          productSKU
          hasChatbot
          payFirst
          context
          createdAt
          clientId
          client {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          clientKyc {
            isSubmitted
            isVerified
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            documentUploads
            identityUploads
          }
          files {
            name
            key
            lastModified
            size
          }
          details {
            type
            isTimeSensitive
            details
            summary
            ask
          }
          messages {
            items {
              id
              author {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              authorId
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              content
              type
              createdAt
              updatedAt
            }
            nextToken
          }
          quotes {
            items {
              id
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              isLeadFromAds
              isManualFee
              lineItems {
                nextToken
              }
              payments {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          payments {
            items {
              id
              caseId
              clientId
              quoteId
              type
              stripeAccountId
              stripePaymentIntentId
              toPlatform
              amount
              refunded
              solicitorId
              chargeType
              createdAt
              updatedAt
            }
            nextToken
          }
          caseSolicitorId
          solicitor {
            id
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            name
            businessName
            sraRegistrationNumber
            stripeAccountId
            onboardingStatus {
              hasAccount
              accountType
              hasChargesEnabled
              hasSubmitDetails
              hasPayoutsEnabled
            }
            createdAt
            updatedAt
          }
          solicitorResponse {
            type
            solicitorId
            reason
          }
          completion {
            type
            details
          }
          review {
            items {
              id
              solicitorId
              caseId
              clientId
              rating
              comment
              createdAt
              updatedAt
            }
            nextToken
          }
          status
          assignedToSolicitorAt
          askedClientToPayAt
          statusWhenCancelled
          exportedCaseId
          updatedAt
        }
        type
        lastSentAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      sku
      title
      description
      lineItems {
        items {
          id
          quote {
            id
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            isLeadFromAds
            isManualFee
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          quoteId
          caseLineItemProductId
          product {
            id
            sku
            title
            description
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          description
          clientPays
          solicitorReceives
          subsidy
          platformFee
          feePercentage
          scope {
            includes
            excludes
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sku
        title
        description
        lineItems {
          items {
            id
            quote {
              id
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              isLeadFromAds
              isManualFee
              lineItems {
                nextToken
              }
              payments {
                nextToken
              }
              createdAt
              updatedAt
            }
            quoteId
            caseLineItemProductId
            product {
              id
              sku
              title
              description
              lineItems {
                nextToken
              }
              createdAt
              updatedAt
            }
            description
            clientPays
            solicitorReceives
            subsidy
            platformFee
            feePercentage
            scope {
              includes
              excludes
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSolicitor = /* GraphQL */ `
  query GetSolicitor($id: ID!) {
    getSolicitor(id: $id) {
      id
      user {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      name
      businessName
      sraRegistrationNumber
      stripeAccountId
      onboardingStatus {
        hasAccount
        accountType
        hasChargesEnabled
        hasSubmitDetails
        hasPayoutsEnabled
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSolicitors = /* GraphQL */ `
  query ListSolicitors(
    $filter: ModelSolicitorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSolicitors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        name
        businessName
        sraRegistrationNumber
        stripeAccountId
        onboardingStatus {
          hasAccount
          accountType
          hasChargesEnabled
          hasSubmitDetails
          hasPayoutsEnabled
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      type
      emailAddress
      displayName
      phoneNumber
      kycSubmission {
        userId
        user {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        status
        address
        documentUploads
        identityUploads
        verificationReport {
          amlReportFileKey
          kycReportFileKey
        }
        rejectionReason {
          reason
          instructions
        }
        createdAt
        updatedAt
      }
      stripeCustomerId
      paymentMethods {
        id
        lastFour
        brand
        expiryMonth
        expiryYear
      }
      clientCases {
        items {
          id
          caseNumber
          brand
          productSKU
          hasChatbot
          payFirst
          context
          createdAt
          clientId
          client {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          clientKyc {
            isSubmitted
            isVerified
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            documentUploads
            identityUploads
          }
          files {
            name
            key
            lastModified
            size
          }
          details {
            type
            isTimeSensitive
            details
            summary
            ask
          }
          messages {
            items {
              id
              author {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              authorId
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              content
              type
              createdAt
              updatedAt
            }
            nextToken
          }
          quotes {
            items {
              id
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              isLeadFromAds
              isManualFee
              lineItems {
                nextToken
              }
              payments {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          payments {
            items {
              id
              caseId
              clientId
              quoteId
              type
              stripeAccountId
              stripePaymentIntentId
              toPlatform
              amount
              refunded
              solicitorId
              chargeType
              createdAt
              updatedAt
            }
            nextToken
          }
          caseSolicitorId
          solicitor {
            id
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            name
            businessName
            sraRegistrationNumber
            stripeAccountId
            onboardingStatus {
              hasAccount
              accountType
              hasChargesEnabled
              hasSubmitDetails
              hasPayoutsEnabled
            }
            createdAt
            updatedAt
          }
          solicitorResponse {
            type
            solicitorId
            reason
          }
          completion {
            type
            details
          }
          review {
            items {
              id
              solicitorId
              caseId
              clientId
              rating
              comment
              createdAt
              updatedAt
            }
            nextToken
          }
          status
          assignedToSolicitorAt
          askedClientToPayAt
          statusWhenCancelled
          exportedCaseId
          updatedAt
        }
        nextToken
      }
      solicitorCases {
        items {
          id
          caseNumber
          brand
          productSKU
          hasChatbot
          payFirst
          context
          createdAt
          clientId
          client {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          clientKyc {
            isSubmitted
            isVerified
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            documentUploads
            identityUploads
          }
          files {
            name
            key
            lastModified
            size
          }
          details {
            type
            isTimeSensitive
            details
            summary
            ask
          }
          messages {
            items {
              id
              author {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              authorId
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              content
              type
              createdAt
              updatedAt
            }
            nextToken
          }
          quotes {
            items {
              id
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              isLeadFromAds
              isManualFee
              lineItems {
                nextToken
              }
              payments {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          payments {
            items {
              id
              caseId
              clientId
              quoteId
              type
              stripeAccountId
              stripePaymentIntentId
              toPlatform
              amount
              refunded
              solicitorId
              chargeType
              createdAt
              updatedAt
            }
            nextToken
          }
          caseSolicitorId
          solicitor {
            id
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            name
            businessName
            sraRegistrationNumber
            stripeAccountId
            onboardingStatus {
              hasAccount
              accountType
              hasChargesEnabled
              hasSubmitDetails
              hasPayoutsEnabled
            }
            createdAt
            updatedAt
          }
          solicitorResponse {
            type
            solicitorId
            reason
          }
          completion {
            type
            details
          }
          review {
            items {
              id
              solicitorId
              caseId
              clientId
              rating
              comment
              createdAt
              updatedAt
            }
            nextToken
          }
          status
          assignedToSolicitorAt
          askedClientToPayAt
          statusWhenCancelled
          exportedCaseId
          updatedAt
        }
        nextToken
      }
      referral
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCasesByStatus = /* GraphQL */ `
  query ListCasesByStatus(
    $status: CaseStatus
    $sortDirection: ModelSortDirection
    $filter: ModelCaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCasesByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        caseNumber
        brand
        productSKU
        hasChatbot
        payFirst
        context
        createdAt
        clientId
        client {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        clientKyc {
          isSubmitted
          isVerified
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          documentUploads
          identityUploads
        }
        files {
          name
          key
          lastModified
          size
        }
        details {
          type
          isTimeSensitive
          details
          summary
          ask
        }
        messages {
          items {
            id
            author {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            authorId
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            content
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            isLeadFromAds
            isManualFee
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        payments {
          items {
            id
            caseId
            clientId
            quoteId
            type
            stripeAccountId
            stripePaymentIntentId
            toPlatform
            amount
            refunded
            solicitorId
            chargeType
            createdAt
            updatedAt
          }
          nextToken
        }
        caseSolicitorId
        solicitor {
          id
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          name
          businessName
          sraRegistrationNumber
          stripeAccountId
          onboardingStatus {
            hasAccount
            accountType
            hasChargesEnabled
            hasSubmitDetails
            hasPayoutsEnabled
          }
          createdAt
          updatedAt
        }
        solicitorResponse {
          type
          solicitorId
          reason
        }
        completion {
          type
          details
        }
        review {
          items {
            id
            solicitorId
            caseId
            clientId
            rating
            comment
            createdAt
            updatedAt
          }
          nextToken
        }
        status
        assignedToSolicitorAt
        askedClientToPayAt
        statusWhenCancelled
        exportedCaseId
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCasesByStatusCreatedAt = /* GraphQL */ `
  query ListCasesByStatusCreatedAt(
    $status: CaseStatus
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCasesByStatusCreatedAt(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        caseNumber
        brand
        productSKU
        hasChatbot
        payFirst
        context
        createdAt
        clientId
        client {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        clientKyc {
          isSubmitted
          isVerified
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          documentUploads
          identityUploads
        }
        files {
          name
          key
          lastModified
          size
        }
        details {
          type
          isTimeSensitive
          details
          summary
          ask
        }
        messages {
          items {
            id
            author {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            authorId
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            content
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            isLeadFromAds
            isManualFee
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        payments {
          items {
            id
            caseId
            clientId
            quoteId
            type
            stripeAccountId
            stripePaymentIntentId
            toPlatform
            amount
            refunded
            solicitorId
            chargeType
            createdAt
            updatedAt
          }
          nextToken
        }
        caseSolicitorId
        solicitor {
          id
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          name
          businessName
          sraRegistrationNumber
          stripeAccountId
          onboardingStatus {
            hasAccount
            accountType
            hasChargesEnabled
            hasSubmitDetails
            hasPayoutsEnabled
          }
          createdAt
          updatedAt
        }
        solicitorResponse {
          type
          solicitorId
          reason
        }
        completion {
          type
          details
        }
        review {
          items {
            id
            solicitorId
            caseId
            clientId
            rating
            comment
            createdAt
            updatedAt
          }
          nextToken
        }
        status
        assignedToSolicitorAt
        askedClientToPayAt
        statusWhenCancelled
        exportedCaseId
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCasesByStatusAskedClientToPayAt = /* GraphQL */ `
  query ListCasesByStatusAskedClientToPayAt(
    $status: CaseStatus
    $askedClientToPayAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCasesByStatusAskedClientToPayAt(
      status: $status
      askedClientToPayAt: $askedClientToPayAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        caseNumber
        brand
        productSKU
        hasChatbot
        payFirst
        context
        createdAt
        clientId
        client {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        clientKyc {
          isSubmitted
          isVerified
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          documentUploads
          identityUploads
        }
        files {
          name
          key
          lastModified
          size
        }
        details {
          type
          isTimeSensitive
          details
          summary
          ask
        }
        messages {
          items {
            id
            author {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            authorId
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            content
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            isLeadFromAds
            isManualFee
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        payments {
          items {
            id
            caseId
            clientId
            quoteId
            type
            stripeAccountId
            stripePaymentIntentId
            toPlatform
            amount
            refunded
            solicitorId
            chargeType
            createdAt
            updatedAt
          }
          nextToken
        }
        caseSolicitorId
        solicitor {
          id
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          name
          businessName
          sraRegistrationNumber
          stripeAccountId
          onboardingStatus {
            hasAccount
            accountType
            hasChargesEnabled
            hasSubmitDetails
            hasPayoutsEnabled
          }
          createdAt
          updatedAt
        }
        solicitorResponse {
          type
          solicitorId
          reason
        }
        completion {
          type
          details
        }
        review {
          items {
            id
            solicitorId
            caseId
            clientId
            rating
            comment
            createdAt
            updatedAt
          }
          nextToken
        }
        status
        assignedToSolicitorAt
        askedClientToPayAt
        statusWhenCancelled
        exportedCaseId
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCasesByNumber = /* GraphQL */ `
  query ListCasesByNumber(
    $caseNumber: String
    $sortDirection: ModelSortDirection
    $filter: ModelCaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCasesByNumber(
      caseNumber: $caseNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        caseNumber
        brand
        productSKU
        hasChatbot
        payFirst
        context
        createdAt
        clientId
        client {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        clientKyc {
          isSubmitted
          isVerified
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          documentUploads
          identityUploads
        }
        files {
          name
          key
          lastModified
          size
        }
        details {
          type
          isTimeSensitive
          details
          summary
          ask
        }
        messages {
          items {
            id
            author {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            authorId
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            content
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            isLeadFromAds
            isManualFee
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        payments {
          items {
            id
            caseId
            clientId
            quoteId
            type
            stripeAccountId
            stripePaymentIntentId
            toPlatform
            amount
            refunded
            solicitorId
            chargeType
            createdAt
            updatedAt
          }
          nextToken
        }
        caseSolicitorId
        solicitor {
          id
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          name
          businessName
          sraRegistrationNumber
          stripeAccountId
          onboardingStatus {
            hasAccount
            accountType
            hasChargesEnabled
            hasSubmitDetails
            hasPayoutsEnabled
          }
          createdAt
          updatedAt
        }
        solicitorResponse {
          type
          solicitorId
          reason
        }
        completion {
          type
          details
        }
        review {
          items {
            id
            solicitorId
            caseId
            clientId
            rating
            comment
            createdAt
            updatedAt
          }
          nextToken
        }
        status
        assignedToSolicitorAt
        askedClientToPayAt
        statusWhenCancelled
        exportedCaseId
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCasesByClient = /* GraphQL */ `
  query ListCasesByClient(
    $clientId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCasesByClient(
      clientId: $clientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        caseNumber
        brand
        productSKU
        hasChatbot
        payFirst
        context
        createdAt
        clientId
        client {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        clientKyc {
          isSubmitted
          isVerified
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          documentUploads
          identityUploads
        }
        files {
          name
          key
          lastModified
          size
        }
        details {
          type
          isTimeSensitive
          details
          summary
          ask
        }
        messages {
          items {
            id
            author {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            authorId
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            content
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            isLeadFromAds
            isManualFee
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        payments {
          items {
            id
            caseId
            clientId
            quoteId
            type
            stripeAccountId
            stripePaymentIntentId
            toPlatform
            amount
            refunded
            solicitorId
            chargeType
            createdAt
            updatedAt
          }
          nextToken
        }
        caseSolicitorId
        solicitor {
          id
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          name
          businessName
          sraRegistrationNumber
          stripeAccountId
          onboardingStatus {
            hasAccount
            accountType
            hasChargesEnabled
            hasSubmitDetails
            hasPayoutsEnabled
          }
          createdAt
          updatedAt
        }
        solicitorResponse {
          type
          solicitorId
          reason
        }
        completion {
          type
          details
        }
        review {
          items {
            id
            solicitorId
            caseId
            clientId
            rating
            comment
            createdAt
            updatedAt
          }
          nextToken
        }
        status
        assignedToSolicitorAt
        askedClientToPayAt
        statusWhenCancelled
        exportedCaseId
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCasesByClientStatus = /* GraphQL */ `
  query ListCasesByClientStatus(
    $clientId: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCasesByClientStatus(
      clientId: $clientId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        caseNumber
        brand
        productSKU
        hasChatbot
        payFirst
        context
        createdAt
        clientId
        client {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        clientKyc {
          isSubmitted
          isVerified
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          documentUploads
          identityUploads
        }
        files {
          name
          key
          lastModified
          size
        }
        details {
          type
          isTimeSensitive
          details
          summary
          ask
        }
        messages {
          items {
            id
            author {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            authorId
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            content
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            isLeadFromAds
            isManualFee
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        payments {
          items {
            id
            caseId
            clientId
            quoteId
            type
            stripeAccountId
            stripePaymentIntentId
            toPlatform
            amount
            refunded
            solicitorId
            chargeType
            createdAt
            updatedAt
          }
          nextToken
        }
        caseSolicitorId
        solicitor {
          id
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          name
          businessName
          sraRegistrationNumber
          stripeAccountId
          onboardingStatus {
            hasAccount
            accountType
            hasChargesEnabled
            hasSubmitDetails
            hasPayoutsEnabled
          }
          createdAt
          updatedAt
        }
        solicitorResponse {
          type
          solicitorId
          reason
        }
        completion {
          type
          details
        }
        review {
          items {
            id
            solicitorId
            caseId
            clientId
            rating
            comment
            createdAt
            updatedAt
          }
          nextToken
        }
        status
        assignedToSolicitorAt
        askedClientToPayAt
        statusWhenCancelled
        exportedCaseId
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCasesByBrand = /* GraphQL */ `
  query ListCasesByBrand(
    $brand: String
    $sortDirection: ModelSortDirection
    $filter: ModelCaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCasesByBrand(
      brand: $brand
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        caseNumber
        brand
        productSKU
        hasChatbot
        payFirst
        context
        createdAt
        clientId
        client {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        clientKyc {
          isSubmitted
          isVerified
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          documentUploads
          identityUploads
        }
        files {
          name
          key
          lastModified
          size
        }
        details {
          type
          isTimeSensitive
          details
          summary
          ask
        }
        messages {
          items {
            id
            author {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            authorId
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            content
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            isLeadFromAds
            isManualFee
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        payments {
          items {
            id
            caseId
            clientId
            quoteId
            type
            stripeAccountId
            stripePaymentIntentId
            toPlatform
            amount
            refunded
            solicitorId
            chargeType
            createdAt
            updatedAt
          }
          nextToken
        }
        caseSolicitorId
        solicitor {
          id
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          name
          businessName
          sraRegistrationNumber
          stripeAccountId
          onboardingStatus {
            hasAccount
            accountType
            hasChargesEnabled
            hasSubmitDetails
            hasPayoutsEnabled
          }
          createdAt
          updatedAt
        }
        solicitorResponse {
          type
          solicitorId
          reason
        }
        completion {
          type
          details
        }
        review {
          items {
            id
            solicitorId
            caseId
            clientId
            rating
            comment
            createdAt
            updatedAt
          }
          nextToken
        }
        status
        assignedToSolicitorAt
        askedClientToPayAt
        statusWhenCancelled
        exportedCaseId
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCasesBySolicitor = /* GraphQL */ `
  query ListCasesBySolicitor(
    $caseSolicitorId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCasesBySolicitor(
      caseSolicitorId: $caseSolicitorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        caseNumber
        brand
        productSKU
        hasChatbot
        payFirst
        context
        createdAt
        clientId
        client {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        clientKyc {
          isSubmitted
          isVerified
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          documentUploads
          identityUploads
        }
        files {
          name
          key
          lastModified
          size
        }
        details {
          type
          isTimeSensitive
          details
          summary
          ask
        }
        messages {
          items {
            id
            author {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            authorId
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            content
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            isLeadFromAds
            isManualFee
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        payments {
          items {
            id
            caseId
            clientId
            quoteId
            type
            stripeAccountId
            stripePaymentIntentId
            toPlatform
            amount
            refunded
            solicitorId
            chargeType
            createdAt
            updatedAt
          }
          nextToken
        }
        caseSolicitorId
        solicitor {
          id
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          name
          businessName
          sraRegistrationNumber
          stripeAccountId
          onboardingStatus {
            hasAccount
            accountType
            hasChargesEnabled
            hasSubmitDetails
            hasPayoutsEnabled
          }
          createdAt
          updatedAt
        }
        solicitorResponse {
          type
          solicitorId
          reason
        }
        completion {
          type
          details
        }
        review {
          items {
            id
            solicitorId
            caseId
            clientId
            rating
            comment
            createdAt
            updatedAt
          }
          nextToken
        }
        status
        assignedToSolicitorAt
        askedClientToPayAt
        statusWhenCancelled
        exportedCaseId
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCasesBySolicitorStatus = /* GraphQL */ `
  query ListCasesBySolicitorStatus(
    $caseSolicitorId: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCasesBySolicitorStatus(
      caseSolicitorId: $caseSolicitorId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        caseNumber
        brand
        productSKU
        hasChatbot
        payFirst
        context
        createdAt
        clientId
        client {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        clientKyc {
          isSubmitted
          isVerified
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          documentUploads
          identityUploads
        }
        files {
          name
          key
          lastModified
          size
        }
        details {
          type
          isTimeSensitive
          details
          summary
          ask
        }
        messages {
          items {
            id
            author {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            authorId
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            content
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            isLeadFromAds
            isManualFee
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        payments {
          items {
            id
            caseId
            clientId
            quoteId
            type
            stripeAccountId
            stripePaymentIntentId
            toPlatform
            amount
            refunded
            solicitorId
            chargeType
            createdAt
            updatedAt
          }
          nextToken
        }
        caseSolicitorId
        solicitor {
          id
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          name
          businessName
          sraRegistrationNumber
          stripeAccountId
          onboardingStatus {
            hasAccount
            accountType
            hasChargesEnabled
            hasSubmitDetails
            hasPayoutsEnabled
          }
          createdAt
          updatedAt
        }
        solicitorResponse {
          type
          solicitorId
          reason
        }
        completion {
          type
          details
        }
        review {
          items {
            id
            solicitorId
            caseId
            clientId
            rating
            comment
            createdAt
            updatedAt
          }
          nextToken
        }
        status
        assignedToSolicitorAt
        askedClientToPayAt
        statusWhenCancelled
        exportedCaseId
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCasePaymentsByCase = /* GraphQL */ `
  query ListCasePaymentsByCase(
    $caseId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCasePaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCasePaymentsByCase(
      caseId: $caseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        caseId
        clientId
        quoteId
        type
        stripeAccountId
        stripePaymentIntentId
        toPlatform
        amount
        refunded
        solicitorId
        chargeType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCasePaymentsByCaseType = /* GraphQL */ `
  query ListCasePaymentsByCaseType(
    $caseId: ID
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCasePaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCasePaymentsByCaseType(
      caseId: $caseId
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        caseId
        clientId
        quoteId
        type
        stripeAccountId
        stripePaymentIntentId
        toPlatform
        amount
        refunded
        solicitorId
        chargeType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCasePaymentsByClient = /* GraphQL */ `
  query ListCasePaymentsByClient(
    $clientId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCasePaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCasePaymentsByClient(
      clientId: $clientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        caseId
        clientId
        quoteId
        type
        stripeAccountId
        stripePaymentIntentId
        toPlatform
        amount
        refunded
        solicitorId
        chargeType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCasePaymentsByQuote = /* GraphQL */ `
  query ListCasePaymentsByQuote(
    $quoteId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCasePaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCasePaymentsByQuote(
      quoteId: $quoteId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        caseId
        clientId
        quoteId
        type
        stripeAccountId
        stripePaymentIntentId
        toPlatform
        amount
        refunded
        solicitorId
        chargeType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCasePaymentByStripePaymentIntent = /* GraphQL */ `
  query ListCasePaymentByStripePaymentIntent(
    $stripePaymentIntentId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCasePaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCasePaymentByStripePaymentIntent(
      stripePaymentIntentId: $stripePaymentIntentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        caseId
        clientId
        quoteId
        type
        stripeAccountId
        stripePaymentIntentId
        toPlatform
        amount
        refunded
        solicitorId
        chargeType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCaseCompletionRequestByCase = /* GraphQL */ `
  query ListCaseCompletionRequestByCase(
    $caseId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCaseCompletionRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCaseCompletionRequestByCase(
      caseId: $caseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        caseId
        case {
          id
          caseNumber
          brand
          productSKU
          hasChatbot
          payFirst
          context
          createdAt
          clientId
          client {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          clientKyc {
            isSubmitted
            isVerified
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            documentUploads
            identityUploads
          }
          files {
            name
            key
            lastModified
            size
          }
          details {
            type
            isTimeSensitive
            details
            summary
            ask
          }
          messages {
            items {
              id
              author {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              authorId
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              content
              type
              createdAt
              updatedAt
            }
            nextToken
          }
          quotes {
            items {
              id
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              isLeadFromAds
              isManualFee
              lineItems {
                nextToken
              }
              payments {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          payments {
            items {
              id
              caseId
              clientId
              quoteId
              type
              stripeAccountId
              stripePaymentIntentId
              toPlatform
              amount
              refunded
              solicitorId
              chargeType
              createdAt
              updatedAt
            }
            nextToken
          }
          caseSolicitorId
          solicitor {
            id
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            name
            businessName
            sraRegistrationNumber
            stripeAccountId
            onboardingStatus {
              hasAccount
              accountType
              hasChargesEnabled
              hasSubmitDetails
              hasPayoutsEnabled
            }
            createdAt
            updatedAt
          }
          solicitorResponse {
            type
            solicitorId
            reason
          }
          completion {
            type
            details
          }
          review {
            items {
              id
              solicitorId
              caseId
              clientId
              rating
              comment
              createdAt
              updatedAt
            }
            nextToken
          }
          status
          assignedToSolicitorAt
          askedClientToPayAt
          statusWhenCancelled
          exportedCaseId
          updatedAt
        }
        requesterId
        requester {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        accepted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCaseCompletionRequestByRequester = /* GraphQL */ `
  query ListCaseCompletionRequestByRequester(
    $requesterId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCaseCompletionRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCaseCompletionRequestByRequester(
      requesterId: $requesterId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        caseId
        case {
          id
          caseNumber
          brand
          productSKU
          hasChatbot
          payFirst
          context
          createdAt
          clientId
          client {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          clientKyc {
            isSubmitted
            isVerified
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            documentUploads
            identityUploads
          }
          files {
            name
            key
            lastModified
            size
          }
          details {
            type
            isTimeSensitive
            details
            summary
            ask
          }
          messages {
            items {
              id
              author {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              authorId
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              content
              type
              createdAt
              updatedAt
            }
            nextToken
          }
          quotes {
            items {
              id
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              isLeadFromAds
              isManualFee
              lineItems {
                nextToken
              }
              payments {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          payments {
            items {
              id
              caseId
              clientId
              quoteId
              type
              stripeAccountId
              stripePaymentIntentId
              toPlatform
              amount
              refunded
              solicitorId
              chargeType
              createdAt
              updatedAt
            }
            nextToken
          }
          caseSolicitorId
          solicitor {
            id
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            name
            businessName
            sraRegistrationNumber
            stripeAccountId
            onboardingStatus {
              hasAccount
              accountType
              hasChargesEnabled
              hasSubmitDetails
              hasPayoutsEnabled
            }
            createdAt
            updatedAt
          }
          solicitorResponse {
            type
            solicitorId
            reason
          }
          completion {
            type
            details
          }
          review {
            items {
              id
              solicitorId
              caseId
              clientId
              rating
              comment
              createdAt
              updatedAt
            }
            nextToken
          }
          status
          assignedToSolicitorAt
          askedClientToPayAt
          statusWhenCancelled
          exportedCaseId
          updatedAt
        }
        requesterId
        requester {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        accepted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCaseCompletionRequestByCaseRequester = /* GraphQL */ `
  query ListCaseCompletionRequestByCaseRequester(
    $caseId: ID
    $requesterId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCaseCompletionRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCaseCompletionRequestByCaseRequester(
      caseId: $caseId
      requesterId: $requesterId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        caseId
        case {
          id
          caseNumber
          brand
          productSKU
          hasChatbot
          payFirst
          context
          createdAt
          clientId
          client {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          clientKyc {
            isSubmitted
            isVerified
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            documentUploads
            identityUploads
          }
          files {
            name
            key
            lastModified
            size
          }
          details {
            type
            isTimeSensitive
            details
            summary
            ask
          }
          messages {
            items {
              id
              author {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              authorId
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              content
              type
              createdAt
              updatedAt
            }
            nextToken
          }
          quotes {
            items {
              id
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              isLeadFromAds
              isManualFee
              lineItems {
                nextToken
              }
              payments {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          payments {
            items {
              id
              caseId
              clientId
              quoteId
              type
              stripeAccountId
              stripePaymentIntentId
              toPlatform
              amount
              refunded
              solicitorId
              chargeType
              createdAt
              updatedAt
            }
            nextToken
          }
          caseSolicitorId
          solicitor {
            id
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            name
            businessName
            sraRegistrationNumber
            stripeAccountId
            onboardingStatus {
              hasAccount
              accountType
              hasChargesEnabled
              hasSubmitDetails
              hasPayoutsEnabled
            }
            createdAt
            updatedAt
          }
          solicitorResponse {
            type
            solicitorId
            reason
          }
          completion {
            type
            details
          }
          review {
            items {
              id
              solicitorId
              caseId
              clientId
              rating
              comment
              createdAt
              updatedAt
            }
            nextToken
          }
          status
          assignedToSolicitorAt
          askedClientToPayAt
          statusWhenCancelled
          exportedCaseId
          updatedAt
        }
        requesterId
        requester {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        accepted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listKycSubmissionsByStatus = /* GraphQL */ `
  query ListKycSubmissionsByStatus(
    $status: KYCSubmissionStatus
    $sortDirection: ModelSortDirection
    $filter: ModelKYCSubmissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKycSubmissionsByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        user {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        status
        address
        documentUploads
        identityUploads
        verificationReport {
          amlReportFileKey
          kycReportFileKey
        }
        rejectionReason {
          reason
          instructions
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listMessagesByCaseCreatedAt = /* GraphQL */ `
  query ListMessagesByCaseCreatedAt(
    $caseId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessagesByCaseCreatedAt(
      caseId: $caseId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        author {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        authorId
        case {
          id
          caseNumber
          brand
          productSKU
          hasChatbot
          payFirst
          context
          createdAt
          clientId
          client {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          clientKyc {
            isSubmitted
            isVerified
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            documentUploads
            identityUploads
          }
          files {
            name
            key
            lastModified
            size
          }
          details {
            type
            isTimeSensitive
            details
            summary
            ask
          }
          messages {
            items {
              id
              author {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              authorId
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              content
              type
              createdAt
              updatedAt
            }
            nextToken
          }
          quotes {
            items {
              id
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              isLeadFromAds
              isManualFee
              lineItems {
                nextToken
              }
              payments {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          payments {
            items {
              id
              caseId
              clientId
              quoteId
              type
              stripeAccountId
              stripePaymentIntentId
              toPlatform
              amount
              refunded
              solicitorId
              chargeType
              createdAt
              updatedAt
            }
            nextToken
          }
          caseSolicitorId
          solicitor {
            id
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            name
            businessName
            sraRegistrationNumber
            stripeAccountId
            onboardingStatus {
              hasAccount
              accountType
              hasChargesEnabled
              hasSubmitDetails
              hasPayoutsEnabled
            }
            createdAt
            updatedAt
          }
          solicitorResponse {
            type
            solicitorId
            reason
          }
          completion {
            type
            details
          }
          review {
            items {
              id
              solicitorId
              caseId
              clientId
              rating
              comment
              createdAt
              updatedAt
            }
            nextToken
          }
          status
          assignedToSolicitorAt
          askedClientToPayAt
          statusWhenCancelled
          exportedCaseId
          updatedAt
        }
        caseId
        content
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listNotificationEmailByCaseUserType = /* GraphQL */ `
  query ListNotificationEmailByCaseUserType(
    $caseId: ID
    $userIdType: ModelNotifcationEmailNotificationEmailByCaseUserTypeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotifcationEmailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotificationEmailByCaseUserType(
      caseId: $caseId
      userIdType: $userIdType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        userId
        caseId
        case {
          id
          caseNumber
          brand
          productSKU
          hasChatbot
          payFirst
          context
          createdAt
          clientId
          client {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          clientKyc {
            isSubmitted
            isVerified
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            documentUploads
            identityUploads
          }
          files {
            name
            key
            lastModified
            size
          }
          details {
            type
            isTimeSensitive
            details
            summary
            ask
          }
          messages {
            items {
              id
              author {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              authorId
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              content
              type
              createdAt
              updatedAt
            }
            nextToken
          }
          quotes {
            items {
              id
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              isLeadFromAds
              isManualFee
              lineItems {
                nextToken
              }
              payments {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          payments {
            items {
              id
              caseId
              clientId
              quoteId
              type
              stripeAccountId
              stripePaymentIntentId
              toPlatform
              amount
              refunded
              solicitorId
              chargeType
              createdAt
              updatedAt
            }
            nextToken
          }
          caseSolicitorId
          solicitor {
            id
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            name
            businessName
            sraRegistrationNumber
            stripeAccountId
            onboardingStatus {
              hasAccount
              accountType
              hasChargesEnabled
              hasSubmitDetails
              hasPayoutsEnabled
            }
            createdAt
            updatedAt
          }
          solicitorResponse {
            type
            solicitorId
            reason
          }
          completion {
            type
            details
          }
          review {
            items {
              id
              solicitorId
              caseId
              clientId
              rating
              comment
              createdAt
              updatedAt
            }
            nextToken
          }
          status
          assignedToSolicitorAt
          askedClientToPayAt
          statusWhenCancelled
          exportedCaseId
          updatedAt
        }
        type
        lastSentAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUsersByType = /* GraphQL */ `
  query ListUsersByType(
    $type: UserType
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUsersByEmailAddress = /* GraphQL */ `
  query ListUsersByEmailAddress(
    $emailAddress: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByEmailAddress(
      emailAddress: $emailAddress
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
