import { pipe } from "fp-ts/lib/function"
import * as D from "io-ts/Decoder"
import { castTo, fetchParsedJSON } from "../../../lib/fetch"
import { useApiFetch } from "../../../lib/useApiClient"
import { SolicitorQuote } from "./useCreateSolicitorQuoteNew"

type CreateSolicitorQuoteResult = { quote: { id: string }, caseQuote: { id: string, caseId: string, quoteId: string } }

export type CreateFollowOnQuoteCommand = D.TypeOf<typeof CreateFollowOnQuoteCommand>

export const CreateFollowOnQuoteCommand = pipe(
  SolicitorQuote,
  D.intersect(D.struct({
    caseId: D.string,
    description: D.string
  }))
)

export const useCreateFollowOnQuote = () => {
  const api = useApiFetch(fetchParsedJSON(castTo<CreateSolicitorQuoteResult>()))

  return (command: CreateFollowOnQuoteCommand) => api(`quotes/solicitors/follow-on`, {
    method: "POST",
    body: JSON.stringify(command)
  })
}
