import 'twin.macro'

import { ButtonLink } from "packages/app/src/elements/Button"
import { Card, CardContent } from 'packages/app/src/elements'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { ROUTES } from 'packages/app/src/layout/Navigation'
import { ChargingModel, useGetChargeAccount } from 'packages/app/src/hooks/useGetChargeAccount'
import { useFromTaskEither } from 'packages/app/src/lib/useAsync'
import { useEffect } from 'react'
import { renderDatum } from 'packages/app/src/lib/utils'
import { matchExhaustive } from '@practical-fp/union-types'

export const ChargeAccountActionCell = () => {
  const getChargeAccount = useGetChargeAccount()

  const { execute, status } = useFromTaskEither(getChargeAccount)

  useEffect(() => { execute() }, [])

  return pipe(
    status,
    renderDatum(
      () => null,
      () => null,
      (chargeAccount) => (
        <ChargeAccountActionPanel
          needsAction={pipe(chargeAccount, O.map(ac => ac.needsSubscription), O.getOrElseW(() => false))}
          chargingModel={pipe(
            chargeAccount,
            O.map(a => a.model),
            O.getOrElse(() => ChargingModel.PayByCase({}) as ChargingModel)
          )}
        />
      )
    )
  )
}

type ChargeAccountActionPanelProps = {
  needsAction: boolean
  chargingModel: ChargingModel
}

export const ChargeAccountActionPanel = ({ chargingModel, needsAction }: ChargeAccountActionPanelProps) => matchExhaustive(chargingModel, {
  Monthly: () => needsAction
    ? (
      <Card>
        <CardContent tw="space-y-5">
          <div tw="space-y-2">
            <h3 tw="text-lg leading-6 font-medium text-gray-900">You need to complete your signup to our pay-monthly plan</h3>
            <div tw="space-y-2 text-sm text-gray-500">
              <p>
                You still have steps to complete to sign-up to our pay-monthly plan.
              </p>
              <p>
                Please complete them before accepting further work.
              </p>
            </div>
          </div>
          <div>
            <div tw="flex flex-col gap-2 sm:flex-row sm:gap-4 items-start">
              <ButtonLink to={ROUTES.solicitors.plan.choose}>
                Complete
              </ButtonLink>
            </div>
          </div>
        </CardContent>
      </Card>
    )
    : null,
  PayByCase: () => (
    <Card>
      <CardContent tw="space-y-5">
        <div tw="space-y-2">
          <h3 tw="text-lg leading-6 font-medium text-gray-900">⚠️ The way we charge platform fees is changing</h3>
          <div tw="space-y-2 text-sm text-gray-500">
            <p>
              From October 1st, you will pay your Lawhive platform fees in a single simple monthly bill, rather than on every case.
            </p>
          </div>
        </div>
        <div>
          <div tw="flex flex-col gap-2 sm:flex-row sm:gap-4 items-start">
            <ButtonLink to={ROUTES.solicitors.plan.choose}>
              Learn more
            </ButtonLink>
          </div>
        </div>
      </CardContent>
    </Card>
  )
})
