import { formatDistanceToNowStrict, isPast } from "date-fns"
import { Button, ButtonLink } from "packages/app/src/elements/Button"
import { DropdownMenu } from "packages/app/src/elements/DropdownMenu"
import { PageHeaderButtonGroup } from "packages/app/src/elements/PageHeader"
import { ContentLayoutFlow } from "packages/app/src/layout/subshells/DetailsSubshell"
import { FC, useCallback } from "react"
import "twin.macro"
import { ErrorMessage, InfoMessage, WarningMessage } from "../../../../elements"
import { ROUTES } from "../../../../layout/Navigation"
import { AssessmentCallUIDetailsResponse } from "../../hooks/useAssessmentCallDetails"
import { AssessmentCallDescriptionSection, AssessmentCallDetailsSubshell } from "../elements"
import { useModal } from "../../../../contexts/Modal"
import { Modal } from "../../../../elements/Modal"
import { InitiateCallModal } from "../modals/InitiateCallModal"
import { useInitiateTelephoneCall } from "../../hooks/useInitiateTelephoneCall"
import { pipe } from "fp-ts/lib/function"
import { refreshFold } from "@nll/datum/DatumEither"
import { Spinner } from "../../../../elements/Loading"
import { constantDelay, RetryStatus } from "retry-ts"
import * as O from "fp-ts/lib/Option"
import * as TE from "fp-ts/lib/TaskEither"
import * as E from "fp-ts/lib/Either"
import { useGetTelephoneCall, } from "../../hooks/useGetTelephoneCall"
import { retrying } from "retry-ts/Task"
import { UUID } from "../../../../lib/Primitives"
import { useFromTaskEither } from "../../../../lib/useAsync"
import { match } from "ts-pattern"

type CallBookedProps = {
  call: AssessmentCallUIDetailsResponse
}


export const CallBooked: FC<CallBookedProps> = ({ call }) => (
  <AssessmentCallDetailsSubshell
    call={call}
    actions={call.solicitorSelection && (
      isPast(call.solicitorSelection)
        ? <CallHappenedActions call={call} />
        : <CallUpcomingActions call={call} />
    )}
    content={<Panel call={call} />}
  />
)

const InitiateCallButton: FC<{call: AssessmentCallUIDetailsResponse}> = ({call}) => {
  const { status: initiateStatus, execute: initiate, reset: resetInitiate } = useInitiateTelephoneCall()
  const getCall = useGetTelephoneCall()

  const policy = constantDelay(3000)

  const logDelay = (status: RetryStatus) => pipe(
    status.previousDelay,
    O.map((delay) => `retrying in ${delay} milliseconds...`),
    O.getOrElse(() => 'first attempt...'),
    c => console.log(c),
    TE.right
  )

  const pollGetStatus = (callId: UUID) => retrying(
    policy,
    (status) => pipe(
      logDelay(status),
      TE.chain(() => getCall(callId)),
      TE.map(a => {console.log(a)
      return a})
    ),
    E.fold(
      () => false,
      response => response.status === "in-progress"
    )
  )

  const { status, execute, reset } = useFromTaskEither(
    (callId: UUID) => pollGetStatus(callId)
  )

  const pollStatus = async (callId: UUID) => {
    if(!call) {
      return
    }

    await execute(callId)
  }

  const initiateAndRefresh = useCallback(
    async () => {
      if(call) {
        const callId = await initiate(call.id)
        hideCall()
        pollStatus(pipe(
          callId,
          E.map(a => a.id),
          E.getOrElse(() => ("" as UUID))))
      }
    },
    [call]
  )

  const [showCall, hideCall] = useModal(() => (
    <Modal onDismiss={hideCall}>
      {call && (
        <InitiateCallModal call={call} onCall={initiateAndRefresh} onCancel={hideCall} isLoading={false}/>
      )}
    </Modal>
  ))

  const resetAndShowCall = () => {
    reset()
    resetInitiate()
    showCall()
  }

  const CallInitiatedButton: FC = () => {
    return pipe(
      status,
      refreshFold(
        () => <Button variant="positive"><Spinner/>Calling client</Button>,
        () => <Button variant="positive"><Spinner/>Calling client</Button>,
        () => <Button variant="positive"><Spinner/>Calling client</Button>,
        (r) => {
          return match(r.status)
            .with("in-progress", () => <Button variant="positive"><Spinner />Calling client</Button>)
            .with("completed", () => <Button variant="positive" onClick={resetAndShowCall}>Call Complete - click to call again</Button>)
            .with("failed", () => <Button variant="negative" onClick={resetAndShowCall}>Call failed - click to try again</Button>)
            .otherwise(() => <></>)
        }

      )
    )
  }

  return pipe (
    initiateStatus,
    refreshFold(
      () => <Button onClick={showCall}>Call Client</Button>,
      () => <Button><Spinner/></Button>,
      () => <ErrorMessage title="Error calling client">Please contact support</ErrorMessage>,
      () => <CallInitiatedButton/>
    )

  )

}

const CallHappenedActions: FC<CallBookedProps> = ({ call }) => {

  return (
    <PageHeaderButtonGroup>
      <InitiateCallButton call={call}/>
      <ButtonLink
        to={ROUTES.assessmentCalls.complete(call.id).cantQuote}
        variant="secondary"
      >
        Can't Quote Client
      </ButtonLink>
      <ButtonLink
        to={ROUTES.assessmentCalls.complete(call.id).quote.build}
      >
        Quote Client
      </ButtonLink>
    </PageHeaderButtonGroup>
  )
}

const CallUpcomingActions: FC<CallBookedProps> = ({ call }) => {

  return (
    <PageHeaderButtonGroup>
      <InitiateCallButton call={call}/>
      <DropdownMenu
        variant="secondary"
        text="Already Called Client"
        items={[
          { label: "Quote Client", href: ROUTES.assessmentCalls.complete(call.id).quote.build },
          { label: "Can't Quote Client", href: ROUTES.assessmentCalls.complete(call.id).cantQuote },
        ]}
      />
    </PageHeaderButtonGroup>
  )
}

const Panel: FC<CallBookedProps> = ({ call }) => (
  <ContentLayoutFlow>
    {call.solicitorSelection && (
      isPast(call.solicitorSelection)
        ? <CallHappenedInstructions />
        : <CallUpcomingInstructions call={call} />
    )}

    <AssessmentCallDescriptionSection call={call} />
  </ContentLayoutFlow>
)

const CallHappenedInstructions = () => (
  <WarningMessage
    title='This client needs a quote from you'
  >
    <div tw="space-y-4">
      <p>
        Please enter the results of your call with the client and any quote provided to them.
      </p>
      <p>
        If you couldn't provide a quote for some reason, please select "Can't quote client".
      </p>
    </div>
  </WarningMessage>
)

const CallUpcomingInstructions: FC<CallBookedProps> = ({ call }) => (
  <InfoMessage
    title={call.solicitorSelection
        ? `Call Scheduled ${formatDistanceToNowStrict(call.solicitorSelection, { addSuffix: true })}`
        : `Call Scheduled`
    }
  >
    <div tw="space-y-4">
      <p>
        This call is to discuss their matter, assess the client's legal needs and provide a quote if suitable.
      </p>
      <p tw="font-semibold">
        Reminder: Please do not provide legal advice on this call.
      </p>
    </div>
  </InfoMessage>
)
