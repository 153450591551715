import { createContext, FC, useContext, useState } from "react"
import { useUser } from "../../../../contexts/User"
import { noop } from "../../../../lib/utils"

type ViewAsTarget = 'client' | 'solicitor'

type ViewAsContext = {
  viewingAs: ViewAsTarget
  setViewingAs: (target: ViewAsTarget) => void
}

const ViewAsContext = createContext<ViewAsContext>({
  viewingAs: 'client',
  setViewingAs: noop
})

export const useViewingAs = () => useContext(ViewAsContext)


export const ViewAsProvider: FC = ({ children }) => {
  const [viewingAs, setViewingAs] = useState<ViewAsTarget>('client')

  const context: ViewAsContext = {
    viewingAs,
    setViewingAs,
  }

  return (
    <ViewAsContext.Provider value={context}>
      {children}
    </ViewAsContext.Provider>
  )
}
