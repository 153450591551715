import { forwardRef, HTMLProps } from 'react'
import { Link } from 'react-router-dom'
import tw from 'twin.macro'


export const TableCell = tw.td`px-3 py-3.5 sm:(px-6 py-4) text-sm`
export const TableSelectionLink = tw(Link)`block px-6 py-4 text-sm`

export const TableNavigationLink = tw(Link)`text-indigo-600 hover:text-indigo-900 text-right text-sm leading-5 font-medium`

export const Table = tw.table`overflow-hidden sm:rounded-lg min-w-full divide-y divide-gray-200`

export const TableHeader = tw.th`table-cell px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500`
export const TableBody = tw.tbody`bg-white divide-y divide-gray-200`
