import "twin.macro"
import { FC, useState } from "react"
import { Card, CardContent, CardTitle } from "../../../elements"
import { ModalText } from "../../../elements/Modal"
import { Button } from "../../../elements/Button"
import { PlainDataTable } from "../../../elements/table/PlainDataTable"
import { Column } from "react-table"
import { formatCurrencyUnits } from "../../../elements/Currency"
import { useIntl } from "react-intl"



export const SavingsModal: FC = () => {

  const [caseValue, setCaseValue] = useState<number>(80000);
  const possibleValues = [20000, 40000, 60000, 80000]

  type MonthlyVsByCase = {
    chargeType: string
    cas: number
    monthly: number,
    savings: number
  }

  const intl = useIntl()

  const columns: Column<MonthlyVsByCase>[] = [
    {
      Header: "Charge Type",
      accessor: "chargeType",
      Cell: ({value}) => (
        <p>
          {value}
        </p>
      )
    },
    {
      Header: "Pay by case",
      accessor: "cas",
      Cell: ({value}) => (
        <p>
          {formatCurrencyUnits(intl)(value)}
        </p>
      )
    },
    {
      Header: "Pay monthly",
      accessor: 'monthly',
      Cell: ({value}) => (
        <p>
          {formatCurrencyUnits(intl)(value)}
        </p>
      )
    },
    {
      Header: "Savings",
      accessor: 'savings',
      Cell: ({value}) => (
        <p tw="font-bold">
          {formatCurrencyUnits(intl)(value)}
        </p>
      )
    }
  ]

  const data: () => MonthlyVsByCase[] = () => {

    const lawhiveFee: () => MonthlyVsByCase = () => {
      const cas = caseValue * 0.25
      const monthly = caseValue * 0.22
      const savings = cas - monthly

      return ({
        chargeType: "Lawhive Fee",
        cas,
        monthly,
        savings
      })
    }

    const stripeFee: () => MonthlyVsByCase = () => {
      const cas = (caseValue * 0.014) + 20
      const monthly = 0
      const savings = cas - monthly

      return ({
        chargeType: "Payment Processor Fee",
        cas,
        monthly,
        savings
      })
    }



    const total: MonthlyVsByCase = ({chargeType: "Total", cas: lawhiveFee().cas + stripeFee().cas, monthly: lawhiveFee().monthly + stripeFee().monthly, savings: lawhiveFee().savings + stripeFee().savings})

    return [
      lawhiveFee(),
      stripeFee(),
      total
    ]
  }

  return (
    <div tw="max-w-xl">
      <Card>
        <CardContent>
          <CardTitle>
            Possible savings on a typical case
          </CardTitle>
          <div tw="flex flex-row space-x-2 items-center py-4">
            <p>Case value:</p>
            {possibleValues.map(pv => (<Button onClick={() => setCaseValue(pv)} variant={caseValue === pv ? "primary" : "secondary"}>{formatCurrencyUnits(intl)(pv)}</Button>))}
          </div>
          <PlainDataTable columns={columns} data={data()}/>

          <ModalText tw="text-center pt-5">
            Over 15 cases, this could mean an extra {formatCurrencyUnits(intl)((data()[2].savings) * 15)} in your pocket!
          </ModalText>

        </CardContent>
      </Card>
    </div>
  )
}
