import React from "react"
import { Route, Switch } from "react-router-dom"

import { ROUTES } from "../../layout/Navigation"
import { ManagePaymentMethodsScene } from './ManagePaymentMethodsScene'

export const PaymentMethodSwitch = () => (
  <Switch>
    <Route path={ROUTES.paymentMethods.root} component={ManagePaymentMethodsScene} />
  </Switch>
)
