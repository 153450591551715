import { CaseDetails } from "packages/app/src/contexts/case/useGetCase"
import { axios } from "packages/app/src/lib/axios"
import { useMutation } from "react-query"

interface RejectCaseDTO {
  caseId: string | undefined
  data: {
    reason: string
    details?: string
  }
}

export const rejectCase = ({
  caseId,
  data
}: RejectCaseDTO): Promise<CaseDetails> => {
  return axios.post(`cases/${caseId}/reject`, data)
}

export const useRejectCase = () => {
  return useMutation({
    mutationFn: rejectCase
  })
}
