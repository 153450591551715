import { updateUser, UpdateUserMutation, UpdateUserMutationVariables } from '@lawhive/generated-api'
import { isPending, isSuccess } from '@nll/datum/DatumEither'
import React, { FC } from 'react'
import { useForm } from 'react-hook-form'
import { Redirect } from 'react-router-dom'
import 'twin.macro'
import { useUser } from '../../../contexts/User'
import { Button } from '../../../elements/Button'
import { Card } from '../../../elements/Card'
import { Form, FormField, FormSection, TextInput } from '../../../elements/Form'
import { ContentContainer, PageContainer } from '../../../layout/Layout'
import { ROUTES } from '../../../layout/Navigation'
import { useAsync } from '../../../lib/useAsync'
import { graphql } from '../../../lib/utils'

type FormOutput = {
  name: string
}

export const AddNameScene: FC = () => {
  const { userId, emailAddress, refresh } = useUser()
  const { register, handleSubmit } = useForm<FormOutput>()

  const { status, execute } = useAsync(
    (details: FormOutput) => graphql<UpdateUserMutation, UpdateUserMutationVariables>({
      query: /* GraphQL */`
        mutation M {
          updateUser(input: {
            id: "${userId}",
            displayName: "${details.name}"
          }) {
            id
          }
        }
      `
    }).then(refresh)
  )

  return isSuccess(status)
    ? <Redirect to={ROUTES.root} />
    : (
      <PageContainer tw="max-w-screen-md">
        <ContentContainer>
          <Card>
            <div tw="px-4 py-5 border-b border-gray-200 sm:px-6">
              <h2 tw="text-lg leading-6 font-medium text-gray-900">
                Welcome {emailAddress}!
              </h2>

              <p tw="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                Let's get you started with Lawhive.
              </p>
            </div>

            <Form onSubmit={handleSubmit(execute)}>
              <FormField
                id={'name'}
                label={'What is your full name?'}
                control={(
                  <TextInput
                    id="name"
                    name="name"
                    type="text"
                    ref={register({ required: true })}
                    required
                  />
                )}
              />

              <FormSection>
                <Button isLoading={isPending(status)}>
                  Continue
                </Button>
              </FormSection>
            </Form>
          </Card>
        </ContentContainer>
      </PageContainer>
    )
}
