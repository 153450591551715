import { useAnalytics } from "../../hooks/useAnalytics"
import { CaseCommittedEvent, CaseCommittedEventProps } from "./types"

export const useCaseCommittedEvent = () => {
    const { trackEvent } = useAnalytics()

    return (props: CaseCommittedEventProps) => {
        trackEvent(buildCaseCommittedEvent(props))
    }
}

const buildCaseCommittedEvent = (properties: CaseCommittedEventProps): CaseCommittedEvent => ({
    target: "case-comittment",
    action: "submitted",
    description: "Case committed",
    properties,
})
