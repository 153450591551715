import { FC } from "react"
import { ReactNode } from "react"

import tw from 'twin.macro'

type SidebarLayoutProps = {
  left: ReactNode
  right: ReactNode
  sidebarFirstMobile?: boolean
}

export const SidebarLayout: FC<SidebarLayoutProps> = ({ left, right, sidebarFirstMobile }) => (
  <div tw="grid grid-cols-1 gap-8 lg:grid-flow-col-dense lg:grid-cols-3">
    <div tw="space-y-6 lg:col-start-1 lg:col-span-2">
      {left}
    </div>
    <hr tw="block lg:hidden" />
    <div css={[
      tw`lg:col-start-3 lg:col-span-1`,
      sidebarFirstMobile && tw`order-first lg:order-none`
    ]}>
      {right}
    </div>
  </div>
)
