import React, { useMemo, useState } from "react"
import { useForm } from 'react-hook-form'
import 'twin.macro'
import { useAuthentication } from '../../contexts/Authentication'
import { ErrorMessageOld, ErrorMessageText } from "../../elements"

import { FormButton, FormField, FormSection, TextInput } from "../../elements/Form"
import { Spinner } from "../../elements/Loading"
import { ContentContainer, PageContainer } from '../../layout/Layout'

type SignInFormValues = {
  emailAddress: string
}

export const LoginScene = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>()
  const { loginWithMagicLink } = useAuthentication()

  const returnUrl = useMemo(
    () => new URLSearchParams(window.location.search).get('returnUrl') ?? undefined,
    [window.location.search]
  )

  const { register, handleSubmit } = useForm<SignInFormValues>()

  const signIn = async ({ emailAddress }: SignInFormValues) => {
    setLoading(true)
    setError(undefined)

    return loginWithMagicLink(emailAddress, returnUrl)
      .then(r => {
        setLoading(false)
        return r
      })
      .catch(e => {
        if(e.code && e.code === 'UserNotFoundException') {
          setError('This user does not exist')
        } else {
          setError('Something went wrong')
        }
        setLoading(false)
        console.log(e)
      })
  }

  return (
    <PageContainer>
      <ContentContainer>
      {/* <div tw="w-screen flex items-center flex-1 sm:h-full justify-center px-4 sm:px-0"> */}
        <div tw="flex flex-col sm:flex-row rounded-lg shadow-lg w-full sm:w-3/4 lg:w-3/4 xl:w-3/4 mx-auto bg-white">
          <div tw="flex w-full md:w-3/4 lg:w-1/2 px-4 py-10 md:py-20 lg:py-32">
            <div tw="flex flex-col flex-1 justify-center">
              <h1 tw="mb-4 text-4xl text-center font-extralight">
                Welcome
              </h1>

              <div tw="w-full mt-4">

                <form tw="sm:w-3/4 mx-auto" onSubmit={handleSubmit(signIn)}>
                  <FormField
                    id={'emailAddress'}
                    label={'Email Address'}
                    control={(
                      // <div tw="relative rounded-md shadow-sm">
                        <TextInput
                          name="emailAddress"
                          id="emailAddress"
                          type="email"
                          ref={register}
                          placeholder=""
                          required
                        />
                      // </div>
                    )}
                  />

                  <FormSection>
                    <FormButton disabled={loading}>
                      {loading ? <Spinner /> : <span>Sign in</span>}
                    </FormButton>
                  </FormSection>

                  {error && (
                    <ErrorMessageOld title={error} tw="mt-4">
                      <ErrorMessageText>
                        Please email <a href="mailto:support@lawhive.co.uk">support@lawhive.co.uk</a> if you continue to have problems.
                      </ErrorMessageText>
                    </ErrorMessageOld>
                  )}
                </form>
              </div>
            </div>
          </div>
          <div tw="hidden md:block md:w-1/3 lg:w-1/2 rounded-r-lg bg-center bg-cover" style={{ background: "url('https://images.unsplash.com/photo-1524758631624-e2822e304c36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80')", backgroundSize: 'cover', backgroundPosition: "center center" }}></div>
        </div>
      {/* </div> */}
      </ContentContainer>
    </PageContainer>
  )
}