import { FC } from 'react'
import 'twin.macro'

type SectionHeaderProps = {
  title: string
  supportingText?: string
  hideRule?: boolean
}

export const SectionHeader: FC<SectionHeaderProps> = ({ title, supportingText, hideRule }) => (
  <div tw="space-y-4">
    <div tw="space-y-2">
      <h2 tw="font-medium text-lg text-gray-900">
        {title}
      </h2>
      {supportingText && (
        <p tw="text-gray-500 text-xs sm:text-sm">
          {supportingText}
        </p>
      )}
    </div>
    {!hideRule && <hr/>}
  </div>
)
