import { forwardRef, useCallback, useEffect, useState } from "react"
import ReactCurrencyInput, { CurrencyInputProps } from 'react-currency-input-field'
import { CurrencyInputOnChangeValues } from "react-currency-input-field/dist/components/CurrencyInputProps"
import tw from 'twin.macro'
import { TextInputStyles } from "./Form"

type Input = number | undefined

type CurrencyInputCustomProps = {
  asUnits: boolean
  value: Input
  onChange: (input: Input | null) => void
}


export const CurrencyInput = forwardRef<HTMLInputElement, CurrencyInputCustomProps & CurrencyInputProps>(({ asUnits, value, onChange, ...props }, ref) => {
  const [stringValue, setStringValue] = useState<string | undefined>(() => value ? String(value) : '')

  const convertUnitsToOuptut = useCallback((input: number) => asUnits ? input * 100 : input, [asUnits])
  const convertInputToUnits = useCallback((input: number) => asUnits ? input / 100 : input, [asUnits])

  useEffect(() => {
    if(Number.isNaN(value)) {
      setStringValue('')
    }

    if(value) {
      setStringValue(String(convertInputToUnits(value)))
    }
  }, [value])

  const onInputChanged = (value: string | undefined, name?: string, values?: CurrencyInputOnChangeValues) => {
    const newValue = values?.float && convertUnitsToOuptut(values.float)

    setStringValue(value)
    onChange(newValue)
  }

  return (
    <div tw="flex rounded-md shadow-sm">
      <span tw="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
        £
      </span>
      <ReactCurrencyInput
        type="text"
        css={[
          TextInputStyles,
          tw`rounded-l-none! rounded-r-md! shadow-none`
        ]}
        ref={ref}
        value={stringValue}
        onValueChange={onInputChanged}
        {...props}
      />
    </div>
  )
})
