import { differenceInHours } from 'date-fns'
import { pipe } from 'fp-ts/lib/function'
import { IO } from 'fp-ts/lib/IO'
import { getOrElse, map, none, Option, some } from 'fp-ts/lib/Option'

type ReminderSchedulerConfig = {
  hoursOverdueSchedule: number[]
  spamProtectionDelayHours: number
}

export type ReminderHistory = Option<{
  alreadySentReminders: number
  lastReminderSentAt: Date
}>

export type IsReminderOverdue = (referenceDate: Date, reminderHistory: ReminderHistory) => boolean

const isOlderThanXHours = (x: number) => (now: Date, compare: Date) => differenceInHours(now, compare) >= x

export const overdueReminderSchedulerFactory =
  (getNow: IO<Date>) =>
  (config: ReminderSchedulerConfig)
  : IsReminderOverdue => (referenceDate, reminderHistory) => {
    const now = getNow()

    const isEligibleBasedOnReminderHistory = pipe(
      reminderHistory,
      map(history =>
        history.alreadySentReminders < config.hoursOverdueSchedule.length
        && isOlderThanXHours(config.spamProtectionDelayHours)(now, history.lastReminderSentAt)
      ),
      getOrElse(() => true)
    )

    const overdueScheduleEntry = pipe(
      reminderHistory,
      map(h => h.alreadySentReminders),
      getOrElse(() => 0),
      scheduleIndex => config.hoursOverdueSchedule.length > scheduleIndex
        ? some(config.hoursOverdueSchedule[scheduleIndex])
        : none
    )

    const mostRecentReference = pipe(
      reminderHistory,
      map(r => r.lastReminderSentAt),
      getOrElse(() => referenceDate)
    )

    const isEligibleBasedOnSchedule = pipe(
      overdueScheduleEntry,
      map(overdueHours => isOlderThanXHours(overdueHours)(now, mostRecentReference)),
      getOrElse(() => false)
    )

    return isEligibleBasedOnReminderHistory
      && isEligibleBasedOnSchedule
}
