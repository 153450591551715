/* eslint-disable react-hooks/rules-of-hooks */
import { ReactElement, ReactNode } from 'react'
import { Column, useTable } from 'react-table'
import { CSSProp } from 'styled-components'
import tw from 'twin.macro'
import { fold } from '@nll/datum/DatumEither'

import { constVoid, pipe } from 'fp-ts/lib/function'
import { Pagination } from '../../components/Pagination'
import { Spinner } from '../Loading'
import { Table, TableBody, TableCell, TableHeader, TableSelectionLink } from './Table'
import { DataController } from '../../hooks/useNextTokenPagination'
import { ErrorSubshell } from '../../layout/subshells/ErrorSubshell'
import { renderDatum } from '../../lib/utils'

export type TableColumn<D extends object> = Column<D> & {
  hideMobile?: boolean
  grow?: boolean
  style?: CSSProp
}

export type DataTableProps<D extends object> = {
  data: D[]
  columns: Array<TableColumn<D>>
  selectable?: DataTableSelectableOptions<D>
  hideHeader?: boolean
  footer?: ReactNode
  noDataComponent?: ReactElement
}

export type DataTableSelectableOptions<D extends object> = {
  [K in keyof D]: {
    key: K
    urlGenerator: (input: D[K]) => string
  }
}[keyof D]

export const DataTable = <D extends object>({
  hideHeader,
  columns,
  selectable,
  footer,
  data,
  noDataComponent,
  ...rest
}: DataTableProps<D>): ReactElement<DataTableProps<D>> => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    manualPagination: true,
    autoResetPage: false,
    columns,
    data
  })

  const hiddenOnMobile = columns.filter((c) => c.hideMobile).map((c) => c.accessor)
  const grow = columns.filter((c) => c.grow).map((c) => c.accessor)

  return (
    <Table {...rest} {...getTableProps()}>
      {!hideHeader && (
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableHeader
                  css={[tw`truncate`, hiddenOnMobile.includes(column.id as any) && tw`hidden sm:table-cell`]}
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </TableHeader>
              ))}
            </tr>
          ))}
        </thead>
      )}
      <TableBody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr css={[selectable && tw`hover:bg-gray-50`]} {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return selectable ? (
                  <td
                    css={[
                      grow.includes(cell.column.id as any) && tw`w-full sm:max-w-none`,
                      hiddenOnMobile.includes(cell.column.id as any) && tw`hidden sm:table-cell`,
                    ]}
                    {...cell.getCellProps()}
                  >
                    <TableSelectionLink to={selectable.urlGenerator(row.original[selectable.key])}>
                      {cell.render('Cell')}
                    </TableSelectionLink>
                  </td>
                ) : (
                  <TableCell
                    css={[
                      grow.includes(cell.column.id as any) && tw`w-full sm:max-w-none`,
                      hiddenOnMobile.includes(cell.column.id as any) && tw`hidden sm:table-cell`,
                      columns.find((c) => c.accessor === cell.column.id)?.style,
                    ]}
                    {...cell.getCellProps()}
                  >
                    {cell.render('Cell')}
                  </TableCell>
                )
              })}
            </tr>
          )
        })}
      </TableBody>
      {footer}
    </Table>
  )
}

export const SkeletonDataTable = <T extends object,>({ columns }: { columns: TableColumn<T>[] }) => (
  <>
    <DataTable
      columns={columns}
      data={[]}
    />
    <div tw="flex items-center justify-center h-16">
      <Spinner />
    </div>
    <Pagination
      nextPage={constVoid}
      previousPage={constVoid}
      gotoPage={constVoid}
      pageCount={0}
      pageIndex={0}
      pageSize={1}
      rowCount={1}
    />
  </>
)

export type ControlledDataTableProps<E, A extends object> = {
  controller: DataController<E, A[]>
  columns: Array<TableColumn<A>>
  error: (e: E) => string
} & Omit<DataTableProps<A>, 'data' | 'columns'>

export const ControlledDataTable = <E, A extends object>({ controller, columns, error, ...rest }: ControlledDataTableProps<E, A>) => {
  const { pageIndex, pageSize, pageCount, hasPagination, nextPage, previousPage, gotoPage } = controller

  return pipe(
    controller.data,
    fold(
      () => <SkeletonDataTable columns={columns} />,
      () => <SkeletonDataTable columns={columns} />,
      (e) => <ErrorSubshell title={error(e)} />,
      () => <SkeletonDataTable columns={columns} />,
      (e) => <ErrorSubshell title={error(e)} />,
      (data) => (
        <>
          <DataTable data={data} columns={columns} {...rest} />
          {hasPagination && (
            <Pagination
              pageSize={pageSize}
              pageIndex={pageIndex}
              pageCount={pageCount}
              rowCount={data.length}
              nextPage={nextPage}
              previousPage={previousPage}
              gotoPage={gotoPage}
            />
          )}
        </>
      )
    )
  )
}
