import { API } from '@aws-amplify/api'
import { GraphQLResult, GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import { DatumEither, fold, isSuccess, refreshFold } from '@nll/datum/DatumEither'
import { sequenceS } from 'fp-ts/es6/Apply'
import { Either, fold as eitherFold } from 'fp-ts/es6/Either'
import { pipe } from 'fp-ts/es6/function'
import * as O from 'fp-ts/es6/Option'
import * as TE from 'fp-ts/es6/TaskEither'
import { ChangeEvent, FormEvent } from 'react'
import { Observable } from 'zen-observable-ts'

export type { GraphQLResult }

export type GraphQLOptions<T> = {
  query: string
  variables?: T
  authMode?: GRAPHQL_AUTH_MODE
}

export const noop = () => {}
export const noopPromise = () => Promise.resolve()
export const throwError = <E>(e?: E) => { throw e }

export const throwIfIsNullOrUndefined = <T,>(i: T | null | undefined): T =>
  isNotNullOrUndefined(i)
    ? i
    : throwError(new Error('Empty Result'))

type SubscriptionResponse<R> = {
  provider: any,
  value: GraphQLResult<R>
}

export const graphql = <R extends object, I extends object = object>(a: GraphQLOptions<I>) =>
  (API.graphql(a) as Promise<GraphQLResult<R>>)
  .catch((result: GraphQLResult<R>) => {
    if(result.data !== null) {
      console.warn('Error in query', result.errors)
      return result
    } else {
      throw result
    }
  })

export const graphqlSubscribe = <R extends object, I extends object = object>(a: GraphQLOptions<I>) => API.graphql(a) as Observable<SubscriptionResponse<R>>

export const formInput = (setter: (t: string) => void) => (e: ChangeEvent<any>) => setter(e.target.value)
export const formSubmit = (onSubmit: () => void) => (e: FormEvent) => {
  e.stopPropagation()
  e.preventDefault()
  onSubmit()
}

// export type Optional<T> = {
//   [K in keyof T] : Option<T[K]>
// }

export const sequenceOption = sequenceS(O.option)

// export const sequenceOption = <T>(inputs: Partial<T>) =>
  // sequenceS(option)(map(c => fromNullable(c))(inputs))

export const mapObject = <A, B>(mapper: ([key, value]: [string, A]) => [string, B]) =>
  (object: Record<string, A>) =>
  Object.fromEntries(Object.entries(object).map(mapper))


export const renderError = (codeRenderer: Record<string, (message: string) => JSX.Element> & { default: () => JSX.Element }) =>
  (error: Error) => {
    const { name, message } = error

    const nameOrFallback = name || 'default'

    const renderer = codeRenderer[nameOrFallback] || codeRenderer['default']

    return renderer(message)
  }

export const matchStringUnion = <I extends string, A>(value: I, map: Record<I, TypeOrBuilder<A, {}>>): A =>
  resolveTypeOrBuilder(map[value] as TypeOrBuilder<A, {}>, {})

export type TypeOrBuilder<T, I> = T | ((s: I) => T)

export const resolveTypeOrBuilder = <T, I>(b: TypeOrBuilder<T, I>, i: I) =>
  b instanceof Function
    ? b(i)
    : b

// TODO Improve naming
export const renderDatum =
<E, A>(loading: () => JSX.Element | null, error: (i: E) => JSX.Element | null, success: (i: A) => JSX.Element | null) =>
  refreshFold(loading, loading, error, success)

export const renderLoadingOrSuccess =
  <A>(loading: () => JSX.Element | null, success: (i: A) => JSX.Element | null) =>
    refreshFold(loading, loading, loading, success)

export const renderLoadingRefreshOrSuccess =
  <A>(loading: () => JSX.Element | null, success: (i: A) => JSX.Element | null) =>
    fold(loading, loading, loading, loading, loading, success)

export const renderNullOrSuccess =
  <E, A>(d: DatumEither<E, A>, onSuccess: (i: A) => JSX.Element | null) =>
  isSuccess(d) ? onSuccess(d.value.right) : null


// TODO Check that this is doing the right thing
// (Needs checking every usage)
export const isNotNullOrUndefined = <T>(input: null | undefined | T): input is T => {
  return input != null;
}

export const eitherOrNull = <E,A>(e: Either<E, A>, render: (a: A) => JSX.Element | null) => eitherFold(() => null, render)(e)

type RenderMap<U extends string, T> = Record<U, (i: T) => JSX.Element | null>

export const renderUnion = <U extends string, T>(unionAccessor: (i: T) => U) => (renderMap: RenderMap<U, T>) =>
  (i: T) => renderMap[unionAccessor(i)](i)

// Convert: Option<A> to Option<TaskEither<E, Foo>> => TaskEither<E, Option<Foo>>
export const mapOptionTaskEither = <E, A, B>(f: (input: A) => TE.TaskEither<E, B>) => (option: O.Option<A>): TE.TaskEither<E, O.Option<B>> => pipe(
  option,
  O.map(f),
  swapOptionTaskEither
)

export const swapOptionTaskEither = <E, B>(option: O.Option<TE.TaskEither<E, B>>): TE.TaskEither<E, O.Option<B>> => pipe(
  option,
  O.fold(
    () => TE.right(O.none),
    TE.map(O.some)
  )
)
