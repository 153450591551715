import { default as React, FC, useMemo } from 'react'
import { differenceInHours, formatRFC7231 } from 'date-fns'
import { isSome } from 'fp-ts/es6/Option'
import 'twin.macro'

import { Card, CardContent } from '../..'
import { useUser } from '../../../contexts/User'
import { Message, MessageList, SendMessageBox } from './'
import { PageTitle } from '../../../layout/Layout'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../../layout/Navigation'
import { ErrorMessageOld, WarnMessage } from '../../Message'

const PageTitleButton = PageTitle.withComponent('button')

const messagesNeedWarning = (messages: Message[]) => {
  const now = new Date()

  if (messages.length < 1) {
    return false
  }

  const lastMessage = messages[messages.length - 1]
  const isLastMessageOlderThan3Hours = differenceInHours(now, lastMessage.timestamp) > 3
  const isLastMessageFromOtherUser = !lastMessage.fromMe
  const doesMessageFitCriteria = lastMessage.content.length > 15 || lastMessage.content.includes('?')

  return isLastMessageOlderThan3Hours && isLastMessageFromOtherUser && doesMessageFitCriteria
}

export const MessagesCard: FC<{
  caseId: string
  messages: Message[]
  addMessage: (message: string) => void
  canSend?: boolean
}> = ({ caseId, messages, addMessage, canSend = true, children }) => {
  const saveConversation = async (messages: Message[]) => {
    const printable = window.open()

    const html = `
      <html>
        <head>
          <style>
            body {
              font-family: sans-serif;
            }

            .content {
              margin-bottom: 1.5rem;
            }

            .message-title {
              display: flex;
              flex-direction: row;
              margin-bottom: 0.5rem;
            }

            .author {
              font-weight: 600;
              font-size: 0.8rem;
              margin-right: 1rem;
            }

            .timestamp {
              font-size: 0.8rem;
              margin-bottom: 0.2rem;
            }

            .content {
              border-radius: 8px;
              padding: 8px 12px;
              line-height: 24px;
              white-space: pre-line;
              background: #F4F4F5;
            }

            .own {
              background: #E0F2FE;
            }
          </style>
        </head>
        <body>
          ${messages
            .map(
              (m) => `
            <div class="message">
              <div class="message-title">
                <div class="author">
                  ${m.authorName}
                </div>
                <div class="timestamp">
                  ${formatRFC7231(m.timestamp)}
                </div>
              </div>
              <div class="content${m.fromMe ? ' own' : ''}">${m.content}</div>
            </div>
          `
            )
            .join('')}
        </body>
      </html>
    `

    printable?.document.write(html || '')
    printable?.print()
  }

  const { isSolicitor, isAdmin } = useUser()

  const doMessagesNeedWarning = useMemo(() => messagesNeedWarning(messages), [messages])

  const shouldShowMessageReminder = (isSolicitor || isAdmin) && doMessagesNeedWarning

  return (
    <Card>
      <div tw="flex-1 justify-between flex flex-col">
        <CardContent tw="border-b border-gray-200 relative flex flex-row justify-between">
          {/* <h3 tw="font-medium text-base sm:text-lg">Messages</h3> */}

          <Link
            to={ROUTES.case.files(caseId)}
            tw="font-bold leading-tight text-gray-500 text-xs px-2 py-1 rounded-full border border-gray-300 flex flex-row items-center"
          >
            <span>Add Files</span>

            <svg
              tw="block h-4 w-4 ml-1"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
              />
            </svg>
          </Link>

          <PageTitleButton
            type="button"
            onClick={() => saveConversation(messages)}
            tw="text-gray-500 text-xs px-2 py-1 rounded-full border border-gray-300 flex flex-row items-center"
          >
            <span>Printable</span>
            <svg
              tw="block h-4 w-4 ml-1"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
              />
            </svg>
          </PageTitleButton>
        </CardContent>
        {children}
        <CardContent tw="max-h-72 overflow-auto">
          <MessageList messages={messages} />
        </CardContent>

        {shouldShowMessageReminder && (
          <div tw="border-t border-gray-200 px-3 py-3 sm:px-4 sm:py-4 bg-red-50 ">
            <div tw="flex flex-row items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                tw="h-6 w-6 text-red-400 flex-shrink-0"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <div tw="pl-2 sm:pl-4 flex flex-col gap-1">
                <p tw="text-red-600 font-medium text-xs sm:text-base">
                  Please ensure you respond to the client to acknowledge seeing their message.
                </p>
                <p tw="text-xs mt-1 hidden sm:block">
                  Even if to say "Thanks for your message, I'll review and be in touch soon."
                </p>
                <p tw="text-xs">Solicitors who provide this feedback get high scoring reviews 80% more often.</p>
              </div>
            </div>
          </div>
        )}
        {canSend && (
          <div tw="border-t border-gray-200 px-3 py-3 sm:px-4 sm:py-4">
            <SendMessageBox addMessage={addMessage} />
          </div>
        )}
      </div>
    </Card>
  )
}
