import { Button } from "packages/app/src/elements/Button"
import React from "react"
import { useIntercom } from "react-use-intercom"
import "twin.macro"

type QuoteExpiredButtonProps = {
  quoteId: string
}

export default function QuoteExpiredButton({
  quoteId
}: QuoteExpiredButtonProps) {
  const { showNewMessages } = useIntercom()

  const handleClick = () =>
    showNewMessages(`I'd like to reopen my quote #${quoteId}.`)

  return (
    <div tw="flex flex-col sm:items-end gap-2">
      <p tw="text-red-600 font-medium">Sorry, your quote has expired. Still need help?</p>
      <Button onClick={handleClick} variant="positive">
        Reopen Quote
      </Button>
    </div>
  )
}
