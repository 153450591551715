import { FC, InputHTMLAttributes, useEffect, useMemo, useState } from 'react'
import { CheckIcon } from '@heroicons/react/solid'
import { Combobox as HeadlessCombobox } from '@headlessui/react'
import tw from 'twin.macro'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline'

export type ComboboxItem = {
  name: string
  value: string
  supportingText?: string
}

type ComboboxProps = {
  items: ComboboxItem[]
  name?: string
  placeholder?: string
  value: string | undefined
  setValue: (value: string | undefined) => void
} & Pick<InputHTMLAttributes<HTMLInputElement>, 'name' | 'disabled' | 'multiple'>

const evaluateFilter = (query: string) => (item: ComboboxItem) =>
  item.name.toLowerCase().includes(query.toLowerCase()) || item.value.toLowerCase().includes(query.toLowerCase())

// https://headlessui.dev/react/combobox
// https://tailwindui.com/components/application-ui/forms/comboboxes#component-0834ed9ce704a78cc0045ef92f4af723
// https://tailwindui.com/components/application-ui/forms/select-menus#component-71680e7ae17adab2886761e3e2390f6a

export const Combobox: FC<ComboboxProps> = ({ items, value, placeholder, setValue, ...rest }) => {
  const [query, setQuery] = useState('')

  const filteredItems = query === '' ? items : items.filter(evaluateFilter(query))

  const getActiveOption = (activeOption?: string) => items.find((i) => i.value === activeOption)

  const [selectedItem, setSelectedItem] = useState<ComboboxItem | undefined>(getActiveOption(value))

  useEffect(() => {
    console.log(value)
    setSelectedItem(getActiveOption(value))
  }, [value, filteredItems])

  return (
    <HeadlessCombobox value={selectedItem} onChange={(i) => setValue(i as unknown as string)} tw="space-y-1" {...rest}>
      {({ activeOption, open }) => (
        <div tw="relative">
          <HeadlessCombobox.Input
            // Bug in styled components mean we need to exclaim everything
            // tw="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm disabled:(bg-gray-50)"
            tw="w-full! rounded-md! border! border-gray-300! bg-white! py-2! pl-3! pr-10! shadow-sm! focus:border-indigo-500! focus:outline-none! focus:ring-1! focus:ring-indigo-500! sm:text-sm! disabled:(bg-gray-50! border-gray-200! placeholder-gray-200)"
            onChange={(e) => setQuery(e.target.value)}
            placeholder={placeholder ?? `Please select a value...`}
            displayValue={(i: ComboboxItem) => (i ? i.name : '')}
            // autoComplete="off"
          />
          <HeadlessCombobox.Button tw="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none text-gray-400 disabled:(text-gray-200)">
            {open ? (
              <ChevronUpIcon tw="h-5 w-5" aria-hidden="true" />
            ) : (
              <ChevronDownIcon tw="h-5 w-5" aria-hidden="true" />
            )}
          </HeadlessCombobox.Button>

          {filteredItems.length > 0 && (
            <HeadlessCombobox.Options tw="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredItems.map((i) => (
                <HeadlessCombobox.Option
                  key={i.value}
                  value={i.value}
                  css={[
                    selectedItem?.value === i.value && tw`bg-indigo-600 text-white`,
                    tw`relative cursor-default select-none py-2 pl-3 pr-9 hover:(bg-indigo-600 text-white)`,
                  ]}
                >
                  {({ active }) => (
                    <>
                      <div tw="flex">
                        <span css={[tw`truncate`, selectedItem?.value === i.value && tw`font-semibold`]}>{i.name}</span>
                        {i.supportingText && (
                          <span
                            css={[tw`ml-2 truncate text-gray-500`, active ? tw`text-indigo-200` : tw`text-gray-500`]}
                          >
                            {i.supportingText}
                          </span>
                        )}
                      </div>

                      {selectedItem?.value === i.value && (
                        <span
                          css={[
                            tw`absolute inset-y-0 right-0 flex items-center pr-4`,
                            active ? tw`text-white` : `text-indigo-600`,
                          ]}
                        >
                          <CheckIcon tw="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </HeadlessCombobox.Option>
              ))}
            </HeadlessCombobox.Options>
          )}
        </div>
      )}
    </HeadlessCombobox>
  )
}
