import "twin.macro"

import { Spinner } from "packages/app/src/elements/Loading"
import { Card, CardContent } from "../../../elements"
import { PanelGrid } from "../../../elements/Layout"
import { useQuoteStatus } from "../hooks/useQuoteStatus"
import { QuoteLineItemsTable } from "../components/QuoteLineItemsTable"
import { QuoteScopesTable } from "../components/QuoteScopesTable"
import { SolicitorEarningsSummary } from "../components/SolicitorEarningsSummary"
import { ClientQuoteSummary } from "../components/ClientQuoteSummary"

export const SolicitorQuoteView = () => {
  const { getQuotes, firstQuote } = useQuoteStatus()

  if (getQuotes.isLoading) {
    return <Spinner />
  }

  if (firstQuote) {
    return (
      <PanelGrid>
        <QuoteLineItemsTable
          priceLabel={"Fee"}
          lineItems={firstQuote.lineItems}
        />

        {firstQuote.scopes.length > 0 && (
          <Card>
            <CardContent>
              <QuoteScopesTable scopes={firstQuote.scopes} />
            </CardContent>
          </Card>
        )}

        <div tw="flex flex-col gap-2">
          <h3 tw="font-semibold">Client Payment</h3>
          <ClientQuoteSummary quote={firstQuote} />
        </div>

        <div tw="flex flex-col gap-2">
          <h3 tw="font-semibold">Your Earnings</h3>
          <SolicitorEarningsSummary quote={firstQuote} />
        </div>
      </PanelGrid>
    )
  } else {
    return null
  }
}
