import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"

import { ROUTES } from "../../layout/Navigation"
import { ConfirmEmailAddressScene } from "./ConfirmEmailAddress"
import { LoginScene } from "./Login"
import { MagicLinkCallbackScene } from "./MagicLinkCallback"

export const AuthSwitch = () => (
  <Switch>
    <Route path={ROUTES.auth.login()} component={LoginScene} />
    <Route path={ROUTES.auth.magicLinkCallback()} component={MagicLinkCallbackScene} />
    {/* <Route path={ROUTES.auth.confirmEmail()} component={ConfirmEmailAddressScene} /> */}
    <Redirect to={ROUTES.auth.login()} />
  </Switch>
)
