import tw, { styled } from 'twin.macro'
import { matchStringUnion } from '../lib/utils'

type PillProps = {
  variant?: 'positive' | 'negative' | 'neutral' | 'orange' | 'yellow' | 'red' | 'purple'
}
export const Badge = styled.div<PillProps>(({ variant = 'neutral' }) => [
  tw`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800`,
  matchStringUnion(variant, {
    positive: tw`bg-green-100 text-green-800`,
    negative: tw`bg-red-100 text-red-800`,
    red: tw`bg-red-100 text-red-800`,
    purple: tw`bg-purple-100 text-purple-800`,
    neutral: tw`bg-indigo-100 text-indigo-800`,
    orange: tw`bg-orange-100 text-orange-800`,
    yellow: tw`bg-yellow-100 text-yellow-800`,
  })
])

// TODO Remove
export const PositivePill = tw(Badge)`bg-green-100 text-green-800`
export const NegativePill = tw(Badge)`bg-red-100 text-red-800`
export const NeutralPill = tw(Badge)`bg-indigo-100 text-indigo-800`
export const OrangePill = tw(Badge)`bg-orange-100 text-orange-800`
export const YellowPill = tw(Badge)`bg-yellow-100 text-yellow-800`
