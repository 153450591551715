
import * as D from 'io-ts/lib/Decoder'
import { ADT } from '../../../lib/adt'
import { castTo, fetchParsedJSON } from "../../../lib/fetch"
import { UUID } from '../../../lib/Primitives'
import { useApiFetch } from "../../../lib/useApiClient"
import { useFromTaskEither } from "../../../lib/useAsync"

export const AssessmentSource = ADT({
  AssessmentCall: UUID,
})
export type AssessmentSource = D.TypeOf<typeof AssessmentSource>

export const NotQuotedReason = ADT({
  NotLegalService: D.struct({ details: D.string }),
  TooBusy: D.partial({ details: D.string }),
  NotSuitable: D.partial({ details: D.string }),
  Other: D.struct({ details: D.string }),
})
export type NotQuotedReason = D.TypeOf<typeof NotQuotedReason>

export const AssessmentResult = ADT({
  SolicitorQuoted: UUID,
  NotLegalMatter: D.partial({ details: D.string })
})
export type AssessmentResult = D.TypeOf<typeof AssessmentResult>


export const CreateAssessmentCommand = D.struct({
  leadId: UUID,
  source: AssessmentSource,
  result: AssessmentResult,
})

type CreateAssessmentResponse = {
  id: UUID
}

export type CreateAssessmentCommand = D.TypeOf<typeof CreateAssessmentCommand>

export const useCreateAssessment = () => {
  const api = useApiFetch(fetchParsedJSON(castTo<CreateAssessmentResponse>()))

  return  (command: CreateAssessmentCommand) => api(`assessments`, {
    method: 'POST',
    body: JSON.stringify(command)
  })
}
