import { useAnalytics } from "../../hooks/useAnalytics";
import { PaymentCTAClickedEvent, PaymentCTAClickedEventProps } from "./types";

export const usePaymentCTAClickedEvent = () => {
    const { trackEvent } = useAnalytics();
    
    return (properties: PaymentCTAClickedEventProps) => {
        trackEvent(buildPaymentCTAClickedEvent(properties));
    }
}

const buildPaymentCTAClickedEvent = (properties: PaymentCTAClickedEventProps): PaymentCTAClickedEvent => {
    return {
        action: "clicked",
        target: "payment-cta",
        description: "Pay fee quote",
        properties: {
            ...properties,
        }
    }
}