import { FormattedCurrencyUnits } from "packages/app/src/elements/Currency"
import { Spinner } from "packages/app/src/elements/Loading"
import {
  CaseStatusTitle,
  CaseStatusTitleHeader
} from "packages/app/src/scenes/case/details/CaseDetails"
import { useQuoteStatus } from "../hooks/useQuoteStatus"

type QuoteTotalPriceMetricProps = {
  label?: string
}

export const QuoteTotalPriceMetric = ({
  label
}: QuoteTotalPriceMetricProps) => {
  const { getQuotes, firstQuote } = useQuoteStatus()

  if (getQuotes.isLoading || !firstQuote) {
    return <Spinner />
  }

  return (
    <div>
      <CaseStatusTitleHeader>{label || `Price`}</CaseStatusTitleHeader>
      <CaseStatusTitle>
        <FormattedCurrencyUnits amountUnits={firstQuote.price.payableAmount} />
      </CaseStatusTitle>
    </div>
  )
}
