import { castTo, fetchParsedJSON } from "../../../lib/fetch"
import { UUID } from "../../../lib/Primitives"
import { useApiFetch } from "../../../lib/useApiClient"
import { useFromTaskEither } from "../../../lib/useAsync"

export type GetTelephoneCallResponse = {
  id: UUID,
  status: "in-progress" | "failed" | "completed"
}

export const useInitiateTelephoneCall = () => {
  const api = useApiFetch(fetchParsedJSON(castTo<GetTelephoneCallResponse>()))

  const { status, execute, reset } = useFromTaskEither(
    (assessmentCallId: UUID) =>
      api(`telephony/initiate-assessment-call`, {
        method: "POST",
        body: JSON.stringify({ assessmentCallId })
      })
  )

  return { status, execute, reset }
}
