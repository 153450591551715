import { CaseCompletionType, CaseDetailsType, CompleteCaseCommandMutation, CompleteCaseCommandMutationVariables, setCaseDescriptionCommand, SetCaseDescriptionCommandMutation, SetCaseDescriptionCommandMutationVariables } from "@lawhive/generated-api"
import { isPending, isSuccess } from "@nll/datum/DatumEither"
import React, { FC } from "react"
import { useForm } from "react-hook-form"
import 'twin.macro'

import { useCase } from "../../../../contexts/case/CaseContext"
import { CaseDetails } from "../../../../contexts/case/useGetCase"
import { CaseTitleBreadcrumb } from "../../../../elements/case/CaseTitleBreadcrumb"
import { Button } from "../../../../elements/Button"
import { CheckItem, EnumDropdown } from "../../../../elements/Form"
import { ScenePanel, ScrollingChildrenContainer } from "../../../../layout/Layout"
import { ROUTES } from "../../../../layout/Navigation"
import { useAsync } from "../../../../lib/useAsync"
import { useRouter } from "../../../../lib/useRouter"
import { graphql } from "../../../../lib/utils"
import TextareaAutosize from "react-autosize-textarea/lib"
import { isSome } from "fp-ts/es6/Option"

export const AdminCaseMarkCompleteScene = () => {
  const { caseDetails } = useCase()
  return isSuccess(caseDetails) ? (
    <>
      <CaseTitleBreadcrumb caseNumber={caseDetails.value.right.friendlyId} action={'Mark Complete'} />
      <ScrollingChildrenContainer>
        <ScenePanel>
          <MarkCompleteForm cas={caseDetails.value.right} />
        </ScenePanel>
      </ScrollingChildrenContainer>
    </>
  ) : null
}

type DetailsInput = {
  details: string | null
  sendClientEmail: boolean
}

const MarkCompleteForm: FC<{ cas: CaseDetails }> = ({ cas }) => {
  const { refresh } = useCase()
  const { replace } = useRouter()

  const { register, handleSubmit, watch } = useForm<DetailsInput>({
    defaultValues: isSome(cas.completion) ? {
      details: cas.completion.value.details,
      sendClientEmail: false
    } : {
      details: '',
      sendClientEmail: true
    }
  })

  const d = watch()

  const { status, execute } = useAsync(
    (input: DetailsInput) => graphql<CompleteCaseCommandMutation, CompleteCaseCommandMutationVariables>({
      query: /* GraphQL */ `
         mutation CompleteCaseCommand($input: CompleteCaseCommand!) {
          completeCaseCommand(input: $input) {
            id
          }
        }
      `,
      variables: {
        input: {
          caseId: cas.id,
          details: input.details,
          sendClientEmail: input.sendClientEmail
        }
      }
    })
    .then(() => refresh())
    .then(() => replace(ROUTES.case.details(cas.id)))
  )


  return (
    <form onSubmit={handleSubmit(execute)} tw="flex flex-col">
      Details (optional)

      <TextareaAutosize
        name={'details'}
        ref={register}
        rows={1}
      />

      <CheckItem
        name='sendClientEmail'
        ref={register}
      >
        Send client email asking for review?
      </CheckItem>

      <Button isLoading={isPending(status)}>
        Submit
      </Button>
    </form>
  )
}
