import { matchExhaustive } from "@practical-fp/union-types"
import { pipe } from "fp-ts/es6/function"
import * as O from "fp-ts/es6/Option"
import * as TE from "fp-ts/es6/TaskEither"
import { castTo, fetchParsedJSON } from "../lib/fetch"
import { UUID } from "../lib/Primitives"
import { useApiFetch } from "../lib/useApiClient"

export type CaseCompletionStatus = "pending" | "accepted" | "rejected" | "superseded"

export type CaseCompletionRequest = {
  id: UUID,
  requestedAt: Date,
  requestedBy: UUID,
  status: CaseCompletionStatus,
  case: UUID,
  respondedAt?: Date
}

export type SolicitorCaseUIResponse = {
  completionRequests: CaseCompletionRequest[]
}

export const useGetCaseNew = (caseId: string) => {
  const api = useApiFetch(fetchParsedJSON(castTo<SolicitorCaseUIResponse>()))

  return () => pipe(
    api(`cases/${caseId}`),
    TE.orElseW(error => matchExhaustive(error, {
      NetworkError: () => TE.left(error),
      ParseError: () => TE.left(error),
      ResponseError: () => TE.left(error)
    }))
  )
}
