import { useEnvironment } from "./Environment"
import posthog, { Properties } from "posthog-js"
import React, { FC, useContext } from "react"
import { constFalse, constVoid } from "fp-ts/lib/function"
import { impl, Variant } from "@practical-fp/union-types"
import { UserType } from "../features/analytics/types"

type PosthogEventName = "ExampleEvent" | "AnotherEvent"

type PosthogFeatureFlag = Variant<"SolicitorAffiliateLinks">

export const PosthogFeatureFlag = impl<PosthogFeatureFlag>()

type PosthogContext = {
  initialise: () => void
  registerEvent: (eventName: PosthogEventName, eventData: Properties) => void
  aliasAndUpdateUserProps: (id: string, id2: string, userAttributes: PosthogUserAttributes) => void
  isFeatureEnabled: (feature: PosthogFeatureFlag) => boolean
}


type PosthogUserAttributes = {
  id: string
  name?: string
  email: string
  isSolicitor: boolean
  isAdmin: boolean
  userType: UserType
}

export const PosthogContext = React.createContext<PosthogContext>({
  initialise: constVoid,
  registerEvent: constVoid,
  aliasAndUpdateUserProps: constVoid,
  isFeatureEnabled: constFalse
})

export const usePosthog = () => useContext(PosthogContext)

export const PosthogProvider: FC = ({ ...props }) => {
  const { POSTHOG_KEY } = useEnvironment()

  const initialise = () => {
    console.log("Posthog initialising...")
    posthog.init(POSTHOG_KEY, { api_host: "https://posthog.lawhive.co.uk" })
  }
  const registerEvent = posthog.capture
  const aliasAndUpdateUserProps = (id: string, id2: string, a: PosthogUserAttributes) => {
    console.log("Posthog user identify...")
    try {
      posthog.alias(id, id2)
      posthog.people.set(a)
    } catch (e) {
      console.log("Posthog failed to ID user", e)
    }
  }

  const isFeatureEnabled = (a: PosthogFeatureFlag) =>
    posthog.isFeatureEnabled(a.tag)

  const context: PosthogContext = {
    initialise,
    registerEvent,
    aliasAndUpdateUserProps,
    isFeatureEnabled
  }

  return <PosthogContext.Provider value={context} {...props} />
}

export const useFeatureFlag = (key: string) => {
  return posthog.isFeatureEnabled(key)
}
