import { FormattedCurrencyUnits } from "packages/app/src/elements/Currency"
import { NonZeroPriceUnits, PriceUnits } from "packages/app/src/lib/Primitives"
import { PropsWithChildren } from "react"
import "twin.macro"

export type QuoteSummaryTableProps = {
  items: SummaryItemProps[]
}

export const QuoteSummaryTable = ({ items }: QuoteSummaryTableProps) => {
  return (
    <div>
      <h2 tw="sr-only">Billing Summary</h2>

      <div tw="rounded-lg bg-white py-6 px-6 lg:px-0 lg:py-8">
        <dl tw="divide-y divide-gray-200 text-sm lg:mt-0 lg:px-8">
          {items.map((i, ix) => (
            <SummaryItem {...i} key={ix} />
          ))}
        </dl>
      </div>
    </div>
  )
}

export type SummaryItemProps = {
  name: string | JSX.Element
  description?: string | JSX.Element
  amount: PriceOrRange
  isNegative?: boolean
  isHighlighted?: boolean
  isBold?: boolean
}

const SummaryItem = ({
  name,
  description,
  amount,
  isHighlighted,
  isBold,
  isNegative
}: SummaryItemProps) => {
  if (isHighlighted) {
    return (
      <div tw="flex items-center justify-between py-4 first:pt-0 last:pb-0">
        <dt tw="text-red-600">
          {name}
          {description && <p tw="text-xs text-gray-400">{description}</p>}
        </dt>
        <dd tw="font-medium text-red-600">
          <PriceOrRangeDisplay amount={amount} isNegative={isNegative} />
        </dd>
      </div>
    )
  }

  if (isBold) {
    return (
      <div tw="flex items-center justify-between py-4 first:pt-0 last:pb-0">
        <dt tw="font-bold text-gray-600">
          {name}
          {description && <p tw="text-xs text-gray-400">{description}</p>}
        </dt>
        <dd tw="font-bold text-gray-900">
          <PriceOrRangeDisplay amount={amount} isNegative={isNegative} />
        </dd>
      </div>
    )
  }

  return (
    <div tw="flex items-center justify-between py-4 first:pt-0 last:pb-0">
      <dt tw="text-gray-600">
        {name}
        {description && <p tw="text-xs text-gray-400">{description}</p>}
      </dt>
      <dd tw="font-medium text-gray-900">
        <PriceOrRangeDisplay amount={amount} isNegative={isNegative} />
      </dd>
    </div>
  )
}

export type PriceRange = {
  upper: PriceUnits | NonZeroPriceUnits
  lower: PriceUnits | NonZeroPriceUnits
}

export type PriceOrRange = PriceUnits | NonZeroPriceUnits | PriceRange

type PriceOrRangeDisplayProps = {
  amount: PriceOrRange
  isNegative?: boolean
}

const PriceOrRangeDisplay = ({
  amount,
  isNegative
}: PriceOrRangeDisplayProps) => {
  return (
    <AccountingDisplay isNegative={isNegative}>
      {typeof amount === "object" ? (
        <PriceRangeDisplay range={amount} />
      ) : (
        <FormattedCurrencyUnits amountUnits={amount} />
      )}
    </AccountingDisplay>
  )
}

type AccountingDisplayProps = PropsWithChildren<{
  isNegative?: boolean
}>

const AccountingDisplay = ({ isNegative, children }: AccountingDisplayProps) =>
  isNegative ? <>-{children}</> : <>{children}</>

const PriceRangeDisplay = ({ range }: { range: PriceRange }) =>
  range.upper === range.lower ? (
    <FormattedCurrencyUnits amountUnits={range.upper} />
  ) : (
    <>
      {" "}
      <FormattedCurrencyUnits amountUnits={range.lower} /> -{" "}
      <FormattedCurrencyUnits amountUnits={range.upper} />{" "}
    </>
  )
