import { isPending, refreshFold } from "@nll/datum/DatumEither"
import * as O from "fp-ts/es6/Option"
import { pipe } from "fp-ts/function"
import { Card, CardContent } from "packages/app/src/elements"
import { useEffect } from "react"
import "twin.macro"
import { Button } from "../../../elements/Button"
import { FormattedCurrencyUnits } from "../../../elements/Currency"
import { SkeletonCard } from "../../../elements/Skeleton"
import { Statistic } from "../../../elements/Statistic"
import { useCreateSolicitorAffiliateId } from "../../../hooks/useCreateSolicitorAffiliateId"
import {
  Affiliate,
  useGetSolicitorAffiliate
} from "../../../hooks/useGetSolicitorAffiliate"
import { useFromTaskEither } from "../../../lib/useAsync"

export const AffiliateLinksCell = () => {
  const affiliateTE = useGetSolicitorAffiliate()

  const { status, execute } = useFromTaskEither(affiliateTE)

  useEffect(() => {
    execute()
  }, [])

  return pipe(
    status,
    refreshFold(
      () => <SkeletonCard />,
      () => <SkeletonCard />,
      () => <SkeletonCard />,
      O.fold(
        () => <AffiliateLinksActionPanel onCreate={execute} />,
        (a) => <AffiliateStatusPanel affiliate={a} />
      )
    )
  )
}

export const AffiliateStatusPanel = ({
  affiliate
}: {
  affiliate: Affiliate
}) => {
  return (
    <Card>
      <CardContent tw="space-y-8">
        <h3 tw="text-lg leading-6 font-medium text-gray-900">
          You're earning money by referring clients to other Lawhive solicitors!
        </h3>
        <p>Your referral link is {affiliate.links[0].url}</p>
        <div tw="space-y-2 text-sm text-gray-500">
          <div tw="flex flex-row space-x-8">
            <Statistic label="Site visitors" value={affiliate.visitors} />
            <Statistic label="Cases created" value={affiliate.leads} />
            <Statistic label="Cases paid" value={affiliate.conversions} />
            <Statistic
              label="Earnings so far"
              value={
                <FormattedCurrencyUnits
                  amountUnits={
                    affiliate.commission_stats.currencies.GBP.total.cents
                  }
                />
              }
            />
          </div>
        </div>
        <div tw="space-y-2 text-sm text-gray-500">
          <p>
            You'll earn a referral fee of 10% on the value of any work done by
            clients you refer to other Lawhive solicitors. We'll take this off
            your Lawhive fees each month.
          </p>
          <p>
            Just use your unique referral link to give to clients and we will do
            the rest, including assigning them to the right Lawhive solicitor
            able to help them with their matter, tracking how much they spend
            and instantly paying you the correct referral fees.
          </p>
        </div>
        {/*<div tw="flex flex-col gap-2 sm:flex-row sm:gap-4 items-start">*/}
        {/*  <Button onClick={openAffiliateDashboard()} isLoading={isPending(linkStatus)}>*/}
        {/*    Take me to the dashboard*/}
        {/*  </Button>*/}
        {/*</div>*/}
      </CardContent>
    </Card>
  )
}

export const AffiliateLinksActionPanel = ({
  onCreate
}: {
  onCreate: () => void
}) => {
  const createAffTE = useCreateSolicitorAffiliateId()
  const { status, execute } = useFromTaskEither(createAffTE)

  const handleClick = () => {
    execute().then(onCreate)
  }

  return (
    <Card>
      <CardContent tw="space-y-8">
        <h3 tw="text-lg leading-6 font-medium text-gray-900">
          You can now earn money by referring clients to other Lawhive
          solicitors!
        </h3>
        <div tw="space-y-4 text-sm text-gray-500">
          <p>
            You'll earn a referral fee of 10% on the value of any work done by
            clients you refer to other Lawhive solicitors. We'll take this off
            your Lawhive fees each month.
          </p>
          <p>
            Just use your unique referral link to give to clients and we will do
            the rest, including assigning them to the right Lawhive solicitor
            able to help them with their matter, tracking how much they spend
            and instantly paying you the correct referral fees.
          </p>
        </div>
        <div tw="flex flex-col gap-2 sm:flex-row sm:gap-4 items-start">
          <Button onClick={handleClick} isLoading={isPending(status)}>
            Sign up
          </Button>
        </div>
      </CardContent>
    </Card>
  )
}
