import { GraphQLResult } from "@aws-amplify/api-graphql"
import {
  CaseCompletionType,
  CaseDetailsType,
  CasePaymentType,
  CaseStatus,
  GetCaseQuery,
  GetCaseQueryVariables
} from "@lawhive/generated-api"
import { chain, map as mapDatum, mapLeft } from "@nll/datum/DatumEither"
import { parseISO } from "date-fns"
import { pipe } from "fp-ts/es6/function"
import { fromNullable, map as mapOption, none, Option, some } from "fp-ts/es6/Option"
import { Solicitor } from "../../lib/types"

import { ApiError, datumFromOption, parseConnection, useAsync } from "../../lib/useAsync"
import { graphql } from "../../lib/utils"

export type CaseDetails = {
  id: string,
  createdAt: Date,
  updatedAt: Date,
  productSKU: Option<string>,
  hasChatbot: boolean,
  completion: Option<{
    details?: string | null
    type?: CaseCompletionType | null
  }>
  clientKyc?: {
    isSubmitted: boolean
    isVerified: boolean
    verificationReport: Option<{
      amlReportFileKey: string
      kycReportFileKey: Option<string>
    }>
    documentUploads: Option<string[]>
    identityUploads: Option<string[]>
  } | null
  brand?: string | null,
  details: Option<{
    type: CaseDetailsType,
    summary?: string | null
    ask?: string | null
    details: string
    isTimeSensitive?: boolean | null
  }>,
  friendlyId: string,
  client: Option<User>
  // quotes: CaseQuote[]
  // payments: CasePayment[]
  messages: CaseMessage[]
  // associations: CaseAssociation[]
  status: CaseStatus
  solicitor: Option<Solicitor>
}

// export type CaseQuote = {
//   id: string
//   isLeadFromAds?: boolean | null
//   updatedAt: Date
//   lineItems: CaseLineItem[]
//   payments: CasePayment[]
//   isManualFee?: boolean
// }

// export type CaseLineItem = {
//   id: string
//   quoteId: string
//   clientPays: number
//   solicitorReceives: number
//   subsidy: number
//   platformFee: number
//   feePercentage: number
//   scope?: {
//     includes: string[]
//     excludes: string[]
//   }
//   description?: string | null
//   createdAt: Date
//   updatedAt: Date
// }

// export type SolicitorBiography = {
//   id: string
//   specialisms: string[]
//   name: string
//   yearsPractising: string
// }

export type CaseMessage = {
  id: string
  author: Option<User>
  authorId: string

  caseId: string
  content: string
  createdAt: Date
  updatedAt: Date
}

export type User = {
  id: string
  displayName: Option<string>
  phoneNumber: Option<string>
  // type: UserType
}

// export type CaseAssociation = {
//   id: string
//   chatMember: ChatMember
//   chatMemberId: string

//   caseId: string
//   type: CaseAssociationType
// }

// export type CasePayment = {
//   id: string
//   caseId: string
//   clientId: string
//   type: CasePaymentType
//   refunded: boolean
//   amount: number
//   createdAt: Date
// }

export const useGetCase = (caseId: string, query: string) => {
  const { status, execute, reset } = useAsync(
    () => graphql<GetCaseQuery, GetCaseQueryVariables>({
      query,
      variables: {
        id: caseId
      }
    })
  )

  const parseResult = (input: GraphQLResult<GetCaseQuery>): Option<CaseDetails> => pipe(
    fromNullable(input.data?.getCase),
    mapOption((i): CaseDetails => ({
      id: i.id,
      completion: fromNullable(i.completion),
      friendlyId: i.caseNumber,
      updatedAt: parseISO(i.updatedAt),
      createdAt: parseISO(i.createdAt),
      hasChatbot: i.hasChatbot || false,
      productSKU: fromNullable(i.productSKU),
      brand: i.brand,
      clientKyc: i.clientKyc
        ? {
          isSubmitted: i.clientKyc.isSubmitted || false,
          isVerified: i.clientKyc.isVerified || false,
          verificationReport: pipe(
            fromNullable(i.clientKyc.verificationReport),
            mapOption(report => ({
              amlReportFileKey: report.amlReportFileKey,
              kycReportFileKey: fromNullable(report.kycReportFileKey)
            }))
          ),
          documentUploads: fromNullable(i.clientKyc.documentUploads),
          identityUploads: fromNullable(i.clientKyc.identityUploads),
        }
        : null,
      solicitor: i.solicitor ? some({
        businessName: i.solicitor.businessName,
        id: i.solicitor.id,
        name: i.solicitor.name,
        stripeAccountId: fromNullable(i.solicitor.stripeAccountId)
      }) : none,
      details: i.details ? some({
        type: i.details.type,
        details: i.details.details,
        ask: i.details?.ask,
        summary: i.details?.summary,
        isTimeSensitive: i.details?.isTimeSensitive || false
      }) : none,
      client: i.client ? some({
        id: i.client.id,
        type: i.client.type,
        displayName: fromNullable(i.client?.displayName),
        phoneNumber: fromNullable(i.client?.phoneNumber)
      }) : none,
      // associations: parseConnection(i.associations)(
      //   (a): CaseAssociation => ({
      //     caseId: a.caseId,
      //     chatMemberId: a.chatMemberId,
      //     id: a.id,
      //     type: a.type,
      //     chatMember: {
      //       displayName: fromNullable(a.chatMember.displayName),
      //       id: a.chatMember.id,
      //       profileUrl: fromNullable(a.chatMember.profileUrl),
      //       type: a.chatMember.type
      //     }
      //   })
      // ),
      messages: parseConnection(i.messages)(
        (m): CaseMessage => ({
          id: m.id,
          caseId: m.caseId,
          authorId: m.authorId,
          content: m.content,
          createdAt: parseISO(m.createdAt),
          updatedAt: parseISO(m.updatedAt),
          author: m.author ? some({
            displayName: fromNullable(m.author?.displayName),
            phoneNumber: fromNullable(m.author?.phoneNumber),
            id: m.authorId,
            // type: m.author?.type || UserType.client
          }) : none
        })
      ),
      // payments: parseConnection(i.payments)(
      //   (p): CasePayment => ({
      //     amount: p.amount,
      //     caseId: p.caseId,
      //     id: p.id,
      //     clientId: p.clientId,
      //     type: p.type,
      //     refunded: p.refunded || false,
      //     createdAt: parseISO(p.createdAt)
      //   })
      // ),
      // quotes: parseConnection(i.quotes)(
      //   (q): CaseQuote => ({
      //     id: q.id,
      //     isLeadFromAds: q.isLeadFromAds,
      //     isManualFee: q.isManualFee || false,
      //     updatedAt: parseISO(q.updatedAt),
      //     payments: parseConnection(q.payments)(
      //       (p): CasePayment => ({
      //         clientId: p.clientId,
      //         refunded: p.refunded || false,
      //         type: p.type,
      //         amount: p.amount,
      //         caseId: p.caseId,
      //         id: p.id,
      //         createdAt: parseISO(p.createdAt)
      //       })
      //     ),
      //     lineItems: parseConnection(q.lineItems)(
      //       (l): CaseLineItem => ({
      //         quoteId: l.quoteId,
      //         clientPays: l.clientPays,
      //         feePercentage: l.feePercentage,
      //         platformFee: l.platformFee,
      //         solicitorReceives: l.solicitorReceives,
      //         subsidy: l.subsidy,
      //         description: l.description,
      //         createdAt: parseISO(l.createdAt),
      //         updatedAt: parseISO(l.updatedAt),
      //         scope: l.scope
      //           ? {
      //             excludes: l.scope.excludes,
      //             includes: l.scope.includes
      //           }
      //           : undefined,
      //         id: l.id
      //       })
      //     )
      //   })
      // ),
      status: i.status
    }))
  )

  const state = pipe(
    status,
    mapDatum(parseResult),
    mapLeft(ApiError.Error),
    chain(datumFromOption(() => ApiError.NotFound()))
  )

  return { status: state, execute, reset }
}


export const adminGetCaseQuery = /* GraphQL */ `
    query GetCase($id: ID!) {
        getCase(id: $id) {
            id
            status
            createdAt
            updatedAt
            caseNumber
            brand
            productSKU
            hasChatbot
            clientId
            context
            payments {
                items {
                    id
                }
            }
            client {
                displayName
                phoneNumber
            }
            clientKyc {
                isSubmitted
                isVerified
                verificationReport {
                    amlReportFileKey
                    kycReportFileKey
                }
                documentUploads
                identityUploads
            }
            details {
                type
                details
                summary
                ask
                isTimeSensitive
            }
            messages(limit: 1000) {
                items {
                    content
                    createdAt
                    authorId
                    author {
                        displayName
                        id
                    }
                    updatedAt
                }
            }
            completion {
                details
                type
            }
            quotes {
                items {
                    id
                    isLeadFromAds
                    isManualFee
                    payments {
                        items {
                            id
                            createdAt
                            amount
                        }
                    }
                    lineItems {
                        items {
                            id
                            clientPays
                            description
                            solicitorReceives
                            createdAt
                            updatedAt
                            scope {
                                includes
                                excludes
                            }
                        }
                    }
                }
            }
            solicitor {
                id
                name
                businessName
                stripeAccountId
            }
    }
  }
`


export const solicitorGetCaseQuery = /* GraphQL */ `
    query GetCase($id: ID!) {
        getCase(id: $id) {
            id
            status
            createdAt
            updatedAt
            caseNumber
            productSKU
            hasChatbot
            context
            clientId
            payments {
                items {
                    id
                }
            }
            clientKyc {
                isSubmitted
                isVerified
                verificationReport {
                    amlReportFileKey
                    kycReportFileKey
                }
                documentUploads
                identityUploads
            }
            completion {
                details
                type
            }
            client {
                id
                displayName
                phoneNumber
            }
            brand
            details {
                type
                details
                summary
                ask
                isTimeSensitive
            }
            messages(limit: 1000) {
                items {
                    content
                    createdAt
                    authorId
                    author {
                        displayName
                        id
                    }
                    updatedAt
                }
            }
            quotes {
                items {
                    id
                    isLeadFromAds
                    isManualFee
                    payments {
                        items {
                            id
                            createdAt
                            amount
                        }
                    }
                    lineItems {
                        items {
                            id
                            description
                            solicitorReceives
                            clientPays
                            createdAt
                            updatedAt
                            scope {
                                includes
                                excludes
                            }
                        }
                    }
                }
            }
            solicitor {
                id
                name
                businessName
                stripeAccountId
      }
    }
  }
`

export const clientGetCaseQuery = /* GraphQL */ `
    query GetCase($id: ID!) {
        getCase(id: $id) {
            id
            status
            createdAt
            updatedAt
            caseNumber
            productSKU
            hasChatbot
            brand
            clientId
            context
            payments {
                items {
                    id
                }
            }
            clientKyc {
                isSubmitted
                isVerified
                verificationReport {
                    amlReportFileKey
                    kycReportFileKey
                }
            }
            completion {
                details
                type
            }
            details {
                type
                details
                summary
                ask
            }
            messages(limit: 1000) {
                items {
                    content
                    createdAt
                    authorId
                    author {
                        displayName
                        id
                    }
                    updatedAt
                }
            }
            quotes {
                items {
                    id
                    isLeadFromAds
                    payments {
                        items {
                            id
                            createdAt
                        }
                    }
                    lineItems {
                        items {
                            id
                            description
                            clientPays
                            createdAt
                            updatedAt
                            scope {
                                includes
                                excludes
                            }
                        }
                    }
                }
            }
            solicitor {
                id
                name
                businessName
                stripeAccountId
            }
        }
    }
`
