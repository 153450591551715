import { map } from "@nll/datum/DatumEither"
import { pipe } from "fp-ts/es6/function"
import { chain, getOrElse } from "fp-ts/es6/Option"
import { extractQuoteInformation } from "packages/app/src/features/quotes"
import { useQuotes } from "packages/app/src/features/quotes/context/QuoteProvider"
import { ClientPaymentsView } from "packages/app/src/features/quotes/routes/ClientPaymentsView"
import tw from "twin.macro"
import { useCase } from "../../../../../contexts/case/CaseContext"
import { useUser } from "../../../../../contexts/User"
import { CardContent } from "../../../../../elements"
import {
  Message,
  MessagesCard,
  SkeletonMessagesCard
} from "../../../../../elements/case/MessagesCard"
import {
  FilesIcon,
  MessageIcon,
  PaymentsIcon
} from "../../../../../elements/Icons"
import { Spinner } from "../../../../../elements/Loading"
import { ROUTES } from "../../../../../layout/Navigation"
import { renderLoadingOrSuccess } from "../../../../../lib/utils"
import { ComponentWithCase } from "../../CaseDetails"
import { CaseFilesCard } from "../../shared/CaseFilesCard"
import { CaseTabLayout } from "../../shared/CaseTabLayout"

export const ClientWaitingForSolicitorStatusPanel = () => {
  return (
    <div tw="flex flex-row items-center">
      <div tw="rounded-full bg-pink-200 h-12 w-12 mr-4 sm:(h-20 w-20 mr-8) flex items-center justify-center animate-pulse  flex-shrink-0">
        <Spinner tw="text-pink-400 w-6 h-6 sm:(w-8 h-8)" />
      </div>
      <div>
        <h2 tw="font-semibold text-base sm:text-2xl text-gray-900">
          Assigning you a solicitor...
        </h2>
        <p tw="mt-2 text-xs sm:text-base text-gray-700">
          We've received your case information and we're finding you the perfect
          solicitor.
        </p>
        <p tw="mt-2 text-xs sm:text-base text-gray-700 font-medium">
          You'll receive an email once a solicitor is assigned. In the meantime,
          you can leave messages for your solicitor or upload files.
        </p>
      </div>
    </div>
  )
}

const InformationPanel = tw.div`bg-pink-50 px-4 py-2 rounded-md text-gray-800 text-sm`

export const ClientWaitingForSolicitorDetailsPanel: ComponentWithCase = ({
  case: cas
}) => {
  const { firstQuote } = useQuotes()

  const showPaymentsTab =
    firstQuote &&
    extractQuoteInformation(firstQuote).quoteType === "ClientAuthorisation"

  return (
    <CaseTabLayout
      defaultRedirect={ROUTES.case.messages(cas.id)}
      config={[
        {
          label: "Messages",
          icon: <MessageIcon />,
          to: ROUTES.case.messages(cas.id),
          path: ROUTES.case.messages(":caseId"),
          render: () => <MessageCard />
        },
        {
          label: "Files",
          icon: <FilesIcon />,
          to: ROUTES.case.files(cas.id),
          path: ROUTES.case.files(":caseId"),
          render: () => <CaseFilesCard canUpload={true} />
        },
        ...(showPaymentsTab
          ? [
              {
                label: "Payments",
                icon: <PaymentsIcon />,
                to: ROUTES.case.fees(cas.id),
                path: ROUTES.case.fees(":caseId"),
                render: () => <ClientPaymentsView />
              }
            ]
          : [])
      ]}
    />
  )
}

export const MessageCard = () => {
  const { userId } = useUser()
  const { addMessage, messages, caseId } = useCase()
  const { firstQuote } = useQuotes()

  const showFuturePaymentMessage = firstQuote
    ? extractQuoteInformation(firstQuote).quoteType === "SolicitorPayment"
    : true

  const displayableMessages = pipe(
    messages,
    map((ms) =>
      ms.map(
        (m): Message => ({
          authorName: getOrElse(() => "Lawhive user")(
            pipe(
              m.author,
              chain((a) => a.displayName)
            )
          ),
          content: m.content,
          fromMe: m.authorId === userId,
          timestamp: m.createdAt
        })
      )
    )
  )

  return renderLoadingOrSuccess(
    () => <SkeletonMessagesCard />,
    (messages: Message[]) => (
      <MessagesCard caseId={caseId} messages={messages} addMessage={addMessage}>
        <CardContent>
          <InformationPanel>
            {showFuturePaymentMessage
              ? "Once your quote has been created and paid, these messages will be seen by your solicitor."
              : "Any messages you send will be seen by your solicitor. You'll receive an email once they've responded."}
          </InformationPanel>
        </CardContent>
      </MessagesCard>
    )
  )(displayableMessages)
}
