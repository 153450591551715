import { FC, useMemo } from "react"
import tw from "twin.macro"

import { FormattedCurrencyUnits } from "../../../elements/Currency"
import {
  PlainDataTable,
  TableColumn
} from "../../../elements/table/PlainDataTable"
import { lineBreaksToHtml } from "../../../lib/helpers"

type LineItem = {
  name: string
  // type: QuoteLineItemFeeType
  amount: number
}

type QuoteLineItemsTableProps = {
  priceLabel: string
  lineItems: LineItem[]
}

export const QuoteLineItemsTable: FC<QuoteLineItemsTableProps> = ({
  priceLabel,
  lineItems,
  ...rest
}) => {
  const previewColumns = useMemo(
    (): TableColumn<LineItem>[] => [
      {
        Header: "Description",
        accessor: "name",
        Cell: ({ value }) => (
          <>
            <p tw="leading-5 font-medium text-gray-900">
              {lineBreaksToHtml(value || "")}
            </p>
          </>
        )
      },
      {
        Header: priceLabel,
        accessor: "amount",
        headerStyle: tw`text-right`,
        Cell: ({ value }) => (
          <p tw="text-right">
            <FormattedCurrencyUnits amountUnits={value} />
          </p>
        )
      }
    ],
    [priceLabel]
  )

  return <PlainDataTable columns={previewColumns} data={lineItems} {...rest} />
}
