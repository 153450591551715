import * as D from "io-ts/lib/Decoder"
import { Quote, QuoteEntityV2 } from "packages/app/src/features/quotes/types"
import { ADT } from "packages/app/src/lib/adt"
import { UUID } from "packages/app/src/lib/Primitives"

import { fetchParsedJSON } from "../../../lib/fetch"
import {
  DateFromString,
  decodeAny,
  decodeOrError,
  optional
} from "../../../lib/io"
import { useApiFetch } from "../../../lib/useApiClient"
import { useFromTaskEither } from "../../../lib/useAsync"
import { AssessmentCallStatus } from "../../../scenes/assessment-calls/AssessmentCallsList"
import { NotAssessedReason } from "./useCompleteCall"

export const SolicitorQuoteStatus = D.literal(
  "proposed",
  "approved",
  "rejected",
  "converted",
  // TODO Roll SolicitorQuote statuses and approvals into
  "unpaid"
)
export type SolicitorQuoteStatus = D.TypeOf<typeof SolicitorQuoteStatus>

export const CallResultDTO = ADT({
  NoAnswer: undefined,
  NotAssessed: NotAssessedReason,
  SolicitorQuoted: D.struct({
    status: SolicitorQuoteStatus,
    // TODO Fix
    quote: decodeAny<QuoteEntityV2>(),
    caseId: optional(UUID),
    quotedAt: optional(DateFromString),
    clientNotifiedAt: optional(DateFromString)
  }),
  NotLegalMatter: undefined
})
export type CallResultDTO = D.TypeOf<typeof CallResultDTO>

export type AssessmentCallUIDetailsResponse = D.TypeOf<
  typeof AssessmentCallUIDetailsResponse
>

export const AssessmentCallUIDetailsResponse = D.struct({
  id: UUID,
  status: AssessmentCallStatus,
  leadId: UUID,
  leadName: D.string,
  leadPhoneNumber: D.string,
  result: optional(CallResultDTO),
  requestDetails: optional(D.string),
  clientSelections: optional(D.array(DateFromString)),
  solicitorSelection: optional(DateFromString),
  completedAt: optional(DateFromString)
})

export const useAssessmentCallDetails = () => {
  const api = useApiFetch(
    fetchParsedJSON(decodeOrError(AssessmentCallUIDetailsResponse))
  )

  const { status, execute } = useFromTaskEither((id: string) =>
    api(`assessment-calls/${id}/private`)
  )

  return { status, execute }
}
