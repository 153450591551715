import { createContext, default as React, FC, useContext, useEffect, useMemo } from 'react'
import { clientEnsureUserIsConfiguredCommand, ClientEnsureUserIsConfiguredCommandMutation, getUser, GetUserQuery, GetUserQueryVariables, KYCSubmissionStatus, UserType } from '@lawhive/generated-api'
import { fromNullable, none, Option, some } from 'fp-ts/es6/Option'
import 'twin.macro'

import { CognitoUserExt, LoggedInUser, useAuthentication, UserState } from './Authentication'
import { graphql, noop, renderLoadingOrSuccess, renderLoadingRefreshOrSuccess } from '../lib/utils'
import { useAsync } from '../lib/useAsync'
import { map, refreshFold } from '@nll/datum/DatumEither'
import { pipe } from 'fp-ts/es6/function'
import { LoggedOutShell } from '../layout/LoggedOutShell'
import { Card, CardContent } from '../elements'
import { OnboardingShell } from '../layout/OnboardingShell'
import { ContentContainer, PageContainer } from '../layout/Layout'
import { Button } from '../elements/Button'

const SOLICITOR_GROUP = "solicitors"
const ADMIN_GROUP = "admins"

type UserContext = {
  userId: string
  emailAddress: string
  isSolicitor: boolean
  isAdmin: boolean
  name: Option<string>
  kycSubmission: Option<KycSubmission>
  refresh: () => void
}

type AuthUser = {
  userId: string
  cognitoName: string
  federatedId: string
  emailAddress: string
  isSolicitor: boolean
  isAdmin: boolean
}

type DbUser = {
  type: UserType
  emailAddress: string

  displayName: Option<string>
  phoneNumber: Option<string>
  kycSubmission: Option<KycSubmission>
}

export type KycSubmission = {
  status: Option<KYCSubmissionStatus>
  address: Option<string>
  documentUploads: Option<string[]>
  identityUploads: Option<string[]>
  rejectionReason: Option<KycRejectionReason>
}

type KycRejectionReason = {
  reason?: string | null
  instructions?: string | null
}

const initialUserContext: UserContext = {
  userId: '',
  name: none,
  emailAddress: '',
  isSolicitor: false,
  isAdmin: false,
  kycSubmission: none,
  refresh: noop
}

const UserContext = createContext<UserContext>(initialUserContext)

const isInGroup = (user: CognitoUserExt) => (group: string) =>
  (user.signInUserSession.accessToken.payload["cognito:groups"] || []).includes(group)


export const useUser = () => useContext(UserContext)

export const UserProvider: FC<{ user: LoggedInUser, loading: JSX.Element }> = ({ user, loading, children }) => {
  const { status: userStatus, execute: loadUser, reset } = useAsync(
    (userId: string) =>
      graphql<GetUserQuery, GetUserQueryVariables>({
        query: /* GraphQL */ `
          query GetUser($id: ID!) {
            getUser(id: $id) {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                status
                address
                documentUploads
                identityUploads
                rejectionReason {
                  reason
                  instructions
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
          }
        `,
        variables:  {
          id: userId
        }
      })
      .then(r => r.data?.getUser
        ? Promise.resolve(r.data.getUser)
        : Promise.reject('User load error')
      )
      .then((r): DbUser => ({
        emailAddress: r.emailAddress!,
        kycSubmission: r.kycSubmission
          ? some({
            address: fromNullable(r.kycSubmission.address),
            documentUploads: fromNullable(r.kycSubmission.documentUploads),
            identityUploads: fromNullable(r.kycSubmission.identityUploads),
            rejectionReason: fromNullable(r.kycSubmission.rejectionReason),
            status: fromNullable(r.kycSubmission.status)
          })
          : none,
        type: r.type!,
        displayName: fromNullable(r.displayName),
        phoneNumber: fromNullable(r.phoneNumber)
      }))
  )

  const authenticationDetails: AuthUser = useMemo(
    (): AuthUser => ({
      userId: user.info.attributes.sub,
      cognitoName: user.user.attributes.name,
      federatedId: user.info.id,
      emailAddress: user.user.attributes.email,
      isSolicitor: isInGroup(user.user)(SOLICITOR_GROUP),
      isAdmin: isInGroup(user.user)(ADMIN_GROUP)
    }),
    [user]
  )

  useEffect(() => {
    loadUser(user.info.attributes.sub)
    return reset
  }, [user])

  const combined = useMemo(() => pipe(
    userStatus,
    map((s): UserContext => ({
      userId: authenticationDetails.userId,
      emailAddress: authenticationDetails.emailAddress,
      isAdmin: authenticationDetails.isAdmin,
      isSolicitor: authenticationDetails.isSolicitor,
      kycSubmission: s.kycSubmission,
      name: s.displayName,
      refresh: () => loadUser(authenticationDetails.userId)
    }))
  ), [userStatus, authenticationDetails])

  return refreshFold(
    () => loading,
    () => loading,
    () => <UserLoadError email={user.info.attributes.email} />,
    (c: UserContext) => (
      <UserContext.Provider value={c}>
        {children}
      </UserContext.Provider>
    )
  )(combined)
}

const UserLoadError: FC<{ email: string }> = ({ email }) => {
  const { logout } = useAuthentication()
  return (
    <OnboardingShell>
      <PageContainer tw="max-w-screen-md">
        <ContentContainer>
          <Card>
            <div tw="px-4 py-5 border-b border-gray-200 sm:px-6">
              <h1 tw="text-xl leading-6 font-medium text-gray-900">
                Sorry! There was an issue logging you in
              </h1>

              <p tw="mt-4 max-w-2xl text-sm leading-5 text-gray-500">
                <Button onClick={() => logout()}>Try again</Button>
              </p>

              <p tw="mt-6 leading-8">
                If you keep experiencing this issue, please
                email <a tw="font-medium underline text-indigo-600" href="mailto:support@lawhive.co.uk">support@lawhive.co.uk</a> from
                your email address {email} saying "There was an issue logging me in".
              </p>
            </div>

          </Card>
        </ContentContainer>
      </PageContainer>
    </OnboardingShell>
  )
}
