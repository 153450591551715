import React, { FC } from "react"
import { Link } from "react-router-dom"
import 'twin.macro'

import { Card, CardContent } from "../../elements"
import { ROUTES } from "../../layout/Navigation"
import { useRouter } from "../../lib/useRouter"

export const VerifiedStep: FC = () => {
  const { params: { returnUrl } } = useRouter<{}, {returnUrl: string}>()

  return (
    <Card tw="mt-4">
      <CardContent tw="border-b border-gray-200">
        <h1 tw="text-lg leading-8 font-medium text-gray-900">
          Your identity has been verified!
        </h1>
      </CardContent>

      <CardContent>
        <p tw="text-gray-900 text-sm sm:text-base">
          You don't need to do anything else at this time.
        </p>
      </CardContent>

      {returnUrl
        ? (
          <CardContent>
            <div tw="rounded-md shadow-sm">
              <Link to={returnUrl} tw="flex items-center justify-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring transition ease-in-out duration-150">
                Return to case
              </Link>
            </div>
          </CardContent>
        )
        : (
          <CardContent>
            <div tw="rounded-md shadow-sm">
            <Link to={ROUTES.case.root} tw="flex items-center justify-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring transition ease-in-out duration-150">
              Return to case list
            </Link>
          </div>
        </CardContent>
      )}
    </Card>
  )
}
