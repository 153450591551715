import {
  calculateAdminPriceSummary,
  constructDraftQuote,
  DraftQuote
} from "@lawhive/domain"
import {
  AddQuoteToCaseCommandMutation,
  AddQuoteToCaseCommandMutationVariables
} from "@lawhive/generated-api"
import { isPending, isSuccess } from "@nll/datum/DatumEither"
import { Either, isRight, map } from "fp-ts/es6/Either"
import { FC, useCallback, useMemo } from "react"
import { useForm } from "react-hook-form"
import "twin.macro"

import { pipe } from "fp-ts/es6/function"
import { QuoteLineItemsTable } from "packages/app/src/features/quotes/components/QuoteLineItemsTable"
import { QuoteScopesTable } from "packages/app/src/features/quotes/components/QuoteScopesTable"
import { useCase } from "../../../../contexts/case/CaseContext"
import { CaseDetails } from "../../../../contexts/case/useGetCase"
import { Button } from "../../../../elements/Button"
import { CaseTitleBreadcrumb } from "../../../../elements/case/CaseTitleBreadcrumb"
import { FormattedCurrencyUnits } from "../../../../elements/Currency"
import {
  CheckItem,
  Form,
  FormField,
  TextArea,
  TextInput
} from "../../../../elements/Form"
import { PanelGrid } from "../../../../elements/Layout"
import {
  ScenePanel,
  ScrollingChildrenContainer
} from "../../../../layout/Layout"
import { ROUTES } from "../../../../layout/Navigation"
import { useAsync } from "../../../../lib/useAsync"
import { useRouter } from "../../../../lib/useRouter"
import { eitherOrNull, graphql } from "../../../../lib/utils"

export const AdminCaseAddPriceScene = () => {
  const { caseDetails } = useCase()
  return isSuccess(caseDetails) ? (
    <>
      <CaseTitleBreadcrumb
        caseNumber={caseDetails.value.right.friendlyId}
        action={"Add details"}
      />
      <ScrollingChildrenContainer>
        <ScenePanel>
          <AddDetailsForm cas={caseDetails.value.right} />
        </ScenePanel>
      </ScrollingChildrenContainer>
    </>
  ) : null
}

type DetailsInput = {
  clientPays: string
  solicitorReceives: string

  isLeadFromAds: boolean

  description: string

  includes: string
  excludes: string
}

const buildQuote = (input: DetailsInput): Either<Error, DraftQuote> => {
  const clientPays = parseInt(input.clientPays)
  const solicitorReceives = parseInt(input.solicitorReceives)

  const includes = input.includes.length > 0 ? input.includes.split("\n") : []
  const excludes = input.excludes.length > 0 ? input.excludes.split("\n") : []

  return constructDraftQuote({
    isLeadFromAds: input.isLeadFromAds,
    items: [
      {
        clientPays,
        solicitorReceives,
        description: input.description,
        scope: {
          includes,
          excludes
        }
      }
    ]
  })
}

const AddDetailsForm: FC<{ cas: CaseDetails }> = ({ cas }) => {
  const { refresh } = useCase()
  const { replace } = useRouter()

  const { register, handleSubmit, watch } = useForm<DetailsInput>({
    defaultValues: {
      clientPays: String(0),
      solicitorReceives: String(0),
      description: "",
      isLeadFromAds: false,
      includes: "",
      excludes: ""
    }
  })

  const { status, execute } = useAsync((input: DraftQuote) =>
    graphql<
      AddQuoteToCaseCommandMutation,
      AddQuoteToCaseCommandMutationVariables
    >({
      query: /* GraphQL */ `
        mutation AddQuoteToCaseCommand($input: AddQuoteToCaseCommand!) {
          addQuoteToCaseCommand(input: $input) {
            id
          }
        }
      `,
      variables: {
        input: {
          caseId: cas.id,
          isLeadFromAds: input.isLeadFromAds || false,
          lineItems: input.items
        }
      }
    })
      .then(() => refresh())
      .then(() => replace(ROUTES.case.details(cas.id)))
  )

  const detailsInput = watch()

  const draftQuote = useMemo(() => buildQuote(detailsInput), [detailsInput])

  const priceSummary = useMemo(
    () => pipe(draftQuote, map(calculateAdminPriceSummary)),
    [draftQuote]
  )

  const submit = useCallback(() => {
    if (isRight(draftQuote)) {
      execute(draftQuote.right)
    }
  }, [draftQuote])

  return (
    <Form onSubmit={handleSubmit(submit)}>
      <div tw="pb-4 flex flex-col">
        <div tw="grid grid-cols-1 gap-2 sm:(grid-cols-2 gap-6)">
          <div>
            <FormField
              id={"clientPays"}
              label={"Client Pays"}
              control={
                <>
                  <TextInput
                    name="clientPays"
                    id="clientPays"
                    type="number"
                    ref={register}
                    required
                  />

                  {eitherOrNull(priceSummary, (summary) => (
                    <p tw="my-2 font-bold">
                      Client will pay{" "}
                      <FormattedCurrencyUnits
                        amountUnits={summary.clientPays}
                      />
                    </p>
                  ))}
                </>
              }
            />

            <FormField
              id={"solicitorReceives"}
              label={"Solicitor Receives"}
              control={
                <>
                  <TextInput
                    name="solicitorReceives"
                    id="solicitorReceives"
                    type="number"
                    ref={register}
                    required
                  />

                  {eitherOrNull(priceSummary, (summary) => (
                    <p tw="my-2 font-bold">
                      Solicitor will receive after rake{" "}
                      <FormattedCurrencyUnits
                        amountUnits={summary.solicitorEarnsAfterRake}
                      />
                      <br />
                      (After all fees{" "}
                      <FormattedCurrencyUnits
                        amountUnits={summary.solicitorEarnsAfterAllFees}
                      />
                      )
                    </p>
                  ))}
                </>
              }
            />

            <FormField
              id={"isLeadFromAds"}
              label={"Is Lead From Ads"}
              control={
                <>
                  <CheckItem
                    name="isLeadFromAds"
                    id="isLeadFromAds"
                    ref={register}
                  >
                    Is Lead from ads?
                  </CheckItem>
                </>
              }
            />
          </div>

          {eitherOrNull(priceSummary, (summary) => (
            <div>
              <FormField
                id="platformFee"
                label={"Platform fee"}
                control={
                  <p tw="font-bold">
                    <FormattedCurrencyUnits amountUnits={summary.lawhiveRake} />
                  </p>
                }
              />

              <FormField
                id="feePercentage"
                label={"Fee Percentage"}
                control={<p tw="font-bold">{summary.lawhiveRakePercentage}%</p>}
              />
            </div>
          ))}
        </div>

        <FormField
          id={"description"}
          label={"Description"}
          control={
            <div tw="relative rounded-md shadow-sm">
              <TextArea
                id="description"
                name={"description"}
                ref={register}
                rows={4}
                required
              />
            </div>
          }
        />

        <div tw="grid grid-cols-2 gap-6">
          <FormField
            id={"includes"}
            label={"Includes (use line breaks to separate)"}
            control={
              <div tw="relative rounded-md shadow-sm">
                <TextArea
                  id="includes"
                  name={"includes"}
                  ref={register}
                  rows={4}
                />
              </div>
            }
          />

          <FormField
            id={"excludes"}
            label={"Excludes (use line breaks to separate)"}
            control={
              <div tw="relative rounded-md shadow-sm">
                <TextArea
                  id="excludes"
                  name={"excludes"}
                  ref={register}
                  rows={4}
                />
              </div>
            }
          />
        </div>
      </div>

      <hr />

      <div tw="py-4">
        <h2 tw="text-lg font-bold">Preview</h2>

        {eitherOrNull(draftQuote, (quote) => (
          <PanelGrid tw="py-4">
            <QuoteLineItemsTable
              priceLabel={"To Pay"}
              lineItems={quote.items.map((i) => ({
                amount: i.clientPays,
                name: i.description || "Legal Fees"
              }))}
            />

            <QuoteScopesTable
              scopes={[
                ...quote.items.flatMap((i) =>
                  (i.scope?.includes || []).map((s) => ({
                    title: s,
                    type: "inclusion" as const
                  }))
                ),
                ...quote.items.flatMap((i) =>
                  (i.scope?.excludes || []).map((s) => ({
                    title: s,
                    type: "exclusion" as const
                  }))
                )
              ]}
            />
          </PanelGrid>
        ))}
      </div>

      <Button isLoading={isPending(status)}>Add Price</Button>
    </Form>
  )
}
