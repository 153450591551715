import { useState, useCallback, useEffect } from "react"

type QueryValue<T extends string> = T | undefined

export const useQueryStringState = <T extends string>(key: string, initialValue: QueryValue<T>) => {
  const [value, setValue] = useState<QueryValue<T>>((getQueryStringValue(key) || initialValue) as QueryValue<T>)

  const onSetValue = useCallback(newValue => {
    setValue(newValue)
    setQueryStringValue(key, newValue)
  }, [key])

  const readValue = (getQueryStringValue(key) || initialValue) as QueryValue<T>

  useEffect(
    () => setValue(readValue)
  , [readValue])

  return [value, onSetValue] as [QueryValue<T>, (v: QueryValue<T>) => void]
}

const setQueryStringWithoutPageReload = (qs: string) => {
  const newurl =
    window.location.protocol + "//" +
    window.location.host +
    window.location.pathname +
    qs

  window.history.pushState({ path: newurl }, "", newurl);
}

const setQueryStringValue = <T extends string | undefined>(key: string, value: T, queryString = window.location.search) => {
   const values = new URLSearchParams(queryString)
   if(value) {
    values.set(key, value as string)
   } else {
     values.delete(key)
   }
   setQueryStringWithoutPageReload(`?${values.toString()}`)
}

export const getQueryStringValue = <T extends string>(key: string, queryString = window.location.search): QueryValue<T> => {
  const values = new URLSearchParams(window.location.search)
  return (values.get(key) || undefined) as QueryValue<T>
}
