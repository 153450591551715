import React, { useMemo } from "react"
import { Column } from "react-table"
import 'twin.macro'

import { Card, CardContent } from "../../../elements"
import { DataTableSelectableOptions, PlainDataTable } from "../../../elements/table/PlainDataTable"

type BaseCase = {
  id: string
}

type CaseListRendererProps<T extends BaseCase> = {
  data: T[],
  none?: JSX.Element | null
  options: CaseFieldRenderer<T>[]
  selectable?: DataTableSelectableOptions<T>
}

export type CaseFieldRenderer<T extends BaseCase> = {
  label: string
  renderCard?: (item: T) => JSX.Element | null
} & (ValueField<T> | StaticField)

type ValueField<T> = {
  [K in keyof T]: {
    accessor: K
    render?: (value: T[K]) => JSX.Element | null
  }
}[keyof T]

type StaticField = {
  id: string
  render?: () => JSX.Element | null
}

export const CaseListRenderer = <T extends BaseCase>({ data, options, selectable, none }: CaseListRendererProps<T>): JSX.Element => {
  const tableColumns = useMemo(() => options
    .map((o): Column<T> => ({
      ...(o as ValueField<T>).accessor ? { accessor: (o as ValueField<T>).accessor as any } : {},
      ...(o as StaticField).id ? { id: (o as StaticField).id! } : {},
      Header: o.label,
      Cell: ({ value }: { value: T[keyof T] }) => (o.render || String)(value)
    })),
    [options]
  )

  return data.length > 0
    ? (
      <>
        <div tw="hidden sm:block">
          <PlainDataTable columns={tableColumns} data={data} selectable={selectable} />
        </div>
        <div tw="block sm:hidden">
          <SolicitorCaseCards data={data} options={options} />
        </div>
      </>
    )
    : none || <NoCasesCard />
}

export const NoCasesCard = () => (
  <Card>
    <CardContent>
      There are no items to display.
    </CardContent>
  </Card>
)



const SolicitorCaseCards = <T extends BaseCase>({ data, options }: CaseListRendererProps<T>) => (
  <>
    {data.map((d, i) => (
      <div tw="mb-4 last:mb-0" key={`card-${i}`}>
        <Card>
          <CardContent>
            {options.map((o, j) => (
              <div  key={`field-${j}`} tw="py-2 grid grid-cols-3 gap-4">
                <dt tw="text-sm leading-5 font-medium text-gray-600">
                  {o.label}
                </dt>
                <dd tw="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 col-span-2">
                  {(o as ValueField<T>).accessor
                    ? o.renderCard ? o.renderCard(d) : (o.render || String)(d[(o as ValueField<T>).accessor])
                    : o.renderCard ? o.renderCard(d) : (o as StaticField).render ? (o as StaticField).render!() : null
                  }
                </dd>
              </div>
            ))}
          </CardContent>
        </Card>
      </div>
    ))}
  </>
)
