import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/solid"
import { Card, CardContent, CardHeader } from "packages/app/src/elements"
import { Badge } from "packages/app/src/elements/Badge"
import { Button } from "packages/app/src/elements/Button"
import { FormattedCurrencyUnits } from "packages/app/src/elements/Currency"
import { Tabs } from "packages/app/src/elements/Tabs"
import { matchStringUnion } from "packages/app/src/lib/utils"
import { FC } from "react"
import 'twin.macro'
import { QuoteLineItemFeeType } from "../hooks/useCreateSolicitorQuote"
import { FeeGuideLineItem, FeeGuideProduct, FeeGuideProductVariant } from "../hooks/useFeeGuide"

export const sumQuoteItems = (items: FeeGuideLineItem[]) => items.reduce((p, c) => p + c.price, 0)

type QuoteCardProps = {
  productName: string
  variantName?: string

  lineItems?: FeeGuideLineItem[]
  includes?: string[]
  excludes?: string[]

  notes?: string

  actions?: JSX.Element

  emptyStateButtonText?: string
  emptyStateDescription?: string
  emptyStateOnClick?: () => void

}

export const QuoteCard: FC<QuoteCardProps> = ({ actions, productName, variantName, lineItems, includes, excludes, notes, emptyStateButtonText, emptyStateDescription, emptyStateOnClick }) => (
  <Card>
    <CardHeader
      highlight
      title={productName}
      badge={variantName && <Badge variant='neutral'>{variantName}</Badge>}
      actions={actions}
    />
    <CardContent tw="space-y-3">
      <div tw="flex flex-row justify-between">
        <p tw="text-gray-500 text-sm font-medium">Fee Total</p>
        <p tw="text-gray-900 text-lg font-semibold">
          <FormattedCurrencyUnits
            amountUnits={sumQuoteItems(lineItems || [])}
          />
        </p>
      </div>
      <Tabs
        id="quote"
        tabs={[
          {
            key: 'line-items',
            title: 'Line Items',
            content: (lineItems || []).length > 0
              ? <LineItems items={lineItems || []} />
              : <EmptyState name="line items" buttonText={emptyStateButtonText} description={emptyStateDescription} onClick={emptyStateOnClick} />
            ,
            badge: `${lineItems?.length || 0}`
          },
          {
            key: 'includes',
            title: 'Includes',
            content:  (includes || []).length > 0
            ? <ScopeItems items={includes || []} type='include' />
            : <EmptyState name="includes" buttonText={emptyStateButtonText} description={emptyStateDescription} onClick={emptyStateOnClick} />,
            badge: `${includes?.length || 0}`
          },
          {
            key: 'excludes',
            title: 'Excludes',
            content: (excludes || []).length > 0
            ? <ScopeItems items={excludes || []} type='exclude' />
            : <EmptyState name="excludes" buttonText={emptyStateButtonText} description={emptyStateDescription} onClick={emptyStateOnClick} />,
            badge: `${excludes?.length || 0}`
          },
          {
            key: 'notes',
            title: 'Notes',
            content: notes
              ? <p>{notes}</p>
              : <EmptyState name={"notes"} buttonText={emptyStateButtonText} description={emptyStateDescription} onClick={emptyStateOnClick} />
          }
        ]}
      />
    </CardContent>
  </Card>
)

type EmptyStateProps = {
  name: string
  description?: string
  buttonText?: string
  onClick?: () => void
}


const EmptyState: FC<EmptyStateProps> = ({ name, description, buttonText, onClick }) => (
  <div
    tw="relative block flex flex-col gap-4 items-center w-full border-2 border-gray-300 border-dashed rounded-lg p-4 sm:p-6 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
  >
    <div tw="space-y-1">
      <h3 tw="text-sm font-medium text-gray-900">No {name}</h3>
      {description && <p tw="text-sm text-gray-500">{description}</p>}
    </div>
    {buttonText && <Button variant='secondary' type="button" onClick={onClick}>{buttonText}</Button>}
  </div>
)

const LineItems: FC<{ items: FeeGuideLineItem[] }> = ({ items }) => (
  <div tw="flex flex-col divide-y sm:(divide-y-0 gap-4)">
    {items.map((i, ix) => (
      <div key={ix} tw="flex flex-row py-2 gap-2 first:pt-0 last:pb-0 sm:(gap-8 py-0)">
        <div tw="flex flex-col flex-1 sm:flex-row">
          <div tw="flex-1">
            <span tw="text-sm text-gray-700 block" title={i.name}>{i.name}</span>
          </div>

          <div>
            <FeeTypeBadge key={ix} feeType={i.feeType} />
          </div>
        </div>
        <div>
          <FormattedCurrencyUnits
            tw="text-sm text-gray-900 font-medium"
            key={ix}
            amountUnits={i.price}
          />
        </div>
      </div>
    ))}
  </div>
)

const ScopeItems: FC<{ items: string[], type: 'include' | 'exclude' }> = ({ items, type }) => (
  <div tw="flex flex-col gap-4">
    {items.map((i, ix) => (
      <div key={ix} tw="flex flex-row gap-2 items-start">
        <div tw="h-4 w-4 flex-shrink-0 py-0.5">
          {matchStringUnion(type, {
            include: <PlusCircleIcon tw="text-green-500" />,
            exclude: <MinusCircleIcon tw="text-red-500" />
          })}
        </div>
        <span tw="text-sm text-gray-700 block" title={i}>{i}</span>
      </div>
    ))}
  </div>
)

export const FeeTypeBadge: FC<{ feeType: QuoteLineItemFeeType }> = ({ feeType }) => (
  <Badge variant={feeType === 'fee' ? 'red' : 'purple'}>
    {matchStringUnion(feeType, {
      disbursement: 'Disbursement',
      fee: 'Fee'
    })}
  </Badge>
)
