import { Badge } from "../../elements/Badge"
import { matchStringUnion } from "../../lib/utils"
import { PayoutStatus } from '../../hooks/useListPayouts'

export const PayoutStatusBadge = ({ status }: { status: PayoutStatus }) => (
  <Badge
    variant={matchStringUnion(status, {
      canceled: 'red',
      failed: 'red',
      in_transit: 'neutral',
      paid: 'positive',
      pending: 'yellow'
    })}
  >
    {matchStringUnion(status, {
      canceled: 'Cancelled',
      failed: 'Failed',
      in_transit: 'In Transit',
      paid: 'Paid',
      pending: 'Pending'
    })}
  </Badge>
)
