import { map } from "@nll/datum/DatumEither"
import { pipe } from "fp-ts/es6/function"
import { chain, getOrElse, isSome } from "fp-ts/es6/Option"
import { useQuoteStatus } from "packages/app/src/features/quotes"
import { CheckoutQuoteButton } from "packages/app/src/features/quotes/components/CheckoutQuoteButton"
import QuoteExpiredButton from "packages/app/src/features/quotes/components/QuoteExpiredButton"
import { QuoteTotalPriceMetric } from "packages/app/src/features/quotes/components/QuoteTotalPriceMetric"
import { ClientQuoteView } from "packages/app/src/features/quotes/routes/ClientQuoteView"
import "twin.macro"
import { useCase } from "../../../../../contexts/case/CaseContext"
import { useUser } from "../../../../../contexts/User"
import { NeutralPill } from "../../../../../elements/Badge"

import {
  Message,
  MessagesCard,
  SkeletonMessagesCard
} from "../../../../../elements/case/MessagesCard"
import {
  FilesIcon,
  MessageIcon,
  PaymentsIcon
} from "../../../../../elements/Icons"
import { PanelGrid } from "../../../../../elements/Layout"
import { ROUTES } from "../../../../../layout/Navigation"
import { renderLoadingOrSuccess } from "../../../../../lib/utils"
import {
  CaseStatusTitle,
  CaseStatusTitleHeader,
  ComponentWithCase
} from "../../CaseDetails"
import { CaseFilesCard } from "../../shared/CaseFilesCard"
import { CaseTabLayout } from "../../shared/CaseTabLayout"

export const ClientWaitingForPaymentActionPanel: ComponentWithCase = ({
  case: cas
}) => {
  const { firstQuote } = useQuoteStatus()

  if (!firstQuote) {
    return null
  }

  const hasExpired =  firstQuote.expiresAt < new Date()

  return hasExpired ? (
    <QuoteExpiredButton quoteId={firstQuote._id} />
  ) : (
    <CheckoutQuoteButton quoteId={firstQuote._id} caseId={cas.id} ammount={firstQuote.price.payableAmount}/>
  )
}

export const ClientWaitingForPaymentStatusPanel: ComponentWithCase = ({
  case: cas
}) => {
  return (
    <div tw="flex flex-row">
      {isSome(cas.solicitor) && (
        <div tw="mr-12">
          <CaseStatusTitleHeader>Your Solicitor:</CaseStatusTitleHeader>
          <CaseStatusTitle>{cas.solicitor.value.name}</CaseStatusTitle>
          <p tw="mt-2 text-base text-gray-800 font-medium">
            <NeutralPill>SRA Regulated</NeutralPill>
          </p>
        </div>
      )}

      <QuoteTotalPriceMetric />
    </div>
  )
}

export const ClientWaitingForPaymentDetailsPanel: ComponentWithCase = ({
  case: cas
}) => (
  <CaseTabLayout
    defaultRedirect={ROUTES.case.fees(cas.id)}
    config={[
      {
        label: "Fee Breakdown",
        icon: <PaymentsIcon />,
        to: ROUTES.case.fees(cas.id),
        path: ROUTES.case.fees(":caseId"),
        render: () => <FeesRoute case={cas} />
      },
      {
        label: "Messages",
        icon: <MessageIcon />,
        to: ROUTES.case.messages(cas.id),
        path: ROUTES.case.messages(":caseId"),
        render: () => <MessagesRoute />
      },
      {
        label: "Files",
        icon: <FilesIcon />,
        to: ROUTES.case.files(cas.id),
        path: ROUTES.case.files(":caseId"),
        render: () => <CaseFilesCard canUpload={true} />
      }
    ]}
  />
)

export const MessagesRoute = () => {
  const { userId } = useUser()
  const { addMessage, messages, caseId } = useCase()

  const displayableMessages = pipe(
    messages,
    map((ms) =>
      ms.map(
        (m): Message => ({
          authorName: getOrElse(() => "Lawhive user")(
            pipe(
              m.author,
              chain((a) => a.displayName)
            )
          ),
          content: m.content,
          fromMe: m.authorId === userId,
          timestamp: m.createdAt
        })
      )
    )
  )

  return renderLoadingOrSuccess(
    () => <SkeletonMessagesCard />,
    (messages: Message[]) => (
      <MessagesCard
        caseId={caseId}
        messages={messages}
        addMessage={addMessage}
      />
    )
  )(displayableMessages)
}

const FeesRoute: ComponentWithCase = () => (
  <PanelGrid>
    <ClientQuoteView />
  </PanelGrid>
)
