import React from "react"
import 'twin.macro'
import { Card, CardContent } from "../../../elements"
import { SkeletonBlock } from "../../../elements/Skeleton"
import { ContentLayoutFlow, DetailsSubshellSkeleton } from "packages/app/src/layout/subshells/DetailsSubshell"
import { SidebarLayout } from "packages/app/src/layout/SidebarLayout"

export const AssessmentCallsDetailsSkeleton = () => (
  <DetailsSubshellSkeleton>
    <SidebarLayout
      left={(
        <Card>
          <CardContent>
            <SkeletonBlock />
            <SkeletonBlock tw="mt-4" />
          </CardContent>
        </Card>
      )}
      right={(
        <ContentLayoutFlow>
          <SkeletonBlock />
          <SkeletonBlock />

        </ContentLayoutFlow>
      )}
    />
  </DetailsSubshellSkeleton>
)
