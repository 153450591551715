import { matchExhaustive } from "@practical-fp/union-types"
import { Badge } from "packages/app/src/elements/Badge"
import { ButtonLink } from "packages/app/src/elements/Button"
import { BoolString } from "packages/app/src/elements/YesNoToggle"
import { ROUTES } from "packages/app/src/layout/Navigation"
import { ContentLayoutFlow } from "packages/app/src/layout/subshells/DetailsSubshell"
import { PriceUnits } from "packages/app/src/lib/Primitives"
import { useRouter } from "packages/app/src/lib/useRouter"
import { matchStringUnion } from "packages/app/src/lib/utils"
import { FC } from "react"
import "twin.macro"
import { Card, CardHeader, Message, SuccessMessage } from "../../../../elements"
import { QuoteCard } from "../../complete/QuoteCard"
import { AssessmentCallUIDetailsResponse } from "../../hooks/useAssessmentCallDetails"
import { NotAssessedReason } from "../../hooks/useCompleteCall"
import { QuoteLineItemName } from "../../hooks/useCreateSolicitorQuote"
import { FeeGuideLineItem } from "../../hooks/useFeeGuide"
import {
  AssessmentCallDescriptionSection,
  AssessmentCallDetailsSubshell
} from "../elements"

type CallCompletedProps = {
  call: AssessmentCallUIDetailsResponse
}

export const CallCompleted: FC<CallCompletedProps> = ({ call }) => (
  <AssessmentCallDetailsSubshell
    call={call}
    content={<Content call={call} />}
  />
)

const Content: FC<CallCompletedProps> = ({ call }) => {
  const { params } = useRouter<{
    quoted?: BoolString
    dismissed?: BoolString
  }>()

  const showQuoted = params.quoted === "true"
  const showDismissed = params.dismissed === "true"
  const showGenericMessage = !(showQuoted || showDismissed)

  return (
    <ContentLayoutFlow>
      {showDismissed && (
        <SuccessMessage title="Saved Results of Call">
          Lawhive has been informed that you weren't able to quote the client.
        </SuccessMessage>
      )}
      {showQuoted && (
        <SuccessMessage title="Successfully Quoted Client">
          Lawhive has been informed of this quote and the client will be asked
          to pay for this work.
        </SuccessMessage>
      )}

      {showGenericMessage && (
        <Message colour="primary" title="This call has been completed">
          There are no further actions you need to take.
        </Message>
      )}

      {call.result &&
        matchExhaustive(call.result, {
          SolicitorQuoted: (quote) => (
            <QuoteCard
              productName={"Quote"} //quote.quote.metadata?.product?.name || "Legal Service"}
              lineItems={quote.quote.lineItems.map(l => ({
                feeType: l.type,
                name: l.name as QuoteLineItemName,
                price: l.amount as PriceUnits
              }))}
              variantName={undefined}//quote.quote.metadata?.variant?.name || ''}
              includes={quote.quote.scopes.filter((s) => s.type === 'inclusion').map(s => s.title)}
              excludes={quote.quote.scopes.filter((s) => s.type==='exclusion').map(s => s.title)}
              notes={quote.quote?.notesFromSolicitor}
              actions={matchStringUnion(quote.status, {
                approved: <Badge variant="positive">Sent to Client</Badge>,
                converted: quote.caseId ? (
                  <ButtonLink to={ROUTES.case.details(quote.caseId)}>
                    View Case
                  </ButtonLink>
                ) : (
                  <Badge variant="positive">Sent to Client</Badge>
                ),
                proposed: <Badge variant="purple">Pending Review</Badge>,
                rejected: <Badge variant="negative">Rejected</Badge>,
                unpaid: undefined
              })}
            />
          ),
          NoAnswer: () => <CallResultCard>Client Didn't Answer</CallResultCard>,
          NotAssessed: (r) => (
            <CallResultCard>
              {matchExhaustive(r, {
                NotSuitable: () => "Outside of practice area",
                Other: () => "Other",
                TooBusy: () => "Too busy"
              })}
            </CallResultCard>
          ),
          NotLegalMatter: () => (
            <CallResultCard>Not Legal Matter</CallResultCard>
          )
        })}

      <AssessmentCallDescriptionSection call={call} />
    </ContentLayoutFlow>
  )
}

const CallResultCard: FC = ({ children }) => (
  <Card>
    <CardHeader
      highlight
      title="Your Response"
      actions={<Badge variant="positive">{children}</Badge>}
    />
  </Card>
)
