import { pipe } from 'fp-ts/lib/function'
import { none, Option, some, getOrElse, map } from 'fp-ts/lib/Option'
import { differenceInHours } from 'date-fns'

import { AssignedCase, Solicitor } from "../case/Case"

export type AcceptQuoteReminder = {
  reminderNumber: number
  solicitor: Solicitor
  caseNumber: string
  caseId: string
  assignedAt: Date
  remindedAt: Date
}

export type AcceptQuoteReminderHistory = {
  reminderCount: number
  lastReminder: AcceptQuoteReminder
}

export type AcceptQuoteReminderFactory = (cas: AssignedCase) => Option<AcceptQuoteReminder>

export type CaseNeedsAcceptReminder = (cas: AssignedCase, history: Option<AcceptQuoteReminderHistory>) => Option<AcceptQuoteReminder>

export const caseNeedsAcceptReminderRule: CaseNeedsAcceptReminder =
  (cas, history) => {
    const now = new Date()

    const lastChangedTime = pipe(
      history,
      map(r => r.lastReminder.remindedAt),
      getOrElse(() => cas.assigneedToSolicitorAt)
    )

    const reminderCount = pipe(
      history,
      map(r => r.reminderCount),
      getOrElse(() => 0)
    )

    const isOlderThan6Hours = (now: Date, compare: Date) => differenceInHours(now, compare) > 6

    if(isOlderThan6Hours(now, lastChangedTime)) {
      // Only remind a solicitor twice about an unaccepted case
      if(reminderCount >= 2) {
        console.log(`Case [${cas.id}] has already been reminded twice`)
        return none
      }

      return some({
        reminderNumber: reminderCount + 1,
        assignedAt: cas.assigneedToSolicitorAt,
        caseId: cas.id,
        caseNumber: cas.caseNumber,
        remindedAt: now,
        solicitor: cas.solicitor
      })
    } else {
      console.log(`Case [${cas.id}] was reminded about less than than 3 hours ago`)
      return none
    }
  }
