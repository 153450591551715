import { DetailsSubshell } from "packages/app/src/layout/subshells/DetailsSubshell"
import React, { FC } from "react"
import { matchStringUnion } from "../../../lib/utils"
import { AssessmentCallUIDetailsResponse } from "../hooks/useAssessmentCallDetails"
import { CallBooked } from "./states/Booked"
import { CallCompleted } from "./states/Completed"
import { AssessmentCallNeedsBooking } from "./states/NeedsBooking"

type AssessmentCallDetailsPanelProps = {
  call: AssessmentCallUIDetailsResponse
  onRefresh: () => void
  onReject: () => void
}

export const AssessmentCallDetailsPanel: FC<AssessmentCallDetailsPanelProps> = ({ call, onRefresh, onReject }) =>
  matchStringUnion(call.status, {
    "pending-client-selection": () => null,
    "closed": () => null,
    "pending-solicitor-confirmation": () => <AssessmentCallNeedsBooking call={call} onRefresh={onRefresh} onReject={onReject} />,
    booked: () => <CallBooked call={call} />,
    completed: () => <CallCompleted call={call} />,
  })
