import { isPending, isRepleteLeft, refreshFold } from "@nll/datum/DatumEither"
import { pipe } from "fp-ts/es6/function"
import { QuoteBuilderSubshell } from "packages/app/src/components/assessment-call/complete/QuoteBuilderSubshell"
import { QuoteCard } from "packages/app/src/components/assessment-call/complete/QuoteCard"
import { DraftQuote, useDraftQuote } from "packages/app/src/components/assessment-call/hooks/useDraftQuote"
import { useUser } from "packages/app/src/contexts/User"
import { ErrorMessage, ErrorMessageOld, FormButtonGroup, WarningMessage } from "packages/app/src/elements"
import { Button, ButtonLink } from "packages/app/src/elements/Button"
import { ROUTES, withOptions } from "packages/app/src/layout/Navigation"
import { UUID } from "packages/app/src/lib/Primitives"
import { ApiError, useFromTaskEither } from "packages/app/src/lib/useAsync"
import { useRouter } from "packages/app/src/lib/useRouter"
import React, { FC, useEffect } from "react"
import { useIntl } from "react-intl"
import "twin.macro"
import { CaseDetails } from "../../../../../contexts/case/useGetCase"
import { isSome } from "fp-ts/Option"
import * as E from "fp-ts/Either"
import { useCase } from "../../../../../contexts/case/CaseContext"
import {
  SolicitorQuote,
  SolicitorQuoteLineItem,
  SolicitorQuoteScope,
  useCreateSolicitorQuoteNew
} from "../../../../../components/assessment-call/hooks/useCreateSolicitorQuoteNew"
import { ContentLayoutFlow } from "../../../../../layout/subshells/DetailsSubshell"
import { SkeletonCaseDetails } from "../../CaseDetails"
import { useCreateFollowOnQuote } from "packages/app/src/components/assessment-call/hooks/useCreateFollowOnQuote"

export const FollowOnSendQuoteScene: FC = () => {
  const { caseDetails } = useCase()

  const render = refreshFold(
    () => <SkeletonCaseDetails />,
    () => <SkeletonCaseDetails />,
    (e: ApiError) =>
      ApiError.match(e, {
        NotFound: () => <ErrorMessageOld title="Error getting case details" />,
        default: () => <ErrorMessageOld title="Error getting case details" />
      }),
    (cas: CaseDetails) => (
      <QuoteBuilderSubshell
        backToText="Back to case"
        backToUrl={ROUTES.case.details(cas.id)}
        clientName={(isSome(cas.client) && isSome(cas.client.value.displayName)) ? cas.client.value.displayName.value : "Client"}
        summaryHeader="Quote Summary"
        matterSummary={`Follow on quote for case ${cas.friendlyId}`}
        step={'send'}
        content={(
          <Content cas={cas} />
        )}
      />
    )
  )


  return (render(caseDetails))
}

const convertDraftToSolicitorQuoteNew = (draft: DraftQuote): SolicitorQuote => ({
  notesFromSolicitor: draft.notes,
  lineItems: draft.lineItems.map((a): SolicitorQuoteLineItem => ({name: a.name, type: a.feeType, amount: a.price})),
  scopes: [
    ...draft.includes.map((a): SolicitorQuoteScope => ({type: 'inclusion', title: a.title})),
    ...draft.excludes.map((a): SolicitorQuoteScope => ({type: 'exclusion', title: a.title}))
  ],
  groups: []
})

const Content: FC<{ cas: CaseDetails }> = ({ cas }) => {
  const intl = useIntl()

  const { draft } = useDraftQuote(cas.id)
  const { userId } = useUser()
  const { push, replace } = useRouter()

  const createFollowOnQuote = useCreateFollowOnQuote()

  const clientId = (cas && isSome(cas.client)) ? cas.client.value.id : ""

  const { status, execute } = useFromTaskEither(
    (draft: DraftQuote) => pipe(
      createFollowOnQuote({
        caseId: cas.id,
        description: `Follow on quote for case ${cas.friendlyId}`,
        ...convertDraftToSolicitorQuoteNew(draft)
      }),
    )
  )

  const submit = async () => {
    await execute(draft)
      .then(result => {
        if(E.isRight(result)) {
          replace(withOptions(ROUTES.case.fees(result.right.caseQuote.caseId), { followOnSubmitted: 'true' }))
        }
      })
  }

  return (
    <>
      <ContentLayoutFlow>
        <QuoteCard
          productName={draft.feeGuide?.product?.name || 'Custom'}
          variantName={draft.feeGuide?.variant?.name}
          lineItems={draft.lineItems}
          includes={draft.includes.map(i => i.title)}
          excludes={draft.excludes.map(i => i.title)}
          notes={draft.notes}
          emptyStateButtonText={`Edit Quote`}
          emptyStateDescription={`Add some by editing this quote.`}
          emptyStateOnClick={() => push(ROUTES.case.followOn(cas.id).customise)}
          actions={
            <ButtonLink
              to={ROUTES.case.followOn(cas.id).customise}
              variant="colour-secondary"
              type="button"
            >
              Edit Quote
            </ButtonLink>
          }
        />

        <WarningMessage
          title={`You're about to agree to this instruction`}
        >
          <div tw="space-y-2">
            <p>
              By submitting this quote, you agree that you wish to engage this client and take on the work to the specifications of the quote above.
            </p>
            <p>
              When submitted, the client will be asked to pay and you will be automatically engaged.
            </p>
          </div>
        </WarningMessage>

        <FormButtonGroup>
          <Button type="button" onClick={submit} isLoading={isPending(status)}>
            Send quote to client
          </Button>
        </FormButtonGroup>

        {isRepleteLeft(status) && (
          <ErrorMessage title="Failed to Send Quote to Client">

          </ErrorMessage>
        )}

      </ContentLayoutFlow>
    </>
  )
}
