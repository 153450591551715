import React, { FC } from 'react'
import 'twin.macro'

import { Spinner } from '../Loading'
import { Table, TableHeader, TableBody, TableCell } from './Table'

type SkeletonTableProps = {
  columns: SkeletonTableColumn[]
}

export type SkeletonTableColumn = {
  label: string,
  skeleton: JSX.Element
}

export const SkeletonTable: FC<SkeletonTableProps> = ({ columns }) => (
    <div tw="relative">
      <Loader />
      <Table>
        <thead>
          <tr>
            {columns.map((c, i) => (
              <TableHeader key={`header-${i}`}>
                {c.label}
              </TableHeader>
            ))}
          </tr>
        </thead>
        <TableBody>
          {new Array(3).fill(0).map((_, i) => (
            <tr key={`row-${i}`}>
              {columns.map((c, j) => (
                <TableCell key={`cell-${i}-${j}`}>
                  {c.skeleton}
                </TableCell>
              ))}
            </tr>
          ))}
        </TableBody>
      </Table>
    </div>
)

const Loader = () => (
  <div tw="absolute flex top-0 right-0 left-0 bottom-0 items-center justify-center animate-pulse">
    <Spinner />
  </div>
)
