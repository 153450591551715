import React, { FC } from "react"
import 'twin.macro'

import { ModalText } from "../../../../../elements/Modal"
import { Card, CardContent, CardTitle, ExpandingTextArea, FormField, FormSection, InfoNote } from "../../../../../elements"
import { Button, ButtonGroup } from "../../../../../elements/Button"


type RequestCaseCompletionModalProps = {
  isLoading: boolean
  onCancel: () => void,
  onComplete: () => void,
}

export const RequestCaseCompletionModal: FC<RequestCaseCompletionModalProps> = ({ onCancel, onComplete, isLoading }) => {
  return (
    <div tw="max-w-xl">
      <Card>
        <CardContent>
          <CardTitle>
            Are you sure?
          </CardTitle>

          <ModalText tw="mt-4">
            This will inform a member of the Lawhive team that you have completed the work required for this matter.
          </ModalText>
          <ModalText tw="mt-4">
            The case is not guaranteed to be closed, for example if there is still work outstanding or if the client
            disputes the completion of the work.
          </ModalText>
        </CardContent>

        <CardContent>
          <ButtonGroup>
            <Button variant='positive' onClick={onComplete} isLoading={isLoading}>
              Request Completion
            </Button>

            <Button variant='negative' display='border' onClick={onCancel}>
              Cancel
            </Button>
          </ButtonGroup>
        </CardContent>
      </Card>
    </div>
  )
}
