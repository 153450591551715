import { isFailure, isPending } from '@nll/datum/DatumEither'
import { default as React, FC, useState } from 'react'
import 'twin.macro'

import { Card, CardContent, ErrorMessageOld, FormButton } from '../../elements'
import { Spinner } from '../../elements/Loading'
import { formSubmit } from '../../lib/utils'
import { KycFiles } from './KycFiles'
import { useUpdateOrCreateKycSubmission } from './KycStatusScene'
import { KycStep } from './KycStep'

export const DocumentUploadStep = () => {
  const [files, setFiles] = useState<string[]>([])

  const { status, execute: submit } = useUpdateOrCreateKycSubmission()

  const submitKYC = () => submit({
    documentUploads: files
  })

  const buttonDisabled = files.length < 1 || isPending(status)

  return (
    <KycStep step={1}>
      <Card>
        <CardContent tw="pb-0 text-sm sm:text-base">
          {/* <h2 tw="text-base sm:text-lg font-semibold mb-2">Identity Document</h2> */}
          <p tw="text-gray-900">
            Please provide a scanned copy or picture of an identity document.
          </p>

          <div tw="flex flex-col bg-blue-100 text-blue-600 rounded-md my-4 px-4 py-3">
            <div tw="text-xs sm:text-sm font-medium">
              Example:
            </div>

            <div tw="flex flex-col items-center">
              <Illustration tw="bg-white rounded-xl mt-2 h-20 sm:h-24" />
            </div>


            <div tw="mt-2">
              <ul tw="flex flex-col gap-1 text-xs sm:text-sm">
                <li tw="flex flex-row items-center">
                  <Check tw="mr-1" />
                  Shows your document clearly
                </li>
                <li tw="flex flex-row items-center">
                  <Check tw="mr-1" />
                  Entire document is in frame
                </li>
                <li tw="flex flex-row items-center">
                  <Check tw="mr-1" />
                  Not blurry or out of focus
                </li>
              </ul>
            </div>
          </div>

          <p tw="text-gray-700">
            We accept any <b>one</b> of the following documents as proof of identity:
          </p>
          <ul tw="mt-2 sm:mt-4 list-disc list-inside text-gray-700 leading-5 sm:leading-7">
            <li>Passport</li>
            <li>Driving License</li>
            <li>National Identity Card</li>
          </ul>
        </CardContent>

        <form>
          <CardContent>
            <KycFiles documentType={'document'} onChange={setFiles} />
          </CardContent>

          <CardContent tw="bg-gray-50">
            <FormButton disabled={buttonDisabled} onClick={formSubmit(submitKYC)}>
              {isPending(status) ? <Spinner /> : <>Continue</>}
            </FormButton>
          </CardContent>

          {isFailure(status) && (
            <ErrorMessageOld title="Something went wrong" />
          )}
        </form>

      </Card>
    </KycStep>
  )
}

const Illustration: FC = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97 73" {...props}>
    <g transform="translate(-400 -373)">
      <g transform="translate(400 373)" fill="none" stroke="#f87171" strokeWidth="2" strokeDasharray="13 6">
        <rect width="97" height="73" rx="13" stroke="none" /><rect x="1" y="1" width="95" height="71" rx="12" fill="none" />
      </g>
      <g transform="translate(0 1)">
        <g transform="translate(410 382)" fill="#fff" stroke="#000" strokeWidth="2">
          <rect width="78" height="53" rx="8" stroke="none" />
          <rect x="1" y="1" width="76" height="51" rx="7" fill="none" />
        </g>
        <g transform="translate(-4)"><g transform="translate(81 -19)">
          <g transform="translate(347 432.296)">
            <path d="M17.355,40.959a5.12,5.12,0,0,0-5.1-4.277H11.231V36H7.818v.683H6.794a5.12,5.12,0,0,0-5.1,4.277l-.7,4.2a.346.346,0,1,0,.683.109l.683-4.2a4.417,4.417,0,0,1,4.151-3.707c.522,3.15.276,2.973,1.679,2.171a1.666,1.666,0,0,0,.509.925c-.321.341-.232.236-.788,4.7a.344.344,0,0,0,.683.082l.512-4.1a.433.433,0,0,1,.86,0l.512,4.1a.344.344,0,0,0,.683-.082c-.556-4.461-.471-4.352-.788-4.7a1.683,1.683,0,0,0,.509-.925c.8.457.826.492.949.492a.341.341,0,0,0,.287-.157,15.64,15.64,0,0,0,.444-2.505,4.417,4.417,0,0,1,4.137,3.707c.683,4.144.625,4.485,1.024,4.485a.341.341,0,0,0,.256-.123C18.1,45.288,17.4,41.236,17.355,40.959Zm-5.782-1.8L10.1,38.314l.932-.949h.823Zm-1.024-2.935v.662L9.525,37.908,8.5,36.884v-.662A4.553,4.553,0,0,0,10.549,36.222ZM8.019,37.365l.949.949-1.475.843-.3-1.792ZM10.1,39.683a.6.6,0,0,1-1.147,0l-.16-.481.734-.42.734.42Z" transform="translate(-0.987 -36)" /></g><path d="M22.508,12.258a4.437,4.437,0,0,0,2.673-3.413,1.37,1.37,0,0,0,.208-2.731,14.824,14.824,0,0,0,.191-3.038A2.475,2.475,0,0,0,23.7.687c-.966-.9-1.942-.717-3.621-.717a4.058,4.058,0,0,0-4.055,4.055,11.752,11.752,0,0,0,.191,2.089,1.37,1.37,0,0,0,.208,2.731,4.417,4.417,0,0,0,2.673,3.413Zm3.413-4.779a.683.683,0,0,1-.683.683V6.8A.683.683,0,0,1,25.921,7.479ZM20.078.653c1.625,0,2.464-.164,3.212.584a.341.341,0,0,0,.174.1A1.785,1.785,0,0,1,24.9,3.076a18.773,18.773,0,0,1-.051,2.014,4.854,4.854,0,0,0-1.072-1.618.341.341,0,0,0-.43-.041c-1.219.823-2.068.635-5.615.635-.321,0-.324.229-.9,1.584a11.264,11.264,0,0,1-.119-1.625A3.376,3.376,0,0,1,20.078.653Zm-4.4,6.827a.683.683,0,0,1,.683-.683V8.162A.683.683,0,0,1,15.682,7.479Zm1.365.683v-1.3l.908-2.116c3.318,0,4.209.167,5.519-.587a4.659,4.659,0,0,1,1.082,2.662V8.162a3.755,3.755,0,1,1-7.509,0Z" transform="translate(334.736 420.046)" />
          <path d="M30.908,29.215a.341.341,0,0,0-.485,0,.683.683,0,0,1-.963,0,.343.343,0,0,0-.485.485C29.894,30.615,31.417,29.727,30.908,29.215Z" transform="translate(325.595 400.498)" />
          <path d="M25,19.341v.341a.341.341,0,1,0,.683,0v-.341a.341.341,0,1,0-.683,0Z" transform="translate(328.148 407.159)" />
          <path d="M37.683,19.683v-.341a.341.341,0,0,0-.683,0v.341a.341.341,0,0,0,.683,0Z" transform="translate(320.244 407.159)" />
        </g>
          <g transform="translate(423 397.249)" fill="none">
            <path d="M0,0H26.751V26.751H0Z" stroke="none" />
            <path d="M 1.500001907348633 1.500001907348633 L 1.500001907348633 25.25088310241699 L 25.25088310241699 25.25088310241699 L 25.25088310241699 16.71930313110352 L 25.25088310241699 1.500001907348633 L 1.500001907348633 1.500001907348633 M 1.9073486328125e-06 1.9073486328125e-06 L 26.75088310241699 1.9073486328125e-06 L 26.75088310241699 16.71930313110352 L 26.75088310241699 26.75088310241699 L 1.9073486328125e-06 26.75088310241699 L 1.9073486328125e-06 1.9073486328125e-06 Z" stroke="none" />
          </g></g><line x2="75" transform="translate(411.5 389.5)" fill="none" stroke="#000" strokeWidth="1.5" />
        <line x2="25.499" transform="translate(452.603 398.5)" fill="none" stroke="#000" strokeWidth="2" />
        <line x2="25.499" transform="translate(452.603 406.5)" fill="none" stroke="#000" strokeWidth="2" />
        <line x2="20" transform="translate(452.603 414.5)" fill="none" stroke="#000" strokeWidth="2" />
        <line x2="20" transform="translate(452.603 422.5)" fill="none" stroke="#000" strokeWidth="2" />
      </g>
    </g>
  </svg>
)

const Check: FC = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" tw="h-4 w-4" viewBox="0 0 20 20" fill="currentColor" {...props}>
    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
  </svg>
)
