import { useEffect, useReducer, useState } from 'react'
import { DatumEither } from '@nll/datum/DatumEither'
import { useAsync } from '../lib/useAsync'
import { impl, matchExhaustive, Variant } from '@practical-fp/union-types'

export type NextToken = string | null | undefined

export type PaginationController<T> = {
  pageIndex: number
  pageCount: number
  pageSize: number
  hasPagination: boolean
  data: DatumEither<Error, T>
  nextPage: () => void
  previousPage: () => void
  gotoPage: (index: number) => void
}

type PaginationControllerState = {
  pageIndex: number
  pageCount: number
  nextTokens: NextToken[]
  hasPagination: boolean
}

const paginationInitialState: PaginationControllerState = {
  pageIndex: 0,
  pageCount: 1,
  nextTokens: [],
  hasPagination: false,
}

type Action =
  | Variant<'SetPageIndex', number>
  | Variant<'SetTotalCount', number>
  | Variant<'SetNextToken', NextToken>
  | Variant<'ResetPaginationData'>

const Actions = impl<Action>()

export const paginationReducer = (state: PaginationControllerState, action: Action): PaginationControllerState => {
  return matchExhaustive(action, {
    SetPageIndex: (i: number) => {
      if (i < 0 || i >= state.pageCount) {
        return { ...state }
      }
      return {
        ...state,
        pageIndex: i,
      }
    },
    SetTotalCount: (count: number) => {
      return {
        ...state,
        pageCount: count,
      }
    },
    SetNextToken: (token: NextToken) => {
      return {
        ...state,
        nextTokens: [...state.nextTokens, token],
        hasPagination: [...state.nextTokens, token].some((token) => token !== null),
      }
    },
    ResetPaginationData: () => {
      return {
        ...paginationInitialState,
      }
    },
  })
}

export const useAppSyncPagination = <T, A>(params: {
  query: (nextToken: NextToken | null, limit: number) => Promise<T>
  nextTokenExtractor: (result: T) => NextToken | null
  opts?: { nextToken?: NextToken | null; limit?: number; filterQueryKey?: A }
}): PaginationController<T> => {
  const pageSize = params.opts?.limit || 1000

  const [state, dispatch] = useReducer(paginationReducer, paginationInitialState)
  const [filterQueryKey, setFilterQueryKey] = useState<A | undefined>(params.opts?.filterQueryKey)

  const { status, execute } = useAsync((newNextToken?: NextToken) => {
    return params.query(newNextToken || params.opts?.nextToken, pageSize).then((a) => {
      const nextToken = params.nextTokenExtractor(a)

      if (!state.nextTokens.includes(nextToken)) {
        dispatch(Actions.SetNextToken(nextToken))
        if (nextToken) dispatch(Actions.SetTotalCount(state.pageCount + 1))
      }
      return a
    })
  })

  useEffect(() => {
    execute(state.pageIndex === 0 ? null : state.nextTokens[state.pageIndex - 1])
  }, [state.pageIndex])

  useEffect(() => {
    if (filterQueryKey !== params.opts?.filterQueryKey) {
      setFilterQueryKey(params.opts?.filterQueryKey)
      dispatch(Actions.ResetPaginationData())
      execute()
    }
  }, [params.opts?.filterQueryKey])

  const nextPage = () => dispatch(Actions.SetPageIndex(state.pageIndex + 1))
  const previousPage = () => dispatch(Actions.SetPageIndex(state.pageIndex - 1))
  const gotoPage = (index: number) => dispatch(Actions.SetPageIndex(index))
  const data = status

  return {
    nextPage,
    previousPage,
    gotoPage,
    pageIndex: state.pageIndex,
    pageCount: state.pageCount,
    pageSize: pageSize,
    hasPagination: state.hasPagination,
    data,
  }
}

// export const useClientPagination = <T>({
//   pageSize,
//   data,
// }: {
//   pageSize: number
//   data: any
// }): PaginationController<T> => {
//   const [state, dispatch] = useReducer(paginationReducer, paginationInitialState)

//   const nextPage = () => dispatch({ type: 'SET_PAGE_INDEX', payload: state.pageIndex + 1 })
//   const previousPage = () => dispatch({ type: 'SET_PAGE_INDEX', payload: state.pageIndex - 1 })
//   const gotoPage = (index: number) => dispatch({ type: 'SET_PAGE_INDEX', payload: index })

//   return {
//     nextPage,
//     previousPage,
//     gotoPage,
//     pageIndex: state.pageIndex,
//     pageCount: Math.ceil(data.length / pageSize),
//     pageSize: pageSize,
//     hasPagination: true,
//   }
// }
