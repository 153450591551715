import { Card, CardContent, CardHeader } from "packages/app/src/elements"
import { PageHeader, PageHeaderBackLink } from "packages/app/src/elements/PageHeader"
import { Stepper } from "packages/app/src/elements/Stepper"
import { SidebarLayout } from "packages/app/src/layout/SidebarLayout"
import { matchStringUnion } from "packages/app/src/lib/utils"
import React, { FC, ReactNode } from "react"
import "twin.macro"
import { ContentLayoutFlow, DetailsSubshell } from "../../../layout/subshells/DetailsSubshell"

type QuoteBuilderSubshellProps = {
  clientName: string
  backToUrl: string
  backToText: string
  summaryHeader: string
  matterSummary: string
  content: ReactNode
  step: QuoteBuilderStep
}

export const QuoteBuilderSubshell: FC<QuoteBuilderSubshellProps> = ({ step, clientName, backToText, backToUrl, summaryHeader, matterSummary, content }) => (
  <DetailsSubshell
    header={(
      <PageHeader
        title={`Quote for ${clientName}`}
        supportingText={`Provide a quote for the work involved in this client's matter.`}
        back={(
          <PageHeaderBackLink to={backToUrl}>
            {backToText}
          </PageHeaderBackLink>
        )}
      />
    )}
    content={(
      <SidebarLayout
        left={content}
        right={<QuoteBuilderSidebar summaryHeader={summaryHeader} summary={matterSummary} step={step} />}
      />
    )}
  />
)
export type QuoteBuilderStep  = 'build' | 'customise' | 'send'

const QuoteBuilderStepper: FC<{ step: QuoteBuilderStep }> = ({ step }) => (
  <Stepper
    steps={[
      {
        name: 'Build Quote',
        description: `Select the client's legal matter`,
        status: matchStringUnion(step, { build: 'current', customise: 'complete', send: 'complete' })
      },
      {
        name: 'Customise Quote',
        description: `Specify the line items, scope of work and pricing.`,
        status: matchStringUnion(step, { build: 'upcoming', customise: 'current', send: 'complete' })
      },
      {
        name: 'Send Quote',
        description: `Confirm details before sending to the client.`,
        status: matchStringUnion(step, { build: 'upcoming', customise: 'upcoming', send: 'current' })
      }
    ]}
  />
)

const QuoteBuilderSidebar: FC<{summaryHeader: string, summary: string, step: QuoteBuilderStep }> = ({summaryHeader, summary, step }) => (
  <ContentLayoutFlow>
    <QuoteBuilderStepper step={step} />
    <Card>
      <CardHeader
        title={summaryHeader}
      />
      <CardContent tw="text-sm">
        {summary}
      </CardContent>
    </Card>
  </ContentLayoutFlow>
)
