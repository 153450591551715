import { default as React, FC, useEffect, useRef } from "react"
import tw from 'twin.macro'

type ModalProps = {
  onDismiss: () => void
}

export const ModalText = tw.p`text-sm sm:text-base leading-6 sm:leading-7`

export const Modal: FC<ModalProps> = ({ onDismiss, children }) => {
  const modalContainer = useRef<HTMLDivElement>(null)

  const handleClickOutside = (e: MouseEvent) => {
    const el = e.target
    if (el && el instanceof Node && modalContainer.current?.contains(el)) {
      return
    }
    onDismiss()
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  return (
    <div
      tw="fixed top-0 left-0 h-screen w-full flex items-center justify-center z-50"
      style={{
        background: "rgba(0,0,0,0.4)",
        backdropFilter: 'blur(2px)'
      }}
    >
      <div ref={modalContainer} tw="flex flex-col">
        <button type="button" tw="self-end w-8 h-8 flex items-center justify-center mb-4" onClick={onDismiss}>
          <svg xmlns="http://www.w3.org/2000/svg" tw="h-8 w-8 text-gray-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        {children}
      </div>
    </div>
  )
}
