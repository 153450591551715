import { createCaseCommand, CreateCaseCommandMutation, CreateCaseCommandMutationVariables, createClientCommand, CreateClientCommandMutation, CreateClientCommandMutationVariables, createUser, CreateUserMutation, CreateUserMutationVariables, UserType } from "@lawhive/generated-api"
import { DatumEither, isPending, isSuccess } from "@nll/datum/DatumEither"
import React, { FC } from "react"
import { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { Link } from "react-router-dom"
import 'twin.macro'

import { Card, CardContent, CardTitle, CheckItem, Form, FormField, InfoMessageOld, Select, SuccessMessageOld, TextInput } from "../../../../elements"
import { ClientSelector, ClientSelectorItem, useClientList } from "../../../../elements/admin/ClientSelector"
import { Button } from "../../../../elements/Button"
import { ContentContainer, PageContainer } from "../../../../layout/Layout"
import { ROUTES } from "../../../../layout/Navigation"
import { useAsync } from "../../../../lib/useAsync"
import { graphql } from "../../../../lib/utils"

export const AdminCreateCaseScene = () => {
  const { status, execute } = useClientList()

  useEffect(() => { execute() }, [])

  return (
    <PageContainer>
      <ContentContainer>
        <CreateUserCard refreshClients={execute} />
        <CreateCaseCard clients={status} />
      </ContentContainer>
    </PageContainer>
  )
}

type CreateCaseFormResult = {
  client: ClientSelectorItem
  alreadyPaid: boolean
}

const CreateCaseCard: FC<{ clients: DatumEither<Error, ClientSelectorItem[]> }> = ({ clients }) => {
  const { register, control, handleSubmit, watch } = useForm<CreateCaseFormResult>()

  const { status, execute } = useAsync(
    (input: CreateCaseFormResult) => graphql<CreateCaseCommandMutation, CreateCaseCommandMutationVariables>({
      query: /* GraphQL */`
       mutation CreateCaseCommand($input: CreateCaseCommand!) {
        createCaseCommand(input: $input) {
          id
        }
      }`,
      variables: {
        input: {
          clientId: input.client.id,
          brand: 'lawhive',
          payFirst: false,
          alreadyPaid: input.alreadyPaid
        }
      }
    })
  )

  const { client } = watch()

  return (
    <Card>
      <CardContent>
        <CardTitle>Create Case</CardTitle>
      </CardContent>

      <Form onSubmit={handleSubmit(execute)}>

        {/* <FormField
          id={'brand'}
          label={'Brand'}
          control={
            <Select name={'brand'} ref={register}>
              <option value='lawhive'>Lawhive</option>
              <option value='llt'>Landlord Tennant</option>
              <option value='lawletter'>LawLetter</option>
              <option value='cfr'>Cancelled Flight Refund</option>
            </Select>
          }
        /> */}

        {/* <FormField
          id={'payFirst'}
          label={'Client pays first'}
          control={
            <CheckItem name={'payFirst'} ref={register}>
              Does the client pay before a solicitor is assigned?
            </CheckItem>
          }
        /> */}

        <FormField
          id={'alreadyPaid'}
          label={'Has client already paid? (Changes email copy)'}
          control={
            <CheckItem name={'alreadyPaid'} ref={register}>
              Client has already paid
            </CheckItem>
          }
        />

        <FormField
          id={'client'}
          label={'Client'}
          control={
            <Controller
              name='client'
              control={control}
              rules={{ required: true }}
              render={props =>
                <ClientSelector items={clients} selectedId={props.value?.id} onSelected={props.onChange} />
              }
            />
          }
        />

        {client &&
          <InfoMessageOld tw="my-4" title={`Will assign case to ${client.emailAddress}`} />
        }

        <div tw="my-4">
          This will send the client an email explaining they can login and add details to the case.
        </div>

        <Button isLoading={isPending(status)} disabled={client === null}>
          Create Case
        </Button>

        {isSuccess(status) && (
          <SuccessMessageOld title='Case Created'>
            <Link to={ROUTES.case.details(status.value.right.data?.createCaseCommand?.id!)}>
              View Case
            </Link>
          </SuccessMessageOld>
        )}
      </Form>
    </Card>
  )
}

type CreateUserFormResult = {
  emailAddress: string
  name: string
  phoneNumber: string
}

const CreateUserCard: FC<{ refreshClients: () => void }> = ({ refreshClients }) => {
  const { register, handleSubmit } = useForm<CreateUserFormResult>()

  const { status, execute } = useAsync(
    (input: CreateUserFormResult) => graphql<CreateClientCommandMutation, CreateClientCommandMutationVariables>({
      query: createClientCommand,
      variables: {
        input: {
          emailAddress: input.emailAddress,
          name: input.name,
          phoneNumber: input.phoneNumber
        }
      }
    }).then(() => refreshClients())
  )
  return (
    <Card>
      <CardContent>
        <CardTitle>Create User</CardTitle>
      </CardContent>

      <Form onSubmit={handleSubmit(execute)}>
        <FormField
          id={'emailAddress'}
          label={'Email Address'}
          control={
            <TextInput type="email" name={'emailAddress'} ref={register} />
          }
        />

        <FormField
          id={'name'}
          label={'Name'}
          control={
            <TextInput type="text" name={'name'} ref={register} />
          }
        />

        <FormField
          id={'phoneNumber'}
          label={'Phone Number'}
          control={
            <TextInput type="text" name={'phoneNumber'} ref={register} />
          }
        />

        <div tw="my-4">
          This won't send an email to the client.
        </div>

        <Button isLoading={isPending(status)}>
          Create User
        </Button>

        {isSuccess(status) && (
          <SuccessMessageOld title='User Created'/>
        )}
      </Form>
    </Card>
  )
}
