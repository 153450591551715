import {
  AssignSolicitorToCaseCommandMutation,
  AssignSolicitorToCaseCommandMutationVariables,
  GetSolicitorQuery,
  GetSolicitorQueryVariables
} from "@lawhive/generated-api"
import { isPending, isSuccess, refreshFold } from "@nll/datum/DatumEither"
import React, { FC, useState } from "react"
import "twin.macro"
import { useCase } from "../../../../contexts/case/CaseContext"
import { CaseDetails } from "../../../../contexts/case/useGetCase"
import { Button } from "../../../../elements/Button"
import { CaseTitleBreadcrumb } from "../../../../elements/case/CaseTitleBreadcrumb"
import { ScenePanel, ScrollingChildrenContainer } from "../../../../layout/Layout"
import { ROUTES } from "../../../../layout/Navigation"
import { useAsync } from "../../../../lib/useAsync"
import { useRouter } from "../../../../lib/useRouter"
import { formSubmit, graphql, noop } from "../../../../lib/utils"
import { InfoNote, SuccessMessage, WarningMessage } from "../../../../elements"
import { SolicitorCombobox, SolicitorWithEmail } from "../../../../elements/admin/SolicitorCombobox"
import { Spinner } from "../../../../elements/Loading"

export const AdminCaseAssignSolicitorScene = () => {
  const { caseDetails } = useCase()
  return isSuccess(caseDetails) ? (
    <>
      <CaseTitleBreadcrumb caseNumber={caseDetails.value.right.friendlyId} action={'Add details'} />
      <ScrollingChildrenContainer>
        <ScenePanel>
          This will send the solicitor an email informing them about the case.
          <AddDetailsForm cas={caseDetails.value.right} />
        </ScenePanel>
      </ScrollingChildrenContainer>
    </>
  ) : null
}

const AddDetailsForm: FC<{ cas: CaseDetails }> = ({ cas }) => {
  const { refresh } = useCase()
  const { replace } = useRouter()
  const [solicitor, setSolicitor] = useState<SolicitorWithEmail>()

  const { status, execute } = useAsync(
    (solicitorId: string) => graphql<AssignSolicitorToCaseCommandMutation, AssignSolicitorToCaseCommandMutationVariables>({
      query: /* GraphQL */ `
        mutation AssignSolicitorToCaseCommand($input: AssignSolicitorToCaseCommand!) {
          assignSolicitorToCaseCommand(input: $input) {
            id
          }
        }
      `,
      variables: {
        input: {
          caseId: cas.id,
          solicitorId: solicitorId
        }
      }
    })
    .then(() => refresh())
    .then(() => replace(ROUTES.case.details(cas.id)))
  )

  const {status: solStripeStatus, execute: checkSolStripe, reset } = useAsync(
    (solicitorId: string) => graphql<GetSolicitorQuery, GetSolicitorQueryVariables>({
      query: /* GraphQL */ `
        query GetSolicitorQuery {
          getSolicitor(id: "${solicitorId}") {
            onboardingStatus {
              hasChargesEnabled
            }
          }
        }
      `
    })
    .then(r => r.data?.getSolicitor?.onboardingStatus?.hasChargesEnabled === true)
  )

  const StripeWarning: FC = () => (
    <>
      {refreshFold(
        () => null,
        () => <InfoNote tw='mt-6'>Checking Stripe Status</InfoNote>,
        () => <Spinner/>,
        (solicitorOnboarded: boolean) => !solicitorOnboarded
          ? (
            <WarningMessage title="This solicitor is not onboarded into Stripe" tw="mt-6">
              They will not be able to receive payments from the client. You can still assign this case to them, but the
              client will see an error message when they attempt payment.
            </WarningMessage>
          )
          : (
            <SuccessMessage title="This solicitor is onboarded to Stripe" tw='mt-6'>
              Go ahead and assign them a case!
            </SuccessMessage>
          ),
      )(solStripeStatus)}
    </>
  )


  const submit = () => solicitor ? execute(solicitor.id) : noop()

  const handleSolChange = (newSol?: SolicitorWithEmail) => {
    if (newSol) {
      reset()
      checkSolStripe(newSol.id)
      setSolicitor(newSol)
    }
  }

  return (
    <form onSubmit={formSubmit(submit)} tw="flex flex-col">
      <SolicitorCombobox onSelected={handleSolChange} selectedId={solicitor?.id} tw='mt-6'/>
      <StripeWarning />
      <Button isLoading={isPending(status) || isPending(solStripeStatus)} tw='mt-6'>
        Submit
      </Button>
    </form>
  )
}
