import { useEffect } from 'react'
import { DeepPartial, FieldName, SetFieldValue, UnpackNestedValue, UseFormMethods } from 'react-hook-form'

// Adapted from https://github.com/tiaanduplessis/react-hook-form-persist/blob/master/src/index.js
export const useFormPersist = <T>(
  storageKey: string,
  { watch, reset }: Pick<UseFormMethods<T>, 'watch' | 'reset'>,
  { storage, include, exclude, onDataRestored }: Partial<{
    storage: Storage,
    exclude: (keyof T)[],
    include: (keyof T)[],
    onDataRestored: (data: T) => void,
  }>
) => {
  const values = include ? watch(include) : watch()
  const getStorage = () => storage || window.sessionStorage

  useEffect(() => {
    const str = getStorage().getItem(storageKey)
    if (str) {
      const excludes = exclude || []
      const values = JSON.parse(str) as Pick<T, typeof excludes[number]>

      const data = Object.keys(values)
        .filter(k => !excludes.includes(k as keyof T) || true)
        .reduce((p, c) => ({ ...p, [c]: values[c as keyof T] }), {} as Pick<T, typeof excludes[number]>)

      reset(data as UnpackNestedValue<DeepPartial<T>>)

      if (onDataRestored) {
        onDataRestored(data)
      }
    }
  }, [storageKey])

  useEffect(() => {
    getStorage().setItem(storageKey, JSON.stringify(values))
  })

  return {
    clear: () => getStorage().removeItem(storageKey)
  }
}
