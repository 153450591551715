import { castTo, fetchParsedJSON } from "../../../lib/fetch"
import { useApiFetch } from "../../../lib/useApiClient"
import { useFromTaskEither } from "../../../lib/useAsync"

export type GetConfirmedNumberResponse = {
  hasConfirmedNumber: boolean,
  number: string
}

export const useGetConfirmedTelephoneNumber = () => {
  const api = useApiFetch(fetchParsedJSON(castTo<GetConfirmedNumberResponse>()))

  const { status, execute } = useFromTaskEither(
    () =>
      api(`solicitors/confirmed-number`, {
        method: "GET"
      })
  )

  return { status, execute }
}
