import React from "react"
import { FC } from "react"
import 'twin.macro'

import { createStateMachine } from "../../contexts/StateMachine"
import { KycTimeline } from "./KycTimeline"

type KycState = {
  name: string,
  emailAddress: string
  phoneNumber: string
  details: string
  secret: string
  submissionId: string,
  completed: boolean
}

const defaultStore: KycState = {
  name: "",
  emailAddress: "",
  phoneNumber: "",
  details: "",
  secret: "",
  submissionId: "",
  completed: false
}

const update = (state: KycState, payload: Partial<KycState>) => ({
  ...state,
  ...payload
})

const { Provider, useStateMachine } = createStateMachine({
  storeName: 'lawhive-kyc-state',
  defaultStoreData: defaultStore,
  options: {
    middleware: [],
    storageType: typeof localStorage !== 'undefined' ? localStorage : ({} as Storage)
  }
})

export const useKycStateMachine = () => useStateMachine({
  update
})

type KycStepProps = {
  step: number
}

export const KycStep: FC<KycStepProps> = ({ step, children }) => (
  <Provider>
    <Inner step={step}>{children}</Inner>
  </Provider>
)

const Inner: FC<KycStepProps> = ({ step, children }) => {
  return (
    <div tw="py-4 sm:py-5">
      <section tw="w-full pb-4 sm:pb-5">
        <KycTimeline step={step} />
      </section>

      <section tw="max-w-screen-md md:max-w-screen-xl mx-auto w-full">
        {children}
      </section>
    </div>
  )
}
