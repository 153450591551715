import { impl, matchExhaustive, Variant } from "@practical-fp/union-types"
import { pipe } from "fp-ts/es6/function"
import * as O from "fp-ts/es6/Option"
import * as TE from "fp-ts/es6/TaskEither"
import { castTo, fetchParsedJSON } from "../lib/fetch"
import { UUID } from "../lib/Primitives"
import { useApiFetch } from '../lib/useApiClient'

export type ChargingModel =
  | Variant<"PayByCase", {}>
  | Variant<"Monthly", {}>

export const ChargingModel = impl<ChargingModel>()

export type PaymentMethodDTO =
  | Variant<"DirectDebit", { last4: string, sortCode: string }>
  | Variant<"Other">

export const PaymentMethodDTO = impl<PaymentMethodDTO>()

export type SubscriptionDTO = {
  isActive: boolean
  name: string
  paymentMethod?: PaymentMethodDTO
}

export type GetChargeAccountDTO = {
  id: UUID
  model: ChargingModel
  subscriptions: SubscriptionDTO[]
  needsSubscription: boolean
}


export const useGetChargeAccount = () => {
  const api = useApiFetch(fetchParsedJSON(castTo<GetChargeAccountDTO>()))

  return () => pipe(
    api(`charge-accounts`),
    TE.map(O.some),
    TE.orElseW(error => matchExhaustive(error, {
      NetworkError: () => TE.left(error),
      ParseError: () => TE.left(error),
      ResponseError: (e) => e.statusCode === 404
        ? TE.right(O.none as O.Option<GetChargeAccountDTO>)
        : TE.left(error)
    }))
  )
}
