import React from "react"
import { Route, Switch } from "react-router-dom"

import { ROUTES } from "../../layout/Navigation"
import { AssessmentCallsList } from "./AssessmentCallsList"
import { AssessmentCallDetailsScene } from "./AssessmentCallDetailsScene"
import { AssessmentCallCantQuoteScene } from "./complete/CantQuoteScene"
import { AssessmentCallQuoteScene } from "./complete/QuoteScene"
import { AssessmentCallCustomiseQuoteScene } from "./complete/CustomiseQuoteScene"
import { AssessmentCallSendQuoteScene } from "./complete/SendQuoteScene"

export const AssessmentCallsSwitch = () => (
  <Switch>
    <Route path={ROUTES.assessmentCalls.complete(':id').quote.build} component={AssessmentCallQuoteScene} />
    <Route path={ROUTES.assessmentCalls.complete(':id').quote.customise} component={AssessmentCallCustomiseQuoteScene} />
    <Route path={ROUTES.assessmentCalls.complete(':id').quote.send} component={AssessmentCallSendQuoteScene} />
    <Route path={ROUTES.assessmentCalls.complete(':id').cantQuote} component={AssessmentCallCantQuoteScene} />
    <Route path={ROUTES.assessmentCalls.details(':id')} component={AssessmentCallDetailsScene} />
    <Route path={ROUTES.assessmentCalls.root()} component={AssessmentCallsList} />
  </Switch>
)
