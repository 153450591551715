import { useAnalytics } from "../../hooks/useAnalytics";
import { CallCompleteEvent, CallCompleteEventProps } from "./types";

export const useCallCompleteEvent = () => {
    const { trackEvent } = useAnalytics();
    
    return (properties: CallCompleteEventProps) => {
        trackEvent(buildCallCompleteEvent(properties));
    };
}

const buildCallCompleteEvent = (properties: CallCompleteEventProps): CallCompleteEvent => {
    return {
        action: "submitted",
        target: "call-action",
        description: "Call completed",
        properties,
    }
}