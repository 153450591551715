import { default as React, FC, useEffect, useRef, useState } from "react"
import { Link, NavLink } from "react-router-dom"
import { useIntercom } from "react-use-intercom"
import tw, { styled } from 'twin.macro'
import { useUser } from "../contexts/User"
import { BoolString } from "../elements/YesNoToggle"

import { CaseType } from '../lib/CaseTypes'
import { mapObject } from '../lib/utils'
import { PageContainer } from './Layout'
import { useUnreadHelpMessages } from "./LoggedInShell"

const optionsToParamString = <T extends Record<string, string>,>(options: T) =>
  new URLSearchParams(options).toString()

const switchedOptionsToParamString = <T extends Record<string, boolean | undefined>>(options: T) =>
  optionsToParamString(
    mapObject(([k, v]: [string, boolean | undefined]) => [k, v ? 'true' : ''])(options)
  )

type NonNullRecord<T> = {
  [K in keyof T]: NonNullable<T[K]>
}

export const filterNullValuesFromObject = <T extends Record<string, string | boolean | undefined>,>(input: T) => Object.entries(input)
  .filter(([_, v]) => !(v === null || v === undefined))
  .reduce((prev, [k, v]) => ({ ...prev, [k]: v }), {} as NonNullRecord<T>)

export const withOptions = <T extends Record<string, string | undefined>>(input: string, options?: T) =>
  options
    ? `${input}?${new URLSearchParams(filterNullValuesFromObject(options)).toString()}`
    : input

export const ROUTES = {
  root: '/',
  submitKyc: {
    root: (returnUrl?: string) => `/submit-kyc${returnUrl ? `?returnUrl=${returnUrl}`: ''}`,
    step1: `/submit-kyc/step1`,
    step2: `/submit-kyc/step2`,
    step3: `/submit-kyc/step3`,
    pending: `/submit-kyc/pending`,
    verified: `/submit-kyc/verified`
  },
  onboarding: {
    root: '/onboarding',
    create: (caseType: CaseType | ':caseType') => `/onboarding/create/${caseType}`,
    checkoutCallback: (preRegistrationId: string) => `/onboarding/checkout/callback/${preRegistrationId}`,
    saveProgress: (preRegistrationId: string) => `/onboarding/save-progress/${preRegistrationId}`,
    claimInterest: (preRegistrationId: string) => `/onboarding/claim/${preRegistrationId}`,
  },
  assessmentCalls: {
    root: (options?: { rejectedClient: string }) => withOptions(`/assessment-calls`, options),
    details: (id: string, options?: { quoted?: BoolString, dismissed?: BoolString }) => withOptions(`/assessment-calls/${id}`, options),
    reject: (id: string) => `/assessment-calls/${id}/reject`,
    complete: (id: string) => ({
      cantQuote: `/assessment-calls/${id}/cant-quote`,
      quote: {
        build: `/assessment-calls/${id}/quote/build`,
        customise: `/assessment-calls/${id}/quote/customise`,
        send: `/assessment-calls/${id}/quote/send`,
      }
    })
  },
  clientPayments: {
    root: '/client-payments',
    payments: '/client-payments/payments',
    payouts: '/client-payments/payouts',
    charges: '/client-payments/charges',
    invoices: '/client-payments/invoices',
    payout: (payoutId: string) => `/client-payments/payouts/${payoutId}`,
    // payment: (paymentId: string) => `/billing/payment/${paymentId}`
  },
  solicitors: {
    root: '/solicitors',
    plan: {
      root: "/solicitors/settings/plan",
      details: "/solicitors/settings/plan/details",
      choose: "/solicitors/settings/plan/choose",
      charges: "/solicitors/settings/plan/charges"
    },
    feeGuide: {
      root: '/solicitors/fee-guide'
    }
  },
  admin: {
    root: '/admin',
    kyc: {
      root: '/admin/kyc',
      details: (userId: string) => `/admin/kyc/${userId}`
    }
  },
  paymentMethods: {
    root: '/paymentmethods',
    claim: (returnUrl?: string) => `/paymentmethods/claim-session${returnUrl ? `?returnUrl=${returnUrl}`: ''}`
  },
  case: {
    root: '/cases',
    details: (caseId: string, options?: { paid?: boolean, created?: boolean, followOnSubmitted?: boolean }) => `/cases/${caseId}${options ? `?${switchedOptionsToParamString(options)}`: ''}`,
    messages: (caseId: string) => `/cases/${caseId}/messages`,
    files: (caseId: string) => `/cases/${caseId}/files`,
    payments: (caseId: string) => `/cases/${caseId}/payments`,
    fees: (caseId: string) => `/cases/${caseId}/fees`,
    client: (caseId: string) => `/cases/${caseId}/client`,
    summary: (caseId: string) => `/cases/${caseId}/summary`,
    viewQuote: (caseId: string, quoteId: string) => `/cases/${caseId}/quote/${quoteId}`,
    checkout: (caseId: string, quoteId: string) => `/cases/${caseId}/quote/${quoteId}/checkout`,
    // claimPayment: (caseId: string, quoteId: string, paymentIntentId?: string) => `/cases/${caseId}/checkout/claim/${quoteId}${paymentIntentId ? `?paymentIntentId=${paymentIntentId}`: ''}`,
    waitForPayment: (caseId: string, quoteId: string) => `/cases/${caseId}/checkout/${quoteId}`,
    admin: {
      create: '/cases/create',
      addDetails: (caseId: string) => `/cases/${caseId}/add-details`,
      cancel: (caseId: string) => `/cases/${caseId}/cancel`,
      unassign: (caseId: string) => `/cases/${caseId}/unassign`,
      addPrice: (caseId: string) => `/cases/${caseId}/add-price`,
      addExistingPayment: (caseId: string) => `/cases/${caseId}/add-existing-payment`,
      assignSolicitor: (caseId: string) => `/cases/${caseId}/assign-solicitor`,
      markComplete: (caseId: string) => `/cases/${caseId}/mark-complete`
    },
    followOn: (caseId: string) => ({
        build: `/cases/${caseId}/follow-on/build`,
        customise: `/cases/${caseId}/follow-on/customise`,
        send: `/cases/${caseId}/follow-on/send`,
    })
  },
  auth: {
    root: '/auth',
    login: (returnUrl?: string) => `/auth/login${returnUrl ? `?returnUrl=${returnUrl}`: ''}`,
    magicLinkCallback: (returnUrl?: string) => `/auth/callback${returnUrl ? `?returnUrl=${returnUrl}`: ''}`,
    confirmEmail: (returnUrl?: string) => `/auth/confirm-email${returnUrl ? `?returnUrl=${returnUrl}`: ''}`
  },
  legal: {
    privacyPolicy: 'https://lawhive.co.uk/legal/privacy-policy/',
    termsOfService: 'https://lawhive.co.uk/legal/terms-of-service/',
    clientSolicitorEngagementTerms: 'https://lawhive.co.uk/legal/client-solicitor-engagement-terms/'
  }
}

export const DesktopNavLink = styled(NavLink)`
  ${tw`px-3 py-2 rounded-md text-sm font-medium transition duration-150 ease-in-out`}

  &.active {
    ${tw`text-white bg-indigo-800 focus:outline-none focus:text-white focus:bg-indigo-700`}
  }

  &:not(.active) {
    ${tw`text-indigo-200 hover:text-white hover:bg-indigo-700 focus:outline-none focus:text-white focus:bg-indigo-700`
  }
`

export const DesktopNavLinkA = DesktopNavLink.withComponent('a')
export const DesktopNavButton = DesktopNavLink.withComponent('button')

export const MobileNavLink = styled(NavLink)`
  ${tw`block px-3 py-2 rounded-md text-base font-medium w-full`}

  &.active {
    ${tw`text-white bg-indigo-800 focus:outline-none focus:text-white focus:bg-indigo-700`}
  }

  &:not(.active) {
    ${tw` text-indigo-200 hover:text-white hover:bg-indigo-700 focus:outline-none focus:text-white focus:bg-indigo-700`
  }
`
export const MobileNavButton = MobileNavLink.withComponent('button')

type NavigationProps = {
  primary: JSX.Element,
  secondary: JSX.Element,
  mobile: (dismiss: () => void) => JSX.Element
}

export const Navigation: FC<NavigationProps> = ({ primary, secondary, mobile }) => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)

  const toggleMobileMenu = () => setMobileMenuOpen(!isMobileMenuOpen)
  const dismissMobile = () => setMobileMenuOpen(false)

  const menu = useRef<HTMLDivElement>(null)

  const handleClickOutside = (e: MouseEvent) => {
    const el = e.target
    if (el && el instanceof Node && menu.current?.contains(el)) {
      return
    }
    dismissMobile()
  }

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside)
    } else {
      document.removeEventListener("mousedown", handleClickOutside)
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [isMobileMenuOpen])


  return (
    <nav tw="bg-indigo-900" ref={menu}>
      <PageContainer>
        <div tw="flex items-center justify-between h-16">
          <div tw="flex items-center">
            <Link to={ROUTES.root} tw="font-logo text-white font-medium mr-8 hocus:underline" onClick={dismissMobile}>
              lawhive
            </Link>

            <div tw="hidden md:block">
              <div tw="flex items-center space-x-4">
                {primary}
              </div>
            </div>
          </div>
          <div tw="hidden md:(flex flex-row gap-2 ml-6)">
            <HelpButton />

            {secondary}
          </div>
          <div tw="-mr-2 flex flex-row gap-2 md:hidden">
            <HelpButton />

            <button onClick={toggleMobileMenu} tw="inline-flex items-center justify-center p-2 rounded-md text-indigo-400 hover:text-white hover:bg-indigo-700 focus:outline-none focus:bg-indigo-700 focus:text-white">
              <svg css={[tw`block h-6 w-6`, isMobileMenuOpen ? tw`hidden` : tw`block`]} stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>

              <svg css={[tw`hidden h-6 w-6`, isMobileMenuOpen ? tw`block` : tw`hidden`]} stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </PageContainer>

      {/* <!--
        Mobile menu, toggle twes based on menu state.

        Open: "block", closed: "hidden"
      --> */}
      <div css={[tw`hidden md:hidden z-30`, isMobileMenuOpen ? tw`block` : tw`hidden`]}>
        {mobile(dismissMobile)}
      </div>
    </nav>
  )
}

const HelpButton = () => {
  const { show } = useIntercom()
  const { isSolicitor } = useUser()
  const { unreadMessagesCount } = useUnreadHelpMessages()

  const Content = () => (
    <>
      <div tw="relative">
        {unreadMessagesCount > 0 && (
          <div tw="absolute flex items-center justify-center top-1 right-1 bg-red-600 rounded-full h-4 w-4">
            <span tw="text-xs">
              {unreadMessagesCount}
            </span>
          </div>
        )}

        <svg xmlns="http://www.w3.org/2000/svg" tw="h-6 w-6" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <circle cx="12" cy="12" r="9" />
          <line x1="12" y1="17" x2="12" y2="17.01" />
          <path d="M12 13.5a1.5 1.5 0 0 1 1 -1.5a2.6 2.6 0 1 0 -3 -4" />
        </svg>
      </div>

      <span tw="ml-1">
        Help
      </span>
    </>
  )

  return isSolicitor ? (
    <DesktopNavLinkA tw="flex flex-row items-center" target={'_blank'} href={"https://help.lawhive.co.uk"} id="help-menu" aria-label="Help">
      <Content />
    </DesktopNavLinkA>
    )
    : (
    <DesktopNavButton tw="flex flex-row items-center" onClick={show} id="help-menu" aria-label="Help">
      <Content />
    </DesktopNavButton>
  )
}
