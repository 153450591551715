import 'twin.macro'

import { format } from 'date-fns/fp'
import { AccountingCurrencyUnits } from '../../elements/Currency'
import { ControlledDataTable } from '../../elements/table/ControlledDataTable'
import { TableColumn } from '../../elements/table/PlainDataTable'
import { PayoutListEntry, useControlledListPayouts } from '../../hooks/useListPayouts'
import { ROUTES } from '../../layout/Navigation'
import { PayoutStatusBadge } from './PayoutStatusBadge'

const formatTable = format('dd/MM/yy')

const payoutColumns: TableColumn<PayoutListEntry>[] = [
  {
    id: 'arrivalDate',
    Header: 'Payout At',
    accessor: 'arrivalDate',
    Cell: ({ value }) => formatTable(value)
  },
  {
    id: 'statementDescriptor',
    Header: 'Statement Reference',
    accessor: 'statementDescriptor'
  },
  {
    id: 'amount',
    Header: 'Amount',
    accessor: 'amount',
    Cell: ({ value }) => <AccountingCurrencyUnits amountUnits={value} />
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => <PayoutStatusBadge status={value} />
  }
]

export const PayoutTable = () => {
  const controller = useControlledListPayouts()

  return (
    <ControlledDataTable
      columns={payoutColumns}
      controller={controller}
      selectable={{
        key: 'id',
        urlGenerator: (id) => ROUTES.clientPayments.payout(id)
      }}
      error={() => `Error getting payout information`}
    />
  )
}
