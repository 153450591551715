import React from "react"
import { Route, Switch, useParams } from "react-router-dom"
import { CaseProvider } from "../../contexts/case/CaseContext"
import { QuoteProvider } from "../../features/quotes/context/QuoteProvider"
import { ROUTES } from "../../layout/Navigation"
import { AdminAuthorizedRoute } from "../../lib/AuthorizedRoute"
import { AdminCaseAddDetailsScene } from "./details/admin/AdminCaseAddDetailsScene"
import { AdminCaseAddExistingPaymentScene } from "./details/admin/AdminCaseAddExistingPaymentScene"
import { AdminCaseAddPriceScene } from "./details/admin/AdminCaseAddPriceScene"
import { AdminCaseAssignSolicitorScene } from "./details/admin/AdminCaseAssignSolicitor"
import { AdminCaseCancelScene } from "./details/admin/AdminCaseCancelScene"
import { AdminCaseMarkCompleteScene } from "./details/admin/AdminCaseMarkCompleteScene"
import { AdminUnassignSolicitorScene } from "./details/admin/AdminUnassignSolicitorScene"
import { CaseDetailsScene } from "./details/CaseDetails"
// import { CheckoutScene } from './details/client/Checkout'
import { AdminCreateCaseScene } from "./list/admin/AdminCreateCaseScene"
import { CaseListScene } from "./list/CaseListScene"
import { WaitForPaymentScene } from "../../features/quotes/routes/WaitForPayment"
import { FollowOnCustomiseQuoteScene } from "./details/solicitor/follow-on/CustomiseQuoteScene"
import { FollowOnSendQuoteScene } from "./details/solicitor/follow-on/SendQuoteScene"
import { FollowOnQuoteScene } from "./details/solicitor/follow-on/QuoteScene"

export const CaseSwitch = () => (
  <Switch>
    <Route
      path={ROUTES.case.waitForPayment(":caseId", ":quoteId")}
      component={WaitForPaymentScene}
    />
    <Route path={ROUTES.case.details(":caseId")} component={CaseWithData} />
    <Route path={ROUTES.case.root} component={CaseListScene} />
  </Switch>
)

export const CaseWithData = () => {
  const { caseId } = useParams<{ caseId: string }>()

  return (
    <CaseProvider caseId={caseId}>
      <QuoteProvider>
        <Switch>
          {/* <Route path={ROUTES.case.checkout(':caseId', ':caseQuoteId')} component={CheckoutScene} /> */}

          <AdminAuthorizedRoute
            path={ROUTES.case.admin.addDetails(":caseId")}
            component={AdminCaseAddDetailsScene}
          />
          <AdminAuthorizedRoute
            path={ROUTES.case.admin.cancel(":caseId")}
            component={AdminCaseCancelScene}
          />
          <AdminAuthorizedRoute
            path={ROUTES.case.admin.unassign(":caseId")}
            component={AdminUnassignSolicitorScene}
          />
          <AdminAuthorizedRoute
            path={ROUTES.case.admin.addPrice(":caseId")}
            component={AdminCaseAddPriceScene}
          />
          <AdminAuthorizedRoute
            path={ROUTES.case.admin.addExistingPayment(":caseId")}
            component={AdminCaseAddExistingPaymentScene}
          />
          <AdminAuthorizedRoute
            path={ROUTES.case.admin.assignSolicitor(":caseId")}
            component={AdminCaseAssignSolicitorScene}
          />
          <AdminAuthorizedRoute
            path={ROUTES.case.admin.markComplete(":caseId")}
            component={AdminCaseMarkCompleteScene}
          />
          <AdminAuthorizedRoute
            path={ROUTES.case.admin.create}
            component={AdminCreateCaseScene}
          />

          <Route
            path={ROUTES.case.followOn(":caseId").customise}
            component={FollowOnCustomiseQuoteScene}
          />

          <Route
            path={ROUTES.case.followOn(":caseId").build}
            component={FollowOnQuoteScene}
          />

          <Route
            path={ROUTES.case.followOn(":caseId").send}
            component={FollowOnSendQuoteScene}
          />

          <Route
            path={ROUTES.case.details(":caseId")}
            component={CaseDetailsScene}
          />
        </Switch>
      </QuoteProvider>
    </CaseProvider>
  )
}
