import React from "react"
import { Route, Switch } from "react-router-dom"

import { ROUTES } from "../../layout/Navigation"
import { PayoutDetailsScene } from "./payouts/PayoutDetails"
import { SolicitorBillingDashboard } from "./SolicitorBillingDashboard"

export const BillingSwitch = () => (
  <Switch>
    <Route path={ROUTES.clientPayments.payout(':payoutId')} component={PayoutDetailsScene} />
    <Route path={ROUTES.clientPayments.root} component={SolicitorBillingDashboard} />
  </Switch>
)

