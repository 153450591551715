import { useEffect } from "react"

import { useUser } from "../contexts/User"
import { ROUTES } from "../layout/Navigation"
import { useRouter } from "./useRouter"

export const useRequireSolicitor = (redirectUrl = ROUTES.root) => {
  const user = useUser()
  const router = useRouter()

  useEffect(() => {
    if (!user.isSolicitor) {
      router.push(redirectUrl)
    }
  }, [user, redirectUrl, router])

  return user
}

export const useRequireAdmin = (redirectUrl = ROUTES.root) => {
  const user = useUser()
  const router = useRouter()

  useEffect(() => {
    if (!user.isAdmin) {
      router.push(redirectUrl)
    }
  }, [user, redirectUrl, router])

  return user
}
