import { WithID } from "packages/app/src/components/list-builder/useListState"
import { appendGeneratedId } from "packages/app/src/lib/asListState"
import { useLocalStorageNew } from "packages/app/src/lib/useLocalStorage"
import { ScopeTitle, SolicitorQuoteType } from "./useCreateSolicitorQuote"
import {
  FeeGuideLegalArea,
  FeeGuideLineItem,
  FeeGuideProduct,
  FeeGuideProductVariant
} from "../../../hooks/useFeeGuide"


type ScopeItem = { title: ScopeTitle }

type FeeGuideSnapshot = {
  id: string
  name: string
}

export type DraftQuote = {
  hasStarted: boolean
  type: SolicitorQuoteType

  feeGuide?: {
    legalArea?: FeeGuideSnapshot
    variant?: FeeGuideSnapshot & { shortName?: string }
    product?: FeeGuideSnapshot
  }

  lineItems: WithID<FeeGuideLineItem>[]
  includes: WithID<ScopeItem>[]
  excludes: WithID<ScopeItem>[]
  notes?: string
}

export const useDraftQuote = (callId: string) => {
  const [draft, setDraft] = useLocalStorageNew<DraftQuote>(`call/${callId}/quote`, {
    feeGuide: undefined,
    hasStarted: false,
    type: "custom",
    includes: [],
    excludes: [],
    notes: undefined,
    lineItems: []
  })

  const createFromTemplate = (legalArea?: FeeGuideLegalArea, product?: FeeGuideProduct, variant?: FeeGuideProductVariant) => {
    setDraft({
      includes: variant?.includes?.map(title => ({ title: title as ScopeTitle })).map(appendGeneratedId) || [],
      excludes: variant?.excludes?.map(title => ({ title: title as ScopeTitle })).map(appendGeneratedId) || [],
      notes: undefined,
      lineItems: variant?.lineItems?.map(appendGeneratedId) || [],
      hasStarted: true,
      type: "template",
      feeGuide: {
        product: product
          ? { name: product.title, id: product.id }
          : { name: "Legal Service", id: "other" },
        legalArea: legalArea
          ? { name: legalArea.title, id: legalArea.id }
          : { name: "Other", id: "other" },
        variant: variant
          ? { name: variant.title, id: variant.id, shortName: variant.shortName }
          : { name: "Other", id: "other" }
      }
    })
  }

  return {
    draft,
    setDraft,
    createFromTemplate
  }
}
