import { useMemo } from "react"
import { useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom"

export const useRouter = <RouteParams = {}, QueryString extends Record<string, string> = {}>() => {
  const params = useParams<RouteParams>()
  const location = useLocation()
  const history = useHistory()
  const match = useRouteMatch()

  const qs = new URLSearchParams(location.search)
  const qso = Object.fromEntries(qs.entries()) as Partial<QueryString>

  return useMemo(() => ({
    push: history.push,
    replace: history.replace,
    pathname: location.pathname,
    params: {
      ...qso,
      ...params
    },
    match,
    location,
    history
  }), [params, match, location, history])
}
