import { Elements } from "@stripe/react-stripe-js"
import { Stripe, loadStripe } from "@stripe/stripe-js"

import React, { FC, useState, useEffect } from "react"
import { useEnvironment } from "../contexts/Environment"

export const StripeContainer: FC<{ accountId?: string }> = ({ children, accountId }) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>(new Promise(() => {}))
  const { STRIPE_PUBLIC_KEY } = useEnvironment()

  useEffect(() => {
    // Make sure to call `loadStripe` outside of a component’s render to avoid
    // recreating the `Stripe` object on every render.
    setStripePromise(loadStripe(STRIPE_PUBLIC_KEY, accountId ? {
      stripeAccount: accountId
    }: {})
     .then(a => { setIsLoaded(true); return a }))
  }, [STRIPE_PUBLIC_KEY, accountId])

  return isLoaded ? (
    <Elements stripe={stripePromise}>
      {children}
    </Elements>
  ) : null
}
