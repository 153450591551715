import { useAnalytics } from "../../hooks/useAnalytics";
import { KYCSubmissionEvent, KYCSubmissionEventProps } from "./types";

export const useKYCSubmissionEvent = () => {
    const { trackEvent } = useAnalytics();

    return (props: KYCSubmissionEventProps) => trackEvent(buildKYCSubmittedEvent(props));
}

export const buildKYCSubmittedEvent = (properties: KYCSubmissionEventProps): KYCSubmissionEvent => {
    return {
        action: "submitted",
        target: "kyc",
        properties,
        description: "KYC submission",
    }
}
