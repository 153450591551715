import React, { FC, ReactNode } from 'react'
import tw from 'twin.macro'

export const CardContent = tw.div`px-4 py-4 sm:px-6 sm:py-6`

export const CardTitle = tw.h4`text-base sm:text-lg font-medium`

export const Card: FC = ({ children, ...props }) => (
  <div tw="sm:rounded-lg ring-1 ring-black ring-opacity-5 w-full" className="group">
    <div tw="bg-white shadow-lg overflow-hidden sm:rounded-lg" {...props}>
      {children}
    </div>
  </div>
)

type CardHeaderProps = {
  title: string
  supportingText?: ReactNode
  highlight?: boolean
  badge?: ReactNode
  actions?: ReactNode
}

export const CardHeader: FC<CardHeaderProps> = ({ title, badge, highlight, supportingText, actions }) => (
  <div css={[
    highlight && tw`border-t-8 border-indigo-600 group-hover:border-indigo-500 transition ease-in-out duration-150`
  ]}>
    <div tw="px-4 sm:px-6 py-4 flex flex-col items-start gap-4 sm:(gap-4 flex-row items-center) justify-between">
      <div tw="flex flex-col">
        <div tw="flex flex-col items-start sm:flex-row sm:items-center gap-4">
          <p tw="font-medium text-gray-900">
            {title}
          </p>
          {badge}
        </div>
        <p tw="text-gray-500 text-xs sm:text-sm">
          {supportingText}
        </p>
      </div>
      {actions}
    </div>
    <hr/>
  </div>
)


type CardFooterProps = {
  actions?: ReactNode
}

export const CardFooter: FC<CardFooterProps> = ({ actions }) => (
  <div>
    <hr/>
    <div tw="px-4 sm:px-6 py-4 flex flex-col items-end gap-4 sm:(gap-4 flex-row items-center) justify-between">
      {actions}
    </div>
  </div>
)

export const FullFlexCard: FC = ({ children, ...props }) => (
  <div tw="sm:rounded-lg ring-1 ring-black ring-opacity-5 w-full h-full">
    <div tw="bg-white shadow-lg overflow-hidden sm:rounded-lg h-full" {...props}>
      {children}
    </div>
  </div>
)
