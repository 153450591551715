import * as D from 'io-ts/lib/Decoder'
import { pipe } from 'fp-ts/lib/function'
import * as A from 'fp-ts/lib/Array'
import * as O from "fp-ts/lib/Option"
import { fetchParsedJSON, RequestError } from "../lib/fetch"
import { decodeOrError, withOptional } from "../lib/io"
import { DateFromString } from '../lib/Primitives'
import { useApiFetch } from '../lib/useApiClient'
import { withOptions } from '../layout/Navigation'
import { StripeNextTokenPagination } from './useListPayments'
import { NextToken, useNextTokenPaginatedData } from './useNextTokenPagination'


export const PayoutStatus = D.literal(`paid`, `pending`, `in_transit`, `canceled`, `failed`)
export type PayoutStatus = D.TypeOf<typeof PayoutStatus>

export const PayoutListEntry = withOptional(
  D.struct({
    id: D.string,
    status: PayoutStatus,
    createdAt: DateFromString,
    arrivalDate: DateFromString,
    amount: D.number,
  }), {
  statementDescriptor: D.string,
})
export type PayoutListEntry = D.TypeOf<typeof PayoutListEntry>

export const PayoutList = D.array(PayoutListEntry)
export type PayoutList = D.TypeOf<typeof PayoutList>

export const useListPayouts = () => {
  const api = useApiFetch(fetchParsedJSON(decodeOrError(PayoutList)))

  return (pagination?: StripeNextTokenPagination) => pipe(
    withOptions(`solicitor-billing/payouts`, pagination),
    api
  )
}

export const useControlledListPayouts = () => {
  const requestPayouts = useListPayouts()

  return useNextTokenPaginatedData<RequestError, PayoutList, PayoutList>({
    query: (nextToken, limit) => requestPayouts({
      startingAfter: nextToken as string | undefined,
      limit: String(limit)
    }),
    dataExtractor: a => a,
    nextTokenExtractor: (r) => pipe(
      r,
      A.last,
      O.map(a => a.id as NextToken),
      O.getOrElse(() => undefined as NextToken)
    ),
    opts: {
      limit: 5
    },
  })
}
