import { OnCreateMessageSubscription, OnCreateMessageSubscriptionVariables } from "@lawhive/generated-api"

import { isSome } from "fp-ts/es6/Option"
import { graphqlSubscribe } from "../../lib/utils"
import { parseMessageResult } from "./useCreateMessage"
import { CaseMessage } from "./useGetCase"

export const subscribeToMessages = (caseId: string, onMessage: (m: CaseMessage) => void) => {
  const onUpdate = graphqlSubscribe<OnCreateMessageSubscription, OnCreateMessageSubscriptionVariables>({
    query: /* GraphQL */ `
      subscription OnCreateMessage($caseId: ID!) {
        onCreateMessage(caseId: $caseId) {
          id
          authorId
          author {
            displayName
          }
          type
          caseId
          content
          createdAt
          updatedAt
        }
      }
    `,
    variables: {
      caseId: caseId
    },
  }).subscribe({
    next: d => {

      const m = parseMessageResult({ data: { createMessage: d.value.data?.onCreateMessage! }})
      if(isSome(m)) {
        onMessage(m.value)
      } else {
        console.warn("Null message received in subscription")
      }
    }
  })

  return () => onUpdate.unsubscribe()
}
