import { CaseDetailsType } from "@lawhive/generated-api"
import { isSome } from "fp-ts/es6/Option"
import React from "react"
import tw from "twin.macro"
import { Card, CardContent } from "../../../../elements"
import { ComponentWithCase } from "../CaseDetails"

const InstructionSubtitle = tw.h4`text-xs uppercase text-gray-400 font-medium`
const InstructionParagraph = tw.p`text-gray-900`
const InstructionSection = tw.div`mb-4 last:mb-0`

export const CaseSummaryCard: ComponentWithCase = ({ case: cas }) => (
  <Card>
    <CardContent>
      {isSome(cas.details)
        ? (
          <>
            {cas.details.value.type === CaseDetailsType.structured && (
              <>
                <InstructionSection>
                  <InstructionSubtitle>
                    Summary
                  </InstructionSubtitle>
                  <InstructionParagraph>
                    {cas.details.value.summary}
                  </InstructionParagraph>
                </InstructionSection>
              </>
            )}

            <InstructionSection>
              <InstructionSubtitle>
                Details
              </InstructionSubtitle>
              <InstructionParagraph>
                {cas.details.value.details.split('\n').map((item, key) => <span key={key}>{item}<br/></span>)}
              </InstructionParagraph>
            </InstructionSection>

            {cas.details.value.type === CaseDetailsType.structured && (
              <>
                <InstructionSection>
                  <InstructionSubtitle>
                    Client Request
                  </InstructionSubtitle>
                  <InstructionParagraph>
                    {cas.details.value.ask}
                  </InstructionParagraph>
                </InstructionSection>
              </>
            )}
          </>
        )
        : (
          <>
            This case doesn't have a summary registered.
            Please check Box or speak to your case manager.
          </>
        )
      }
    </CardContent>
  </Card>
)
