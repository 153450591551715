

import { useAnalytics } from '../../hooks/useAnalytics'
import { CheckoutEvent, CheckoutEventProps } from './types'

export const useQuoteCheckoutCancelledEvent = () => {
  const { trackEvent } = useAnalytics()

  return (props: CheckoutEventProps) => trackEvent(buildQuoteCheckoutCancelledEvent(props))
}

const buildQuoteCheckoutCancelledEvent = (properties: CheckoutEventProps): CheckoutEvent => ({
  action: 'cancelled',
  target: 'checkout',
  properties: {
    ...properties,
  },
  description: 'Quote checkout cancelled',
})

export const useQuoteCheckoutCompletedEvent = () => {
    const { trackEvent } = useAnalytics()
  
    return (props: CheckoutEventProps) => trackEvent(buildQuoteCheckoutCompletedEvent(props))
  }
  
const buildQuoteCheckoutCompletedEvent = (properties: CheckoutEventProps): CheckoutEvent => ({
action: 'completed',
target: 'checkout',
properties: {
    ...properties,
},
description: 'Quote checkout started',
})
  

export const useQuoteCheckoutStartedEvent = () => {
    const { trackEvent } = useAnalytics()
  
    return (props: CheckoutEventProps) => trackEvent(buildQuoteCheckoutStartedEvent(props))
  }
  
const buildQuoteCheckoutStartedEvent = (properties: CheckoutEventProps): CheckoutEvent => ({
action: 'clicked',
target: 'checkout',
properties: {
    ...properties,
},
description: 'Quote checkout started',
})
  