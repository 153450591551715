
/**
 * TODO Properly create abstractions for fee earning and non-fee-earning work
 * (Fees / Deductions)
 * Including any appropriate flagging / descriptions / sku-ification of said work
 *
 *
 *
 * MISC OTHER CODE
 * TODO Clean up
// const quotes = cas.quotes.sort((a, b) => compareDesc(a.updatedAt, b.updatedAt))

// const firstQuote = quotes.length > 0 ? quotes[0] : null
// const paidOn = firstQuote.payments.reduce((p, c) => isBefore(p, c.createdAt) ? p : c.createdAt, new Date())
 *
 *
 */
export type Quote = {
  id: string
  isLeadFromAds?: boolean | null
  items: WorkItems
}

export type WorkItems = LineItem[]

export type DraftQuote = Omit<Quote, 'id'>

export type WorkScope = {
  includes: string[]
  excludes: string[]
}

export type LineItem = {
  clientPays: number
  solicitorReceives: number
  description?: string | null,
  platformFee: number
  feePercentage: number
  subsidy: number
  scope?: WorkScope
}

type AdminPriceSummary = {
  clientPays: number
  lawhiveRake: number
  lawhiveRakePercentage: number
  solicitorEarnsAfterRake: number
  solicitorEarnsAfterAllFees: number
}

export type PricingItem = {
  label: string
  amount: number
  type: 'credit' | 'debit'
  display: 'standard' | 'advertising-fee' | 'payment-processor'
  breakdown: PricingItem[]
}

export type PricingSummary = {
  items: PricingItem[]
  net: number
}

type QuoteScopeSummary = {
  includes: string[]
  excludes: string[]
}

export const calculatePlatformFee = (clientPays: number, solicitorReceives: number) => clientPays - solicitorReceives
export const calculateFeePercentage = (clientPays: number, solicitorReceives: number) => Math.round((clientPays - solicitorReceives)*100/clientPays)

export const calculateAdminPriceSummary = (quote: DraftQuote): AdminPriceSummary => {
  const items = quote.items

  const clientPays = items.reduce((prev, cur) => prev + cur.clientPays || 0, 0)

  const solicitorEarnsAfterRake = items.reduce((prev, cur) => prev + cur.solicitorReceives || 0, 0)

  const lawhiveRake = calculatePlatformFee(clientPays, solicitorEarnsAfterRake)
  const lawhiveRakePercentage = calculateFeePercentage(clientPays, solicitorEarnsAfterRake)

  const paymentProcessorFees = clientPays * 0.014 + 20

  const solicitorEarnsAfterAllFees = solicitorEarnsAfterRake - paymentProcessorFees

  return {
    clientPays,
    lawhiveRake,
    lawhiveRakePercentage,
    solicitorEarnsAfterRake,
    solicitorEarnsAfterAllFees,
  }
}

const calculateNet = (items: PricingItem[]) =>
  items.reduce((prev, cur) => cur.type === 'debit' ? prev + cur.amount : prev - cur.amount, 0)

export const calculatePricingSummary = (quote: DraftQuote): PricingSummary => {
  const clientPays = quote.items.reduce((prev, cur) => prev + cur.clientPays || 0, 0)

  const solicitorEarnsAfterRake = quote.items.reduce((prev, cur) => prev + cur.solicitorReceives || 0, 0)

  const lawhiveRake = calculatePlatformFee(clientPays, solicitorEarnsAfterRake)

  const paymentProcessorFees = clientPays * 0.014 + 20
  const advertisingFee = lawhiveRake * 0.15 / 0.35

  const fees: PricingItem[] = [
    {
      label: 'Payment Processor Fees',
      amount: paymentProcessorFees,
      type: 'credit',
      display: 'payment-processor',
      breakdown: [],
    },
    {
      label: 'Lawhive Fees',
      amount: lawhiveRake,
      type: 'credit',
      display: 'standard',
      breakdown: !quote.isLeadFromAds ? [] : [
        {
          label: 'Platform Fee',
          amount: lawhiveRake - advertisingFee,
          type: 'credit',
          display: 'standard',
          breakdown: [],
        },
        {
          label: 'Introductory Advertising Fee',
          amount: advertisingFee,
          type: 'credit',
          display: 'advertising-fee',
          breakdown: [],
        },
      ]
    }
  ]

  const pricing: PricingItem[] = [
    {
      label: 'Client Fee Quote',
      amount: clientPays,
      type: 'debit',
      display: 'standard',
      breakdown: [],
    },
    {
      label: 'Fee Deductions',
      amount: Math.abs(calculateNet(fees)),
      type: 'credit',
      display: 'standard',
      breakdown: fees,
    },
  ]

  return {
    items: pricing,
    net: calculateNet(pricing)
  }
}

export const calculateClientPaysTotal = (items: WorkItems): number =>
  items.reduce((prev, cur) => prev + cur.clientPays || 0, 0)

export const calculateWorkScopeSummary = (items: WorkItems): QuoteScopeSummary => {
  const includes = items.reduce((prev, cur) => cur.scope ? [...prev, ...cur.scope.includes] : prev, [] as string[])
  const excludes = items.reduce((prev, cur) => cur.scope ? [...prev, ...cur.scope.excludes] : prev, [] as string[])

  return {
    includes,
    excludes
  }
}
