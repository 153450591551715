/* eslint-disable jsx-a11y/anchor-is-valid */
import tw, { styled } from 'twin.macro'

interface PaginationButtonProps {
  pageNumber: number
  isActive: boolean
  gotoPage: (pageIndex: number) => void
}

export const PaginationButton = ({ isActive, pageNumber, gotoPage }: PaginationButtonProps) => {
  return (
    <PaginationLink onClick={() => gotoPage(pageNumber - 1)} isActive={isActive}>
      {pageNumber}
    </PaginationLink>
  )
}

const PaginationLink = styled.a(({ isActive }: { isActive: boolean }) => [
  tw`hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium cursor-pointer`,
  isActive
    ? tw`z-10 bg-indigo-50 border-indigo-500 text-indigo-600`
    : tw`bg-white border-gray-300 text-gray-500 hover:bg-gray-50`,
])
