import { FC, ReactNode } from "react"
import { SkeletonBlock, SkeletonSingleLine } from "../../elements/Skeleton"
import { PageTitleBar, ScenePanel, ScrollingChildrenContainer } from "../Layout"
import tw from 'twin.macro'

type DetailsSubshellProps = {
  header: ReactNode
  content: ReactNode
}

export const DetailsSubshell: FC<DetailsSubshellProps> = ({ header, content }) => (
  <ScrollingChildrenContainer>
    <ScenePanel>
      <div tw="space-y-4 sm:space-y-6">
        {header}

        <hr />

        {content}
      </div>
    </ScenePanel>
  </ScrollingChildrenContainer>
)


export const BlankSubshell: FC = ({ children, ...rest }) => (
  <ScrollingChildrenContainer>
    <ScenePanel {...rest}>
      {children}
    </ScenePanel>
  </ScrollingChildrenContainer>
)


export const DetailsSubshellSkeleton: FC = ({ children }) => (
  <>
    <ScrollingChildrenContainer>
      <ScenePanel>
        <div tw="space-y-4 sm:space-y-6">
          <SkeletonSingleLine tw="w-32 bg-gray-200" />

          <div tw="flex flex-row">
            <SkeletonBlock tw="mr-4 w-16 bg-gray-200" />
            <div>
              <SkeletonSingleLine tw="bg-gray-200 w-48 sm:w-96" />
              <SkeletonSingleLine tw="mt-2 bg-gray-200 w-24 sm:w-48" />
            </div>
          </div>

          <hr />

          {children}
        </div>
      </ScenePanel>
    </ScrollingChildrenContainer>
  </>
)

export const ContentLayoutFlow = tw.div`flex flex-col gap-y-4 sm:gap-y-8`
