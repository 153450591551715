import "twin.macro"

import { Spinner } from "packages/app/src/elements/Loading"
import { Card, CardContent, InfoMessage } from "../../../elements"
import { PanelGrid } from "../../../elements/Layout"
import { useQuoteStatus } from "../hooks/useQuoteStatus"
import { QuoteLineItemsTable } from "../components/QuoteLineItemsTable"
import { QuoteScopesTable } from "../components/QuoteScopesTable"
import { SolicitorEarningsSummary } from "../components/SolicitorEarningsSummary"
import { ClientQuoteSummary } from "../components/ClientQuoteSummary"
import { SolicitorPaymentsTable } from "../components/SolicitorPaymentsTable"
import { OpenPaymentDashboardLink } from "packages/app/src/elements/solicitor/OpenPaymentDashboardLink"
import {
  SolicitorOnboardingProvider,
  useSolicitorOnboarding
} from "packages/app/src/scenes/solicitor/SolicitorOnboarding"
import { isSome } from "fp-ts/es6/Option"

export const SolicitorPaymentsView = () => {
  const { getQuotes, firstQuote } = useQuoteStatus()

  if (getQuotes.isLoading) {
    return <Spinner />
  }

  if (firstQuote) {
    return (
      <PanelGrid>
        <SolicitorPaymentsTable quote={firstQuote} />

        <SolicitorOnboardingProvider>
          <SolicitorPayoutExplainerMessage />
        </SolicitorOnboardingProvider>

        <QuoteLineItemsTable
          priceLabel={"Fee"}
          lineItems={firstQuote.lineItems}
        />

        {firstQuote.scopes.length > 0 && (
          <Card>
            <CardContent>
              <QuoteScopesTable scopes={firstQuote.scopes} />
            </CardContent>
          </Card>
        )}

        <div tw="flex flex-col gap-2">
          <h3 tw="font-semibold">Client Payment</h3>
          <ClientQuoteSummary quote={firstQuote} />
        </div>

        <div tw="flex flex-col gap-2">
          <h3 tw="font-semibold">Your Earnings</h3>
          <SolicitorEarningsSummary quote={firstQuote} />
        </div>
      </PanelGrid>
    )
  } else {
    return null
  }
}

const SolicitorPayoutExplainerMessage = () => {
  const { accountType } = useSolicitorOnboarding()

  return (
    <InfoMessage title="Note: Payments take at least 7 days to be paid out to your nominated bank account.">
      {isSome(accountType) && accountType.value === "express" ? (
        <p tw="mt-2 max-w-2xl text-sm text-indigo-500">
          For more details <OpenPaymentDashboardLink label={"click here"} /> to
          view your payout dashboard.
        </p>
      ) : null}
    </InfoMessage>
  )
}
