import { PriceUnits } from "packages/app/src/lib/Primitives"
import { QuoteEntityV2, PricedLawhiveDiscount, Quote } from "../types"
import { QuoteSummaryTable, SummaryItemProps } from "./QuoteSummaryTable"
import * as A from "fp-ts/Array"
import { matchStringUnion } from "packages/app/src/lib/utils"

type ClientQuoteSummaryProps = {
  quote: QuoteEntityV2
}

const isClientDiscount = (d: PricedLawhiveDiscount) => d.side === "payer"
const getClientDiscounts = A.filter(isClientDiscount)

const produceClientDiscountText = (d: PricedLawhiveDiscount) =>
  matchStringUnion(d.details.type, {
    absolute: `Discount`,
    percentage: `Discount (${d.details.amount}%)`
  })

export const ClientQuoteSummary = ({ quote }: ClientQuoteSummaryProps) => {
  // TODO Make backend driven
  const lineItemsTotal = quote.lineItems.reduce(
    (p, c) => (p + c.amount) as PriceUnits,
    0 as PriceUnits
  )
  const clientDiscounts = getClientDiscounts(quote.price.discounts)

  const items: SummaryItemProps[] = A.isNonEmpty(clientDiscounts)
    ? [
        { name: "Subtotal", amount: lineItemsTotal },
        ...clientDiscounts.map((d) => ({
          name: produceClientDiscountText(d),
          amount: d.amount,
          isHighlighted: true,
          isNegative: true
        })),
        { name: "Total paid", amount: quote.price.payableAmount, isBold: true }
      ]
    : [{ name: "Total paid", amount: quote.price.payableAmount, isBold: true }]

  return <QuoteSummaryTable items={items} />
}
