/* eslint-disable jsx-a11y/anchor-is-valid */
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { PaginationButton } from './PaginationButton'
import tw from 'twin.macro'
import { useEffect } from 'react'

interface PaginationProps {
  rowCount: number
  pageSize: number
  pageIndex: number
  pageCount: number
  nextPage: () => void
  previousPage: () => void
  gotoPage: (pageIndex: number) => void
}

//TODO: If pageCount is over 10, rework pagination buttons
export const Pagination = ({
  rowCount,
  pageSize,
  pageCount,
  pageIndex,
  nextPage,
  previousPage,
  gotoPage,
}: PaginationProps) => {
  return (
    <div tw="bg-white px-4 py-3 flex items-center w-full justify-between border-t border-gray-200 sm:px-6 rounded-lg mt-2">
      <div tw="flex-1 flex justify-between md:hidden">
        <a
          href="#"
          tw="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          onClick={() => previousPage()}
        >
          Previous
        </a>
        <a
          href="#"
          tw="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          onClick={() => nextPage()}
        >
          Next
        </a>
      </div>
      <div tw="hidden md:flex-1 md:flex md:items-center md:justify-between">
        <div>
          <p tw="text-sm text-gray-700">
            Showing {' '}
            <span tw="font-medium">{rowCount}</span> results
          </p>
        </div>
        <div>
          <nav tw="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            <a
              href="#"
              tw="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              onClick={() => previousPage()}
            >
              <span tw="sr-only">Previous</span>
              <ChevronLeftIcon tw="h-5 w-5" aria-hidden="true" />
            </a>
            {[...Array(pageCount)].map((_, index) => (
              <PaginationButton gotoPage={gotoPage} key={index} pageNumber={index + 1} isActive={index === pageIndex} />
            ))}
            <a
              href="#"
              tw="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              onClick={() => nextPage()}
            >
              <span tw="sr-only">Next</span>
              <ChevronRightIcon tw="h-5 w-5" aria-hidden="true" />
            </a>
          </nav>
        </div>
      </div>
    </div>
  )
}
