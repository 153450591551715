import React, { FC } from 'react'
import tw, { styled } from 'twin.macro'

export const PageContainer = tw.div`flex flex-col max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 w-full`
export const ContentContainer = tw.div`py-4 sm:py-8 lg:mt-8`

export const ScenePanel = tw(PageContainer)`py-4 sm:py-6 flex-1`
// export const ScenePanel = PageContainer

export const ScrollingChildrenContainer = tw.div`flex flex-1 flex-col overflow-auto`

export const PageTitleBar: FC = (props) => (
  <header tw="bg-white shadow z-20">
    <PageContainer tw="py-3 px-4 sm:py-5 sm:px-6 lg:px-8" {...props} />
  </header>
)

export const PageTitle = styled.h1`
  ${tw`text-sm sm:text-lg font-bold leading-tight text-gray-900`}
`
