import { RadioGroup } from "packages/app/src/elements/RadioGroup"
import { FC } from "react"
import { Controller, useForm } from "react-hook-form"
import 'twin.macro'
import { FormFieldNew, TextArea } from "../../../../elements"
import { FormButtonGroup } from "../../../../elements/Form"
import { Button } from "../../../../elements/Button"
import { useFormPersist } from "../../../../lib/useFormPersist"

type ReasonKey = 'no-response' | 'wrong-solicitor' | 'not-legal-matter' | 'unavailable' | 'other'

export type CantQuoteFormResult = {
  reasonKey: ReasonKey
  other?: string
}


export type CantQuoteFormProps = {
  callId: string
  loading?: boolean
  onComplete: (result: CantQuoteFormResult) => void
}

export const CantQuoteForm: FC<CantQuoteFormProps> = ({ callId, loading, onComplete }) => {
  const { control, watch, register, errors, handleSubmit, reset } = useForm<CantQuoteFormResult>({
    defaultValues: {}
  })

  useFormPersist(`${callId}/cant-quote`, { watch, reset }, {
    storage: window.localStorage,
  });

  const state = watch()

  return (
    <form tw="space-y-8" onSubmit={handleSubmit(onComplete)}>
      <FormFieldNew
        id="other"
        error={errors.reasonKey}
        control={(
          <Controller
            name="reasonKey"
            control={control}
            rules={{ required: 'Please select an answer.' }}
            render={({ onChange, value }) => (
              <RadioGroup<ReasonKey>
                items={[
                  {
                    label: `Client didn't respond`,
                    description: `The client didn't answer the phone or was unavailable.`,
                    value: 'no-response',
                  },
                  {
                    label: `Outside your practice area`,
                    description: `You weren't suitable to assess this type of work.`,
                    value: 'wrong-solicitor',
                  },
                  {
                    label: `Too busy for this matter`,
                    description: `You are unavailable or can't meet the timeline needed for this work.`,
                    value: 'unavailable',
                  },
                  {
                    label: `Not a legal matter`,
                    description: `The client didn't have a legal matter.`,
                    value: 'not-legal-matter',
                  },
                  {
                    label: `Something else`,
                    description: `Please provide details below.`,
                    value: 'other',
                  },
                ]}
                groupName='option'
                setValue={onChange}
                value={value}
              />
            )}
          />
        )}
      />

      <FormFieldNew
        id="other"
        label={state.reasonKey === 'other'
          ? "Please provide details"
          : `(Optional) Please provide details`
        }
        error={errors.other}
        control={(
          <TextArea
            id="other"
            name="other"
            rows={5}
            ref={register({
              required: state.reasonKey === 'other'
                ? 'Please enter details'
                : false
            })}
            placeholder={`Enter reason details...`}
          />
        )}
        hint={`This will be shown to the Lawhive team for feedback.`}
      />

      <FormButtonGroup>
        <Button isLoading={loading}>
          Complete
        </Button>
      </FormButtonGroup>
    </form>
  )
}
