import { LawhiveEvent } from "../features/analytics/types"
import posthog from 'posthog-js'

export const useAnalytics = () => {
  const track = (eventName: string, data?: any) => {
    if (window.heap && window.heap.track) {
      window.heap.track(eventName, data)
    }

    posthog.capture(eventName, data);
  }

  const createEventName = <I extends string, J>(event: LawhiveEvent<I, J>) => {
    return `${event.target}:${event.action}`
  }

  const trackEvent = <I extends string, J>(event: LawhiveEvent<I, J>) => {
    const eventName = createEventName(event)
    console.log(">> TRACK", eventName, event.properties)
    track(eventName, event.properties)
  }

  const identify = (id: string) => {
    if (window.heap && window.heap.identify) {
      window.heap.identify(id)
    }
  }

  return {
    track,
    identify,
    trackEvent,
  }
}
