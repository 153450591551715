import axios from 'axios'
import { useAsync } from './useAsync'

export const performUpload = (file: File, url: string, fields: object) => {
  const formData = new FormData()
  Object.entries(fields).forEach(([key, value]) => {
    formData.append(key, value)
  })

  formData.append('file', file)

  return axios.post(url, formData, {})
}

export const downloadFile = (downloadName: string) => (url: string) => {
  const a = document.createElement('a')
  a.href = url
  a.download = downloadName || 'download'
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url)
      a.removeEventListener('click', clickHandler)
    }, 150)
  }
  a.addEventListener('click', clickHandler, false)
  a.click()
  return a
}

export const downloadFileVoid = (downloadName: string) => (url: string) => {
  const a = document.createElement('a')
  a.href = url
  a.download = downloadName || 'download'
  const clickHandler = () => {
    URL.revokeObjectURL(url)
    a.removeEventListener('click', clickHandler)
  }
  a.addEventListener('click', clickHandler, false)
  a.click()
}
