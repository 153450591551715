import * as O from "fp-ts/lib/Option"
import { PayoutTransaction } from "../../hooks/useGetPayoutDetails"
import { FC } from "react"
import { pipe } from "fp-ts/lib/function"
import { Card, CardHeader, Message } from "../../elements"
import { PlainDataTable, TableColumn } from "../../elements/table/PlainDataTable"
import { InformationCircleIcon } from "@heroicons/react/outline"

type PayoutDetailsTableProps = {
  data: O.Option<PayoutTransaction[]>
  columns: TableColumn<PayoutTransaction>[]
}

export const PayoutDetailsTable: FC<PayoutDetailsTableProps> = ({data, columns}) => {
  return pipe(
    data,
    O.fold(
      () => (<Message
          colour="primary"
          icon={<InformationCircleIcon/>}
          title="Breakdown not available"
      >
        This payout was manually initiated, and as such we are unable to display a breakdown for it.
      </Message>),
      (d) =>
        (<Card>
          <CardHeader
            title="Included Payments"
            supportingText="This payout is comprised of the following payments and charges."
          />
        <PlainDataTable columns={columns} data={d}/>
        </Card>)
    )
  )
}
