import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"

import { ROUTES } from "../../layout/Navigation"
import { useRequireAdmin } from "../../lib/useRequireAuth"
import { AdminDashboard } from "./AdminDashboard"
import { AdminKycDetailsScene } from "./kyc/AdminKycDetailsScene"
import { AdminKycListScene } from "./kyc/AdminKycListScene"

export const AdminSwitch = () => {
  useRequireAdmin()

  return (
    <Switch>
      <Route path={ROUTES.admin.kyc.details(':userId')} component={AdminKycDetailsScene} />
      <Route path={ROUTES.admin.kyc.root} component={AdminKycListScene} />
      <Route exact={true} path={ROUTES.admin.root} component={AdminDashboard} />

      <Redirect to={ROUTES.admin.root} />
    </Switch>
  )
}
