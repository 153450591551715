import React from 'react'
import tw from 'twin.macro'

import { Card, CardContent } from './Card'

export const SkeletonSingleLine = tw.div`h-4 rounded-md bg-gray-200 animate-pulse`
export const SkeletonBlock = tw.div`h-12 rounded-md bg-gray-200 animate-pulse`

export const SkeletonCard = () => (
  <Card>
    <CardContent>
      <SkeletonSingleLine tw="mb-4" />
      <SkeletonSingleLine tw="mb-4" />
      <SkeletonSingleLine />
    </CardContent>
  </Card>
)
