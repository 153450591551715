
import { solicitorRequestStripeDashboardLinkCommand, SolicitorRequestStripeDashboardLinkCommandMutation } from "@lawhive/generated-api"

import { useAsync } from "../../lib/useAsync"
import { graphql } from "../../lib/utils"

export const useOpenPaymentDashboard = () => useAsync(
  () => graphql<SolicitorRequestStripeDashboardLinkCommandMutation>({
    query: solicitorRequestStripeDashboardLinkCommand
  }).then(r => r.data?.solicitorRequestStripeDashboardLinkCommand.url
    ? Promise.resolve(r.data.solicitorRequestStripeDashboardLinkCommand.url)
    : Promise.reject('Could not get dashboard URL')
  ).then(url => {
    Object.assign(document.createElement('a'), { target: '_blank', href: url}).click()
  })
)
