import { pipe } from "fp-ts/es6/function"
import { fromNullable, map as mapOption, none, Option, some } from "fp-ts/es6/Option"

import { CreateMessageMutation, Message, UserType } from "@lawhive/generated-api"
import { GraphQLResult } from "../../lib/utils"
import { CaseMessage } from "./useGetCase"
import { parseISO } from "date-fns"
import { castTo, fetchParsedJSON } from "../../lib/fetch"
import { useApiFetch } from "../../lib/useApiClient"
import { useCaseInteractionEvent } from "../../features/analytics/useCaseInteractionEvent"

export const parseMessageResult = (input: GraphQLResult<CreateMessageMutation>): Option<CaseMessage> => pipe(
  fromNullable(input.data?.createMessage),
  mapOption((m): CaseMessage => ({
    caseId: m.caseId,
    author: m.author ? some({
      displayName: fromNullable(m.author?.displayName),
      id: m.authorId,
      type: m.author?.type || UserType.client,
      phoneNumber: fromNullable(m.author?.phoneNumber)
    }) : none,
    authorId: m.authorId,
    content: m.content,
    createdAt: parseISO(m.createdAt),
    id: m.id,
    updatedAt: parseISO(m.updatedAt)
  }))
)

type CreateMessageCommand = {
  author: string,
  caseId: string,
  type: string,
  content: string
}

export const useCreateMessage = () => {
  const api = useApiFetch(fetchParsedJSON(castTo<Message>()))

  const triggerMessageEvent = useCaseInteractionEvent()

  
  return (command: CreateMessageCommand) => {
    triggerMessageEvent({caseId: command.caseId, interactionType: "sent-message"})
    return api(`case-messages/create-message`, {
    method: "POST",
    body: JSON.stringify(command)
  })}
}
