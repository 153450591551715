import 'twin.macro'
import { FC } from 'react'

import { Card, CardContent, CardTitle, FormFieldNew, TextInput } from '../../../../elements'
import { ModalText } from '../../../../elements/Modal'
import { Button } from '../../../../elements/Button'

import { AssessmentCallUIDetailsResponse } from '../../hooks/useAssessmentCallDetails'
import { addMinutes, format } from 'date-fns'
import { AssessmentCallRejectionReason } from '../../hooks/useRejectAssessmentCall'
import { Controller, useForm } from 'react-hook-form'
import { RadioGroup } from 'packages/app/src/elements/RadioGroup'
import { matchStringUnion } from 'packages/app/src/lib/utils'

type BookCallModalProps = {
  isLoading: boolean,
  call: AssessmentCallUIDetailsResponse
  onCancel: () => void,
  onReject: (reason: AssessmentCallRejectionReason) => void,
}

type ReasonKey = AssessmentCallRejectionReason['tag']

type RejectCallFormResult = {
  reasonKey: ReasonKey
  productFreeformEstimate?: string
  details?: string
}

const convertFormToResult = (input: RejectCallFormResult): AssessmentCallRejectionReason => matchStringUnion(input.reasonKey, {
  WrongSolicitor: AssessmentCallRejectionReason.WrongSolicitor({
    productFreeformEstimate: input.productFreeformEstimate || '',
    details: input.details
  }),
  Other: AssessmentCallRejectionReason.Other({
    details: input.details || ''
  })

})
export const RejectCallModal: FC<BookCallModalProps> = ({ onReject, call, isLoading }) => {
  const { control, errors, watch, register, handleSubmit } = useForm<RejectCallFormResult>()

  const submit = (input: RejectCallFormResult) => {
    const result = convertFormToResult(input)
    onReject(result)
  }

  const state = watch()

  return (
    <form tw="w-full sm:width[600px] max-w-xl" onSubmit={handleSubmit(submit)}>
      <Card>
        <CardContent tw="space-y-4">
          <CardTitle>
            Why are you rejecting this call?
          </CardTitle>

          <FormFieldNew
            id="reasonKey"
            error={errors.reasonKey}
            control={(
              <Controller
                name="reasonKey"
                control={control}
                rules={{ required: 'Please select an answer.' }}
                render={({ onChange, value }) => (
                  <RadioGroup<ReasonKey>
                    items={[
                      {
                        label: `This work is in a legal area I don't serve`,
                        value: 'WrongSolicitor',
                      },
                      {
                        label: `Another reason`,
                        value: 'Other',
                      },
                    ]}
                    groupName='option'
                    setValue={onChange}
                    value={value}
                  />
                )}
              />
            )}
          />

          {matchStringUnion(state.reasonKey, {
            WrongSolicitor: () => (
              <>
                <FormFieldNew
                  id="productFreeformEstimate"
                  label={"What does this client need that you can't provide?"}
                  error={errors.productFreeformEstimate}
                  control={(
                    <TextInput
                      id="productFreeformEstimate"
                      name="productFreeformEstimate"
                      type="text"
                      ref={register({ required: 'Please enter an answer.' })}
                    />
                  )}
                />
                <FormFieldNew
                  id="details"
                  label={"Any other details?"}
                  error={errors.details}
                  control={(
                    <TextInput
                      id="details"
                      name="details"
                      type="text"
                      ref={register}
                    />
                  )}
                />
              </>
            ),
            Other: () => (
              <>
                <FormFieldNew
                  id="details"
                  label={"Please provide more details on why you are rejecting"}
                  error={errors.details}
                  control={(
                    <TextInput
                      id="details"
                      name="details"
                      type="text"
                      ref={register({ required: 'Please enter an answer.' })}
                    />
                  )}
                />
              </>
            )
          })}

        </CardContent>

        <CardContent tw="pt-0">
          <Button variant='positive' type='submit' isLoading={isLoading}>
            Reject Call
          </Button>
        </CardContent>
      </Card>
    </form>
  )
}
