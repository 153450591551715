import React from "react"
import { FC } from "react"
import { useUser } from "../contexts/User"

export const SolicitorOnly: FC = ({ children }) => {
  const { isSolicitor } = useUser()

  return isSolicitor
    ? <>{children}</>
    : null
}

export const SolicitorOrAdminOnly: FC = ({ children }) => {
  const { isSolicitor, isAdmin } = useUser()

  return isSolicitor || isAdmin
    ? <>{children}</>
    : null
}

export const AdminOnly: FC = ({ children }) => {
  const { isAdmin } = useUser()

  return isAdmin
    ? <>{children}</>
    : null
}

export const ClientOnly: FC = ({ children }) => {
  const { isSolicitor, isAdmin } = useUser()

  return !(isSolicitor || isAdmin)
    ? <>{children}</>
    : null
}
