import * as D from "io-ts/lib/Decoder"
import { useCallCompleteEvent } from "packages/app/src/features/analytics/useCallCompleteEvent"
import { ADT } from "../../../lib/adt"
import { castTo, fetchParsedJSON } from "../../../lib/fetch"
import { UUID } from "../../../lib/Primitives"
import { useApiFetch } from "../../../lib/useApiClient"
import { useFromTaskEither } from "../../../lib/useAsync"
import { AssessmentCallUIDetailsResponse } from "./useAssessmentCallDetails"

export const NotAssessedReason = ADT({
  TooBusy: D.partial({ details: D.string }),
  NotSuitable: D.partial({ details: D.string }),
  Other: D.struct({ details: D.string })
})
export type NotAssessedReason = D.TypeOf<typeof NotAssessedReason>

export const AssessmentCallResult = ADT({
  NoAnswer: undefined,
  Assessed: UUID,
  NotAssessed: NotAssessedReason
})

export type AssessmentCallResult = D.TypeOf<typeof AssessmentCallResult>

export const CompleteCallCommand = D.struct({
  // notes: D.string,
  result: AssessmentCallResult
})
export type CompleteCallCommand = D.TypeOf<typeof CompleteCallCommand>

export const useCompleteCall = () => {
  const api = useApiFetch(fetchParsedJSON(castTo<void>()))

  return (id: string, command: CompleteCallCommand) => api(`assessment-calls/${id}/complete`, {
    method: 'POST',
    body: JSON.stringify(command)
  })
}

export const useCompleteCallWithAnalytics = () => {
  const complete = useCompleteCall()
  const track = useCallCompleteEvent()
  return (call: AssessmentCallUIDetailsResponse, command: CompleteCallCommand) => {
    track({ callId: call.id, status: call.status })
    return complete(call.id, command)
  }
}
