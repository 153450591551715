import React, { FC } from 'react'
import { NavLink } from 'react-router-dom'
import tw, { css, styled } from 'twin.macro'

// TODO Remove in favour of layout/sidebarlayout
export const SidebarLayout: FC<{ menu: JSX.Element, content: JSX.Element }> = ({ menu, content, ...rest }) => (
  <div tw="flex flex-col md:(flex-row) md:gap-8" {...rest}>
    <div tw="md:(w-1/4)">
      {menu}
    </div>
    <div tw="w-full md:(w-3/4)">
      {content}
    </div>
  </div>
)


export const TabMenu = tw.div`flex flex-row md:mx-0 overflow-auto border-b border-gray-200`

const tabBase = tw`flex text-gray-500 flex-shrink-0 text-center items-center pb-4 border-b-2 border-transparent text-sm font-medium pb-3 first:ml-0 mx-4 px-2 tracking-wide`

export const TabLink = styled(NavLink)<{ active?: boolean }>`
  ${tabBase}

  &.active {
    ${tw`border-pink-500 text-pink-600`}
  }

  &:not(.active) {
    ${tw``}
  }

  ${({ active }) => active && tw`border-pink-500 text-pink-600`}
`

export const SidebarMenuContainer = tw(TabMenu)`md:(flex-col border-0)`

export const SidebarTitle = tw.h3`text-xs text-gray-500 border-b border-gray-200 mb-4 md:(text-sm mb-4) pb-2`

export const SidebarSublist = tw.div`flex flex-row sm:flex-col`
export const SidebarSection = tw.div`flex sm:flex-col flex-col flex-shrink-0 mr-6 last:(mr-0 mb-0) sm:(mr-0 mb-8)`

export const SidebarLink = styled(TabLink)(({ active }) => [
  tw`md:(font-semibold px-4 mx-0 py-2 last:mb-0 text-gray-500 text-left border-0 mb-2 rounded-md)`,
  css`
    &.active {
      ${tw`text-gray-500 md:text-gray-500`}

      ${!active && tw`border-transparent md:hover:(text-gray-900)`}
      ${active && tw`border-pink-500 text-pink-600 md:text-gray-700`}
    }
  `,
  active && tw`md:bg-gray-200`
])

export type SidebarMenuItem = SidebarSubsection | SidebarMenuLink

type SidebarSubsection = {
  label: string
  items: SidebarMenuLink[]
}

type SidebarMenuLink = {
  label: string
  icon?: JSX.Element
  active?: boolean
  to: string
}


const SidebarItem: FC<{ item: SidebarMenuLink }> = ({ item }) => (
  <SidebarLink
    type={'button'}
    to={item.to}
    active={item.active}
  >
    {item.icon && <span tw="mr-2 h-4 w-4">{item.icon}</span>}
    {item.label}
  </SidebarLink>
)

const SidebarSubsection: FC<{ section: SidebarSubsection }> = ({ section }) => (
  <SidebarSection>
    <SidebarTitle>
      {section.label}
    </SidebarTitle>

    <SidebarSublist>
      {section.items.map((l, i) => <SidebarItem key={`item-${i}`} item={l} />)}
    </SidebarSublist>
  </SidebarSection>
)

export const SidebarMenu: FC<{ items: SidebarMenuItem[] }> = ({ items, ...rest }) => (
  <SidebarMenuContainer {...rest}>
    {items.map((i, j) => (
      'items' in i ? (
        <SidebarSubsection section={i} />
      ) : (
        <SidebarItem key={`item-${j}`} item={i} />
      )
    ))}
  </SidebarMenuContainer>
)
