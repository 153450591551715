import { CheckCircleIcon, ExclamationCircleIcon, ExclamationIcon, InformationCircleIcon, XIcon } from '@heroicons/react/outline'
import React, { FC } from 'react'
import tw, { styled } from 'twin.macro'
import { matchStringUnion } from '../lib/utils'

type ErrorMessageProps = {
  title: string
}

const MessageContainerOld = tw.div`px-4 py-3 sm:py-5 sm:px-5 rounded-lg flex-grow`
const MessageIcon = tw.svg`h-4 w-4 sm:h-6 sm:w-6 flex-shrink-0`
const MessageContent = tw.div`ml-3 sm:ml-5 text-xs sm:text-base`
const MessageTitleOld = tw.h3`text-sm sm:text-lg leading-6 font-semibold leading-tight`

const Note = tw.div`rounded-md px-4 py-2 text-xs sm:text-sm font-medium`

export const InfoNote = tw(Note)`bg-blue-100 text-blue-600`

export const WarnNote = tw(Note)`bg-yellow-100 text-yellow-600`

type MessageColour = 'primary' | 'green' | 'red' | 'yellow' | 'grey'

type MessageProps = {
  title?: React.ReactNode
  colour: MessageColour
  bold?: boolean
  icon?: React.ReactNode
  dismissable?: boolean
  onDismiss?: () => void
  textSize?: TextSize
}

const MessageContainer = styled.div<{ colour: MessageColour, bold?: boolean }>(({ colour, bold }) => [
  tw`flex flex-row p-3 sm:p-5 rounded-lg space-x-2 sm:space-x-4`,
  matchStringUnion(colour, {
    primary: bold ? tw`bg-indigo-600 text-white` : tw`border bg-indigo-50 border-indigo-300 text-indigo-600`,
    green: tw`bg-green-50 border border-green-300 text-green-600`,
    red: tw`bg-red-50 border border-red-300 text-red-600`,
    yellow: tw`bg-yellow-50 border border-yellow-300 text-yellow-600`,
    grey: tw`bg-gray-50 border border-gray-300 text-gray-600`,
  })
])

type TextSize = "small" | "medium" | "large"
const MessageTitle = styled.h3<{ colour: MessageColour, bold?: boolean, textSize: TextSize }>(({ colour, bold, textSize }) => [
  tw` font-semibold`,
  matchStringUnion(colour, {
    primary: bold ? tw`text-white` : tw`text-indigo-700`,
    green: tw`text-green-600`,
    red: tw`text-red-600`,
    yellow: tw`text-yellow-600`,
    grey: tw`text-gray-600`,
  }),
  matchStringUnion(textSize, {
    small: tw`text-xs sm:(text-base leading-tight)`,
    medium: tw`text-sm sm:(text-lg leading-tight)`,
    large: tw`text-lg sm:(text-xl leading-tight)`,
  })
])

export const Message: FC<MessageProps> = ({ icon, title, children, colour, bold, dismissable, onDismiss, textSize, ...props }) => (
  <MessageContainer colour={colour} bold={bold} {...props}>
    {icon && (
      <div tw="flex-shrink-0 h-4 w-4 mt-1 sm:(h-6 w-6 mt-0)">
        {icon}
      </div>
    )}

    <div tw="w-full space-y-2 text-xs sm:text-sm">
      <div tw="flex flex-row items-center">
        <div tw="flex-1">
          <MessageTitle colour={colour} bold={bold} textSize={textSize ? textSize : "medium"}>
            {title}
          </MessageTitle>
        </div>
        {dismissable && (
          <div>
            <button tw="h-5 w-5" type='button' onClick={onDismiss}>
              <XIcon />
            </button>
          </div>
        )}
      </div>
      {children && (
        <div>
          {children}
        </div>
      )}
    </div>
  </MessageContainer>
)

export const ErrorMessage: FC<Omit<MessageProps, 'colour'>> = (props) => (
  <Message colour='red' icon={props.icon || <ExclamationCircleIcon />} {...props} />
)
export const WarningMessage: FC<Omit<MessageProps, 'colour'>> = (props) => (
  <Message colour='yellow' icon={props.icon || <ExclamationIcon />} {...props} />
)

export const SuccessMessage: FC<Omit<MessageProps, 'colour'>> = (props) => (
  <Message colour='green' icon={props.icon || <CheckCircleIcon />} {...props} />
)

export const InfoMessage: FC<Omit<MessageProps, 'colour'>> = (props) => (
  <Message colour='primary' icon={props.icon || <InformationCircleIcon />} {...props} />
)

export const ErrorMessageOld: FC<ErrorMessageProps> = ({ title, children, ...props }) => (
  <MessageContainerOld tw="bg-red-50" {...props}>
    <div tw="flex flex-row items-center">
      <MessageIcon xmlns="http://www.w3.org/2000/svg" tw="text-red-400" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
      </MessageIcon>
      <MessageContent>
        <MessageTitleOld tw="text-red-600">
          {title}
        </MessageTitleOld>
        {children}
      </MessageContent>
    </div>
  </MessageContainerOld>
)

export const SuccessMessageOld: FC<{ title: string }> = ({ children, title, ...rest }) => (
  <MessageContainerOld tw="bg-green-50" {...rest}>
    <div tw="flex flex-row items-center">
      <MessageIcon xmlns="http://www.w3.org/2000/svg" tw="text-green-400" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
      </MessageIcon>
      <MessageContent>
        <MessageTitleOld tw="text-green-600">
          {title}
        </MessageTitleOld>
        {children}
      </MessageContent>
    </div>
  </MessageContainerOld>
)

export const InfoMessageOld: FC<{ title: string }> = ({ children, title, ...rest }) => (
  <MessageContainerOld tw="bg-blue-50" {...rest}>
    <div tw="flex flex-row items-center">
      <MessageIcon xmlns="http://www.w3.org/2000/svg" tw="text-blue-400" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
      </MessageIcon>
      <MessageContent>
        <MessageTitleOld tw="text-blue-600">
          {title}
        </MessageTitleOld>
        {children}
      </MessageContent>
    </div>
  </MessageContainerOld>
)

export const WarnMessage: FC<{ title: string }> = ({ children, title, ...rest }) => (
  <div tw="p-4 sm:p-5 bg-yellow-50 rounded-lg flex-grow" {...rest}>
    <div tw="flex flex-row items-center">
      <svg xmlns="http://www.w3.org/2000/svg" tw="h-6 w-6 text-yellow-400 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
      </svg>
      <MessageContent>
        <MessageTitleOld tw="text-yellow-600">
          {title}
        </MessageTitleOld>
        {children}
      </MessageContent>
    </div>
  </div>
)

const MessageText = tw.p`mt-1 sm:mt-3 font-medium text-xs sm:text-sm`

export const ErrorMessageText = tw(MessageText)`text-red-500`
export const WarnMessageText = tw(MessageText)`text-yellow-500`
export const SuccessMessageText = tw(MessageText)`text-green-500`
export const InfoMessageText = tw(MessageText)`text-blue-500`
