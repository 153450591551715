import { initial } from "@nll/datum/Datum"
import { refreshFold, pending } from "@nll/datum/DatumEither"
import { matchWildcard, WILDCARD } from "@practical-fp/union-types"
import React, { FC, useEffect } from "react"
import { useParams } from "react-router-dom"
import { AssessmentCallDetailsPanel } from "../../components/assessment-call/details/AssessmentCallDetailsPanel"
import { AssessmentCallsDetailsSkeleton } from "../../components/assessment-call/details/Skeleton"
import { AssessmentCallUIDetailsResponse, useAssessmentCallDetails } from "../../components/assessment-call/hooks/useAssessmentCallDetails"
import { ErrorMessage, ErrorMessageOld, Message } from "../../elements"
import { useCallCompleteEvent } from "../../features/analytics/useCallCompleteEvent"
import { ROUTES } from "../../layout/Navigation"
import { ErrorSubshell } from "../../layout/subshells/ErrorSubshell"
import { RequestError } from "../../lib/fetch"
import { useRouter } from "../../lib/useRouter"

export const AssessmentCallDetailsScene = () => {
  const { id } = useParams<{ id: string }>()
  const { status, execute } = useAssessmentCallDetails()
  const router = useRouter()

  const render = refreshFold(
    () => <AssessmentCallsDetailsSkeleton />,
    () => <AssessmentCallsDetailsSkeleton />,
    (e: RequestError) => matchWildcard(e, {
      ResponseError: e => e.statusCode === 401
        ? <ErrorSubshell title={`You don't have access to this call`} />
        : <ErrorSubshell title={`Error getting call details ${e.details}`} />,
      [WILDCARD]: () => <ErrorSubshell title={`Error getting call details, please check your network or refresh`} />
    }),
    (call: AssessmentCallUIDetailsResponse) => <AssessmentCallDetailsPanel
      call={call}
      onRefresh={() => execute(id)}
      onReject={() => router.replace(ROUTES.assessmentCalls.root({ rejectedClient: call.leadName }))}
    />
  )

  useEffect(() => { execute(id) }, [id])

  return render(status)
}
