import { isPending, isReplete, refreshFold } from "@nll/datum/DatumEither"
import * as O from "fp-ts/es6/Option"
import { isSome } from "fp-ts/es6/Option"
import { FC, useEffect } from "react"

import {
  solicitorRequestStripeDashboardLinkCommand,
  SolicitorRequestStripeDashboardLinkCommandMutation
} from "@lawhive/generated-api"
import { Card, CardContent } from "../../elements"
import { Spinner } from "../../elements/Loading"
import { SkeletonCard } from "../../elements/Skeleton"
import { useIsMonthlyBillingEnabled } from "../../hooks/useFeatureFlags"
import { ContentContainer, PageContainer } from "../../layout/Layout"
import { ContentLayoutFlow } from "../../layout/subshells/DetailsSubshell"
import { useAsync, useFromTaskEither } from "../../lib/useAsync"
import { graphql } from "../../lib/utils"
import { AccountStatusCardCell } from "./dashboard/AccountStatusCard"
import { ChargeAccountActionCell } from "./dashboard/ChargeAccountCell"
import { HelpCard } from "./dashboard/HelpCard"
import { SolicitorOnboardingProvider, useSolicitorOnboarding } from "./SolicitorOnboarding"
import { SolicitorOnboardingWizard } from "./SolicitorOnboardingWizard"
import "twin.macro"
import { useGetChargeAccount } from "../../hooks/useGetChargeAccount"
import { pipe } from "fp-ts/es6/function"
import { useRouter } from "../../lib/useRouter"
import { AffiliateLinksCell } from "./dashboard/AffiliateLinksCell"
import { PosthogFeatureFlag, usePosthog } from "../../contexts/Posthog"

export const SolicitorDashboard = () => {
  return (
    <SolicitorOnboardingProvider>
      <EnsureDataCollectedWizard />
    </SolicitorOnboardingProvider>
  )
}

const EnsureDataCollectedWizard: FC = () => {
  const { hasSubmitDetails: paymentDetailsSubmitted, state} = useSolicitorOnboarding()
  const monthlyBillingTE = useGetChargeAccount()

  const {status, execute} = useFromTaskEither(monthlyBillingTE)

  const { params: { success } } = useRouter<{ success: string }>()

  useEffect(() => {execute()}, [])

  const hasMonthlyBilling = pipe(
    status,
    refreshFold(
    () => O.none,
    () => O.none,
    () => O.none,
    a => pipe(a, O.map(b => b.model.tag === "Monthly" && !b.needsSubscription))
  ))

  return (
    <PageContainer>
      <ContentContainer>
        {
          !((isReplete(status)) && (isReplete(state))) ?
            <SkeletonCard/> :
            isSome(paymentDetailsSubmitted) && paymentDetailsSubmitted.value
          && isSome(hasMonthlyBilling) && hasMonthlyBilling.value
              && success !== 'true'
          ? <OnboardedView/>
          : <SolicitorOnboardingWizard/>}



      </ContentContainer>
    </PageContainer>
  )
}

const OnboardedView = () => {
  const { hasPayoutsEnabled } = useSolicitorOnboarding()

  const isMonthlyBillingEnabled = useIsMonthlyBillingEnabled()

  const {isFeatureEnabled} = usePosthog()

  const isSolicitorAffiliateLinksEnabled = isFeatureEnabled(PosthogFeatureFlag.SolicitorAffiliateLinks())

  return (
    <ContentLayoutFlow>
      {isSome(hasPayoutsEnabled) && !hasPayoutsEnabled.value && (
        <CheckStripePanel />
      )}

      {isMonthlyBillingEnabled && <ChargeAccountActionCell />}
      {isSolicitorAffiliateLinksEnabled
        && <AffiliateLinksCell/>}
      <HelpCard />
      {/*<AccountStatusCardCell />*/}
    </ContentLayoutFlow>
  )
}

const CheckStripePanel = () => {
  const { accountType } = useSolicitorOnboarding()

  const { status, execute } = useAsync(
    () => graphql<SolicitorRequestStripeDashboardLinkCommandMutation>({
      query: solicitorRequestStripeDashboardLinkCommand
    }).then(r => r.data?.solicitorRequestStripeDashboardLinkCommand.url
      ? Promise.resolve(r.data.solicitorRequestStripeDashboardLinkCommand.url)
      : Promise.reject('Could not get dashboard URL')
    ).then(url => {
      Object.assign(document.createElement('a'), { target: '_blank', href: url}).click()
    })
  )


  return (
    <Card tw="bg-indigo-600">
      <CardContent>
        <div tw="flex flex-col items-start justify-between flex-wrap">
          <div tw="flex-1 flex flex-col">
            <p tw="font-medium text-white">
              You're ready to start taking cases!
            </p>
            <p tw="mt-2 font-medium text-white">
              However, our payment provider hasn't finished verifying your identity. You won't receive payouts to your bank until this completed.
            </p>
          </div>
          <div tw="self-end order-3 flex-shrink-0 sm:order-2 sm:mt-4 mt-2">
            <div tw="rounded-md shadow-sm">
              {isSome(accountType) && accountType.value === 'express' && (
                <button
                  tw="flex items-center justify-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-indigo-600 bg-white hover:text-indigo-500 focus:outline-none focus:ring transition ease-in-out duration-150"
                  onClick={execute}
                  disabled={isPending(status)}
                >
                  {isPending(status) ? <Spinner tw="h-5 w-32 text-indigo-500" /> : <>Fix this on Stripe</>}
                </button>
              )}
              {isSome(accountType) && accountType.value === 'standard' && (
                <a href={'https://dashboard.stripe.com'} target='_blank' rel='noopener noreferrer' tw="flex items-center justify-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-indigo-600 bg-white hover:text-indigo-500 focus:outline-none focus:ring transition ease-in-out duration-150">
                  Go to Stripe
                </a>
              )}
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}
