import { matchExhaustive } from "@practical-fp/union-types"
import Tippy from "@tippyjs/react"
import "tippy.js/dist/tippy.css"
import { parseISO } from "date-fns"
import "twin.macro"

import { Badge } from "packages/app/src/elements/Badge"
import { PaymentMethodDTO } from "packages/app/src/hooks/useGetQuotePricing"
import { QuoteEntityV2, QuotePayment } from "../types"
import { PaymentItem, PaymentsTable } from "./PaymentsTable"
import { match } from "ts-pattern"
import { constUndefined } from "fp-ts/function"

type ClientPaymentsTableProps = {
  quote: QuoteEntityV2
}

const parsePayment = (payment: QuotePayment): PaymentItem => ({
  amount: payment.payment.amount,
  paidOn: parseISO(payment.createdAt),
  title: match(payment.type.type)
    .with("ClientAuthorisation", () => "Payment Hold")
    .with("SolicitorPayment", () => "Payment")
    .with("OffPlatformPayment", () => "Manual Payment")
    .exhaustive(),
  description: match(payment.type)
    .with({ type: "ClientAuthorisation" }, (a) => <AuthorisedPaymentTooltip isCancelled={a.isCancelled}/> )
    .with({ type: "SolicitorPayment" }, (a) => a.completedPayment && a.completedPayment?.paymentMethod && <PaymentMethod method={a.completedPayment.paymentMethod} />)
    .otherwise(constUndefined)
})

export const ClientPaymentsTable = ({ quote }: ClientPaymentsTableProps) => {
  const payments: PaymentItem[] = quote.quotePayments.map(parsePayment)

  return <PaymentsTable payments={payments} />
}

const AuthorisedPaymentTooltip = ({
  isCancelled
}: {
  isCancelled?: boolean
}) => (
  <div tw="flex flex-col items-start gap-1">
    {isCancelled && <Badge variant="yellow">Refunded</Badge>}
    <Tippy
      content={
        <span>
          Your payment is held until a solicitor is assigned to your case.{" "}
          <br />
          <br />
          If we cannot assign a solicitor to your case, we will refund your
          payment.
          <br />
        </span>
      }
    >
      <p tw="font-semibold underline w-auto inline-block hover:text-blue-600 text-blue-500 cursor-pointer">
        What is a held payment?
      </p>
    </Tippy>
  </div>
)

const PaymentMethod = ({ method }: { method: PaymentMethodDTO }) =>
  matchExhaustive(method, {
    Card: (pm) => (
      <>
        Card ({pm.brand} *{pm.last4})
      </>
    ),
    DirectDebit: (pm) => (
      <>
        Direct debit (*{pm.last4}, {pm.sortCode})
      </>
    ),
    BankTransfer: () => <>Bank Transfer</>,
    Klarna: () => <>Klarna</>,
    Other: () => null
  })
