import { createContext, ReactNode, useContext, useMemo } from "react"
import { QuoteStatusHook, useQuoteStatus } from "../hooks/useQuoteStatus"

export const QuoteContext = createContext<QuoteStatusHook>(
  {} as QuoteStatusHook
)
export const useQuotes = () => useContext(QuoteContext)

export const QuoteProvider = ({ children }: { children: ReactNode }) => {
  const { caseQuotes, firstQuote, getQuotes } = useQuoteStatus()

  const value = useMemo(() => {
    return {
      caseQuotes: caseQuotes,
      firstQuote: firstQuote,
      getQuotes: getQuotes
    }
  }, [caseQuotes, firstQuote, getQuotes])

  return <QuoteContext.Provider value={value}>{children}</QuoteContext.Provider>
}
