import { FC, useEffect, useMemo } from "react"
import { FeeGuideLegalArea, FeeGuideProduct, FeeGuideProductVariant, useFeeGuide } from "../../hooks/useFeeGuide"
import { ContentLayoutFlow, DetailsSubshell } from "../../layout/subshells/DetailsSubshell"
import { PageHeader } from "../../elements/PageHeader"
import { IntlShape, useIntl } from "react-intl"
import { Controller, useForm } from "react-hook-form"
import { pipe } from "fp-ts/es6/function"
import { map } from "fp-ts/Array"
import { refreshFold } from "@nll/datum/DatumEither"
import * as O from "fp-ts/Option"
import { SectionHeader } from "../../elements/SectionHeader"
import { FormFieldNew } from "../../elements"
import { Combobox, ComboboxItem } from "../../elements/Combobox"
import { QuoteCard, sumQuoteItems } from "../../components/assessment-call/complete/QuoteCard"
import { formatCurrencyUnits } from "../../elements/Currency"
import "twin.macro"
import { useUser } from "../../contexts/User"
import { Redirect } from "react-router-dom"
import { ROUTES } from "../../layout/Navigation"

export const FeeGuideViewScene: FC = () => {

  const {isSolicitor} = useUser()

  return (

    isSolicitor ?

    <DetailsSubshell header={
      <PageHeader title="Fee Guide"/>
    } content={<FeeGuideSelector/>}/>

      : <Redirect to={ROUTES.root}/>
  )
}

type FeeGuideSelectorState = {
  legalArea: string
  product: string
  variant: string
}

const buildAreaComboboxItem = (area: FeeGuideLegalArea): ComboboxItem => ({
  name: area.title,
  value: area.id,
  // supportingText: `${area.products.length} items`
})

const buildProductComboboxItem = (intl: IntlShape) => (product: FeeGuideProduct): ComboboxItem => ({
  name: product.title,
  value: product.id,
  // supportingText: `${product.variants.length} variants, from ${pipe(product.variants, cheapestVariant, v => formatCurrencyUnits(intl)(v[1]))}`
})

const cheapestVariant = (variants: FeeGuideProductVariant[]) => variants
  .map(v => [v, sumQuoteItems(v.lineItems || [])] as const)
  .sort(([_1, t1], [_2, t2]) => t1 - t2)[0]

const buildVariantComboboxItem = (intl: IntlShape) => (variant: FeeGuideProductVariant): ComboboxItem => ({
  name: variant.title,
  value: variant.id,
  supportingText: `${formatCurrencyUnits(intl)(sumQuoteItems(variant.lineItems || []))}`
})

const selectEntry = <T,>(filter: (i: T) => boolean) => (items: T[]) => {
  const filtered = items.filter(filter)
  return filtered.length > 0 ? filtered[0] : undefined
}
const FeeGuideSelector: FC = () => {
  const intl = useIntl()

  const { errors, control, watch, setValue, clearErrors } = useForm<FeeGuideSelectorState>()

  const formData: Partial<FeeGuideSelectorState> = watch()
  const {
    legalAreas,
    products,
    variants,
    feeGuide
  } = useFeeGuide({
    legalArea: formData.legalArea,
    productVariant: formData.variant,
    areaProduct: formData.product
  })

  const areaList: FeeGuideLegalArea[] = useMemo(
    () => refreshFold(
      () => [],
      () => [],
      () => [],
      (a: FeeGuideLegalArea[]) => a
    )(legalAreas),
    [legalAreas]
  )

  const areaComboboxItems = useMemo(
    () => pipe(areaList, map(buildAreaComboboxItem)),
    [areaList]
  )

  const productsList: FeeGuideProduct[] = useMemo(
    () => refreshFold(
      () => [],
      () => [],
      () => [],
      (a: FeeGuideProduct[]) => a
    )(products),
    [products]
  )

  const productComboboxItems = useMemo(
    () => pipe(productsList, map(buildProductComboboxItem(intl))),
    [productsList, intl]
  )

  const product = useMemo(
    () => pipe(
      productsList,
      selectEntry(a => formData.product && a.id === formData.product || false),
    ),
    [formData.product]
  )

  const variantsList: FeeGuideProductVariant[] = useMemo(
    () => refreshFold(
      () => [],
      () => [],
      () => [],
      (a: FeeGuideProductVariant[]) => a
    )(variants),
    [variants]
  )

  const variantComboboxItems = useMemo(
    () => pipe(
      variantsList,
      map(buildVariantComboboxItem(intl)),
    ),
    [variantsList, intl]
  )

  const selectedFeeGuideItem: O.Option<FeeGuideProductVariant> = useMemo(
    () => refreshFold(
      () => O.none,
      () => O.none,
      () => O.none,
      (a: O.Option<FeeGuideProductVariant>) => a
    )(feeGuide),
    [feeGuide]
  )

  useEffect(
    () => {
      if(formData.legalArea) {
        setValue('product', undefined)
        setValue('variant', undefined)
        clearErrors(['product', 'variant'])
      }
    },
    [formData.legalArea]
  )

  useEffect(
    () => {
      if(formData.product) {
        setValue('variant', undefined)
        clearErrors(['variant'])
      }
    },
    [formData.product]
  )

  return (
    <form>
      <ContentLayoutFlow>
        <div tw="space-y-4">
          <SectionHeader
            title="Legal Area"
            supportingText="Find the category of work this matter falls under."
            hideRule
          />

          <FormFieldNew
            id="legalArea"
            error={errors.legalArea as any}
            control={(
              <Controller
                name="legalArea"
                control={control}
                rules={{ required: 'Please select an answer.' }}
                render={({ onChange, value }) => (
                  <Combobox
                    items={areaComboboxItems}
                    value={value}
                    setValue={onChange}
                  />
                )}
              />
            )}
          />
        </div>

        <div tw="space-y-4">
          <SectionHeader
            title="Product"
            supportingText={`Find the product that this matter relates to from our fee guide.`}
            hideRule
          />

          <FormFieldNew
            id="product"
            error={errors.product as any}
            control={(
              <Controller
                name="product"
                control={control}
                rules={{
                  required: formData.legalArea && formData.legalArea !== 'other'
                    ? 'Please select an answer.'
                    : false
                }}
                render={({ onChange, value }) => (
                  <Combobox
                    disabled={productComboboxItems.filter(i => i.value !== 'other').length === 0}
                    items={productComboboxItems}
                    value={value}
                    setValue={onChange}
                  />
                )}
              />
            )}
          />

          <FormFieldNew
            id="variant"
            error={errors.variant as any}
            control={(
              <Controller
                name="variant"
                control={control}
                rules={{
                  required: formData.product && formData.product !== 'other'
                    ? 'Please select an answer.'
                    : false
                }}
                render={({ onChange, value }) => (
                  <Combobox
                    disabled={variantComboboxItems.filter(i => i.value !== 'other').length === 0}
                    items={variantComboboxItems}
                    value={value}
                    setValue={onChange}
                  />
                )}
              />
            )}
          />
        </div>

        {product && O.isSome(selectedFeeGuideItem) && (
          <QuoteCard
            productName={product!.title}
            variantName={selectedFeeGuideItem.value.shortName}
            lineItems={selectedFeeGuideItem.value.lineItems}
            includes={selectedFeeGuideItem.value.includes}
            excludes={selectedFeeGuideItem.value.excludes}
          />
        )}
      </ContentLayoutFlow>
    </form>
  )
}
