import { mapLeft, toError } from 'fp-ts/lib/Either'
import { flow, pipe } from 'fp-ts/lib/function'
import * as D from 'io-ts/lib/Decoder'
import * as G from 'io-ts/lib/Guard'
import { draw } from 'io-ts/lib/Decoder'

export const withOptional = <A, B, C>(base: D.Decoder<A, B>, partial: { [K in keyof C]: D.Decoder<unknown, C[K]> }) => pipe(
  base,
  D.intersect(
    D.partial(partial)
  )
)

export const decodeAny = <A>() => D.id<A>() as D.Decoder<unknown, A>

export const optional = <A, B>(base: D.Decoder<A, B>) => D.union(undefinedDecoder2, base)

export const brand = <T>(name: string) =>
  D.refine((s: any): s is T => true, name)

  export const undefinedGuard: G.Guard<unknown, undefined> = {
    is: (u: unknown): u is undefined => u === undefined
  }

  export const undefinedDecoder2: D.Decoder<unknown, undefined>
    = D.fromGuard(undefinedGuard, 'undefined')

// https://github.com/gcanti/io-ts-types/blob/9ddc26eb40ad038c6ab998f51d3fae15602e8ae9/src/Decoder/DateFromString.ts
export const DateFromString: D.Decoder<unknown, Date> = pipe(
  D.string,
  D.parse(s => {
    const d = new Date(s)
    return isNaN(d.getTime())
    ? D.failure(s, `cannot parse ${JSON.stringify(s)} to a Date`)
    : D.success(d)
  })
)

export const decodeOrError = <E, A>(decoder: D.Decoder<E, A>) => flow(
  decoder.decode,
  mapLeft(e => {
    console.log('Decode error', draw(e))
    return e
  }),
  mapLeft(toError)
)
