/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createClientCommand = /* GraphQL */ `
  mutation CreateClientCommand($input: CreateClientCommand!) {
    createClientCommand(input: $input) {
      id
      type
      emailAddress
      displayName
      phoneNumber
      kycSubmission {
        userId
        user {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        status
        address
        documentUploads
        identityUploads
        verificationReport {
          amlReportFileKey
          kycReportFileKey
        }
        rejectionReason {
          reason
          instructions
        }
        createdAt
        updatedAt
      }
      stripeCustomerId
      paymentMethods {
        id
        lastFour
        brand
        expiryMonth
        expiryYear
      }
      clientCases {
        items {
          id
          caseNumber
          brand
          productSKU
          hasChatbot
          payFirst
          context
          createdAt
          clientId
          client {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          clientKyc {
            isSubmitted
            isVerified
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            documentUploads
            identityUploads
          }
          files {
            name
            key
            lastModified
            size
          }
          details {
            type
            isTimeSensitive
            details
            summary
            ask
          }
          messages {
            items {
              id
              author {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              authorId
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              content
              type
              createdAt
              updatedAt
            }
            nextToken
          }
          quotes {
            items {
              id
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              isLeadFromAds
              isManualFee
              lineItems {
                nextToken
              }
              payments {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          payments {
            items {
              id
              caseId
              clientId
              quoteId
              type
              stripeAccountId
              stripePaymentIntentId
              toPlatform
              amount
              refunded
              solicitorId
              chargeType
              createdAt
              updatedAt
            }
            nextToken
          }
          caseSolicitorId
          solicitor {
            id
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            name
            businessName
            sraRegistrationNumber
            stripeAccountId
            onboardingStatus {
              hasAccount
              accountType
              hasChargesEnabled
              hasSubmitDetails
              hasPayoutsEnabled
            }
            createdAt
            updatedAt
          }
          solicitorResponse {
            type
            solicitorId
            reason
          }
          completion {
            type
            details
          }
          review {
            items {
              id
              solicitorId
              caseId
              clientId
              rating
              comment
              createdAt
              updatedAt
            }
            nextToken
          }
          status
          assignedToSolicitorAt
          askedClientToPayAt
          statusWhenCancelled
          exportedCaseId
          updatedAt
        }
        nextToken
      }
      solicitorCases {
        items {
          id
          caseNumber
          brand
          productSKU
          hasChatbot
          payFirst
          context
          createdAt
          clientId
          client {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          clientKyc {
            isSubmitted
            isVerified
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            documentUploads
            identityUploads
          }
          files {
            name
            key
            lastModified
            size
          }
          details {
            type
            isTimeSensitive
            details
            summary
            ask
          }
          messages {
            items {
              id
              author {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              authorId
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              content
              type
              createdAt
              updatedAt
            }
            nextToken
          }
          quotes {
            items {
              id
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              isLeadFromAds
              isManualFee
              lineItems {
                nextToken
              }
              payments {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          payments {
            items {
              id
              caseId
              clientId
              quoteId
              type
              stripeAccountId
              stripePaymentIntentId
              toPlatform
              amount
              refunded
              solicitorId
              chargeType
              createdAt
              updatedAt
            }
            nextToken
          }
          caseSolicitorId
          solicitor {
            id
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            name
            businessName
            sraRegistrationNumber
            stripeAccountId
            onboardingStatus {
              hasAccount
              accountType
              hasChargesEnabled
              hasSubmitDetails
              hasPayoutsEnabled
            }
            createdAt
            updatedAt
          }
          solicitorResponse {
            type
            solicitorId
            reason
          }
          completion {
            type
            details
          }
          review {
            items {
              id
              solicitorId
              caseId
              clientId
              rating
              comment
              createdAt
              updatedAt
            }
            nextToken
          }
          status
          assignedToSolicitorAt
          askedClientToPayAt
          statusWhenCancelled
          exportedCaseId
          updatedAt
        }
        nextToken
      }
      referral
      createdAt
      updatedAt
    }
  }
`;
export const createCaseCommand = /* GraphQL */ `
  mutation CreateCaseCommand($input: CreateCaseCommand!) {
    createCaseCommand(input: $input) {
      id
      caseNumber
      brand
      productSKU
      hasChatbot
      payFirst
      context
      createdAt
      clientId
      client {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      clientKyc {
        isSubmitted
        isVerified
        verificationReport {
          amlReportFileKey
          kycReportFileKey
        }
        documentUploads
        identityUploads
      }
      files {
        name
        key
        lastModified
        size
      }
      details {
        type
        isTimeSensitive
        details
        summary
        ask
      }
      messages {
        items {
          id
          author {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          authorId
          case {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          caseId
          content
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      quotes {
        items {
          id
          case {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          caseId
          isLeadFromAds
          isManualFee
          lineItems {
            items {
              id
              quote {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              quoteId
              caseLineItemProductId
              product {
                id
                sku
                title
                description
                createdAt
                updatedAt
              }
              description
              clientPays
              solicitorReceives
              subsidy
              platformFee
              feePercentage
              scope {
                includes
                excludes
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          payments {
            items {
              id
              caseId
              clientId
              quoteId
              type
              stripeAccountId
              stripePaymentIntentId
              toPlatform
              amount
              refunded
              solicitorId
              chargeType
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      payments {
        items {
          id
          caseId
          clientId
          quoteId
          type
          stripeAccountId
          stripePaymentIntentId
          toPlatform
          amount
          refunded
          solicitorId
          chargeType
          createdAt
          updatedAt
        }
        nextToken
      }
      caseSolicitorId
      solicitor {
        id
        user {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        name
        businessName
        sraRegistrationNumber
        stripeAccountId
        onboardingStatus {
          hasAccount
          accountType
          hasChargesEnabled
          hasSubmitDetails
          hasPayoutsEnabled
        }
        createdAt
        updatedAt
      }
      solicitorResponse {
        type
        solicitorId
        reason
      }
      completion {
        type
        details
      }
      review {
        items {
          id
          solicitorId
          caseId
          clientId
          rating
          comment
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      assignedToSolicitorAt
      askedClientToPayAt
      statusWhenCancelled
      exportedCaseId
      updatedAt
    }
  }
`;
export const updateCaseMetadataCommand = /* GraphQL */ `
  mutation UpdateCaseMetadataCommand($input: UpdateCaseMetadataCommand!) {
    updateCaseMetadataCommand(input: $input) {
      id
      caseNumber
      brand
      productSKU
      hasChatbot
      payFirst
      context
      createdAt
      clientId
      client {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      clientKyc {
        isSubmitted
        isVerified
        verificationReport {
          amlReportFileKey
          kycReportFileKey
        }
        documentUploads
        identityUploads
      }
      files {
        name
        key
        lastModified
        size
      }
      details {
        type
        isTimeSensitive
        details
        summary
        ask
      }
      messages {
        items {
          id
          author {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          authorId
          case {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          caseId
          content
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      quotes {
        items {
          id
          case {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          caseId
          isLeadFromAds
          isManualFee
          lineItems {
            items {
              id
              quote {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              quoteId
              caseLineItemProductId
              product {
                id
                sku
                title
                description
                createdAt
                updatedAt
              }
              description
              clientPays
              solicitorReceives
              subsidy
              platformFee
              feePercentage
              scope {
                includes
                excludes
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          payments {
            items {
              id
              caseId
              clientId
              quoteId
              type
              stripeAccountId
              stripePaymentIntentId
              toPlatform
              amount
              refunded
              solicitorId
              chargeType
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      payments {
        items {
          id
          caseId
          clientId
          quoteId
          type
          stripeAccountId
          stripePaymentIntentId
          toPlatform
          amount
          refunded
          solicitorId
          chargeType
          createdAt
          updatedAt
        }
        nextToken
      }
      caseSolicitorId
      solicitor {
        id
        user {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        name
        businessName
        sraRegistrationNumber
        stripeAccountId
        onboardingStatus {
          hasAccount
          accountType
          hasChargesEnabled
          hasSubmitDetails
          hasPayoutsEnabled
        }
        createdAt
        updatedAt
      }
      solicitorResponse {
        type
        solicitorId
        reason
      }
      completion {
        type
        details
      }
      review {
        items {
          id
          solicitorId
          caseId
          clientId
          rating
          comment
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      assignedToSolicitorAt
      askedClientToPayAt
      statusWhenCancelled
      exportedCaseId
      updatedAt
    }
  }
`;
export const setCaseDescriptionCommand = /* GraphQL */ `
  mutation SetCaseDescriptionCommand($input: SetCaseDescriptionCommand!) {
    setCaseDescriptionCommand(input: $input) {
      id
      caseNumber
      brand
      productSKU
      hasChatbot
      payFirst
      context
      createdAt
      clientId
      client {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      clientKyc {
        isSubmitted
        isVerified
        verificationReport {
          amlReportFileKey
          kycReportFileKey
        }
        documentUploads
        identityUploads
      }
      files {
        name
        key
        lastModified
        size
      }
      details {
        type
        isTimeSensitive
        details
        summary
        ask
      }
      messages {
        items {
          id
          author {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          authorId
          case {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          caseId
          content
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      quotes {
        items {
          id
          case {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          caseId
          isLeadFromAds
          isManualFee
          lineItems {
            items {
              id
              quote {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              quoteId
              caseLineItemProductId
              product {
                id
                sku
                title
                description
                createdAt
                updatedAt
              }
              description
              clientPays
              solicitorReceives
              subsidy
              platformFee
              feePercentage
              scope {
                includes
                excludes
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          payments {
            items {
              id
              caseId
              clientId
              quoteId
              type
              stripeAccountId
              stripePaymentIntentId
              toPlatform
              amount
              refunded
              solicitorId
              chargeType
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      payments {
        items {
          id
          caseId
          clientId
          quoteId
          type
          stripeAccountId
          stripePaymentIntentId
          toPlatform
          amount
          refunded
          solicitorId
          chargeType
          createdAt
          updatedAt
        }
        nextToken
      }
      caseSolicitorId
      solicitor {
        id
        user {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        name
        businessName
        sraRegistrationNumber
        stripeAccountId
        onboardingStatus {
          hasAccount
          accountType
          hasChargesEnabled
          hasSubmitDetails
          hasPayoutsEnabled
        }
        createdAt
        updatedAt
      }
      solicitorResponse {
        type
        solicitorId
        reason
      }
      completion {
        type
        details
      }
      review {
        items {
          id
          solicitorId
          caseId
          clientId
          rating
          comment
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      assignedToSolicitorAt
      askedClientToPayAt
      statusWhenCancelled
      exportedCaseId
      updatedAt
    }
  }
`;
export const addQuoteToCaseCommand = /* GraphQL */ `
  mutation AddQuoteToCaseCommand($input: AddQuoteToCaseCommand!) {
    addQuoteToCaseCommand(input: $input) {
      id
      case {
        id
        caseNumber
        brand
        productSKU
        hasChatbot
        payFirst
        context
        createdAt
        clientId
        client {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        clientKyc {
          isSubmitted
          isVerified
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          documentUploads
          identityUploads
        }
        files {
          name
          key
          lastModified
          size
        }
        details {
          type
          isTimeSensitive
          details
          summary
          ask
        }
        messages {
          items {
            id
            author {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            authorId
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            content
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            isLeadFromAds
            isManualFee
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        payments {
          items {
            id
            caseId
            clientId
            quoteId
            type
            stripeAccountId
            stripePaymentIntentId
            toPlatform
            amount
            refunded
            solicitorId
            chargeType
            createdAt
            updatedAt
          }
          nextToken
        }
        caseSolicitorId
        solicitor {
          id
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          name
          businessName
          sraRegistrationNumber
          stripeAccountId
          onboardingStatus {
            hasAccount
            accountType
            hasChargesEnabled
            hasSubmitDetails
            hasPayoutsEnabled
          }
          createdAt
          updatedAt
        }
        solicitorResponse {
          type
          solicitorId
          reason
        }
        completion {
          type
          details
        }
        review {
          items {
            id
            solicitorId
            caseId
            clientId
            rating
            comment
            createdAt
            updatedAt
          }
          nextToken
        }
        status
        assignedToSolicitorAt
        askedClientToPayAt
        statusWhenCancelled
        exportedCaseId
        updatedAt
      }
      caseId
      isLeadFromAds
      isManualFee
      lineItems {
        items {
          id
          quote {
            id
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            isLeadFromAds
            isManualFee
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          quoteId
          caseLineItemProductId
          product {
            id
            sku
            title
            description
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          description
          clientPays
          solicitorReceives
          subsidy
          platformFee
          feePercentage
          scope {
            includes
            excludes
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      payments {
        items {
          id
          caseId
          clientId
          quoteId
          type
          stripeAccountId
          stripePaymentIntentId
          toPlatform
          amount
          refunded
          solicitorId
          chargeType
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const addExistingPaymentToCaseCommand = /* GraphQL */ `
  mutation AddExistingPaymentToCaseCommand(
    $input: AddExistingPaymentToCaseCommand!
  ) {
    addExistingPaymentToCaseCommand(input: $input) {
      id
      caseNumber
      brand
      productSKU
      hasChatbot
      payFirst
      context
      createdAt
      clientId
      client {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      clientKyc {
        isSubmitted
        isVerified
        verificationReport {
          amlReportFileKey
          kycReportFileKey
        }
        documentUploads
        identityUploads
      }
      files {
        name
        key
        lastModified
        size
      }
      details {
        type
        isTimeSensitive
        details
        summary
        ask
      }
      messages {
        items {
          id
          author {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          authorId
          case {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          caseId
          content
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      quotes {
        items {
          id
          case {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          caseId
          isLeadFromAds
          isManualFee
          lineItems {
            items {
              id
              quote {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              quoteId
              caseLineItemProductId
              product {
                id
                sku
                title
                description
                createdAt
                updatedAt
              }
              description
              clientPays
              solicitorReceives
              subsidy
              platformFee
              feePercentage
              scope {
                includes
                excludes
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          payments {
            items {
              id
              caseId
              clientId
              quoteId
              type
              stripeAccountId
              stripePaymentIntentId
              toPlatform
              amount
              refunded
              solicitorId
              chargeType
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      payments {
        items {
          id
          caseId
          clientId
          quoteId
          type
          stripeAccountId
          stripePaymentIntentId
          toPlatform
          amount
          refunded
          solicitorId
          chargeType
          createdAt
          updatedAt
        }
        nextToken
      }
      caseSolicitorId
      solicitor {
        id
        user {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        name
        businessName
        sraRegistrationNumber
        stripeAccountId
        onboardingStatus {
          hasAccount
          accountType
          hasChargesEnabled
          hasSubmitDetails
          hasPayoutsEnabled
        }
        createdAt
        updatedAt
      }
      solicitorResponse {
        type
        solicitorId
        reason
      }
      completion {
        type
        details
      }
      review {
        items {
          id
          solicitorId
          caseId
          clientId
          rating
          comment
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      assignedToSolicitorAt
      askedClientToPayAt
      statusWhenCancelled
      exportedCaseId
      updatedAt
    }
  }
`;
export const assignSolicitorToCaseCommand = /* GraphQL */ `
  mutation AssignSolicitorToCaseCommand($input: AssignSolicitorToCaseCommand!) {
    assignSolicitorToCaseCommand(input: $input) {
      id
      caseNumber
      brand
      productSKU
      hasChatbot
      payFirst
      context
      createdAt
      clientId
      client {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      clientKyc {
        isSubmitted
        isVerified
        verificationReport {
          amlReportFileKey
          kycReportFileKey
        }
        documentUploads
        identityUploads
      }
      files {
        name
        key
        lastModified
        size
      }
      details {
        type
        isTimeSensitive
        details
        summary
        ask
      }
      messages {
        items {
          id
          author {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          authorId
          case {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          caseId
          content
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      quotes {
        items {
          id
          case {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          caseId
          isLeadFromAds
          isManualFee
          lineItems {
            items {
              id
              quote {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              quoteId
              caseLineItemProductId
              product {
                id
                sku
                title
                description
                createdAt
                updatedAt
              }
              description
              clientPays
              solicitorReceives
              subsidy
              platformFee
              feePercentage
              scope {
                includes
                excludes
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          payments {
            items {
              id
              caseId
              clientId
              quoteId
              type
              stripeAccountId
              stripePaymentIntentId
              toPlatform
              amount
              refunded
              solicitorId
              chargeType
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      payments {
        items {
          id
          caseId
          clientId
          quoteId
          type
          stripeAccountId
          stripePaymentIntentId
          toPlatform
          amount
          refunded
          solicitorId
          chargeType
          createdAt
          updatedAt
        }
        nextToken
      }
      caseSolicitorId
      solicitor {
        id
        user {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        name
        businessName
        sraRegistrationNumber
        stripeAccountId
        onboardingStatus {
          hasAccount
          accountType
          hasChargesEnabled
          hasSubmitDetails
          hasPayoutsEnabled
        }
        createdAt
        updatedAt
      }
      solicitorResponse {
        type
        solicitorId
        reason
      }
      completion {
        type
        details
      }
      review {
        items {
          id
          solicitorId
          caseId
          clientId
          rating
          comment
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      assignedToSolicitorAt
      askedClientToPayAt
      statusWhenCancelled
      exportedCaseId
      updatedAt
    }
  }
`;
export const addPaymentToCaseCommand = /* GraphQL */ `
  mutation AddPaymentToCaseCommand($input: AddPaymentToCaseCommand!) {
    addPaymentToCaseCommand(input: $input) {
      id
      caseId
      clientId
      quoteId
      type
      stripeAccountId
      stripePaymentIntentId
      toPlatform
      amount
      refunded
      solicitorId
      chargeType
      createdAt
      updatedAt
    }
  }
`;
export const refundCaseDepositCommand = /* GraphQL */ `
  mutation RefundCaseDepositCommand($input: RefundCaseDepositCommand!) {
    refundCaseDepositCommand(input: $input) {
      id
      caseNumber
      brand
      productSKU
      hasChatbot
      payFirst
      context
      createdAt
      clientId
      client {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      clientKyc {
        isSubmitted
        isVerified
        verificationReport {
          amlReportFileKey
          kycReportFileKey
        }
        documentUploads
        identityUploads
      }
      files {
        name
        key
        lastModified
        size
      }
      details {
        type
        isTimeSensitive
        details
        summary
        ask
      }
      messages {
        items {
          id
          author {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          authorId
          case {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          caseId
          content
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      quotes {
        items {
          id
          case {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          caseId
          isLeadFromAds
          isManualFee
          lineItems {
            items {
              id
              quote {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              quoteId
              caseLineItemProductId
              product {
                id
                sku
                title
                description
                createdAt
                updatedAt
              }
              description
              clientPays
              solicitorReceives
              subsidy
              platformFee
              feePercentage
              scope {
                includes
                excludes
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          payments {
            items {
              id
              caseId
              clientId
              quoteId
              type
              stripeAccountId
              stripePaymentIntentId
              toPlatform
              amount
              refunded
              solicitorId
              chargeType
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      payments {
        items {
          id
          caseId
          clientId
          quoteId
          type
          stripeAccountId
          stripePaymentIntentId
          toPlatform
          amount
          refunded
          solicitorId
          chargeType
          createdAt
          updatedAt
        }
        nextToken
      }
      caseSolicitorId
      solicitor {
        id
        user {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        name
        businessName
        sraRegistrationNumber
        stripeAccountId
        onboardingStatus {
          hasAccount
          accountType
          hasChargesEnabled
          hasSubmitDetails
          hasPayoutsEnabled
        }
        createdAt
        updatedAt
      }
      solicitorResponse {
        type
        solicitorId
        reason
      }
      completion {
        type
        details
      }
      review {
        items {
          id
          solicitorId
          caseId
          clientId
          rating
          comment
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      assignedToSolicitorAt
      askedClientToPayAt
      statusWhenCancelled
      exportedCaseId
      updatedAt
    }
  }
`;
export const completeCaseCommand = /* GraphQL */ `
  mutation CompleteCaseCommand($input: CompleteCaseCommand!) {
    completeCaseCommand(input: $input) {
      id
      caseNumber
      brand
      productSKU
      hasChatbot
      payFirst
      context
      createdAt
      clientId
      client {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      clientKyc {
        isSubmitted
        isVerified
        verificationReport {
          amlReportFileKey
          kycReportFileKey
        }
        documentUploads
        identityUploads
      }
      files {
        name
        key
        lastModified
        size
      }
      details {
        type
        isTimeSensitive
        details
        summary
        ask
      }
      messages {
        items {
          id
          author {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          authorId
          case {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          caseId
          content
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      quotes {
        items {
          id
          case {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          caseId
          isLeadFromAds
          isManualFee
          lineItems {
            items {
              id
              quote {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              quoteId
              caseLineItemProductId
              product {
                id
                sku
                title
                description
                createdAt
                updatedAt
              }
              description
              clientPays
              solicitorReceives
              subsidy
              platformFee
              feePercentage
              scope {
                includes
                excludes
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          payments {
            items {
              id
              caseId
              clientId
              quoteId
              type
              stripeAccountId
              stripePaymentIntentId
              toPlatform
              amount
              refunded
              solicitorId
              chargeType
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      payments {
        items {
          id
          caseId
          clientId
          quoteId
          type
          stripeAccountId
          stripePaymentIntentId
          toPlatform
          amount
          refunded
          solicitorId
          chargeType
          createdAt
          updatedAt
        }
        nextToken
      }
      caseSolicitorId
      solicitor {
        id
        user {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        name
        businessName
        sraRegistrationNumber
        stripeAccountId
        onboardingStatus {
          hasAccount
          accountType
          hasChargesEnabled
          hasSubmitDetails
          hasPayoutsEnabled
        }
        createdAt
        updatedAt
      }
      solicitorResponse {
        type
        solicitorId
        reason
      }
      completion {
        type
        details
      }
      review {
        items {
          id
          solicitorId
          caseId
          clientId
          rating
          comment
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      assignedToSolicitorAt
      askedClientToPayAt
      statusWhenCancelled
      exportedCaseId
      updatedAt
    }
  }
`;
export const refundCaseCommand = /* GraphQL */ `
  mutation RefundCaseCommand($input: RefundCaseCommand!) {
    refundCaseCommand(input: $input) {
      id
      caseNumber
      brand
      productSKU
      hasChatbot
      payFirst
      context
      createdAt
      clientId
      client {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      clientKyc {
        isSubmitted
        isVerified
        verificationReport {
          amlReportFileKey
          kycReportFileKey
        }
        documentUploads
        identityUploads
      }
      files {
        name
        key
        lastModified
        size
      }
      details {
        type
        isTimeSensitive
        details
        summary
        ask
      }
      messages {
        items {
          id
          author {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          authorId
          case {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          caseId
          content
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      quotes {
        items {
          id
          case {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          caseId
          isLeadFromAds
          isManualFee
          lineItems {
            items {
              id
              quote {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              quoteId
              caseLineItemProductId
              product {
                id
                sku
                title
                description
                createdAt
                updatedAt
              }
              description
              clientPays
              solicitorReceives
              subsidy
              platformFee
              feePercentage
              scope {
                includes
                excludes
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          payments {
            items {
              id
              caseId
              clientId
              quoteId
              type
              stripeAccountId
              stripePaymentIntentId
              toPlatform
              amount
              refunded
              solicitorId
              chargeType
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      payments {
        items {
          id
          caseId
          clientId
          quoteId
          type
          stripeAccountId
          stripePaymentIntentId
          toPlatform
          amount
          refunded
          solicitorId
          chargeType
          createdAt
          updatedAt
        }
        nextToken
      }
      caseSolicitorId
      solicitor {
        id
        user {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        name
        businessName
        sraRegistrationNumber
        stripeAccountId
        onboardingStatus {
          hasAccount
          accountType
          hasChargesEnabled
          hasSubmitDetails
          hasPayoutsEnabled
        }
        createdAt
        updatedAt
      }
      solicitorResponse {
        type
        solicitorId
        reason
      }
      completion {
        type
        details
      }
      review {
        items {
          id
          solicitorId
          caseId
          clientId
          rating
          comment
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      assignedToSolicitorAt
      askedClientToPayAt
      statusWhenCancelled
      exportedCaseId
      updatedAt
    }
  }
`;
export const cancelCaseCommand = /* GraphQL */ `
  mutation CancelCaseCommand($input: CancelCaseCommand!) {
    cancelCaseCommand(input: $input) {
      id
      caseNumber
      brand
      productSKU
      hasChatbot
      payFirst
      context
      createdAt
      clientId
      client {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      clientKyc {
        isSubmitted
        isVerified
        verificationReport {
          amlReportFileKey
          kycReportFileKey
        }
        documentUploads
        identityUploads
      }
      files {
        name
        key
        lastModified
        size
      }
      details {
        type
        isTimeSensitive
        details
        summary
        ask
      }
      messages {
        items {
          id
          author {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          authorId
          case {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          caseId
          content
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      quotes {
        items {
          id
          case {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          caseId
          isLeadFromAds
          isManualFee
          lineItems {
            items {
              id
              quote {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              quoteId
              caseLineItemProductId
              product {
                id
                sku
                title
                description
                createdAt
                updatedAt
              }
              description
              clientPays
              solicitorReceives
              subsidy
              platformFee
              feePercentage
              scope {
                includes
                excludes
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          payments {
            items {
              id
              caseId
              clientId
              quoteId
              type
              stripeAccountId
              stripePaymentIntentId
              toPlatform
              amount
              refunded
              solicitorId
              chargeType
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      payments {
        items {
          id
          caseId
          clientId
          quoteId
          type
          stripeAccountId
          stripePaymentIntentId
          toPlatform
          amount
          refunded
          solicitorId
          chargeType
          createdAt
          updatedAt
        }
        nextToken
      }
      caseSolicitorId
      solicitor {
        id
        user {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        name
        businessName
        sraRegistrationNumber
        stripeAccountId
        onboardingStatus {
          hasAccount
          accountType
          hasChargesEnabled
          hasSubmitDetails
          hasPayoutsEnabled
        }
        createdAt
        updatedAt
      }
      solicitorResponse {
        type
        solicitorId
        reason
      }
      completion {
        type
        details
      }
      review {
        items {
          id
          solicitorId
          caseId
          clientId
          rating
          comment
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      assignedToSolicitorAt
      askedClientToPayAt
      statusWhenCancelled
      exportedCaseId
      updatedAt
    }
  }
`;
export const unassignSolicitorCommand = /* GraphQL */ `
  mutation UnassignSolicitorCommand($input: UnassignSolicitorCommand!) {
    unassignSolicitorCommand(input: $input) {
      id
      caseNumber
      brand
      productSKU
      hasChatbot
      payFirst
      context
      createdAt
      clientId
      client {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      clientKyc {
        isSubmitted
        isVerified
        verificationReport {
          amlReportFileKey
          kycReportFileKey
        }
        documentUploads
        identityUploads
      }
      files {
        name
        key
        lastModified
        size
      }
      details {
        type
        isTimeSensitive
        details
        summary
        ask
      }
      messages {
        items {
          id
          author {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          authorId
          case {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          caseId
          content
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      quotes {
        items {
          id
          case {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          caseId
          isLeadFromAds
          isManualFee
          lineItems {
            items {
              id
              quote {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              quoteId
              caseLineItemProductId
              product {
                id
                sku
                title
                description
                createdAt
                updatedAt
              }
              description
              clientPays
              solicitorReceives
              subsidy
              platformFee
              feePercentage
              scope {
                includes
                excludes
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          payments {
            items {
              id
              caseId
              clientId
              quoteId
              type
              stripeAccountId
              stripePaymentIntentId
              toPlatform
              amount
              refunded
              solicitorId
              chargeType
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      payments {
        items {
          id
          caseId
          clientId
          quoteId
          type
          stripeAccountId
          stripePaymentIntentId
          toPlatform
          amount
          refunded
          solicitorId
          chargeType
          createdAt
          updatedAt
        }
        nextToken
      }
      caseSolicitorId
      solicitor {
        id
        user {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        name
        businessName
        sraRegistrationNumber
        stripeAccountId
        onboardingStatus {
          hasAccount
          accountType
          hasChargesEnabled
          hasSubmitDetails
          hasPayoutsEnabled
        }
        createdAt
        updatedAt
      }
      solicitorResponse {
        type
        solicitorId
        reason
      }
      completion {
        type
        details
      }
      review {
        items {
          id
          solicitorId
          caseId
          clientId
          rating
          comment
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      assignedToSolicitorAt
      askedClientToPayAt
      statusWhenCancelled
      exportedCaseId
      updatedAt
    }
  }
`;
export const leaveReviewCommand = /* GraphQL */ `
  mutation LeaveReviewCommand($input: LeaveReviewCommand!) {
    leaveReviewCommand(input: $input) {
      id
      solicitorId
      caseId
      clientId
      rating
      comment
      createdAt
      updatedAt
    }
  }
`;
export const downloadFileCommand = /* GraphQL */ `
  mutation DownloadFileCommand($input: DownloadFileCommand!) {
    downloadFileCommand(input: $input) {
      url
    }
  }
`;
export const requestKycReportUploadCommand = /* GraphQL */ `
  mutation RequestKycReportUploadCommand(
    $input: RequestKycReportUploadCommand!
  ) {
    requestKycReportUploadCommand(input: $input) {
      signature
    }
  }
`;
export const approveKycSubmissionCommand = /* GraphQL */ `
  mutation ApproveKycSubmissionCommand($input: ApproveKycSubmissionCommand!) {
    approveKycSubmissionCommand(input: $input) {
      userId
      user {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      status
      address
      documentUploads
      identityUploads
      verificationReport {
        amlReportFileKey
        kycReportFileKey
      }
      rejectionReason {
        reason
        instructions
      }
      createdAt
      updatedAt
    }
  }
`;
export const rejectKycSubmissionCommand = /* GraphQL */ `
  mutation RejectKycSubmissionCommand($input: RejectKycSubmissionCommand!) {
    rejectKycSubmissionCommand(input: $input) {
      userId
      user {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      status
      address
      documentUploads
      identityUploads
      verificationReport {
        amlReportFileKey
        kycReportFileKey
      }
      rejectionReason {
        reason
        instructions
      }
      createdAt
      updatedAt
    }
  }
`;
export const solicitorAcceptCaseCommand = /* GraphQL */ `
  mutation SolicitorAcceptCaseCommand($input: SolicitorAcceptCaseCommand!) {
    solicitorAcceptCaseCommand(input: $input) {
      id
      caseNumber
      brand
      productSKU
      hasChatbot
      payFirst
      context
      createdAt
      clientId
      client {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      clientKyc {
        isSubmitted
        isVerified
        verificationReport {
          amlReportFileKey
          kycReportFileKey
        }
        documentUploads
        identityUploads
      }
      files {
        name
        key
        lastModified
        size
      }
      details {
        type
        isTimeSensitive
        details
        summary
        ask
      }
      messages {
        items {
          id
          author {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          authorId
          case {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          caseId
          content
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      quotes {
        items {
          id
          case {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          caseId
          isLeadFromAds
          isManualFee
          lineItems {
            items {
              id
              quote {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              quoteId
              caseLineItemProductId
              product {
                id
                sku
                title
                description
                createdAt
                updatedAt
              }
              description
              clientPays
              solicitorReceives
              subsidy
              platformFee
              feePercentage
              scope {
                includes
                excludes
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          payments {
            items {
              id
              caseId
              clientId
              quoteId
              type
              stripeAccountId
              stripePaymentIntentId
              toPlatform
              amount
              refunded
              solicitorId
              chargeType
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      payments {
        items {
          id
          caseId
          clientId
          quoteId
          type
          stripeAccountId
          stripePaymentIntentId
          toPlatform
          amount
          refunded
          solicitorId
          chargeType
          createdAt
          updatedAt
        }
        nextToken
      }
      caseSolicitorId
      solicitor {
        id
        user {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        name
        businessName
        sraRegistrationNumber
        stripeAccountId
        onboardingStatus {
          hasAccount
          accountType
          hasChargesEnabled
          hasSubmitDetails
          hasPayoutsEnabled
        }
        createdAt
        updatedAt
      }
      solicitorResponse {
        type
        solicitorId
        reason
      }
      completion {
        type
        details
      }
      review {
        items {
          id
          solicitorId
          caseId
          clientId
          rating
          comment
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      assignedToSolicitorAt
      askedClientToPayAt
      statusWhenCancelled
      exportedCaseId
      updatedAt
    }
  }
`;
export const solicitorRejectCaseCommand = /* GraphQL */ `
  mutation SolicitorRejectCaseCommand($input: SolicitorRejectCaseCommand!) {
    solicitorRejectCaseCommand(input: $input) {
      id
      caseNumber
      brand
      productSKU
      hasChatbot
      payFirst
      context
      createdAt
      clientId
      client {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      clientKyc {
        isSubmitted
        isVerified
        verificationReport {
          amlReportFileKey
          kycReportFileKey
        }
        documentUploads
        identityUploads
      }
      files {
        name
        key
        lastModified
        size
      }
      details {
        type
        isTimeSensitive
        details
        summary
        ask
      }
      messages {
        items {
          id
          author {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          authorId
          case {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          caseId
          content
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      quotes {
        items {
          id
          case {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          caseId
          isLeadFromAds
          isManualFee
          lineItems {
            items {
              id
              quote {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              quoteId
              caseLineItemProductId
              product {
                id
                sku
                title
                description
                createdAt
                updatedAt
              }
              description
              clientPays
              solicitorReceives
              subsidy
              platformFee
              feePercentage
              scope {
                includes
                excludes
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          payments {
            items {
              id
              caseId
              clientId
              quoteId
              type
              stripeAccountId
              stripePaymentIntentId
              toPlatform
              amount
              refunded
              solicitorId
              chargeType
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      payments {
        items {
          id
          caseId
          clientId
          quoteId
          type
          stripeAccountId
          stripePaymentIntentId
          toPlatform
          amount
          refunded
          solicitorId
          chargeType
          createdAt
          updatedAt
        }
        nextToken
      }
      caseSolicitorId
      solicitor {
        id
        user {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        name
        businessName
        sraRegistrationNumber
        stripeAccountId
        onboardingStatus {
          hasAccount
          accountType
          hasChargesEnabled
          hasSubmitDetails
          hasPayoutsEnabled
        }
        createdAt
        updatedAt
      }
      solicitorResponse {
        type
        solicitorId
        reason
      }
      completion {
        type
        details
      }
      review {
        items {
          id
          solicitorId
          caseId
          clientId
          rating
          comment
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      assignedToSolicitorAt
      askedClientToPayAt
      statusWhenCancelled
      exportedCaseId
      updatedAt
    }
  }
`;
export const solicitorOnboardToStripeCommand = /* GraphQL */ `
  mutation SolicitorOnboardToStripeCommand(
    $input: SolicitorOnboardToStripeCommand!
  ) {
    solicitorOnboardToStripeCommand(input: $input) {
      url
    }
  }
`;
export const solicitorRequestStripeDashboardLinkCommand = /* GraphQL */ `
  mutation SolicitorRequestStripeDashboardLinkCommand {
    solicitorRequestStripeDashboardLinkCommand {
      url
    }
  }
`;
export const solicitorRequestKycReportDownloadCommand = /* GraphQL */ `
  mutation SolicitorRequestKycReportDownloadCommand(
    $input: SolicitorRequestKycReportDownloadCommand
  ) {
    solicitorRequestKycReportDownloadCommand(input: $input) {
      url
    }
  }
`;
export const clientCreateAddCardSessionCommand = /* GraphQL */ `
  mutation ClientCreateAddCardSessionCommand(
    $input: ClientCreateAddCardSessionCommand
  ) {
    clientCreateAddCardSessionCommand(input: $input) {
      sessionId
    }
  }
`;
export const clientCheckoutQuoteCommand = /* GraphQL */ `
  mutation ClientCheckoutQuoteCommand($input: ClientCheckoutQuoteCommand) {
    clientCheckoutQuoteCommand(input: $input) {
      stripeCheckoutSecret
      stripeAccountId
    }
  }
`;
export const clientClaimQuotePaymentCommand = /* GraphQL */ `
  mutation ClientClaimQuotePaymentCommand(
    $input: ClientClaimQuotePaymentCommand
  ) {
    clientClaimQuotePaymentCommand(input: $input) {
      caseId
      quoteId
      paymentId
    }
  }
`;
export const clientRequestCaseFileDownloadCommand = /* GraphQL */ `
  mutation ClientRequestCaseFileDownloadCommand(
    $input: ClientRequestCaseFileDownloadCommand
  ) {
    clientRequestCaseFileDownloadCommand(input: $input) {
      url
    }
  }
`;
export const clientRequestCaseFileUploadCommand = /* GraphQL */ `
  mutation ClientRequestCaseFileUploadCommand(
    $input: ClientRequestCaseFileUploadCommand
  ) {
    clientRequestCaseFileUploadCommand(input: $input) {
      signature
    }
  }
`;
export const clientRequestKycFileUploadCommand = /* GraphQL */ `
  mutation ClientRequestKycFileUploadCommand(
    $input: ClientRequestKycFileUploadCommand
  ) {
    clientRequestKycFileUploadCommand(input: $input) {
      signature
    }
  }
`;
export const clientSubmitKycForApprovalCommand = /* GraphQL */ `
  mutation ClientSubmitKycForApprovalCommand {
    clientSubmitKycForApprovalCommand {
      userId
      user {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      status
      address
      documentUploads
      identityUploads
      verificationReport {
        amlReportFileKey
        kycReportFileKey
      }
      rejectionReason {
        reason
        instructions
      }
      createdAt
      updatedAt
    }
  }
`;
export const clientEnsureUserIsConfiguredCommand = /* GraphQL */ `
  mutation ClientEnsureUserIsConfiguredCommand {
    clientEnsureUserIsConfiguredCommand
  }
`;
export const detachPaymentMethodCommand = /* GraphQL */ `
  mutation DetachPaymentMethodCommand($input: DetachPaymentMethodCommand) {
    detachPaymentMethodCommand(input: $input)
  }
`;
export const registerMessageSentCommand = /* GraphQL */ `
  mutation RegisterMessageSentCommand($input: RegisterMessageSentCommand) {
    registerMessageSentCommand(input: $input)
  }
`;
export const startChatbotSessionCommand = /* GraphQL */ `
  mutation StartChatbotSessionCommand($input: StartChatbotSessionCommand) {
    startChatbotSessionCommand(input: $input) {
      sessionId
    }
  }
`;
export const requestCaseCompletionCommand = /* GraphQL */ `
  mutation RequestCaseCompletionCommand($input: RequestCaseCompletionCommand!) {
    requestCaseCompletionCommand(input: $input) {
      id
    }
  }
`;
export const emitQuoteAddedToCaseEvent = /* GraphQL */ `
  mutation EmitQuoteAddedToCaseEvent($event: QuoteAddedToCaseEvent) {
    emitQuoteAddedToCaseEvent(event: $event) {
      success
    }
  }
`;
export const emitClientPaidQuoteEvent = /* GraphQL */ `
  mutation EmitClientPaidQuoteEvent($event: ClientPaidQuoteEvent) {
    emitClientPaidQuoteEvent(event: $event) {
      success
    }
  }
`;
export const emitSolicitorAskedToAcceptCaseEvent = /* GraphQL */ `
  mutation EmitSolicitorAskedToAcceptCaseEvent(
    $event: SolicitorAskedToAcceptCaseEvent
  ) {
    emitSolicitorAskedToAcceptCaseEvent(event: $event) {
      success
    }
  }
`;
export const emitSolicitorAcceptedCaseAssignmentEvent = /* GraphQL */ `
  mutation EmitSolicitorAcceptedCaseAssignmentEvent(
    $event: SolicitorAcceptedCaseAssignmentEvent
  ) {
    emitSolicitorAcceptedCaseAssignmentEvent(event: $event) {
      success
    }
  }
`;
export const emitSolicitorRejectedCaseAssignmentEvent = /* GraphQL */ `
  mutation EmitSolicitorRejectedCaseAssignmentEvent(
    $event: SolicitorRejectedCaseAssignmentEvent
  ) {
    emitSolicitorRejectedCaseAssignmentEvent(event: $event) {
      success
    }
  }
`;
export const emitClientSubmittedKycEvent = /* GraphQL */ `
  mutation EmitClientSubmittedKycEvent($event: ClientSubmittedKycEvent) {
    emitClientSubmittedKycEvent(event: $event) {
      success
    }
  }
`;
export const emitRejectedClientKycEvent = /* GraphQL */ `
  mutation EmitRejectedClientKycEvent($event: RejectedClientKycEvent) {
    emitRejectedClientKycEvent(event: $event) {
      success
    }
  }
`;
export const emitApprovedClientKycEvent = /* GraphQL */ `
  mutation EmitApprovedClientKycEvent($event: ApprovedClientKycEvent) {
    emitApprovedClientKycEvent(event: $event) {
      success
    }
  }
`;
export const emitCaseCompletedEvent = /* GraphQL */ `
  mutation EmitCaseCompletedEvent($event: CaseCompletedEvent) {
    emitCaseCompletedEvent(event: $event) {
      success
    }
  }
`;
export const emitCaseCreatedEvent = /* GraphQL */ `
  mutation EmitCaseCreatedEvent($event: CaseCreatedEvent) {
    emitCaseCreatedEvent(event: $event) {
      success
    }
  }
`;
export const emitCaseCancelledEvent = /* GraphQL */ `
  mutation EmitCaseCancelledEvent($event: CaseCancelledEvent) {
    emitCaseCancelledEvent(event: $event) {
      success
    }
  }
`;
export const emitSolicitorUnassignedEvent = /* GraphQL */ `
  mutation EmitSolicitorUnassignedEvent($event: SolicitorUnassignedEvent) {
    emitSolicitorUnassignedEvent(event: $event) {
      success
    }
  }
`;
export const emitUserRequestedCaseCompletionEvent = /* GraphQL */ `
  mutation EmitUserRequestedCaseCompletionEvent(
    $event: UserRequestedCaseCompletionEvent
  ) {
    emitUserRequestedCaseCompletionEvent(event: $event) {
      success
    }
  }
`;
export const prepareSignIn = /* GraphQL */ `
  mutation PrepareSignIn($emailAddress: String!) {
    prepareSignIn(emailAddress: $emailAddress) {
      success
    }
  }
`;
export const createCase = /* GraphQL */ `
  mutation CreateCase(
    $input: CreateCaseInput!
    $condition: ModelCaseConditionInput
  ) {
    createCase(input: $input, condition: $condition) {
      id
      caseNumber
      brand
      productSKU
      hasChatbot
      payFirst
      context
      createdAt
      clientId
      client {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      clientKyc {
        isSubmitted
        isVerified
        verificationReport {
          amlReportFileKey
          kycReportFileKey
        }
        documentUploads
        identityUploads
      }
      files {
        name
        key
        lastModified
        size
      }
      details {
        type
        isTimeSensitive
        details
        summary
        ask
      }
      messages {
        items {
          id
          author {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          authorId
          case {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          caseId
          content
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      quotes {
        items {
          id
          case {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          caseId
          isLeadFromAds
          isManualFee
          lineItems {
            items {
              id
              quote {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              quoteId
              caseLineItemProductId
              product {
                id
                sku
                title
                description
                createdAt
                updatedAt
              }
              description
              clientPays
              solicitorReceives
              subsidy
              platformFee
              feePercentage
              scope {
                includes
                excludes
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          payments {
            items {
              id
              caseId
              clientId
              quoteId
              type
              stripeAccountId
              stripePaymentIntentId
              toPlatform
              amount
              refunded
              solicitorId
              chargeType
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      payments {
        items {
          id
          caseId
          clientId
          quoteId
          type
          stripeAccountId
          stripePaymentIntentId
          toPlatform
          amount
          refunded
          solicitorId
          chargeType
          createdAt
          updatedAt
        }
        nextToken
      }
      caseSolicitorId
      solicitor {
        id
        user {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        name
        businessName
        sraRegistrationNumber
        stripeAccountId
        onboardingStatus {
          hasAccount
          accountType
          hasChargesEnabled
          hasSubmitDetails
          hasPayoutsEnabled
        }
        createdAt
        updatedAt
      }
      solicitorResponse {
        type
        solicitorId
        reason
      }
      completion {
        type
        details
      }
      review {
        items {
          id
          solicitorId
          caseId
          clientId
          rating
          comment
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      assignedToSolicitorAt
      askedClientToPayAt
      statusWhenCancelled
      exportedCaseId
      updatedAt
    }
  }
`;
export const updateCase = /* GraphQL */ `
  mutation UpdateCase(
    $input: UpdateCaseInput!
    $condition: ModelCaseConditionInput
  ) {
    updateCase(input: $input, condition: $condition) {
      id
      caseNumber
      brand
      productSKU
      hasChatbot
      payFirst
      context
      createdAt
      clientId
      client {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      clientKyc {
        isSubmitted
        isVerified
        verificationReport {
          amlReportFileKey
          kycReportFileKey
        }
        documentUploads
        identityUploads
      }
      files {
        name
        key
        lastModified
        size
      }
      details {
        type
        isTimeSensitive
        details
        summary
        ask
      }
      messages {
        items {
          id
          author {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          authorId
          case {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          caseId
          content
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      quotes {
        items {
          id
          case {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          caseId
          isLeadFromAds
          isManualFee
          lineItems {
            items {
              id
              quote {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              quoteId
              caseLineItemProductId
              product {
                id
                sku
                title
                description
                createdAt
                updatedAt
              }
              description
              clientPays
              solicitorReceives
              subsidy
              platformFee
              feePercentage
              scope {
                includes
                excludes
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          payments {
            items {
              id
              caseId
              clientId
              quoteId
              type
              stripeAccountId
              stripePaymentIntentId
              toPlatform
              amount
              refunded
              solicitorId
              chargeType
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      payments {
        items {
          id
          caseId
          clientId
          quoteId
          type
          stripeAccountId
          stripePaymentIntentId
          toPlatform
          amount
          refunded
          solicitorId
          chargeType
          createdAt
          updatedAt
        }
        nextToken
      }
      caseSolicitorId
      solicitor {
        id
        user {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        name
        businessName
        sraRegistrationNumber
        stripeAccountId
        onboardingStatus {
          hasAccount
          accountType
          hasChargesEnabled
          hasSubmitDetails
          hasPayoutsEnabled
        }
        createdAt
        updatedAt
      }
      solicitorResponse {
        type
        solicitorId
        reason
      }
      completion {
        type
        details
      }
      review {
        items {
          id
          solicitorId
          caseId
          clientId
          rating
          comment
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      assignedToSolicitorAt
      askedClientToPayAt
      statusWhenCancelled
      exportedCaseId
      updatedAt
    }
  }
`;
export const deleteCase = /* GraphQL */ `
  mutation DeleteCase(
    $input: DeleteCaseInput!
    $condition: ModelCaseConditionInput
  ) {
    deleteCase(input: $input, condition: $condition) {
      id
      caseNumber
      brand
      productSKU
      hasChatbot
      payFirst
      context
      createdAt
      clientId
      client {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      clientKyc {
        isSubmitted
        isVerified
        verificationReport {
          amlReportFileKey
          kycReportFileKey
        }
        documentUploads
        identityUploads
      }
      files {
        name
        key
        lastModified
        size
      }
      details {
        type
        isTimeSensitive
        details
        summary
        ask
      }
      messages {
        items {
          id
          author {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          authorId
          case {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          caseId
          content
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      quotes {
        items {
          id
          case {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          caseId
          isLeadFromAds
          isManualFee
          lineItems {
            items {
              id
              quote {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              quoteId
              caseLineItemProductId
              product {
                id
                sku
                title
                description
                createdAt
                updatedAt
              }
              description
              clientPays
              solicitorReceives
              subsidy
              platformFee
              feePercentage
              scope {
                includes
                excludes
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          payments {
            items {
              id
              caseId
              clientId
              quoteId
              type
              stripeAccountId
              stripePaymentIntentId
              toPlatform
              amount
              refunded
              solicitorId
              chargeType
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      payments {
        items {
          id
          caseId
          clientId
          quoteId
          type
          stripeAccountId
          stripePaymentIntentId
          toPlatform
          amount
          refunded
          solicitorId
          chargeType
          createdAt
          updatedAt
        }
        nextToken
      }
      caseSolicitorId
      solicitor {
        id
        user {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        name
        businessName
        sraRegistrationNumber
        stripeAccountId
        onboardingStatus {
          hasAccount
          accountType
          hasChargesEnabled
          hasSubmitDetails
          hasPayoutsEnabled
        }
        createdAt
        updatedAt
      }
      solicitorResponse {
        type
        solicitorId
        reason
      }
      completion {
        type
        details
      }
      review {
        items {
          id
          solicitorId
          caseId
          clientId
          rating
          comment
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      assignedToSolicitorAt
      askedClientToPayAt
      statusWhenCancelled
      exportedCaseId
      updatedAt
    }
  }
`;
export const createReview = /* GraphQL */ `
  mutation CreateReview(
    $input: CreateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    createReview(input: $input, condition: $condition) {
      id
      solicitorId
      caseId
      clientId
      rating
      comment
      createdAt
      updatedAt
    }
  }
`;
export const updateReview = /* GraphQL */ `
  mutation UpdateReview(
    $input: UpdateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    updateReview(input: $input, condition: $condition) {
      id
      solicitorId
      caseId
      clientId
      rating
      comment
      createdAt
      updatedAt
    }
  }
`;
export const deleteReview = /* GraphQL */ `
  mutation DeleteReview(
    $input: DeleteReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    deleteReview(input: $input, condition: $condition) {
      id
      solicitorId
      caseId
      clientId
      rating
      comment
      createdAt
      updatedAt
    }
  }
`;
export const createCasePayment = /* GraphQL */ `
  mutation CreateCasePayment(
    $input: CreateCasePaymentInput!
    $condition: ModelCasePaymentConditionInput
  ) {
    createCasePayment(input: $input, condition: $condition) {
      id
      caseId
      clientId
      quoteId
      type
      stripeAccountId
      stripePaymentIntentId
      toPlatform
      amount
      refunded
      solicitorId
      chargeType
      createdAt
      updatedAt
    }
  }
`;
export const updateCasePayment = /* GraphQL */ `
  mutation UpdateCasePayment(
    $input: UpdateCasePaymentInput!
    $condition: ModelCasePaymentConditionInput
  ) {
    updateCasePayment(input: $input, condition: $condition) {
      id
      caseId
      clientId
      quoteId
      type
      stripeAccountId
      stripePaymentIntentId
      toPlatform
      amount
      refunded
      solicitorId
      chargeType
      createdAt
      updatedAt
    }
  }
`;
export const deleteCasePayment = /* GraphQL */ `
  mutation DeleteCasePayment(
    $input: DeleteCasePaymentInput!
    $condition: ModelCasePaymentConditionInput
  ) {
    deleteCasePayment(input: $input, condition: $condition) {
      id
      caseId
      clientId
      quoteId
      type
      stripeAccountId
      stripePaymentIntentId
      toPlatform
      amount
      refunded
      solicitorId
      chargeType
      createdAt
      updatedAt
    }
  }
`;
export const createCaseQuote = /* GraphQL */ `
  mutation CreateCaseQuote(
    $input: CreateCaseQuoteInput!
    $condition: ModelCaseQuoteConditionInput
  ) {
    createCaseQuote(input: $input, condition: $condition) {
      id
      case {
        id
        caseNumber
        brand
        productSKU
        hasChatbot
        payFirst
        context
        createdAt
        clientId
        client {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        clientKyc {
          isSubmitted
          isVerified
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          documentUploads
          identityUploads
        }
        files {
          name
          key
          lastModified
          size
        }
        details {
          type
          isTimeSensitive
          details
          summary
          ask
        }
        messages {
          items {
            id
            author {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            authorId
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            content
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            isLeadFromAds
            isManualFee
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        payments {
          items {
            id
            caseId
            clientId
            quoteId
            type
            stripeAccountId
            stripePaymentIntentId
            toPlatform
            amount
            refunded
            solicitorId
            chargeType
            createdAt
            updatedAt
          }
          nextToken
        }
        caseSolicitorId
        solicitor {
          id
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          name
          businessName
          sraRegistrationNumber
          stripeAccountId
          onboardingStatus {
            hasAccount
            accountType
            hasChargesEnabled
            hasSubmitDetails
            hasPayoutsEnabled
          }
          createdAt
          updatedAt
        }
        solicitorResponse {
          type
          solicitorId
          reason
        }
        completion {
          type
          details
        }
        review {
          items {
            id
            solicitorId
            caseId
            clientId
            rating
            comment
            createdAt
            updatedAt
          }
          nextToken
        }
        status
        assignedToSolicitorAt
        askedClientToPayAt
        statusWhenCancelled
        exportedCaseId
        updatedAt
      }
      caseId
      isLeadFromAds
      isManualFee
      lineItems {
        items {
          id
          quote {
            id
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            isLeadFromAds
            isManualFee
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          quoteId
          caseLineItemProductId
          product {
            id
            sku
            title
            description
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          description
          clientPays
          solicitorReceives
          subsidy
          platformFee
          feePercentage
          scope {
            includes
            excludes
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      payments {
        items {
          id
          caseId
          clientId
          quoteId
          type
          stripeAccountId
          stripePaymentIntentId
          toPlatform
          amount
          refunded
          solicitorId
          chargeType
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCaseQuote = /* GraphQL */ `
  mutation UpdateCaseQuote(
    $input: UpdateCaseQuoteInput!
    $condition: ModelCaseQuoteConditionInput
  ) {
    updateCaseQuote(input: $input, condition: $condition) {
      id
      case {
        id
        caseNumber
        brand
        productSKU
        hasChatbot
        payFirst
        context
        createdAt
        clientId
        client {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        clientKyc {
          isSubmitted
          isVerified
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          documentUploads
          identityUploads
        }
        files {
          name
          key
          lastModified
          size
        }
        details {
          type
          isTimeSensitive
          details
          summary
          ask
        }
        messages {
          items {
            id
            author {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            authorId
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            content
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            isLeadFromAds
            isManualFee
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        payments {
          items {
            id
            caseId
            clientId
            quoteId
            type
            stripeAccountId
            stripePaymentIntentId
            toPlatform
            amount
            refunded
            solicitorId
            chargeType
            createdAt
            updatedAt
          }
          nextToken
        }
        caseSolicitorId
        solicitor {
          id
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          name
          businessName
          sraRegistrationNumber
          stripeAccountId
          onboardingStatus {
            hasAccount
            accountType
            hasChargesEnabled
            hasSubmitDetails
            hasPayoutsEnabled
          }
          createdAt
          updatedAt
        }
        solicitorResponse {
          type
          solicitorId
          reason
        }
        completion {
          type
          details
        }
        review {
          items {
            id
            solicitorId
            caseId
            clientId
            rating
            comment
            createdAt
            updatedAt
          }
          nextToken
        }
        status
        assignedToSolicitorAt
        askedClientToPayAt
        statusWhenCancelled
        exportedCaseId
        updatedAt
      }
      caseId
      isLeadFromAds
      isManualFee
      lineItems {
        items {
          id
          quote {
            id
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            isLeadFromAds
            isManualFee
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          quoteId
          caseLineItemProductId
          product {
            id
            sku
            title
            description
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          description
          clientPays
          solicitorReceives
          subsidy
          platformFee
          feePercentage
          scope {
            includes
            excludes
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      payments {
        items {
          id
          caseId
          clientId
          quoteId
          type
          stripeAccountId
          stripePaymentIntentId
          toPlatform
          amount
          refunded
          solicitorId
          chargeType
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCaseQuote = /* GraphQL */ `
  mutation DeleteCaseQuote(
    $input: DeleteCaseQuoteInput!
    $condition: ModelCaseQuoteConditionInput
  ) {
    deleteCaseQuote(input: $input, condition: $condition) {
      id
      case {
        id
        caseNumber
        brand
        productSKU
        hasChatbot
        payFirst
        context
        createdAt
        clientId
        client {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        clientKyc {
          isSubmitted
          isVerified
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          documentUploads
          identityUploads
        }
        files {
          name
          key
          lastModified
          size
        }
        details {
          type
          isTimeSensitive
          details
          summary
          ask
        }
        messages {
          items {
            id
            author {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            authorId
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            content
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            isLeadFromAds
            isManualFee
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        payments {
          items {
            id
            caseId
            clientId
            quoteId
            type
            stripeAccountId
            stripePaymentIntentId
            toPlatform
            amount
            refunded
            solicitorId
            chargeType
            createdAt
            updatedAt
          }
          nextToken
        }
        caseSolicitorId
        solicitor {
          id
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          name
          businessName
          sraRegistrationNumber
          stripeAccountId
          onboardingStatus {
            hasAccount
            accountType
            hasChargesEnabled
            hasSubmitDetails
            hasPayoutsEnabled
          }
          createdAt
          updatedAt
        }
        solicitorResponse {
          type
          solicitorId
          reason
        }
        completion {
          type
          details
        }
        review {
          items {
            id
            solicitorId
            caseId
            clientId
            rating
            comment
            createdAt
            updatedAt
          }
          nextToken
        }
        status
        assignedToSolicitorAt
        askedClientToPayAt
        statusWhenCancelled
        exportedCaseId
        updatedAt
      }
      caseId
      isLeadFromAds
      isManualFee
      lineItems {
        items {
          id
          quote {
            id
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            isLeadFromAds
            isManualFee
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          quoteId
          caseLineItemProductId
          product {
            id
            sku
            title
            description
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          description
          clientPays
          solicitorReceives
          subsidy
          platformFee
          feePercentage
          scope {
            includes
            excludes
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      payments {
        items {
          id
          caseId
          clientId
          quoteId
          type
          stripeAccountId
          stripePaymentIntentId
          toPlatform
          amount
          refunded
          solicitorId
          chargeType
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCaseLineItem = /* GraphQL */ `
  mutation CreateCaseLineItem(
    $input: CreateCaseLineItemInput!
    $condition: ModelCaseLineItemConditionInput
  ) {
    createCaseLineItem(input: $input, condition: $condition) {
      id
      quote {
        id
        case {
          id
          caseNumber
          brand
          productSKU
          hasChatbot
          payFirst
          context
          createdAt
          clientId
          client {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          clientKyc {
            isSubmitted
            isVerified
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            documentUploads
            identityUploads
          }
          files {
            name
            key
            lastModified
            size
          }
          details {
            type
            isTimeSensitive
            details
            summary
            ask
          }
          messages {
            items {
              id
              author {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              authorId
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              content
              type
              createdAt
              updatedAt
            }
            nextToken
          }
          quotes {
            items {
              id
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              isLeadFromAds
              isManualFee
              lineItems {
                nextToken
              }
              payments {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          payments {
            items {
              id
              caseId
              clientId
              quoteId
              type
              stripeAccountId
              stripePaymentIntentId
              toPlatform
              amount
              refunded
              solicitorId
              chargeType
              createdAt
              updatedAt
            }
            nextToken
          }
          caseSolicitorId
          solicitor {
            id
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            name
            businessName
            sraRegistrationNumber
            stripeAccountId
            onboardingStatus {
              hasAccount
              accountType
              hasChargesEnabled
              hasSubmitDetails
              hasPayoutsEnabled
            }
            createdAt
            updatedAt
          }
          solicitorResponse {
            type
            solicitorId
            reason
          }
          completion {
            type
            details
          }
          review {
            items {
              id
              solicitorId
              caseId
              clientId
              rating
              comment
              createdAt
              updatedAt
            }
            nextToken
          }
          status
          assignedToSolicitorAt
          askedClientToPayAt
          statusWhenCancelled
          exportedCaseId
          updatedAt
        }
        caseId
        isLeadFromAds
        isManualFee
        lineItems {
          items {
            id
            quote {
              id
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              isLeadFromAds
              isManualFee
              lineItems {
                nextToken
              }
              payments {
                nextToken
              }
              createdAt
              updatedAt
            }
            quoteId
            caseLineItemProductId
            product {
              id
              sku
              title
              description
              lineItems {
                nextToken
              }
              createdAt
              updatedAt
            }
            description
            clientPays
            solicitorReceives
            subsidy
            platformFee
            feePercentage
            scope {
              includes
              excludes
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        payments {
          items {
            id
            caseId
            clientId
            quoteId
            type
            stripeAccountId
            stripePaymentIntentId
            toPlatform
            amount
            refunded
            solicitorId
            chargeType
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      quoteId
      caseLineItemProductId
      product {
        id
        sku
        title
        description
        lineItems {
          items {
            id
            quote {
              id
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              isLeadFromAds
              isManualFee
              lineItems {
                nextToken
              }
              payments {
                nextToken
              }
              createdAt
              updatedAt
            }
            quoteId
            caseLineItemProductId
            product {
              id
              sku
              title
              description
              lineItems {
                nextToken
              }
              createdAt
              updatedAt
            }
            description
            clientPays
            solicitorReceives
            subsidy
            platformFee
            feePercentage
            scope {
              includes
              excludes
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      description
      clientPays
      solicitorReceives
      subsidy
      platformFee
      feePercentage
      scope {
        includes
        excludes
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCaseLineItem = /* GraphQL */ `
  mutation UpdateCaseLineItem(
    $input: UpdateCaseLineItemInput!
    $condition: ModelCaseLineItemConditionInput
  ) {
    updateCaseLineItem(input: $input, condition: $condition) {
      id
      quote {
        id
        case {
          id
          caseNumber
          brand
          productSKU
          hasChatbot
          payFirst
          context
          createdAt
          clientId
          client {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          clientKyc {
            isSubmitted
            isVerified
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            documentUploads
            identityUploads
          }
          files {
            name
            key
            lastModified
            size
          }
          details {
            type
            isTimeSensitive
            details
            summary
            ask
          }
          messages {
            items {
              id
              author {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              authorId
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              content
              type
              createdAt
              updatedAt
            }
            nextToken
          }
          quotes {
            items {
              id
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              isLeadFromAds
              isManualFee
              lineItems {
                nextToken
              }
              payments {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          payments {
            items {
              id
              caseId
              clientId
              quoteId
              type
              stripeAccountId
              stripePaymentIntentId
              toPlatform
              amount
              refunded
              solicitorId
              chargeType
              createdAt
              updatedAt
            }
            nextToken
          }
          caseSolicitorId
          solicitor {
            id
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            name
            businessName
            sraRegistrationNumber
            stripeAccountId
            onboardingStatus {
              hasAccount
              accountType
              hasChargesEnabled
              hasSubmitDetails
              hasPayoutsEnabled
            }
            createdAt
            updatedAt
          }
          solicitorResponse {
            type
            solicitorId
            reason
          }
          completion {
            type
            details
          }
          review {
            items {
              id
              solicitorId
              caseId
              clientId
              rating
              comment
              createdAt
              updatedAt
            }
            nextToken
          }
          status
          assignedToSolicitorAt
          askedClientToPayAt
          statusWhenCancelled
          exportedCaseId
          updatedAt
        }
        caseId
        isLeadFromAds
        isManualFee
        lineItems {
          items {
            id
            quote {
              id
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              isLeadFromAds
              isManualFee
              lineItems {
                nextToken
              }
              payments {
                nextToken
              }
              createdAt
              updatedAt
            }
            quoteId
            caseLineItemProductId
            product {
              id
              sku
              title
              description
              lineItems {
                nextToken
              }
              createdAt
              updatedAt
            }
            description
            clientPays
            solicitorReceives
            subsidy
            platformFee
            feePercentage
            scope {
              includes
              excludes
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        payments {
          items {
            id
            caseId
            clientId
            quoteId
            type
            stripeAccountId
            stripePaymentIntentId
            toPlatform
            amount
            refunded
            solicitorId
            chargeType
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      quoteId
      caseLineItemProductId
      product {
        id
        sku
        title
        description
        lineItems {
          items {
            id
            quote {
              id
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              isLeadFromAds
              isManualFee
              lineItems {
                nextToken
              }
              payments {
                nextToken
              }
              createdAt
              updatedAt
            }
            quoteId
            caseLineItemProductId
            product {
              id
              sku
              title
              description
              lineItems {
                nextToken
              }
              createdAt
              updatedAt
            }
            description
            clientPays
            solicitorReceives
            subsidy
            platformFee
            feePercentage
            scope {
              includes
              excludes
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      description
      clientPays
      solicitorReceives
      subsidy
      platformFee
      feePercentage
      scope {
        includes
        excludes
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCaseLineItem = /* GraphQL */ `
  mutation DeleteCaseLineItem(
    $input: DeleteCaseLineItemInput!
    $condition: ModelCaseLineItemConditionInput
  ) {
    deleteCaseLineItem(input: $input, condition: $condition) {
      id
      quote {
        id
        case {
          id
          caseNumber
          brand
          productSKU
          hasChatbot
          payFirst
          context
          createdAt
          clientId
          client {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          clientKyc {
            isSubmitted
            isVerified
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            documentUploads
            identityUploads
          }
          files {
            name
            key
            lastModified
            size
          }
          details {
            type
            isTimeSensitive
            details
            summary
            ask
          }
          messages {
            items {
              id
              author {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              authorId
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              content
              type
              createdAt
              updatedAt
            }
            nextToken
          }
          quotes {
            items {
              id
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              isLeadFromAds
              isManualFee
              lineItems {
                nextToken
              }
              payments {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          payments {
            items {
              id
              caseId
              clientId
              quoteId
              type
              stripeAccountId
              stripePaymentIntentId
              toPlatform
              amount
              refunded
              solicitorId
              chargeType
              createdAt
              updatedAt
            }
            nextToken
          }
          caseSolicitorId
          solicitor {
            id
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            name
            businessName
            sraRegistrationNumber
            stripeAccountId
            onboardingStatus {
              hasAccount
              accountType
              hasChargesEnabled
              hasSubmitDetails
              hasPayoutsEnabled
            }
            createdAt
            updatedAt
          }
          solicitorResponse {
            type
            solicitorId
            reason
          }
          completion {
            type
            details
          }
          review {
            items {
              id
              solicitorId
              caseId
              clientId
              rating
              comment
              createdAt
              updatedAt
            }
            nextToken
          }
          status
          assignedToSolicitorAt
          askedClientToPayAt
          statusWhenCancelled
          exportedCaseId
          updatedAt
        }
        caseId
        isLeadFromAds
        isManualFee
        lineItems {
          items {
            id
            quote {
              id
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              isLeadFromAds
              isManualFee
              lineItems {
                nextToken
              }
              payments {
                nextToken
              }
              createdAt
              updatedAt
            }
            quoteId
            caseLineItemProductId
            product {
              id
              sku
              title
              description
              lineItems {
                nextToken
              }
              createdAt
              updatedAt
            }
            description
            clientPays
            solicitorReceives
            subsidy
            platformFee
            feePercentage
            scope {
              includes
              excludes
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        payments {
          items {
            id
            caseId
            clientId
            quoteId
            type
            stripeAccountId
            stripePaymentIntentId
            toPlatform
            amount
            refunded
            solicitorId
            chargeType
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      quoteId
      caseLineItemProductId
      product {
        id
        sku
        title
        description
        lineItems {
          items {
            id
            quote {
              id
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              isLeadFromAds
              isManualFee
              lineItems {
                nextToken
              }
              payments {
                nextToken
              }
              createdAt
              updatedAt
            }
            quoteId
            caseLineItemProductId
            product {
              id
              sku
              title
              description
              lineItems {
                nextToken
              }
              createdAt
              updatedAt
            }
            description
            clientPays
            solicitorReceives
            subsidy
            platformFee
            feePercentage
            scope {
              includes
              excludes
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      description
      clientPays
      solicitorReceives
      subsidy
      platformFee
      feePercentage
      scope {
        includes
        excludes
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCaseCompletionRequest = /* GraphQL */ `
  mutation CreateCaseCompletionRequest(
    $input: CreateCaseCompletionRequestInput!
    $condition: ModelCaseCompletionRequestConditionInput
  ) {
    createCaseCompletionRequest(input: $input, condition: $condition) {
      id
      caseId
      case {
        id
        caseNumber
        brand
        productSKU
        hasChatbot
        payFirst
        context
        createdAt
        clientId
        client {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        clientKyc {
          isSubmitted
          isVerified
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          documentUploads
          identityUploads
        }
        files {
          name
          key
          lastModified
          size
        }
        details {
          type
          isTimeSensitive
          details
          summary
          ask
        }
        messages {
          items {
            id
            author {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            authorId
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            content
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            isLeadFromAds
            isManualFee
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        payments {
          items {
            id
            caseId
            clientId
            quoteId
            type
            stripeAccountId
            stripePaymentIntentId
            toPlatform
            amount
            refunded
            solicitorId
            chargeType
            createdAt
            updatedAt
          }
          nextToken
        }
        caseSolicitorId
        solicitor {
          id
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          name
          businessName
          sraRegistrationNumber
          stripeAccountId
          onboardingStatus {
            hasAccount
            accountType
            hasChargesEnabled
            hasSubmitDetails
            hasPayoutsEnabled
          }
          createdAt
          updatedAt
        }
        solicitorResponse {
          type
          solicitorId
          reason
        }
        completion {
          type
          details
        }
        review {
          items {
            id
            solicitorId
            caseId
            clientId
            rating
            comment
            createdAt
            updatedAt
          }
          nextToken
        }
        status
        assignedToSolicitorAt
        askedClientToPayAt
        statusWhenCancelled
        exportedCaseId
        updatedAt
      }
      requesterId
      requester {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      accepted
      createdAt
      updatedAt
    }
  }
`;
export const updateCaseCompletionRequest = /* GraphQL */ `
  mutation UpdateCaseCompletionRequest(
    $input: UpdateCaseCompletionRequestInput!
    $condition: ModelCaseCompletionRequestConditionInput
  ) {
    updateCaseCompletionRequest(input: $input, condition: $condition) {
      id
      caseId
      case {
        id
        caseNumber
        brand
        productSKU
        hasChatbot
        payFirst
        context
        createdAt
        clientId
        client {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        clientKyc {
          isSubmitted
          isVerified
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          documentUploads
          identityUploads
        }
        files {
          name
          key
          lastModified
          size
        }
        details {
          type
          isTimeSensitive
          details
          summary
          ask
        }
        messages {
          items {
            id
            author {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            authorId
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            content
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            isLeadFromAds
            isManualFee
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        payments {
          items {
            id
            caseId
            clientId
            quoteId
            type
            stripeAccountId
            stripePaymentIntentId
            toPlatform
            amount
            refunded
            solicitorId
            chargeType
            createdAt
            updatedAt
          }
          nextToken
        }
        caseSolicitorId
        solicitor {
          id
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          name
          businessName
          sraRegistrationNumber
          stripeAccountId
          onboardingStatus {
            hasAccount
            accountType
            hasChargesEnabled
            hasSubmitDetails
            hasPayoutsEnabled
          }
          createdAt
          updatedAt
        }
        solicitorResponse {
          type
          solicitorId
          reason
        }
        completion {
          type
          details
        }
        review {
          items {
            id
            solicitorId
            caseId
            clientId
            rating
            comment
            createdAt
            updatedAt
          }
          nextToken
        }
        status
        assignedToSolicitorAt
        askedClientToPayAt
        statusWhenCancelled
        exportedCaseId
        updatedAt
      }
      requesterId
      requester {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      accepted
      createdAt
      updatedAt
    }
  }
`;
export const deleteCaseCompletionRequest = /* GraphQL */ `
  mutation DeleteCaseCompletionRequest(
    $input: DeleteCaseCompletionRequestInput!
    $condition: ModelCaseCompletionRequestConditionInput
  ) {
    deleteCaseCompletionRequest(input: $input, condition: $condition) {
      id
      caseId
      case {
        id
        caseNumber
        brand
        productSKU
        hasChatbot
        payFirst
        context
        createdAt
        clientId
        client {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        clientKyc {
          isSubmitted
          isVerified
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          documentUploads
          identityUploads
        }
        files {
          name
          key
          lastModified
          size
        }
        details {
          type
          isTimeSensitive
          details
          summary
          ask
        }
        messages {
          items {
            id
            author {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            authorId
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            content
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            isLeadFromAds
            isManualFee
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        payments {
          items {
            id
            caseId
            clientId
            quoteId
            type
            stripeAccountId
            stripePaymentIntentId
            toPlatform
            amount
            refunded
            solicitorId
            chargeType
            createdAt
            updatedAt
          }
          nextToken
        }
        caseSolicitorId
        solicitor {
          id
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          name
          businessName
          sraRegistrationNumber
          stripeAccountId
          onboardingStatus {
            hasAccount
            accountType
            hasChargesEnabled
            hasSubmitDetails
            hasPayoutsEnabled
          }
          createdAt
          updatedAt
        }
        solicitorResponse {
          type
          solicitorId
          reason
        }
        completion {
          type
          details
        }
        review {
          items {
            id
            solicitorId
            caseId
            clientId
            rating
            comment
            createdAt
            updatedAt
          }
          nextToken
        }
        status
        assignedToSolicitorAt
        askedClientToPayAt
        statusWhenCancelled
        exportedCaseId
        updatedAt
      }
      requesterId
      requester {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      accepted
      createdAt
      updatedAt
    }
  }
`;
export const createConfiguration = /* GraphQL */ `
  mutation CreateConfiguration(
    $input: CreateConfigurationInput!
    $condition: ModelConfigurationConditionInput
  ) {
    createConfiguration(input: $input, condition: $condition) {
      key
      value
      createdAt
      updatedAt
    }
  }
`;
export const updateConfiguration = /* GraphQL */ `
  mutation UpdateConfiguration(
    $input: UpdateConfigurationInput!
    $condition: ModelConfigurationConditionInput
  ) {
    updateConfiguration(input: $input, condition: $condition) {
      key
      value
      createdAt
      updatedAt
    }
  }
`;
export const deleteConfiguration = /* GraphQL */ `
  mutation DeleteConfiguration(
    $input: DeleteConfigurationInput!
    $condition: ModelConfigurationConditionInput
  ) {
    deleteConfiguration(input: $input, condition: $condition) {
      key
      value
      createdAt
      updatedAt
    }
  }
`;
export const createKycSubmission = /* GraphQL */ `
  mutation CreateKycSubmission(
    $input: CreateKYCSubmissionInput!
    $condition: ModelKYCSubmissionConditionInput
  ) {
    createKYCSubmission(input: $input, condition: $condition) {
      userId
      user {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      status
      address
      documentUploads
      identityUploads
      verificationReport {
        amlReportFileKey
        kycReportFileKey
      }
      rejectionReason {
        reason
        instructions
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateKycSubmission = /* GraphQL */ `
  mutation UpdateKycSubmission(
    $input: UpdateKYCSubmissionInput!
    $condition: ModelKYCSubmissionConditionInput
  ) {
    updateKYCSubmission(input: $input, condition: $condition) {
      userId
      user {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      status
      address
      documentUploads
      identityUploads
      verificationReport {
        amlReportFileKey
        kycReportFileKey
      }
      rejectionReason {
        reason
        instructions
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteKycSubmission = /* GraphQL */ `
  mutation DeleteKycSubmission(
    $input: DeleteKYCSubmissionInput!
    $condition: ModelKYCSubmissionConditionInput
  ) {
    deleteKYCSubmission(input: $input, condition: $condition) {
      userId
      user {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      status
      address
      documentUploads
      identityUploads
      verificationReport {
        amlReportFileKey
        kycReportFileKey
      }
      rejectionReason {
        reason
        instructions
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      author {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      authorId
      case {
        id
        caseNumber
        brand
        productSKU
        hasChatbot
        payFirst
        context
        createdAt
        clientId
        client {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        clientKyc {
          isSubmitted
          isVerified
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          documentUploads
          identityUploads
        }
        files {
          name
          key
          lastModified
          size
        }
        details {
          type
          isTimeSensitive
          details
          summary
          ask
        }
        messages {
          items {
            id
            author {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            authorId
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            content
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            isLeadFromAds
            isManualFee
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        payments {
          items {
            id
            caseId
            clientId
            quoteId
            type
            stripeAccountId
            stripePaymentIntentId
            toPlatform
            amount
            refunded
            solicitorId
            chargeType
            createdAt
            updatedAt
          }
          nextToken
        }
        caseSolicitorId
        solicitor {
          id
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          name
          businessName
          sraRegistrationNumber
          stripeAccountId
          onboardingStatus {
            hasAccount
            accountType
            hasChargesEnabled
            hasSubmitDetails
            hasPayoutsEnabled
          }
          createdAt
          updatedAt
        }
        solicitorResponse {
          type
          solicitorId
          reason
        }
        completion {
          type
          details
        }
        review {
          items {
            id
            solicitorId
            caseId
            clientId
            rating
            comment
            createdAt
            updatedAt
          }
          nextToken
        }
        status
        assignedToSolicitorAt
        askedClientToPayAt
        statusWhenCancelled
        exportedCaseId
        updatedAt
      }
      caseId
      content
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      author {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      authorId
      case {
        id
        caseNumber
        brand
        productSKU
        hasChatbot
        payFirst
        context
        createdAt
        clientId
        client {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        clientKyc {
          isSubmitted
          isVerified
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          documentUploads
          identityUploads
        }
        files {
          name
          key
          lastModified
          size
        }
        details {
          type
          isTimeSensitive
          details
          summary
          ask
        }
        messages {
          items {
            id
            author {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            authorId
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            content
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            isLeadFromAds
            isManualFee
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        payments {
          items {
            id
            caseId
            clientId
            quoteId
            type
            stripeAccountId
            stripePaymentIntentId
            toPlatform
            amount
            refunded
            solicitorId
            chargeType
            createdAt
            updatedAt
          }
          nextToken
        }
        caseSolicitorId
        solicitor {
          id
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          name
          businessName
          sraRegistrationNumber
          stripeAccountId
          onboardingStatus {
            hasAccount
            accountType
            hasChargesEnabled
            hasSubmitDetails
            hasPayoutsEnabled
          }
          createdAt
          updatedAt
        }
        solicitorResponse {
          type
          solicitorId
          reason
        }
        completion {
          type
          details
        }
        review {
          items {
            id
            solicitorId
            caseId
            clientId
            rating
            comment
            createdAt
            updatedAt
          }
          nextToken
        }
        status
        assignedToSolicitorAt
        askedClientToPayAt
        statusWhenCancelled
        exportedCaseId
        updatedAt
      }
      caseId
      content
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      author {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      authorId
      case {
        id
        caseNumber
        brand
        productSKU
        hasChatbot
        payFirst
        context
        createdAt
        clientId
        client {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        clientKyc {
          isSubmitted
          isVerified
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          documentUploads
          identityUploads
        }
        files {
          name
          key
          lastModified
          size
        }
        details {
          type
          isTimeSensitive
          details
          summary
          ask
        }
        messages {
          items {
            id
            author {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            authorId
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            content
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            isLeadFromAds
            isManualFee
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        payments {
          items {
            id
            caseId
            clientId
            quoteId
            type
            stripeAccountId
            stripePaymentIntentId
            toPlatform
            amount
            refunded
            solicitorId
            chargeType
            createdAt
            updatedAt
          }
          nextToken
        }
        caseSolicitorId
        solicitor {
          id
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          name
          businessName
          sraRegistrationNumber
          stripeAccountId
          onboardingStatus {
            hasAccount
            accountType
            hasChargesEnabled
            hasSubmitDetails
            hasPayoutsEnabled
          }
          createdAt
          updatedAt
        }
        solicitorResponse {
          type
          solicitorId
          reason
        }
        completion {
          type
          details
        }
        review {
          items {
            id
            solicitorId
            caseId
            clientId
            rating
            comment
            createdAt
            updatedAt
          }
          nextToken
        }
        status
        assignedToSolicitorAt
        askedClientToPayAt
        statusWhenCancelled
        exportedCaseId
        updatedAt
      }
      caseId
      content
      type
      createdAt
      updatedAt
    }
  }
`;
export const createNotifcationEmail = /* GraphQL */ `
  mutation CreateNotifcationEmail(
    $input: CreateNotifcationEmailInput!
    $condition: ModelNotifcationEmailConditionInput
  ) {
    createNotifcationEmail(input: $input, condition: $condition) {
      id
      user {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      userId
      caseId
      case {
        id
        caseNumber
        brand
        productSKU
        hasChatbot
        payFirst
        context
        createdAt
        clientId
        client {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        clientKyc {
          isSubmitted
          isVerified
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          documentUploads
          identityUploads
        }
        files {
          name
          key
          lastModified
          size
        }
        details {
          type
          isTimeSensitive
          details
          summary
          ask
        }
        messages {
          items {
            id
            author {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            authorId
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            content
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            isLeadFromAds
            isManualFee
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        payments {
          items {
            id
            caseId
            clientId
            quoteId
            type
            stripeAccountId
            stripePaymentIntentId
            toPlatform
            amount
            refunded
            solicitorId
            chargeType
            createdAt
            updatedAt
          }
          nextToken
        }
        caseSolicitorId
        solicitor {
          id
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          name
          businessName
          sraRegistrationNumber
          stripeAccountId
          onboardingStatus {
            hasAccount
            accountType
            hasChargesEnabled
            hasSubmitDetails
            hasPayoutsEnabled
          }
          createdAt
          updatedAt
        }
        solicitorResponse {
          type
          solicitorId
          reason
        }
        completion {
          type
          details
        }
        review {
          items {
            id
            solicitorId
            caseId
            clientId
            rating
            comment
            createdAt
            updatedAt
          }
          nextToken
        }
        status
        assignedToSolicitorAt
        askedClientToPayAt
        statusWhenCancelled
        exportedCaseId
        updatedAt
      }
      type
      lastSentAt
      createdAt
      updatedAt
    }
  }
`;
export const updateNotifcationEmail = /* GraphQL */ `
  mutation UpdateNotifcationEmail(
    $input: UpdateNotifcationEmailInput!
    $condition: ModelNotifcationEmailConditionInput
  ) {
    updateNotifcationEmail(input: $input, condition: $condition) {
      id
      user {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      userId
      caseId
      case {
        id
        caseNumber
        brand
        productSKU
        hasChatbot
        payFirst
        context
        createdAt
        clientId
        client {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        clientKyc {
          isSubmitted
          isVerified
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          documentUploads
          identityUploads
        }
        files {
          name
          key
          lastModified
          size
        }
        details {
          type
          isTimeSensitive
          details
          summary
          ask
        }
        messages {
          items {
            id
            author {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            authorId
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            content
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            isLeadFromAds
            isManualFee
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        payments {
          items {
            id
            caseId
            clientId
            quoteId
            type
            stripeAccountId
            stripePaymentIntentId
            toPlatform
            amount
            refunded
            solicitorId
            chargeType
            createdAt
            updatedAt
          }
          nextToken
        }
        caseSolicitorId
        solicitor {
          id
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          name
          businessName
          sraRegistrationNumber
          stripeAccountId
          onboardingStatus {
            hasAccount
            accountType
            hasChargesEnabled
            hasSubmitDetails
            hasPayoutsEnabled
          }
          createdAt
          updatedAt
        }
        solicitorResponse {
          type
          solicitorId
          reason
        }
        completion {
          type
          details
        }
        review {
          items {
            id
            solicitorId
            caseId
            clientId
            rating
            comment
            createdAt
            updatedAt
          }
          nextToken
        }
        status
        assignedToSolicitorAt
        askedClientToPayAt
        statusWhenCancelled
        exportedCaseId
        updatedAt
      }
      type
      lastSentAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotifcationEmail = /* GraphQL */ `
  mutation DeleteNotifcationEmail(
    $input: DeleteNotifcationEmailInput!
    $condition: ModelNotifcationEmailConditionInput
  ) {
    deleteNotifcationEmail(input: $input, condition: $condition) {
      id
      user {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      userId
      caseId
      case {
        id
        caseNumber
        brand
        productSKU
        hasChatbot
        payFirst
        context
        createdAt
        clientId
        client {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        clientKyc {
          isSubmitted
          isVerified
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          documentUploads
          identityUploads
        }
        files {
          name
          key
          lastModified
          size
        }
        details {
          type
          isTimeSensitive
          details
          summary
          ask
        }
        messages {
          items {
            id
            author {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            authorId
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            content
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            isLeadFromAds
            isManualFee
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        payments {
          items {
            id
            caseId
            clientId
            quoteId
            type
            stripeAccountId
            stripePaymentIntentId
            toPlatform
            amount
            refunded
            solicitorId
            chargeType
            createdAt
            updatedAt
          }
          nextToken
        }
        caseSolicitorId
        solicitor {
          id
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          name
          businessName
          sraRegistrationNumber
          stripeAccountId
          onboardingStatus {
            hasAccount
            accountType
            hasChargesEnabled
            hasSubmitDetails
            hasPayoutsEnabled
          }
          createdAt
          updatedAt
        }
        solicitorResponse {
          type
          solicitorId
          reason
        }
        completion {
          type
          details
        }
        review {
          items {
            id
            solicitorId
            caseId
            clientId
            rating
            comment
            createdAt
            updatedAt
          }
          nextToken
        }
        status
        assignedToSolicitorAt
        askedClientToPayAt
        statusWhenCancelled
        exportedCaseId
        updatedAt
      }
      type
      lastSentAt
      createdAt
      updatedAt
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      sku
      title
      description
      lineItems {
        items {
          id
          quote {
            id
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            isLeadFromAds
            isManualFee
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          quoteId
          caseLineItemProductId
          product {
            id
            sku
            title
            description
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          description
          clientPays
          solicitorReceives
          subsidy
          platformFee
          feePercentage
          scope {
            includes
            excludes
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      sku
      title
      description
      lineItems {
        items {
          id
          quote {
            id
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            isLeadFromAds
            isManualFee
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          quoteId
          caseLineItemProductId
          product {
            id
            sku
            title
            description
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          description
          clientPays
          solicitorReceives
          subsidy
          platformFee
          feePercentage
          scope {
            includes
            excludes
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      sku
      title
      description
      lineItems {
        items {
          id
          quote {
            id
            case {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            caseId
            isLeadFromAds
            isManualFee
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          quoteId
          caseLineItemProductId
          product {
            id
            sku
            title
            description
            lineItems {
              items {
                id
                quoteId
                caseLineItemProductId
                description
                clientPays
                solicitorReceives
                subsidy
                platformFee
                feePercentage
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          description
          clientPays
          solicitorReceives
          subsidy
          platformFee
          feePercentage
          scope {
            includes
            excludes
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSolicitor = /* GraphQL */ `
  mutation CreateSolicitor(
    $input: CreateSolicitorInput!
    $condition: ModelSolicitorConditionInput
  ) {
    createSolicitor(input: $input, condition: $condition) {
      id
      user {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      name
      businessName
      sraRegistrationNumber
      stripeAccountId
      onboardingStatus {
        hasAccount
        accountType
        hasChargesEnabled
        hasSubmitDetails
        hasPayoutsEnabled
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSolicitor = /* GraphQL */ `
  mutation UpdateSolicitor(
    $input: UpdateSolicitorInput!
    $condition: ModelSolicitorConditionInput
  ) {
    updateSolicitor(input: $input, condition: $condition) {
      id
      user {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      name
      businessName
      sraRegistrationNumber
      stripeAccountId
      onboardingStatus {
        hasAccount
        accountType
        hasChargesEnabled
        hasSubmitDetails
        hasPayoutsEnabled
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSolicitor = /* GraphQL */ `
  mutation DeleteSolicitor(
    $input: DeleteSolicitorInput!
    $condition: ModelSolicitorConditionInput
  ) {
    deleteSolicitor(input: $input, condition: $condition) {
      id
      user {
        id
        type
        emailAddress
        displayName
        phoneNumber
        kycSubmission {
          userId
          user {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          status
          address
          documentUploads
          identityUploads
          verificationReport {
            amlReportFileKey
            kycReportFileKey
          }
          rejectionReason {
            reason
            instructions
          }
          createdAt
          updatedAt
        }
        stripeCustomerId
        paymentMethods {
          id
          lastFour
          brand
          expiryMonth
          expiryYear
        }
        clientCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        solicitorCases {
          items {
            id
            caseNumber
            brand
            productSKU
            hasChatbot
            payFirst
            context
            createdAt
            clientId
            client {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            clientKyc {
              isSubmitted
              isVerified
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              documentUploads
              identityUploads
            }
            files {
              name
              key
              lastModified
              size
            }
            details {
              type
              isTimeSensitive
              details
              summary
              ask
            }
            messages {
              items {
                id
                authorId
                caseId
                content
                type
                createdAt
                updatedAt
              }
              nextToken
            }
            quotes {
              items {
                id
                caseId
                isLeadFromAds
                isManualFee
                createdAt
                updatedAt
              }
              nextToken
            }
            payments {
              items {
                id
                caseId
                clientId
                quoteId
                type
                stripeAccountId
                stripePaymentIntentId
                toPlatform
                amount
                refunded
                solicitorId
                chargeType
                createdAt
                updatedAt
              }
              nextToken
            }
            caseSolicitorId
            solicitor {
              id
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              name
              businessName
              sraRegistrationNumber
              stripeAccountId
              onboardingStatus {
                hasAccount
                accountType
                hasChargesEnabled
                hasSubmitDetails
                hasPayoutsEnabled
              }
              createdAt
              updatedAt
            }
            solicitorResponse {
              type
              solicitorId
              reason
            }
            completion {
              type
              details
            }
            review {
              items {
                id
                solicitorId
                caseId
                clientId
                rating
                comment
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assignedToSolicitorAt
            askedClientToPayAt
            statusWhenCancelled
            exportedCaseId
            updatedAt
          }
          nextToken
        }
        referral
        createdAt
        updatedAt
      }
      name
      businessName
      sraRegistrationNumber
      stripeAccountId
      onboardingStatus {
        hasAccount
        accountType
        hasChargesEnabled
        hasSubmitDetails
        hasPayoutsEnabled
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      type
      emailAddress
      displayName
      phoneNumber
      kycSubmission {
        userId
        user {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        status
        address
        documentUploads
        identityUploads
        verificationReport {
          amlReportFileKey
          kycReportFileKey
        }
        rejectionReason {
          reason
          instructions
        }
        createdAt
        updatedAt
      }
      stripeCustomerId
      paymentMethods {
        id
        lastFour
        brand
        expiryMonth
        expiryYear
      }
      clientCases {
        items {
          id
          caseNumber
          brand
          productSKU
          hasChatbot
          payFirst
          context
          createdAt
          clientId
          client {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          clientKyc {
            isSubmitted
            isVerified
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            documentUploads
            identityUploads
          }
          files {
            name
            key
            lastModified
            size
          }
          details {
            type
            isTimeSensitive
            details
            summary
            ask
          }
          messages {
            items {
              id
              author {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              authorId
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              content
              type
              createdAt
              updatedAt
            }
            nextToken
          }
          quotes {
            items {
              id
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              isLeadFromAds
              isManualFee
              lineItems {
                nextToken
              }
              payments {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          payments {
            items {
              id
              caseId
              clientId
              quoteId
              type
              stripeAccountId
              stripePaymentIntentId
              toPlatform
              amount
              refunded
              solicitorId
              chargeType
              createdAt
              updatedAt
            }
            nextToken
          }
          caseSolicitorId
          solicitor {
            id
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            name
            businessName
            sraRegistrationNumber
            stripeAccountId
            onboardingStatus {
              hasAccount
              accountType
              hasChargesEnabled
              hasSubmitDetails
              hasPayoutsEnabled
            }
            createdAt
            updatedAt
          }
          solicitorResponse {
            type
            solicitorId
            reason
          }
          completion {
            type
            details
          }
          review {
            items {
              id
              solicitorId
              caseId
              clientId
              rating
              comment
              createdAt
              updatedAt
            }
            nextToken
          }
          status
          assignedToSolicitorAt
          askedClientToPayAt
          statusWhenCancelled
          exportedCaseId
          updatedAt
        }
        nextToken
      }
      solicitorCases {
        items {
          id
          caseNumber
          brand
          productSKU
          hasChatbot
          payFirst
          context
          createdAt
          clientId
          client {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          clientKyc {
            isSubmitted
            isVerified
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            documentUploads
            identityUploads
          }
          files {
            name
            key
            lastModified
            size
          }
          details {
            type
            isTimeSensitive
            details
            summary
            ask
          }
          messages {
            items {
              id
              author {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              authorId
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              content
              type
              createdAt
              updatedAt
            }
            nextToken
          }
          quotes {
            items {
              id
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              isLeadFromAds
              isManualFee
              lineItems {
                nextToken
              }
              payments {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          payments {
            items {
              id
              caseId
              clientId
              quoteId
              type
              stripeAccountId
              stripePaymentIntentId
              toPlatform
              amount
              refunded
              solicitorId
              chargeType
              createdAt
              updatedAt
            }
            nextToken
          }
          caseSolicitorId
          solicitor {
            id
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            name
            businessName
            sraRegistrationNumber
            stripeAccountId
            onboardingStatus {
              hasAccount
              accountType
              hasChargesEnabled
              hasSubmitDetails
              hasPayoutsEnabled
            }
            createdAt
            updatedAt
          }
          solicitorResponse {
            type
            solicitorId
            reason
          }
          completion {
            type
            details
          }
          review {
            items {
              id
              solicitorId
              caseId
              clientId
              rating
              comment
              createdAt
              updatedAt
            }
            nextToken
          }
          status
          assignedToSolicitorAt
          askedClientToPayAt
          statusWhenCancelled
          exportedCaseId
          updatedAt
        }
        nextToken
      }
      referral
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      type
      emailAddress
      displayName
      phoneNumber
      kycSubmission {
        userId
        user {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        status
        address
        documentUploads
        identityUploads
        verificationReport {
          amlReportFileKey
          kycReportFileKey
        }
        rejectionReason {
          reason
          instructions
        }
        createdAt
        updatedAt
      }
      stripeCustomerId
      paymentMethods {
        id
        lastFour
        brand
        expiryMonth
        expiryYear
      }
      clientCases {
        items {
          id
          caseNumber
          brand
          productSKU
          hasChatbot
          payFirst
          context
          createdAt
          clientId
          client {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          clientKyc {
            isSubmitted
            isVerified
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            documentUploads
            identityUploads
          }
          files {
            name
            key
            lastModified
            size
          }
          details {
            type
            isTimeSensitive
            details
            summary
            ask
          }
          messages {
            items {
              id
              author {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              authorId
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              content
              type
              createdAt
              updatedAt
            }
            nextToken
          }
          quotes {
            items {
              id
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              isLeadFromAds
              isManualFee
              lineItems {
                nextToken
              }
              payments {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          payments {
            items {
              id
              caseId
              clientId
              quoteId
              type
              stripeAccountId
              stripePaymentIntentId
              toPlatform
              amount
              refunded
              solicitorId
              chargeType
              createdAt
              updatedAt
            }
            nextToken
          }
          caseSolicitorId
          solicitor {
            id
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            name
            businessName
            sraRegistrationNumber
            stripeAccountId
            onboardingStatus {
              hasAccount
              accountType
              hasChargesEnabled
              hasSubmitDetails
              hasPayoutsEnabled
            }
            createdAt
            updatedAt
          }
          solicitorResponse {
            type
            solicitorId
            reason
          }
          completion {
            type
            details
          }
          review {
            items {
              id
              solicitorId
              caseId
              clientId
              rating
              comment
              createdAt
              updatedAt
            }
            nextToken
          }
          status
          assignedToSolicitorAt
          askedClientToPayAt
          statusWhenCancelled
          exportedCaseId
          updatedAt
        }
        nextToken
      }
      solicitorCases {
        items {
          id
          caseNumber
          brand
          productSKU
          hasChatbot
          payFirst
          context
          createdAt
          clientId
          client {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          clientKyc {
            isSubmitted
            isVerified
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            documentUploads
            identityUploads
          }
          files {
            name
            key
            lastModified
            size
          }
          details {
            type
            isTimeSensitive
            details
            summary
            ask
          }
          messages {
            items {
              id
              author {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              authorId
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              content
              type
              createdAt
              updatedAt
            }
            nextToken
          }
          quotes {
            items {
              id
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              isLeadFromAds
              isManualFee
              lineItems {
                nextToken
              }
              payments {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          payments {
            items {
              id
              caseId
              clientId
              quoteId
              type
              stripeAccountId
              stripePaymentIntentId
              toPlatform
              amount
              refunded
              solicitorId
              chargeType
              createdAt
              updatedAt
            }
            nextToken
          }
          caseSolicitorId
          solicitor {
            id
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            name
            businessName
            sraRegistrationNumber
            stripeAccountId
            onboardingStatus {
              hasAccount
              accountType
              hasChargesEnabled
              hasSubmitDetails
              hasPayoutsEnabled
            }
            createdAt
            updatedAt
          }
          solicitorResponse {
            type
            solicitorId
            reason
          }
          completion {
            type
            details
          }
          review {
            items {
              id
              solicitorId
              caseId
              clientId
              rating
              comment
              createdAt
              updatedAt
            }
            nextToken
          }
          status
          assignedToSolicitorAt
          askedClientToPayAt
          statusWhenCancelled
          exportedCaseId
          updatedAt
        }
        nextToken
      }
      referral
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      type
      emailAddress
      displayName
      phoneNumber
      kycSubmission {
        userId
        user {
          id
          type
          emailAddress
          displayName
          phoneNumber
          kycSubmission {
            userId
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            status
            address
            documentUploads
            identityUploads
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            rejectionReason {
              reason
              instructions
            }
            createdAt
            updatedAt
          }
          stripeCustomerId
          paymentMethods {
            id
            lastFour
            brand
            expiryMonth
            expiryYear
          }
          clientCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          solicitorCases {
            items {
              id
              caseNumber
              brand
              productSKU
              hasChatbot
              payFirst
              context
              createdAt
              clientId
              client {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              clientKyc {
                isSubmitted
                isVerified
                documentUploads
                identityUploads
              }
              files {
                name
                key
                lastModified
                size
              }
              details {
                type
                isTimeSensitive
                details
                summary
                ask
              }
              messages {
                nextToken
              }
              quotes {
                nextToken
              }
              payments {
                nextToken
              }
              caseSolicitorId
              solicitor {
                id
                name
                businessName
                sraRegistrationNumber
                stripeAccountId
                createdAt
                updatedAt
              }
              solicitorResponse {
                type
                solicitorId
                reason
              }
              completion {
                type
                details
              }
              review {
                nextToken
              }
              status
              assignedToSolicitorAt
              askedClientToPayAt
              statusWhenCancelled
              exportedCaseId
              updatedAt
            }
            nextToken
          }
          referral
          createdAt
          updatedAt
        }
        status
        address
        documentUploads
        identityUploads
        verificationReport {
          amlReportFileKey
          kycReportFileKey
        }
        rejectionReason {
          reason
          instructions
        }
        createdAt
        updatedAt
      }
      stripeCustomerId
      paymentMethods {
        id
        lastFour
        brand
        expiryMonth
        expiryYear
      }
      clientCases {
        items {
          id
          caseNumber
          brand
          productSKU
          hasChatbot
          payFirst
          context
          createdAt
          clientId
          client {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          clientKyc {
            isSubmitted
            isVerified
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            documentUploads
            identityUploads
          }
          files {
            name
            key
            lastModified
            size
          }
          details {
            type
            isTimeSensitive
            details
            summary
            ask
          }
          messages {
            items {
              id
              author {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              authorId
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              content
              type
              createdAt
              updatedAt
            }
            nextToken
          }
          quotes {
            items {
              id
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              isLeadFromAds
              isManualFee
              lineItems {
                nextToken
              }
              payments {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          payments {
            items {
              id
              caseId
              clientId
              quoteId
              type
              stripeAccountId
              stripePaymentIntentId
              toPlatform
              amount
              refunded
              solicitorId
              chargeType
              createdAt
              updatedAt
            }
            nextToken
          }
          caseSolicitorId
          solicitor {
            id
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            name
            businessName
            sraRegistrationNumber
            stripeAccountId
            onboardingStatus {
              hasAccount
              accountType
              hasChargesEnabled
              hasSubmitDetails
              hasPayoutsEnabled
            }
            createdAt
            updatedAt
          }
          solicitorResponse {
            type
            solicitorId
            reason
          }
          completion {
            type
            details
          }
          review {
            items {
              id
              solicitorId
              caseId
              clientId
              rating
              comment
              createdAt
              updatedAt
            }
            nextToken
          }
          status
          assignedToSolicitorAt
          askedClientToPayAt
          statusWhenCancelled
          exportedCaseId
          updatedAt
        }
        nextToken
      }
      solicitorCases {
        items {
          id
          caseNumber
          brand
          productSKU
          hasChatbot
          payFirst
          context
          createdAt
          clientId
          client {
            id
            type
            emailAddress
            displayName
            phoneNumber
            kycSubmission {
              userId
              user {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              status
              address
              documentUploads
              identityUploads
              verificationReport {
                amlReportFileKey
                kycReportFileKey
              }
              rejectionReason {
                reason
                instructions
              }
              createdAt
              updatedAt
            }
            stripeCustomerId
            paymentMethods {
              id
              lastFour
              brand
              expiryMonth
              expiryYear
            }
            clientCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            solicitorCases {
              items {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              nextToken
            }
            referral
            createdAt
            updatedAt
          }
          clientKyc {
            isSubmitted
            isVerified
            verificationReport {
              amlReportFileKey
              kycReportFileKey
            }
            documentUploads
            identityUploads
          }
          files {
            name
            key
            lastModified
            size
          }
          details {
            type
            isTimeSensitive
            details
            summary
            ask
          }
          messages {
            items {
              id
              author {
                id
                type
                emailAddress
                displayName
                phoneNumber
                stripeCustomerId
                referral
                createdAt
                updatedAt
              }
              authorId
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              content
              type
              createdAt
              updatedAt
            }
            nextToken
          }
          quotes {
            items {
              id
              case {
                id
                caseNumber
                brand
                productSKU
                hasChatbot
                payFirst
                context
                createdAt
                clientId
                caseSolicitorId
                status
                assignedToSolicitorAt
                askedClientToPayAt
                statusWhenCancelled
                exportedCaseId
                updatedAt
              }
              caseId
              isLeadFromAds
              isManualFee
              lineItems {
                nextToken
              }
              payments {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          payments {
            items {
              id
              caseId
              clientId
              quoteId
              type
              stripeAccountId
              stripePaymentIntentId
              toPlatform
              amount
              refunded
              solicitorId
              chargeType
              createdAt
              updatedAt
            }
            nextToken
          }
          caseSolicitorId
          solicitor {
            id
            user {
              id
              type
              emailAddress
              displayName
              phoneNumber
              kycSubmission {
                userId
                status
                address
                documentUploads
                identityUploads
                createdAt
                updatedAt
              }
              stripeCustomerId
              paymentMethods {
                id
                lastFour
                brand
                expiryMonth
                expiryYear
              }
              clientCases {
                nextToken
              }
              solicitorCases {
                nextToken
              }
              referral
              createdAt
              updatedAt
            }
            name
            businessName
            sraRegistrationNumber
            stripeAccountId
            onboardingStatus {
              hasAccount
              accountType
              hasChargesEnabled
              hasSubmitDetails
              hasPayoutsEnabled
            }
            createdAt
            updatedAt
          }
          solicitorResponse {
            type
            solicitorId
            reason
          }
          completion {
            type
            details
          }
          review {
            items {
              id
              solicitorId
              caseId
              clientId
              rating
              comment
              createdAt
              updatedAt
            }
            nextToken
          }
          status
          assignedToSolicitorAt
          askedClientToPayAt
          statusWhenCancelled
          exportedCaseId
          updatedAt
        }
        nextToken
      }
      referral
      createdAt
      updatedAt
    }
  }
`;
