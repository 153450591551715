import { SuccessMessage } from "packages/app/src/elements"
import React, { useEffect, useState } from "react"

type QuoteSubmittedMessageProps = {
  initialVisible: boolean
}
export const QuoteSubmittedMessage = ({
  initialVisible
}: QuoteSubmittedMessageProps) => {

  useEffect(() => {setNotificationVisible(initialVisible)}, [initialVisible])

  const [notificationVisible, setNotificationVisible] = useState(initialVisible)

  return notificationVisible ? (
    <SuccessMessage
      title="Follow-on quote created"
      dismissable={true}
      onDismiss={() => setNotificationVisible(false)}
    >
      The follow-on quote has been created and sent to the client!
    </SuccessMessage>
  ) : null
}
