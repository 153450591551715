import { chain, isPending, map } from "@nll/datum/DatumEither"
import { pipe } from "fp-ts/es6/function"
import { chain as chainOption, getOrElse, isSome, map as mapOption, Option } from "fp-ts/es6/Option"
import * as TE from "fp-ts/es6/TaskEither"
import { useModal } from "packages/app/src/contexts/Modal"
import { Modal } from "packages/app/src/elements/Modal"
import { SolicitorPaymentsView } from "packages/app/src/features/quotes/routes/SolicitorPaymentsView"
import { useFromTaskEither } from "packages/app/src/lib/useAsync"
import { FC, useEffect } from "react"
import "twin.macro"
import { useCase } from "../../../../../contexts/case/CaseContext"
import { useCreateMessage } from "../../../../../contexts/case/useCreateMessage"
import { CaseDetails, User } from "../../../../../contexts/case/useGetCase"
import { useUser } from "../../../../../contexts/User"
import { Card, CardContent } from "../../../../../elements"
import { NeutralPill, PositivePill, YellowPill } from "../../../../../elements/Badge"
import { Button, ButtonGroup } from "../../../../../elements/Button"
import { FilesIcon, IdentificationIcon, MessageIcon, PaymentsIcon, SummaryIcon } from "../../../../../elements/Icons"
import { Spinner } from "../../../../../elements/Loading"
import { SolicitorCaseUIResponse, useGetCaseNew } from "../../../../../hooks/useGetCaseNew"
import { ROUTES } from "../../../../../layout/Navigation"
import { fetchAndCast } from "../../../../../lib/fetch"
import { Solicitor } from "../../../../../lib/types"
import { useApiFetch } from "../../../../../lib/useApiClient"
import { renderLoadingRefreshOrSuccess, renderNullOrSuccess } from "../../../../../lib/utils"
import { CaseStatusTitle, CaseStatusTitleHeader, ComponentWithCase } from "../../CaseDetails"
import { CaseFilesCard } from "../../shared/CaseFilesCard"
import { CaseSummaryCard } from "../../shared/CaseSummaryCard"
import { CaseTabLayout } from "../../shared/CaseTabLayout"
import { MessagesCardWithData } from "../../shared/MessagesCardWithData"
import { RequestCaseCompletionModal } from "../actions/RequestCaseCompletionModal"
import { SolicitorClientDetailsCard } from "../shared/SolicitorClientDetailsCard"
import { useRouter } from "../../../../../lib/useRouter"
import { useFeatureFlag } from "packages/app/src/contexts/Posthog"
import { useIntercom } from "react-use-intercom"
import { useCaseCommittedEvent } from "packages/app/src/features/analytics/useCaseCommittedEvent"

export const ActiveStatusPanel: ComponentWithCase = ({ case: cas }) => {
  return (
    <div tw="flex flex-row items-center">
      {isSome(cas.client) && (
        <>
          <div tw="mr-12">
            <CaseStatusTitleHeader>Your client</CaseStatusTitleHeader>
            <CaseStatusTitle>
              {getOrElse(() => "")(cas.client.value.displayName)}
            </CaseStatusTitle>
          </div>

          {isSome(cas.client.value.phoneNumber) && (
            <div tw="mr-12">
              <CaseStatusTitleHeader>Phone Number</CaseStatusTitleHeader>
              <CaseStatusTitle>
                {getOrElse(() => "")(cas.client.value.phoneNumber)}
              </CaseStatusTitle>
            </div>
          )}
        </>
      )}

      <div>
        <CaseStatusTitleHeader>KYC Status</CaseStatusTitleHeader>
        <p>
          <KycStatus
            submitted={cas.clientKyc?.isSubmitted || false}
            verified={cas.clientKyc?.isVerified || false}
          />
        </p>
      </div>
    </div>
  )
}

const KycStatus: FC<{ submitted: boolean; verified: boolean }> = ({
  submitted,
  verified
}) =>
  submitted ? (
    verified ? (
      <PositivePill>Identity Verified</PositivePill>
    ) : (
      <NeutralPill>Verification in progress</NeutralPill>
    )
  ) : (
    <YellowPill>Waiting for documents</YellowPill>
  )

export const ActiveActionPanel: ComponentWithCase = ({ case: cas }) => {
  const { userId } = useUser()
  const { status, execute } = useFromTaskEither(useGetCaseNew(cas.id))

  // const { status, execute: load }= useAsync(
  //   (caseId: string, requesterId: string) => graphql<ListCaseCompletionRequestByCaseRequesterQuery, ListCaseCompletionRequestByCaseRequesterQueryVariables>({
  //     query: /* GraphQL */ `
  //       query ListCaseCompletionRequestByCaseRequester($caseId: ID, $requesterId: ModelIDKeyConditionInput!) {
  //         listCaseCompletionRequestByCaseRequester(caseId: $caseId, requesterId: $requesterId) {
  //           items {
  //             id
  //           }
  //         }
  //       }
  //     `,
  //     variables: {
  //       caseId,
  //       requesterId: { eq: requesterId }
  //     }
  //   })
  //   .then(a => a.data?.listCaseCompletionRequestByCaseRequester?.items || [])
  // )

  useEffect(() => {
    execute()
  }, [cas, userId])

  const render = renderLoadingRefreshOrSuccess(
    () => <Spinner />,
    // (items: ListCaseCompletionRequestByCaseRequesterQuery['listCaseCompletionRequestByCaseRequester']['items']) =>
    (items: SolicitorCaseUIResponse) =>
      items.completionRequests.filter((a) => a.status === "pending").length >
      0 ? (
        <>
          <ButtonGroup>
            <Button disabled={true}>
              Requested Completion
            </Button>
            <RequestFollowOnQuoteButton cas={cas}/>
          </ButtonGroup>
        </>
      ) : (
        <ButtonGroup>
          <RequestFollowOnQuoteButton cas={cas} />
          <RequestCaseCompletionButton onRefresh={execute} case={cas} />
        </ButtonGroup>
      )
  )

  return render(status)
}

export const RequestFollowOnQuoteButton: FC<{ cas: CaseDetails }> = ({
  cas
}) => {
  const { push } = useRouter()
  const { showNewMessages } = useIntercom()

  const isFollowOnEnabled = useFeatureFlag('platform-solicitor-follow-on-quotes')

  const handleClick = () => {
    if(isFollowOnEnabled) {
      push(ROUTES.case.followOn(cas.id).build)
    } else {
      showNewMessages(
        `I'd like a follow-on case for case #${cas.friendlyId}.
      The fees for this quote are £
      The disbursements for this quote are £
      This quote should specifically include the following work scopes:
      This quote should specifically exclude the following work scopes:`
      )
    }
  }

  return (
    <Button onClick={handleClick} variant="positive">
      Request follow-on case
    </Button>
  )
}

export const RequestCaseCompletionButton: FC<{
  case: CaseDetails
  onRefresh: () => void
}> = ({ case: cas, onRefresh }) => {
  const api = useApiFetch(fetchAndCast<void>())

  const triggerCompltionEvent = useCaseCommittedEvent()

  const newCompletion = () => {
    triggerCompltionEvent({caseCommitment: "completion-requested", caseId: cas.id})
    return pipe(
      api(`cases/${cas.id}/complete`, {
        method: "POST"
      }),
      TE.chainFirst(() => TE.of(hideComplete())),
      TE.chainFirst(() => TE.of(onRefresh()))
    )
  }


  const { status: newStatus, execute } = useFromTaskEither(newCompletion)

  // const { status, execute: requestCompletion }= useAsync(
  //   () => graphql<RequestCaseCompletionCommandMutation, RequestCaseCompletionCommandMutationVariables>({
  //     query: /* GraphQL */ `
  //       mutation RequestCaseCompletionCommand($input: RequestCaseCompletionCommand!) {
  //         requestCaseCompletionCommand(input: $input) {
  //           id
  //         }
  //       }
  //     `,
  //     variables: {
  //       input: {
  //         caseId: cas.id
  //       }
  //     }
  //   })
  //   .then(_ => hideComplete())
  //   .then(_ => onRefresh())
  // )

  const [showComplete, hideComplete] = useModal(
    () => (
      <Modal onDismiss={hideComplete}>
        <RequestCaseCompletionModal
          onCancel={hideComplete}
          onComplete={execute}
          isLoading={isPending(newStatus)}
        />
      </Modal>
    ),
    [newStatus]
  )

  return <Button onClick={showComplete}>Complete Case</Button>
}

const SolicitorMessages = () => (
  <>
    <InitialMessageSuggestion />
    <MessagesCardWithData canSend={true} />
  </>
)

const InitialMessageSuggestion = () => {
  const { messages, caseDetails, caseId } = useCase()
  const { userId } = useUser()

  const createMessage = useCreateMessage()

  const context = pipe(
    messages,
    map((m) => m.length),
    chain((messageCount) =>
      pipe(
        caseDetails,
        map((d) => ({
          details: d,
          messageCount
        }))
      )
    )
  )

  const getClientName = (u: Option<User>) =>
    pipe(
      u,
      chainOption((c) => c.displayName),
      getOrElse<string>(() => "Client")
    )

  const getSolicitorName = (u: Option<Solicitor>) =>
    pipe(
      u,
      mapOption((c) => c.name),
      getOrElse<string>(() => "your solicitor")
    )

  const getSolicitorId = (u: Option<Solicitor>) =>
    pipe(
      u,
      mapOption((c) => c.id),
      getOrElse<string>(() => "your solicitor")
    )

  const buildMessage = (details: CaseDetails) => `Hello ${getClientName(
    details.client
  )},

I am ${getSolicitorName(details.solicitor)} and I will be working on your case.

I am reviewing the information provided so far and will respond in due course.

Kind regards, ${getSolicitorName(details.solicitor)}
  `

  const { status, execute } = useFromTaskEither((details: CaseDetails) =>
    createMessage({
      type: "text",
      content: buildMessage(details),
      caseId: details.id,
      author: getSolicitorId(details.solicitor)
    })
  )

  return renderNullOrSuccess(context, (c) =>
    c.messageCount === 0 ? (
      <div tw="mb-4">
        <Card tw="bg-indigo-600">
          <CardContent>
            <div tw="flex flex-col items-start justify-between flex-wrap text-sm sm:text-base">
              <div tw="flex-1 flex flex-col items-start text-white">
                <p tw="pb-1 font-medium">
                  This case is now active and your client is waiting to hear
                  from you.
                </p>
                <p tw="py-1">
                  It is recommended you briefly introduce yourself and let your
                  client know you're working on their matter. Additionally, you
                  may wish to ask any questions you have about their
                  circumstances.
                </p>
                <p tw="pt-4 mb-2">To start with, you may use this template:</p>
                <div tw="p-4 rounded-md bg-white text-gray-900 text-sm">
                  {buildMessage(c.details)
                    .split("\n")
                    .map((l, i) => (
                      <p key={i}>{l}</p>
                    ))}
                </div>
              </div>
              <div tw="order-3 flex-shrink-0 sm:order-2 mt-2 sm:mt-4">
                <div tw="rounded-md shadow-sm">
                  <Button
                    tw="bg-white text-indigo-600 hover:(bg-indigo-100)"
                    disabled={isPending(status)}
                    onClick={() =>{ execute(c.details)}}
                  >
                    {isPending(status) ? (
                      <Spinner />
                    ) : (
                      <>Send Template Introduction</>
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    ) : null
  )
}

export const ActiveDetailsPanel: ComponentWithCase = ({ case: cas }) => (
  <CaseTabLayout
    defaultRedirect={ROUTES.case.messages(cas.id)}
    config={[
      {
        label: "Messages",
        icon: <MessageIcon />,
        to: ROUTES.case.messages(cas.id),
        path: ROUTES.case.messages(":caseId"),
        render: () => <SolicitorMessages />
      },
      {
        label: "Files",
        icon: <FilesIcon />,
        to: ROUTES.case.files(cas.id),
        path: ROUTES.case.files(":caseId"),
        render: () => <CaseFilesCard canUpload={true} />
      },
      {
        label: "Case Summary",
        icon: <SummaryIcon />,
        to: ROUTES.case.summary(cas.id),
        path: ROUTES.case.summary(":caseId"),
        render: () => <CaseSummaryCard case={cas} />
      },
      {
        label: "Client Identity",
        icon: <IdentificationIcon />,
        to: ROUTES.case.client(cas.id),
        path: ROUTES.case.client(":caseId"),
        render: () => <SolicitorClientDetailsCard case={cas} />
      },
      {
        label: "Payments",
        icon: <PaymentsIcon />,
        to: ROUTES.case.fees(cas.id),
        path: ROUTES.case.fees(":caseId"),
        render: () => <SolicitorPaymentsView />
      }
    ]}
  />
)
