import { pipe } from 'fp-ts/lib/function'
import { getOrElse, none, Option, some, map as mapOption, isNone } from 'fp-ts/lib/Option'

import { GetDateTime } from "../utils/Date"
import { AssignedCase } from "../case/Case"
import { map, of, TaskEither } from 'fp-ts/lib/TaskEither'
import { IsReminderOverdue, ReminderHistory } from './ReminderScheduler'

export type ContactInformation = {
  emailAddress: string
}

export type ReminderRecipient = {
  userId: string
  type: 'solicitor' | 'client'
  name: string
  contactInformation: ContactInformation
}

export type MessageNeedsResponseReminder = {
  recipient: ReminderRecipient
  reminderNumber: number
  caseId: string
  caseNumber: string
  lastMessage: CaseMessage
  remindedAt: Date
}

// export type SolicitorMessageReminderHistory = {
//   reminderCount: number
//   lastReminderSentAt: Date
//   // lastReminder: MessageNeedsResponseReminder
// }

export type UserType = 'solicitor' | 'client'

export type CaseMessage = {
  author: { userId: string, type: UserType, name: string },
  sentAt: Date
  content: string
}

export type CaseMessageHistory = Option<CaseMessage>

export type GetUserReminderHistoryForCase = (input: { userId: string, caseId: string, fromDate: Date }) => TaskEither<Error, ReminderHistory>

export type CaseNeedsSolicitorMessageReminder = (cas: AssignedCase, messageHistory: CaseMessageHistory) => TaskEither<Error, Option<MessageNeedsResponseReminder>>


export const caseNeedsMessageReminderRule =
  (getDateTime: GetDateTime) =>
  (isReminderOverdue: IsReminderOverdue) =>
  (getReminderHistoryForCase: GetUserReminderHistoryForCase)
  : CaseNeedsSolicitorMessageReminder => (cas, messageHistory) => {
    const now = getDateTime()

    if(isNone(messageHistory)) {
      return of(none)
    }

    const lastMessage = messageHistory.value

    if(lastMessage.author.type === 'solicitor') {
      return of(none)
    }

    const isEligibleBasedOnCase = cas.status === 'active'

    const isEligibleBasedOnLastMessage =
      lastMessage.author.type === 'client'
      && (
        lastMessage.content.length > 15
        || lastMessage.content.includes('?')
      )

    const isEligibleForReminder =
        isEligibleBasedOnCase
        && isEligibleBasedOnLastMessage

    if(isEligibleForReminder) {
      // We get the reminder history only after initial eligibility checks have been performed to avoid
      // performing lots of unnecessary requests to build a reminder history
      const reminderHistoryTask = getReminderHistoryForCase({
        userId: cas.solicitor.id,
        caseId: cas.id,
        fromDate: lastMessage.sentAt
      })

      return pipe(
        reminderHistoryTask,
        map(reminderHistory => {

          const nextReminderNumber = pipe(
            reminderHistory,
            mapOption(r => r.alreadySentReminders + 1),
            getOrElse(() => 1)
          )

          const shouldSend = isReminderOverdue(lastMessage.sentAt, reminderHistory)

          if(shouldSend) {
              return some<MessageNeedsResponseReminder>({
                caseId: cas.id,
                caseNumber: cas.caseNumber,
                lastMessage: lastMessage,
                recipient: {
                  userId: cas.solicitor.id,
                  contactInformation: { emailAddress: cas.solicitor.emailAddress },
                  name: cas.solicitor.name,
                  type: 'solicitor'
                },
                remindedAt: now,
                reminderNumber: nextReminderNumber
              })
          } else {
            return none
          }
        })
      )
    }

    return of(none)
  }
