import * as D from "io-ts/lib/Decoder"
import { castTo, fetchParsedJSON } from "../../../lib/fetch"
import { useApiFetch } from "../../../lib/useApiClient"
import { useFromTaskEither } from "../../../lib/useAsync"

export const SetConfirmedPhoneNumberCommand = D.struct({
  phoneNumber: D.string
})

export type SetConfirmedPhoneNumberCommand = D.TypeOf<typeof SetConfirmedPhoneNumberCommand>

export const useSetConfirmedTelephoneNumber = () => {
  const api = useApiFetch(fetchParsedJSON(castTo<void>()))

  const { status, execute } = useFromTaskEither(
    (command: SetConfirmedPhoneNumberCommand) =>
      api(`solicitors/confirmed-number`, {
        method: "POST",
        body: JSON.stringify(command)
      })
  )

  return { status, execute }
}
