import { CaseStatus } from "@lawhive/generated-api"
import React, { FC } from "react"
import { ComponentWithCase } from "../CaseDetails"
import { ActiveActionPanel, ActiveDetailsPanel, ActiveStatusPanel } from "./states/Active"
import { CancelledDetailsPanel } from "../shared/states/Cancelled"
import { CompletedActionPanel, CompletedDetailsPanel, CompletedStatusPanel } from "./states/Completed"
import { NeedsApprovalDetailsPanel } from "./states/NeedsApproval"
import { NeedsApprovalActionPanel, NeedsApprovalStatusPanel } from "./states/NeedsApproval"
import { WaitingForPaymentStatusPanel, WaitingForClientPaymentDetailsPanel } from "./states/WaitingForClientPayment"
import { CaseDetails } from "../../../../contexts/case/useGetCase"

export const SolicitorCaseDetailsPanel: ComponentWithCase = ({ case: cas }) => {
  switch(cas.status) {
    case CaseStatus.needsDetails:
    case CaseStatus.exported:
    case CaseStatus.pendingExport:
      return null
    case CaseStatus.needsPrice:
    case CaseStatus.needsSolicitorAssignment:
    case CaseStatus.needsSolicitorApproval:
      return <NeedsApprovalDetailsPanel case={cas} />
    case CaseStatus.needsClientPayment:
      return <WaitingForClientPaymentDetailsPanel case={cas} />
    case CaseStatus.active:
      return <ActiveDetailsPanel case={cas} />
    case CaseStatus.completed:
    case CaseStatus.reviewed:
      return <CompletedDetailsPanel case={cas} />
    case CaseStatus.cancelled:
      return <CancelledDetailsPanel case={cas} />
  }
}

export const SolicitorCaseActionPanel: FC<{ case: CaseDetails }> = ({ case: cas }) => {
  switch(cas.status) {
    case CaseStatus.needsSolicitorApproval:
      return <NeedsApprovalActionPanel case={cas} />
    case CaseStatus.needsClientPayment:
      return null
    case CaseStatus.active:
      return <ActiveActionPanel case={cas} />
    case CaseStatus.needsDetails:
    case CaseStatus.needsPrice:
    case CaseStatus.needsSolicitorAssignment:
    case CaseStatus.cancelled:
    case CaseStatus.completed:
      return <CompletedActionPanel case={cas}/>
    case CaseStatus.reviewed:
    case CaseStatus.exported:
    case CaseStatus.pendingExport:
      return null
  }
}

export const SolicitorCaseStatusPanel: ComponentWithCase = ({ case: cas }) => {
  switch(cas.status) {
    case CaseStatus.needsDetails:
    case CaseStatus.needsPrice:
    case CaseStatus.needsSolicitorAssignment:
      return <ActiveStatusPanel case={cas} />
    case CaseStatus.needsSolicitorApproval:
      return <NeedsApprovalStatusPanel case={cas} />
    case CaseStatus.needsClientPayment:
      return <WaitingForPaymentStatusPanel case={cas} />
      // return <ClientNeedsToPay case={cas} />
    case CaseStatus.active:
      return <ActiveStatusPanel case={cas} />
    case CaseStatus.completed:
      return <CompletedStatusPanel case={cas} />
    case CaseStatus.cancelled:
    case CaseStatus.reviewed:
    case CaseStatus.exported:
    case CaseStatus.pendingExport:
      return null
      // return <ClientActiveCase case={cas} />

  }
}
