import { fetchAndCast } from "../../../lib/fetch"
import { useApiFetch } from "../../../lib/useApiClient"
import { useFromTaskEither } from "../../../lib/useAsync"

export const useScheduleAssessmentCall = () => {
  const api = useApiFetch(fetchAndCast<void>())

  const { status, execute } = useFromTaskEither(
    (id: string, slot: Date) =>
      api(`assessment-calls/${id}/schedule`, {
        method: 'POST',
        body: JSON.stringify({
          dateSelection: slot
        })
      })
  )

  return { status, execute }
}
