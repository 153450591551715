import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { FC, FormHTMLAttributes, Fragment, ReactNode } from "react"
import 'twin.macro'

export type SlideOutMenuRenderProps = {
  close: () => void
}

export type SlideOutMenuProps = {
  isOpen: boolean
  onClose: () => void

  content: ReactNode | ((r: SlideOutMenuRenderProps) => ReactNode)
}

export type SlideOutFormProps = {
  renderProps: SlideOutMenuRenderProps
  content: ReactNode | ((r: SlideOutMenuRenderProps) => ReactNode)
  actions: ReactNode | ((r: SlideOutMenuRenderProps) => ReactNode)
}

export const SlideOutForm: FC<SlideOutFormProps & FormHTMLAttributes<HTMLFormElement>> = ({ renderProps, content, actions, ...rest }) => (
  <form tw="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl" {...rest}>
    <div tw="h-0 flex-1 overflow-y-auto flex flex-col">
      {typeof content === 'function'
        ? content(renderProps)
        : content
      }
    </div>
    <div tw="flex flex-shrink-0 justify-end px-4 py-4 gap-4">
      {typeof actions === 'function'
        ? actions(renderProps)
        : actions
      }
    </div>
  </form>
)

export const SlideOutMenu: FC<SlideOutMenuProps> = ({ isOpen, onClose, content }) => {
  const renderProps: SlideOutMenuRenderProps = {
    close: () => onClose()
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog tw="relative z-10" onClose={onClose}>
        <div tw="fixed inset-0" />
        <div tw="fixed inset-0 overflow-hidden">
          <div tw="absolute inset-0 overflow-hidden">
            <div tw="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel tw="pointer-events-auto w-screen max-w-md">
                  {typeof content === 'function' ? content(renderProps) : content}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export type SlideOutMenuHeaderProps = {
  title: string
  supportingText?: string
  onClose: () => void
}

export const SlideOutMenuHeader: FC<SlideOutMenuHeaderProps> = ({ title, supportingText, onClose }) => (
  <div tw="bg-gray-100 py-4 sm:py-6 px-4 sm:px-6">
    <div tw="flex items-center justify-between">
      <Dialog.Title tw="text-base sm:text-lg font-medium text-gray-900">
        {title}
      </Dialog.Title>
      <div tw="ml-3 flex h-7 items-center">
        <button
          type="button"
          tw="rounded-md text-gray-700 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-700"
          onClick={onClose}
        >
          <span tw="sr-only">Close panel</span>
          <XIcon tw="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
    </div>
    <div tw="mt-1">
      <p tw="text-xs sm:text-sm text-gray-500">
        {supportingText}
      </p>
    </div>
  </div>
)
