import { Auth } from "@aws-amplify/auth"
import { pipe } from "fp-ts/lib/function"
import { chain, chainW, map, TaskEither, tryCatch } from "fp-ts/lib/TaskEither"
import { useEnvironment } from "../contexts/Environment"
import { RequestError, toNetworkError } from "./fetch"

export const useApiFetch = <E extends RequestError, A>(fetcher: (input: RequestInfo, init?: RequestInit) => TaskEither<E, A>) => {
  const { BACKEND_URL } = useEnvironment()

  return (input: RequestInfo, init?: RequestInit) => pipe(
    tryCatch(() => Auth.currentSession(), toNetworkError),
    map(s => s.getAccessToken()),
    chainW(token => fetcher(`${BACKEND_URL}/${input}`, {
      ...init,
      headers: {
        ...init?.headers,
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.getJwtToken()}`
      }
    }))
  )
}
