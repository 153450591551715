import { default as React, FC } from "react"
import 'twin.macro'

import { useAuthentication } from "../contexts/Authentication"
import { ScrollingChildrenContainer } from "./Layout"

export const OnboardingShell: FC = ({ children }) => {
  const { logout } = useAuthentication()

  return (
    <ScrollingChildrenContainer tw="bg-indigo-900 flex-1 flex flex-col h-full">
      <div tw="py-10">
        <h1 tw="font-logo text-2xl sm:text-4xl text-white text-center mb-6 sm:mb-2">
          lawhive
        </h1>

        {children}

        <div tw="flex flex-row justify-center mt-4">
          <button tw="flex items-center text-indigo-200" onClick={logout}>
            Logout
          </button>
        </div>

      </div>
    </ScrollingChildrenContainer>
  )
}
