import "tippy.js/dist/tippy.css"
import "twin.macro"

import Tippy from "@tippyjs/react"
import { pipe } from "fp-ts/es6/function"
import { fold } from "fp-ts/es6/Option"
import { PositivePill } from "packages/app/src/elements/Badge"
import {
  extractQuoteInformation,
  useQuoteStatus
} from "packages/app/src/features/quotes"
import { useAcceptCase } from "packages/app/src/features/quotes/api/acceptCase"
import { useRejectCase } from "packages/app/src/features/quotes/api/rejectCase"
import { QuoteTotalPriceMetric } from "packages/app/src/features/quotes/components/QuoteTotalPriceMetric"
import { useQuotes } from "packages/app/src/features/quotes/context/QuoteProvider"
import { useWaitForQuotePaid } from "packages/app/src/features/quotes/hooks/useWaitForQuotePaid"
import { SolicitorQuoteView } from "packages/app/src/features/quotes/routes/SolicitorQuoteView"
import { useAnalytics } from "packages/app/src/hooks/useAnalytics"
import { useCase } from "../../../../../contexts/case/CaseContext"
import { useModal } from "../../../../../contexts/Modal"
import { useUser } from "../../../../../contexts/User"
import { WarningMessage } from "../../../../../elements"
import { Button, ButtonGroup } from "../../../../../elements/Button"
import {
  MessageIcon,
  PaymentsIcon,
  SummaryIcon
} from "../../../../../elements/Icons"
import { Modal } from "../../../../../elements/Modal"
import { ROUTES } from "../../../../../layout/Navigation"
import { useRouter } from "../../../../../lib/useRouter"
import { ComponentWithCase } from "../../CaseDetails"
import { CaseSummaryCard } from "../../shared/CaseSummaryCard"
import { CaseTabLayout } from "../../shared/CaseTabLayout"
import { AcceptCaseModal } from "../actions/AcceptCaseModal"
import { RejectCaseModal } from "../actions/RejectCaseModal"
import { MessagesCardWithData } from "../../shared/MessagesCardWithData"
import { useCaseCommittedEvent } from "packages/app/src/features/analytics/useCaseCommittedEvent"

export const NeedsApprovalStatusPanel: ComponentWithCase = ({ case: cas }) => {
  const { firstQuote } = useQuoteStatus()

  if (!firstQuote) {
    return null
  }

  const { quoteType, paymentStatus, hasPaymentHoldExpired } =
    extractQuoteInformation(firstQuote)

  return (
    <div tw="flex flex-row items-center">
      <div>
        {firstQuote && (
          <>
            <div tw="flex gap-8 items-center">
              <div>
                <QuoteTotalPriceMetric label="Total Fees" />
              </div>
              <div>
                {paymentStatus === "not-captured" &&
                  !hasPaymentHoldExpired &&
                  quoteType === "ClientAuthorisation" && (
                    <div tw="flex flex-row items-center">
                      <Tippy
                        content={
                          <span>
                            The client has already paid this quote. <br />
                            <br />
                            Funds will be released to you as soon as the case is
                            accepted.
                          </span>
                        }
                      >
                        <PositivePill tw="text-base cursor-pointer">
                          Client Paid
                        </PositivePill>
                      </Tippy>
                    </div>
                  )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export const NeedsApprovalActionPanel: ComponentWithCase = ({ case: cas }) => {
  const { userId } = useUser()
  const { refresh } = useCase()
  const { push } = useRouter()

  const { track, identify } = useAnalytics()
  const { firstQuote, caseQuotes, getQuotes } = useQuotes()

  identify(userId)

  const acceptCase = useAcceptCase()
  const rejectCase = useRejectCase()

  const { status, poll, isPolling } = useWaitForQuotePaid({
    onSuccess: () => {
      getQuotes.refetch()
      refresh()
      hideAccept()
    },
    onFailure: () => {
      refresh()
      hideAccept()
    }
  })

  const isLoading = acceptCase.isLoading || isPolling

  const triggerCaseCommitAction = useCaseCommittedEvent()

  const handleAcceptCase = () => {
    triggerCaseCommitAction({ caseId: cas.id, caseCommitment: "accepted" })
    if (!firstQuote) {
      return
    }

    const { quoteType, hasPaymentHoldExpired } =
      extractQuoteInformation(firstQuote)

    acceptCase.mutateAsync({ caseId: cas.id }).then(() => {
      if (quoteType === "ClientAuthorisation" && !hasPaymentHoldExpired) {
        poll(firstQuote._id)
      } else {
        refresh()
        hideAccept()
      }
    })

    track("Quote Accepted", {
      caseId: cas.id,
      payableAmount: firstQuote?.price.payableAmount,
      solicitorId: userId,
      timeToAccept: Date.now() - cas.createdAt.getTime(),
      quoteType: quoteType
    })
  }

  const handleRejectCase = (data: string) => {
    triggerCaseCommitAction({ caseId: cas.id, caseCommitment: "rejected" })
    rejectCase
      .mutateAsync({ caseId: cas.id, data: { reason: data } })
      .then(() => {
        push(ROUTES.case.root)
        hideReject()
      })

    if (!firstQuote) {
      return
    }

    const { quoteType } = extractQuoteInformation(firstQuote)
    track("Quote Rejected", {
      caseId: cas.id,
      payableAmount: firstQuote?.price.payableAmount,
      solicitorId: userId,
      timeToReject: Date.now() - cas.createdAt.getTime(),
      quoteType: quoteType
    })
  }

  const isTimeSensitive = pipe(
    cas.details,
    fold(
      () => false,
      (d) => d.isTimeSensitive === true
    )
  )

  const [showAccept, hideAccept] = useModal(
    () => (
      <Modal onDismiss={hideAccept}>
        <AcceptCaseModal
          showPaymentWarning={
            !caseQuotes?.some((q) => q.quotePayments.length > 0)
          }
          showTimeSensitiveWarning={isTimeSensitive}
          onCancel={hideAccept}
          onConfirm={handleAcceptCase}
          isLoading={isLoading}
        />
      </Modal>
    ),
    [handleAcceptCase, isLoading, isTimeSensitive]
  )

  const [showReject, hideReject] = useModal(
    () => (
      <Modal onDismiss={hideReject}>
        <RejectCaseModal
          onCancel={hideReject}
          onReject={handleRejectCase}
          isLoading={rejectCase.isLoading}
        />
      </Modal>
    ),
    []
  )

  return (
    <div tw="max-w-md">
      {isTimeSensitive && (
        <WarningMessage title="Time Sensitive Case" tw="mb-6">
          Please do not accept this case if you cannot meet the timelines
          outlined in the case description
        </WarningMessage>
      )}
      <ButtonGroup>
        <Button onClick={showAccept} variant="positive">
          Accept Instruction
        </Button>
        <Button onClick={showReject} variant="negative">
          Reject Instruction
        </Button>
      </ButtonGroup>
    </div>
  )
}

export const NeedsApprovalDetailsPanel: ComponentWithCase = ({ case: cas }) => (
  <CaseTabLayout
    defaultRedirect={ROUTES.case.summary(cas.id)}
    config={[
      {
        label: "Summary",
        icon: <SummaryIcon />,
        to: ROUTES.case.summary(cas.id),
        path: ROUTES.case.summary(":caseId"),
        render: () => <CaseSummaryCard case={cas} />
      },

      {
        label: "Messages",
        icon: <MessageIcon />,
        to: ROUTES.case.messages(cas.id),
        path: ROUTES.case.messages(":caseId"),
        render: () => <MessagesCardWithData canSend={true} />
      },
      {
        label: "Fees",
        icon: <PaymentsIcon />,
        to: ROUTES.case.fees(cas.id),
        path: ROUTES.case.fees(":caseId"),
        render: () => <SolicitorQuoteView />
      }
    ]}
  />
)
