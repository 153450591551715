import * as D from 'io-ts/lib/Decoder'

import { ADT } from "../../../lib/adt"
import { fetchAndCast } from "../../../lib/fetch"
import { useApiFetch } from "../../../lib/useApiClient"
import { useFromTaskEither } from "../../../lib/useAsync"
import { optional } from "../../../lib/io"


export const AssessmentCallRejectionReason = ADT({
  WrongSolicitor: D.struct({
    productFreeformEstimate: D.string,
    details: optional(D.string)
  }),
  Other: D.struct({
    details: D.string
  })
})

export type AssessmentCallRejectionReason = D.TypeOf<typeof AssessmentCallRejectionReason>

export const useRejectAssessmentCall = () => {
  const api = useApiFetch(fetchAndCast<void>())

  const { status, execute } = useFromTaskEither(
    (id: string, reason: AssessmentCallRejectionReason) =>
      api(`assessment-calls/${id}/reject`, {
        method: 'POST',
        body: JSON.stringify({ reason })
      })
  )

  return { status, execute }
}
