import { Badge } from "../../elements/Badge"
import { matchStringUnion } from "../../lib/utils"
import { PaymentListEntry, PaymentStatus } from '../../hooks/useListPayments'

export const PaymentStatusBadge = ({ payment }: { payment: PaymentListEntry }) => (
  payment.refunded
    ? <Badge variant="orange">Refunded</Badge>
    : <Badge
      variant={matchStringUnion(payment.status, {
        canceled: 'red',
        processing: 'yellow',
        requires_action: 'orange',
        requires_capture: 'orange',
        requires_confirmation: 'orange',
        requires_payment_method: 'orange',
        succeeded: 'positive'
      })}
    >
      {matchStringUnion(payment.status, {
        canceled: 'Cancelled',
        processing: 'Processing',
        requires_action: 'Requires Action',
        requires_capture: 'Requires Action',
        requires_confirmation: 'Requires Action',
        requires_payment_method: 'Requires Action',
        succeeded: 'Succeeded'
      })}
    </Badge>
)
