import React, { FC, useState } from 'react'
import { TextareaAutosize } from 'react-autosize-textarea/lib/TextareaAutosize'
import 'twin.macro'

import { formSubmit, formInput } from '../../../lib/utils'

export const SendMessageBox: FC<{ addMessage: (message: string) => void }> = ({ addMessage }) => {
  const [message, setMessage] = useState<string>()

  const send = () => {
    if (!message) {
      return
    }

    addMessage(message)
    setMessage('')
  }

  return (
    <form tw="relative flex" onSubmit={formSubmit(send)}>
      <TextareaAutosize
        value={message}
        onChange={formInput(setMessage)}
        placeholder="Write case details"
        tw="w-full border-transparent focus:(outline-none text-gray-700 placeholder-gray-400 border-pink-300 ring-2 ring-pink-100) text-gray-600 text-sm sm:text-base placeholder-gray-600 px-4 sm:px-6 bg-gray-200 rounded-xl py-2 sm:py-3"
      />
      <div tw="ml-4 items-center flex">
        <button tw="block inline-flex items-center justify-center rounded-full h-10 w-10 sm:h-12 sm:w-12 transition duration-500 ease-in-out text-white bg-blue-500 hover:bg-blue-400 focus:outline-none">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            tw="h-5 w-5 sm:h-6 sm:w-6 transform rotate-90"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
          </svg>
        </button>
      </div>
    </form>
  )
}
