import { Dispatch, SetStateAction } from "react"
import { v4 } from "uuid"
import { WithID } from "../components/list-builder/useListState"
import { UUID } from "./Primitives"

const generateId = () => v4() as UUID

export const appendId = <T,>(id: UUID, item: T): WithID<T> => ({ ...item, id })
export const appendGeneratedId = <T,>(item: T): WithID<T> => appendId(generateId(), item)

type ListState<T> = WithID<T>[]

export const asListState = <T,>(
  items: ListState<T>,
  setItems: Dispatch<SetStateAction<ListState<T>>>
) => {
  const itemsOrList = items || []

  const addItem = (item: T) => setItems(i => [...i, appendId(generateId(), item)])

  const moveUp = (id: UUID) => setItems(items => {
    const curIndex = items.findIndex(i => i.id === id)
    const newIndex = Math.max(curIndex - 1, 0)

    const clone = [...items]
    clone.splice(newIndex, 0, clone.splice(curIndex, 1)[0])

    return clone
  })

  const moveDown = (id: UUID) => setItems(items => {
    const curIndex = items.findIndex(i => i.id === id)
    const newIndex = Math.min(curIndex + 1, items.length)

    const clone = [...items]
    clone.splice(newIndex, 0, clone.splice(curIndex, 1)[0])

    return clone
  })


  const updateItem = (id: UUID) => (item: T) => setItems(items => {
    const i = items.findIndex(i => i.id === id)
    return [...items.slice(0, i), appendId(id, item), ...items.slice(i + 1)]
  })

  const deleteItem = (id: UUID) => setItems(items => items.filter(i => i.id !== id))

  return { items: itemsOrList, addItem, updateItem, deleteItem, moveDown, moveUp }
}
