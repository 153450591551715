import { default as React, FC } from 'react'
import { FormattedNumber, IntlShape, FormatNumberOptions } from 'react-intl'

export const FormattedCurrencyUnits: FC<{ amountUnits: number } & Pick<Partial<FormatNumberOptions>, 'currency' | 'currencySign'>> =
({ amountUnits, currency='gbp', currencySign, ...rest }) => (
  <span {...rest}>
    <FormattedNumber
      value={amountUnits/100}
      currency={currency}
      style={'currency'}
      currencyDisplay={'narrowSymbol'}
      currencySign={currencySign || 'standard'}
    />
  </span>
)

export const formatCurrencyUnits =
  (intl: IntlShape) =>
  (amountUnits: number, o?: { currency?: string }) =>
    intl.formatNumber(amountUnits/100, {
      currency: o?.currency || 'gbp',
      currencyDisplay: 'narrowSymbol',
      style: 'currency'
    })

export const AccountingCurrencyUnits = ({ amountUnits }: { amountUnits: number }) => <FormattedCurrencyUnits amountUnits={amountUnits} currencySign={'accounting'} />
