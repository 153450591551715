import React, { FC } from 'react'
import { format } from 'date-fns'
import { CardContent } from './Card'
import 'twin.macro'

type FilesListProps = {
  items?: NewFileListItem[]
  onClick: (key: string, name?: string) => void
  update: () => void
}

export type NewFileListItem = {
  name: string,
  key: string,
  timestamp?: Date
}
export const FilesList: FC<FilesListProps> = ({ items = [], onClick, update }) => {

  return (
    <div tw="mt-6">
      {items.length > 0
        ? (
          <ul tw="border border-gray-200 rounded-md">
            {items.map((f, i) => (

              <li key={`file-${i}`} tw="pl-3 pr-4 py-3 flex items-center justify-between border-b border-gray-200 last:border-none text-sm leading-5">
                <div tw="w-0 flex-1 flex items-center">
                  <svg tw="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clipRule="evenodd" />
                  </svg>
                  <div tw='flex w-0 flex-grow flex-col sm:flex-row'>
                    <button
                      tw="font-medium text-left  ml-2 truncate text-indigo-600 hover:text-indigo-500 transition duration-150 ease-in-out"
                      onClick={() => onClick(f.key, f.name)}
                    >
                      {f.name}
                    </button>
                    {f.timestamp &&
                      <div tw="font-light text-gray-500 ml-2 sm:ml-auto text-xs sm:text-sm">
                        {format(f.timestamp, "dd/LL/yy hh:mmaaa")}
                      </div>
                    }
                  </div>
                </div>
                {/* <div tw="ml-4 flex-shrink-0">
                <button
                  tw="font-medium text-indigo-600 hover:text-indigo-500 transition duration-150 ease-in-out"
                  onClick={() => deleteFile(f.key)}
                >
                  Delete
                </button>
              </div> */}
              </li>
            ))}
          </ul>
        )
        : (
          <CardContent tw="border border-gray-200 rounded-md">
            <p tw="text-sm text-gray-500">
              No files yet
            </p>
          </CardContent>
        )
      }
    </div>
  )
}
