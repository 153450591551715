import { isPending } from "@nll/datum/DatumEither"
import { default as React, FC, useState } from "react"
import "twin.macro"

import { Card, CardContent, FormButton } from "../../elements"
import { Spinner } from "../../elements/Loading"
import { formSubmit } from "../../lib/utils"
import { KycFiles } from "./KycFiles"
import { useUpdateOrCreateKycSubmission } from "./KycStatusScene"
import { KycStep } from "./KycStep"
export const IdentityUploadStep = () => {
  const [files, setFiles] = useState<string[]>([])

  const { status, execute: submit } = useUpdateOrCreateKycSubmission()

  const submitKYC = () =>
    submit({
      identityUploads: files
    })

  const buttonDisabled = files.length < 1 || isPending(status)

  return (
    <KycStep step={2}>
      <Card>
        <CardContent tw="text-sm sm:text-base">
          {/* <h2 tw="text-base sm:text-lg font-semibold mb-2">Your Identity</h2> */}
          <p tw="text-gray-900">
            Please provide a <strong>picture or selfie</strong> of you holding
            your identity document next to your face.
          </p>

          <div tw="flex flex-col bg-blue-100 text-blue-600 rounded-md my-4 px-4 py-3">
            <div tw="text-xs sm:text-sm font-medium">Example:</div>

            <div tw="flex flex-col items-center">
              <div tw="bg-white rounded-xl mt-2 h-20 sm:h-24">
                <img
                  tw="h-full w-full object-cover rounded-xl"
                  src="/passport_example_compressed.jpg"
                  alt="Example ID"
                />
              </div>
            </div>

            <div tw="mt-2">
              <ul tw="flex flex-col gap-1 text-xs sm:text-sm">
                <li tw="flex flex-row items-center">
                  <Check tw="mr-1" />
                  Shows your face and document clearly in one photo
                </li>
                <li tw="flex flex-row items-center">
                  <Check tw="mr-1" />
                  Ensure you are using the same document you used in the
                  previous photo
                </li>
                <li tw="flex flex-row items-center">
                  <Check tw="mr-1" />
                  Not blurry or out of focus
                </li>
              </ul>
            </div>
          </div>

          <KycFiles documentType={"identity"} onChange={setFiles} />
        </CardContent>

        <CardContent tw="bg-gray-50">
          {/* <div tw="flex justify-between items-center justify-end"> */}
          {/* <Link
              tw="text-indigo-600 font-medium hover:text-indigo-500"
              to={`${ROUTES.kyc.step1}?edit=true`}
            >
              Previous
            </Link> */}

          <FormButton disabled={buttonDisabled} onClick={formSubmit(submitKYC)}>
            {isPending(status) ? <Spinner /> : <>Continue</>}
          </FormButton>
          {/* </div> */}
        </CardContent>
      </Card>
    </KycStep>
  )
}

const Illustration: FC = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97 73.036" {...props}>
    <g transform="translate(-244 -373)">
      <g transform="translate(-315 -28)">
        <g transform="translate(577.013 410.024)">
          <path d="M63,35c0-3.95-7.221-5-7.221-5H53s-5,1-5,6c0,3.79-.5,5.57,2,8.39V47a1,1,0,0,0-1,1c0,3,.07,3.34-.06,2.53A15,15,0,0,0,34,38H31V36H21v2H18A15,15,0,0,0,3.06,50.53L1,62.84a1.013,1.013,0,0,0,2,.32l2-12.3A12.94,12.94,0,0,1,17.16,40c1.53,9.23.81,8.71,4.92,6.36a4.88,4.88,0,0,0,1.49,2.71c-.94,1-.68.69-2.31,13.78a1.007,1.007,0,1,0,2,.24l1.5-12a1.27,1.27,0,0,1,2.52,0l1.5,12a1.007,1.007,0,0,0,2-.24C29.15,49.78,29.4,50.1,28.47,49.07a4.93,4.93,0,0,0,1.49-2.71c2.33,1.34,2.42,1.44,2.78,1.44a1,1,0,0,0,.84-.46c.17-.27,0,.32,1.3-7.34A12.94,12.94,0,0,1,47,50.86C49,63,48.83,64,50,64a1,1,0,0,0,.75-.36c.36-.43.25.4.25-14.64h8V63a1,1,0,0,0,2,0V48a1,1,0,0,0-1-1V44.5C63.52,41.66,63,39.15,63,35ZM32,45.25l-4.32-2.47L30.41,40h2.41Zm-3-8.6v1.94l-3,3-3-3V36.65A13.34,13.34,0,0,0,29,36.65ZM21.59,40l2.78,2.78-4.32,2.47L19.18,40Zm6.09,6.79a1.77,1.77,0,0,1-3.36,0l-.47-1.41L26,44.15l2.15,1.23Zm30.77-3.62c-.59.39-.45.7-.45,3.83H52c0-3.26.09-3.32-.29-3.71A5.79,5.79,0,0,1,50,40c0-4.24-.58-7.81,2.66-8-.86,1.65-.66,3.43-.66,6,0,3.12,4,3.86,4,2a1,1,0,0,0-1-1,.91.91,0,0,1-1-1c0-2.47-.15-4,.49-5.14a1.07,1.07,0,0,0,.39-.4C57.07,31.41,61,32.14,61,35v4c0,2.42-2.53,4.15-2.55,4.17Z" />
        </g>
        <path
          d="M37,36a13,13,0,0,0,7.83-10,4.015,4.015,0,0,0,.61-8A43.431,43.431,0,0,0,46,9.1a7.25,7.25,0,0,0-5.51-7C37.66-.54,34.8,0,29.88,0A11.89,11.89,0,0,0,18,11.88,34.43,34.43,0,0,0,18.56,18c-5,.56-4.68,8,.61,8A12.94,12.94,0,0,0,27,36ZM47,22a2,2,0,0,1-2,2V20A2,2,0,0,1,47,22ZM29.88,2c4.76,0,7.22-.48,9.41,1.71A1,1,0,0,0,39.8,4,5.23,5.23,0,0,1,44,9.1a55,55,0,0,1-.15,5.9,14.22,14.22,0,0,0-3.14-4.74,1,1,0,0,0-1.26-.12C35.88,12.55,33.39,12,23,12c-.94,0-.95.67-2.65,4.64A33,33,0,0,1,20,11.88,9.89,9.89,0,0,1,29.88,2ZM17,22a2,2,0,0,1,2-2v4A2,2,0,0,1,17,22Zm4,2V20.2L23.66,14c9.72,0,12.33.49,16.17-1.72,2.45,2.79,2.76,5.36,3.17,7.8V24a11,11,0,0,1-22,0Z"
          transform="translate(571.013 410.046)"
        />
        <path
          d="M34.83,29.41a1,1,0,0,0-1.42,0,2,2,0,0,1-2.82,0,1,1,0,1,0-1.42,1.42C31.86,33.51,36.32,30.91,34.83,29.41Z"
          transform="translate(571.013 410.046)"
        />
        <path
          d="M25,20v1a1,1,0,0,0,2,0V20a1,1,0,0,0-2,0Z"
          transform="translate(571.013 410.046)"
        />
        <path
          d="M39,21V20a1,1,0,0,0-2,0v1a1,1,0,0,0,2,0Z"
          transform="translate(571.013 410.046)"
        />
        <rect
          width="15"
          height="11"
          transform="translate(630 438)"
          fill="#fff"
        />
        <path
          d="M14.427,32H1.312A1.372,1.372,0,0,0,0,33.425V33.9H15.738v-.475A1.372,1.372,0,0,0,14.427,32ZM0,43.875A1.372,1.372,0,0,0,1.312,45.3H14.427a1.372,1.372,0,0,0,1.312-1.425V34.85H0Zm9.618-6.887a.229.229,0,0,1,.219-.237h3.935a.229.229,0,0,1,.219.237v.475a.229.229,0,0,1-.219.237H9.837a.229.229,0,0,1-.219-.237Zm0,1.9a.229.229,0,0,1,.219-.237h3.935a.229.229,0,0,1,.219.237v.475a.229.229,0,0,1-.219.237H9.837a.229.229,0,0,1-.219-.237Zm0,1.9a.229.229,0,0,1,.219-.237h3.935a.229.229,0,0,1,.219.237v.475a.229.229,0,0,1-.219.237H9.837a.229.229,0,0,1-.219-.237ZM4.809,36.75a1.907,1.907,0,0,1,0,3.8,1.907,1.907,0,0,1,0-3.8ZM1.833,42.812A1.772,1.772,0,0,1,3.5,41.5h.224a2.609,2.609,0,0,0,2.175,0h.224a1.772,1.772,0,0,1,1.664,1.312.46.46,0,0,1-.426.588H2.26A.461.461,0,0,1,1.833,42.812Z"
          transform="translate(629.262 404.87)"
        />
      </g>
      <g
        transform="translate(244 373)"
        fill="none"
        stroke="#f87171"
        strokeWidth="2"
        strokeDasharray="13 6"
      >
        <rect width="97" height="73" rx="13" stroke="none" />
        <rect x="1" y="1" width="95" height="71" rx="12" fill="none" />
      </g>
    </g>
  </svg>
)

const Check: FC = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    tw="h-4 w-4"
    viewBox="0 0 20 20"
    fill="currentColor"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
      clipRule="evenodd"
    />
  </svg>
)
