import { FC, ReactNode, useState } from 'react'
import { Tab } from '@headlessui/react'
import tw from 'twin.macro'

type TabConfig = {
  key: string
  title: string
  badge?: string
  content: ReactNode
}

type TabProps = {
  id: string
  tabs: TabConfig[]
}

export const Tabs: FC<TabProps> = ({ id, tabs }) => {
  const [selectedIndex, setSelectedIndex] = useState(0)

  return (
    <div tw="flex flex-col gap-2">
      <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
        <div tw="sm:hidden">
          <label htmlFor="tabs" tw="sr-only">
            Select a tab
          </label>
          <select
            id={id}
            name={id}
            tw="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            value={selectedIndex}
            onChange={e => setSelectedIndex(+e.target.value)}
          >
            {tabs.map((tab, i) => (
              <option value={i} key={tab.key}>{tab.title}</option>
            ))}
          </select>
        </div>
        <div tw="hidden sm:block">
          <div tw="border-b border-gray-200">
            <Tab.List tw="-mb-px flex space-x-8">
              {({ selectedIndex }) => tabs.map((t, i)  => (
                <Tab
                  key={t.key}
                  css={[
                    tw`whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm focus:outline-none`,
                    selectedIndex === i
                      ? tw`border-indigo-500 text-indigo-600`
                      : tw`border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300`,
                  ]}
                  aria-current={selectedIndex === i ? 'page' : undefined}
                >
                  {t.title}
                  {t.badge ? (
                    <span
                      css={[
                        selectedIndex === i ? tw`bg-indigo-100 text-indigo-600` : tw`bg-gray-100 text-gray-900`,
                        tw`hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block`
                      ]}
                    >
                      {t.badge}
                    </span>
                  ) : null}
                </Tab>
              ))}
            </Tab.List>
          </div>
        </div>
        <Tab.Panels tw="mt-2">
          {tabs.map((t, i) => (
            <Tab.Panel key={i}>
              {t.content}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}
