import { FC, Fragment, useMemo, useState } from "react"
import { Listbox, Transition } from '@headlessui/react'
import tw from 'twin.macro'

import { TabLink, TabMenu } from "./Sidebar"
import { useHistory } from "react-router-dom"
import { getOrElse } from "fp-ts/es6/Option"
import { pipe } from "fp-ts/es6/function"
import { findFirst } from "fp-ts/lib/Array"
import { useEffect } from "react"
import { ReactNode } from "react"

type TabNavigationProps = {
  items: TabNavigationItem[]
}

type TabNavigationItem = {
  to: string,
  label: string,
  icon?: ReactNode
}

const currentLocationItemOrFirst = (items: TabNavigationItem[], pathname: string) => pipe(
  findFirst<TabNavigationItem>(i => i.to === pathname)(items),
  getOrElse(() => items[0])
)

export const TabNavigation: FC<TabNavigationProps> = ({ items, ...rest }) => {


  return (
    <div {...rest}>
      <div tw="hidden sm:block">
        <TabMenu>
          {items.map((c, i) => (
            <TabLink key={`tab-${i}`} to={c.to}>
              {c.icon && <span tw="mr-2 h-5 w-5 flex-shrink-0">{c.icon}</span>}
              {c.label}
            </TabLink>
          ))}
        </TabMenu>
      </div>

      <div tw="block sm:hidden">
        <DropdownNavigation items={items} />
      </div>
    </div>
  )
}

export const DropdownNavigation = ({ items }: { items: TabNavigationItem[] }) => {
  const { push, location } = useHistory()

  const [selected, setSelected] = useState<TabNavigationItem>(() => currentLocationItemOrFirst(items, location.pathname))

  useEffect(() => {
    const item = currentLocationItemOrFirst(items, location.pathname)
    setSelected(item)
  }, [items, location])

  const navigate = (i: TabNavigationItem) => {
    push(i.to)
    setSelected(i)
  }

  return (
    <Listbox value={selected} onChange={navigate} tw="z-10">
      <div tw="relative mt-1">
        <Listbox.Button tw="relative flex flex-row items-center text-pink-600 w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-pink-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">

          {selected.icon && (
            <div tw="mr-2 h-5 w-5">
              {selected.icon}
            </div>
          )}

          <span tw="block truncate">{selected.label}</span>

          <span tw="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <svg xmlns="http://www.w3.org/2000/svg" tw="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          </span>
        </Listbox.Button>

        <Transition
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options tw="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {items.map((item, i) => (
              <Listbox.Option
                key={i}
                value={item}
              >
                {({ selected, active }) => (
                  <div
                    css={[
                      tw`cursor-default select-none relative py-2 pl-10 pr-4 flex flex-row`,
                      active ? tw`text-pink-600 bg-pink-100` : tw`text-gray-900`,
                      location.pathname === item.to && tw`bg-pink-50`
                    ]}
                  >
                    {item.icon && (
                      <div tw="mr-2 h-5 w-5">
                        {item.icon}
                      </div>
                    )}

                    <span
                      css={[
                        selected ? tw`font-medium` : tw`font-normal`,
                        tw`block truncate`
                      ]}
                    >
                      {item.label}
                    </span>

                    {selected ? (
                      <span
                        css={[
                          active ? tw`text-pink-600` : tw`text-pink-600`,
                          tw`absolute inset-y-0 left-0 flex items-center pl-3`
                        ]}
                      >
                        <div tw="h-2 w-2 bg-pink-400 rounded-full" />
                      </span>
                    ) : null}
                  </div>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
}
