import { isPending } from "@nll/datum/Datum"
import { isRepleteLeft, refreshFold } from "@nll/datum/DatumEither"
import { matchWildcard, WILDCARD } from "@practical-fp/union-types"
import { pipe } from "fp-ts/es6/function"
import { chain } from "fp-ts/lib/TaskEither"
import { CantQuoteForm, CantQuoteFormResult } from "packages/app/src/components/assessment-call/complete/forms/CantQuoteForm"
import { AssessmentCallResult, NotAssessedReason, useCompleteCallWithAnalytics } from "packages/app/src/components/assessment-call/hooks/useCompleteCall"
import { AssessmentResult, AssessmentSource, useCreateAssessment } from "packages/app/src/components/assessment-call/hooks/useCreateAssessment"
import { Card, CardContent, CardHeader, ErrorMessage } from "packages/app/src/elements"
import { PageHeader, PageHeaderBackLink } from "packages/app/src/elements/PageHeader"
import { SectionHeader } from "packages/app/src/elements/SectionHeader"
import { SidebarLayout } from "packages/app/src/layout/SidebarLayout"
import { ErrorSubshell } from "packages/app/src/layout/subshells/ErrorSubshell"
import { useAsync, useFromTaskEither } from "packages/app/src/lib/useAsync"
import { useRouter } from "packages/app/src/lib/useRouter"
import { matchStringUnion } from "packages/app/src/lib/utils"
import { ComponentProps, FC, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import 'twin.macro'
import { AssessmentCallsDetailsSkeleton } from "../../../components/assessment-call/details/Skeleton"
import { AssessmentCallUIDetailsResponse, useAssessmentCallDetails } from "../../../components/assessment-call/hooks/useAssessmentCallDetails"
import { ROUTES } from "../../../layout/Navigation"
import { ContentLayoutFlow, DetailsSubshell } from "../../../layout/subshells/DetailsSubshell"
import { RequestError } from "../../../lib/fetch"

export const AssessmentCallCantQuoteScene: FC = () => {
  const { id } = useParams<{ id: string }>()
  const { status, execute } = useAssessmentCallDetails()

  const render = refreshFold(
    () => <AssessmentCallsDetailsSkeleton />,
    () => <AssessmentCallsDetailsSkeleton />,
    (e: RequestError) => matchWildcard(e, {
      ResponseError: e => e.statusCode === 401
        ? <ErrorSubshell title={`You don't have access to this call`} />
        : <ErrorSubshell title={`Error getting call details ${e.details}`} />,
      [WILDCARD]: () => <ErrorSubshell title={`Error getting call details, please check your network or refresh`} />
    }),
    (call: AssessmentCallUIDetailsResponse) => (
      <DetailsSubshell
        header={(
          <PageHeader
            title={`${call.leadName} - Can't Quote`}
            back={(
              <PageHeaderBackLink to={ROUTES.assessmentCalls.details(id)}>
                Back to call details
              </PageHeaderBackLink>
            )}
          />
        )}
        content={(
          <SidebarLayout
            left={(
              <Content call={call} />
            )}
            right={<Sidebar call={call} />}
          />
        )}
      />
    )
  )

  useEffect(() => { execute(id) }, [id])

  return render(status)
}

const Sidebar: FC<{ call: AssessmentCallUIDetailsResponse }> = ({ call }) => (
  <Card>
    <CardHeader
      title={`Client's Summary`}
    />
    <CardContent tw="text-sm">
      {call.requestDetails}
    </CardContent>
  </Card>
)

const Content: FC<{ call: AssessmentCallUIDetailsResponse }> = ({ call }) => {

  const completeCall = useCompleteCallWithAnalytics()
  const createAssessment = useCreateAssessment()
  const { replace } = useRouter()

  const resultToAction = (result: CantQuoteFormResult) => {
    const complete = (result: AssessmentCallResult) => completeCall(call, { result })

    const assess = (result: AssessmentResult) => pipe(
      createAssessment({
        leadId: call.leadId,
        result,
        source: AssessmentSource.AssessmentCall(call.id)
      }),
      chain(r => completeCall(call, {
        result: AssessmentCallResult.Assessed(r.id)
      } ))
    )

    return matchStringUnion(result.reasonKey, {
      "not-legal-matter": () => assess(AssessmentResult.NotLegalMatter({ details: result.other })),
      "no-response": () => complete(AssessmentCallResult.NoAnswer(undefined)),
      "wrong-solicitor": () => complete(AssessmentCallResult.NotAssessed(NotAssessedReason.NotSuitable({ details: result.other }))),
      unavailable: () => complete(AssessmentCallResult.NotAssessed(NotAssessedReason.TooBusy({ details: result.other }))),
      other: () => complete(AssessmentCallResult.NotAssessed(NotAssessedReason.Other({ details: result.other! })))
    })
  }

  const { status, execute } = useFromTaskEither(resultToAction)

  const submit = async (result: CantQuoteFormResult) => {
    await execute(result)
      .then(() => replace(ROUTES.assessmentCalls.details(call.id, { dismissed: 'true' })))
  }

  return (
    <ContentLayoutFlow>
      <SectionHeader
        title={"Why didn't you quote the client?"}
        supportingText={"Please select the reason you couldn't provide a quote. This will be used to improve the types of clients you receive in future."}
      />

      <CantQuoteForm callId={call.id} onComplete={submit} loading={isPending(status)} />

      {isRepleteLeft(status) && (
        <ErrorMessage title="Error saving call result" />
      )}
    </ContentLayoutFlow>
  )
}

