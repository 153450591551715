import { refreshFold } from "@nll/datum/DatumEither"
import React, { FC, useEffect, useMemo } from "react"
import { IntlProvider } from "react-intl"
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useLocation
} from "react-router-dom"

import {
  AuthenticationProvider,
  LoggedInUser,
  useAuthentication,
  UserState
} from "./contexts/Authentication"
import { UserConsistencyProvider } from "./contexts/UserConsistencyProvider"
import { ModalProvider } from "./contexts/Modal"
// import { KycProvider } from './contexts/KYC'
// import { PaymentDetailsProvider } from './contexts/PaymentDetails'
// import { PersonalInformationProvider } from './contexts/PersonalInformation'
import { UserProvider, useUser } from "./contexts/User"
import { ErrorMessageOld } from "./elements"
import { Spinner } from "./elements/Loading"
import { LoggedInShell } from "./layout/LoggedInShell"
import { LoggedOutShell } from "./layout/LoggedOutShell"
import { filterNullValuesFromObject, ROUTES } from "./layout/Navigation"
import { AdminSwitch } from "./scenes/admin/AdminSwitch"
import { AuthSwitch } from "./scenes/auth/AuthSwitch"
import { CaseSwitch } from "./scenes/case/CaseSwitch"
import { DashboardScene } from "./scenes/Dashboard"
import { KycStatusScene } from "./scenes/kyc/KycStatusScene"
import { PaymentMethodSwitch } from "./scenes/paymentmethods/PaymentMethodsSwitch"
import { SolicitorSwitch } from "./scenes/solicitor/SolicitorSwitch"
import { isSome } from "fp-ts/es6/Option"
import { OnboardingShell } from "./layout/OnboardingShell"
import { OnboardingSwitch } from "./scenes/onboarding/OnboardingSwitch"
import { AssessmentCallsSwitch } from "./scenes/assessment-calls/AssessmentCallsSwitch"
import { BillingSwitch } from "./scenes/billing/BillingSwitch"
import { QueryClient, QueryClientProvider } from "react-query"
// @ts-ignore
import CobrowseIO from "cobrowse-sdk-js"
import { useEnvironment } from "./contexts/Environment"
import { FeatureFlagsProvider } from "./hooks/useFeatureFlags"
import { pipe } from "fp-ts/lib/function"
import * as O from "fp-ts/lib/Option"
import { PosthogProvider, usePosthog } from "./contexts/Posthog"
import { usePageViewEvent as usePageViewEvent } from "./features/analytics/useViewPageEvent"
import { UserType } from "./features/analytics/types"

const LoggedOutSwitch = () => {
  return (
    <Switch>
      <Route path={ROUTES.auth.root} component={AuthSwitch} />
      {/* <Route path={ROUTES.onboarding.root} component={OnboardingSwitch} /> */}
      <Redirect to={ROUTES.auth.login(window.location.pathname)} />
    </Switch>
  )
}

const LoggedInSwitch = () => (
  <Switch>
    <Route path={ROUTES.admin.root} component={AdminSwitch} />
    <Route path={ROUTES.submitKyc.root()} component={KycStatusScene} />
    <Route path={ROUTES.case.root} component={CaseSwitch} />
    <Route path={ROUTES.paymentMethods.root} component={PaymentMethodSwitch} />
    <Route path={ROUTES.solicitors.root} component={SolicitorSwitch} />
    <Route path={ROUTES.clientPayments.root} component={BillingSwitch} />
    <Route
      path={ROUTES.assessmentCalls.root()}
      component={AssessmentCallsSwitch}
    />
    <Route path={ROUTES.root} component={DashboardScene} />
  </Switch>
)

const AuthenticatedOnboardingSwitch = () => (
  <Switch>
    <Route path={ROUTES.onboarding.root} component={OnboardingSwitch} />
    <Redirect to={ROUTES.onboarding.root} />
  </Switch>
)

export const App = () => {
  const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <PosthogProvider>
        <FeatureFlagsProvider>
          <BrowserRouter>
            <IntlProvider locale={navigator.language}>
              <ModalProvider>
                <AuthenticationProvider>
                  <AuthLoader />
                </AuthenticationProvider>
              </ModalProvider>
            </IntlProvider>
          </BrowserRouter>
        </FeatureFlagsProvider>
      </PosthogProvider>
    </QueryClientProvider>
  )
}

export const AuthLoader: FC = () => {
  const { state } = useAuthentication()

  const { initialise } = usePosthog()

  useEffect(initialise, [])

  const location = useLocation()
  const trackPageView = usePageViewEvent()
  // use location track page view
  useEffect(() => {
    // get pathname and query strings
    const pathNameAndQueryString = location.pathname + location.search
    if (location) {
      trackPageView({ page: pathNameAndQueryString })
    }
  }, [location])

  const render = refreshFold(
    () => <Spinner />,
    () => <Spinner />,
    () => <ErrorMessageOld title="Error loading user" />,
    (user: UserState) =>
      UserState.match(user, {
        LoggedIn: (loggedIn) => <LoggedInApp user={loggedIn} />,
        LoggedOut: () => <LoggedOutApp />
      })
  )

  return render(state)
}

export const LoggedInApp: FC<{ user: LoggedInUser }> = ({ user }) => {
  const { COBROWSE_KEY } = useEnvironment()

  CobrowseIO.license = COBROWSE_KEY
  CobrowseIO.start()
  CobrowseIO.customData = {
    user_id: user.user.attributes.sub,
    user_email: user.user.attributes.email
  }

  return (
    <UserConsistencyProvider user={user} loading={<Spinner />}>
      <UserProvider user={user} loading={<Spinner />}>
        <UserOnboardingHandler />
        {/* <KycProvider>
        <PersonalInformationProvider>
          <PaymentDetailsProvider> */}
        {/* <LoggedInTemplate>
                <LoggedInSwitch />
              </LoggedInTemplate> */}
        {/* </PaymentDetailsProvider>
        </PersonalInformationProvider>
      </KycProvider> */}
      </UserProvider>
    </UserConsistencyProvider>
  )
}

const UserOnboardingHandler = () => {
  const user = useUser()
  const { aliasAndUpdateUserProps } = usePosthog()

  const userAttributes = pipe(
    user.name,
    O.toUndefined,
    (a) => { 
      const userType: UserType = user.isSolicitor ? 'solicitor' : user.isAdmin ? 'admin' : 'client'

      return {
      name: a,
      id: user.userId,
      email: user.emailAddress,
      isSolicitor: user.isSolicitor,
      isAdmin: user.isAdmin,
      userType
    }},
    filterNullValuesFromObject
  )

  useEffect(() => {
    aliasAndUpdateUserProps(userAttributes.email, userAttributes.id, userAttributes)
  }, [user])

  const isOnboarded = isSome(user.name)

  return isOnboarded ? (
    <LoggedInShell>
      <LoggedInSwitch />
    </LoggedInShell>
  ) : (
    <OnboardingShell>
      <AuthenticatedOnboardingSwitch />
    </OnboardingShell>
  )
}

export const LoggedOutApp: FC = (props) => (
  <LoggedOutShell>
    <LoggedOutSwitch />
  </LoggedOutShell>
)
