import { CheckIcon } from "@heroicons/react/outline"
import { FC } from "react"
import tw from 'twin.macro'
import { matchStringUnion } from "../lib/utils"

export type Step = {
  name: string
  description: string
  status: 'complete' | 'current' | 'upcoming'
}

// type StepWithLink = Step & { href: string }

// type StepperProps = StepperPropsWithLink | StepperPropsWithoutLink

// type StepperPropsWithLink = {
//   navigable: true
//   steps: StepWithLink[]
// }

type StepperProps = {
  // navigable: false
  steps: Step[]
}

export const Stepper: FC<StepperProps> = ({ steps }) => {
  return (
    <nav aria-label="Progress">
      <ol role="list" tw="overflow-hidden">
        {steps.map((step, stepIdx) => (
          <li key={step.name} css={[
            tw`relative`,
            stepIdx !== steps.length - 1 ? tw`pb-10` : ''
          ]}>
            {stepIdx !== steps.length - 1
              ? (
                <div
                  css={[
                    tw`-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full`,
                    matchStringUnion(step.status, {
                      complete: tw`bg-indigo-600`,
                      current: tw`bg-gray-300`,
                      upcoming: tw`bg-gray-300`
                    })
                  ]}
                  aria-hidden="true"
                />
              )
              : null
            }
            <div tw="relative flex items-start" className="group" aria-current={matchStringUnion(step.status, {
              current: 'step',
              complete: false,
              upcoming: false
            })}>
              <span tw="h-9 flex items-center" aria-hidden="true">
                {matchStringUnion(step.status, {
                  complete: (
                    <span css={[
                      tw`relative z-10 w-8 h-8 flex items-center justify-center bg-indigo-600 rounded-full`
                      // tw`group-hover:bg-indigo-800`
                    ]}>
                      <CheckIcon tw="w-5 h-5 text-white" aria-hidden="true" />
                    </span>
                  ),
                  current: (
                    <span tw="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-indigo-600 rounded-full">
                      <span tw="h-2.5 w-2.5 bg-indigo-600 rounded-full" />
                    </span>
                  ),
                  upcoming: (
                    <span css={[
                      tw`relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full`,
                      // tw`group-hover:border-gray-400`
                    ]}>
                      <span css={[
                        tw`h-2.5 w-2.5 bg-transparent rounded-full`
                        // tw`group-hover:bg-gray-300`
                      ]} />
                    </span>
                  )
                })}
              </span>
              <span tw="ml-4 min-w-0 flex flex-col">
                <span
                  css={[
                    tw`text-sm font-semibold tracking-wide`,
                    matchStringUnion(step.status, {
                      complete: tw`text-gray-700`,
                      current: tw`text-indigo-700`,
                      upcoming: tw`text-gray-700`
                    })
                  ]}
                >
                  {step.name}
                </span>
                <span
                  css={[
                    tw`text-sm`,

                    matchStringUnion(step.status, {
                      complete: tw`text-gray-500`,
                      current: tw`text-indigo-600`,
                      upcoming: tw`text-gray-500`
                    })
                  ]}
                >
                  {step.description}
                </span>
              </span>
            </div>

          </li>
        ))}
      </ol>
    </nav>
  )
}
