import { CaseStatus } from "@lawhive/generated-api"
import { sequenceT } from "fp-ts/es6/Apply"
import React, { FC, useEffect, useState } from "react"
import { isSuccess, refreshFold, sequenceTuple } from "@nll/datum/DatumEither"
import { Link, Redirect, useParams } from "react-router-dom"
import tw from "twin.macro"

import { useCase } from "../../../contexts/case/CaseContext"
import { CaseDetails, CaseMessage } from "../../../contexts/case/useGetCase"
import { Card, CardContent, CardTitle, ErrorMessage, ErrorMessageOld } from "../../../elements"

import { Button, ButtonGroup, ButtonLink } from "../../../elements/Button"
import { CaseTitleBreadcrumb } from "../../../elements/case/CaseTitleBreadcrumb"
import { SkeletonBlock, SkeletonSingleLine } from "../../../elements/Skeleton"
import { PageTitleBar, ScenePanel, ScrollingChildrenContainer } from "../../../layout/Layout"
import { ROUTES } from "../../../layout/Navigation"
import { AdminOnly, ClientOnly, SolicitorOnly } from "../../../layout/Selectors"
import { ApiError, useFromTaskEither } from "../../../lib/useAsync"
import { useViewingAs, ViewAsProvider } from "./admin/ViewAsContext"
import { ActiveActionPanel, ActiveDetailsPanel, ActiveStatusPanel } from "./client/states/Active"
import {
  ClientCompletedActionPanel,
  ClientCompletedDetailsPanel,
  ClientCompletedStatusPanel
} from "./client/states/Complete"
import {
  ClientWaitingForPaymentActionPanel,
  ClientWaitingForPaymentDetailsPanel,
  ClientWaitingForPaymentStatusPanel
} from "./client/states/WaitingForPayment"
import {
  ClientWaitingForSolicitorDetailsPanel,
  ClientWaitingForSolicitorStatusPanel
} from "./client/states/WaitingForSolicitor"
import {
  SolicitorCaseActionPanel,
  SolicitorCaseDetailsPanel,
  SolicitorCaseStatusPanel
} from "./solicitor/SolicitorDetailsHub"
import { CancelledDetailsPanel } from "./shared/states/Cancelled"
import { useQuotes } from "packages/app/src/features/quotes/context/QuoteProvider"
import { useUser } from "packages/app/src/contexts/User"
import { useModal } from "packages/app/src/contexts/Modal"
import { Modal } from "packages/app/src/elements/Modal"
import { useCaseViewed } from "packages/app/src/features/analytics/useCaseViewed"
import { CheckoutCancelledMessage } from "packages/app/src/features/quotes/components/CheckoutCancelledMessage"
import { QuoteSubmittedMessage } from "./solicitor/follow-on/QuoteSubmittedMessage"
import { extractQuoteInformation } from "packages/app/src/features/quotes"
import { useRouter } from "../../../lib/useRouter"
import { useQuoteCheckoutCancelledEvent } from "packages/app/src/features/analytics/useCheckoutEvent"
import { Badge } from "packages/app/src/elements/Badge"
import { fetchAndCast } from "packages/app/src/lib/fetch"
import { useApiFetch } from "packages/app/src/lib/useApiClient"
import { pipe } from "fp-ts/es6/function"
import { useIntercom } from "react-use-intercom"

export type ComponentWithCase = FC<{ case: CaseDetails }>

export const CaseDetailsScene = () => {
  const { caseDetails, messages } = useCase()

  const render = refreshFold(
    () => <SkeletonCaseDetails />,
    () => <SkeletonCaseDetails />,
    (e: ApiError) =>
      ApiError.match(e, {
        NotFound: () => <NotFound />,
        default: () => <ErrorMessageOld title="Error getting case details" />
      }),
    ([cas, messages]: [CaseDetails, CaseMessage[]]) => (
      <CaseWithData case={cas} />
    )
  )

  // return render(initial)
  return render(sequenceTuple(caseDetails, messages))
}

export const SkeletonCaseDetails = () => (
  <>
    <PageTitleBar>
      <SkeletonSingleLine />
    </PageTitleBar>
    <ScenePanel>
      <div tw="py-4 flex flex-row mb-2">
        <SkeletonBlock tw="mr-4 w-16 bg-gray-200" />
        <div>
          <SkeletonSingleLine tw="bg-gray-200 w-48 sm:w-96" />
          <SkeletonSingleLine tw="mt-2 bg-gray-200 w-24 sm:w-48" />
        </div>
      </div>
      <Card>
        <CardContent>
          <SkeletonBlock />
          <SkeletonBlock tw="mt-4" />
        </CardContent>
      </Card>
    </ScenePanel>
  </>
)

const CaseWithData: ComponentWithCase = ({ case: cas }) => {
  const { getQuotes: getQuote } = useQuotes()

  const triggerCaseViewedEvent = useCaseViewed()

  useEffect(() => {
    triggerCaseViewedEvent({
      caseId: cas.id,
    })
  }, [cas.id])

  return getQuote.isLoading ? (
    <SkeletonCaseDetails />
  ) : (
    <ViewAsProvider>
      <CaseTitleBreadcrumb caseNumber={cas.friendlyId} />
      <ScrollingChildrenContainer>
        <AdminOnly>
          <AdminPanel case={cas} />
        </AdminOnly>
        <PaymentHoldExpired />
        <ScenePanel tw="space-y-4">
          <FollowOnCreatedMessage />
          <CheckoutCancelled caseId={cas.id} />
          <div tw="flex flex-col sm:(flex-row items-center) gap-4 sm:gap-8">
            <div tw="flex-1">
              <CaseStatusPanel case={cas} />
            </div>
            <div tw="max-w-screen-md">
              <CaseActionPanel case={cas} />
            </div>
          </div>
          <CaseDetailsPanel case={cas} />
        </ScenePanel>
      </ScrollingChildrenContainer>
    </ViewAsProvider>
  )
}

export const FollowOnCreatedMessage = () => {
  const { isSolicitor } = useUser()

  const { params }: {params: {followOnSubmitted: string}} = useRouter()

  const displayFollowOnMessage = params.followOnSubmitted === "true" && isSolicitor

  return <QuoteSubmittedMessage initialVisible={displayFollowOnMessage}/>
}

export const PaymentHoldExpired = () => {
  const { firstQuote } = useQuotes()

  const { isSolicitor } = useUser()

  const { caseDetails } = useCase()

  const [showModal, hideModal] = useModal(() => (
    <Modal onDismiss={hideModal}>
      <Card>
        <CardContent>
          <CardTitle>Your Initial Payment was Refunded</CardTitle>
          <div tw="max-w-lg leading-relaxed mt-3">
            Sorry about the inconvenience here. Lawhive doesn't take any
            payments until there's a solicitor ready to work with you. Sometimes
            finding the perfect solicitor takes longer than expected, and so we
            automatically fully refund any pending charges after 7 days. This
            means you'll have to authorise a new payment if you want to
            continue.
            <p tw="mt-3">
              <b>
                Your previous payment has been fully refunded and you have not
                been charged anything yet.
              </b>
            </p>
          </div>
        </CardContent>
      </Card>
    </Modal>
  ))

  if(!firstQuote) {
    return null
  }

  const { quoteType, hasPaymentHoldExpired } = extractQuoteInformation(firstQuote)

  const displayExpiryMessage =
    hasPaymentHoldExpired &&
    quoteType === "ClientAuthorisation" &&
    !isSolicitor &&
    isSuccess(caseDetails) && caseDetails.value.right.status === CaseStatus.needsClientPayment

  return displayExpiryMessage ? (
    <div tw="bg-red-500 w-full text-center p-3 text-white font-semibold">
      <p tw="underline cursor-pointer" onClick={showModal}>
        Your initial payment was refunded.
      </p>{" "}
      Please make a new payment to continue with your case.
    </div>
  ) : (
    <></>
  )
}

type CheckoutCancelledProps = {
  caseId: string
}

export const CheckoutCancelled = ({caseId}: CheckoutCancelledProps) => {
  const { firstQuote } = useQuotes()
  const {params}: {params: {cancelled: string}} = useRouter()
  const checkoutCancelledEvent = useQuoteCheckoutCancelledEvent()
  const triggerCheckoutCancelledEvent = () => {
    checkoutCancelledEvent({
      quoteId: firstQuote ? firstQuote._id : "",
      caseId,
    })
  }
    const { isSolicitor } = useUser()
    const didCancel = params.cancelled === "true"

    if(didCancel) {
      triggerCheckoutCancelledEvent()
    }
    const displayCancelledMessage = didCancel && !isSolicitor

    return <CheckoutCancelledMessage initialVisible={displayCancelledMessage} />
}

const ClientCaseStatusPanel: ComponentWithCase = ({ case: cas }) => {
  switch (cas.status) {
    case CaseStatus.needsDetails:
    case CaseStatus.needsPrice:
    case CaseStatus.needsSolicitorAssignment:
    case CaseStatus.needsSolicitorApproval:
    case CaseStatus.pendingExport:
      return <ClientWaitingForSolicitorStatusPanel />
    case CaseStatus.needsClientPayment:
      return <ClientWaitingForPaymentStatusPanel case={cas} />
    // return <ClientNeedsToPay case={cas} />
    case CaseStatus.active:
      return <ActiveStatusPanel case={cas} />
    case CaseStatus.completed:
      return <ClientCompletedStatusPanel case={cas} />
    case CaseStatus.cancelled:
    case CaseStatus.reviewed:
    case CaseStatus.exported:
      return null
    // return <ClientActiveCase case={cas} />
  }
}

const ActionLink = tw(
  Link
)`w-full flex items-center justify-center rounded-md shadow px-5 py-3 border border-transparent text-base leading-6 font-medium text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo-200 transition duration-150 ease-in-out disabled:text-gray-50 disabled:cursor-not-allowed disabled:bg-indigo-300`

const AdminCaseActionImpersonationPanel: ComponentWithCase = ({ case: cas }) => {
  const { viewingAs } = useViewingAs()

  return (
    <div>
      {viewingAs === "client" && <ClientCaseActionPanel case={cas} />}
      {viewingAs === "solicitor" && <SolicitorCaseActionPanel case={cas} />}
    </div>
  )
}

const AdminPanel: ComponentWithCase = (props) => (
  <PageTitleBar tw="border-t py-2">
    <div tw="flex flex-col sm:flex-row gap-4 justify-between items-center">
      <div>
        <AdminStatusMessage {...props} />
      </div>
      <div tw="flex flex-col gap-2 sm:flex-row sm:gap-8 items-center">
        <AdminViewSwitcher />
        <AdminCaseActions {...props} />
      </div>
    </div>
  </PageTitleBar>
)

const AdminCaseActions: ComponentWithCase = ({ case: cas }) => {
  const renderAction = () => {
    switch (cas.status) {
      case CaseStatus.needsDetails:
        return (
          <ButtonGroup>
            <ButtonLink to={ROUTES.case.admin.addDetails(cas.id)}>
              Add case details
            </ButtonLink>
            <ButtonLink to={ROUTES.case.admin.cancel(cas.id)}>
              Cancel Case
            </ButtonLink>
          </ButtonGroup>
        )
      case CaseStatus.needsPrice:
        return (
          <ButtonGroup>
            <ButtonLink to={ROUTES.case.admin.addPrice(cas.id)}>
              Add price
            </ButtonLink>
            <ButtonLink to={ROUTES.case.admin.addExistingPayment(cas.id)}>
              Add existing payment
            </ButtonLink>
            <ButtonLink to={ROUTES.case.admin.cancel(cas.id)}>
              Cancel Case
            </ButtonLink>
          </ButtonGroup>
        )
      case CaseStatus.needsSolicitorAssignment:
        return (
          <ButtonGroup>
            <ButtonLink to={ROUTES.case.admin.assignSolicitor(cas.id)}>
              Assign Solicitor
            </ButtonLink>
            <ButtonLink to={ROUTES.case.admin.cancel(cas.id)}>
              Cancel Case
            </ButtonLink>
          </ButtonGroup>
        )
      case CaseStatus.needsSolicitorApproval:
        return (
          <ButtonGroup>
            <ButtonLink to={ROUTES.case.admin.unassign(cas.id)}>
              Unassign Solicitor
            </ButtonLink>
            <ButtonLink to={ROUTES.case.admin.cancel(cas.id)}>
              Cancel Case
            </ButtonLink>
          </ButtonGroup>
        )
      case CaseStatus.needsClientPayment:
        return (
          <ButtonGroup>
            <ButtonLink to={ROUTES.case.admin.cancel(cas.id)}>
              Cancel Case
            </ButtonLink>
          </ButtonGroup>
        )
      case CaseStatus.active:
        return (
          <ButtonLink to={ROUTES.case.admin.markComplete(cas.id)}>
            Mark as Complete
          </ButtonLink>
        )
      case CaseStatus.cancelled:
      case CaseStatus.completed:
      case CaseStatus.reviewed:
      case CaseStatus.exported:
      case CaseStatus.pendingExport:
        return null
    }
  }

  return (
    <div>
      {renderAction()}
    </div>
  )
}

const AdminViewSwitcher = () => {
  const { viewingAs, setViewingAs } = useViewingAs()

  const toggleViewingAs = () => {
    viewingAs === "client" ? setViewingAs("solicitor") : setViewingAs("client")
  }

  return (
    <div tw="flex flex-row gap-4 items-center">
      <span tw="text-sm">Viewing as: <Badge>{viewingAs}</Badge></span>
      <Button onClick={toggleViewingAs}>Toggle</Button>
    </div>
  )
}

const ClientCaseActionPanel: ComponentWithCase = ({ case: cas }) => {
  switch (cas.status) {
    case CaseStatus.needsDetails:
    case CaseStatus.needsPrice:
    case CaseStatus.needsSolicitorAssignment:
    case CaseStatus.needsSolicitorApproval:
    case CaseStatus.pendingExport:
      return null
    case CaseStatus.needsClientPayment:
      return <ClientWaitingForPaymentActionPanel case={cas} />
    case CaseStatus.active:
      return <ActiveActionPanel case={cas} />
    case CaseStatus.completed:
      return <ClientCompletedActionPanel case={cas} />
    case CaseStatus.cancelled:
    case CaseStatus.reviewed:
    case CaseStatus.exported:
      return null
  }
}

const CaseStatusPanel: ComponentWithCase = ({ case: cas }) => (
  <>
    <AdminOnly>
      <AdminCaseStatusPanel case={cas} />
    </AdminOnly>
    <ClientOnly>
      <ClientCaseStatusPanel case={cas} />
    </ClientOnly>
    <SolicitorOnly>
      <SolicitorCaseStatusPanel case={cas} />
    </SolicitorOnly>
  </>
)

const AdminStatusIndicator = tw.div`text-pink-600 font-medium rounded-md`

const AdminCaseStatusPanel: ComponentWithCase = ({ case: cas }) => {
  const { viewingAs } = useViewingAs()

  return (
    <div>
      {viewingAs === "client" && <ClientCaseStatusPanel case={cas} />}
      {viewingAs === "solicitor" && <SolicitorCaseStatusPanel case={cas} />}
    </div>
  )
}

const AdminStatusMessage: ComponentWithCase = ({ case: cas }) => {
  const renderStatus = () => {
    switch (cas.status) {
      case CaseStatus.needsDetails:
        return <AdminStatusIndicator>Needs Details</AdminStatusIndicator>
      case CaseStatus.needsPrice:
        return <AdminStatusIndicator>Needs Details</AdminStatusIndicator>
      case CaseStatus.needsSolicitorAssignment:
        return <AdminStatusIndicator>Needs Solicitor Assignment</AdminStatusIndicator>
      case CaseStatus.needsSolicitorApproval:
        return <AdminStatusIndicator>Needs Solicitor Approval</AdminStatusIndicator>
      case CaseStatus.needsClientPayment:
        return <AdminStatusIndicator>Needs Client Payment</AdminStatusIndicator>
      case CaseStatus.active:
        return <AdminStatusIndicator>Active</AdminStatusIndicator>
      case CaseStatus.cancelled:
        return <AdminStatusIndicator>Cancelled</AdminStatusIndicator>
      case CaseStatus.completed:
        return <AdminStatusIndicator>Completed</AdminStatusIndicator>
      case CaseStatus.reviewed:
        return <AdminStatusIndicator>Reviewed</AdminStatusIndicator>
      case CaseStatus.exported:
        return <AdminStatusIndicator>Exported</AdminStatusIndicator>
      case CaseStatus.pendingExport:
        return <AdminStatusIndicator>Pending Export</AdminStatusIndicator>
    }
  }

  return (
    <div>
      {renderStatus()}
    </div>
  )
}

const CaseActionPanel: ComponentWithCase = ({ case: cas }) => (
  <>
    <SolicitorOnly>
      <SolicitorCaseActionPanel case={cas} />
    </SolicitorOnly>
    <AdminOnly>
      <AdminCaseActionImpersonationPanel case={cas} />
    </AdminOnly>
    <ClientOnly>
      <ClientCaseActionPanel case={cas} />
    </ClientOnly>
  </>
)

const CaseDetailsPanel: ComponentWithCase = ({ case: cas }) => (
  <>
    <SolicitorOnly>
      <SolicitorCaseDetailsPanel case={cas} />
    </SolicitorOnly>
    <ClientOnly>
      <ClientCaseDetailsPanel case={cas} />
    </ClientOnly>
    <AdminOnly>
      <AdminCaseDetailsPanel case={cas} />
    </AdminOnly>
  </>
)

const AdminCaseDetailsPanel: ComponentWithCase = ({ case: cas }) => {
  const { viewingAs } = useViewingAs()

  return (
    <div>
      {viewingAs === "client" && <ClientCaseDetailsPanel case={cas} />}
      {viewingAs === "solicitor" && <SolicitorCaseDetailsPanel case={cas} />}
    </div>
  )
}

const ClientCaseDetailsPanel: ComponentWithCase = ({ case: cas }) => {
  switch (cas.status) {
    case CaseStatus.needsDetails:
    case CaseStatus.needsPrice:
    case CaseStatus.needsSolicitorAssignment:
    case CaseStatus.needsSolicitorApproval:
    case CaseStatus.pendingExport:
      return <ClientWaitingForSolicitorDetailsPanel case={cas} />
    case CaseStatus.needsClientPayment:
      return <ClientWaitingForPaymentDetailsPanel case={cas} />
    // return <ClientNeedsToPay case={cas} />
    case CaseStatus.active:
      return <ActiveDetailsPanel case={cas} />
    case CaseStatus.completed:
      return <ClientCompletedDetailsPanel case={cas} />
    case CaseStatus.cancelled:
      return <CancelledDetailsPanel case={cas} />
    case CaseStatus.exported:
      return <ClientCaseRedirect case={cas} />
    case CaseStatus.reviewed:
      return null
  }
}


export const useRedirectToV2 = () => {
  const api = useApiFetch(fetchAndCast<{ url: string }>())

  const { status, execute } = useFromTaskEither((caseId: string) =>
      api(`v2-integration/login-token/${caseId}`, {
        method: 'POST',
      })
  )

  return { status, execute }
}


const ClientCaseRedirect: ComponentWithCase = ({ case: cas }) => {
  const { execute, status } = useRedirectToV2()
  const { show } = useIntercom()

  useEffect(() => {
    execute(cas.id)
  }, [])

  return pipe(status, refreshFold(
    () => <div>Redirecting...</div>,
    () => <div>Redirecting...</div>,
    (e) => (
      <ErrorMessage title="Failed to take you to your case">
    Please refresh to try again. If this persists please <button type='button' onClick={show} tw="underline text-blue-500">click here</button> {" "} to speak to support, or email
    <a href="mailto:support@lawhive.co.uk" tw="underline">
      support@lawhive.co.uk
    </a>
  </ErrorMessage>),
    (result) => {
      window.location.href = result.url
      return null
    }
  ))
}

const NotFound = () => (
  <CardContent>
    <h3 tw="text-lg leading-6 font-medium text-gray-900">Case not found</h3>
  </CardContent>
)

export const CaseStatusTitle = tw.h2`font-semibold text-lg sm:text-2xl text-gray-900`
export const CaseStatusTitleHeader = tw.p`uppercase text-xs font-medium text-gray-500 tracking-wide`
