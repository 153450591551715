import { PriceUnits } from "packages/app/src/lib/Primitives"
import { QuoteEntityV2, PricedLawhiveDiscount, Quote, QuotePriceDetails, QuoteLineItem } from "../types"
import {
  PriceOrRange,
  PriceRange,
  QuoteSummaryTable,
  SummaryItemProps
} from "./QuoteSummaryTable"
import * as A from "fp-ts/Array"
import { matchStringUnion } from "packages/app/src/lib/utils"
import {
  ExternalFee,
  PricedLawhiveFee
} from "packages/app/src/hooks/useGetQuotePricing"
import { match } from "ts-pattern"
import { matchExhaustive } from "@practical-fp/union-types"
import { QuoteScopesTable } from "./QuoteScopesTable"

type SolicitorQuoteSummaryProps = {
  quote: QuoteEntityV2
}

const isClientDiscount = (d: PricedLawhiveDiscount) => d.side === "payer"
const getClientDiscounts = A.filter(isClientDiscount)

const produceClientDiscountText = (d: PricedLawhiveDiscount) =>
  matchStringUnion(d.details.type, {
    absolute: `Client Discount`,
    percentage: `Client Discount (${d.details.amount}%)`
  })

const lineItemFromLawhiveFee = (fee: PricedLawhiveFee): SummaryItemProps => ({
  amount: fee.amount as PriceUnits,
  name: match(fee.key)
    .with("lawhive-pay-monthly-fee", () => `${fee.description} (Inc. VAT)`)
    .otherwise(() => fee.description),
  description: match(fee.key)
    .with("stripe-payment-processing", () => `(1.4-2.9% + 20p)`)
    .otherwise(() => undefined),
  isNegative: true
})

const lineItemFromDisbursement = (lineItems: QuoteLineItem[]): SummaryItemProps => {
  const totalDisbursements = lineItems.filter(l => l.type === "disbursement").reduce((acc, cur) => acc + cur.amount, 0)
  return {
    amount: totalDisbursements as PriceUnits,
    name: "Disbursements",
    description: undefined,
    isNegative: false
  }
}

// TODO Make backend driven
const calculateExternalFeePriceRange =
  (price: QuotePriceDetails) =>
  (fee: ExternalFee): PriceRange =>
    matchExhaustive(fee.structure, {
      Dynamic: (c) => ({
        lower: ((c.percentageRangeStart / 100) * price.payableAmount +
          c.fixedFeeAmount) as PriceUnits,
        upper: ((c.percentageRangeEnd / 100) * price.payableAmount +
          c.fixedFeeAmount) as PriceUnits
      })
    })

const lineItemFromExternalFee =
  (price: QuotePriceDetails) =>
  (fee: ExternalFee): SummaryItemProps => {
    const range = calculateExternalFeePriceRange(price)(fee)

    return {
      amount: range,
      name: `Payment Processing Fee`,
      description: `(1.4-2.9% + 20p)`,
      isNegative: true
    }
  }

// TODO Make backend driven
const calculateEarnings = (price: QuotePriceDetails): PriceOrRange => {
  const hasExternalFees = A.isNonEmpty(price.externalFees)

  const baseEarnings = (price.payableAmount -
    price.lawhiveFeeTotal) as PriceUnits

  if (!hasExternalFees) {
    return baseEarnings
  } else {
    const totalExternalFees = price.externalFees
      .map(calculateExternalFeePriceRange(price))
      .reduce(
        (p, c) => ({
          lower: p.lower + c.lower,
          upper: p.lower + c.upper
        }),
        {
          lower: 0,
          upper: 0
        }
      )

    const earningsRange: PriceRange = {
      lower: (baseEarnings - totalExternalFees.upper) as PriceUnits,
      upper: (baseEarnings - totalExternalFees.lower) as PriceUnits
    }

    return earningsRange
  }
}

const discountAdjustmentItem = (price: QuotePriceDetails): SummaryItemProps => {
  const unadjustedTotal = price.lawhiveFees
    .map((f) => f.amount)
    .reduce((p, c) => p + c, 0)
  const adjustment = unadjustedTotal - price.lawhiveFeeTotal

  return {
    name: "Case Discount Applied",
    description:
      "We've discounted this quote by reducing the Lawhive fee - your earnings are not affected",
    amount: adjustment as PriceUnits,
    isHighlighted: true
  }
}

export const SolicitorEarningsSummary = ({
  quote
}: SolicitorQuoteSummaryProps) => {
  const clientDiscounts = getClientDiscounts(quote.price.discounts)
  
  const items: SummaryItemProps[] = A.isNonEmpty(clientDiscounts)
    ? [
        {
          name: "Client Payment",
          amount: quote.price.payableAmount
        },
        ...quote.price.lawhiveFees.map(lineItemFromLawhiveFee),
        lineItemFromDisbursement(quote.lineItems),
        ...quote.price.externalFees.map(lineItemFromExternalFee(quote.price)),
        discountAdjustmentItem(quote.price),
        {
          name: "Your Net Earnings (Inc. VAT if applicable)",
          amount: calculateEarnings(quote.price),
          isBold: true
        }
      ]
    : [
        {
          name: "Legal fees (Inc. VAT if applicable)",
          amount: quote.price.feeChargeableAmount,
          isBold: true
        },
        lineItemFromDisbursement(quote.lineItems),
        ...quote.price.lawhiveFees.map(lineItemFromLawhiveFee),
        ...quote.price.externalFees.map(lineItemFromExternalFee(quote.price)),
        {
          name: "Your Net Earnings (Inc. VAT if applicable)",
          amount: calculateEarnings(quote.price),
          isBold: true
        }
      ]

  return <QuoteSummaryTable items={items} />
}
