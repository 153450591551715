import { ListSolicitorsQuery, ListSolicitorsQueryVariables } from "@lawhive/generated-api"
import { fromNullable } from "fp-ts/es6/Option"
import React, { FC, useEffect, useState } from "react"
import "twin.macro"
import { Spinner } from "../Loading"
import { Solicitor } from "../../lib/types"
import { useAsync } from "../../lib/useAsync"
import { graphql, isNotNullOrUndefined, renderLoadingOrSuccess } from "../../lib/utils"
import { Combobox, ComboboxItem } from "../Combobox"
import { pipe } from "fp-ts/es6/function"
import { refreshFold } from "@nll/datum/DatumEither"

export type SolicitorWithEmail = Solicitor & {
  emailAddress: string
}

export const SolicitorCombobox: FC<{ selectedId?: string, onSelected: (s?: SolicitorWithEmail) => void }> = ({ selectedId, onSelected, ...rest }) => {
  const { status, execute } = useAsync(
    () => graphql<ListSolicitorsQuery, ListSolicitorsQueryVariables>({
      query: /* GraphQL */ `
        query ListSolicitors(
          $filter: ModelSolicitorFilterInput
          $limit: Int
          $nextToken: String
        ) {
          listSolicitors(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
              id
              businessName
              user {
                id
                type
                emailAddress
                displayName
              }
            }
          }
        }
      `
    })
    .then(r => r.data?.listSolicitors
      ? Promise.resolve(r.data.listSolicitors)
      : Promise.reject()
    )
    .then(r => ((r.items || []).filter(isNotNullOrUndefined).map((i): SolicitorWithEmail => ({
      id: i.id,
      name: i.user?.displayName || 'No name set',
      businessName: i.businessName,
      stripeAccountId: fromNullable(i.stripeAccountId),
      emailAddress: i.user?.emailAddress || 'No email set',
    }))))
    .then(r => r.sort((a, b) => a.name.localeCompare(b.name)))
  )

  useEffect(() => {
    execute()
  }, [])

  const [internalSelectedId, setInternalSelectedId] = useState(() => selectedId)

  const solicitors: SolicitorWithEmail[] = refreshFold<Error, SolicitorWithEmail[], SolicitorWithEmail[]>(
    () => [],
    () => [],
    () => [],
    (s) => s
  )(status)

  const selectedSolicitor = solicitors.find(s => s.id === internalSelectedId)

  const getSolicitorComboboxItem = (s: SolicitorWithEmail): ComboboxItem => ({
    name: `${s.name} - ${s.emailAddress}`,
    value: s.id
  })

  useEffect(() => {
    onSelected(selectedSolicitor)
  }, [selectedSolicitor])

  return pipe(
    status,
    renderLoadingOrSuccess(
      () => <Spinner/>,
      solicitors => <Combobox items={solicitors.map(getSolicitorComboboxItem)} value={internalSelectedId} setValue={setInternalSelectedId} {...rest}/>
    )
  )
}
