import "twin.macro"

import * as DE from "@nll/datum/DatumEither"
import { isSome } from "fp-ts/es6/Option"
import { PaymentTable } from "../../components/solicitor-billing/PaymentTable"
import { PayoutTable } from "../../components/solicitor-billing/PayoutTable"
import { Card, CardHeader, FormButtonGroup } from "../../elements"
import { Button } from "../../elements/Button"
import { Spinner } from "../../elements/Loading"
import { PageHeader } from "../../elements/PageHeader"
import { useOpenPaymentDashboard } from "../../elements/solicitor/useOpenPaymentDashboard"
import { ContentLayoutFlow, DetailsSubshell } from "../../layout/subshells/DetailsSubshell"
import { SolicitorOnboardingProvider, useSolicitorOnboarding } from "../solicitor/SolicitorOnboarding"
import { Redirect, Route, Switch } from "react-router-dom"
import { ROUTES } from "../../layout/Navigation"
import { SidebarMenu, SidebarMenuItem, SidebarMenuLayout } from "../../layout/SidebarMenu"
import { ChargesScene } from "./charges/ChargesScene"

export const SolicitorBillingDashboard = () => {

  return (
    <SolicitorOnboardingProvider>
      <DetailsSubshell
        header={(
          <PageHeader
            title={"Client Payments"}
            supportingText={`View your client payments and payouts to your connected bank account. To change your billing address or bank account details click "Payment Settings"`}
            actions={(
              <FormButtonGroup>
                <PayoutDashboardAction />
              </FormButtonGroup>
            )}
          />
        )}
        content={(
          <ContentLayoutFlow>
            <SidebarMenuLayout
              menu={<BillingMenu />}
              content={<BillingRouter/>}
            />
          </ContentLayoutFlow>
        )}
      />
    </SolicitorOnboardingProvider>
  )
}

const BillingMenu = () => {

  const items: SidebarMenuItem[] = [
    {
      label: 'Payments',
      to: ROUTES.clientPayments.payments
    },
    {
      label: 'Payouts',
      to: ROUTES.clientPayments.payouts
    },
  ]

  return (
    <SidebarMenu items={items} />
  )
}


const BillingRouter = () => (
  <Switch>
    <Route path={ROUTES.clientPayments.payouts} component={PayoutsScene} />
    <Route path={ROUTES.clientPayments.charges} component={ChargesScene} />
    <Route path={ROUTES.clientPayments.payments} component={PaymentsScene} />
    <Redirect to={ROUTES.clientPayments.payments} />
  </Switch>
)

const PaymentsScene = () => (
  <Card>
    <CardHeader
      title="Client Payments"
      supportingText="Payments received from your clients by case number, date and client name."
    />
    <PaymentTable />
  </Card>
)

const PayoutsScene = () => (
  <Card>
    <CardHeader
      title="Recent Bank Payouts"
      supportingText="Payments made from your Lawhive Account into your connected bank account."
    />

    <PayoutTable />
  </Card>
)

const PayoutDashboardAction = () => {
  const { accountType } = useSolicitorOnboarding()

  return isSome(accountType) && accountType.value === 'express'
    ? <OpenPaymentDashboardButton />
    : null
}


const OpenPaymentDashboardButton = () => {
  const { status: dashboardStatus, execute } = useOpenPaymentDashboard()

  return (
    <Button
      onClick={execute}
      disabled={DE.isPending(dashboardStatus)}
    >
      Payment Settings
      {(DE.isPending(dashboardStatus) || DE.isRefresh(dashboardStatus)) &&
        <Spinner tw="h-3" />
      }
    </Button>
  )
}
