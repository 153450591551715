import { isPending } from "@nll/datum/Datum"
import { addHours, addMinutes, eachMinuteOfInterval, format, isFuture, startOfHour } from "date-fns"
import { Card, CardContent, Message } from "packages/app/src/elements"
import { ButtonLink } from "packages/app/src/elements/Button"
import { PageHeaderButtonGroup } from "packages/app/src/elements/PageHeader"
import { ROUTES } from "packages/app/src/layout/Navigation"
import { ContentLayoutFlow } from "packages/app/src/layout/subshells/DetailsSubshell"
import { useRouter } from "packages/app/src/lib/useRouter"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"
import tw, { styled } from 'twin.macro'
import { useModal } from "../../../../contexts/Modal"
import { Modal } from "../../../../elements/Modal"
import { AssessmentCallUIDetailsResponse } from "../../hooks/useAssessmentCallDetails"
import { AssessmentCallRejectionReason, useRejectAssessmentCall } from "../../hooks/useRejectAssessmentCall"
import { useScheduleAssessmentCall } from "../../hooks/useScheduleAssessmentCall"
import { AssessmentCallDescriptionSection, AssessmentCallDetailsSubshell } from "../elements"
import { BookCallModal } from "../modals/BookCallModal"
import { RejectCallModal } from "../modals/RejectCallModal"

type AssessmentCallNeedsBookingProps = {
  call: AssessmentCallUIDetailsResponse
  onRefresh: () => void
  onReject: () => void
}

export const AssessmentCallNeedsBooking: FC<AssessmentCallNeedsBookingProps> = ({ call, onRefresh, onReject }) => (
  <AssessmentCallDetailsSubshell
    call={call}
    actions={(
      <PageHeaderButtonGroup>
        <RejectCallAction call={call} onReject={onReject} />
      </PageHeaderButtonGroup>
    )}
    content={<Content call={call} onRefresh={onRefresh} />}
  />
)

const RejectCallAction: FC<{ call: AssessmentCallUIDetailsResponse, onReject: () => void }> = ({ call, onReject }) => {
  const { status: rejectStatus, execute: reject } = useRejectAssessmentCall()

  const rejectAndRefresh = useCallback(
    async (reason: AssessmentCallRejectionReason) => {
      await reject(call.id, reason)
      hide()
      onReject()
    },
    [call]
  )


  const [show, hide] = useModal(() => (
    <Modal onDismiss={dismissModal}>
      <RejectCallModal call={call} onCancel={dismissModal} onReject={rejectAndRefresh} isLoading={isPending(rejectStatus)} />
    </Modal>
  ), [rejectStatus, call])

  const location = useLocation()
  const { replace } = useRouter()

  const dismissModal = useCallback(() => {
    replace(ROUTES.assessmentCalls.details(call.id))
  }, [])

  useEffect(() => {
    // TODO Implement this in a Routed view rather than a reaction to URL changes
    // This was implemented quickly to convert a modal popup to be URL controlled
    if (location.pathname.endsWith('reject')) {
      show()
    } else {
      hide()
    }
  }, [location])

  return (
    <ButtonLink to={ROUTES.assessmentCalls.reject(call.id)} variant="negative">Reject Call</ButtonLink>
  )
}


const Content: FC<{ call: AssessmentCallUIDetailsResponse, onRefresh: () => void }> = ({ call, onRefresh }) => (
  <ContentLayoutFlow>
    <AssessmentCallDescriptionSection call={call} />
    <BookATime call={call} onRefresh={onRefresh} />
  </ContentLayoutFlow>
)

const BookATime: FC<{ call: AssessmentCallUIDetailsResponse, onRefresh: () => void }> = ({ call, onRefresh }) => {
  const [selectedSlot, setSelectedSlot] = useState<Date>()

  const { status: bookStatus, execute: book } = useScheduleAssessmentCall()

  const bookAndRefresh = useCallback(
    async () => {
      if(selectedSlot) {
        await book(call.id, selectedSlot)
        hideBook()
        onRefresh()
      }
    },
    [call, selectedSlot]
  )

  const [showBook, hideBook] = useModal(() => (
    <Modal onDismiss={hideBook}>
      {selectedSlot && (
        <BookCallModal call={call} slot={selectedSlot} onCancel={hideBook} onBook={bookAndRefresh} isLoading={isPending(bookStatus)} />
      )}
    </Modal>
  ), [bookStatus, selectedSlot, call])

  const askToBook = (date: Date) => {
    setSelectedSlot(date)
    showBook()
  }

  const dateSelections = useMemo(() =>
    call.clientSelections
      ?.sort((a, b) => a.getTime() - b.getTime())
      ?.filter(isFuture),
    [call]
  )

  return (
    <section tw="flex flex-col gap-y-4">
      <div tw="text-xs sm:text-sm">
        <h3 tw="font-medium text-gray-700">
          Book a Time Slot
        </h3>
        <p tw="text-gray-500">
          The client has committed to being available at the following times.
        </p>
      </div>

      {dateSelections && dateSelections.length > 0
        ? (
          <Card>
            <CardContent>
              <div tw="space-y-6">
                {dateSelections.map((d, i) => (
                  <div key={i} tw="space-y-2">
                    <p tw="text-gray-900 font-semibold">
                      {format(d, 'do MMM (EEEE)')}: {format(d, 'h:mmaaa')} - {format(addHours(d, 2), 'h:mmaaa')}
                    </p>

                    <FifteenMinSlotList date={d} onSelect={askToBook} />
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        )
        : (
          <div>
            <Message colour='red' title='No available slots for this call'>
              This typically means the client selected call times which have now passed.
              Please reject the call and we will attempt to reschedule it.
            </Message>
          </div>
        )}
    </section>
  )
}


const Slot = styled.button<{ selected?: boolean, selectable?: boolean }>(({ selected, selectable = true }) => [
  tw`rounded-md bg-white border-gray-300 text-gray-500 relative flex items-center justify-center px-2 sm:px-4 py-2 border text-xs sm:text-sm font-medium`,
  selectable && tw`hover:(bg-gray-50 border-gray-300 text-gray-500)`,
  selected && tw`bg-indigo-50 border-indigo-500 text-indigo-600`,
  selectable && selected && tw`hover:(bg-indigo-50 border-indigo-300 text-indigo-500)`,
  !selectable && tw`pointer-events-none`
])


const FifteenMinSlotList: FC<{ date: Date, onSelect: (slot: Date) => void }> = ({ date, onSelect }) => {
  const startTime = useMemo(() => startOfHour(date), [date])
  const endTime = useMemo(() => startOfHour(addHours(date, 2)), [date])

  const slots = useMemo(() =>
    eachMinuteOfInterval({ start: startTime, end: endTime }, { step: 15 }).slice(0, -1),
    [startTime, endTime]
  )

  return (
    <div tw="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-2">
      {slots.map((h, i) => (
        // TODO This logic is horrible
        <Slot key={i} onClick={() => onSelect(h)} type="button">
          {format(h, 'h:mm')}-{format(addMinutes(h, 15), 'h:mmaaa')}
        </Slot>
      ))}
    </div>
  )
}
