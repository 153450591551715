import {
  isPending,
  isRefresh,
  isRepleteLeft,
  isRepleteRight
} from "@nll/datum/DatumEither"
import { toError } from "fp-ts/es6/Either"
import * as TE from "fp-ts/es6/TaskEither"
import { fold } from "fp-ts/lib/Either"
import { pipe } from "fp-ts/lib/function"
import { useEffect } from "react"
import { constantDelay, limitRetries, Monoid } from "retry-ts"
import { retrying } from "retry-ts/Task"
import "twin.macro"
import { useFromTaskEither } from "../../../lib/useAsync"
import { getQuote } from "../api/getQuote"

const policy = Monoid.concat(constantDelay(2000), limitRetries(10))

type WaitForQuotePaidProps = {
  onSuccess?: () => void
  onFailure?: () => void
}

export const useWaitForQuotePaid = ({
  onSuccess,
  onFailure
}: WaitForQuotePaidProps) => {
  const quote = (quoteId: string) =>
    TE.tryCatch(() => getQuote(quoteId), toError)

  const pollGetPayment = (quoteId: string) =>
    retrying(
      policy,
      () =>
        pipe(
          quote(quoteId),
          TE.map((quote) => quote.status === "paid")
        ),
      fold(
        // If this errors, stop
        () => false,
        // Repeat until is paid
        (isPaid) => !isPaid
      )
    )

  const { status, execute } = useFromTaskEither((quoteId: string) =>
    pollGetPayment(quoteId)
  )

  const poll = async (quoteId: string) => {
    if (!quoteId) {
      return
    }

    await execute(quoteId)
  }


  useEffect(() => {
    if (isRepleteLeft(status)) {
      onFailure && onFailure()
    }

    // Polling has completed
    if (isRepleteRight(status)) {
      const isPaid = status.value.right
      if (isPaid) {
        onSuccess && onSuccess()
      } else {
        onFailure && onFailure()
      }
    }
  }, [status])

  const isPolling = isPending(status) || isRefresh(status)

  return { status, poll, isPolling }
}
