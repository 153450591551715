import { matchExhaustive } from "@practical-fp/union-types"
import { pipe } from "fp-ts/es6/function"
import * as O from "fp-ts/es6/Option"
import * as TE from "fp-ts/es6/TaskEither"
import { castTo, fetchParsedJSON } from "../lib/fetch"
import { UUID } from "../lib/Primitives"
import { useApiFetch } from "../lib/useApiClient"

export type Affiliate = {
  id: UUID
  links: { url: string }[]
  conversions: number
  leads: number
  visitors: number
  commission_stats: {
    currencies: {
      [K in AffiliateCommissionCurrencies]: {
        due: AffiliateCurrencyDetail<K>
        gross_revenue: AffiliateCurrencyDetail<K>
        net_revenue: AffiliateCurrencyDetail<K>
        paid: AffiliateCurrencyDetail<K>
        total: AffiliateCurrencyDetail<K>
        unpaid: AffiliateCurrencyDetail<K>
      }
    }
  }
}

type AffiliateCommissionCurrencies = "GBP"
type AffiliateCurrencyDetail<K extends AffiliateCommissionCurrencies> = {
  cents: number
  currency_iso: K
}

export const useGetSolicitorAffiliate = () => {
  const api = useApiFetch(fetchParsedJSON(castTo<Affiliate>()))

  return () => pipe(
    api(`solicitors/affiliate`),
    TE.map(O.some),
    TE.orElseW(error => matchExhaustive(error, {
      NetworkError: () => TE.left(error),
      ParseError: () => TE.left(error),
      ResponseError: (e) => e.statusCode === 404
        ? TE.right(O.none as O.Option<Affiliate>)
        : TE.left(error)
    })),
  )
}
