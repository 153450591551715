import { chain, refreshFold } from "@nll/datum/DatumEither"
import { pipe } from "fp-ts/es6/function"
import { map } from "fp-ts/lib/Array"
import { QuoteBuilderSubshell } from "packages/app/src/components/assessment-call/complete/QuoteBuilderSubshell"
import { QuoteCard, sumQuoteItems } from "packages/app/src/components/assessment-call/complete/QuoteCard"
import { useDraftQuote } from "packages/app/src/components/assessment-call/hooks/useDraftQuote"
import {
  Card,
  CardContent,
  CardHeader,
  ErrorMessageOld,
  FormButtonGroup,
  FormFieldNew,
  WarningMessage
} from "packages/app/src/elements"
import { Button, LinkButtonA } from "packages/app/src/elements/Button"
import { Combobox, ComboboxItem } from "packages/app/src/elements/Combobox"
import { formatCurrencyUnits } from "packages/app/src/elements/Currency"
import { LoadingSection } from "packages/app/src/elements/Loading"
import { SectionHeader } from "packages/app/src/elements/SectionHeader"
import { ROUTES } from "packages/app/src/layout/Navigation"
import { useRouter } from "packages/app/src/lib/useRouter"
import { renderLoadingOrSuccess } from "packages/app/src/lib/utils"
import React, { FC, useEffect, useMemo } from "react"
import { Controller, useForm } from "react-hook-form"
import { IntlShape, useIntl } from "react-intl"
import "twin.macro"
import * as O from "fp-ts/lib/Option"
import { isSome } from "fp-ts/lib/Option"
import { CaseDetails } from "../../../../../contexts/case/useGetCase"
import { useCase } from "../../../../../contexts/case/CaseContext"
import {
  FeeGuideLegalArea,
  FeeGuideProduct,
  FeeGuideProductVariant,
  FeeGuideQuery,
  useFeeGuide
} from "../../../../../hooks/useFeeGuide"
import { ContentLayoutFlow } from "packages/app/src/layout/subshells/DetailsSubshell"
import { SkeletonCaseDetails } from "../../CaseDetails"
import { ApiError } from "../../../../../lib/useAsync"

export const FollowOnQuoteScene: FC = () => {
  const { caseDetails } = useCase()

  const render = refreshFold(
    () => <SkeletonCaseDetails />,
    () => <SkeletonCaseDetails />,
    (e: ApiError) =>
      ApiError.match(e, {
        NotFound: () => <ErrorMessageOld title="Error getting case details" />,
        default: () => <ErrorMessageOld title="Error getting case details" />
      }),
    (cas: CaseDetails) => (
      <QuoteBuilderSubshell
        backToText="Back to case"
        backToUrl={ROUTES.case.details(cas.id)}
        clientName={(isSome(cas.client) && isSome(cas.client.value.displayName)) ? cas.client.value.displayName.value : "Client"}
        summaryHeader="Quote Summary"
        matterSummary={`Follow on quote for case ${cas.friendlyId}`}
        step={'build'}
        content={(
          <Content cas={cas} />
        )}
      />
    )
  )


  return (render(caseDetails))
}

const buildAreaComboboxItem = (area: FeeGuideLegalArea): ComboboxItem => ({
  name: area.title,
  value: area.id,
  // supportingText: `${area.products.length} items`
})

const buildProductComboboxItem =
  (intl: IntlShape) =>
  (product: FeeGuideProduct): ComboboxItem => ({
    name: product.title,
    value: product.id,
    // supportingText: `${product.variants.length} variants, from ${pipe(product.variants, cheapestVariant, v => formatCurrencyUnits(intl)(v[1]))}`
  })

const cheapestVariant = (variants: FeeGuideProductVariant[]) =>
  variants.map((v) => [v, sumQuoteItems(v.lineItems || [])] as const).sort(([_1, t1], [_2, t2]) => t1 - t2)[0]

const buildVariantComboboxItem =
  (intl: IntlShape) =>
  (variant: FeeGuideProductVariant): ComboboxItem => ({
    name: variant.title,
    value: variant.id,
    supportingText: `${formatCurrencyUnits(intl)(sumQuoteItems(variant.lineItems || []))}`,
  })

const selectEntry =
  <T,>(filter: (i: T) => boolean) =>
  (items: T[]) => {
    const filtered = items.filter(filter)
    return filtered.length > 0 ? filtered[0] : undefined
  }

const Content: FC<{ cas: CaseDetails }> = ({ cas }) => {
  const { draft, createFromTemplate } = useDraftQuote(cas.id)
  const { push } = useRouter()

  const customise = (result: FeeGuideSelectorResult) => {
    createFromTemplate(result.legalArea, result.product, result.variant)
    push(ROUTES.case.followOn(cas.id).customise)
  }

  const complete = (result: FeeGuideSelectorResult) => {
    createFromTemplate(result.legalArea, result.product, result.variant)
    push(ROUTES.case.followOn(cas.id).send)
  }

  const i: FeeGuideQuery = {}

  const { legalAreas } = useFeeGuide(i)

  return pipe(
    legalAreas,
    renderLoadingOrSuccess(
      () => <LoadingSection description="Getting the latest fee guide" />,
      (d) => (
        <FeeGuideSelector
          caseId={cas.id}
          data={d}
          initialValue={{
            legalArea: draft.feeGuide?.legalArea?.id,
            product: draft.feeGuide?.product?.id,
            variant: draft.feeGuide?.variant?.id,
          }}
          hasStarted={draft.hasStarted}
          onCustomise={customise}
          onComplete={complete}
        />
      )
    )
  )
}

const appendOtherItem = (items: ComboboxItem[]) => [
  ...items,
  {
    name: 'Other',
    value: 'other',
  },
]

type FeeGuideSelectorState = {
  legalArea: string
  product: string
  variant: string
}

type FeeGuideSelectorResult = {
  legalArea?: FeeGuideLegalArea
  product?: FeeGuideProduct
  variant?: FeeGuideProductVariant
}

type FeeGuideSelectorProps = {
  caseId: string
  data: FeeGuideLegalArea[]
  hasStarted: boolean
  initialValue?: Partial<FeeGuideSelectorState>
  onComplete: (result: FeeGuideSelectorResult) => void
  onCustomise: (result: FeeGuideSelectorResult) => void
}

const FeeGuideSelector: FC<FeeGuideSelectorProps> = ({
  caseId,
  initialValue,
  hasStarted,
  data,
  onComplete,
  onCustomise,
}) => {
  const intl = useIntl()

  const {
    register,
    errors,
    control,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    reset,
    formState: { isDirty },
  } = useForm<FeeGuideSelectorState>({
    defaultValues: initialValue,
  })

  const formData: Partial<FeeGuideSelectorState> = watch()

  const areaComboboxItems = useMemo(() => pipe(data, map(buildAreaComboboxItem), appendOtherItem), [data])

  const legalArea = useMemo(
    () =>
      pipe(
        data,
        selectEntry((a) => (formData.legalArea && a.id === formData.legalArea) || false)
      ),
    [data, formData.legalArea]
  )

  const i: FeeGuideQuery = {
    legalArea: formData.legalArea,
    areaProduct: formData.product,
    productVariant: formData.variant,
  }

  const { products, variants, feeGuide } = useFeeGuide(i)

  const productsList: FeeGuideProduct[] = useMemo(
    () =>
      refreshFold(
        () => [],
        () => [],
        () => [],
        (a: FeeGuideProduct[]) => a
      )(products),
    [legalArea, products]
  )

  const productComboboxItems = useMemo(
    () => pipe(productsList, map(buildProductComboboxItem(intl)), appendOtherItem),
    [productsList, intl]
  )

  const product = useMemo(
    () =>
      pipe(
        productsList,
        selectEntry((a) => (formData.product && a.id === formData.product) || false)
      ),
    [productComboboxItems, formData.product]
  )

  const variantsList: FeeGuideProductVariant[] = useMemo(
    () =>
      refreshFold(
        () => [],
        () => [],
        () => [],
        (a: FeeGuideProductVariant[]) => a
      )(variants),
    [product, formData.variant, variants]
  )

  const variantComboboxItems = useMemo(
    () => pipe(variantsList, map(buildVariantComboboxItem(intl)), appendOtherItem),
    [variantsList, intl]
  )

  const variant = useMemo(
    () =>
      pipe(
        variantsList,
        selectEntry((a) => (formData.variant && a.id === formData.variant) || false)
      ),
    [variants, formData.variant]
  )

  const selectedFeeGuideItem: O.Option<FeeGuideProductVariant> = useMemo(
    () =>
      refreshFold(
        () => O.none,
        () => O.none,
        () => O.none,
        (a: O.Option<FeeGuideProductVariant>) => a
      )(feeGuide),
    [product, formData.variant, feeGuide]
  )

  const isCustomQuote = useMemo(
    () => formData.legalArea === 'other' || formData.product === 'other' || formData.variant === 'other',
    [formData]
  )

  useEffect(() => {
    if (formData.legalArea && formData.legalArea !== initialValue?.legalArea) {
      setValue('product', undefined)
      setValue('variant', undefined)
      clearErrors(['product', 'variant'])
    }
  }, [formData.legalArea])

  useEffect(() => {
    if (formData.product && formData.product !== initialValue?.product) {
      setValue('variant', undefined)
      clearErrors(['variant'])
    }
  }, [formData.product])

  const combined = pipe(
    products,
    chain(() => variants)
  )

  const customise = (state: FeeGuideSelectorState) => {
    onCustomise({
      legalArea,
      product,
      variant,
    })
  }

  const complete = (state: FeeGuideSelectorState) => {
    onComplete({
      legalArea,
      product,
      variant,
    })
  }

  return (
    <form onSubmit={handleSubmit(complete)}>
      <ContentLayoutFlow>
        <div tw="space-y-4">
          <SectionHeader
            title="Legal Area"
            supportingText="Find the category of work this matter falls under."
            hideRule
          />

          <FormFieldNew
            id="legalArea"
            error={errors.legalArea as any}
            control={
              <Controller
                name="legalArea"
                control={control}
                rules={{ required: 'Please select an answer.' }}
                render={({ onChange, value }) => (
                  <Combobox items={areaComboboxItems} value={value} setValue={onChange} />
                )}
              />
            }
          />
        </div>

        <div tw="space-y-4">
          <SectionHeader
            title="Product"
            supportingText={`Find the product that this matter relates to from our fee guide. If there is no suitable product, please select "Other".`}
            hideRule
          />

          <FormFieldNew
            id="product"
            error={errors.product as any}
            control={
              <Controller
                name="product"
                control={control}
                rules={{
                  required: formData.legalArea && formData.legalArea !== 'other' ? 'Please select an answer.' : false,
                }}
                defaultValue={initialValue?.product}
                render={({ onChange, value }) => (
                  <Combobox
                    disabled={productComboboxItems.filter((i) => i.value !== 'other').length === 0}
                    items={productComboboxItems}
                    value={value}
                    setValue={onChange}
                  />
                )}
              />
            }
          />

          <FormFieldNew
            id="variant"
            error={errors.variant as any}
            control={
              <Controller
                name="variant"
                control={control}
                rules={{
                  required: formData.product && formData.product !== 'other' ? 'Please select an answer.' : false,
                }}
                defaultValue={initialValue?.variant}
                render={({ onChange, value }) => (
                  <Combobox
                    disabled={variantComboboxItems.filter((i) => i.value !== 'other').length === 0}
                    items={variantComboboxItems}
                    value={value}
                    setValue={onChange}
                  />
                )}
              />
            }
          />
        </div>

        {variant && product && O.isSome(selectedFeeGuideItem) && (
          <QuoteCard
            productName={product.title}
            variantName={selectedFeeGuideItem.value.shortName}
            lineItems={selectedFeeGuideItem.value.lineItems}
            includes={selectedFeeGuideItem.value.includes}
            excludes={selectedFeeGuideItem.value.excludes}
            emptyStateButtonText={`Customise Quote`}
            emptyStateDescription={`Get started by customising this quote.`}
            emptyStateOnClick={handleSubmit(customise)}
          />
        )}

        {isCustomQuote && <OtherCard />}

        {hasStarted && <ExistingQuoteWarning caseId={caseId} />}

        <FormButtonGroup>
          <Button variant={isCustomQuote ? 'primary' : 'secondary'} type="button" onClick={handleSubmit(customise)}>
            Customise Quote
          </Button>
          {!isCustomQuote && <Button type="submit">Confirm Quote</Button>}
        </FormButtonGroup>
      </ContentLayoutFlow>
    </form>
  )
}

const ExistingQuoteWarning: FC<{ caseId: string }> = ({ caseId }) => (
  <WarningMessage title="You've already started a quote">
    <div tw="flex flex-col gap-2 items-start">
      <p>If you change the product, your customisations will be overwritten.</p>
      <p>
        <LinkButtonA tw="text-yellow-700" to={ROUTES.case.followOn(caseId).customise}>
          Click here to return to your custom quote
        </LinkButtonA>
      </p>
    </div>
  </WarningMessage>
)

const OtherCard = () => (
  <Card>
    <CardHeader highlight title="Build a Custom Quote" />
    <CardContent tw="text-sm space-y-2">
      <p>On the next screen, you'll be able to create a custom quote to provide to this client.</p>
      <p>You can set the line items and the scope of work for the matter.</p>
    </CardContent>
  </Card>
)
