import React, { ButtonHTMLAttributes, ChangeEvent, FC, forwardRef, InputHTMLAttributes, ReactNode, SelectHTMLAttributes } from 'react'
import TextareaAutosize from 'react-autosize-textarea/lib'
import { FieldError } from 'react-hook-form'
import tw, { styled } from 'twin.macro'

export const Form = tw.form`px-4 py-4 sm:px-6 sm:py-6`
export const FormSection = tw.div`mb-4 sm:mb-6 last:mb-0`
export const Label = styled.label<{ compact?: boolean }>(({ compact }) => [
  tw`block font-medium text-gray-700 whitespace-nowrap`,
  compact ? tw`text-xs` : tw`text-sm leading-5`
])

export const TextInputStyles = tw`block! w-full! border! rounded-md! border-gray-300! shadow-sm! focus:(ring! outline-none! ring-indigo-500! ring-opacity-50! border-indigo-300!) sm:text-sm!`

export const TextInput = styled.input<{ compact?: boolean }>(({ compact }) => [
  TextInputStyles,
  compact ? tw`text-sm leading-5 py-1` : tw``
])
export const TextArea = tw.textarea`block w-full border border-gray-300 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:text-sm sm:leading-5 px-4`

export const ExpandingTextArea = tw(TextareaAutosize)`w-full border border-gray-300 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:text-sm sm:leading-5 px-4`

export const FormSelect = tw.select`block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-200 focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5`
export const FormError = tw.p`text-xs text-red-500 font-medium sm:text-sm leading-6`

// export const useRegisterFormConversion = () => {
//   const { CONVERSION_CAMPAIGN_FORM } = useEnvironment()

//   const registerConversion = () => {
//     if (window && window.gtag) {
//       window.gtag(`event`, `conversion`, {
//         'send_to': CONVERSION_CAMPAIGN_FORM,
//       })
//     }
//   }

//   return registerConversion
// }

export const FormButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({ children, ...props }) => (
  <div tw="rounded-md shadow-md">
    <button
      tw="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo-200 transition duration-150 ease-in-out disabled:text-gray-50 disabled:cursor-not-allowed disabled:bg-indigo-300"
      {...props}
    >
      {children}
    </button>
  </div>
)


type FormFieldProps = {
  id: string,
  label?: string,
  control: JSX.Element,
  error?: FieldError,
  compact?: boolean,
  hint?: ReactNode
}

export const FormField: FC<FormFieldProps> = ({ id, label, control, error, ...rest }) => (
  <FormSection {...rest}>
    {label && (
      <Label htmlFor={id}>
        {label}
      </Label>
    )}

    <div tw="mt-2">
      {control}
    </div>

    {error && (
      <FormError>{error.message}</FormError>
    )}
  </FormSection>
)

export const FormFieldNew: FC<FormFieldProps> = ({ id, label, control, error, compact, hint, ...rest }) => (
  <div
    css={[
      compact ? tw`space-y-1` : tw`space-y-2`
    ]}
    {...rest}
  >
    {label && (
      <Label htmlFor={id} compact={compact}>
        {label}
      </Label>
    )}

    <div tw="space-y-1">
      <div>
        {control}
      </div>

      {hint && (
        <div tw="text-xs sm:text-sm text-gray-500">
          {hint}
        </div>
      )}
    </div>

    {error && (
      <FormError>{error.message}</FormError>
    )}
  </div>
)


type CheckBoxGroupState = Set<string>

type CheckBoxGroupProps = {
  name: string
  config: {
    value: string
    children: ReactNode
  }[]
  value: CheckBoxGroupState
  onChange: (state: CheckBoxGroupState) => void
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void
}

export const CheckBoxGroup: FC<CheckBoxGroupProps> = ({ name, value, config, onChange, onBlur }) => {
  const getNewState = (key: string, checked: boolean) => {
    const set = new Set(value)
    if (checked) {
      set.add(key)
    } else {
      set.delete(key)
    }
    return set
  }

  return (
    <>
      {config.map((r, i) => (
        <CheckItem
          key={i}
          name={name}
          id={`${name}-${r.value}`}
          value={r.value}
          checked={value.has(r.value) || false}
          onChange={e => onChange(getNewState(r.value, e.target.checked))}
          onBlur={onBlur}
        >
          {r.children}
        </CheckItem>
      ))}
    </>
  )
}

// forwardRef<
// HTMLSelectElement,
// { options: StandardEnum<unknown> } & SelectHTMLAttributes<HTMLSelectElement>
// >(({ options, ...rest }, ref) => (
//   <select ref={ref} {...rest}>
//     {Object.keys(options).map((o, i) => (
//       <option key={`opt-${i}`}>{o}</option>
//     ))}
//   </select>
// ))

export const CheckItem = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement>
>(({ children, ...rest }, ref) =>
  <div tw="mb-2 last:mb-0">
    <label tw="inline-flex items-center">
      <input
        type="checkbox"
        tw="border border-blue-200"
        ref={ref}
        {...rest}
      />
      <span tw="ml-2">
        {children}
      </span>
    </label>
  </div>
)

export type StandardEnum<T> = {
  [id: string]: T | string;
  [nu: number]: string;
}

export const EnumDropdown = forwardRef<
  HTMLSelectElement,
  { options: StandardEnum<unknown> } & SelectHTMLAttributes<HTMLSelectElement>
>(({ options, ...rest }, ref) => (
  <select ref={ref} {...rest}>
    {Object.keys(options).map((o, i) => (
      <option key={`opt-${i}`}>{o}</option>
    ))}
  </select>
))

export const Select = styled.select<{ compact?: boolean }>(({ compact }) => [
  tw`mt-1 block w-full px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-200 focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5`,
  compact ? tw`py-1` : tw`py-2`
])

export const SelectDiv = tw.div`mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-200 focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5`

export const FormButtonGroup = tw.div`flex flex-row gap-4 justify-end`
