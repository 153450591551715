import "twin.macro"
import { FC, PropsWithChildren } from "react"
import { Card, CardContent, CardFooter, CardHeader } from "../../../elements"
import { Step, Stepper } from "../../../elements/Stepper"
import { useEnrollToMonthlyBilling } from "../../../hooks/useEnrollToMonthlyBIlling"
import { ROUTES, withOptions } from "../../../layout/Navigation"
import { useFromTaskEither } from "../../../lib/useAsync"
import { Button } from "../../../elements/Button"
import { isPending } from "@nll/datum/Datum"

export const ConfirmSignupModal: FC = () => {

  const steps: Step[] = [
    {
      name: "Set up Direct Debit",
      description: "Set up a Direct Debit for the payment of your Lawhive fees",
      status: "current"
    },
    {
      name: "Get paid",
      description: "Every time a client pays you, a charge will be added to your upcoming charges list for the Lawhive fee",
      status: "upcoming"
    },
    {
      name: "Get an invoice",
      description: "At the end of the month, we will invoice you for Lawhive fees collected over the month",
      status: "upcoming"
    },
    {
      name: "Pay the invoice",
      description: "We will collect the balance of the invoice via Direct Debit",
      status: "upcoming"
    }
  ]

  return (
    <div tw="max-w-xl">
      <Card>
        <CardHeader title="What's next?"/>
        <CardContent>
          <Stepper steps={steps} />
        </CardContent>
        <CardFooter actions={
          <>
            <div/>
            <SubscribeToMonthlyBillingButton>
              Confirm and set up Direct Debit
            </SubscribeToMonthlyBillingButton>
          </>
        }>

        </CardFooter>
      </Card>
    </div>
  )
}

type SubscribeToMonthlyBillingButtonProps = {
  cancelUrl?: string
  successUrl?: string
}

export const SubscribeToMonthlyBillingButton = (props: PropsWithChildren<SubscribeToMonthlyBillingButtonProps>) => {
  const enrollToMonthlyBilling = useEnrollToMonthlyBilling({
    cancelUrl: withOptions( props.cancelUrl || ROUTES.solicitors.plan.root, { cancelled: 'true' }),
    successUrl: withOptions(props.successUrl || ROUTES.solicitors.plan.root, { success: 'true' })
  })

  const { execute, status } = useFromTaskEither(enrollToMonthlyBilling)

  return (
    <Button onClick={execute} isLoading={isPending(status)} {...props} />
  )
}
