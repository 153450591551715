import {
  KYCSubmissionStatus,
  ListKycSubmissionsByStatusQuery,
  ListKycSubmissionsByStatusQueryVariables
} from '@lawhive/generated-api'
import { compareAsc, formatDistanceToNow, parseISO } from 'date-fns'
import React, { FC, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import 'twin.macro'
import { SidebarLayout, SidebarMenu, SidebarMenuItem } from '../../../elements/Sidebar'
import { ContentContainer, PageContainer } from '../../../layout/Layout'
import { ROUTES } from '../../../layout/Navigation'
import { useAsync } from '../../../lib/useAsync'
import { useQueryStringState } from '../../../lib/useQueryStringState'
import { graphql, isNotNullOrUndefined, renderLoadingRefreshOrSuccess } from '../../../lib/utils'
import { CaseListRenderer, CaseFieldRenderer } from '../../case/list/CaseListRenderer'
import { SkeletonCaseList } from '../../case/list/SkeletonCaseList'

type KYCListItem = {
  id: string
  status: KYCSubmissionStatus
  name: string
  updatedAt: Date
}

export const AdminKycListScene = () => {
  const [status] = useQueryStringState<KYCSubmissionStatus>('status', KYCSubmissionStatus.submitted)
  
  return (
    <PageContainer>
      <ContentContainer>
        <SidebarLayout
          menu={<StatusSelectMenu status={status || KYCSubmissionStatus.submitted} queryKey={'status'} />}
          content={<AdminCaseSection status={status || KYCSubmissionStatus.submitted} />}
        />
      </ContentContainer>
    </PageContainer>
  )
}

const StatusSelectMenu: FC<{ status: KYCSubmissionStatus, queryKey: string }> = ({ status, queryKey }) => {
  const items = useMemo(() => Object.entries({
    [KYCSubmissionStatus.submitted]: 'Needs Approval',
    [KYCSubmissionStatus.approved]: 'Approved',
    [KYCSubmissionStatus.rejected]: 'Rejected',
  }).map(([k, v]): SidebarMenuItem => ({
    to: `?${queryKey}=${k}`,
    active: k === status,
    label: v
  })), [status])

  return <SidebarMenu tw="pb-4" items={items} />
}

const AdminCaseSection: FC<{ status: KYCSubmissionStatus }> = ({ status }) => {
  const { status: cases, execute } = useAsync(
    (status: KYCSubmissionStatus) => graphql<ListKycSubmissionsByStatusQuery, ListKycSubmissionsByStatusQueryVariables>({
      query: /* GraphQL */ `
        query ListCases {
          listKycSubmissionsByStatus(status: ${status}) {
            items {
              userId
              status
              updatedAt
              user {
                displayName
              }
            }
          }
        }
      `
    })
    .then(r => r.data?.listKycSubmissionsByStatus)
  )

  const render = renderLoadingRefreshOrSuccess(
    () => <SkeletonCaseList columns={['Client Name', 'Status', 'Last Update', '']} />,
    (r: ListKycSubmissionsByStatusQuery['listKycSubmissionsByStatus']) => {

      const data: KYCListItem[] = (r?.items || [])
        .filter(isNotNullOrUndefined)
        .map(c => ({
          id: c.userId!,
          name: c.user.displayName!,
          updatedAt: parseISO(c.updatedAt),
          status: c.status!
        }))
        .sort((a, b) => (compareAsc(a.updatedAt, b.updatedAt)))

      return <KycSubmissionsList data={data} />
    }
  )

  useEffect(() => { execute(status) }, [status])

  return render(cases)
}

const KycSubmissionsList: FC<{ data: KYCListItem[] }> = ({ data }) => {
  const options = useMemo((): CaseFieldRenderer<KYCListItem>[] => [
    {
      label: 'Client Name',
      accessor: 'name',
      render: value => <span tw="leading-5 font-medium text-gray-900">{value}</span>
    },
    {
      label: 'Status',
      accessor: 'status',
      render: value => <>{value}</>
    },
    {
      label: 'Last Update',
      accessor: 'updatedAt',
      render: value => <>{formatDistanceToNow(value)}</>
    },
    {
      label: '',
      id: 'details',
      render: () => (
        <p tw="text-indigo-600 hover:text-indigo-900 text-right text-sm leading-5 font-medium">Details</p>
      ),
      renderCard: u => (
        <Link to={ROUTES.admin.kyc.details(u.id)}>Details</Link>
      )
    }

  ], [])

  return (
    <CaseListRenderer
      options={options}
      data={data}
      selectable={{ key: 'id', urlGenerator: s => ROUTES.admin.kyc.details(s) }}
    />
  )
}
