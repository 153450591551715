import { castTo, fetchParsedJSON } from "../../../lib/fetch"
import { useApiFetch } from "../../../lib/useApiClient"
import { UUID } from "../../../lib/Primitives"
import { pipe } from "fp-ts/lib/function"

export type GetTelephoneCallResponse = {
  id: UUID,
  status: "in-progress" | "failed" | "completed"
}

export const useGetTelephoneCall = () => {
  const api = pipe(
    castTo<GetTelephoneCallResponse>(),
    fetchParsedJSON,
    useApiFetch
  )

  return (callId: string) => api(`telephony/${callId}`)

}
