import { useApiFetch } from "../lib/useApiClient"
import * as D from "io-ts/lib/Decoder"
import { fetchParsedJSON } from "../lib/fetch"
import { DateFromString } from "../lib/Primitives"
import { decodeOrError, withOptional } from "../lib/io"
import { ADT } from "../lib/adt"

export const TransactionFee = D.struct({
  type: D.literal("lawhive", "stripe", "tax"),
  amount: D.number
})

export type TransactionFee = D.TypeOf<typeof TransactionFee>

const CasePaymentTransaction = D.struct({
  caseNumber: D.string,
  caseId: D.string,
  clientName: D.string
})

export const PayoutTransactionType = ADT({
  CasePayment: CasePaymentTransaction,
  Other: undefined
})

export type PayoutTransactionType = D.TypeOf<typeof PayoutTransactionType>

export type PayoutTransaction = D.TypeOf<typeof PayoutTransaction>

export const PayoutTransaction = D.struct({
  status: D.literal('available', 'pending'),
  paidAt: DateFromString,
  amount: D.number,
  net: D.number,
  fee: D.number,
  fees: D.array(TransactionFee),
  type: PayoutTransactionType
})

const PayoutStatus = D.literal("paid", "pending", "in_transit", "canceled", "failed")

export const AutomaticPayoutDetails = withOptional(D.struct({
  id: D.string,
  status: PayoutStatus,
  createdAt: DateFromString,
  arrivalDate: DateFromString,
  amount: D.number,
  transactions: D.array(PayoutTransaction),
  automatic: D.literal(true)
}), {
  statementDescriptor: D.string,
  reference: D.string,
})
export type AutomaticPayoutDetails = D.TypeOf<typeof AutomaticPayoutDetails>

export const ManualPayoutDetails = withOptional(D.struct({
  id: D.string,
  status: PayoutStatus,
  createdAt: DateFromString,
  arrivalDate: DateFromString,
  amount: D.number,
  automatic: D.literal(false)
}), {
  statementDescriptor: D.string,
  reference: D.string,
})

export const PayoutDetails = D.union(AutomaticPayoutDetails, ManualPayoutDetails)

export type PayoutDetails = D.TypeOf<typeof PayoutDetails>

export const useGetPayoutDetails = () => {
  const api = useApiFetch(fetchParsedJSON(decodeOrError(PayoutDetails)))

  return (payoutId: string) => api(`solicitor-billing/payouts/${payoutId}`)
}
