import { CancelCaseCommandMutation, CancelCaseCommandMutationVariables, CompleteCaseCommandMutation, CompleteCaseCommandMutationVariables, setCaseDescriptionCommand, SetCaseDescriptionCommandMutation, SetCaseDescriptionCommandMutationVariables, UnassignSolicitorCommandMutation, UnassignSolicitorCommandMutationVariables } from "@lawhive/generated-api"
import { isPending, isSuccess } from "@nll/datum/DatumEither"
import { FC } from "react"
import 'twin.macro'

import { useCase } from "../../../../contexts/case/CaseContext"
import { CaseDetails } from "../../../../contexts/case/useGetCase"
import { CaseTitleBreadcrumb } from "../../../../elements/case/CaseTitleBreadcrumb"
import { Button } from "../../../../elements/Button"
import { ScenePanel, ScrollingChildrenContainer } from "../../../../layout/Layout"
import { ROUTES } from "../../../../layout/Navigation"
import { useAsync } from "../../../../lib/useAsync"
import { useRouter } from "../../../../lib/useRouter"
import { graphql } from "../../../../lib/utils"

export const AdminUnassignSolicitorScene = () => {
  const { caseDetails } = useCase()

  return isSuccess(caseDetails) ? (
    <>
      <CaseTitleBreadcrumb caseNumber={caseDetails.value.right.friendlyId} action={'Mark Complete'} />
      <ScrollingChildrenContainer>
        <ScenePanel>
          <UnassignForm cas={caseDetails.value.right} />
        </ScenePanel>
      </ScrollingChildrenContainer>
    </>
  ) : null
}

const UnassignForm: FC<{ cas: CaseDetails }> = ({ cas }) => {
  const { refresh } = useCase()
  const { replace } = useRouter()

  const { status, execute } = useAsync(
    () => graphql<UnassignSolicitorCommandMutation, UnassignSolicitorCommandMutationVariables>({
      query: /* GraphQL */ `
         mutation UnassignSolicitorCommand($input: UnassignSolicitorCommand!) {
          unassignSolicitorCommand(input: $input) {
            id
          }
        }
      `,
      variables: {
        input: {
          caseId: cas.id
        }
      }
    })
    .then(() => refresh())
    .then(() => replace(ROUTES.case.details(cas.id)))
  )


  return (
    <Button isLoading={isPending(status)} onClick={execute}>
      Unassign Solicitor
    </Button>
  )
}
