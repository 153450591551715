import React from 'react'
import 'twin.macro'
import { AdminOnly, ClientOnly, SolicitorOnly } from '../../../layout/Selectors'
import { AdminCasesScene } from './admin/AdminList'
import { ClientCasesScene } from './client/ClientList'
import { SolicitorCasesScene } from './solicitor/SolicitorList'

export const CaseListScene = () => {
  // const { userId } = useUser()

  return (
    <>
      <AdminOnly>
        <AdminCasesScene />
      </AdminOnly>
      <ClientOnly>
        <ClientCasesScene />
      </ClientOnly>
      <SolicitorOnly>
        <SolicitorCasesScene />
      </SolicitorOnly>
    </>
  )
}
