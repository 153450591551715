import { pipe } from "fp-ts/es6/function"
import { isSome, Option } from "fp-ts/es6/Option"
import { default as React, FC, useEffect, useMemo } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import "twin.macro"
import {
  ClientSubmitKycForApprovalCommandMutation,
  CreateKycSubmissionMutation,
  CreateKycSubmissionMutationVariables,
  KycRejectionReason,
  KYCSubmissionStatus,
  UpdateKycSubmissionMutation,
  UpdateKycSubmissionMutationVariables
} from "@lawhive/generated-api"

// import { CreateKycSubmissionMutation, CreateKycSubmissionMutationVariables, UpdateKycSubmissionMutation, UpdateKycSubmissionMutationVariables } from '../../API'
import { KycSubmission, useUser } from "../../contexts/User"
// import { createKycSubmission, updateKycSubmission } from '../../graphql/mutations'
import { ROUTES } from "../../layout/Navigation"
import { useAsync } from "../../lib/useAsync"
import { useRouter } from "../../lib/useRouter"
import { graphql } from "../../lib/utils"
import { DocumentUploadStep } from "./DocumentUploadStep"
import { IdentityUploadStep } from "./IdentityUploadStep"
import { PendingStep } from "./PendingStep"
import { VerifiedStep } from "./VerifiedStep"
import { AddressForm } from "./AddressForm"
import { useKYCSubmissionEvent } from "../../features/analytics/useKYCSubmissionEvent"
import { Card, CardContent } from "../../elements"

type KycUpdateParams = Partial<{
  documentUploads: string[]
  identityUploads: string[]
  address: string
}>

type Rejection = Omit<KycRejectionReason, "__typename">
export const KycStatusScene = () => {
  const { kycSubmission, refresh } = useUser()

  useEffect(() => {
    refresh()
  }, [])

  return (
    <>
      <div tw="max-w-screen-md mx-auto w-full px-4 sm:px-6 lg:px-8">
        <KycStage maybeSubmission={kycSubmission} />
      </div>
    </>
  )
}

const RejectionCard = ({
  maybeSubmission
}: {
  maybeSubmission: Option<KycSubmission>
}) => {
  if (!isSome(maybeSubmission)) return null
  if (!isSome(maybeSubmission.value.status)) return null
  if (maybeSubmission.value.status.value !== KYCSubmissionStatus.rejected)
    return null
  if (!isSome(maybeSubmission.value.rejectionReason)) return null

  const rejection = maybeSubmission.value.rejectionReason.value
  return (
    <Card tw="my-1 md:my-2 px-1 md:px-2">
      <CardContent tw="text-sm sm:text-base">
        <h2 tw="text-base text-base md:text-lg font-semibold mb-2 text-center text-red-500">
          KYC Rejected
        </h2>
        <p tw="hidden md:block text-sm md:text-base">
          It looks like your previous KYC attempt was unsucessful, please try
          again.
        </p>
        <br tw="hidden md:block" />
        {rejection.reason && (
          <>
            <h3 tw="font-semibold text-sm md:text-base">Reason</h3>
            <p tw="text-gray-900 text-sm md:text-base">{rejection.reason}</p>
            <br />
          </>
        )}
        {rejection.instructions && (
          <>
            <h3 tw="font-semibold text-sm md:text-base">Instructions</h3>
            <p tw="text-gray-900 text-sm md:text-base">
              {rejection.instructions}
            </p>
          </>
        )}
      </CardContent>
    </Card>
  )
}

const StageSelector: FC<{ maybeSubmission: Option<KycSubmission> }> = ({
  maybeSubmission
}) => {
  const {
    params: { edit }
  } = useRouter<{}, { edit: string }>()

  if (!isSome(maybeSubmission)) {
    return <Redirect to={ROUTES.submitKyc.step1} />
  }

  const submission = maybeSubmission.value

  const isVerified =
    isSome(submission.status) &&
    submission.status.value === KYCSubmissionStatus.approved
  const isSubmitted =
    isSome(submission.status) &&
    submission.status.value === KYCSubmissionStatus.submitted

  const hasDocuments =
    isSome(submission.documentUploads) &&
    submission.documentUploads.value.length > 0
  const hasIdentityPicture =
    isSome(submission.identityUploads) &&
    submission.identityUploads.value.length > 0
  const hasAddress = isSome(submission.address)

  if (isVerified) {
    return <Redirect to={ROUTES.submitKyc.verified} />
  }

  if (isSubmitted) {
    return <Redirect to={ROUTES.submitKyc.pending} />
  }

  return edit ? null : hasDocuments ? (
    hasIdentityPicture ? (
      hasAddress ? (
        isSubmitted ? (
          <Redirect to={ROUTES.submitKyc.pending} />
        ) : (
          <Redirect to={ROUTES.submitKyc.step3} />
        )
      ) : (
        <Redirect to={ROUTES.submitKyc.step3} />
      )
    ) : (
      <Redirect to={ROUTES.submitKyc.step2} />
    )
  ) : (
    <Redirect to={ROUTES.submitKyc.step1} />
  )

  // const h = useHistory()

  // const l = useLocation()

  // console.log('>>>', edit)

  // console.log('>>>', edit
  //   ? 'null'
  //   : hasDocuments
  //     ? hasIdentityPicture
  //       ? hasAddress
  //         ? '<Redirect to={ROUTES.kyc.pending} />'
  //         : '<Redirect to={ROUTES.kyc.step3} />'
  //       : '<Redirect to={ROUTES.kyc.step2} />'
  //     : '<Redirect to={ROUTES.kyc.step1} />'
  // )

  // const r = edit
  //   ? null
  //   : hasDocuments
  //     ? hasIdentityPicture
  //       ? hasAddress
  //         ? ROUTES.kyc.pending
  //         : ROUTES.kyc.step3
  //       : ROUTES.kyc.step2
  //     : ROUTES.kyc.step1

  // if(r) {
  //   if(l.pathname !== r) {
  //     h.replace(r)
  //   }
  // }

  // return null
}

const KycStage: FC<{ maybeSubmission: Option<KycSubmission> }> = ({
  maybeSubmission
}) => {
  const stage = useMemo(() => {
    if (!isSome(maybeSubmission)) {
      return ROUTES.submitKyc.step1
    }

    const submission = maybeSubmission.value

    const isVerified =
      isSome(submission.status) &&
      submission.status.value === KYCSubmissionStatus.approved
    const isSubmitted =
      isSome(submission.status) &&
      submission.status.value === KYCSubmissionStatus.submitted

    const hasDocuments =
      isSome(submission.documentUploads) &&
      submission.documentUploads.value.length > 0
    const hasIdentityPicture =
      isSome(submission.identityUploads) &&
      submission.identityUploads.value.length > 0
    const hasAddress = isSome(submission.address)

    if (isVerified) {
      return ROUTES.submitKyc.verified
    }

    if (isSubmitted) {
      return ROUTES.submitKyc.pending
    }

    return hasDocuments
      ? hasIdentityPicture
        ? hasAddress
          ? isSubmitted
            ? ROUTES.submitKyc.pending
            : ROUTES.submitKyc.step3
          : ROUTES.submitKyc.step3
        : ROUTES.submitKyc.step2
      : ROUTES.submitKyc.step1
  }, [maybeSubmission])

  return (
    <>
      <Switch>
        <StageSelector maybeSubmission={maybeSubmission} />
      </Switch>
      <RejectionCard maybeSubmission={maybeSubmission} />
      <Switch>
        <Route path={ROUTES.submitKyc.step1} component={DocumentUploadStep} />
        <Route path={ROUTES.submitKyc.step2} component={IdentityUploadStep} />
        <Route path={ROUTES.submitKyc.step3} component={AddressForm} />
        <Route path={ROUTES.submitKyc.pending} component={PendingStep} />
        <Route path={ROUTES.submitKyc.verified} component={VerifiedStep} />
        <Redirect to={stage} from={ROUTES.submitKyc.root()} />
      </Switch>
    </>
  )
}
export const useUpdateOrCreateKycSubmission = () => {
  const { kycSubmission, refresh, userId } = useUser()

  const { status: createStatus, execute: create } = useAsync(
    (userId: string, params: KycUpdateParams) =>
      graphql<
        CreateKycSubmissionMutation,
        CreateKycSubmissionMutationVariables
      >({
        query: /* GraphQL */ `
          mutation CreateKycSubmission(
            $input: CreateKYCSubmissionInput!
            $condition: ModelKYCSubmissionConditionInput
          ) {
            createKYCSubmission(input: $input, condition: $condition) {
              userId
            }
          }
        `,
        variables: {
          input: {
            userId,
            address: null,
            documentUploads: null,
            identityUploads: null,
            ...params
          }
        }
      }).then(refresh)
  )

  const { status: updateStatus, execute: update } = useAsync(
    (userId: string, params: KycUpdateParams) =>
      graphql<
        UpdateKycSubmissionMutation,
        UpdateKycSubmissionMutationVariables
      >({
        query: /* GraphQL */ `
          mutation UpdateKycSubmission(
            $input: UpdateKYCSubmissionInput!
            $condition: ModelKYCSubmissionConditionInput
          ) {
            updateKYCSubmission(input: $input, condition: $condition) {
              userId
            }
          }
        `,
        variables: {
          input: {
            userId,
            ...params
          }
        }
      }).then(refresh)
  )

  const { status: completionStatus, execute: completeSubmission } = useAsync(
    () =>
      graphql<ClientSubmitKycForApprovalCommandMutation>({
        query: /* GraphQL */ `
          mutation ClientSubmitKycForApprovalCommand {
            clientSubmitKycForApprovalCommand {
              userId
            }
          }
        `
      }).then(refresh)
  )

  const triggerSubmittedEvent = useKYCSubmissionEvent()
  const completeAndTrackSubmission = () => {
    triggerSubmittedEvent({})
    return completeSubmission()
  }

  const resolve = (maybeSubmission: Option<KycSubmission>) => ({
    execute: (params: KycUpdateParams) => {
      return isSome(maybeSubmission)
        ? update(userId, params)
        : create(userId, params)
    },

    status: isSome(maybeSubmission) ? updateStatus : createStatus,
    complete: completeAndTrackSubmission,
    completionStatus
  })

  return resolve(kycSubmission)

  // return refreshFold(
  //   () => ({ execute: noopPromise, status: initial }),
  //   () => ({ execute: noopPromise, status: initial }),
  //   () => ({ execute: noopPromise, status: initial }),
  //   (submission: Option<KycSubmission>) => null //resolve(submission),
  // )(kycSubmission)
}
