import React, { FC } from 'react'
import 'twin.macro'

import { Card, CardContent, CardTitle, InfoNote, WarnNote } from '../../../../../elements'
import { ModalText } from '../../../../../elements/Modal'
import { Button, ButtonGroup } from '../../../../../elements/Button'
import { ROUTES } from '../../../../../layout/Navigation'

export const AcceptCaseModal: FC<{
  showPaymentWarning: boolean
  showTimeSensitiveWarning: boolean
  onCancel: () => void
  onConfirm: () => void
  isLoading: boolean
}> = ({ onCancel, onConfirm, showPaymentWarning, showTimeSensitiveWarning, isLoading }) => {
  return (
    <div tw="max-w-xl">
      <Card>
        <CardContent>
          <CardTitle>Are you sure?</CardTitle>

          <ModalText tw="mt-4">
            By accepting this instruction you agree to be formally engaged by the client according to the{' '}
            <a
              tw="font-medium underline text-indigo-600"
              target="_blank"
              rel="noopener noreferrer"
              href={ROUTES.legal.clientSolicitorEngagementTerms}
            >
              Client Solicitor Terms of Engagement
            </a>
            .
          </ModalText>

          {showPaymentWarning && (
            <InfoNote tw="mt-6">
              If you accept, we will collect payment from the client. Please <strong>do not</strong> start work on this
              case until you receive an email notifying you that payment has been received.
            </InfoNote>
          )}

          {showTimeSensitiveWarning && (
            <WarnNote tw="mt-6">
              This is a time-sensitive case. Please <strong>do not</strong> accept this case if you cannot meet the
              timelines outlined in the case description
            </WarnNote>
          )}
        </CardContent>

        <CardContent>
          <ButtonGroup>
            <Button variant="positive" onClick={onConfirm} isLoading={isLoading}>
              Accept Instruction
            </Button>

            <Button variant="negative" display="border" onClick={onCancel}>
              Cancel
            </Button>
          </ButtonGroup>
        </CardContent>
      </Card>
    </div>
  )
}
