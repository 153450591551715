import { useAnalytics } from "../../hooks/useAnalytics";
import { CaseViewedEvent, CaseViewedEventProps } from "./types";

export const useCaseViewed = () => {
    const { trackEvent } = useAnalytics();

    return (properties: CaseViewedEventProps) => {
        trackEvent(buildCaseViewedEvent(properties));
    }
}

const buildCaseViewedEvent = (properties: CaseViewedEventProps): CaseViewedEvent => ({
    action: "viewed",
    target: "case",
    description: "Case viewed",
    properties,
});
