import { isSuccess } from "@nll/datum/DatumEither"
import { matchExhaustive } from "@practical-fp/union-types"
import { pipe } from "fp-ts/function"
import { flow } from "fp-ts/lib/function"
import * as TE from "fp-ts/lib/TaskEither"
import { Card, CardContent, CardHeader, ErrorMessage, SuccessMessage } from "packages/app/src/elements"
import { Button, ButtonLink } from "packages/app/src/elements/Button"
import { PageHeader } from "packages/app/src/elements/PageHeader"
import { useGetChargeAccount } from "packages/app/src/hooks/useGetChargeAccount"
import { useRequestBillingPortal } from "packages/app/src/hooks/useRequestBillingPortal"
import { ROUTES } from "packages/app/src/layout/Navigation"
import { ContentLayoutFlow, DetailsSubshell } from "packages/app/src/layout/subshells/DetailsSubshell"
import { useFromTaskEither } from "packages/app/src/lib/useAsync"
import { FC, PropsWithChildren, useEffect, useState } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import "twin.macro"
import { useModal } from "../../../../contexts/Modal"
import { useUser } from "../../../../contexts/User"
import { Modal } from "../../../../elements/Modal"
import { useIsMonthlyBillingEnabled } from "../../../../hooks/useFeatureFlags"
import { SidebarMenu, SidebarMenuItem, SidebarMenuLayout } from "../../../../layout/SidebarMenu"
import { useRouter } from "../../../../lib/useRouter"
import { isNotNullOrUndefined } from "../../../../lib/utils"
import { ChargesScene } from "../../../billing/charges/ChargesScene"

export const PlanDetailsScene = () => {
  const { params: { success, cancelled } } = useRouter<{ success: string, cancelled: string }>()

  const [showSuccess, setShowSuccess] = useState(() => isNotNullOrUndefined(success))
  const [showCancelled, setShowCancelled] = useState(() => isNotNullOrUndefined(cancelled))


  return (
    <DetailsSubshell
      header={(
        <>
          {(showSuccess || showCancelled) && (
            <>
              {showSuccess && (
                <SuccessMessage title="Successfully subscribed to Monthly Charges" dismissable={true} onDismiss={() => setShowSuccess(false)}>
                  Each time you accept a client payment, we'll add your Lawhive fee to be paid at the end of the billing period.
                </SuccessMessage>
              )}

              {showCancelled && (
                <ErrorMessage title="You didn't complete the sign up process" dismissable={true} onDismiss={() => setShowCancelled(false)}>
                  If you want to sign up to pay-monthly, please start the sign up process again.
                </ErrorMessage>
              )}
            </>
          )}
        <PageHeader
          title="My Plan"
          actions={
            <ChangePlanButton />
          }
        />
        </>
      )}
      content={(
        <ContentLayoutFlow>
          <SidebarMenuLayout
            menu={<BillingTable />}
            content={<SolicitorChargesRouter />}
          />
        </ContentLayoutFlow>
      )}
    />
  )
}

export const ChangePlanButton: FC = () => {
  const getChargeAccount = useGetChargeAccount()

  const { execute, status } = useFromTaskEither(flow(
    getChargeAccount,
    TE.chainW(TE.fromOption(() => new Error())),
  ))

  useEffect(() => {execute()}, [])

  const [show, hide] = useModal(() => (
    <Modal onDismiss={hide}>
      <RevertToPayByCaseModal />
    </Modal>
  ))

  return (
    isSuccess(status) &&
    status.value.right.model.tag === 'Monthly'
      ? <Button onClick={show}>Change my plan</Button> :
      <ButtonLink to={ROUTES.solicitors.plan.choose}>
        Change my plan
      </ButtonLink>
  )
}

const RevertToPayByCaseModal: FC = () => {

  return (
    <div tw="max-w-xl">
      <Card>
        <CardHeader title="Change back to pay by case" />
        <CardContent>
          You are currently on Lawhive's pay-monthly plan. If you would like to switch back to pay-by-case, please contact our Solicitor Success team.
        </CardContent>
      </Card>
    </div>
  )
}

const BillingTable: FC = () => {

  const isMonthlyBillingEnabled = useIsMonthlyBillingEnabled()

  const enrollToMonthlyBilling = useRequestBillingPortal()
  const getChargeAccount = useGetChargeAccount()

  const { execute, status } = useFromTaskEither(() => pipe(
    TE.Do,
    TE.bindW("chargeAccount", getChargeAccount),
    a => a,
    TE.bindW("chargeAccount2", ({ chargeAccount }) => TE.fromOption(() => new Error())(chargeAccount)),
    TE.bindW("enrol", ({ chargeAccount2 }) => enrollToMonthlyBilling({
      chargeAccountId: chargeAccount2.id,
      returnUrl: ROUTES.solicitors.plan.charges
    })),
    TE.bindW("url",({ enrol }) => TE.of(enrol.url))
  ))

  useEffect(() => {execute()}, [])

  const items: SidebarMenuItem[] = (isMonthlyBillingEnabled
    && isSuccess(status)
    && status.value.right.chargeAccount2.model.tag === "Monthly"
      ? [
        {
          label: "Plan Details",
          to: ROUTES.solicitors.plan.details
        },
        {
          label: "Upcoming Charges",
          to: ROUTES.solicitors.plan.charges
        },
        {
          label: "Invoices",
          to: isSuccess(status) ? status.value.right.url : "",
          external: true,
        }
      ] : [
        {
          label: "Plan Details",
          to: ROUTES.solicitors.plan.details
        }
      ])

  return (
    <SidebarMenu items={items} />
  )

}

const CurrentPlanDetailsCard: FC = () => {
  const getChargeAccount = useGetChargeAccount()

  const { execute, status } = useFromTaskEither(flow(
    getChargeAccount,
    TE.chainW(TE.fromOption(() => new Error())),
  ))

  useEffect(() => {execute()}, [])

  type FeeCardProps = {
    title: string,
    stats: {
      title: string
      stat: string
      subtext?: string
    }[]
  }

  const FeeCard = ({ title, stats, children }: PropsWithChildren<FeeCardProps>) => (
    <Card>
      <CardContent tw="space-y-5">
        <div tw="flex flex-col gap-8 sm:flex-row sm:justify-between">
          <div tw="space-y-2">
            <div tw="flex flex-row gap-2 items-center">
              <h3 tw="text-lg leading-6 font-medium text-gray-900">{title}</h3>
            </div>
            <div tw="space-y-2 text-sm text-gray-500">
              {children}
            </div>
          </div>
          <div tw="grid grid-cols-2 gap-2">
            {stats.map((s, i) => (
              <>
                <div key={`title-${i}`} tw="text-gray-500 font-medium sm:text-right">{s.title}</div>
                <div key={`stat-${i}`} tw="flex flex-row items-start gap-1">
                  <span tw="text-gray-900 font-semibold text-xl">{s.stat}</span>
                  {s.subtext && (
                    <span tw="font-medium text-gray-500 text-sm">{s.subtext}</span>
                  )}
                </div>
              </>
            ))}
          </div>
        </div>
      </CardContent>
    </Card>
  )

  return (
    isSuccess(status) ?
    matchExhaustive(status.value.right.model, {
      Monthly: () => <FeeCard
        title="Pay monthly"
        stats={[
          { title: "Monthly Fee", stat: "£0", subtext: "per month" },
          { title: "Lawhive Fee", stat: "22% + VAT" }
        ]}
      >
        You're currently paying your Lawhive fees on our pay monthly plan
      </FeeCard>,
      PayByCase: () =>
        <FeeCard title="Pay by case" stats={[
          { title: "Monthly Fee", stat: "£0", subtext: "per month" },
          { title: "Lawhive Fee", stat: "20%" },
          { title: "Payment Processing Fee", stat: "1.4-2.9% + 20p" },
        ]}>
          You're currently paying your Lawhive fees on the pay-by-case plan.
          <br/><br/>
          ⚠️ From October 1st Lawhive will switch to monthly billing. Accounts on pay-by-case will be charged an additional 5% admin fee. Click 'Change
          my plan' above to set up monthly billing.
        </FeeCard>
    }) :
      <FeeCard title="Pay by case" stats={[
        { title: "Monthly Fee", stat: "£0", subtext: "per month" },
        { title: "Lawhive Fee", stat: "20%" },
        { title: "Payment Processing Fee", stat: "1.4-2.9% + 20p" },
      ]}>
        You're currently paying your Lawhive fees on the pay-by-case plan.
        <br/><br/>
        ⚠️ From October 1st Lawhive will switch to monthly billing. Accounts on pay-by-case will be charged an additional 5% admin fee. Click 'Change
        my plan' above to set up monthly billing.
      </FeeCard>
  )
}

const SolicitorChargesRouter = () => (
  <Switch>
    <Route path={ROUTES.solicitors.plan.details} component={CurrentPlanDetailsCard} />
    <Route path={ROUTES.solicitors.plan.charges} component={ChargesScene} />
    <Redirect to={ROUTES.solicitors.plan.details} />
  </Switch>
)
