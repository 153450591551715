import 'twin.macro'
import { Link } from 'react-router-dom'
import { Card, CardContent } from '../../../elements'
import { ROUTES } from '../../../layout/Navigation'

export const HelpCard = () => (
  <Card>
    <CardContent>
      <div tw="flex flex-row items-center">
        <h1 tw="text-lg leading-8 font-medium text-gray-900 flex-1">
          Welcome to Lawhive 👋
        </h1>
      </div>
    </CardContent>

    <CardContent tw="border-t border-gray-200">
      <p>
        New instructions will show up under your{' '}
        <Link
          to={ROUTES.case.root}
          tw="text-indigo-600 hover:text-indigo-500 transition font-medium"
        >
          Cases
        </Link>{' '}
        tab and assessment calls will show up under your{' '}
        <Link
          to={ROUTES.assessmentCalls.root()}
          tw="text-indigo-600 hover:text-indigo-500 transition font-medium"
        >
          Assessment Calls
        </Link>{' '}
        tab.
      </p>
    </CardContent>
    <CardContent tw="border-t border-gray-200">
      <p tw="mt-2">
        For more information on how Lawhive works, including assessment calls,
        fee quotes, and payments, please refer to our guides:
      </p>
      <p tw="mt-2">
        <a
          href="https://help.lawhive.co.uk"
          target="_blank"
          rel="noopener noreferrer"
          tw="text-indigo-600 hover:text-indigo-500 transition font-medium"
        >
          How to use Lawhive
        </a>
      </p>
    </CardContent>
    <CardContent tw="border-t border-gray-200">
      <p>
        If you have any questions, get in touch with the Solicitor Success team
        who can answer any of your questions and make sure you get the most out
        of Lawhive.
      </p>
    </CardContent>
    <CardContent tw="border-t border-gray-200">
      <p>
        When engaging Lawhive clients, you agree to being engaged according to
        Lawhive's standard{' '}
        <a
          href="https://lawhive.co.uk/legal/client-solicitor-engagement-terms/"
          target="_blank"
          rel="noopener noreferrer"
          tw="text-indigo-600 hover:text-indigo-500 transition font-medium"
        >
          Client Solicitor Terms of Engagement
        </a>
        . This will be sent to and agreed by your clients through the Lawhive
        platform as your Client Care Letter. If you want to use a different CCL
        please{' '}
        <a
          href="mailto:support@lawhive.co.uk"
          target="_blank"
          rel="noopener noreferrer"
          tw="text-indigo-600 hover:text-indigo-500 transition font-medium"
        >
          contact us
        </a>
        .
      </p>
    </CardContent>
  </Card>
)
