import { CaseStatus, ListCasesByStatusQuery } from "@lawhive/generated-api"
import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import "twin.macro"

import { FormattedCurrencyUnits } from "../../../../elements/Currency"
import { PositivePill } from "../../../../elements/Badge"
import { SpokeBrandSmall } from "../../../../elements/SpokeBrand"
import { ROUTES } from "../../../../layout/Navigation"
import { useAsync } from "../../../../lib/useAsync"
import {
  graphql,
  isNotNullOrUndefined,
  renderLoadingRefreshOrSuccess
} from "../../../../lib/utils"
import { CaseListRenderer } from "../CaseListRenderer"
import { SkeletonCaseList } from "../SkeletonCaseList"

type WaitingForClientPaymentListItem = {
  id: string
  workValue?: number
  caseNumber: string
  hasClientPaid: boolean
  brand?: string | null
  shortDescription: string
}

export const WaitingForClientPaymentCaseList = () => {
  const { status: cases, execute } = useAsync((status: CaseStatus) =>
    graphql<ListCasesByStatusQuery>({
      query: /* GraphQL */ `query ListCases {
        listCasesByStatus(status: ${status}, limit: 1000) {
          items {
            id
            caseNumber
            brand

            createdAt

            details {
              details
            }

            client {
              displayName
            }

            quotes {
              items {
                lineItems {
                  items {
                    solicitorReceives
                    clientPays
                  }
                }
              }
            }

            payments {
              items {
                id
              }
            }
          }
        }
      }`
    }).then((r) => r.data?.listCasesByStatus)
  )

  const render = renderLoadingRefreshOrSuccess(
    () => <SkeletonCaseList columns={["Total Fees", "", "Description", ""]} />,
    (r: ListCasesByStatusQuery["listCasesByStatus"]) => {
      const data: WaitingForClientPaymentListItem[] = (r?.items || [])
        .filter(isNotNullOrUndefined)
        .map((c) => ({
          id: c.id,
          brand: c.brand,
          hasClientPaid: (c.payments?.items || []).length > 0,
          caseNumber: c.caseNumber,
          shortDescription:
            c.details?.details || "None set",
          createdAt: new Date(c.createdAt)
              // ? `${c.details?.details.substring(0, 30)}...`
              // : "None set"
          // workValue: (c.quotes?.items || []).length > 0
          //   ? (c.quotes?.items![0]?.lineItems?.items || []).reduce((prev, cur) => prev + (cur?.clientPays ?? 0), 0)
          //   : undefined,
        }))
        .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())

      return (
        <CaseListRenderer<WaitingForClientPaymentListItem>
          data={data}
          selectable={{
            key: "id",
            urlGenerator: (s) => ROUTES.case.details(s)
          }}
          options={[
            // {
            //   label: 'Total Fees',
            //   accessor: 'workValue',
            //   render: value => value
            //     ? <FormattedCurrencyUnits amountUnits={value} />
            //     : <>Speak to case manager</>
            // },
            // {
            //   label: '',
            //   accessor: 'hasClientPaid',
            //   render: value => value
            //     ? <PositivePill>Client Paid</PositivePill>
            //     : null
            // },
            {
              label: 'Case #',
              accessor: 'caseNumber',
            },
            {
              label: "Description",
              accessor: "shortDescription",
              render: (value) => (
                <span tw="block truncate overflow-ellipsis overflow-hidden max-w-sm sm:max-w-xl">
                  {value}
                </span>
              )
            },
            {
              label: "",
              id: "action",
              render: () => (
                <p tw="text-indigo-600 hover:text-indigo-900 text-right text-sm leading-5 font-medium">
                  View Details
                </p>
              ),
              renderCard: (d) => (
                <Link
                  to={ROUTES.case.details(d.id)}
                  tw="inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-green-500 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-400 focus:outline-none focus:border-green-700 focus:ring-green-200 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  View Instruction
                </Link>
              )
            }
          ]}
        />
      )
    }
  )

  useEffect(() => {
    execute(CaseStatus.needsClientPayment)
  }, [])

  return render(cases)
}
