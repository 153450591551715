import React from "react"
import { Route, Switch } from "react-router-dom"

import { ROUTES } from "../../layout/Navigation"
import { SolicitorDashboard } from "./SolicitorDashboard"
import { ChangeModelScene } from "./select-charge-model/ChangeModelScene"
import { PlanDetailsScene } from "./select-charge-model/scenes/PlanDetailsScene"
import { FeeGuideViewScene } from "./FeeGuideViewScene"

export const SolicitorSwitch = () => (
  <Switch>
    <Route path={ROUTES.solicitors.plan.choose} component={ChangeModelScene} />
    <Route path={ROUTES.solicitors.plan.root} component={PlanDetailsScene} />
    <Route path={ROUTES.solicitors.feeGuide.root} component={FeeGuideViewScene} />
    <Route path={ROUTES.solicitors.root} component={SolicitorDashboard} />
  </Switch>
)
