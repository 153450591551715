import React, { FC } from "react"
import { Link } from "react-router-dom"
import { useCase } from "../../contexts/case/CaseContext"

import { PageTitleBar } from "../../layout/Layout"
import { ROUTES } from "../../layout/Navigation"
import { BreadcrumbContainer, BreadcrumbItem, BreadcrumbChevron } from "../Breadcrumb"

export const CaseTitleBreadcrumb: FC<{ caseNumber: string, action?: string }> = ({ caseNumber, action }) => {
  const { caseId } = useCase()

  return (
    <PageTitleBar>
      <BreadcrumbContainer>
        <BreadcrumbItem>
          <Link to={ROUTES.case.root}>Cases</Link>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbChevron />
        </BreadcrumbItem>

        <BreadcrumbItem active={!action} >
          {action
            ? <Link to={ROUTES.case.details(caseId)}>Case #{caseNumber}</Link>
            : <>Case #{caseNumber}</>
          }
        </BreadcrumbItem>
        {action && (
          <>
            <BreadcrumbItem>
              <BreadcrumbChevron />
            </BreadcrumbItem>

            <BreadcrumbItem active={!!action}>
              {action}
            </BreadcrumbItem>
          </>
        )}
      </BreadcrumbContainer>
    </PageTitleBar>
  )
}
