import { isPending, isRefresh } from "@nll/datum/DatumEither"
import React, { FC } from "react"
import 'twin.macro'

import { Spinner } from "../Loading"
import { useOpenPaymentDashboard } from "./useOpenPaymentDashboard"

type OpenPaymentDashboardLinkProps = {
  label: string
}

export const OpenPaymentDashboardLink: FC<OpenPaymentDashboardLinkProps> = ({ label }) => {
  const { status: dashboardStatus, execute } = useOpenPaymentDashboard()

  return (
    <button
      tw="inline-flex flex-row items-center justify-center underline"
      onClick={execute}
      disabled={isPending(dashboardStatus)}
    >
      {label}
      {(isPending(dashboardStatus) || isRefresh(dashboardStatus)) &&
        <Spinner tw="h-3" />
      }
    </button>
  )
}
