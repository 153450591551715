import * as React from 'react'
import * as ReactDOM from 'react-dom'
import Amplify from "@aws-amplify/core"

import { AWSConfig } from "@lawhive/generated-api"

import CSSReset from './CSSReset'
import './index.css'

import { App } from './App'

Amplify.configure(AWSConfig)

ReactDOM.render(
  <React.StrictMode>
    <CSSReset />
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// @ts-ignore
if (module.hot) {
  // @ts-ignore
  module.hot.accept()
}
