import { ArrowLeftIcon } from "@heroicons/react/outline"
import { FC, ReactNode } from "react"
import tw from 'twin.macro'
import { LinkButton, LinkButtonA } from "./Button"

type PageHeaderProps = {
  title?: ReactNode
  supportingText?: ReactNode

  back?: ReactNode
  actions?: ReactNode
}

export const PageHeader: FC<PageHeaderProps> = ({ back, title, supportingText, actions }) => (
  <div tw="flex flex-col gap-y-5 items-start">
    {back}
    <div tw="w-full flex flex-col gap-x-0 gap-y-4 sm:(flex-row items-center gap-y-0 gap-x-8)">
      <div tw="flex-1 space-y-2">
        <h1 tw="font-semibold text-lg sm:text-2xl text-gray-900">
          {title}
        </h1>

        {supportingText && (
          <p tw="text-xs sm:text-sm text-gray-500">
            {supportingText}
          </p>
        )}
      </div>
      <div tw="max-w-screen-md">
        {actions}
      </div>
    </div>
  </div>
)

export const PageHeaderBackLink: FC<{ to: string }> = ({ to, children }) => (
  <LinkButtonA
    icon={<ArrowLeftIcon />}
    to={to}
  >
    {children}
  </LinkButtonA>
)

export const PageHeaderButtonGroup = tw.div`flex flex-row gap-x-2`
