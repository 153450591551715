import { parseISO } from "date-fns"
import "twin.macro"

import { Badge } from "packages/app/src/elements/Badge"
import { matchStringUnion } from "packages/app/src/lib/utils"
import { QuoteEntityV2, PaymentStatus, Quote, QuotePayment } from "../types"
import { PaymentItem, PaymentsTable } from "./PaymentsTable"
import { match } from "ts-pattern"

type SolicitorPaymentsTableProps = {
  quote: QuoteEntityV2
}

const parsePayment = (payment: QuotePayment): PaymentItem => ({
  amount: payment.payment.amount,
  paidOn: parseISO(payment.createdAt),
  title: match(payment.type.type)
    .with("ClientAuthorisation", () => "Payment Hold")
    .with("SolicitorPayment", () => "Payment")
    .with("OffPlatformPayment", () => "Manual Payment")
    .exhaustive(),
  description: <StatusPill status={payment.payment.status} />
})

export const SolicitorPaymentsTable = ({
  quote
}: SolicitorPaymentsTableProps) => {
  const payments: PaymentItem[] = quote.quotePayments.map(parsePayment)

  console.log(payments)

  return <PaymentsTable payments={payments} />
}

const StatusPill = ({ status }: { status: PaymentStatus }) =>
  matchStringUnion(status, {
    paid: () => <Badge variant="positive">Paid</Badge>,
    "not-captured": () => <Badge variant="neutral">Awaiting Capture</Badge>,
    refunded: () => <Badge variant="yellow">Refunded</Badge>,
    failed: () => <Badge variant="red">Failed</Badge>,
    cancelled: () => <Badge variant="yellow">Cancelled</Badge>,
    "capture-requested": () => <Badge variant="negative">Instructed</Badge>,
  })
