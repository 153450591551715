import { Link } from 'react-router-dom'
import 'twin.macro'

import { Card, CardContent } from '../..'
import { useCase } from '../../../contexts/case/CaseContext'
import { ROUTES } from '../../../layout/Navigation'
import { noop } from '../../../lib/utils'
import { SkeletonBlock } from '../../Skeleton'
import { SendMessageBox } from './SendMessageBox'

export const SkeletonMessagesCard = () => {
  const { caseId } = useCase()

  return (
    <Card>
      <div tw="flex-1 justify-between flex flex-col">
        <CardContent tw="py-3 bg-gray-100 text-center text-gray-600 font-bold text-xs sm:text-sm relative">
          <div tw="absolute right-0 sm:mt-1 mr-4">
            <Link to={ROUTES.case.messages(caseId)} tw="block h-4 w-4">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path
                  stroke="#374151"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 8V4m0 0h4M3 4l4 4m8 0V4m0 0h-4m4 0l-4 4m-8 4v4m0 0h4m-4 0l4-4m8 4l-4-4m4 4v-4m0 4h-4"
                />
              </svg>
            </Link>
          </div>
        </CardContent>
        <CardContent tw="max-h-72 overflow-auto">
          <SkeletonBlock />
          <SkeletonBlock tw="mt-4" />
          <SkeletonBlock tw="mt-4" />
        </CardContent>
        <div tw="border-t border-gray-200 px-3 py-3 sm:px-4 sm:py-4">
          <SendMessageBox addMessage={noop} />
        </div>
      </div>
    </Card>
  )
}

export default SkeletonMessagesCard
