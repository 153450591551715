import tw, { styled } from 'twin.macro'
import { format, isToday } from 'date-fns'

export type Message = {
  authorName: string
  fromMe: boolean
  content: string
  timestamp: Date
}

// TODO Reduce space between messages in "group" (i.e. multiple by same person)
// https://tailwindcomponents.com/component/chat
export const ChatMessage = ({ message }: { message: Message }) => {
  return (
    <ChatMessageOuterWrapper fromMe={message.fromMe}>
      <ChatMessageInnerWrapper fromMe={message.fromMe}>
        {!message.fromMe && <AuthorName>{message.authorName}</AuthorName>}
        <MessageContent fromMe={message.fromMe}>{message.content.trim()}</MessageContent>
        <Timestamp title={format(message.timestamp, 'E do LLL yyyy hh:mma')}>
          {format(message.timestamp, isToday(message.timestamp) ? 'hh:mmaaa' : 'dd/LL - hh:mmaaa')}
        </Timestamp>
      </ChatMessageInnerWrapper>
    </ChatMessageOuterWrapper>
  )
}

// Styling
const ChatMessageOuterWrapper = styled.div(({ fromMe }: { fromMe: boolean }) => [
  tw`flex items-end mt-4`,
  fromMe && tw`justify-end`,
])

const ChatMessageInnerWrapper = styled.div(({ fromMe }: { fromMe: boolean }) => [
  tw`flex flex-col text-xs max-w-lg`,
  fromMe ? tw`order-1 items-end` : tw`order-2 items-start`,
])

const AuthorName = tw.span`mb-1 text-xs text-indigo-500`

const MessageContent = styled.span(({ fromMe }: { fromMe: boolean }) => [
  tw`px-4 py-2 rounded-lg inline-block whitespace-pre-line break-words`,
  fromMe ? tw`rounded-br-none bg-blue-600 text-white` : tw`rounded-bl-none bg-gray-300 text-gray-600`,
])

const Timestamp = tw.span`mt-1 text-gray-400 text-xs`
