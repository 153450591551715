import "twin.macro"

import { Spinner } from "packages/app/src/elements/Loading"
import { Card, CardContent } from "../../../elements"
import { PanelGrid } from "../../../elements/Layout"
import { useQuoteStatus } from "../hooks/useQuoteStatus"
import { QuoteLineItemsTable } from "../components/QuoteLineItemsTable"
import { QuoteScopesTable } from "../components/QuoteScopesTable"
import { ClientQuoteSummary } from "../components/ClientQuoteSummary"

export const ClientQuoteView = () => {
  const { getQuotes, firstQuote } = useQuoteStatus()

  if (getQuotes.isLoading) {
    return <Spinner />
  }

  if (firstQuote) {
    return (
      <PanelGrid>
        <QuoteLineItemsTable
          priceLabel={"Cost"}
          lineItems={firstQuote.lineItems}
        />

        {firstQuote.scopes.length > 0 && (
          <Card>
            <CardContent>
              <QuoteScopesTable scopes={firstQuote.scopes} />
            </CardContent>
          </Card>
        )}

        <ClientQuoteSummary quote={firstQuote} />
      </PanelGrid>
    )
  } else {
    return null
  }
}
