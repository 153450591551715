import {
  DatumEither,
  isFailure,
  isInitial,
  isPending,
  isSuccess
} from "@nll/datum/DatumEither"
import { isNonEmpty } from "fp-ts/lib/Array"
import { default as React, FC } from "react"
import { useDropzone } from "react-dropzone"
import "twin.macro"

import { Spinner } from "./Loading"
import { Button } from "./Button"
import { ErrorMessage, SuccessMessage } from "./Message"

type FileUploadProps = {
  state: DatumEither<Error, string>
  fileTypes?: FileTypes
  maxSize?: string
  onUpload: (filename: File) => void
}

type FileTypes = {
  fileTypes: string
  acceptedExtensions?: string[]
}

export const imageAndDocFileTypes = {
  fileTypes: "Images and Documents",
  acceptedExtensions: [
    // Images
    ".jpg",
    ".jpeg",
    ".jpe",
    ".gif",
    ".png",
    ".bmp",
    ".tiff",
    ".tif",
    ".svg",
    ".webp",
    ".heif",
    ".heic",

    // Documents
    ".pdf",
    ".doc",
    ".docx",
    ".pptx",
    ".txt",
    ".rtf",
    ".odt",
    ".ods",
    ".odp"
  ]
}

export const unrestrictedFileTypes: FileTypes = {
  fileTypes: "PNG, JPG, GIF, PD",
}

export const FileUploadInput: FC<FileUploadProps> = ({
  state,
  onUpload,
  fileTypes = imageAndDocFileTypes,
  maxSize = "200MB"
}) => {
  const onDrop = (acceptedFiles: File[]) => {
    if (!isNonEmpty(acceptedFiles)) {
      return
    }

    const file = acceptedFiles[0]
    onUpload(file)
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false
  })

  return (
    <div
      tw="p-4 sm:p-8 border-2 border-dashed border-gray-300 rounded-lg flex flex-col items-center justify-center"
      {...(getRootProps() as any)}
    >
      {isPending(state) ? (
        <Spinner />
      ) : (
        <div tw="flex flex-col items-center">
          <p tw="text-sm sm:text-base font-semibold text-gray-500 flex flex-col items-center">
            {(isInitial(state) || isFailure(state)) && (
              <>
                <Button tw="font-semibold" type="button">
                  Upload a file
                </Button>{" "}
                <div tw="invisible sm:visible sm:p-2">
                  (or drop a file here)
                </div>
              </>
            )}

            {isSuccess(state) && (
              <>
                <Button tw="font-semibold" type="button">
                  Upload another file
                </Button>{" "}
                <div tw="invisible sm:visible sm:p-2">
                  (or drop another file here)
                </div>
              </>
            )}
          </p>

          <p tw="text-xs sm:text-sm font-semibold text-gray-400">
            {fileTypes.fileTypes} up to {maxSize}
          </p>

          <input
            {...getInputProps()}
            accept={
              fileTypes.acceptedExtensions
                ? fileTypes.acceptedExtensions.join(",")
                : undefined
            }
          />
        </div>
      )}
    </div>
  )
}

type UploadFeedbackMessageProps = {
  tw?: string
}

const UploadSuccessMessage: FC<UploadFeedbackMessageProps> = (props) => (
  <SuccessMessage title="Document uploaded successfully" {...props} />
)
const UploadFailureMessage: FC<UploadFeedbackMessageProps> = (props) => (
  <ErrorMessage title="Document upload failed" {...props}>
    Please try again. If this persists please contact{" "}
    <a href="mailto:support@lawhive.co.uk" tw="underline">
      support@lawhive.co.uk
    </a>
  </ErrorMessage>
)

export const SingleFileUploadInput: FC<FileUploadProps> = ({
  state,
  onUpload,
  fileTypes = unrestrictedFileTypes,
  maxSize = "200MB"
}) => (
  <>
    {(isInitial(state) || isFailure(state) || isPending(state)) && (
      <FileUploadInput
        state={state}
        onUpload={onUpload}
        fileTypes={fileTypes}
        maxSize={maxSize}
      />
    )}
    {isSuccess(state) && <UploadSuccessMessage />}
    {isFailure(state) && <UploadFailureMessage tw="mt-6" />}
  </>
)

export const MultiFileUploadInput: FC<FileUploadProps> = ({
  state,
  onUpload,
  fileTypes = unrestrictedFileTypes,
  maxSize = "200MB"
}) => (
  <>
    <FileUploadInput
      state={state}
      onUpload={onUpload}
      fileTypes={fileTypes}
      maxSize={maxSize}
    />
    {isSuccess(state) && <UploadSuccessMessage tw="mt-6" />}
    {isFailure(state) && <UploadFailureMessage tw="mt-6" />}
  </>
)
