import { CaseStatus, ListCasesByStatusQuery } from '@lawhive/generated-api'
import { FC, useMemo } from 'react'
import { Column } from 'react-table'
import 'twin.macro'
import { Card, CardContent } from '../../../../elements'
import { ButtonLink } from '../../../../elements/Button'
import { NeutralPill, PositivePill, YellowPill } from '../../../../elements/Badge'
import { SidebarLayout, SidebarMenu, SidebarMenuItem } from '../../../../elements/Sidebar'
import { PlainDataTable } from '../../../../elements/table/PlainDataTable'
import { ContentContainer, PageContainer } from '../../../../layout/Layout'
import { ROUTES } from '../../../../layout/Navigation'
import { useQueryStringState } from '../../../../lib/useQueryStringState'
import { graphql, GraphQLResult, isNotNullOrUndefined, renderLoadingRefreshOrSuccess } from '../../../../lib/utils'
import { NoCasesCard } from '../CaseListRenderer'
import { SkeletonCaseList } from '../SkeletonCaseList'
import { NextToken, PaginationController, useAppSyncPagination } from '../../../../hooks/useAppSyncPagination'

type AdminCaseListItem = {
  id: string
  number: string
  solicitorName: string
  clientName: string
  status: 'pending' | 'quote' | 'active' | 'complete'
  createdAt: Date;
}

export const AdminCasesScene = () => {
  const [status] = useQueryStringState<CaseStatus>('status', CaseStatus.needsDetails)

  return (
    <PageContainer>
      <ContentContainer>
        <SidebarLayout
          menu={<AdminMenu status={status || CaseStatus.needsDetails} />}
          content={<AdminCaseSection status={status || CaseStatus.needsDetails} />}
        />
      </ContentContainer>
    </PageContainer>
  )
}

const AdminMenu: FC<{ status: CaseStatus }> = ({ status }) => (
  <div tw="flex flex-col">
    <div>
      <ButtonLink to={ROUTES.case.admin.create}>Add Case</ButtonLink>
    </div>
    <StatusSelectMenu status={status} queryKey={'status'} />
  </div>
)

const StatusSelectMenu: FC<{ status: CaseStatus; queryKey: string }> = ({ status, queryKey }) => {
  const items = useMemo(
    () =>
      Object.entries({
        [CaseStatus.needsDetails]: 'Needs Summary',
        [CaseStatus.needsPrice]: 'Needs Price',
        [CaseStatus.needsSolicitorAssignment]: 'Needs Solicitor Assignment',
        [CaseStatus.needsSolicitorApproval]: 'Needs Solicitor Approval',
        [CaseStatus.needsClientPayment]: 'Awaiting Payment',
        [CaseStatus.active]: 'Active',
        [CaseStatus.completed]: 'Completed',
        [CaseStatus.reviewed]: 'Reviewed',
        [CaseStatus.cancelled]: 'Cancelled',
        [CaseStatus.exported]: 'Exported',
        [CaseStatus.pendingExport]: 'Pending Export',
      }).map(
        ([k, v]): SidebarMenuItem => ({
          to: `?${queryKey}=${k}`,
          active: k === status,
          label: v,
        })
      ),
    [status]
  )

  return <SidebarMenu tw="pb-4" items={items} />
}

const AdminCaseSection: FC<{ status: CaseStatus }> = ({ status }) => {
  const listCasesByStatus = (status: CaseStatus) => (nextToken: NextToken | null, limit: number) =>
    graphql<ListCasesByStatusQuery>({
      query: /* GraphQL */ `query ListCases($nextToken: String, $limit: Int) {
    listCasesByStatus(status: ${status}, limit: $limit, nextToken: $nextToken) {
      items {
        id
        caseSolicitorId
        caseNumber
        brand
        payFirst

        client {
          displayName
        }

        solicitor {
          user {
            displayName
          }
        }

        quotes {
          items {
            lineItems {
              items {
                clientPays
              }
            }
          }
        }

        status

        payments {
          items {
            amount
          }
        }
        createdAt
      }
      nextToken
    }
  }
  `,
      variables: { nextToken, limit },
    })

  const paginationController = useAppSyncPagination({
    query: listCasesByStatus(status),
    nextTokenExtractor: (r) => {
      return r.data?.listCasesByStatus?.nextToken
    },
    opts: { filterQueryKey: status },
  })

  const render = renderLoadingRefreshOrSuccess(
    () => <SkeletonCaseList columns={['Case ID', 'Client', 'Status', '']} />,
    (r: GraphQLResult<ListCasesByStatusQuery>) => {
      const data: AdminCaseListItem[] = (r?.data?.listCasesByStatus?.items || [])
        .filter(isNotNullOrUndefined)
        .map(
          (c): AdminCaseListItem => ({
            id: c.id,
            number: c.caseNumber,
            status:
              c.status === CaseStatus.completed
                ? 'complete'
                : (c.quotes?.items || []).length > 0
                ? (c.payments?.items || []).length > 0
                  ? 'active'
                  : c.status === CaseStatus.needsClientPayment
                  ? 'quote'
                  : 'pending'
                : 'pending',
            clientName: c.client?.displayName || 'No name',
            solicitorName: c.caseSolicitorId ? c.solicitor?.user?.displayName || 'No name' : 'No solicitor assigned',
            createdAt: new Date(c.createdAt),
          })
        )
        // sort in date order desc
        .sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)
      return <ClientCaseList paginationController={paginationController} data={data} />
    }
  )

  return render(paginationController.data)
}

const ClientCaseList = <T,>({
  paginationController,
  data,
}: {
  paginationController: PaginationController<T>
  data: AdminCaseListItem[]
}) => (
  <>
    <div tw="hidden sm:block">
      <ClientCaseTable paginationController={paginationController} data={data} />
    </div>
    <div tw="block sm:hidden">{data.length > 0 ? <ClientCaseCards data={data} /> : <NoCasesCard />}</div>
  </>
)

const ClientCaseTable = <T,>({
  paginationController,
  data,
}: {
  paginationController: PaginationController<T>
  data: AdminCaseListItem[]
}) => {
  const columns = useMemo(
    (): Column<AdminCaseListItem>[] => [
      {
        Header: 'Client',
        accessor: 'clientName',
        Cell: ({ value }) => <span tw="leading-5 font-medium text-gray-900">{value}</span>,
      },
      {
        Header: 'Solicitor',
        accessor: 'solicitorName',
        Cell: ({ value }) => <span tw="leading-5 font-medium text-gray-900">{value}</span>,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) =>
          value === 'active' ? (
            <PositivePill>Case work underway</PositivePill>
          ) : value === 'quote' ? (
            <YellowPill>Quote ready</YellowPill>
          ) : value === 'complete' ? (
            <PositivePill>Complete</PositivePill>
          ) : (
            <NeutralPill>Building case</NeutralPill>
          ),
      },
      {
        Header: 'Case ID',
        accessor: 'number',
      },
      {
        Header: '',
        id: 'details',
        Cell: () => <p tw="text-indigo-600 hover:text-indigo-900 text-right text-sm leading-5 font-medium">Details</p>,
      },
    ],
    []
  )

  return (
    <PlainDataTable
      columns={columns}
      data={data}
      selectable={{ key: 'id', urlGenerator: (s) => ROUTES.case.details(s) }}
      paginationController={paginationController}
      noDataComponent={<NoCasesCard />}
    />
  )
}

const ClientCaseCards: FC<{ data: AdminCaseListItem[] }> = ({ data }) => {
  return (
    <>
      {data.map((d) => (
        <div tw="mb-4 last:mb-0">
          <Card>
            <CardContent>
              <div tw="py-2 grid grid-cols-3 gap-4">
                <dt tw="text-sm leading-5 font-medium text-gray-600">Case ID</dt>
                <dd tw="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 col-span-2">{d.number}</dd>
              </div>

              <div tw="py-2 grid grid-cols-3 gap-4">
                <dt tw="text-sm leading-5 font-medium text-gray-600">Status</dt>
                <dd tw="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 col-span-2">
                  {d.status === 'active' ? (
                    <PositivePill>Case work underway</PositivePill>
                  ) : d.status === 'quote' ? (
                    <YellowPill>Quote ready</YellowPill>
                  ) : d.status === 'complete' ? (
                    <PositivePill>Complete</PositivePill>
                  ) : (
                    <NeutralPill>Building case</NeutralPill>
                  )}
                </dd>
              </div>

              <div tw="py-2 grid grid-cols-3 gap-4">
                <dt tw="text-sm leading-5 font-medium text-gray-600">Client</dt>
                <dd tw="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 col-span-2">{d.clientName}</dd>
              </div>

              {d.solicitorName && (
                <div tw="py-2 grid grid-cols-3 gap-4">
                  <dt tw="text-sm leading-5 font-medium text-gray-600">Solicitor</dt>
                  <dd tw="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 col-span-2">{d.solicitorName}</dd>
                </div>
              )}

              <div tw="mt-4 whitespace-nowrap text-right text-sm leading-5 font-medium">
                <ButtonLink to={ROUTES.case.details(d.id)} variant="positive">
                  View Case
                </ButtonLink>
              </div>
            </CardContent>
          </Card>
        </div>
      ))}
    </>
  )
}
