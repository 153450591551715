import { clientEnsureUserIsConfiguredCommand, ClientEnsureUserIsConfiguredCommandMutation, GetUserQuery, GetUserQueryVariables } from '@lawhive/generated-api'
import { default as React, FC, useEffect } from 'react'
import { useAsync } from '../lib/useAsync'
import { graphql, renderLoadingOrSuccess } from '../lib/utils'
import { CognitoUserExt, LoggedInUser } from './Authentication'

type GetUserResponse = GetUserQuery['getUser']

const isInGroup = (user: CognitoUserExt) => (group: string) =>
  (user.signInUserSession.accessToken.payload["cognito:groups"] || []).includes(group)

const loadUser = (userId: string) => graphql<GetUserQuery, GetUserQueryVariables>({
  query: /* GraphQL */ `
    query GetUser($id: ID!) {
      getUser(id: $id) {
        id
        type
        emailAddress
      }
    }
  `,
  variables:  {
    id: userId
  }
}).then(r => r.data?.getUser)

export const UserConsistencyProvider: FC<{ user: LoggedInUser, loading: JSX.Element }> = ({ user, loading, children }) => {
  const ensureHasUserEntry = () => graphql<ClientEnsureUserIsConfiguredCommandMutation>({
    query: clientEnsureUserIsConfiguredCommand
  })

  const isCorrectEmailAddress = (dbEmail: string) => dbEmail === user.info.attributes.email

  const isCorrectType = (dbType: string) =>
    dbType === 'solicitor'
      ? isInGroup(user.user)('solicitors')
      : dbType === 'client'


  const { status: userStatus, execute, reset } = useAsync(
    (userId: string) => loadUser(userId)
    .then(async user => {
      if(user) {
        if(!isCorrectEmailAddress(user.emailAddress!) || !isCorrectType(user.type!)) {
          await ensureHasUserEntry()
          return loadUser(userId)
        } else {
          return user
        }
      } else {
        await ensureHasUserEntry()
        return loadUser(userId)
      }
    })
  )

  useEffect(() => {
    execute(user.user.attributes.sub)
    return reset
  }, [user])

  return renderLoadingOrSuccess(
    () => loading,
    (c: GetUserResponse) => <>{children}</>
  )(userStatus)
}
