import React from 'react'
import { Redirect } from 'react-router-dom'
import 'twin.macro'

import { useUser } from '../contexts/User'
import { ROUTES } from '../layout/Navigation'

export const DashboardScene = () => {
  const { isSolicitor } = useUser()

  return isSolicitor
    ? <Redirect to={ROUTES.solicitors.root} />
    : <Redirect to={ROUTES.case.root} />
}

// type ProductListingProps = {
//   title: string
//   price: string
//   benefits: (string | JSX.Element)[]
//   type: CaseType
// }
// const ProductListing: FC<ProductListingProps> = ({ title, price, benefits, type }) => (
//   <div tw="p-4 xl:w-1/3 md:w-1/3 w-full">
//     <div tw="h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden">
//       <h2 tw="text-sm tracking-widest mb-1 font-medium uppercase">{title}</h2>
//       <h1 tw="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-gray-200">
//         <span>{price}</span>
//         <span tw="text-lg ml-1 font-normal text-gray-500">/mo</span>
//       </h1>
//       <div tw="mb-6">
//       {benefits.map((b, i) => (
//         <p tw="flex items-center text-gray-600 mb-2 last:mb-0" key={`benefit-${i}`}>
//           <span tw="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-500 text-white rounded-full flex-shrink-0">
//             <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" tw="w-3 h-3" viewBox="0 0 24 24">
//               <path d="M20 6L9 17l-5-5"></path>
//             </svg>
//           </span>
//           {b}
//         </p>
//       ))}
//       </div>

//       <Link to={ROUTES.onboarding.create(type)} tw="flex items-center mt-auto text-white bg-gray-500 border-0 py-2 px-4 w-full focus:outline-none hover:bg-gray-600 rounded">
//         Continue
//         <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" tw="w-4 h-4 ml-auto" viewBox="0 0 24 24">
//           <path d="M5 12h14M12 5l7 7-7 7"></path>
//         </svg>
//       </Link>
//     </div>
//   </div>
// )

// const ProductListingDashboard = () => (
//   <section tw="text-gray-700 overflow-hidden">
//     <div tw="container px-5 py-24 mx-auto">
//       <div tw="flex flex-col text-center w-full mb-20">
//         <h1 tw="sm:text-4xl text-3xl font-medium mb-2 text-gray-900">Choose your case</h1>
//       </div>

//       <div tw="flex flex-wrap -m-4">
//         <ProductListing
//           title='Self-signed Letter Before Action'
//           price='£79'
//           benefits={[]}
//           type={'self-serve-lba'}
//         />
//         <ProductListing
//           title='Solicitor Letter Before Action'
//           price='£349'
//           benefits={[]}
//           type={'solicitor-lba'}
//         />
//         <ProductListing
//           title='Eviction Notice'
//           price='£79'
//           benefits={[]}
//           type={'eviction-notice'}
//         />
//       </div>
//     </div>
//   </section>
// )
