import { format } from "date-fns"
import { FC } from "react"
import tw from "twin.macro"

import { FormattedCurrencyUnits } from "../../../elements/Currency"
import {
  PlainDataTable,
  TableColumn
} from "../../../elements/table/PlainDataTable"
import { lineBreaksToHtml } from "../../../lib/helpers"

export type PaymentItem = {
  title: string
  description?: string | JSX.Element
  paidOn: Date
  amount: number
}

type PaymentsTableProps = {
  payments: PaymentItem[]
}

export const PaymentsTable: FC<PaymentsTableProps> = ({
  payments,
  ...rest
}) => {
  const previewColumns: TableColumn<PaymentItem>[] = [
    {
      Header: "Payment",
      accessor: "title",
      Cell: ({ value, row }) => (
        <div tw="flex flex-col gap-2">
          <p tw="leading-5 font-medium text-gray-900">
            {lineBreaksToHtml(value || "")}
          </p>
          <p>
            {row.original.description && (
              <p tw="text-xs text-gray-400">{row.original.description}</p>
            )}
          </p>
        </div>
      )
    },
    {
      Header: "Paid On",
      accessor: "paidOn",
      headerStyle: tw`text-right`,
      Cell: ({ value }) => <p tw="text-right">{format(value, "do MMM yy")}</p>
    },
    {
      Header: "Amount",
      accessor: "amount",
      headerStyle: tw`text-right`,
      Cell: ({ value }) => (
        <p tw="text-right">
          <FormattedCurrencyUnits amountUnits={value} />
        </p>
      )
    }
  ]

  return <PlainDataTable columns={previewColumns} data={payments} {...rest} />
}
