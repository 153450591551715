import { axios } from "packages/app/src/lib/axios"
import { useQuery } from "react-query"
import { Quote } from "../types"

interface GetQuoteData {
  quoteId: string
  onSuccess?: (data: Quote) => void
}

export const getQuote = (quoteId: string): Promise<Quote> => {
  return axios.get(`quotes/${quoteId}`)
}

export const useGetQuote = ({ quoteId, onSuccess }: GetQuoteData) => {
  return useQuery({
    queryKey: ["quotes", quoteId],
    enabled: Boolean(quoteId),
    queryFn: () => getQuote(quoteId),
    onSuccess: (data) => {
      onSuccess && onSuccess(data)
    }
  })
}
