import { QuoteTotalPriceMetric } from "packages/app/src/features/quotes/components/QuoteTotalPriceMetric"
import "twin.macro"
import { InfoMessage, InfoMessageOld } from "../../../../../elements"

import { ComponentWithCase } from "../../CaseDetails"
import { NeedsApprovalDetailsPanel } from "./NeedsApproval"

export const WaitingForPaymentStatusPanel: ComponentWithCase = ({
  case: cas
}) => {
  return (
    <div tw="space-y-4">
      <InfoMessage title="Your client has been instructed to pay" />

      <QuoteTotalPriceMetric label="Total Fees" />
    </div>
  )
}

export const WaitingForClientPaymentDetailsPanel: ComponentWithCase = ({
  case: cas
}) => <NeedsApprovalDetailsPanel case={cas} />
