import { map } from '@nll/datum/DatumEither'
import { pipe } from 'fp-ts/es6/function'
import { chain, getOrElse } from 'fp-ts/es6/Option'
import React, { FC } from 'react'

import { useCase } from '../../../../contexts/case/CaseContext'
import { useUser } from '../../../../contexts/User'
import { MessagesCard, Message, SkeletonMessagesCard } from '../../../../elements/case/MessagesCard'
import { renderLoadingOrSuccess } from '../../../../lib/utils'

export const MessagesCardWithData: FC<{ canSend: boolean }> = ({ canSend }) => {
  const { userId } = useUser()
  const { addMessage, messages, caseId } = useCase()

  const displayableMessages = pipe(
    messages,
    map((ms) =>
      ms.map(
        (m): Message => ({
          authorName: getOrElse(() => 'Lawhive user')(
            pipe(
              m.author,
              chain((a) => a.displayName)
            )
          ),
          content: m.content,
          fromMe: m.authorId === userId,
          timestamp: m.createdAt,
        })
      )
    )
  )

  return renderLoadingOrSuccess(
    () => <SkeletonMessagesCard />,
    (messages: Message[]) => (
      <MessagesCard caseId={caseId} messages={messages} addMessage={addMessage} canSend={canSend} />
    )
  )(displayableMessages)
}
