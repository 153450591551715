import { datumEither, initial, isFailure, isPending, isRefresh, isSuccess, refreshFold } from '@nll/datum/DatumEither'
import { sequenceT } from 'fp-ts/es6/Apply'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import 'twin.macro'

// import { AssociateUserWithCaseMutation, CreateCaseQuoteMutation, GetSolicitorsQuery, ListCaseV2sQuery, OnboardUserMutation, OnboardUserMutationVariables } from '../../API'
import { Card, CardContent, ErrorMessageOld, ErrorMessageText, Form, FormButton, FormField, FormSection, FormSelect, SuccessMessageOld, TextArea, TextInput } from '../../elements'
import { Spinner } from '../../elements/Loading'
// import { onboardUser } from '../../graphql/mutations'
// import { getSolicitors } from '../../graphql/queries'
import { ContentContainer, PageContainer } from '../../layout/Layout'
import { ROUTES } from '../../layout/Navigation'
import { useAsync } from '../../lib/useAsync'
import { graphql, isNotNullOrUndefined } from '../../lib/utils'

export const AdminDashboard = () => (
  <PageContainer>
    <ContentContainer>
      <OnboardUser />
      <br/>
      <AddQuoteToCase />
      <br/>
      <AssignUserToCase />
    </ContentContainer>
  </PageContainer>
)


type OnboardUserParams = {
  email: string
  password: string
  caseNumber: string
  phoneNumber: string
  fullName: string
  message: string
}

const OnboardUser = () => {
  const [lastParams, setLastParams] = useState<OnboardUserParams>()

  // const { status: submissionState, execute } = useAsync(
  //   (params: OnboardUserParams) =>
  //     graphql<OnboardUserMutation, OnboardUserMutationVariables>({
  //       query: onboardUser,
  //       variables: {
  //         input: {
  //           email: params.email,
  //           caseNumber: params.caseNumber,
  //           fullName: params.fullName,
  //           password: params.password,
  //           phoneNumber: params.phoneNumber,
  //           message: params.message
  //         }
  //       }
  //     })
  //     .then(a => a.data?.onboardUser
  //         ? Promise.resolve(a.data.onboardUser)
  //         : Promise.reject('Onboarding response error')
  //     )
  // )

  const { register, handleSubmit, errors } = useForm<OnboardUserParams>({
    defaultValues: {
      message: 'Details collected via phone',
    }
  })

  const onSubmit = async (params: OnboardUserParams) => {
    setLastParams(params)
    // await execute(params)
  }

  return (
    <Card>
      <CardContent>
        <h1 tw="text-lg leading-8 font-medium text-gray-900">
          Create user and assign initial case
        </h1>
      </CardContent>
      <Form
        tw="border-t border-gray-200"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormSection tw="flex flex-col items-start">

          <FormField
            id={'email'}
            label={'Email Address'}
            error={errors.email}
            control={(
              <TextInput
                ref={register({ required: "This field is required" })}
                id="email"
                name="email"
                type="email"
                required
              />
            )}
          />

          <FormField
            id={'password'}
            label={'Password'}
            error={errors.password}
            control={(
              <TextInput
                ref={register({ required: "This field is required", minLength: { message: 'Needs to be at least 8 chars', value: 8 } })}
                id="password"
                name="password"
                type="text"
                required
              />
            )}
          />

          <FormField
            id={'caseNumber'}
            label={'Case Number'}
            error={errors.caseNumber}
            control={(
              <TextInput
                ref={register({ required: "This field is required" })}
                id="caseNumber"
                name="caseNumber"
                type="text"
                required
              />
            )}
          />

          <FormField
            id={'phoneNumber'}
            label={'Phone Number'}
            error={errors.phoneNumber}
            control={(
              <TextInput
                ref={register({ required: "This field is required" })}
                id="phoneNumber"
                name="phoneNumber"
                type="text"
                required
              />
            )}
          />

          <FormField
            id={'fullName'}
            label={'Full Name'}
            error={errors.fullName}
            control={(
              <TextInput
                ref={register({ required: "This field is required" })}
                id="fullName"
                name="fullName"
                type="text"
                required
              />
            )}
          />

          <FormField
            id={'message'}
            label={'Initial Case Message'}
            error={errors.message}
            control={(
              <TextArea
                ref={register({ required: "This field is required" })}
                id="message"
                name="message"
                rows={4}
                style={{ width: '800px' }}
                required
              />
            )}
          />

          {/* <FormButton disabled={isPending(submissionState) || isRefresh(submissionState)}>
            Create user, create case and assign user to case
          </FormButton> */}
        </FormSection>


        {/* {isSuccess(submissionState) && (
            <SuccessMessage title="Created user, case, assigned user and initial details">
              {submissionState.value.right && (
                <>
                  <p>Case Number: {lastParams?.caseNumber}</p>
                  <p>Email: {lastParams?.email}</p>
                  <p>Password: {lastParams?.password}</p>

                  <p>Phone Number: {lastParams?.phoneNumber}</p>
                  <p>Full Name: {lastParams?.fullName}</p>
                  <p>Message: {lastParams?.message}</p>

                  <p><Link tw="text-indigo-500 underline font-medium" to={ROUTES.case.details(submissionState.value.right.caseId)}>Case {submissionState.value.right.caseId}</Link></p>
                  <p>User ID: {submissionState.value.right.userId}</p>
                </>
              )}
            </SuccessMessage>
          )}

        {isFailure(submissionState) && (
          <ErrorMessage tw="mb-4" title={'Something went wrong'}>
            <ErrorMessageText>
              {JSON.stringify(submissionState.value.left)}
            </ErrorMessageText>
          </ErrorMessage>
        )} */}
      </Form>
    </Card>
  )
}

type AddQuoteToCasePayload = {
  message: string
  price: number
  platformFee: number
  caseId: string
  solicitorId: string
}

const AddQuoteToCase = () => {
  const { register, handleSubmit } = useForm<AddQuoteToCasePayload>({
    defaultValues: {
      platformFee: 5588,
      price: 14900,
      message: "Solicitors Letter - £149"
    }
  })

  // const { status: solicitorStatus, execute: loadSolicitors } = useAsync(
  //   () =>
  //     graphql<GetSolicitorsQuery>({
  //       query: getSolicitors,
  //     })
  //     .then(a => a.data?.getSolicitors
  //         ? Promise.resolve(a.data.getSolicitors)
  //         : Promise.reject('Onboarding response error')
  //     )
  // )

  // const { status: caseStatus, execute: loadCases } = useAsync(
  //   () => graphql<ListCaseV2sQuery>({
  //     query: /* GraphQL */ `query ListCaseV2s {
  //       listCaseV2s {
  //         items {
  //           id
  //           type
  //           friendlyId
  //           createdAt
  //           updatedAt
  //           associations {
  //             items {
  //               type
  //               chatMember {
  //                 displayName
  //               }
  //             }
  //           }
  //           quotes {
  //             items {
  //               price
  //             }
  //           }
  //           payments {
  //             items {
  //               amount
  //             }
  //           }
  //         }
  //         nextToken
  //       }
  //     }`
  //   }).then(
  //     a => a.data?.listCaseV2s?.items
  //       ? Promise.resolve(a.data.listCaseV2s.items)
  //       : Promise.reject('Empty')
  //   ).then(
  //     a => a
  //       .filter(isNotNullOrUndefined)
  //       .map(i => ({
  //         id: i.id,
  //         friendlyId: i.friendlyId,
  //         names: (i.associations?.items || []).map(x => x?.chatMember.displayName || null).filter(isNotNullOrUndefined)
  //       }))
  //   )
  // )
  // const { status: quoteStatus, execute: createQuote } = useAsync(
  //   (params: AddQuoteToCasePayload) => graphql<CreateCaseQuoteMutation>({
  //     query: /* GraphQL */ `
  //       mutation CreateQuote {
  //         createCaseQuote(input: {
  //             caseId: "${params.caseId}",
  //             platformFee: ${params.platformFee},
  //             price: ${params.price},
  //             solicitorId: "${params.solicitorId}",
  //             content: "${params.message}"
  //           }) {
  //           id
  //           caseId
  //         }
  //       }
  //     `
  //   }).then(a => {
  //     loadCases()
  //     return a.data?.createCaseQuote ? { caseId: a.data.createCaseQuote.caseId, quoteId: a.data.createCaseQuote.id } : null
  //   })
  // )

  const assign = (payload: AddQuoteToCasePayload) => {
    // createQuote(payload)
  }

  useEffect(() => {
    // loadSolicitors()
    // loadCases()
  }, [])

  // const combined = sequenceT(datumEither)(solicitorStatus, caseStatus)

  // const allCombined = sequenceT(datumEither)(solicitorStatus, caseStatus, quoteStatus)

  return refreshFold(
    () => <Spinner />,
    () => <Spinner />,
    () => <Spinner />,
    ([solicitors, cases]: [
      { id: string, name: string | null, email: string | null }[],
      { id: string, friendlyId: string | null, names: string[] }[]
    ]) => (
      <Card tw="flex flex-col">
        <CardContent>
          <h1 tw="text-lg leading-8 font-medium text-gray-900">
          Create Quote
          </h1>
        </CardContent>

        <Form onSubmit={handleSubmit(assign)}>
          <FormField
            id={'price'}
            label={'Quote Price'}
            control={<TextInput id="price" name="price" ref={register} />}
          />

          <FormField
            id={'platformFee'}
            label={'Platform Fee'}
            control={<TextInput id="platformFee" name="platformFee" ref={register} />}
          />

          <FormField
            id={'message'}
            label={'Quote Message'}
            control={<TextInput id="message" name="message" ref={register} />}
          />

          <FormField
            id={'solicitor'}
            label={'Solicitor'}
            control={(
              <FormSelect id="solicitor" name="solicitorId" ref={register}>
                {solicitors.map((s, i) => <option value={s.id} key={i}>{s.name}: {s.email}</option>)}
              </FormSelect>
            )}
          />

          <FormField
            id={'case'}
            label={'Case'}
            control={(
            <FormSelect id="case" name="caseId" ref={register}>
              {cases.map((c, i) => <option value={c.id} key={i}>[{c.friendlyId || 'No friendly ID'}]: {c.names.join(', ')}</option>)}
            </FormSelect>
            )}
          />

          {/* <FormButton disabled={isPending(allCombined) || isRefresh(allCombined)}>
            Add Quote to Case
          </FormButton>

          {isSuccess(allCombined) && (
            <SuccessMessage title="Created quote on case">
              {allCombined.value.right[2] && (
                <>
                  <Link tw="text-indigo-500 underline font-medium" to={ROUTES.case.details(allCombined.value.right[2].caseId)}>Case {allCombined.value.right[2].caseId}</Link>
                  <br/>
                  <Link tw="text-indigo-500 underline font-medium" to={ROUTES.case.viewQuote(allCombined.value.right[2].caseId, allCombined.value.right[2].quoteId)}>Quote {allCombined.value.right[2].quoteId}</Link>
                </>
              )}
            </SuccessMessage>
          )} */}

        {/* {isFailure(allCombined) && (
          <ErrorMessage tw="mb-4" title={'Something went wrong'}>
            <ErrorMessageText>
              {JSON.stringify(allCombined.value.left)}
            </ErrorMessageText>
          </ErrorMessage>
        )} */}
        </Form>
      </Card>
    )
  )(initial)
  // )(combined)
}



type AssignUserToCaseForm = {
  solicitorId: string
  caseId: string
}

const AssignUserToCase = () => {
  const { register, handleSubmit } = useForm()
  const [lastParams, setLastParams] = useState<AssignUserToCaseForm>()

  // const { status: solicitorStatus, execute: loadSolicitors } = useAsync(
  //   () =>
  //     graphql<GetSolicitorsQuery>({
  //       query: getSolicitors,
  //     })
  //     .then(a => a.data?.getSolicitors
  //         ? Promise.resolve(a.data.getSolicitors)
  //         : Promise.reject('Onboarding response error')
  //     )
  // )

  // const { status: caseStatus, execute: loadCases } = useAsync(
  //   () => graphql<ListCaseV2sQuery>({
  //     query: /* GraphQL */ `query ListCaseV2s {
  //       listCaseV2s {
  //         items {
  //           id
  //           type
  //           friendlyId
  //           createdAt
  //           updatedAt
  //           associations {
  //             items {
  //               type
  //               chatMember {
  //                 displayName
  //               }
  //             }
  //           }
  //           quotes {
  //             items {
  //               price
  //             }
  //           }
  //           payments {
  //             items {
  //               amount
  //             }
  //           }
  //         }
  //         nextToken
  //       }
  //     }`
  //   }).then(
  //     a => a.data?.listCaseV2s?.items
  //       ? Promise.resolve(a.data.listCaseV2s.items)
  //       : Promise.reject('Empty')
  //   ).then(
  //     a => a
  //       .filter(isNotNullOrUndefined)
  //       .map(i => ({
  //         id: i.id,
  //         friendlyId: i.friendlyId,
  //         names: (i.associations?.items || []).map(x => x?.chatMember.displayName || null).filter(isNotNullOrUndefined)
  //       }))
  //   )
  // )
  // const { status: assignStatus, execute: assignSolicitor } = useAsync(
  //   (caseId: string, solicitorId: string) => graphql<AssociateUserWithCaseMutation>({
  //     query: /* GraphQL */ `
  //       mutation AssociateToCase {
  //         associateUserWithCase(
  //           associationType: solicitor,
  //           caseId: "${caseId}",
  //           userId: "${solicitorId}"
  //         ) {
  //           id
  //         }
  //       }
  //     `
  //   }).then(async a => {
  //     await loadCases()
  //     return a.data?.associateUserWithCase
  //   })
  // )

  const assign = (payload: AssignUserToCaseForm) => {
    setLastParams(payload)
    // assignSolicitor(payload.caseId, payload.solicitorId)
  }

  useEffect(() => {
    // loadSolicitors()
    // loadCases()
  }, [])

  // const combined = sequenceT(datumEither)(solicitorStatus, caseStatus)
  // const allCombined = sequenceT(datumEither)(solicitorStatus, caseStatus, assignStatus)

  return refreshFold(
    () => <Spinner />,
    () => <Spinner />,
    () => <Spinner />,
    ([solicitors, cases]: [
      { id: string, name: string | null, email: string | null }[],
      { id: string, friendlyId: string | null, names: string[] }[]
    ]) => (
      <Card tw="flex flex-col">
        <CardContent>
          <h1 tw="text-lg leading-8 font-medium text-gray-900">
            After payment: Assign solicitor to case
          </h1>
        </CardContent>

        <Form onSubmit={handleSubmit(assign)}>
          <FormField
            id={'solicitor'}
            label={'Solicitor'}
            control={(
              <FormSelect id="solicitor" name="solicitorId" ref={register}>
                {solicitors.map((s, i) => <option value={s.id} key={i}>{s.name}: {s.email}</option>)}
              </FormSelect>
            )}
          />

          <FormField
            id={'case'}
            label={'Case'}
            control={(
            <FormSelect id="case" name="caseId" ref={register}>
              {cases.map((c, i) => <option value={c.id} key={i}>[{c.friendlyId || 'No friendly ID'}]: {c.names.join(', ')}</option>)}
            </FormSelect>
            )}
          />

          {/* <FormButton disabled={isPending(allCombined) || isRefresh(allCombined)}>
            Assign Solicitor
          </FormButton> */}
        </Form>
{/*
        {isSuccess(allCombined) && (
          <SuccessMessage title="Created quote on case">
            {allCombined.value.right[2] && (
              <>
                {lastParams && (<Link tw="text-indigo-500 underline font-medium" to={ROUTES.case.details(lastParams.caseId)}>Case {lastParams?.caseId}</Link>)}
              </>
            )}
          </SuccessMessage>
        )}

        {isFailure(allCombined) && (
          <ErrorMessage tw="mb-4" title={'Something went wrong'}>
            <ErrorMessageText>
              {JSON.stringify(allCombined.value.left)}
            </ErrorMessageText>
          </ErrorMessage>
        )} */}
      </Card>
    )
  // )(combined)
  )(initial)
}
