import { impl, Variant } from "@practical-fp/union-types"
import { castTo, fetchParsedJSON } from "../lib/fetch"
import { decodeOrError } from '../lib/io'
import { UUID } from "../lib/Primitives"
import { useApiFetch } from '../lib/useApiClient'
import { ChargingModel } from "./useGetChargeAccount"

export type CreateChargeAccountCommand = {
  model: ChargingModel
}

export type ChargeAccountOwner = Variant<"Solicitor", UUID>
export const ChargeAccountOwner = impl<ChargeAccountOwner>()

export type ChargeAccountEntity = {
  id: UUID
}

export const useCreateChargeAccount = () => {
  const api = useApiFetch(fetchParsedJSON(castTo<ChargeAccountEntity>()))

  return (command: CreateChargeAccountCommand) =>
    api(`charge-accounts`, {
      method: "POST",
      body: JSON.stringify(command)
    })
}
