import { useEnvironment } from "../contexts/Environment"
import { gql, GraphQLClient } from "graphql-request"
import * as TE from "fp-ts/lib/TaskEither"
import * as O from "fp-ts/lib/Option"
import * as A from "fp-ts/Array"
import { useFromTaskEither } from "../lib/useAsync"
import {
  PriceUnits,
  QuoteLineItemFeeType,
  QuoteLineItemName
} from "../components/assessment-call/hooks/useCreateSolicitorQuote"
import { pipe } from "fp-ts/lib/function"
import { useEffect } from "react"

export type FeeGuideQuery = {
  legalArea?: string
  areaProduct?: string
  productVariant?: string
}

type Collection<T> = T[]

export type FeeGuideLineItem = {
  name: QuoteLineItemName
  price: PriceUnits
  feeType: QuoteLineItemFeeType
}

export type FeeGuideProductVariant = {
  id: string
  title: string
  shortName: string
  includes?: string[]
  excludes?: string[]

  lineItems: Collection<FeeGuideLineItem>
}

export type FeeGuideProduct = {
  id: string
  title: string
}

export type FeeGuideLegalArea = {
  id: string
  title: string
}

type ContentfulEntry = {
  sys: { id: string }
  title: string
}

type ContentfulVariantEntry = ContentfulEntry & {
  shortName: string
  lineItemsCollection: {
    items: FeeGuideLineItem[]
  }
  includes?: string[]
  excludes?: string[]
}

export const useFeeGuide = (input: FeeGuideQuery) => {
  const { CONTENTFUL_KEY, CONTENTFUL_URL } = useEnvironment()

  const client = new GraphQLClient(CONTENTFUL_URL, {
    headers: {
      authorization: `Bearer ${CONTENTFUL_KEY}`
    }
  })

  const data = (i: { query: string; variables?: Record<string, string> }) =>
    TE.tryCatch(
      () => client.request(i.query, i.variables),
      () => new Error("Fee guide request failed")
    )

  const categoriesQuery = gql`
    {
      feeGuideCategoryCollection {
        items {
          sys {
            id
          }
          title
        }
      }
    }
  `

  const productsQuery = gql`
    query ($id: String!) {
      feeGuideCategory(id: $id) {
        sys {
          id
        }
        productsCollection {
          items {
            sys {
              id
            }
            title
          }
        }
      }
    }
  `

  const variantsQuery = gql`
    query ($id: String!) {
      feeGuideProduct(id: $id) {
        sys {
          id
        }
        variantsCollection {
          items {
            sys {
              id
            }
            title
            shortName
            lineItemsCollection {
              items {
                name
                feeType
                price
              }
            }
            includes
            excludes
          }
        }
      }
    }
  `

  const feeGuideQuery = gql`
    query ($id: String!) {
      feeGuideProductVariant(id: $id) {
        sys {
          id
        }
        shortName
        lineItemsCollection {
          items {
            sys {
              id
            }
            name
            price
            feeType
          }
        }
        includes
        excludes
      }
    }
  `

  const legalAreasTE = () =>
    pipe(
      data({ query: categoriesQuery }),
      TE.map((a): ContentfulEntry[] => a.feeGuideCategoryCollection.items),
      TE.map(
        A.map((a): FeeGuideLegalArea => ({ id: a.sys.id, title: a.title }))
      )
    )

  const productsTE = () =>
    pipe(
      input.legalArea,
      O.fromNullable,
      O.chain(O.fromPredicate((a) => a !== "other")),
      O.fold(
        () => TE.of([]),
        (a) =>
          pipe(
            data({ query: productsQuery, variables: { id: a } }),
            TE.map((a) => O.fromNullable(a.feeGuideCategory)),
            TE.map((a): ContentfulEntry[] =>
              pipe(
                a,
                O.fold(
                  () => [],
                  (b: any) => b.productsCollection.items
                )
              )
            ),
            TE.map(
              A.map((a): FeeGuideProduct => ({ id: a.sys.id, title: a.title }))
            )
          )
      )
    )

  const variantsTE = () =>
    pipe(
      input.areaProduct,
      O.fromNullable,
      O.chain(O.fromPredicate((a) => a !== "other")),
      O.fold(
        () => TE.of([]),
        (a) =>
          pipe(
            data({ query: variantsQuery, variables: { id: a } }),
            TE.map((a) => O.fromNullable(a.feeGuideProduct)),
            TE.map((a): ContentfulVariantEntry[] =>
              pipe(
                a,
                O.fold(
                  () => [],
                  (b: any) => b.variantsCollection.items
                )
              )
            ),
            TE.map(
              A.map(
                (a): FeeGuideProductVariant => ({
                  id: a.sys.id,
                  title: a.title,
                  shortName: a.shortName,
                  lineItems: a.lineItemsCollection.items,
                  includes: a.includes,
                  excludes: a.excludes
                })
              )
            )
          )
      )
    )

  const feeGuideTE = () =>
    pipe(
      input.productVariant,
      O.fromNullable,
      O.chain(O.fromPredicate((a) => a !== "other")),
      O.fold(
        () => TE.of(O.none),
        (a) =>
          pipe(
            data({ query: feeGuideQuery, variables: { id: a } }),
            TE.map(
              (a): O.Option<ContentfulVariantEntry> =>
                O.fromNullable(a.feeGuideProductVariant)
            ),
            TE.map(
              (b): O.Option<FeeGuideProductVariant> =>
                pipe(
                  b,
                  O.map((a) => ({
                    id: a.sys.id,
                    title: a.title,
                    shortName: a.shortName,
                    lineItems: a.lineItemsCollection.items,
                    includes: a.includes,
                    excludes: a.excludes
                  }))
                )
            )
          )
      )
    )

  const legalAreas = useFromTaskEither(legalAreasTE)
  const products = useFromTaskEither(productsTE)
  const variants = useFromTaskEither(variantsTE)
  const feeGuide = useFromTaskEither(feeGuideTE)

  useEffect(() => {
    legalAreas.execute()
  }, [])

  useEffect(() => {
    products.execute()
  }, [input.legalArea])

  useEffect(() => {
    variants.execute()
  }, [input.areaProduct])

  useEffect(() => {
    feeGuide.execute()
  }, [input.productVariant])

  return {
    legalAreas: legalAreas.status,
    products: products.status,
    variants: variants.status,
    feeGuide: feeGuide.status
  }
}
