import {
  clientRequestCaseFileDownloadCommand,
  ClientRequestCaseFileDownloadCommandMutation,
  ClientRequestCaseFileDownloadCommandMutationVariables,
  clientRequestCaseFileUploadCommand,
  ClientRequestCaseFileUploadCommandMutation,
  ClientRequestCaseFileUploadCommandMutationVariables,
  GetCaseQuery,
  GetCaseQueryVariables
} from "@lawhive/generated-api"
import { refreshFold } from "@nll/datum/DatumEither"
import { matchExhaustive } from "@practical-fp/union-types"
import { format } from "date-fns"
import {
  ListFilesForCaseDTOItem,
  useGetCaseFiles,
  useRegisterFileUpload
} from "packages/app/src/contexts/case/useRegisterFileUpload"
import { useCaseInteractionEvent } from "packages/app/src/features/analytics/useCaseInteractionEvent"
import React, { FC, useEffect } from "react"
import "twin.macro"
import { useCase } from "../../../../contexts/case/CaseContext"
import {
  Card,
  CardContent,
  ErrorMessageOld,
  MultiFileUploadInput
} from "../../../../elements"
import { Spinner } from "../../../../elements/Loading"
import { downloadFile, performUpload } from "../../../../lib/files"
import { FileListItem } from "../../../../lib/uploads"
import { useAsync } from "../../../../lib/useAsync"
import { graphql, throwIfIsNullOrUndefined } from "../../../../lib/utils"

export const CaseFilesCard: FC<{ canUpload: boolean }> = ({
  canUpload = true
}) => {
  const { caseId } = useCase()

  const registerUpload = useRegisterFileUpload()

  const { status, execute: load } = useGetCaseFiles(caseId)

  const { status: uploadStatus, execute: upload } = useAsync((file: File) =>
    graphql<
      ClientRequestCaseFileUploadCommandMutation,
      ClientRequestCaseFileUploadCommandMutationVariables
    >({
      query: clientRequestCaseFileUploadCommand,
      variables: {
        input: {
          caseId,
          filename: file.name,
          mimeType: file.type
        }
      }
    })
      .then((a) =>
        a.data?.clientRequestCaseFileUploadCommand?.signature
          ? Promise.resolve(
              JSON.parse(a.data.clientRequestCaseFileUploadCommand.signature)
            )
          : Promise.reject("Invalid upload signature")
      )
      .then((r) =>
        performUpload(file, r.url, r.fields).then(() =>
          registerUpload({ displayName: file.name, s3FileKey: r.fields.key })()
        )
      )
      .then(() => {
        load()
      })
      .then(() => file.name)
  )

  const renderFiles = refreshFold(
    () => <Loading />,
    () => <Loading />,
    (e) => <ErrorMessageOld title="Couldn't load case files" />,
    (r: ListFilesForCaseDTOItem[]) => (
      <dl>
        <FilesList
          caseId={caseId}
          items={r.map((f) =>
            matchExhaustive(f, {
              CaseFile: (f) => ({
                key: f.s3FileKey,
                name: f.displayName,
                lastModified: f.lastModified,
                size: f.fileSize
              }),
              LegacyFile: (f) => ({
                key: f.key,
                name: f.name,
                lastModified: f.lastModified,
                size: f.size
              })
            })
          )}
          update={load}
        />
      </dl>
    )
  )

  const triggerFileUploadEvent = useCaseInteractionEvent()

  const uploadAndTrack = (file: File) => {
    triggerFileUploadEvent({ caseId, interactionType: "file-uploaded" })
    return upload(file)
  }

  useEffect(() => {
    load()
  }, [caseId])

  return (
    <Card>
      <CardContent tw="text-sm leading-5 text-gray-900 font-medium">
        <p>Please ensure you use descriptive file names where possible.</p>
        {canUpload && (
          <MultiFileUploadInput
            state={uploadStatus}
            onUpload={uploadAndTrack}
            fileTypes={{
              fileTypes: "Images or Documents"
            }}
          />
        )}
        {renderFiles(status)}
      </CardContent>
    </Card>
  )
}

const Loading = () => (
  <CardContent tw="mt-6 border border-gray-200 rounded-md">
    <Spinner />
  </CardContent>
)

const FilesList: FC<{
  caseId: string
  items: FileListItem[]
  update: () => void
}> = ({ caseId, items, update }) => {
  const download = (key: string, name: string) =>
    graphql<
      ClientRequestCaseFileDownloadCommandMutation,
      ClientRequestCaseFileDownloadCommandMutationVariables
    >({
      query: clientRequestCaseFileDownloadCommand,
      variables: {
        input: {
          caseId,
          fileKey: key
        }
      }
    })
      .then((r) => r.data?.clientRequestCaseFileDownloadCommand?.url)
      .then(throwIfIsNullOrUndefined)
      .then(downloadFile(name))

  return (
    <div tw="mt-6">
      {items.length > 0 ? (
        <ul tw="border border-gray-200 rounded-md">
          {items.map((f, i) => (
            <li
              key={`file-${i}`}
              tw="pl-3 pr-4 py-3 flex items-center justify-between border-b border-gray-200 last:border-none text-sm leading-5"
            >
              <div tw="w-0 flex-1 flex items-center">
                <svg
                  tw="flex-shrink-0 h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                    clipRule="evenodd"
                  />
                </svg>
                <div tw="flex w-0 flex-grow flex-col sm:flex-row">
                  <button
                    tw="font-medium text-left  ml-2 truncate text-indigo-600 hover:text-indigo-500 transition duration-150 ease-in-out"
                    onClick={() => download(f.key, f.name)}
                  >
                    {f.name}
                  </button>
                  <div tw="font-light text-gray-500 ml-2 sm:ml-auto text-xs sm:text-sm">
                    {format(new Date(f.lastModified), "dd/LL/yy hh:mmaaa")}
                  </div>
                </div>
              </div>
              {/* <div tw="ml-4 flex-shrink-0">
                <button
                  tw="font-medium text-indigo-600 hover:text-indigo-500 transition duration-150 ease-in-out"
                  onClick={() => deleteFile(f.key)}
                >
                  Delete
                </button>
              </div> */}
            </li>
          ))}
        </ul>
      ) : (
        <CardContent tw="border border-gray-200 rounded-md">
          <p tw="text-sm text-gray-500">No files yet</p>
        </CardContent>
      )}
    </div>
  )
}
