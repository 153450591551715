import { axios } from "packages/app/src/lib/axios"
import { useMutation } from "react-query"

type CheckoutQuoteInput = {
  quoteId: string
  successUrl: string
  cancelUrl: string
}

interface CreateCheckoutOutput {
  url: string
}

export const checkoutQuote = (
  i: CheckoutQuoteInput
): Promise<CreateCheckoutOutput> => {
  return axios.post(`quotes/${i.quoteId}/pay`, i)
}

export const useCheckoutQuote = (
  onSuccess: (o: CreateCheckoutOutput) => void
) => {
  return useMutation({
    mutationFn: checkoutQuote,
    onSuccess
  })
}
