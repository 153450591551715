import { default as React, FC } from "react"
import 'twin.macro'

import { ScrollingChildrenContainer } from "./Layout"

export const LoggedOutShell: FC = ({ children }) => {
  return (
    <ScrollingChildrenContainer tw="bg-indigo-900 flex-1 flex flex-col h-full">
      <div tw="py-10">
        <h1 tw="font-logo text-2xl sm:text-4xl text-white text-center mb-6 sm:mb-2">
          lawhive
        </h1>

        {children}

        <div tw="flex flex-row justify-center mt-4">
          <a tw="flex items-center text-indigo-200" href="https://lawhive.co.uk">
            Homepage
            <svg xmlns="http:www.w3.org/2000/svg" tw="h-4 w-4  ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
            </svg>
          </a>
        </div>

      </div>
      {/* <Navigation
        primary={<PrimaryNavigation />}
        secondary={<SecondaryNavigation />}
        mobile={dismiss => <MobileNavigation dismiss={dismiss} />}
      />
      <ScrollingChildrenContainer>
        {children}
      </ScrollingChildrenContainer> */}
    </ScrollingChildrenContainer>
  )
}

// const PrimaryNavigation = () => (
//   <DesktopNavLinkA tw="flex items-center" href="https://lawhive.co.uk">
//     Return to main site
//     <svg xmlns="http://www.w3.org/2000/svg" tw="h-4 w-4  ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//       <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
//     </svg>
//   </DesktopNavLinkA>
// )

// const SecondaryNavigation = () => (
//   <DesktopNavLink to={ROUTES.auth.login()}>
//     Sign in
//   </DesktopNavLink>
// )

// type DismissMenuProp = {
//   dismiss: () => void
// }

// const MobileNavigation: FC<DismissMenuProp> = ({ dismiss }) => (
//   <>
//     <div tw="px-2 pt-2 pb-3 space-y-1 sm:px-3">
//       <DesktopNavLinkA tw="flex items-center" href="https://lawhive.co.uk" onClick={dismiss}>
//         Return to main site
//         <svg xmlns="http:www.w3.org/2000/svg" tw="h-4 w-4  ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
//         </svg>
//       </DesktopNavLinkA>
//     </div>
//     <div tw="pt-4 pb-3 border-t border-indigo-800">
//       <div tw="px-2 space-y-1">
//         <Link
//           tw="block px-3 py-2 rounded-md text-base font-medium text-indigo-400 hover:text-white hover:bg-indigo-700 focus:outline-none focus:text-white focus:bg-indigo-700"
//           to={ROUTES.auth.login()}
//           onClick={dismiss}
//         >
//           Sign in
//         </Link>
//       </div>
//     </div>
//   </>
// )
