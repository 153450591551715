import { DatumEither, initial, map } from "@nll/datum/DatumEither"
import { pipe } from "fp-ts/es6/function"
import React, { createContext, FC, useCallback, useContext, useEffect, useState } from "react"

import { useUser } from "../User"
import { ApiError } from "../../lib/useAsync"
import { noop } from "../../lib/utils"
import { subscribeToMessages } from "./subscribeToMessages"
import { useCreateMessage } from "./useCreateMessage"
import {
  adminGetCaseQuery,
  CaseDetails,
  CaseMessage,
  clientGetCaseQuery,
  solicitorGetCaseQuery,
  useGetCase
} from "./useGetCase"

type CaseContext = {
  caseId: string
  caseDetails: DatumEither<ApiError, CaseDetails>
  messages: DatumEither<ApiError, CaseMessage[]>
  addMessage: (message: string) => Promise<void>
  refresh: () => void
}

const CaseContext = createContext<CaseContext>({
  caseId: '',
  caseDetails: initial,
  messages: initial,
  addMessage: () => Promise.resolve(noop()),
  refresh: noop
})

export const useCase = () => useContext(CaseContext)


export const CaseProvider: FC<{ caseId: string }> = ({ caseId, children }) => {
  const { userId, isSolicitor, isAdmin, name } = useUser()

  const query = isAdmin
    ? adminGetCaseQuery
    : isSolicitor
      ? solicitorGetCaseQuery
      : clientGetCaseQuery

  const { status: caseStatus, execute: loadCase, reset } = useGetCase(caseId, query)

  const createMessage = useCreateMessage()

  const [subscribedMessages, setSubscribedMessage] = useState<CaseMessage[]>([])

  // const combinedStatus = subscriptionUpdate
  //   ? success(subscriptionUpdate)
  //   : status

  useEffect(() => {
    const run = async () => {
      reset()
      const cas = await loadCase()
    }

    run()
  }, [caseId])

  const addMessage = useCallback(
    async (message: string) => {
      const sendMessage = pipe(
        createMessage({ author: userId, caseId: caseId, type: 'text', content: message }),
      )

      await sendMessage()
    },
    []
  )

  useEffect(
    () => subscribeToMessages(caseId, m => setSubscribedMessage(prev => [...prev, m])),
    [caseId]
  )

  const messages = pipe(
    caseStatus,
    map(c => [...c.messages, ...subscribedMessages])
  )

  const context: CaseContext = {
    caseId,
    caseDetails: caseStatus,
    messages,
    addMessage,
    refresh: loadCase
  }

  return (
    <CaseContext.Provider value={context}>
      {children}
    </CaseContext.Provider>
  )
}
