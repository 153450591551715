import { default as React, FC, SVGProps } from 'react'
import tw, { styled } from 'twin.macro'
import { Link } from "react-router-dom"
import { PageTitleBar } from "../layout/Layout"

export const BreadcrumbContainer = tw.div`flex flex-row items-center leading-tight`

export const BreadcrumbItem = styled.h3<{ active?: boolean }>(({ active }) => [
  tw`text-gray-600 mr-4 last:mr-0`,
  active && tw`font-medium text-gray-800`
])

export const BreadcrumbChevron = (props: SVGProps<SVGSVGElement>) => (
  <svg tw="text-gray-400 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" {...props}>
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
  </svg>
)

export type BreadcrumbItem = {
  label: string
  to?: string
  active?: boolean
}

export const BreadcrumbBar: FC<{ items: BreadcrumbItem[] }> = ({ items }) => (
  <PageTitleBar>
    <BreadcrumbContainer>
      {items.map((b, i) => (
        <React.Fragment key={i}>
          <BreadcrumbItem active={b.active}>
            {b.to
              ? <Link to={b.to}>{b.label}</Link>
              : b.label
            }
          </BreadcrumbItem>

          {i < items.length -1 && (
            <BreadcrumbItem>
              <BreadcrumbChevron />
            </BreadcrumbItem>
          )}
        </React.Fragment>
      ))}
    </BreadcrumbContainer>
  </PageTitleBar>
)
